import * as _ from 'underscore';

angular.module('app').directive('farmyProductItem', () => {
  return {
    controller: ['$rootScope', '$scope', '$window', '$element', '$timeout', '$interval', '$location', 'blockUI', 'Alerts', 'CartData', '$translate', 'UnitsInflector', 'UiStateService', 'CatalogServiceHelper', function ($rootScope, $scope, $window, $element, $timeout, $interval, $location, blockUI, Alerts, CartData, $translate, UnitsInflector, UiStateService, CatalogServiceHelper) {
      var imageSizeRatio = 1.3207660416; // Used to scale the image container, while the iamge resource is loading
      var hdImageLoaded = false; // set to true when the HD image is loaded

      $scope.storefrontSupplierId = window.currentStorefrontSupplier ? window.currentStorefrontSupplier.id : null;

      function constructor() {
        if (window.Rails.env == 'development') {
          if (window.farmyProductItem == null) window.farmyProductItem = [];
          window.farmyProductItem.push($scope);
        }

        // If element is NOT visible, watch its state to apply updateLayout() once it is.
        // Solves cart button misplacements in special blocks, like carousels.
        // https://farmyag.atlassian.net/browse/IM-596 (Explore Page Suppliers Block Bug)
        if(!$element.find('.item-container').is(':visible')) {
          var visibilityWatcher = setInterval(() => {
            if($element.find('.item-container').is(':visible')) {
              updateLayout(); // TODO: May be needs to be wrapped in $scope.$apply()
              clearInterval(visibilityWatcher);
            }
          }, 300 + Math.round(Math.random() * 30));
        }

        // Remove links from the element, if needed
        // if($scope.noLink) {
        //   $element.find('a').attr('href', '#');
        // }

        // Disable clicks for mobile app mode.
        if ($rootScope.NativeContainer.isApp) {
          $element.find('a[href]').attr('href', '#');
        }

        setupCss();

        // Really clunky click callback, part of it comes from legacy code that was supposed to
        // 'angularize' product cards on top static HTML code.
        setTimeout(() => {
          $element.find('.product-link, .product-url').bind('click', function(e) {
            var toElementTagName, toElement;

            // This is a Firefox workaround
            if(e.toElement == null && e.target != null) {
              toElementTagName = e.target.tagName.toLowerCase();
              toElement = e.target;
            }
            else {
              toElementTagName = e.toElement.tagName.toLowerCase();
              toElement = e.toElement;
            }

            if (window.isTouchDevice) {
              // Photo click
              var clickType = 'info';

              if (($(toElement).hasClass('view-product') || $(toElement).parents('.view-product').length > 0))
                clickType = 'info';
              else if ($(toElement).hasClass('col-photo') || $(toElement).parents('.col-photo, .control-panel').length > 0)
                clickType = 'photo';

              // console.log("click target", toElement, clickType);

              if (clickType == 'photo') {
                if (window.currentStorefront == 'farmybusiness' && $(toElement).parent().hasClass('btn-add-to-cart')) {
                  return true
                } else {
                  if (!$scope.hover || $scope.inCart)
                    e.stopPropagation();

                  e.preventDefault();
                }

                return false;
              } else if(clickType == 'info' && !$scope.hover) {
                $scope.hover = true;
                // openProductPopup();
                TestManager.startTest(`products_show_scrolling_${window.isMobile ? 'mobile' : 'desktop'}`, 'noscroll').then(response => {
                  if (response.variant == 'scroll') $scope.openProductPage();
                  else $scope.openProductPopup();
                }, (e) => {
                  $scope.openProductPopup();
                  console.error(e);
                });

                e.preventDefault();
                e.stopPropagation();
                return false;
              } else if(clickType == 'info' && $scope.hover) {
                // openProductPopup();
                TestManager.startTest(`products_show_scrolling_${window.isMobile ? 'mobile' : 'desktop'}`, 'noscroll').then(response => {
                  if (response.variant == 'scroll') $scope.openProductPage();
                  else $scope.openProductPopup();
                }, (e) => {
                  $scope.openProductPopup();
                });

                e.preventDefault();
                e.stopPropagation();
                return false;
              }
            } else { // non-mobile behaviour
              if(toElementTagName == 'button' || $(toElement).hasClass('buttons') || $(toElement).hasClass('control-panel-body') || $(toElement).hasClass('normal') || $(toElement).hasClass('mini-style') || $(toElement).hasClass('favorites-toggle')) {
                if (window.currentStorefront == 'farmybusiness')
                  return true;
                else {
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }
              } else if($(toElement).hasClass('item-title')) {
                if ($scope.layout == 'table') {
                  $scope.openProductPopup();
                } else {
                  ProductNavigation.closePopup();
                  $scope.openProductPage();
                }

                e.preventDefault();
                e.stopPropagation();
                return false;
              } else if ($(toElement).parents('.control-panel').length > 0) {
                TestManager.startTest(`products_show_scrolling_${window.isMobile ? 'mobile' : 'desktop'}`, 'noscroll').then(response => {
                  if ($scope.layout == 'table') {
                    $scope.openProductPopup();
                  } else {
                    if (response.variant == 'scroll') $scope.openProductPage();
                    else $scope.openProductPopup();
                  }
                });
                e.preventDefault();
                e.stopPropagation();
                return false;
              }
            }

            return true;
          });
        }, 10);

        setTimeout(loadHdImageWhenInViewport, 400);

        // Allow the item to show after all initialization has been ready
        $timeout(() => {
          $scope.productItemReady = true;
        }, 10);
      }

      function destructor() {

      }

      $scope.currentStorefront = window.currentStorefront;

      let storefrontOptions = window.storefrontOptions;

      let storefrontName = $scope.currentStorefront || 'farmy';
      let storefrontStyles = storefrontOptions[storefrontName] && storefrontOptions[storefrontName].styles && storefrontOptions[storefrontName].styles.product_item ? storefrontOptions[storefrontName].styles.product_item : storefrontOptions['farmy'].styles.product_item;

      $scope.$element = $element;

      /**
       * The item will be shown when this flag is switched to true
       * @type {boolean}
       */
      $scope.productItemReady = false;

      $scope.locale = $rootScope.ngfrontend.locale;
      $scope.lineItemInCart = null;
      $scope.variantInCart = null;
      $scope.variantIndex = null;
      $scope.inCart = false;
      $scope.layout = $element.attr('layout') || 'full';

      $scope.afterCartAddRecommendationsVisible = false;

      // Determine item layout
      if ($element.hasClass('list-view')) $scope.layout = 'list';

      if ($scope.product != null) {
        $scope.productId = $scope.product.id;
        $scope.hubIds = $scope.product.hub_ids;
        $scope.variants = $scope.product.variants;
        $scope.disabledSale = $scope.product.disabled_sale;
        updateProductAvailability($scope.product.available_to_add_to_cart);
        $scope.ageRestricted = $scope.product.age_restricted;
        $scope.portalProduct = $scope.product.portal_product;
        $scope.recipeProduct = $scope.product.recipe_product;
        $scope.no_link = $scope.product.no_link;
        $scope.productUrl = $scope.product.product_url;

        // TODO: Rating serialization is needed as well here for the default categories product view, otherwise ratings metadata will be empty
      }

      if (window.Rails.env == 'development' && $scope.productId) {
        if (window.ProductItems == null) window.ProductItems = {};
        window.ProductItems[$scope.productId] = $scope;
      }

      $scope.productLink = $element.find('.product-link').attr('href');

      $scope.CartData = CartData;

      setGoogleAnalyticsListName();

      window.ProductItemCtrl = $scope;

      // Cart actions
      $scope.increaseCartQuantity = function(event) {
        $scope.setActive();

        if (event) {
          event.stopPropagation();
          event.preventDefault();
        }

        if (!$scope.product.available_to_add_to_cart) {
          showUnavailableAlert();
          return;
        }

        if ($scope.variants == null) {
          Alerts.warn("Bitte warten...");
          return;
        }

        if ($scope.ageRestricted && !window.currentUserAgeVerified) {
          window.FarmyCartAgeVerificationPopup.show(function () {
            window.currentUserAgeVerified = true;
            $scope.increaseCartQuantity(null);
          });
          return;
        }

        let maxVariantIndex = $scope.variants.length - 1;

        if ($scope.product.from_inventory) {
          maxVariantIndex = _.filter($scope.variants, v => (v.quantity_in_units || v.qiu) <= $scope.product.remaining_quantity_in_units).length - 1;
        }

        if (maxVariantIndex < 0) {
          console.info("Product unavailable");
          $scope.available_to_add_to_cart = false; // change availability on the fly
        } else {
          if ($scope.variantIndex == null) {
            $scope.variantIndex = 0;
            trackAddToCart();
          } else if($scope.variantIndex < maxVariantIndex)
            $scope.variantIndex += 1;

          // Fail-safe
          if($scope.variantIndex > maxVariantIndex)
            $scope.variantIndex = maxVariantIndex;

          $scope.variantInCart = $scope.variants[$scope.variantIndex];

          if ($scope.variantInCart) $scope.inCart = true;

          afterCartAdd();

          return CartData.setCartVariant($scope.productId, {
            id: $scope.variantInCart.id,
            price: $scope.variantInCart.price,
            quantity_in_units: $scope.variantInCart.qiu
          }, addToCartReferrer()).then(result => {
          }, error => {
            // Reset product on rejection
            if (error == "cart_blocked") {
              $scope.variantIndex = null;
              $scope.inCart = false;
              $scope.variantInCart = null;
            }
          });
        }
      };

      $scope.decreaseCartQuantity = function(e) {
        $scope.setActive();

        if ($scope.variantIndex == 0) {
          $scope.variantIndex = null;

          // Track event
          Tracking.sendCartRemoveEvent($scope.productId, $element.find('.item-title').text());
        } else if($scope.variantIndex > 0)
          $scope.variantIndex -= 1;

        // Fail-safe
        if($scope.variantIndex < 0) $scope.variantIndex = null;


        if($scope.variantIndex != null) {
          $scope.variantInCart = $scope.variants[$scope.variantIndex];
          return CartData.setCartVariant($scope.productId, {
            id: $scope.variantInCart.id,
            price: $scope.variantInCart.price,
            quantity_in_units: $scope.variantInCart.qiu
          });
        }
        else {
          $scope.variantInCart = null;
          $scope.inCart = false;
          return CartData.setCartVariant($scope.productId, null);
        }
      };

      $scope.openProductPage = function(event) {
        if (event) {
          event.stopPropagation();
          event.preventDefault();
        }

        if (window.CatalogViewCtrl) {
          UiStateService.backFromProductPageData = { type: 'taxon', taxonData: angular.copy(window.CatalogViewCtrl.taxonData), location: { path: $location.path(), search: $location.search() }, filters: window.CatalogFilterCtrl ? window.CatalogFilterCtrl.getSelection() : null }
        } else if (window.SupplierPageViewCtrl) {
          UiStateService.backFromProductPageData = { type: 'supplier', supplier: angular.copy(window.SupplierPageViewCtrl.supplier), location: { path: $location.path(), search: $location.search(), filters: window.CatalogFilterCtrl ? window.CatalogFilterCtrl.getSelection() : null } }
        } else {
          UiStateService.backFromProductPageData = { type: 'location', location: { path: $location.path(), search: $location.search() } }
        }

        if ($scope.noLink) {
          openProductPopup();
        } else {
          trackClick(function() {
            $location.path($scope.product.seo_url);
            // if (window.enableSPA) {
            //   UiStateManager.turboOpenPage($scope.productUrl);
            // } else {
            //   document.location = $scope.productUrl;
            // }
          });
        }
      };

      $scope.openSmartPresentation = function(event) {
        event.stopPropagation();
        event.preventDefault();

        if ($rootScope.NativeContainer.isApp) {
          openProductPopup()
        } else {
          $scope.openProductPage(event)
        }
      };

      $scope.onMouseEnter = function(event) {
        $scope.hover = true;
      };

      $scope.onMouseLeave = function(event) {
        $scope.hover = false;
      };

      $scope.onImageLoaded = function(e) {
        $timeout(() => {
          updateLayout()
          $scope.imageLoadComplete = true;
        }, 5);
      };

      $scope.onTableItemClick = function(event) {
        // This is a Firefox workaround
        let toElement = $(event.target || event.relatedTarget);

        $scope.setActive();

        if (toElement.parents('.col-cart-controls').length == 0) {
          $scope.openProductPopup();
        }
      };

      $scope.onNavigationClick = function(event) {
        console.log('onNavigationClick');

        if (window.isMobile) {
          openProductPopup();

          event.preventDefault();
          event.stopPropagation();
        }
      };

      $scope.setActive = function() {
        if (window._lastActiveProductItem) {
          window._lastActiveProductItem.isActive = false;
        }

        window._lastActiveProductItem = $scope;
        $scope.isActive = true;
      };

      // Private members:

      /**
       * Adjusts calculates dynamic layout properties,
       * mostly used for the 'full' layout card.
       */
      function updateLayout() {
        if ($scope.layout == 'full') {
          var imgElement = $element.find('.photo img');
          $scope.imageHeight = imgElement.css('height');

          $timeout(function() {
            var imageWidth = $element.width();

            if (imageWidth > 1) {
              imgElement.css({
                width: imageWidth,
                height: imageWidth / imageSizeRatio
              });
            }
          }, 1);

          imgElement.one('load', function(e) {
            $scope.imageHeight = $element.find('.photo img').css('height');
          });
        }

        if ($scope.product) {
          let titleLength = $element.find('.item-title').length > 0 ? $element.find('.item-title').text().trim().length : $scope.product.name;
          let fontSizes = null;

          if ($scope.layout == 'full' && !window.browserTabletDevice)
            fontSizes = storefrontStyles.font_sizes.full;
          else if ($scope.layout != 'table')
            fontSizes = storefrontStyles.font_sizes.other;

          // fontSizes schema: { minNameLength: fontSize }
          if (fontSizes) {
            let sizes = _.chain(fontSizes)
              .keys()
              .map((key) => parseInt(key))
              .select((minLenght) => titleLength > minLenght)
              .value();

            let size = sizes.length > 0 ? `${fontSizes[_(sizes).max()]}px` : 'inherit';
            $element.find('.item-title').css({ 'font-size': size });
          }
        }

        $scope.layoutUpdatedOnce = true;
      }

      function setupCss() {
        if ($scope.layout == 'list') {
          $element.addClass('list-view');
        } else if ($scope.layout == 'list_mini') {
          $element.addClass('list-mini-item');
        }
      }

      function trackAddToCart() {
        var options = {
          googleAnalyticsProduct: googleAnalyticsProduct()
        };

        Tracking.addToCart($scope.productId, $element.find('.item-title').text(), options);

        ahoy.track("order-item-added",{
          order_id: $scope.CartData.cart.id,
          order_number: $scope.CartData.cart.number,
          product_id: $scope.product.id,
          variant_id: $scope.product.variants[$scope.variantIndex].id,
          hub_id: window.currentHubId,
          list_mode: CatalogServiceHelper.lastUsedListMode,
          sort_mode: CatalogServiceHelper.lastUsedSortMode,
          referrer: addToCartReferrer(),
          channel: window.xSessionChannel
        });
      }

      function trackClick(callback) {
        try {
          var options = {
            analyticsListName: $scope.analyticsListName,
            googleAnalyticsProduct: googleAnalyticsProduct()
          };

          Tracking.productClick($scope.productId, options, function() {
            callback();
          });
        } catch(e) {
          console.warn("trackClick failed", e)
          callback();
        }
      }

      function googleAnalyticsProduct() {
        return $element.data('google-analytics-product') || ($scope.product && $scope.product.google_analytics_product);
      }

      function getGoogleAnalyticsListName() {
        var listElement = getGoogleAnalyticsListElement($element);
        return listElement.data("analytics-list-name");
      }

      function getGoogleAnalyticsListElement(el) {
        return el.parents('[data-analytics-list-name]');
      }

      function setGoogleAnalyticsListName() {
        var listName = getGoogleAnalyticsListName();
        if (listName && listName.length > 0) {
          $scope.analyticsListName = listName;
        }
      }

      function addToCartReferrer() {
        var res = "";

        if ($scope.product && $scope.product.listItemReferrer != null) {
          // listItemReferrer can be optionally set by the parent
          // farmyProductList to override any other list names (such as GA list name)
          // that would otherwise be sent as a referrer tag
          return ":" + $scope.product.listItemReferrer;
        } else {
          safely(function() {
            res = res + location.pathname + location.hash;
            if ($scope.analyticsListName && $scope.analyticsListName.length > 0) { res = res + ":" + $scope.analyticsListName; }
          });

          return res;
        }
      }

      function updateProductAvailability(isAvailable) {
        $scope.available = isAvailable;

        if (!isAvailable) {
          // $element.find("a").removeClass("product-link");
          // $element.find("a").addClass("no-link");
          // $element.find("a").prop("href", "#");
        }
      }

      /**
       * Reflects changes of the cart state in the current line item control
       */
      function updateCartState() {
        if (CartData.currentWeekly == null) {
          $scope.lineItemInCart = _.find(CartData.cart.line_items, function(line_item) {
            return line_item.product_id == $scope.productId;
          });
        } else {
          $scope.lineItemInCart = _.find(CartData.currentWeekly.items, item => {
            return item.product_id == $scope.productId;
          });

          // Fix line item to match the expected interface
          if ($scope.lineItemInCart.variant == null) {
            let variant = _.find($scope.lineItemInCart.product_data.variants.not_master.visible, v => v.id == $scope.lineItemInCart.variant_id);
            $scope.lineItemInCart.variant = variant;
          }
        }

        if($scope.lineItemInCart) {
          $scope.variantInCart = _.find($scope.variants, v => v.id == $scope.lineItemInCart.variant_id);

          // Set the index of the variant
          if ($scope.variantInCart)
            $scope.variantIndex = _.findIndex($scope.variants, v => v.id == $scope.variantInCart.id);
          else {
            if (Rails.env == 'development') debugger;
            console.warn(`Could not find variantInCart ${$scope.lineItemInCart.variant_id} for product ${$scope.product.name}`);
          }
          if ($scope.variantIndex < 0 || $scope.variantInCart == null) $scope.variantIndex = null;
        } else {
          $scope.variantIndex = $scope.variantInCart = null;
          $scope.inCart = false;
        }

        $scope.inCart = $scope.variantInCart != null;
      };

      function openProductPopup() {
        trackClick(function() {
          // blockUI.start(); // not displaying the loader anymore, since a 'preProduct' is provided and the popup will be shown immediately

          ProductNavigation.openProductPopup($scope.productId, { preProduct: $scope.product, parentAnalyticsListName: $scope.analyticsListName }).then(() => {
            // blockUI.stop(); // Called by the popup upon product data load
          }, () => blockUI.stop());
        })
      }

      $scope.openProductPopup = openProductPopup;

      function afterCartAdd() {
        if (!window.currentStorefront) {
          showAfterCartAddRecommendations();
        }
      }

      function showAfterCartAddRecommendations() {
        $scope.afterCartAddRecommendationsVisible = true;
      }

      function loadHdImage() {
        var imgElement = $element.find('.photo img');
        var hdImageSrc = imgElement.data('hd-src');

        // A hack to fix random image onload listener not firing
        $timeout(() => {
          $scope.imageHeight = imgElement.css('height');
        }, 100);

        hdImageLoaded = true;

        if (imgElement.length > 0 && hdImageSrc) {
          imgElement.attr('src', hdImageSrc)
        }
      }

      function isAvailableToCurrentHub(hubIds) {
        return (window.currentHubId && _.any(hubIds)) ? _.include(hubIds, window.currentHubId) : true
      }

      function loadHdImageWhenInViewport() {
        // var imgElement = $element.find('.photo img');

        var interval = setInterval(function() {
          if (!hdImageLoaded && $($element).is(':in-viewport')) {
            clearInterval(interval);
            loadHdImage();
          }
        }, 800);
      }

      function showUnavailableAlert() {
        Alerts.warn($translate.instant('disabled_sale_message'))
      }

      // Destruction
      $scope.$on('$destroy', function() {
        if (window.ProductItems && $scope.product)
          window.ProductItems[$scope.product.id] = null;
      });

      // Construction
      updateLayout();

      $window.addEventListener('resize', function(e) {
        updateLayout();
      });

      $rootScope.$on('layout:update', () => {
        updateLayout();
      });

      $scope.$watch('product.id', function(newValue, oldValue) {
        if (newValue != null) {
          var product = $scope.product;
          
          $scope.productId = product.id;

          $scope.variants = [];

          _.each(product.variants, function(variant) {
            if (variant.is_master || variant.hidden)
              return;

            $scope.variants.push({id: variant.id, label: variant.quantity_label || variant.label, qiu: variant.quantity_in_units, price: variant.price });
          });

          $scope.variants = _.sortBy($scope.variants, function(variant) { return variant.qiu });

          if ($scope.variantIndex && $scope.variantIndex >= 0) {
            UnitsInflector.$inflect($scope.variants[$scope.variantIndex].qiu, product.unit_name).then(label => {
              $scope.variantInCartLabel = label;

              let lcUnit = product.unit_name.toLowerCase();
              if (lcUnit == 'g.' || lcUnit == 'g' || lcUnit == 'kg' || lcUnit == 'kg.')
                $scope.variantInCartLabelShort = label;
              else $scope.variantInCartLabelShort = $scope.variants[$scope.variantIndex].qiu.toString().replace(/\.0$/, '');
            })
          }

          $scope.recipeProduct = product.is_recipe;
          $scope.ageRestricted = product.age_restricted;
          $scope.disabledSale = product.disabled_sale;
          updateProductAvailability(product.available_to_add_to_cart && isAvailableToCurrentHub(product.hub_ids) && !product.disabled_sale);
          $scope.portalProduct = product.portal_data;

          // Calculate helper CSS classes for potentially long strings
          $scope.cssSupplierClass = '';

          if (product.supplier && product.supplier.name)
          {
            if (product.supplier.name.length > 30)
              $scope.cssSupplierClass = 'string-longest';
            else if (product.supplier.name.length > 25)
              $scope.cssSupplierClass = 'string-longer';
            else if (product.supplier.name.length > 20)
              $scope.cssSupplierClass = 'string-long';
          }

          $scope.cssNameClass = '';

          if (product.name)
          {
            if (product.name.length > 37)
              $scope.cssNameClass = 'string-longest';
            else if (product.name.length > 32)
              $scope.cssNameClass = 'string-longer';
            else if (product.name.length > 26)
              $scope.cssNameClass = 'string-long';
          }

          updateCartState();
        }
      });

      $scope.$watch('variantIndex', (newValue, oldValue) => {
        if (newValue == null) {
          $scope.variantInCartLabel = ""
          $scope.variantInCartLabelShort = $scope.variantInCartLabel;
        } else {
          UnitsInflector.$inflect($scope.variants[$scope.variantIndex].qiu, $scope.product.unit_name).then(label => {
            $scope.variantInCartLabel = label;

            let lcUnit = $scope.product.unit_name.toLowerCase();
            if (lcUnit == 'g.' || lcUnit == 'g' || lcUnit == 'kg' || lcUnit == 'kg.')
              $scope.variantInCartLabelShort = label;
            else $scope.variantInCartLabelShort = $scope.variants[$scope.variantIndex].qiu.toString().replace(/\.0$/, '');
          })
        }
      });

      $scope.$watch('CartData.cart.updated_at', function(updatedAt) {
        if(updatedAt == null)
          return;

        updateCartState();
      });

      constructor();
      $scope.$on('$destroy', destructor);


    }],
    scope: {
      product: '=',
      layout: '@'
    },
    link: function($scope, $element, attributes) {

    },
    template: `<ng-include ng-show='layout && productItemReady' src="'/ng/templates/products/product_item_' + layout + '.html?locale=${window.I18n.locale}'" />`
  }
});
