import React, {useEffect} from 'react';
import {RjsFilterCategoriesSubmenu} from "./RjsFilterCategoriesSubmenu";
import axios from 'axios';
import useTopTaxonFilters from '../react-services/useTopTaxonFilters';

const withTopFilters = (WrappedComponent) => {
  return (props) => {
    const topTaxonFilters = useTopTaxonFilters();

    return (
      < WrappedComponent topTaxonFilters={topTaxonFilters} {...props} />
    );
  };
};

class RjsFilterCategories extends React.Component {
  constructor(props) {
    super(props);

    const scope = this;

    this.state = {
      minimized: props.minimized || false,
      isLoading: false,
      showAll: this.props.showFirstCount ? false : true,
      selections: props.selections || {},
      isSupplierView: window.CatalogServiceHelper.isSupplierView,
      currentTaxon: CatalogServiceHelper.currentTaxon || window.TaxonHelper.CATEGORIES_ROOT_TAXON,
      cachedFilter: null
    };

    AngularIntegration.$on('catalog:currentTaxon:changed', this.onCurrentTaxonChanged.bind(this)).then(unsubscribe => scope._unbindOnCatalogTaxonChanged = unsubscribe);

    AngularIntegration.$on('catalog:loading', () => { scope.setState({ isLoading: true }) }).then(unsubscribe => scope._unbindOnCatalogLoading = unsubscribe);
    AngularIntegration.$on('catalog:loaded', () => { scope.setState({ isLoading: false }) }).then(unsubscribe => scope._unbindOnCatalogLoaded = unsubscribe);
    AngularIntegration.$on('catalog:filters:changed', scope.onFiltersChanged.bind(scope)).then(unsubscribe => scope._unbindOnFiltersChanged = unsubscribe);
  }

  componentDidMount() {
    this._isMounted = true;

    let $ = window.$;
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this._unbindOnCatalogLoading) this._unbindOnCatalogLoading();
    if (this._unbindOnCatalogLoaded) this._unbindOnCatalogLoaded();
    if (this._unbindOnCatalogTaxonChanged) this._unbindOnCatalogTaxonChanged();
    if (this._unbindOnFiltersChanged) this._unbindOnFiltersChanged();
  }

  onFiltersLoaded(event, options) {

  }

  onFiltersChanged(event, options) {
    // Just provoke a refresh
    this.setState({ r: new Date() });
  }

  onCurrentTaxonChanged(event, options) {
    var taxon = options.taxon;

    if (taxon) {
      this.setState({
        currentTaxon: taxon,
        currentParent: this.getParentTaxon(taxon),
        currentDepth: taxon.depth
      })
    } else {
      this.setState({
        currentTaxon: null,
        currentParent: null,
        currentDepth: null
      })
    }
  }

  toggleMinimize() {

  }

  onMouseEnter(value) {
    let k = `hoverState${value.value}`;
    this.setState({[k]: true});
  }

  onMouseLeave(value) {
    let k = `hoverState${value.value}`;
    this.setState({[k]: null});
  }

  onCategoryClick(value, event) {
    if (event) {
      if (event.isPropagationStopped())
        return false;

      event.preventDefault();
      event.stopPropagation();

      event.nativeEvent.stopImmediatePropagation();
      event.nativeEvent.stopPropagation();
      event.nativeEvent.preventDefault();
    }

    let selections = this.state.selections;

    // Clear all other selections
    for(var k in selections) {
      delete(selections[k])
    }

    if (value.value)
      selections[value.value] = !selections[value.value];

    let selected = selections[value.value] || false;

    this.setState({ selections: selections });

    if (window.CatalogFilterCtrl) {
      if (window.CatalogFilterCtrl.filterSelections[this.props.filter.search_param] == null)
        window.CatalogFilterCtrl.filterSelections[this.props.filter.search_param] = {}

      for(var k in window.CatalogFilterCtrl.filterSelections[this.props.filter.search_param]) {
        delete(window.CatalogFilterCtrl.filterSelections[this.props.filter.search_param][k])
      }

      if (selected) {
        window.CatalogFilterCtrl.filterSelections[this.props.filter.search_param][value.value] = true;
      } else {
        // Clear all other selections
      }
    }

    // CatalogServiceHelper.onFilterSelectionChange();
    //
    // if (window.CatalogViewCtrl) window.CatalogViewCtrl.update({ resetPage: true, resetProducts: true });
    // if (window.CatalogSearchViewCtrl) window.CatalogSearchViewCtrl.update({ resetPage: true });
    // if (window.SupplierPageViewCtrl) window.SupplierPageViewCtrl.update({ resetPage: true });

    AngularIntegration.$broadcast('filters:postchange', { filter: this.props.filter, selections: selections });

    AngularIntegration.$location.path(`${window.NgFrontendAppCtrl.locationLocalePrefix || '/'}${value.url}`); // The simple way to trigger

    return false;
  }

  fetchTopCategoryTree() {
    const params = {};

    return new Promise((resolve, reject) => {
      axios.get('/api/products/top_category_filter_tree.json', {params}).then(response => {
          resolve(response.data);
        },
        error => {
          console.error(error);
          reject();
        });
    });
  }

  render(props) {
    let hasTopTaxonFilters = !!this.props.topTaxonFilters;
    var wrapperClass = "filter-wrapper";
    if (this.state.minimizing && this.state.minimized) wrapperClass += " minimized"

    var toggleIconClass = "fa fa-caret-right";
    if (this.state.minimized) toggleIconClass += " open";

    let wrapperStyle = { };

    // if (this.state.isLoading)
    //   wrapperStyle['opacity'] = 0.75;

    // TODO: Apply sorting orderBy:checkboxFilterCtrl.orderingExpression | limitTo:checkboxFilterCtrl.limitExpression:0
    var subcategories = [];
    var parents = [];

    var parentElements = [];
    var subElements = [];

    var parent;
    var nextParentId = this.state.currentTaxon ? this.state.currentTaxon.parent_id : null;

    var i;

    if (hasTopTaxonFilters && this.props.topTaxonFilters && nextParentId) {
      while (parent = _.find(this.props.topTaxonFilters.values, v => v.value === nextParentId)) {
        parents.push(parent);
        nextParentId = parent.parent_id;
      }
    } else {
      while(parent = _.find(this.props.filter.values, v => v.value == nextParentId)) {
        parents.push(parent);
        nextParentId = parent.parent_id;
      }
    }

    parents.reverse();

    // WARNING: currentTaxon has a structure that is different from the filter values type!
    if (this.state.currentTaxon?.depth) {
      if (hasTopTaxonFilters) {
        const taxon = this.props.filter.values.find(filter => filter.value === this.state.currentTaxon.id);
        if (taxon) parents.push(taxon);
      } else {
        let parentFilterValue = _.find(this.props.filter.values, v => v.value == this.state.currentTaxon.id);
        if (parentFilterValue) parents.push(parentFilterValue);
      }
    }

    // Parent categories
    for(i = 0; i < parents.length; i++) {
      let labelClass = "link";
      if (parents[i].length >= 24) labelClass = 'tight-1';
      else if (parents[i].length >= 28) labelClass = 'tight-2';
      else if (parents[i].length >= 33) labelClass = 'tight-3';

      let labelId = `k_${this.props.filter.search_param}_v_${parents[i].value}`;
      let last = i == parents.length - 1;

      let url = parents[i].seo_url ? UrlUtils.stripLeadingSlash(UrlUtils.stripLocaleSlash(parents[i].seo_url)) + window.location.search : ("/" + ((parents[i].url + window.location.search) || ""));

      parentElements.push(
        <li className={"checkbox-filter-value link-filter-value depth-" + parents[i].depth + (last ? " last-category" : '') + (!last && this.state[`hoverState${parents[i].value}`] ? " expanded" : "")} key={labelId} ref={labelId + "_li"}
            onMouseEnter={this.onMouseEnter.bind(this, parents[i])} onMouseLeave={this.onMouseLeave.bind(this, parents[i])}
            onClick={this.onCategoryClick.bind(this, parents[i])}>
          <a href={url ? `${window.NgFrontendAppCtrl.locationLocalePrefix || '/'}${UrlUtils.stripLeadingSlash(url)}` : ''} className={labelClass}>
            {parents[i].name}
          </a>
          {!last && <i className={"fa fa-caret-down pull-right toggle-caret"}></i>}
          {!last && this.state[`hoverState${parents[i].value}`] && <RjsFilterCategoriesSubmenu parentFilter={this} valueList={hasTopTaxonFilters ? this.props.topTaxonFilters.values : this.props.filter.values} taxon={parents[i]} />}
        </li>)
    }

    // Subcategories
    if (this.state.currentTaxon) {
      subcategories = _.filter(this.props.filter.values, v => v.parent_id == this.state.currentTaxon.id && v.name);
    }

    subcategories = _.sortBy(subcategories, v => v.position);

    var values = subcategories;

    for(i = 0; i < values.length; i++) {
      if (values[i].result_count != 0 && !values[i].hidden) {
        let labelClass = "link";
        if (values[i].length >= 24) labelClass = 'tight-1';
        else if (values[i].length >= 28) labelClass = 'tight-2';
        else if (values[i].length >= 33) labelClass = 'tight-3';

        let labelId = `k_${this.props.filter.search_param}_v_${values[i].value}`;

        subElements.push(
          <li className="checkbox-filter-value link-filter-value" key={labelId}
              onClick={this.onCategoryClick.bind(this, values[i])}>
            <a href={values[i].url ? `${window.NgFrontendAppCtrl.locationLocalePrefix || '/'}${values[i].url}${window.location.search}` : ''} className={labelClass} onClick={e => e.preventDefault()}>
              {values[i].name}
            </a>
            <span className="result-count">[{values[i].result_count}]</span>
          </li>)
      }
    }

    // console.log(this.props.filter.search_para, !this.props.minimizing, (this.props.minimizing && !this.state.minimized))
    let hasValues = _.filter(values, v => v.result_count > 0).length > 0;
    // console.log(this.props.filter.search_para, _.filter(values, v => v.result_count > 0).length > 0);

    return <div className="filter-component filter-categories">{true &&
    <div className={wrapperClass} style={wrapperStyle}>
      {/*<h5 className="filter-title" onClick={this.toggleMinimize.bind(this)}>*/}
        {/*{this.props.minimizing ? <i className={toggleIconClass} ng-show=""></i> : null}*/}
        {/*{this.props.filter.display_name}*/}
      {/*</h5>*/}

      {parentElements.length > 0 &&
      <ul className={"filter parents filter-type-checkbox filter-type-" + this.props.filter.search_param}>
        {parentElements}
      </ul>}

      <ul className={"filter subs filter-type-checkbox filter-type-" + this.props.filter.search_param}>
        {subElements}
      </ul>
      <div className="clearfix"></div>
    </div>
    }</div>;
  }

  getParentTaxon(taxon) {
    return angular.extend({}, { id: taxon.parent_id, name: taxon.parent_name, permalink: taxon.parent_permalink, parent_id: taxon.parent_parent_id, all_parents: taxon.parents })
  }

  // componentDidCatch(error, info) {
  //   console.log(error, info);
  //   super.componentDidCatch(error, info);
  // }
}

export default withTopFilters(RjsFilterCategories);
