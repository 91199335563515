import React, {useCallback} from 'react';
import {trackEvent} from '../../services/tracking-helper';

export class RjsSupportContactFormHelpInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeQuestion: null
        }
        this.$http = props.http;
        this._isMounted = false;

        this.loadArticles = this.loadArticles.bind(this);
    }

    render(props) {
        return (<div className="helpdesk-faq">
            {this.buildHelpdeskFaq()}
        </div>);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) this.loadArticles();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    buildHelpdeskFaq() {
        if (_.isEmpty(this.state.faqInfo)) return '';

        return this.state.faqInfo.articles.map((questionObj) => {
            return (<div className='question' key={questionObj.id}>
                <h3 onClick={this.showQuestion.bind(this, questionObj.id, questionObj.title)}><span>{questionObj.title}</span><i className={'fa fa-' + (this.state.activeQuestion === questionObj.id ? 'minus' : 'plus')}/></h3>
                <div className={this.state.activeQuestion === questionObj.id ? '' : 'hidden' }>
                    <p dangerouslySetInnerHTML={{__html: questionObj.details}} />
                </div>
            </div>)
        });
    }

    showQuestion(id, title) {
        if (this.state.activeQuestion === id) {
            id = null;
        }
        this.setState({activeQuestion: id});
        if (id && title) {
            trackEvent('contactform',
              {
                  gtmObject: {
                          type: 'suggested',
                          searchterm: this.props.parentFormType,
                          selection: title
                      }
              });
        };
    }




    loadArticles() {
        setTimeout(()=> {
            this.$http.get(`/api/zendesk/${this.props.parentFormType}/get_articles_by_section.json?locale=${window.I18n.locale}`).then((response) => {
                this.setState({faqInfo: response.data}, () => {
                    if(this.props.onChildComponentLoaded) this.props.onChildComponentLoaded(true);
                });
            }, (error) => {
                console.debug(error);
            });
        }, 500);
    }
}