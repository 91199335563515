'use strict';

angular.module('app')
  .directive('productCart', function() {
    return {
      link: function(scope, element, attributes) {
        scope.productId = attributes.productId;
        scope.variantId = attributes.variantId;
      },
      scope: {

      },
      transclude: true,
      template: `<farmy-ng-include src="'/ng/templates/products/cart_form.html'"></farmy-ng-include>`,
      controller: ['$scope', '$element', '$http', function ($scope, $element, $http) {
        $scope.$watch('productId', function(productId) {
          if (productId != null) {
            return $http.get(sprintf('/api/products/%s.json', productId), {params: {locale: I18n.locale}}).then(function(response) {
              $scope.product = response.data;
            }, function(error) {
              console.warn(error);
            });
          }
        })
      }]
    }
  });
