'use strict';

angular.module('app').controller('UserPermaboxReportCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$q', '$translate', '$localStorage', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, $q, $translate, $localStorage, Alerts) {
    window.UserPermaboxReportCtrl = $scope;

    // Public properties
    $scope.permabox_deposit = null;
    $scope.pending_boxes = null;
    $scope.explanation = false; // display the explanation

    //
    // Private members
    //

    function constructor() {
      $scope.getPermaboxReport();
    }

    function destructor() {
      window.UserPermaboxReportCtrl = null;
    }

    /**
     * Load user permabox deposits data
     */
    $scope.getPermaboxReport = function () {
      return $q(function (resolve, reject) {
        $http.get('/api/frontend/users/permabox_report.json').then(function (response) {
          $scope.permabox_deposit = response.data.permabox_deposit;
          $scope.pending_orders = response.data.pending_orders;
          $scope.pending_box_count = response.data.pending_box_count;
          resolve({ deposit: $scope.permabox_deposit, orders: $scope.pending_orders });
        })
      })
    };

    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
