import {setVariantDisplayPrices} from '../../shared-services/product-data-parser';
import * as _ from 'underscore';
import * as sprintf from 'sprintf';

angular.module('app').controller('ProductModalViewCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$timeout', '$http', '$localStorage', 'Alerts', '$uibModal', '$uibModalInstance', 'productIdentifier', 'productName', 'nextProductIds', 'preProduct', '$sce', 'parentAnalyticsListName', 'lastRoute', 'loadingState', 'UserService',
  function ($scope, $rootScope, $location, $window, $interval, $timeout, $http, $localStorage, Alerts, $uibModal, $uibModalInstance, productIdentifier, productName, nextProductIds, preProduct, $sce, parentAnalyticsListName, lastRoute, loadingState, UserService) {
    // The 'productIdentifier' denotes a flexible-type token for finding the product.
    // normally it is either the ID or the PERMALINK of a single Spree::Product object
    $scope.productIdentifier = productIdentifier;
    $scope.productName = productName; // title name is a preview name, which is available before the product is actually loaded (can be passed by the callee)
    $scope.nextProductIds = nextProductIds; // array of IDs of products that are to be loaded when the 'next' button is clicked
    if (!_.isEmpty(parentAnalyticsListName)) { $scope.analyticsListName = parentAnalyticsListName; }
    $scope.product = null;
    $scope.afterCartAddRecommendations = null; // recommended products, showed after this one is added to the cart

    $scope.collapsable = true;
    $scope.descriptionHeight = 0;
    $scope.collapseDescription = false;
    $scope.weightNoticeOpen = false;

    $scope.displaySaturdayDeliveryNote = false;

    $scope.blockUI = window.blockUI;

    $scope.currentStorefrontSupplier = window.currentStorefrontSupplier;

    window.ProductModalViewCtrl = $scope;

    var openUnavailableInHubPopup = function() {
      var locale_query = '';
      if (I18n.locale != 'de') { locale_query = '?locale=' + I18n.locale }

      $scope.unavailableInHubModal = $uibModal.open({
        animation: true,
        templateUrl: sprintf('/ng/templates/products/unavailable_in_hub_modal.html%s', locale_query),
        controller: 'ProductUnavailableInHubModalCtrl',
        windowClass: 'modal-rounded',
        resolve: {
          subCategoryUrl: function() { return $scope.product.sub_category_url }
        }
      });
    };

    $scope.loadProduct = function() {
      $scope.isLoading = true;

      return $http.get(sprintf('/api/products/%s.json', productIdentifier), {params: {locale: I18n.locale}}).then(function(response) {
        $scope.product = response.data;
        $scope.productName = $scope.product.name;
        $scope.collapsable = true;
        $scope.descriptionHeight = 0;

        // For modal text compatibility
        $scope.resource = $scope.product;

        $scope.masterVariant = _.find($scope.product.variants, function(variant) { return variant.is_master == true; });

        // Inflate some additional data, based on the product source JSON
        $scope.product.availableInCurrentHub = $scope.product.hub_ids.indexOf(window.currentHubId) > -1;
        $scope.product.image_xl = $scope.product.image.lg;
        $scope.product.image_sm = $scope.product.image.md;
        $scope.product.image_xs = $scope.product.image.xs;
        $scope.product.image_product = $scope.product.image.md;

        $scope.available = $scope.product.available_to_add_to_cart && isAvailableToCurrentHub($scope.product.hub_ids);

        $scope.parsedDescription = $scope.product.description.replace(/\n/g, '<br />');
        $scope.parsedDescription = $sce.trustAsHtml($scope.parsedDescription);

        $scope.displaySaturdayDeliveryNote = isFriday() && !$scope.product.delivers_saturday;

        $scope.usingPreProduct = false; // this is no longer a preproduct

        $scope.product.variants = setVariantDisplayPrices($scope.product, UserService.currentUser);

        PartnerPixelHelper.productView($scope.product);
        // In theory, the popup can be dismissed before this callback fires, so we need to recheck the state
        if (loadingState.dismissed != true) {
          $location.changeUrl(UrlUtils.addLocationPrefix('/popup/' + response.data.permalink));

          // True if a 'producer' property exists:
          $scope.product.producer_property = _($scope.product.product_properties).find(function(p) {return p.property_name == 'producer'});
          if ($scope.product.producer_property) {
            $scope.product.producer_property.value = $sce.trustAsHtml($scope.product.producer_property.value);
          }

          setMetaData();
          trackShow();
          if (!$scope.product.availableInCurrentHub) openUnavailableInHubPopup();

          $scope.loadAfterCartAddRecommendations();

          $rootScope.$broadcast('productModalView:productLoaded', $scope.product);
          ahoy.track("viewed-product", { product_id: $scope.product.id, id: $scope.product.id, permalink: $scope.product.permalink, popup: true, channel: window.xSessionChannel });
        }
      }, function(error) {
        if (window.Rollbar) Rollbar.error("Product wasn't available for product modal dialog: #" + productIdentifier, error);
        Alerts.warn("Momentan nicht verfügbar");
        $scope.dismiss();
      }).finally(function() {
        blockUI.stop();
        $scope.isLoading = false;
        $timeout(function() {
          $scope.descriptionHeight = $('.collapsable .description-content').outerHeight();
          if ($scope.descriptionHeight > 500) {
            $scope.collapsable = true;
            $scope.collapseDescription = true;
          } else {
            $scope.collapsable = false;
          }

          // $scope.weightNoticeHeight = $('.weight-control-explanation-wrapper .exp-in-cart').outerHeight() + 25;
          $scope.weightNoticeOpen = false;
        }, 100);
      })
    };

    // Unit can come from weight_units or packaging.
    // In absence of both, will return 'item'.
    $scope.getProductUnit = function() {
      if(!$scope.product) return $translate.instant('item');

      if ($scope.product.weight_units && $scope.product.weight_units.length > 0) {
        return $scope.product.weight_units
      } else if ($scope.product.packaging && $scope.product.packaging.length > 0) {
        return $scope.product.packaging
      } else {
        return $translate.instant('item')
      }
    };

    $scope.loadAfterCartAddRecommendations = function() {
      var url = apiUrl(sprintf(`/products/%s/after_card_add_recommendation.json?max_products=4&express_delivery=${CartData.isExpressDelivery ? 't' : 'f'}`, $scope.product.id));

      return $http.get(url).then(function(response) {
        $scope.afterCartAddRecommendations = response.data.products;
      });
    };

    $scope.openSupplierView = function(permalink) {
      $uibModalInstance.close();

      $timeout(function() {
       $location.path(permalink)
      }, 2);
    };

    $scope.dismiss = function() {
      $scope.product = null;
      $uibModalInstance.dismiss('cancel');
    };

    $scope.dismissToLocation = function(url) {
      $scope.product = null;
      $uibModalInstance.close({nextLocation: url, loadPath: true});
    };

    // Private members
    var isFriday = function() {
      return (new Date).getDay() == 5;
    };

    var setMetaData = function() {
      window.document.title = $scope.product.name;

      var canonical = sprintf('%s%s', settingsSiteUrl, $scope.product.seo_url);

      $("link[rel='canonical']").attr('href', canonical);
      $("link[rel='alternate']").attr('href', canonical); // unfortunately, it's best to clear alternative links,
    };

    var trackShow = function() {
      let url = sprintf('%spopup/%s', $rootScope.locationLocalePrefix || '', $scope.product.permalink);
      Tracking.sendPageviewExt(url);
      Tracking.trackPageview(url, UserService);
    };

    var onDeviceSwipe = function(e, data) {
      if (data && data.direction == 'right') {
        $scope.dismiss();
      }
    };

    var preparePreProduct = function(productInCatalog) {
      var product = angular.copy(productInCatalog);

      product.image_xl = (product.image.md || product.image.preview || "");//.replace("/product/", "/large/");

      $scope.productName = product.name;

      $scope.parsedDescription = product.description.replace(/\n/g, '<br />');
      $scope.parsedDescription = $sce.trustAsHtml($scope.parsedDescription);

      $scope.available = product.available_to_add_to_cart && isAvailableToCurrentHub(product.hub_ids);

      product.supplier.sales_regions = [
        {name: product.supplier.region_name}
      ];

      if (product.certificates) {
        _.each(product.certificates, cert => {
          if (cert.icon != null && cert.icon.length > 1)
            cert.show_icon = true;
        })
      }
      // Description needs to be fitted
      $timeout(function() {
        $scope.descriptionHeight = $('.collapsable .description-content').outerHeight();
        if ($scope.descriptionHeight > 500) {
          $scope.collapsable = true;
          $scope.collapseDescription = true;
        } else {
          $scope.collapsable = false;
        }
      }, 200);

      return product;
    };

    var isAvailableToCurrentHub = function(hubIds) {
      return (window.currentHubId && _.any(hubIds)) ? _.include(hubIds, window.currentHubId) : true
    };

    // Construction

    var unsubscribeOnDeviceSwipe = $scope.$on('user:device:swipe', onDeviceSwipe);

    $scope.$on('$destroy', function() {
      unsubscribeOnDeviceSwipe();
      $rootScope.$broadcast('productModalView:dismissed');
    });

    if (preProduct) {
      $scope.product = preparePreProduct(preProduct);
      $scope.usingPreProduct = true;
    }

    $scope.loadProduct();
  }
]);
