'use strict';

angular.module('app').controller('CheckoutPaymentCtrl', ['$scope', '$element', '$http', '$interval', 'Alerts',
  function($scope, $element, $http, $interval, Alerts) {
    window.CheckoutPaymentCtrl = $scope;

    // Private members:
    var checkDeliveryTimeRestritionsInterval = null;
    var validatedOnce = false;

    var checkDeliveryTimeRestritions = function() {
      if ($scope.isValidatingOrder || $scope.isSending)
        return;

      if (!validatedOnce) {
        $scope.isValidatingOrder = true;
        validatedOnce = true;
      }

      return $http.get(sprintf('/api/frontend/orders/%s/delivery_time_restrictions.json', $scope.orderId)).then(function(response) {
        if (response.data.issue) {
          $scope.checkoutRestriction = response.data.issue;
          $scope.paymentEnables = false;
        } else {
          $scope.paymentEnables = true;
        }
      }).finally(function() {
        $scope.isValidatingOrder = false;
      })
    };

    // Public members:

    /**
     * Currently opened payment tab, ONLY ON MOBILE (see watcher)
     */
    $scope.currentPaymentMethodTab = window.defaultPaymentMethodId; // this flag is used for mobile UI only

    /**
     * Allows to disable/enable payments based on validation
     * @type {boolean}
     */
    $scope.paymentEnables = false;

    $scope.isValidatingOrder = false;

    /**
     * Set to true when the payment form is submitted
     * @type {boolean}
     */
    $scope.isSending = false;

    /**
     * Points to a localized text of checkout restriction message, if there's any
     *
     * @type {string}
     */
    $scope.checkoutRestriction = null;

    // Hackish way to sync desktop payment method nav pills
    // with mobile-only payment methods dropdown selector
    // and spice it all up with some legacy code bindings:
    $scope.$watch('currentPaymentMethodTab', function(newValue) {
      if (newValue != null) {
        $(sprintf('[data-target=#%s]', newValue)).tab('show');
      }
    });

    $scope.$watch('orderId', function(newValue) {
      if (newValue) {
        checkDeliveryTimeRestritions();
      }
    });

    checkDeliveryTimeRestritionsInterval = $interval(function() {
      checkDeliveryTimeRestritions()
    }, 5000);
  }]);