'use strict';

angular.module('app')
  .directive('farmyFilterGlobalText', function () {
    return {
      controller: ['$scope', 'CatalogServiceHelper', '$timeout', function ($scope, CatalogServiceHelper, $timeout) {
        function constructor() {
          $scope.selectedFilters = [];
          $scope.$watchCollection('CatalogServiceHelper.filterSelections', handleFilterSelection);
        }

        function destructor() {
          delete $scope.selectedFilters;
        }

        function handleFilterSelection() {
          $timeout(() => {
            const prefixKey = 'filters.master_filters';
            let masterSelection = Object.keys(CatalogServiceHelper.getMasterFilterSelection());
            $scope.selectedFilters = [];
            if (masterSelection && !_.isEmpty(masterSelection)) {
              masterSelection.forEach((e) => {
                e = e.replace('master_', '');
                $scope.selectedFilters.push(`${prefixKey}.${e}`)
              });
            }
          }, 200);
        }

        constructor();
        $scope.$on('$destroy', destructor);
      }],
      template: `<p ng-if="selectedFilters.length > 0" class="mobile-global-filters" ng-click="UiStateService.showFilterSidebar(true)">
  {{'filters.master_filters.title' | translate}}:
  <span ng-repeat="filterKey in selectedFilters track by $index" class="each-filter">
    <span class="text">{{filterKey | translate}}</span>
    <span class="separator" ng-if="($index +1) !== selectedFilters.length"> | </span>
  </span>
</p>`
    }
  });
