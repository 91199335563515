import * as _ from 'underscore';

angular.module('app').service('AddressHelper', ['$q', '$http', '$interval', '$timeout', '$location', 'blockUI', 'Hubs', ($q, $http, $interval, $timeout, $location, blockUI, Hubs) => {
  var $scope = this || {};
  window.AddressHelper = $scope;

  $scope.DEFAULT_COUNTRY_ID = 101;
  $scope.DEFAULT_COUNTRY_NAME = "Switzerland";

  /**
   * Returns true if the param is either null or a blank string.
   *
   * @param v
   * @returns {boolean}
   */
  function isNOB(v) {
    return (v == null) || (v.trim() == "")
  }

  $scope.isBlank = function(address) {
    if (address == null)
      return true;

    var anyNonNOB = false;

    _([address.firstname, address.lastname, address.company, address.address1, address.house_number, address.zipcode, address.city, address.phone]).each((val) => {
      if (!isNOB(val)) anyNonNOB = true;
    });

    // It's not blank if there are any non-blank options
    return !anyNonNOB;
  };

  $scope.isValid = function(address) {
    // TODO
  };

  $scope.isEqual = function(address1, address2) {
    var equal = true;

    var keys = ['firstname', 'lastname', 'country_id', 'address1', 'address2', 'house_number', 'city', 'zipcode', 'phone', 'birth_date', 'company', 'floor']

    _(keys).each((k) => {
      if (address1[k] != address2[k]) {
        equal = false
      }
    })

    return equal;
  };

  /**
   * Returns true if the addresses collection contains the specified address,
   * by using the AddressHelper.isEqual comparison.
   *
   * @param addresses
   * @param address
   * @returns {boolean}
   */
  $scope.contains = function(addresses, address) {
    return _.filter(addresses, (a) => $scope.isEqual(a, address)).length > 0;
  };

  $scope.validateAddress = function(address) {
    return $q(function(resolve, reject) {
      var params = {
        title: address.title,
        country_id: address.country_id,
        firstname: address.firstname,
        lastname: address.lastname,
        company: address.company,
        address1: address.address1,
        house_number: address.house_number,
        zipcode: address.zipcode,
        city: address.city,
        phone: address.phone,
        birth_date: address.birth_date,
        floor: address.floor
      }

      return $http.post('/api/frontend/addresses/validate_address.json', params).then(function(response) {
        if (response.data.errors) {
          var errorJar = new ActiveRecordErrorJar(response.data.errors);
          errorJar.inflateResource(address).then(a => resolve(a));
          return;
        }

        resolve(address);
      }, e => reject(e));
    })
  };

  $scope.geocodeAddress = function(address) {
    return $q(function(resolve, reject) {
      if (!address) {
        return reject(false);
      }

      if (address.latitude) { // use available coordinates, if possible
        resolve({lat: address.latitude, lng: address.longitude});
      } else {
        $http.post('/api/frontend/addresses/geocode.json', {address_string: $scope.toGoogleMapsAddress(address)} ).then(function(response) {
          var result = response.data;

          if (result.status == 'success')
            resolve({lat: result.lat, lng: result.lng});
          else
            reject(result);
        });
      }
    });
  };

  $scope.locationToAddress = function(lat, lng) {

  };

  $scope.toGoogleMapsAddress = function(a) {
    return `${a.house_number}, ${a.address1}, ${a.city}, ${a.zipcode}, ${$scope.DEFAULT_COUNTRY_NAME}`;
  };

  /**
   * Resolves the promise if the zipcode is a known zipcode, rejects the promise if it's unknown.
   * Warning, it works only when the static 'checkoutKnownZipcodes' window variable is present. It's
   * available within the checkout process.
   *
   * @param zipcode
   * @returns {*}
   */
  $scope.zipcodeIsValid = function(zipcode) {
    return $q((resolve, reject) => {
      if (window.checkoutKnownZipcodes && window.checkoutKnownZipcodes.indexOf(zipcode) == -1)
        return reject(false);
      else return resolve(true);
    })
  };

  $scope.birthDateIsValid = function(birthDate) {
    var date;

    try {
      if (typeof(birthDate) == 'string')
        date = new Date(birthDate)
      else date = new Date(birthDate);

      if (date.getYear() > 10 && date.getYear() < 108)
        return true;
      else
        return false;
    } catch(e) {
      console.error(e);
      return false;
    }
  };

  /**
   * A dead simple date sanitizer - if the birth date is valid, returns it,
   * if not, returns null. I.e. if non valid date is set, we just nullify it.
   *
   * @param birthDate
   * @returns {*}
   */
  $scope.sanitizeBirthDate = function(birthDate) {
    if ($scope.birthDateIsValid(birthDate)) {
      return birthDate;
    } else {
      return null;
    }
  };

  /**
   * Sanitized address attributes, keeping only those that can be serialized
   * to the server.
   *
   * @param unsanitizedAddress
   * @returns {{country_id: *, firstname: *|string, lastname: *|string, company: *|string, address1: *|string|string, house_number: *|string|string, zipcode: *|string|string, city, phone: *|string|string, birth_date: *|null}}
   */
  $scope.sanitizeAttributes = function(unsanitizedAddress) {
    return {
      country_id: unsanitizedAddress.country_id,
      firstname: unsanitizedAddress.firstname,
      lastname: unsanitizedAddress.lastname,
      title: unsanitizedAddress.title,
      company: unsanitizedAddress.company,
      address1: unsanitizedAddress.address1,
      house_number: unsanitizedAddress.house_number,
      zipcode: unsanitizedAddress.zipcode,
      city: unsanitizedAddress.city,
      phone: unsanitizedAddress.phone,
      birth_date: unsanitizedAddress.birth_date,
      floor: unsanitizedAddress.floor,
      register_number: unsanitizedAddress.register_number,
    }
  };

  // Here's a collection of validators to use in address forms
  $scope.validators = {
    /**
     * ASYNCHRONOUS known zipcode validator. Will fail if the zipcode is not in the database.
     *
     * @param modelValue
     * @param viewValue
     * @returns {*}
     */
    knownZipcode: function(modelValue, viewValue) {
      var value = modelValue || viewValue;

      return $q((resolve, reject) => {
        if (window.checkoutKnownZipcodes) {
          if (window.checkoutKnownZipcodes.indexOf(value) == -1) return reject('unknown');
          else return resolve(true);
        } else {
          Hubs.getZipcodeInfo(value).then(result => {
            resolve(true)
          }, error => reject('unknown'))
        }
      })
    },

    validCity: function(modelValue, viewValue) {
      const city = modelValue || viewValue;

      return $q((resolve, reject) => {
        if (city && /^[\u00BF-\u1FFF\u2C00-\uD7FFa-zA-Z',.\s-]{2,30}$/.test(city)) {
          resolve(true);
        } else {
          reject('unknown');
        }
      })
    }
  };

  return $scope;
}]);