import * as _ from 'underscore';
import * as sprintf from 'sprintf';
import {t} from '../shared-services/translate';

'use strict';

angular.module('app')
  .directive('farmyCheckoutAddressForm', function() {
    return {
      controller: ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', 'CartData', 'Alerts', 'blockUI', '$attrs', '$element', 'UserService', function ($scope, $sce, $http, $q, $rootScope, $timeout, CartData, Alerts, blockUI, $attrs, $element, UserService) {
        window.CheckoutAddressFormCtrl = $scope;
        if ($attrs.name) {
          window["Checkout_" + $attrs.name] = $scope;
          if (window.CheckoutShippingFormCtrl) {
            window.CheckoutShippingFormCtrl["Checkout_" + $attrs.name] = $scope;
          }
        }

        $scope.checkoutCtrl = window.CheckoutCtrl;

        $scope.checkoutShippingFormCtrl = $scope.$parent;

        $scope.UserService = UserService;

        if ($scope.checkoutShippingFormCtrl)
          $scope.checkoutShippingFormCtrl.formsValidity = $scope.checkoutShippingFormCtrl.formsValidity || {};

        $scope.showBirthDate = true;
        // $scope.birthDate = new Date();
        $scope.showFullForm = true;
        $scope.monthNames = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'].map(month => {
          return t(`calendar.month.${month}`);
        }).join(',');

        function constructor() {
          $scope.$watch('birthDate', (newValue, oldValue) => {
            if (newValue != oldValue && newValue && $scope.address) {
              $scope.address.birth_date = moment(newValue).format();
            }
          });

          initializeValidations();

          initializeEvents();

          // Inject name into the parent scope
          $scope.$watch('name', (n) => { n ? $scope.$parent[n] = $scope : null });

          // TODO: denis: @mike why it's like this?
          $scope.$watch('addressForm.$valid', (newVal) => {
            if ($scope.checkoutShippingFormCtrl && $scope.checkoutCtrl.stage == 'shipping') {
              $scope.checkoutShippingFormCtrl.formsValidity[$scope.name] = newVal;
              $scope.checkoutShippingFormCtrl.checkFormsValid();
            }
          });

          // Expand the form, if after setting an existing address
          $scope.$watch('address.isNew', (newValue, oldValue) => {
            if (newValue == true) {
              $scope.showFullForm = true;
            }
          });

          // Expand to full form upon new address selection in a linked dropdown selector
          $scope.$on('addressselector:new', (e) => {
            $scope.showFullForm = true;
          });
        };

        $scope.onToggleFullFormClicked = () => {
          $scope.showFullForm = !$scope.showFullForm;
          $rootScope.$broadcast('address:form:toggled', { visible: $scope.showFullForm, address: $scope.address, isBilling: $scope.isBilling, name: $scope.name });
        };

        function destructor() {
          window.CheckoutAddressFormCtrl = null;

          if ($attrs.name) {
            window["Checkout_" + $attrs.name] = null;

            if (window.CheckoutShippingFormCtrl) {
              window.CheckoutShippingFormCtrl["Checkout_" + $attrs.name] = null;
            }
          }
        }

        /**
         * Sets up validation watches for the address
         */
        function initializeValidations() {
          // Switch the full / summary form on address change, if needed
          $scope.$watch('address', (newValue, oldValue) => {
            if (newValue && !_(newValue).isEqual(oldValue)) {
              $scope.birthDate = newValue.birth_date ? moment(newValue.birth_date).toDate() : null;

              if ($scope.checkoutCtrl.preferenceShipAddresses && $scope.checkoutCtrl.preferenceShipAddresses.length > 0) {
                if ($scope.address && _(newValue).isEqual(oldValue)){
                  $scope.showFullForm = false;
                }

                if (_(newValue).isEqual({id: undefined})){
                  $scope.showFullForm = true;
                }
              } else  {
                $scope.showFullForm = true;
              }
            } else $scope.showFullForm = false;
          }, true);

          // Validate zipcode for known/unknown code
          if ($scope.validateZipcode) {
            $timeout(() => {
              // Based on https://docs.angularjs.org/api/ng/type/ngModel.NgModelController#$asyncValidators
              var modelZipcode = $element.find('input[name=zipcode]').controller('ngModel');

              if (modelZipcode) {
                modelZipcode.$asyncValidators.unknown = AddressHelper.validators.knownZipcode
              }
            }, 1)
          }

          // Validate city
          $timeout(() => {
            const modelCity = $element.find('input[name=city]').controller('ngModel');

            if (modelCity) {
              modelCity.$asyncValidators.invalid = AddressHelper.validators.validCity;
            }
          }, 1)
        }

        function initializeEvents() {
          $scope.$watch('address.zipcode', () => {
            $scope.$broadcast('address:zipcode:changed', $scope.address)
          })
        }
        // Public members

        // Initialize

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      scope: {
        address: '=',
        validateZipcode: '=',
        name: '@',
        isBilling: '=',
        formDisabled: '='
      },
      link: function($scope, $element, attributes) {

      },
      templateUrl: function() { return `/ng/templates/checkout/address_form.html?locale=${window.I18n.locale}` }
    }
  });


