import { initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

const faro = initializeFaro({
  url: window.FaroUrl,
  app: {
    name: 'farmy-frontend',
    version: '1.0.0',
    environment: window.Rails && window.Rails.env
  },

  instrumentations: [
  ],
});

const trackGoogleDistanceMatrixApiCall = (opts) => {
  const logObject = JSON.stringify({
    ...opts,
    type: "frontend_google_distance_matrix_api_call",
  })

  faro.api.pushLog([logObject])
}

export { trackGoogleDistanceMatrixApiCall }
