import * as _ from 'underscore';
'use strict';

angular.module('app').service('Engagement', ['$rootScope', '$q', '$location', '$http', '$timeout', '$compile', function ($rootScope, $q, $location, $http, $timeout, $compile) {
  let $scope = this || {};
  window.Engagement = this;

  function constructor() {
    $scope.browserIsBot = window.browserIsBot || window.prerenderAgent;
    $scope.isMobile = window.isMobile;

    // Main activity flag
    $scope.active = false;

    // In case a momentary pause is needed during an active workflow.
    $scope.paused = false;

    // Collection of promo versions to be displayed.
    // Will be decided by the backend and served here.
    $scope.promos = {
      popup: [],
      in_catalog: []
    };

    // Delay in milliseconds from closing.
    $scope.delay = 100;
  }

  // POPUP promos are handled directly by the 'side-dock'

  // IN-CATALOG promos
  // This is a still un-used approach by which
  // promos are pushed into the catalog through animation.
  function displayInCatalogPromo(promo, delay) {
    delay = delay || 0;

    $timeout(() => {
      let anchorElement, cssClasses, scrollWatcher;
      $scope.currentPromo = promo;
      $scope.currentPromo.displayed = true;
       anchorElement = getAnchorElement();
        cssClasses = "col-xs-12 in-catalog";
        if (anchorElement) {
          let compiled = $compile(`<farmy-content-promo type="${promo.type}" version="${promo.version}" class="${cssClasses}"/>`)($rootScope);
          anchorElement.before(compiled[0]);
        }
    }, delay);
  }

  function getAnchorElement() {
    let productCards = Array.from(document.getElementsByClassName('p-full-wrapper'));
    return _(productCards).select((card) => card.getBoundingClientRect().y - window.innerHeight * 0.7 > 0)[0];
  }

  // Will remove from the collection any "terminated" promo.
  function onPromoDismissed() {
    _(_($scope.promos).keys()).each((key) => $scope.promos[key] = _($scope.promos[key]).reject((promo) => promo.terminated));
  }

  // Calls the backend 'engagement' service to send a random promo.
  $scope.sendRandomPromo = function() {
    let engagementEndpoint = '/api/frontend/engagement_tools';
    let params;

    if (window.currentUserId) {
      params = {user_id: window.currentUserId}
    } else if (ahoy.getVisitorId()) {
      params = {visitor_id: ahoy.getVisitorId()}
    }

    $http.put(`${engagementEndpoint}/random_promo`, params)
  };

  $rootScope.$on('engagement:launch', (event, message) => {
    console.log(message);

    // Disable Engagement during checkout, for non-German locales and for storefronts.

    let isStorefront = window.currentStorefront;
     if (isStorefront)
       return;

    let disabledPaths = ['/cart', '/cashier', '/checkout'];
    if (_(disabledPaths).some(disabledPath => $location.path().indexOf(disabledPath) > -1))
      return;

    if (I18n.locale != 'de')
      return;

    $scope.promos[message.type].push(message);

    $scope.active = true;
  });

  $rootScope.$on('engagement:stop', (event, payload) => {
    $scope.active = false;
  });

  $rootScope.$on('content_promo:dismissed', () => {
    onPromoDismissed();
  });

  $rootScope.$on('$destroy', () => {
    window.Engagement = null;
  });

  constructor();
}]);
