import axios from "axios";
import * as _ from "underscore";

angular.module("app").service("DatatransHelper", ["$q", "$http", "$interval", "$timeout", "$translate", "UserService", "CartData", ($q, $http, $interval, $timeout, $translate, UserService, CartData) => {
  const $scope = this || {};

  $scope.deferredSettlement = UserService.currentUser.deferred_settlement && CartData.cart.deferred_settlement;

  /**
   * Example:
   *
   * DatatransHelper.initiatePayment({
   *   merchantId: '1100019384',
   *   amount: 1.0,
   *   currency: 'CHF',
   *   identifier: '100001'
   * });
   *
   * Options:
   *
   * - mpo: use it to initiate subscription-based payments with deferred server-side payments
   * - paymentmethod: specify available payment methods
   * - useAlias: save an alias of the card after transaction
   * - rememberMe: Use together with useAlias to 'remember' the card
   *
   * @param paymentData
   */
  $scope.initiatePayment = function(paymentData, options) {
    if (options == null) {
      options = {
        mode: "lightbox"
      };
    }

    return $q((resolve, reject) => {
      if (paymentData.identifier == null) {
        console.error("paymentData.identifier (unique) must be specified");
        return reject("paymentData.identifier (unique) must be specified");
      }

      const dt_params = {};

      dt_params.opened = () => {
        axios.put(`/api/checkouts/${paymentData.order_id}/payment_started.json`);
        options.onOpen && options.onOpen();
      };

      dt_params.closed = () => {
        axios.put(`/api/checkouts/${paymentData.order_id}/payment_cancelled.json`);
        options.onClosed && options.onClosed();
      };

      dt_params.error = () => {
        axios.put(`/api/checkouts/${paymentData.order_id}/payment_cancelled.json`);
        $scope.onDatatransError(); // Legacy, doesn't really do anything.

        options.onError && options.onError();
      };

      /* dt_params.params = config;
      dt_params.form = options.form || "#dt_lightbox_form";
      dt_params.mode = options.mode;
*/
      initTransaction(paymentData, options).then(transaction => {
        dt_params.transactionId = transaction.transactionId;

        window.Datatrans.startPayment(dt_params);
        resolve();
      }, e => {
        reject(e);
      });
    });
  };

  $scope.initiateOrderPayment = function(order, options) {
    return $scope.initiatePayment({amount: order.total, currency: order.currency || window.defaultCurrency, identifier: order.id}, options);
  };

  /**
   * get transactionId to init the payment
   *
   * @param paymentData
   * @returns {*}
   */
  function initTransaction(paymentData, options) {
    return $q((resolve, reject) => {
      const config = {
        amount: parseInt((paymentData.amount * 100).toFixed(2)),
        currency: paymentData.currency || window.defaultCurrency,
        refno: paymentData.identifier,
        option: {},
        redirect: {}
 /*        webhook: {
          url: options.webhook_url
        } */
      };

      // params.redirect = {};
      if (options.success_url) config.redirect.successUrl = options.success_url;
      if (options.error_url) config.redirect.errorUrl = options.error_url;
      if (options.cancel_url) config.redirect.cancelUrl = options.cancel_url;
      if (options.use_alias) config.option.createAlias = true;
      if (options.rememberMe || options.remember_me) config.option.rememberMe = true;
      if (options.uppStartTarget) config.redirect.startTarget = options.uppStartTarget;

      config.language = I18n.locale;
      if (config.language === "fr-CH") config.language = "fr"; // CH suffix hack

      if (options.mpo) {
        config.merchantId = window.settings.mpoMerchantId;
      } else {
        config.merchantId = window.settings.cpoMerchantId;
      }

      if (options.mpo) {
        config.merchantId = window.settings.mpoMerchantId;
      }

      // if (paymentData.order_id) config.refno = "O" + paymentData.order_id;
      if (paymentData.subscription_id) config.refno = "S" + paymentData.subscription_id;
      if (options.paymentmethod) config.paymentMethods = options.paymentmethod.split(",");

      if (paymentData.aliasCard) {
        config.card = {};
        config.card.alias = paymentData.aliasCard.pan;
        config.card.expiryMonth = paymentData.aliasCard.month;
        config.card.expiryYear = paymentData.aliasCard.year;
      }

      if ($scope.deferredSettlement && options.deferredSettlement) config.autoSettle = false;

      $http.post("/api/frontend/transactions", config).then(response => {
        resolve(response.data);
      });
    });
  }

  $scope.onDatatransError = function(errorData) {
    console.error("onDatatransError", errorData);
  };

  window.DatatransHelper = $scope;

  return $scope;
}]);
