import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {RjsProductList} from "../react-components/RjsProductList";
import useNgRef from "../react-components/shared/ngRef";
import {ReduxTinyProvider} from "../shared-services/redux-tiny";

const NgrContextProvider = ({children}) => {
  return <ReduxTinyProvider>{children}</ReduxTinyProvider>;
};

const NgrRouterProvider = ({children}) => {
  return <BrowserRouter>{children}</BrowserRouter>;
};

angular.module("app")
  .directive("ngrProductList", ["$rootScope", "$http", "$timeout", function($rootScope, $http, $timeout) {
    "use strict";
    return {
      scope: {
        products: "=",
        paginator: "=",
        layout: "<",
        columnClasses: "@",
        namedset: "@",
        trackingListName: "@",
        nocache: "@",
        limitTo: "@",
        taxonIds: "@",
        productIds: "@",
        keywords: "@",
        debounceLoad: "@",
        includeUnavailable: "@",
        includeUnaddable: "@",
        itemReferrer: "@",
        infiniteScroll: "@",
        parentNodeId: "@",
        expressDelivery: "=",
        isLoading: "<",
        hideMoreButton: "<",
        debugSearch: "@"
      },
      link: function($scope, $element, attrs) {
        function destructor() {
          ReactDOM.unmountComponentAtNode($element[0]);
        }

        const ngref = useNgRef($scope); // ngref is a placeholder that will be populated with component's state setters, to be used from the outside of React (yikes!)

        $scope.$on("$destroy", destructor);

        $scope.$watch("layout", (newValue) => {
          // Update products props of the child React element
          if (ngref.isValid() && newValue) {
            ngref.change(actions => actions.setListMode(newValue));
          }
        });

        $scope.$watch("isLoading", (newVal, oldVal) => {
          if (newVal == oldVal) return;

          if (ngref.isValid()) {
            ngref.change(actions => actions.updateLoading(newVal));
          }
        });

        $scope.$watchCollection("products", (newValue) => {
          // Update products props of the child React element
          if (ngref.isValid() && newValue) {
            ngref.change(actions => actions.setProducts(newValue));
          }
        });

        const props = {
          ngref,
          $http,
          $timeout,
          productIds: attrs.productIds,
          keywords: $scope.keywords,
          layout: $scope.layout,
          products: $scope.products,
          paginator: $scope.paginator,
          noloading: attrs.noloading,
          limitTo: $scope.limitTo,
          infiniteScroll: $scope.infiniteScroll,
          parentNodeId: $scope.parentNodeId,
          namedset: $scope.namedset,
          trackingListName: $scope.trackingListName,
          isLoading: $scope.isLoading,
          expressDelivery: $scope.expressDelivery,
          hideMoreButton: $scope.hideMoreButton,
          debugSearch: $scope.debugSearch
        };

        const routerFactory = React.createFactory(NgrRouterProvider);
        const componentFactory = React.createFactory(RjsProductList);
        const mainFactory = React.createFactory(NgrContextProvider);
        const component = routerFactory({children: mainFactory({children: componentFactory(props)})});

        ReactDOM.render(component, $element[0]);
      }
    };
  }]);
