'use strict';

angular.module('app').controller('CheckoutDeliveryCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', 'Alerts', 'blockUI', function ($scope, $sce, $http, $q, $rootScope, Alerts, blockUI) {
  window.CheckoutDeliveryCtrl = $scope;

  var rootScopeCartDataListener, rootScopeDeliveryDateUpdatedListener, rootScopeDeliverySlotUpdatedListener;

  $scope.eligibleForFirstTwoOrdersPromo = window.eligibleForFirstTwoOrdersPromo;

  function constructor() {
    $scope.loadOrder();

    rootScopeCartDataListener = $rootScope.$on('cartdata:saved', function() {
      $scope.loadOrder();
    });

    rootScopeDeliveryDateUpdatedListener = $rootScope.$on("delivery_date:updated", onDeliveryDateUpdated);
    rootScopeDeliverySlotUpdatedListener = $rootScope.$on("delivery_slot:updated", onDeliverySlotUpdated);

    $('#backToAddress').click(function() {
      location.href = Routes.spree_checkout_state_path({state: 'address'});
    });
  }

  function destructor() {
    // Deregister rootScope listeners
    if (rootScopeCartDataListener) rootScopeCartDataListener();
    if (rootScopeDeliveryDateUpdatedListener) rootScopeDeliveryDateUpdatedListener();
    if (rootScopeDeliverySlotUpdatedListener) rootScopeDeliverySlotUpdatedListener();

    window.CheckoutDeliveryCtrl = null;
  }

  /**
   * Invoked by the legacy checkout-delivery.js component, when
   * a date is clicked in the calendar
   *
   * @param e
   */
  var onDeliveryDateUpdated = function(event, date) {
    $scope.currentSelectedDate = moment(date).toDate();

    if ($scope.activeSmartPass || $scope.eligibleForFirstTwoOrdersPromo) {
      $scope.setDatesAndSlots($scope.activeSmartPass);
    }

    updateDateSpecificMessages();

    $scope.unfulfillableProducts = getUnfulfillableProductsForDate($scope.currentSelectedDate);
  };

  /**
   * Invoked by the legacy checkout-delivery.js component, when
   * a delivery slot item is clicked in the handlebars-based slots list
   *
   * @param event
   */
  var onDeliverySlotUpdated = function(event, data) {
    $scope.currentSlot = _($scope.deliverySlots).find(function(slot) {
      return slot.id == data.slotId;
    });

    // console.log("onDeliverySlotUpdated", data.slotId, $scope.currentSlot);
  };

  var isSmartPassDate = function(date, smartPass) {
    if (typeof date == 'object') {
      return _.contains(smartPass.week_days, moment(date).toDate().getDay())
    } else if (typeof date == 'number') {
      return _.contains(smartPass.week_days, date)
    }
  };

  var getEligibleDateObjects = function(smartPass) {
    var eligibleSlots = [];
    var activeSlots = $('td.day').not($('.disabled'));

    $.each(activeSlots, function(index, slot) {
      var date_splitted = $(slot).data('day').split('.');
      if (date_splitted.length == 1) {date_splitted = $(slot).data('day').split('/');}
      var day_of_week = new Date(date_splitted[2], (parseInt(date_splitted[1]) - 1).toString(), date_splitted[0]).getDay();

      if (isSmartPassDate(day_of_week, smartPass)) {
        var slotSpan = $(slot).find("span")[0];
        eligibleSlots.push(slotSpan);
      }
    });

    return eligibleSlots;
  };

  var addDeliverySlotBadges = function(smartPass) {
    if (!smartPass) return;

    $scope.emptyDateBadges();
    var eligibleDateObjects = getEligibleDateObjects(smartPass);
    $(eligibleDateObjects).append('<img class="smart-pass-badge hidden-xs" style="opacity: 0" src="' + $scope.smartPassImgUrl[smartPass.level] + '" />');
    $('.smart-pass-badge').delay(85).fadeTo('fast', 1);
  };

  var updateDateSpecificMessages = function() {
    // Optionally toggle something for specific dates:
    // (example)
    // if ($scope.currentSelectedDate.format("YYYYMMDD") == '20171224')
    //   $scope.showUnfulfillableProductsMessage = true;
    // else
    //   $scope.showUnfulfillableProductsMessage = false;
  };

  var getUnfulfillableProductsForDate = function(momentDate) {
    // Get slots for the currently selected date and collect unique restricted product ids
    var currentDate = moment(momentDate).format().split('T')[0];

    var slots = _.select($scope.deliverySlots, function(slot) {
      return slot.delivery_time.split('T')[0] == currentDate;
    });

    return {
      ids: _.chain(slots).map(function(s) { return s.restricted_product_ids }).flatten().uniq().value(),
      namestring: _.chain(slots).map(function(s) { return s.restricted_products }).flatten().uniq().value()[0]
    };
  }

  //
  // Public members
  //

  /**
   * Slot that is currently selected in the radio-box list. The objects themselves are fetched
   * by id from a global array of delivery slots (window.deliverySlots) available through the page itself.
   *
   * window.deliverySlots is flattened and copied to $scope.deliverySlots
   *
   * @type {object}
   */
  $scope.currentSlot = null;

  /**
   * Array of all delivery slots that can be displayed to the user (not necessarily _available_).
   * Delivery time is converted to a date object
   */
  $scope.deliverySlots = _.chain(window.deliverySlots).values().flatten().map(function(slot) {
    slot.delivery_datetime = new Date(slot.delivery_time);
    return slot;
  }).value();

  /**
   * Moment date, that is currently selected in the calendar.
   *
   * @type {Moment}
   */
  $scope.currentSelectedDate;

  $scope.eligibleSmartPassDate = false;
  $scope.activeSmartPass = null;
  $scope.purchasingSmartPass = false;
  $scope.freeSmartPass = false;
  $scope.smartPassImgUrl = {
    'basic': $('#delivery-date-picker').data('smart-pass-badge-basic'),
    'premium': $('#delivery-date-picker').data('smart-pass-badge-premium')
  };

  $scope.loadOrder = function() {
    return $q(function(resolve, reject) {
      $http.get(apiUrl("/orders/current/delivery.json")).then(function(response) {
        $scope.order = response.data.order;
        if ($scope.order) {
          if ($scope.order.eligible_smart_pass_subscription) {
            $scope.activeSmartPass = $scope.order.eligible_smart_pass_subscription;
            $scope.setDatesAndSlots($scope.activeSmartPass);
          } else if ($scope.eligibleForFirstTwoOrdersPromo) {
            $scope.setDatesAndSlots()
          } else { $scope.smartPassImgUrl['basic'] = $scope.smartPassImgUrl['premium']; }

          if ($scope.order.purchasing_smart_pass) {
            $scope.purchasingSmartPass = true;
            if ($scope.order.purchasing_smart_pass.free_smart_pass) {
              $scope.freeSmartPass = true;
            }
          }
        }
        resolve(response);
      });
    });
  };

  /**
   * Manages how prices per delivery slot are shown
   */
  $scope.setSlotPricesFree = function() {
    var slots = angular.element('#delivery-slot-picker .delivery-slot-option');

    // Toggle free/non-free price tag (e.g. due to a Hofpass)
    angular.forEach(slots, function(object, key) {
      $(object).delay(key * 50).fadeTo('fast', 0, function() {
        $(object).find('.price-addon.price-regular').hide();
        $(object).find('.price-addon.price-free').show();
        $(object).addClass('slot-free').fadeTo('fast', 1);
      });
    });
  };

  /**
   * Resets delivery slot price tags to the default state (show the price)
   */
  $scope.resetSlotPrices = function() {
    var slots = angular.element('#delivery-slot-picker .delivery-slot-option');
    angular.forEach(slots, function(object, key) {
      $(object).delay(key * 150).fadeTo('fast', 0, function() {
        $(object).find('.price-addon.price-free').hide();
        $(object).find('.price-addon.price-regular').show();
        $(object).removeClass('slot-free').fadeTo('fast', 1);
      });
    });
  };

  $scope.setDatesAndSlots = function(smartPass) {
    if ($scope.eligibleForFirstTwoOrdersPromo || isSmartPassDate($scope.currentSelectedDate, smartPass)) {
      $scope.eligibleSmartPassDate = true;
      $scope.setSlotPricesFree();
    } else {
      $scope.eligibleSmartPassDate = false;
      $scope.resetSlotPrices();
    }
    addDeliverySlotBadges(smartPass);
  };

  $scope.emptyDateBadges = function(){
    $('.smart-pass-badge').fadeTo('fast', 0, function() {this.remove()});
  };

  /**
   * Chooses a nearest fully available slot for the date (moment) specified. If
   * that specific date isn't available, another date will be suggested, with earlier
   * dates having priority
   *
   * @param momentDate
   */
  $scope.chooseNearestAvailableSlot = function(momentDate) {
    var date = momentDate.startOf('day').toDate();
    var lastSlot = null;

    var slots = _.chain($scope.deliverySlots)
      .select(function(slot) { return slot.delivery_datetime < date && (slot.restricted_product_ids == null || slot.restricted_product_ids.length == 0) }) // select earlier available slots
      .sortBy(function(slot) { return slot.delivery_datetime }) // sort by delivery datetime
      .value();

    if (slots.length > 0) {
      lastSlot = slots[slots.length - 1];
    } else {
      var slots = _.chain($scope.deliverySlots)
        .select(function(slot) { return slot.delivery_datetime > date && (slot.restricted_product_ids == null || slot.restricted_product_ids.length == 0) }) // select earlier available slots
        .sortBy(function(slot) { return slot.delivery_datetime }) // sort by delivery datetime
        .value();

      lastSlot = slots[0];
    }

    // setTimeout is used to avoid circular angular $apply() invocation that is triggered as
    // the result of changing the date. The calendar is a jQuery component, thus we have lots
    // of legacy-looking code here
    setTimeout(function() {
      $(window.deliveryDatetimepicker).data('DateTimePicker').date(moment(lastSlot.delivery_datetime));

      // Autoselect the recommended delivery slot
      setTimeout(function() {
        $(sprintf("li.delivery-slot-option input[value=%s]", lastSlot.id.toString())).trigger('click')
      }, 330);
    }, 1);
  };

  /**
   * Tries to remove products that generate date limitations for certain delivery
   * slots and reloads the page to display new delivery slot options
   */
  $scope.removeUnfulfillableProducts = function() {
    // Get slots for the currently selected date and collect unique restricted product ids
    var currentDate = moment($scope.currentSelectedDate).format().split('T')[0];

    var slots = _.select($scope.deliverySlots, function(slot) {
      return slot.delivery_time.split('T')[0] == currentDate;
    });

    var params = {
      product_ids: $scope.unfulfillableProducts.ids
    };

    blockUI.start();

    return $http.post(sprintf('/api/checkouts/%s/remove_unfulfillable_products.json', $scope.order.number), params).then(function() {
      Alerts.success("Wird aktualisiert...");
      blockUI.stop();
      location.reload();
    }, function(error) {
      Alerts.warn(errorMessage(error));
    })

  };

  // Initialization

  $scope.$on('destroy', destructor);
  constructor();
}]);
