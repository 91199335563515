import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

/**
 * Listens to shipping zipcode changes and runs cart contents validations related
 * to the shipping destination (Hubs compatibility, etc.)
 *
 * A visual element of the shipping stage is linked to scope variables
 * modified by this method.
 */
angular.module('app')
  .directive('farmyCheckoutIncompatibleProducts', function() {
    return {
      controller: ['$scope', '$http', '$q', '$timeout', 'Alerts', 'blockUI', '$attrs', '$element', 'Hubs', 'AddressHelper', 'OrderHelper', function ($scope, $http, $q, $timeout, Alerts, blockUI, $attrs, $element, Hubs, AddressHelper, OrderHelper) {
        window.CheckoutIncompatibleProducts = $scope;

        // Some performance hacks to avoid unneeded bindings (oh yeah, that's a nice justification!)
        $scope.checkoutCtrl = window.CheckoutCtrl;
        $scope.checkoutShippingFormCtrl = $scope.$parent;
        $scope.incompatibleLineItems = [];

        function constructor() {
          // Wait one frame for the children to initialize
          $timeout(() => {
            $('farmy-checkout-address-form[name=shipAddressForm] fieldset').scope().$on('address:zipcode:changed', onZipcodeChange);
          }, 1)

          // Force the onchange initially with a very small delay
          $timeout(() => { validateZipcode() }, 250)
        }

        function destructor() {
          window.CheckoutIncompatibleProducts = null;
        }

        function validateZipcode() {
          if ($scope.checkoutShippingFormCtrl && $scope.checkoutShippingFormCtrl.selectedShipAddress)
            onZipcodeChange(null, $scope.checkoutShippingFormCtrl.selectedShipAddress)
        };

        function onZipcodeChange(event, address) {
          // Ignore if the address is blank
          if (address == null || address.zipcode == null)
            return;

          // Validate contents products only if the entered zipcode is valid
          AddressHelper.zipcodeIsValid(address.zipcode).then((valid) => {
            OrderHelper.getIncompatibleLineItems($scope.checkoutCtrl.checkout, { zipcode: address.zipcode }).then((r) => {
              $scope.incompatibleLineItems.length = 0;

              _.each(r, i => $scope.incompatibleLineItems.push(i));

              $scope.orderTotalWithoutIncompatible = $scope.checkoutCtrl.checkout.item_total - _.chain($scope.incompatibleLineItems).map(i => i.total).reduce((memo, num) => memo + num, 0).value();
              $scope.orderMinTotal = $scope.checkoutCtrl.checkout.minimum_order_value;

              // This is a logical flag that indicates if the checkout can proceed.
              // If after removal of problematic items, order.total will be fall below the minimum (which is custom for each order, depending on...)
              $scope.orderTotalWithoutCompatibleBelowMinimum = $scope.orderTotalWithoutIncompatible < $scope.orderMinTotal;

              $scope.checkoutShippingFormCtrl.allProductsCompatible = $scope.incompatibleLineItems.length == 0;
              $scope.checkoutShippingFormCtrl.orderTotalWithoutCompatibleBelowMinimum = $scope.orderTotalWithoutCompatibleBelowMinimum;

              $scope.destinationZipcode = address.zipcode;
            }, (e) => console.warn(e))
          }, (invalid) => {
            // Wait until it becomes valid
          })
        }

        // Public members

        /**
         * Remove cart items that cannot be shipped to the hub, linked
         * to the zipcode specified in the shipping form.
         *
         * @returns {*}
         */
        $scope.removeConflictingItems = function() {
          return $q((resolve, reject) => {
            // First we need to identify the zipcode's hub
            Hubs.getHubByZipcode($scope.destinationZipcode).then(hub => {
              // User OrderHelper to remove invalid items
              return OrderHelper.removeConflictingItems($scope.checkoutCtrl.checkout, hub.id).then(result => {
                // Reload the checkout and revalidate zipcode compatibility
                return $scope.checkoutCtrl.getCheckout().then((checkout) => {
                  validateZipcode();
                  resolve(checkout)
                });
              })
            })
          })
        };

        $scope.backToCart = function() {
          $timeout(() => { window.location.href = '/cart' }, 1);
        };

        // Initialize

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      scope: {
        incompatibleLineItems: '=?bind',
      },
      link: function($scope, $element, attributes) {

      },
      templateUrl: function() { return `/ng/templates/checkout/incompatible_products.html?locale=${window.I18n.locale}` }
    }
  });


