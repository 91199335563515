'use strict';

angular.module('app').controller('HeaderCtrl', ['$scope', '$rootScope', '$timeout', '$window', function ($scope, $rootScope, $timeout, $window) {
  // Listed for clicks on minified search field in the header, to expand it
  // $('#header-search-form input.form-control').on('focus', function(e) {
  //
  // });
  console.error("HeaderCtrl is depreceated, please switch to the farmyWebsiteHeader directive");
}

]);