'use strict';

/**
 * CONTENT SWITCHER DIRECTIVE (blame Miguel Entrena)
 * Instructions:
 *
 * Create a switcher component with the '.content-switcher' class and a 'switcher-target' attribute.
 * Can be any kind of list/selector, just needs the class 'switcher-option' and
 * set the value to the HTML ID (starting with '#') of the later content block
 * example:
 *
 *  .content-switcher(switcher-target="my-content-tag")
 *    %select.form-control(ng-model="selectedIndex")
 *      %option.switcher-option(value="#my-option1") My option 1
 *      %option.switcher-option(value="#my-option2") My option 2
 *      %option.switcher-option(value="#my-option3") My option 3
 *
 * Next create the '.content-blocks' wrapper anywhere on the view, with identical 'switcher-target' value.
 * '.content-block' child elements inside need to have the ID of the correspondent selector in the above switcher.
 * NOTE: actual content templates for each block are preferably set via 'ng-include' attr (see the example below).
 * example:
 *
 *  .content-blocks(switcher-tag="my-content-tag")
 *    #my-option1.content-block(ng-include="'/path/to/my/template1.html")
 *    #my-option2.content-block(ng-include="'/path/to/my/template2.html")
 *    #my-option3.content-block(ng-include="'/path/to/my/template3.html")
 */

import {trackPageView} from '../services/tracking-helper';

angular.module('app')
  .directive('contentSwitcher', function() {
    return {
      restrict: 'C',
      link: function(scope, element, attributes) {
        if (attributes.switcherUrlInteraction) scope.switcherUrlInteraction = true;

        var getDefaultIndex = function() {
          var defaultIndex = null;
          var defaultElement = element.find('option[selected=selected]')[0] || element.find('option:first')[0];
          if (scope.switcherUrlInteraction) {
            defaultIndex = window.location.hash.replace(/\//ig, '').replace('%2F', '') || window.localStorage.UserEditPageCtrl_activeTab || $(defaultElement).attr('value');
          }
          return defaultIndex || $(defaultElement).attr('value');
        };

        var getOptions = function() {
          var options = {};
          _(element.find('option')).each(function(option) {
            options[$(option).attr('value')] = $(option).text();
          });

          if (_(options).isEmpty()) {
            var reg = /'([^"]*)'/;

            _(element.find('.switcher-option')).each(function(option) {
              options[reg.exec($(option).attr('ng-click'))[0].replace(/'/g, "")] = $(option).find('.btn-text').text();
            });
          }

          return options
        };

        scope.options = getOptions();
        scope.optionsOrderedList = _(scope.options).keys();
        scope.switcherTag = attributes.switcherTag;
        scope.selectedIndex = attributes.switcherSelected ? '#' + attributes.switcherSelected.replace('#', '') : null;
        scope.selectedIndex = scope.optionsOrderedList.indexOf(scope.selectedIndex) > -1 ? scope.selectedIndex : getDefaultIndex();
        // console.log('scope.selectedIndex', scope.selectedIndex);
      },

      controller: ['$scope', '$location', function($scope, $location){
        $scope.eventName = 'content-switcher:' + $scope.switcherTag + ':selected';

        $scope.selectNext = function() {
          var nextIndex = $scope.optionsOrderedList.indexOf($scope.selectedIndex) + 1;
          if (nextIndex >= $scope.optionsOrderedList.length)
            nextIndex = 0;
          $scope.selectedIndex = $scope.optionsOrderedList[nextIndex];
        };

        $scope.selectPrevious = function() {
          var nextIndex = $scope.optionsOrderedList.indexOf($scope.selectedIndex) - 1;
          if (nextIndex < 0)
            nextIndex = $scope.optionsOrderedList.length - 1;
          $scope.selectedIndex = $scope.optionsOrderedList[nextIndex];
        };


        $scope.$watch('selectedIndex', function(){
          broadcastSwitchContent();
        });

        var broadcastSwitchContent = function() {
          var targetElementScope = $('.content-blocks[switcher-tag="' + $scope.switcherTag + '"]').scope();
          targetElementScope.$broadcast($scope.eventName, {selectedIndex: $scope.selectedIndex});

          if ($scope.switcherUrlInteraction) setUrlAndLocalStorage();
        };

        var setUrlAndLocalStorage = function() {
          window.localStorage.UserEditPageCtrl_activeTab = $scope.selectedIndex;
          // location.hash = $scope.selectedIndex.replace(/(\/|\#)/ig);
          // $location.path($scope.selectedIndex.replace(/(\/|\#)/ig, ''));
        };
      }]
    }
  })

  .directive('contentBlocks', function() {
    return {
      restrict: 'C',
      link: function(scope, element, attributes) {
        scope.switcherTag = attributes.switcherTag;
      },
      controller: ['$scope', '$element', function ($scope, $element) {
        $scope.eventName = 'content-switcher:' + $scope.switcherTag + ':selected';
        $scope.contentLoading = false;
        $scope.$on($scope.eventName, function(event, args) {
          $scope.selectedIndex = args.selectedIndex;
        });

        $scope.onContentLoaded = function() {
          trackPageView('account');
          $scope.contentLoading = false;
        };
      }]
    }
  })
  .directive('contentBlock', ['$animate', function($animate) {
    // This is a modified copy of the ng-if directive which will show the content of the block only if
    // the activeContentId scope variable is the same as the element id
    return {
      multiElement: true,
      transclude: 'element',
      priority: 600,
      terminal: true,
      restrict: 'C',
      $$tlb: true,
      link: function($scope, $element, $attr, ctrl, $transclude) {
        var block, childScope, previousElements;
        $scope.$watch('selectedIndex', function ngIfWatchAction(value, oldValue) { // comparing old/new values prevents correct initial behaviour.
          // IMPORTANT: This whole watcher function is copy/pasted from the original Angular 'ngIf' code.
          // The above watcher has been edited for the current purpose.
          // Also, the line below compares the value with the ID of the current DOM element.
          // The rest is mostly untouched.
          if (value && value == ('#' + $attr.id)) {
            $scope.contentLoading = true;
            if (!childScope) {
              $transclude(function(clone, newScope) {
                childScope = newScope;
                clone[clone.length++] = document.createComment('end contentBlock');
                // Note: We only need the first/last node of the cloned nodes.
                block = {
                  clone: clone
                };
                $animate.enter(clone, $element.parent(), $element);
              });
            }
          } else {
            if (previousElements) {
              previousElements.remove();
              previousElements = null;
            }
            if (childScope) {
              childScope.$destroy();
              childScope = null;
            }
            if (block) {
              previousElements = getBlockNodes(block.clone);
              $animate.leave(previousElements).then(function() {
                previousElements = null;
              });
              block = null;
            }
          }

          function getBlockNodes(nodes) {
            var node = nodes[0];
            var endNode = nodes[nodes.length - 1];
            var blockNodes;

            for (var i = 1; node !== endNode && (node = node.nextSibling); i++) {
              if (blockNodes || nodes[i] !== node) {
                if (!blockNodes) {
                  blockNodes = $(nodes.slice(0, i));
                }
                blockNodes.push(node);
              }
            }

            return blockNodes || nodes;
          }
        });
      }
    };
  }]);
