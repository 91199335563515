'use strict';

angular.module('app')
  .directive('dismissablePopover', function() {
    return {
      controller: ['$scope', '$element', '$timeout', function($scope, $element) {
        $scope.$watch(function() {
          let first = $($element.siblings('.popover:first')).first();
          return first && first.scope && first.scope()
        }, function(result) {
          if (result) {
            setGlobalClickWatcher()
          }
        });

        var closePopover = function (target) {
          let first = $(target.siblings('.popover:first')).first();

          if (first && first.scope()) {
            $(target.siblings('.popover:first')).first().hide();
          }
        };

        var setGlobalClickWatcher = function() {
          $(document).one('click', function () {
            try {
              closePopover($element)
            } catch(e) {
              console.warn(e);
            }
          })
        };
      }]
    }
  });
