import * as _ from 'underscore';
import * as moment from 'moment';
import {trackEvent, trackPageView} from '../services/tracking-helper';

'use strict';

angular.module('app').controller('CheckoutDeliveryTimeCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', '$element', 'CartData', 'Alerts', 'blockUI', 'Hubs', '$swipe', function ($scope, $sce, $http, $q, $rootScope, $timeout, $element, CartData, Alerts, blockUI, Hubs, $swipe) {
  window.CheckoutDeliveryTimeCtrl = $scope;

  // Public properties

  $scope.checkoutCtrl = $scope.$parent.$parent;
  $scope.Hubs = Hubs;
  $scope.needsRinger = false;
  /**
   * A structure, containing checkoutCtrl.availableDeliverySlots grouped by dates
   * @type {null}
   */
  $scope.slotsByDate = null;

  /**
   * Array of dates with available slots for the ng-repeater of the slot selector
   * @type {null}
   */
  $scope.slotDates = null;

  $scope.slotDays = null;

  $scope.selectedSlot = null;

  $scope.nowDate = new Date();

  //
  // Private members
  //

  // Some UI layout helpers
  const SLOT_ELEMENT_WIDTH = 160;
  const SLOT_ELEMENT_MARGINS = 16;

  function loadDeliverySlotsIfNecessary() {
    if ($scope.checkoutCtrl.checkout &&
      !$scope.checkoutCtrl.isCheckoutStageBefore("delivery_time") &&
      !$scope.checkoutCtrl.loadingDeliverySlots) {
      $scope.checkoutCtrl.getDeliverySlots().then(r => afterDeliverySlotLoad());
    }
  }

  function afterDeliverySlotLoad() {
    transformSlots();
    setSwipeBinding();
    $scope.selectedSlot = null;
    $scope.needsRingerCheck();
  }

  function constructor() {
    // Load delivery slots if we're already past the delivery_time stage (e.g. in payment) and the delivery slot has already been set.
    $scope.$watch("checkoutCtrl.stage", function(newValue) {
      if ($scope.checkoutCtrl.availableDeliverySlots == null) {
        loadDeliverySlotsIfNecessary();
      }

      if (newValue === "delivery_time")
        trackPageView("checkout", {products: $scope.checkoutCtrl.checkout?.line_items, checkout: $scope.checkoutCtrl.checkout});
    });

    // $rootScope.$on("cart:changed", (event, payload) => loadDeliverySlotsIfNecessary(event, payload));

    // Watch changes to source available slots, and transform the array
    // into a number of data-structures needed for the delivery slot selector to work
    $scope.$watch("checkoutCtrl.availableDeliverySlots", function(newValue, oldValue) {
      if (newValue && !_.isEqual(newValue, oldValue)) {
        afterDeliverySlotLoad();
      }
    }, true);

    $scope.$watch('checkoutCtrl.checkout.delivery_slot_id', function(newValue, oldValue) {
      if (newValue) {
        $scope.selectedSlot = _.find($scope.checkoutCtrl.availableDeliverySlots, s => s.id == newValue);
        // If we're past the delivery time stage, set this slot as checkout's active slot
        if ($scope.checkoutCtrl.isCheckoutStageAfter('delivery_time')) {
          $scope.checkoutCtrl.deliverySlot = $scope.selectedSlot;
        }
        $scope.needsRingerCheck();
      }
    });

    $scope.$watch('slotDates', function(newValue, oldValue) {
      if (newValue && $scope.selectedSlot == null && $scope.checkoutCtrl.checkout.delivery_slot_id) {
        $scope.selectedSlot = _.find($scope.checkoutCtrl.availableDeliverySlots, s => s.id == $scope.checkoutCtrl.checkout.delivery_slot_id);
        $scope.selectSlot($scope.selectedSlot);

        // If we're past the delivery time stage, set this slot as checkout's active slot
        if ($scope.checkoutCtrl.isCheckoutStageAfter('delivery_time')) {
          $scope.checkoutCtrl.deliverySlot = $scope.selectedSlot;
        }
      }

      if (newValue && newValue.length > 0 && $scope.checkoutCtrl.stage == 'delivery_time') $timeout(setDatesHeight, 1000);
    });
  }

  function setSwipeBinding() {
    if (!$('.data-selector').is(':visible')) return;
    if ($._data($('.data-selector')[0], 'events')) return;

    var element = angular.element('.data-selector');
    var unbind = $swipe.bind(element, {
      'start': function(c) {
        $scope.removeShadowOverlay();
        element.unbind('mousedown mousemove mouseup touchstart touchmove touchend touchcancel');
      }}, ['mouse', 'touch']);
  }

  function setDatesHeight() {
    if ($scope.checkoutCtrl.stage == 'delivery_time') {
      var slots = $('.delivery-day');
      var maxHeight = _(_(slots).map(function(e) { return $(e).height() })).max();

      slots.height(maxHeight);
    }
  }

  function destructor() {
    window.CheckoutDeliveryTimeCtrl = null;
  }

  /**
   * Reads the available delivery slots array and converts it
   * into a structure grouped by slot dates
   */
  function transformSlots() {
    $scope.slotsByDate = _.groupBy($scope.checkoutCtrl.availableDeliverySlots, (slot) => moment(slot.delivery_time).locale(I18n.locale).format("Do ddd"));
    $scope.slotDays = _.map($scope.slotsByDate, (slots, key) => key);
    $scope.slotDates = _.map($scope.slotsByDate, (slots, key) => moment(slots[0].delivery_time).startOf('day').toDate());

    $element.find(".inner-scroll").css({width: `${$scope.slotDates.length * (SLOT_ELEMENT_WIDTH + SLOT_ELEMENT_MARGINS)}px`});
  }

  function getUnfulfillableProducts() {
    var productIds = _.uniq(_($scope.selectedSlot.problem_line_items).map(i => i.product_id))

    return $q((resolve, reject) => {
      if (productIds.length == 0) {
        resolve([]);
        return
      }

      $http.get(`/api/products.json?ids=${productIds.join(',')}&per_page=100&template=product_in_catalog`).then((response) => {
        $scope.unfulfillableProducts = response.data.products
        resolve($scope.unfulfillableProducts)
      }, (e) => reject(e))
    })
  }

  function selectSlotById(id) {
    var slot = _.find($scope.checkoutCtrl.availableDeliverySlots, (s) => s.id == id)

    if (slot == null) throw new Error(`Slot not found with id: ${id}`)

    $scope.selectSlot(slot)
  }

  //
  // Public methods
  //

  /**
   * Tries to remove products that generate date limitations for certain delivery
   * slots and reloads the page to display new delivery slot options
   */
  $scope.removeUnfulfillableProducts = function() {
    // Get ids of unfulfillable products from the current slot:
    var params = {
      product_ids: _.uniq(_($scope.selectedSlot.problem_line_items).map(i => i.product_id))
    };

    blockUI.start();

    return $q(function(resolve, reject) {
      return $http.post(sprintf('/api/checkouts/%s/remove_unfulfillable_products.json', $scope.order.number), params).then(function() {
        // Reload checkout and delivery slots
        $scope.checkoutCtrl.getCheckout().then(() => {
          if (window.CartData) window.CartData.load().then(() => {
            $scope.checkoutCtrl.getDeliverySlots().then(() => {
              transformSlots();

              // Re-select previously selected slot, if any:
              if ($scope.selectedSlot)
                selectSlotById($scope.selectedSlot.id)

              blockUI.stop();
              resolve()
            }, (e) => {
              blockUI.stop();
              reject(e)
            })
          }, () => {
            blockUI.stop()
          });
        }, (e) => {
          blockUI.stop()
          reject(e)
        })
      }, (e) => {
        blockUI.stop()
        Alerts.error(errorMessage(e))
        reject(e)
      })
    })
  };

  $scope.selectSlot = function(slot) {
    if (slot) {
      if (!slot.level2_capacity_reached) {
        $scope.selectedSlot = slot;
        $scope.selectedSlotValid = true;
        $scope.currentSelectedDate = slot.delivery_time;
        $scope.needsRingerCheck();
        if ($scope.selectedSlot.problem_line_items && $scope.selectedSlot.problem_line_items.length > 0) {
          getUnfulfillableProducts()
          $scope.selectedSlotValid = false;
        }
      }
    } else {
      $scope.selectedSlotValid = false;
      $scope.selectedSlot = null;
      $scope.currentSelectedDate = null;
    }
  };

  $scope.submitDeliveryTime = function() {
    return $q(function(resolve, reject) {
      if ($scope.selectedSlot.issues && $scope.selectedSlot.issues.length > 0) {
        Alerts.error(I18n.t(`javascript.errors.checkout.${$scope.selectedSlot.issues[0]}`));
        return reject($scope.selectedSlot.issues);
      }

      $scope.submitting = true;

      blockUI.start();

      $scope.checkoutCtrl.updateCheckoutDeliverySlot($scope.selectedSlot).then(function(result) {
        // Update the checkout, but do not move the state if we're already beyond the 'delivery' state
        return $scope.checkoutCtrl.updateCheckout({
          delivery_slot_id: $scope.selectedSlot.id,
          allow_morning_ringer: $scope.checkoutCtrl.checkout.allow_morning_ringer,
                                          ///
          include_recommend_step: false  // IMPORTANT: Temporarily disabled the recommendation step, until its implemented
                                        ///
        }, !$scope.isCheckoutStateBefore('payment')).then(function(result) {
          if (window.CartData)
            window.CartData.load(); // reload totals

          trackEvent(
            'add_shipping_time',
            {
              products: result.line_items,
              checkout: result
            });
          $timeout(() => window.scrollToElement('#deliveryTimeSummary'), 500)
          return $scope.updateStage().then(r => resolve(result))
        })
      }, (e) => reject(e)).finally(() => {
        blockUI.stop();
        $scope.submitting = false;
      })
    })
  };

  //
  // Debug features
  //
  $scope.onAdminToggleSlotCapacityClick = function($event) {
    if ($scope.showSlotCapacity === undefined) $scope.showSlotCapacity = false;
    $scope.showSlotCapacity = !$scope.showSlotCapacity;
  };

  // $scope.scrollForthSelector = function() {
  //   var element = $('.data-selector');
  //   $(element).scrollLeft($(element).innerWidth());
  // };

  // $scope.scrollBackSelector = function() {
  //   $('.data-selector').animate({scrollLeft: 0}, 500)
  // };

  $scope.removeShadowOverlay = function() {
    var element = $('.shadow-overlay');

    element.addClass('invisible');
    $timeout(function() {
      element.remove();
    }, 500)
  };

  $scope.needsRingerCheck = function (f) {
    $scope.needsRinger = $scope.checkoutCtrl?.shipAddress && $scope.checkoutCtrl.shipAddress?.marketing_zone == "C" && !$scope.selectedSlot?.tamedia_zone && $scope.selectedSlot?.early_morning_delivery;
  };

  // Initialize

  $scope.$on('$destroy', destructor)
  constructor()
}]);
