'use strict';

angular.module('app').controller('CheckoutPaymentCreditCardDtCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', '$element', 'DatatransHelper', 'Alerts', 'blockUI',
                                                                  function ($scope,   $sce,   $http,   $q,   $rootScope,   $timeout,   $element,   DatatransHelper,   Alerts,   blockUI) {
  window.CheckoutPaymentCreditCardDtCtrl = $scope;

  // Public properties

  $scope.checkoutCtrl = window.CheckoutCtrl;
  $scope.paymentCtrl = $scope.$parent.$parent;
  $scope.deferredSettlement = true;


  /**
   * If true, the card will be saved as a user's preferred card on the server
   *
   * @type {boolean}
   */
  $scope.saveCardOnSubmit = true;

  //
  // Private members
  //

  function constructor() {
    if ($scope.checkoutCtrl.userProfile && $scope.checkoutCtrl.userProfile.credit_cards && $scope.checkoutCtrl.userProfile.credit_cards.length > 0) {
      // Use saved cards, just display the form
    } else {
      // No saved cards: automatically initiate the payment
      $scope.initiatePayment();
    }
  }

  function destructor() {
    window.CheckoutPaymentCreditCardDtCtrl = null;
  }

  function onDatatransPopupClosed() {
    if (Rails.env == 'development') console.log("onDatatransPopupClosed");
  }

  //
  // Public methods
  //

  $scope.initiatePayment = function() {
    let amount = parseFloat($scope.checkoutCtrl.checkout.total_including_surcharges);
    if ($scope.checkoutCtrl.checkout.payment_total) amount -= parseFloat($scope.checkoutCtrl.checkout.payment_total);

    $scope.payment = {
      amount: amount,
      currency: $scope.checkoutCtrl.checkout.currency,
      identifier: $scope.checkoutCtrl.checkout.id,
      order_id: $scope.checkoutCtrl.checkout.id,
      order_token: $scope.checkoutCtrl.checkout.token
    };

    if ($scope.card != null && $scope.card.pseudo_pan != null) {
      $scope.payment.aliasCard = { pan: $scope.card.pseudo_pan, year: $scope.card.year, month: $scope.card.month, type: $scope.card.cc_type }
    }

    $scope.checkoutCtrl.trackAddPaymentInfo($scope.paymentCtrl.selectedPaymentMethod.name);

    DatatransHelper.initiatePayment($scope.payment, {
      mode: 'lightbox',
      paymentmethod: 'VIS,ECA,AMX',
      rememberMe: true,
      deferredSettlement: $scope.deferredSettlement,
      success_url: `${window.settingsSiteUrl}/orders/${$scope.checkoutCtrl.checkout.number}/thankyou?order_token=${$scope.checkoutCtrl.checkout.token}`,
      error_url: `${window.settingsSiteUrl}/payments/${$scope.checkoutCtrl.checkout.number}/order_status?order_token=${$scope.checkoutCtrl.checkout.token}`,
      cancel_url: `${window.settingsSiteUrl}/payments/${$scope.checkoutCtrl.checkout.number}/order_status?order_token=${$scope.checkoutCtrl.checkout.token}`,
      onClosed: onDatatransPopupClosed
    });
  };

  // Initialize

  $scope.$on('$destroy', destructor);
  constructor();
}]);
