'use strict';

angular.module('app').controller('OrderRemovableAdjustmentsCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, Alerts) {
    console.log("OrderRemovableAdjustmentsCtrl controller initialized with scope");

    $scope.removableAdjustments = null;

    $scope.loadRemovableAdjustments = function() {
      return $http.get('/api/frontend/orders/removable_adjustments.json').then(function(response) {
        $scope.removableAdjustments = response.data.removable_adjustments;
      });
    };

    $scope.removeAdjustment = function(adjustment) {
      adjustment.isUpdating = true;

      return $http.post('/api/frontend/orders/' + adjustment.order.id.toString() + '/remove_adjustment.json', { adjustment_id: adjustment.id }).then(function(response) {
        $scope.removableAdjustments = response.data.removable_adjustments;
      }).finally(function() {
        if (adjustment)
          adjustment.isUpdating = false;
      });
    }

    $scope.loadRemovableAdjustments();
  }
]);
