'use strict';

/**
 * Controls the popup header frame with rich search form
 *
 * Template: spree/shared/header/_branding_header
 */
angular.module('app').controller('NavRichSearchCtrl', ['$scope', '$rootScope', '$location', '$window', '$timeout', '$localStorage', 'Alerts',
  function ($scope, $rootScope, $location, $window, $timeout, $localStorage, Alerts) {
    window.NavRichSearchCtrl = $scope;
    $scope.expanded = false;

    $scope.expand = function() {
      $scope.buttonsDisabled = true;

      $('#nav-container').hide();
      $('#nav-search-container').fadeIn();
      $('#search-grey-background').fadeIn();

      $timeout(function() {
        $('#nav-search-container input.form-control').focus();
      }, 150);

      $timeout(function() {
        $scope.buttonsDisabled = false;
      }, 500);

      $scope.expanded = true;
    };

    $scope.collapse = function() {
      $('#nav-search-container').hide();
      $('#search-grey-background').hide();
      $('#nav-container').fadeIn();

      $scope.expanded = false;
      $rootScope.$broadcast('search:close');
      Tracking.sendSearchEvent('headerCollapsed');
    };

    $rootScope.$on('search:shortcut:function', function(e) {
      if (!$scope.expanded) {
        $scope.expand();
        Tracking.sendSearchEvent('headerExpanded');
      }
    });
    $rootScope.$on('search:dropdown:close', function(e) {
      if ($scope.expanded) $scope.collapse();
    });
    $scope.$on('$destroy', function() {
      window.NavRichSearchCtrl = null;
    });
  }
]);
