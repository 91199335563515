import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

/**
 * Watches notifications from the Notifications service
 * and displays an ordered queue of messages
 *
 * Markup example (restricted to 'element' only):
 *  <farmy-notifications-dock></farmy-notifications-dock>
 *
 */
angular.module('app')
  .directive('farmyNotification', function() {
    return {
      restrict: 'E',
      scope: {notification: '='},
      controller: ['$scope', '$timeout', '$element', 'FarmyNotifications', 'UserNotifications', '$sce', '$templateCache', '$http', '$q', function ($scope, $timeout, $element, FarmyNotifications, UserNotifications, $sce, $templateCache, $http, $q) {
        $scope.templateCache = $templateCache;
        function constructor() {
          // covid update: since there are temporarily two versions of the eggs_earned notification, we need to bypass the cached template.
          let cacheFixPrepend = $scope.notification && $scope.notification.style == 'eggs_earned' ? `&${(new Date).getTime()}` : '';
          $scope.templateUrl = `/ng/templates/farmy_notifications/notification_${$scope.notification.style}.html?locale=${window.I18n.locale}${cacheFixPrepend}`;
          var triggered = $scope.$watch('notification.current', function(newVal, oldVal) {
            if($scope.notification.current) {
              if (!$scope.notification.visible) {
                // triggered();
                $timeout($scope.trigger, 200)
              }
            } else if (newVal != oldVal) {
              $scope.hide();
            }
          }, true)
        }

        function destructor() {
          window.farmyNotification = null;
        }

        $scope.trigger = function() {
          let delay
          $scope.notification.visible = true;
          $element.addClass('notification-visible');

          if ($scope.notification.delay && $scope.notification.delay > 100)
            delay = $scope.notification.delay;
          else {
            // $templateCache is needed to ensure template load after site locale is changed.
            let isTemplateLoaded = angular.isString($scope.templateCache.get($scope.templateUrl));
            delay = isTemplateLoaded ? 100 : 500;
          }

          $timeout(function() {
            $element.find('.notification').addClass('notification-visible');
            if ($scope.notification.id) UserNotifications.setDelivered($scope.notification);
          }, delay);

          if ($scope.notification.timeout && $scope.notification.timeout > 0) {
            $timeout(function() {
              $scope.dismiss();
            }, $scope.notification.timeout)
          }
        };

        $scope.dismiss = function() {
          $scope.hide().then(function() {
            FarmyNotifications.remove($scope.notification)
          });
        };

        $scope.hide = function() {
          return $q(function(resolve, reject) {
            $($element).find('.notification').removeClass('notification-visible');
            $timeout(function() {
              $($element).removeClass('notification-visible');
              resolve()
            }, 200)
          });
        };

        // Initialize

        $scope.$on('$destroy', destructor);
        constructor();
      }],

      link: function($scope, $element, attributes) {

      },

      // templateUrl: `notification-template-${$scope.notification.style}`

      template: `
        <div farmy-ng-include="templateUrl" class="notification-wrapper" ng-class="{'modal-mode': notification.modal}">
        </div>`
    }
  });


