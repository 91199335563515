'use strict';

angular.module('app').controller('OrderSuggestionsViewCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$timeout', '$compile', '$sce', '$http', '$element', '$localStorage', '$uibModal', 'Alerts', 'blockUI',
  function ($scope, $rootScope, $location, $window, $interval, $timeout, $compile, $sce, $http, $element, $localStorage, $uibModal, Alerts, blockUI) {

    $scope.orderSuggestion = null;
    $scope.order = null; // This is a reference to $scope.orderSuggestion.order (simply a shortcut)
    $scope.deliverySlots = null; // This is a reference to $scope.orderSuggestion.delivery_slots (simply a shortcut)
    $scope.isCreating = false;
    $scope.isLoading = false;

    window.OrderSuggestionsViewCtrl = $scope;

    $scope.loadOrderSuggestion = function() {
      $scope.isLoading = true;
      CartData.stopUpdating();

      $http.get(sprintf('/api/frontend/order_suggestions/%d.json', $scope.orderSuggestionId)).then(
        function(response) {
          setOrderSuggestion(response.data.order_suggestion);
        },
        function(response) {
          Alerts.error($translate.instant("errors.unknown_error"));
        })
        .finally(function() {
          $scope.isLoading = false;
          CartData.startUpdating();
        });
    };

    $scope.createOrderSuggestion = function() {
      $scope.isCreating = true;
      CartData.stopUpdating();

      $http.post(apiUrl("/order_suggestions.json")).then(
        function(response) {
          setOrderSuggestion(response.data.order_suggestion);
        },
        function(response) {
          Alerts.error($translate.instant("errors.unknown_error"));
        })
        .finally(function(response) {
          $scope.isCreating = false;
          CartData.startUpdating();
        });
    };

    $scope.setDeliverySlot = function(slotId) {
      if ($scope.isUpdatingDeliverySlot)
        return false;

      window.Tracking.sendOrderSuggestionEvent('deliverySlot', 'change');

      if ($scope.order.delivery_slot == null) $scope.order.delivery_slot = {};
      $scope.order.delivery_slot.id = slotId;
    };

    $scope.scrollTo = function(sectionSelector) {
      $('html, body').animate({ scrollTop: $(sectionSelector).offset().top - 100 }, 500)

      window.Tracking.sendOrderSuggestionEvent('scrollTo', sectionSelector.replace(/\#|\./ig, ''));
    };

    $scope.checkoutToPayment = function() {
      window.Tracking.sendOrderSuggestionEvent('checkout', 'to-payment');
      redirectToCheckout("payment");
    };

    $scope.checkoutToDelivery = function() {
      window.Tracking.sendOrderSuggestionEvent('checkout', 'to-delivery');
      redirectToCheckout("delivery");
    };

    $scope.checkoutToAddress = function() {
      window.Tracking.sendOrderSuggestionEvent('checkout', 'to-address');
      redirectToCheckout("address");
    };

    $scope.updateLayout = function() {
      $element.find('.jumbotron').css({"min-height": $element.find('.jumbotron > .container').height() + 95 + 80 });
    };

    $scope.checkoutAllowed = function() {
      return $scope.order && ($scope.order.total >= $scope.minimumOrderValue);
    };

    $scope.showNextExtraProducts = function () {
      $scope.extraProductCurrentPage = $scope.extraProductCurrentPage + 1;

      if ($scope.extraProductCurrentPage == 1)
        window.Tracking.sendOrderSuggestionEvent('extraProducts', 'requested');
      else
        window.Tracking.sendOrderSuggestionEvent('extraProducts', 'more-requested');
    };

    $scope.nextExtraProductsAvailable = function () {
      if ($scope.extraProductCurrentPage == null || $scope.pagedExtraProducts == null || $scope.pagedExtraProducts.length < 1) {
        window.Tracking.sendOrderSuggestionEvent('extraProducts', 'no-more-available');
        return false;
      }

      return $scope.extraProductCurrentPage < ($scope.pagedExtraProducts.length - 1);
    };

    $scope.leaveOrderSuggestionPage = function(event, place) {
      window.Tracking.sendOrderSuggestionEvent('leftPage', place);

      if (event.currentTarget)
        $(event.currentTarget).attr('disabled', true);

      $timeout(function() {
        window.location.href = '/';
      }, 300);
    };

    // Private members:
    var VimeoPlayer;

    function redirectToCheckout(state) {
      var url = localizeUrl(sprintf('/order_suggestions/%d/checkout?checkout_state=%s', $scope.orderSuggestion.id, state));
      window.location.href = url;
    };

    // function fitText() {
    //   var addressElement = $element.find(".detail-container .address");
    //   addressElement.textfill({ maxFontPixels: parseInt(addressElement.css("fontSize")) });
    // }

    function addPagedExtraProducts() {
      $scope.extraProductCurrentPage = 0;
      var perPage = 5;

      var actualExtraProducts = _.filter($scope.orderSuggestion.extra_suggested_products, function(product) {
        return !window.CartData.containsProduct(product.id);
      });

      $scope.pagedExtraProducts = _.chain(actualExtraProducts)
        .groupBy(function(element, index) { return Math.floor(index/perPage);}).toArray().value();
    }

    function initializeVideoPlayerAPI() {
      safely(function() {
        $scope.videoPlayer = new VimeoPlayer($(".video-wrapper .video")[0]);

        $scope.videoPlayer.on('play', function() {
          window.Tracking.sendOrderSuggestionEvent('introVideo', 'played');
        });

        $scope.videoPlayer.on('pause', function() {
          window.Tracking.sendOrderSuggestionEvent('introVideo', 'paused');
        });

        $scope.videoPlayer.on('error', function() {
          window.Tracking.sendOrderSuggestionEvent('introVideo', 'error');
        });

        $scope.videoPlayer.on('ended', function() {
          window.Tracking.sendOrderSuggestionEvent('introVideo', 'ended');
        });
      });
    }

    function setOrderSuggestion(orderSuggestion) {
      $scope.orderSuggestion = orderSuggestion;
      $scope.orderSuggestionId = $scope.orderSuggestion.id;
      $scope.order = $scope.orderSuggestion.order;

      CartData.setCurrentOrder($scope.order);

      $scope.deliverySlots = $scope.orderSuggestion.delivery_slots;

      addPagedExtraProducts();

      // Establish a moment alias, to enable weekday watching from the template
      _.each($scope.deliverySlots, function(slot) {
        slot.delivery_moment = moment(slot.delivery_time);
      });

      $timeout(function() {
        $scope.updateLayout();
      }, 10);
    }

    function playVideo() {
      safely(function() {
        if ($scope.videoPlayer) { $scope.videoPlayer.play(); }
      });
    }

    function pauseVideo() {
      safely(function() {
        if ($scope.videoPlayer) { $scope.videoPlayer.pause(); }
      });
    }

    function autoPlayVideo() {
      safely(function() {
        if (window.isMobile) { return; }

        if ($scope.completeOrderSuggestionsCount != null && $scope.completeOrderSuggestionsCount < 1) {
          playVideo();
        }
      });
    }

    function initialize() {
      $scope.orderSuggestionId = $element.data("order-suggestion-id");
      $scope.completeOrderSuggestionsCount = $element.data("complete-order-suggestions-count");

      import(/* webpackChunkName: "vimeoPlayer" */ 'ng-frontend/lazy-modules/vimeo-player.js').then((vimeoPlayerClass) => {
        VimeoPlayer = vimeoPlayerClass;
        initializeVideoPlayerAPI();
        autoPlayVideo();
      });

      if ($scope.orderSuggestionId == null) {
        $scope.createOrderSuggestion();
      } else {
        $scope.loadOrderSuggestion();
      }
    }

    // Watchers:

    $scope.$on('$destroy', function() {
      window.OrderSuggestionsViewCtrl = null;
    });

    $scope.$watch('order.delivery_slot.id', function(deliverySlotId, oldValue) {
      if (deliverySlotId != null && oldValue != null && deliverySlotId != oldValue) {
        $scope.isUpdatingDeliverySlot = true;

        blockUI.start($translate.instant('messages.please_wait_while_loading'));

        $http.put(sprintf('/api/orders/%s/update_delivery_slot.json', $scope.order.number), {
          'id': $scope.order.number,
          'order_token': $scope.order.token,
          'order': { 'delivery_slot_id': deliverySlotId }}).then(function(response) {
        }, function(error) {
          window.Tracking.sendOrderSuggestionEvent('deliverySlot', 'change-error');
          Alerts.error(errorMessage(error));
        }).finally(function() {
          $scope.isUpdatingDeliverySlot = false;
          blockUI.stop();
        });
      }
    });

    $rootScope.$on('cart-ctrl:updated', function(event, order) {
      $scope.order.total = order.total;
    });

    initialize();
  }
]);
