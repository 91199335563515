/**
 * Product Data Parser.
 * We'll be filling this file with new helper actions over product data.
 */

// Returns the variant's collection with a new `displayPrice` attribute.
// Reacts to product/user Farmy Family conditions.
const setVariantDisplayPrices = (product, user) => {
  if (!product?.variants?.length) return product?.variants;

  let variants;
  const ffSubscriber = user?.ff_subscriber;

  if (product?.eligible_for_ffamily && product?.strikeout_price) {
    variants = product.variants.map(variant => {
      let displayPrice = ffSubscriber ? variant.price : variant.strikeout_price;
      return {...variant, displayPrice};
    })
  } else {
    variants = product.variants.map (variant => ({...variant, displayPrice: variant.price}))
  }

  return variants;
};

const getVariantDisplayPrice = (variant, product, user) => {
  if (!product?.eligible_for_ffamily) return variant.price;
  if (!variant.strikeout_price) return variant.price;
  const ffSubscriber = user?.ff_subscriber;

  // console.log('getVariantDisplayPrice', {displayPrice: ffSubscriber ? variant.price : variant.strikeout_price, variant, product, user});

  return ffSubscriber ? variant.price : variant.strikeout_price;
};

const getFilteredCertificates = (certificates, filterBaseCodes = null, inclusive = true) => {
  if (!filterBaseCodes || !Array.isArray(filterBaseCodes)) return certificates;

  return certificates.filter(certificate => {
    if (inclusive) {
      return filterBaseCodes.includes(certificate.code);
    } else {
      return !filterBaseCodes.includes(certificate.code);
    }
  });
};

export {setVariantDisplayPrices, getVariantDisplayPrice, getFilteredCertificates};
