'use strict';

angular.module('app')
  .directive('reloadOnHubsChange', ['$rootScope', '$location', 'Hubs', function($rootScope, $location, Hubs) {
    return {
      link: function(scope, element, attributes) {
        let unOnHubsChange = $rootScope.$on('hubs:changed', function(event, options) {
          $location.search(angular.extend($location.search(), { hub_id: Hubs.currentHub && Hubs.currentHub.id }));
          NgFrontendAppCtrl.updateFarmyNgView(false)
        });

        scope.$on('$destroy', () => {
          if (unOnHubsChange)
            unOnHubsChange();
        })
      }
    }
  }]);
