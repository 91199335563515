/**
 * Promised element allows creating a promise that will be resolved as soon as the
 * element with specified query appears in the DOM tree and is visible in the screen.
 *
 * Example:
 *
 * PromisedElementVisible.promise($element, '.component-child').then((r) => {
 *  r.get(0); // 100%-guaranteed .component-child within $element
 * }, (e) => {
 *  // timeout error, no element still after a few seconds
 * });
 */
angular.module('app').service('PromisedElementVisible', ['$q', '$interval', '$timeout', ($q, $interval, $timeout) => {
  var $scope = this || {};

  $scope.promise = function($parentElement, cssQuery, options) {
    var delay = 100;
    var maxDuration = (options && options.timeout) ? options.timeout : 5000;
    var duration = 0;

    return $q(function(resolve, reject) {
      var currentResult = $parentElement.find(cssQuery)
      if (currentResult.length > 0 && currentResult.is(':visible')) {
        resolve(currentResult);
      } else {
        $timeout(() => {
          duration += delay;

          if (duration < maxDuration) {
            $scope.promise($parentElement, cssQuery).then((r) => resolve(r), (e) => reject(e));
          } else {
            console.warn(`PromisedElementVisible timeout on query ${cssQuery}`);
            reject({error: 'timeout'});
          }
        }, 100);
      }
    });
  };

  return $scope;
}]);