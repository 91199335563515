'use strict';

angular.module('app').directive('googleStructuredData', () => {
  return {
    scope: {
      product: '@'
    },

    link: function(scope, $element, $attrs) {
    },

    controller: ['$rootScope', '$scope', '$sce', '$element', '$http', '$q', function($rootScope, $scope, $sce, $element, $http, $q) {
      $scope.productRatings = null;

      function getProductRatings(product) {
        return $q(function (resolve, reject) {
          // return unchanged if the same ratings are already stored
          if ($scope.productRatings && $scope.productRatings.product_id && product.id && $scope.productRatings.product_id.toString() == product.id.toString())
            resolve($scope.productRatings);
          else {
            $http.get(`api/frontend/product_ratings.json?id=${product.id}`).then((response) => {
              $scope.productRatings = response.data;
              resolve($scope.productRatings)
            }, (e) => {
              reject(e)
            })
          }
        })
      }

      function singleProductJson(product) {
        return $q(function(resolve, reject) {
          if (!product) resolve(null);
          else
            // We'll continue generating the JSON no matter getProductRatings result.
            getProductRatings(product).finally(() => {
              let availability = product.available_to_add_to_cart ? "http://schema.org/InStock" : "http://schema.org/OutOfStock";

              let jsonResponse = {
                "@context": "http://schema.org/",
                "@type": "Product",
                "name": product.name,
                "image": product.image_xl,
                "description": product.description,
                "brand": product.supplier.name,
                "category": `${location.protocol}//${location.host}${product.sub_category_url}`, // <!-- Take the next Category, where the Product is inside, f.e. here the category "wurst" not "fleisch" -->
                "sku": product.sku ? product.sku.toString() : product.id.toString(), //<!-- Product-ID -->
                "offers": {
                  "@type": "Offer",
                  "priceCurrency": defaultCurrency,
                  "price": product.price.toString(),
                  "priceValidUntil": "2019-12-31", // TODO: Ask where is this date stored --> Just when its an special offer
                  "availability": availability,
                  "itemCondition": "http://schema.org/NewCondition",
                  "url":`${location.protocol}//${location.host}${product.seo_url}`,
                },

                // TODO Mike: implement this block --> no api endpoint for this yet
                // but instead generated server-side at 'product_page.html.haml'
                // "isRelatedTo": [
                //   "https://www.farmy.ch/albisgutli-schuetzenwurst-keller",   // <!-- Products from the section -> https://gyazo.com/a71f623bd7bfe3e1ff1e6b71b1030f86 -->
                //   "https://www.farmy.ch/friesenberger-buurewuerstli-keller"
                // ],
              };

              // If a '.g' or 'kg' weight property exists...
              if (product.weight && product.weight_units && ['kg', 'g.'].indexOf(product.weight_units) > -1) {
                let unitText = product.weight_units == 'kg' ? 'KGM' : 'GRM'

                let weightProperty = {
                  "weight": {
                    "@type": "QuantitativeValue",
                    "unitText": unitText,
                    "value": product.weight.toString(),
                  }
                };

                angular.extend(jsonResponse, weightProperty)
              }

              if ($scope.productRatings && $scope.productRatings.average_rating) {
                let jsonRatings = {
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": $scope.productRatings.average_rating.toString(),
                    "bestRating": $scope.productRatings.best_rating.toString(),
                    "worstRating": $scope.productRatings.worst_rating.toString(),
                    "ratingCount": $scope.productRatings.rating_count.toString(),
                    "reviewCount": $scope.productRatings.review_count.toString(),
                  },
                  "reviews": _($scope.productRatings.ratings).map((rating) => {
                    return {
                      "@context": "http://schema.org/",
                      "@type": "Review",
                      "name": rating.title,
                      "reviewBody": rating.body,
                      "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": rating.rating.toString(),
                        "bestRating": "5",
                        "worstRating": "1",
                      },
                      "datePublished": moment(rating.created_at).format('YYYY-mm-DD'),
                      "author": {"@type": "Person", "name": rating.user_name},
                    }
                  }),
                };

                angular.extend(jsonResponse, jsonRatings);
              }

              resolve(jsonResponse)
            });
        });
      }

      $scope.generateSingleProductJsonData = function(product) {
        singleProductJson(product).then((result) => {
          $scope.jsonData = result ? $sce.trustAsHtml(angular.toJson(result)) : null
        })
      };

      $rootScope.$on('productPage:productLoaded', (event, product) => {
        $scope.generateSingleProductJsonData(product);
      });

      $rootScope.$on('productPage:dismissed', () => $scope.generateSingleProductJsonData());

      $rootScope.$on('productModalView:productLoaded', (event, product) => {
        $scope.generateSingleProductJsonData(product);
      });

      $rootScope.$on('productModalView:dismissed', () => $scope.generateSingleProductJsonData());
    }],

    template: `<script type="application/ld+json" ng-bind-html="jsonData"></script>`
  }
});
