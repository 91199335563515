import * as _ from 'underscore';
import * as sprintf from 'sprintf';

var ProductImpressions = {
  initialize: function(el) {
    if (!el) { el = $(window) };

    var scope = this;
    var debouncedUpdate = _.debounce(function() { scope.update(); }, 2500);

    el.scroll(function() {
      debouncedUpdate();
    });
  },

  update: function() {
    var scope = this;

    safely(function() {
      var elements = scope.collect();

      if (_.any(elements)) {
        var impressions = scope.buildImpressions(elements);
        scope.sendToGoogle(impressions);
        scope.markAsSent(elements);
      }
    });
  },

  // private

  collect: function() {
    return $("[data-google-analytics-product]:not([data-google-analytics-impression-is-sent]):in-viewport");
  },

  markAsSent: function(elements) {
    _.each(elements, function(el) {
      $(el).attr("data-google-analytics-impression-is-sent", "1");
    })
  },

  buildImpressions: function(elements) {
    var scope = this;

    return _.map(elements, function(el) {
      return scope.buildImpression(el);
    })
  },

  buildImpression: function(el) {
    el = $(el);
    var googleAnalyticsProduct = el.data("google-analytics-product");
    var position = this.getPosition(el);
    var listName = this.getListName(el);

    return {
      'id': googleAnalyticsProduct.id,
      'name': googleAnalyticsProduct.name,
      'category': googleAnalyticsProduct.category,
      'brand': googleAnalyticsProduct.brand,
      'variant': '',
      'list': listName,
      'position': position
    };
  },

  getContainer: function(el) {
    el = $(el);
    var parent = el.parent();
    var parentClassNames = parent.attr("class").split(/\s+/);

    var parentIsColElement = _.any(parentClassNames, function(className) {
      return className.match(/^col-\w{2}-\d{1,2}$/)
    });

    // treat as parent col-xs-12 element as a container
    if (parentIsColElement) {
      return parent;
    } else {
      return el;
    }
  },

  getPosition: function(el) {
    return this.getContainer(el).index() + 1;
  },

  getListName: function(el) {
    var listElement = this.getListElement(el);
    return listElement.data("analytics-list-name");
  },

  getListElement: function(el) {
    return el.parents('[data-analytics-list-name]');
  },

  sendToGoogle: function(impressions) {
    Tracking.productImpressions({ googleAnalyticsImpressions: impressions });
  }
};

window.ProductImpressions = ProductImpressions;

$(function () {
  window.ProductImpressions.initialize();
});
