'use strict';

angular.module('app').directive('farmyParallaxBg', ['$window', '$interval', '$timeout',
  function ($window, $interval, $timeout) {
    return {
      restrict: 'A',
      link: function(scope, $element, $attrs) {
        let parallaxSpeed = $attrs.parallaxSpeed ? parseFloat($attrs.parallaxSpeed) : null;
        let startingPositionY = parseFloat($attrs.startingPositionY) ? parseFloat($attrs.startingPositionY) : 0;

        function updatePosition() {
          let scrollTop = $(window).scrollTop();
          let totalHeight = window.innerHeight || (document.body && document.body.offsetHeight);

          let offset = (scrollTop * (parallaxSpeed || 1.313) / totalHeight) * 100;
          let offsetTagY = `${startingPositionY - offset}px`;
          if ($attrs.usePercentage) {
            let offsetPercentage = scrollTop / $element.innerHeight() * 100;
            offsetTagY = `${startingPositionY - (offsetPercentage * parallaxSpeed / 50)}%`
          }

          $element.css('background-position-y', offsetTagY)
        }

        function initialize() {
          angular.element($window).bind("scroll", updatePosition);
          angular.element($window).bind("touchmove", updatePosition);
          updatePosition();
        }

        initialize();
      }
    }
  }
]);
