// This is the notifications popup that displays a list of recent notifications of the user

appModule.directive('farmyNotificationsPanel', ['$q', '$translate', '$http', function($q, $translate, $http) {
  return {
    restrict: 'EAC',
    scope: {
      'listener': '='
    },
    controller: ['$scope', '$rootScope', '$window', '$location', '$timeout', '$element', 'UserService', 'OrderHelper', 'UiStateService', 'UserNotifications', 'PromotionsService', 'FarmyNotifications', function($scope, $rootScope, $window, $location, $timeout, $element, UserService, OrderHelper, UiStateService, UserNotifications, PromotionsService, FarmyNotifications) {
      window.farmyNotificationsPanel = $scope;
      let removeLoadListener;
      $scope.element = $element;
      $scope.notifications = [];

      function constructor() {
        $scope.isFirstLoad = true;
        onNotificationsLoaded();
        removeLoadListener = $rootScope.$on('user-notifications:loaded', onNotificationsLoaded);
      }

      function destructor() {
        removeLoadListener();
        window.farmyNotificationsPanel = null;
      }

      $scope.toggleNotification = function(notification) {
        if (notification.expanded != true) {
          if (notification.has_details) {
            $scope.expandNotification(notification);
          } else {
            $scope.flashNotification(notification);
          }

          // Mark as delivered, if necessary
          if (!notification.delivered) {
            UserNotifications.setDelivered(notification);
          }

          notification.seen = true;
        } else {
          notification.expanded = false;
          isOneExpanded();
        }
      };

      $scope.expandNotification = function(notification) {
        // Collapse all others
        _.each($scope.notifications, n => n.expanded = false);

        notification.expanded = true;
        isOneExpanded();
        $timeout(function() {
          if($scope.notifications.indexOf(notification) == 0) {
            scrollToTop();
          } else {
            scrollNotificationToTop(notification)
          }
        }, 350)
        // notification.templateUrl = `/ng/templates/farmy_notifications/notification_${getStyle(notification)}.html?locale=${window.I18n.locale}`;
      };

      $scope.flashNotification = function(notification) {
        // Collapse all others
        _.each($scope.notifications, n => n.expanded = false);

        isOneExpanded();

        notification.flashed = true;
        $timeout(() => { notification.flashed = false }, 1200);
      };

      $scope.dismiss = function() {
        if (window.UserNotificationsPopupCtrl) window.UserNotificationsPopupCtrl.dismiss();
      };

      function scrollToTop() {
        let wrapper = $('.modal-body.notifications-modal-body');
        wrapper.animate({scrollTop: 0}, 500)
      }

      function scrollNotificationToTop(notification) {
        let wrapper = $('.modal-body.notifications-modal-body');
        let notificationIndex = $scope.notifications.indexOf(notification);
        let element = $('li.notification-entry')[notificationIndex];

        wrapper.animate({scrollTop: element.offsetTop - 3 }, 500)
      }

      function onNotificationsLoaded() {
        let newIds = _(UserNotifications.notifications).map((n) => n.id);
        let currentIds = _($scope.notifications).map((n) => n.id);

        if($scope.isFirstLoad || _.difference(newIds, currentIds).length > 0) {
          $scope.notifications = UserNotifications.notifications;
          $scope.isFirstLoad = false;

          buildNotifications();
          isOneExpanded();
          scrollToTop();
        }
      }

      function buildNotifications() {
        _($scope.notifications).each((notification) => {
          notification.templateUrl = `/ng/templates/farmy_notifications/notification_${getStyle(notification)}.html?locale=${window.I18n.locale}`;
          $timeout(() => calculateMessageHeight(notification), 100);
        })
      }

      function calculateMessageHeight(notification) {
        notification.calculating = true;

        $timeout(() => {
          let notificationIndex = $scope.notifications.indexOf(notification);
          let element = $($element.find('.notification-message.invisible')[notificationIndex]);

          notification.messageHeight = `${element.outerHeight()}px`;

          $timeout(() => {
            notification.calculating = false;
          }, 100);
        }, 100);
      }

      function isOneExpanded() {
        $scope.oneIsExpanded = _($scope.notifications).any((n) => n.expanded);
      }

      function getStyle(notification) {
        if (notification.event_type == 'order_shipped') return 'success';
        else if (notification.event_type == 'added_line_item_promotion') return 'free_product_promotion';
        else if (notification.event_type == 'increase') return 'info';
        else if (notification.event_type == 'decrease') return 'info';
        else if (notification.event_type == 'danger') return 'error';
        else return notification.event_type;
      }

      $scope.$on('$destroy', destructor);
      constructor();
    }],
    link: function (scope, element, attrs) {

    },
    template: () => {
      return `<div>
                <ul role="menu">
                    <li class="notification-entry" role="menuitem" ng-repeat="notification in notifications track by $index" ng-class="{flashed: notification.flashed, dimmed: oneIsExpanded && !notification.expanded}">
                        <table class="title-table" cellspacing="0" cellpadding="0" ng-click="toggleNotification(notification)">
                          <tr>
                              <td class="col-img">
                                <div class="event-img" ng-class="notification.event_list_class"><i ng-class="notification.icon_list_class"></i></div>
                              </td>
                              <td class="col-msg">
                                <a href="#" class="notification-title" ng-bind-html="notification.title_compiled"></a>
                                <span class="time-ago" am-time-ago="notification.created_at"></span>                                                              
                              </td>
                          </tr>
                        </table>                        
                        <div class="notification-message" ng-include="notification.templateUrl" ng-class="{invisible: notification.calculating, collapsed: !notification.calculating && !notification.expanded}" ng-style="{height: notification.messageHeight}"></div>
                    </li>
                </ul>
              </div>`
    }
  };
}]);
