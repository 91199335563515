import * as _ from 'underscore';

angular.module('app').service('UserLocation', ['$q', '$http', '$interval', '$timeout', '$location', 'blockUI', '$translate', ($q, $http, $interval, $timeout, $location, blockUI, $translate) => {
  var $scope = this || {};

  $scope.knownLocation = null;
  $scope.knownLocationAt = null;

  var retries = 0;

  // Get distance in km
  // From: https://stackoverflow.com/a/27943/2351589
  function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1);
    var a =
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c; // Distance in km
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }

  /**
   * Gets a { latitude: , longitude: } promise result, using browser
   * geolocation API.
   *
   * @returns {*}
   */
  $scope.getUserLocation = function() {
    retries += 1;

    return $q(function(resolve, reject) {
      // Reuse previous location
      if ($scope.knownLocation) { // TODO: Ensure that it was recently updated
        resolve($scope.knownLocation);
        return;
      }

      $scope.loadingLocation = true;

      if (navigator.geolocation && navigator.geolocation.getCurrentPosition) {
        window.Alerts.info($translate.instant('messages.location_request_notice'));

        // Callback for update position
        function onPositionUpdated(position) {
          retries = 0;
          console.log("Position arrived", position);

          if(position.coords == null) {
            console.warn("No valid position data available in ", position);
            $scope.loadingLocation = false;
            Tracking.sendEvent("checkout", "locationFailed");
            ahoy.track("location-failed", {});
            reject("unknown");
            return;
          }

          Tracking.sendEvent("checkout", "locationFetched");
          ahoy.track("location-fetched", {});

          $scope.knownLocation = position.coords;
          $scope.knownLocationAt = new Date();
          resolve(position.coords);

          $scope.loadingLocation = false;
        };

        // Request location update
        navigator.geolocation.getCurrentPosition(onPositionUpdated,
          function(error) {
            console.warn("Location API error", error);
            $scope.loadingLocation = false;

            if (retries < 1) {
              $scope.getUserLocation().then(function(result) {
                resolve(result);
              });
            } else {
              ahoy.track("location-timeout", {});
              retries = 0;
              reject("timeout");
            }
          },
          { enableHighAccuracy: true, timeout: 5000 });
      } else {
        Tracking.sendEvent("checkout", "locationNotSupported");
        ahoy.track("location-not-supported", {});
        reject("geolocation_not_supported");
      }
    });
  };

  $scope.unsafeDistanceTo = function(lat, lng) {
    if ($scope.knownLocation == null) throw new Error("Location is still unknown");

    return getDistanceFromLatLonInKm($scope.knownLocation.latitude, $scope.knownLocation.longitude, lat, lng);
  };

  return $scope;
}]);

