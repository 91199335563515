angular.module('app').config(['$httpProvider', function($httpProvider) {
  window.$httpProvider = $httpProvider; // this is needed for turbolinks

  $httpProvider.defaults.withCredentials = true;
  $httpProvider.defaults.xsrfCookieName = 'CSRF-TOKEN';
  $httpProvider.defaults.xsrfHeaderName = 'X-CSRF-Token';

  if ($httpProvider.defaults.headers.common == null)
    $httpProvider.defaults.headers.common = {};

  // $httpProvider.defaults.headers.common['X-CSRF-Token'] = $('meta[name=csrf-token]').attr('content');
  $httpProvider.defaults.headers.common['X-NG-HTTP'] = 'X-NG-HTTP';
}]);