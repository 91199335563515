import React from 'react';
import {trackEvent} from '../../services/tracking-helper';
import {RjsSupportContactFormDelivery} from "./RjsSupportContactFormDelivery";
import {RjsSupportContactFormOrdering} from "./RjsSupportContactFormOrdering";
import {RjsSupportContactFormPayment} from "./RjsSupportContactFormPayment";
import {RjsSupportContactFormReturns} from "./RjsSupportContactFormReturns";
import {RjsSupportContactFormProducers} from "./RjsSupportContactFormProducers";
import {RjsSupportContactFormSearch} from "./RjsSupportContactFormSearch";
import {t} from "../../shared-services/translate";

export class RjsSupportContactForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            typeSelected: null,
            formSubmitted: false,
            formSuccessfulResponse: false
        }
        this.$http = props.$http;
        this.firstLoad = true;

        if(props.routeParams.theme != null) {
            this.state.typeSelected = props.routeParams.theme;
        }
    }

    render(props) {
        return(
            <div className='support-form'>
                <RjsSupportContactFormSearch formType={this.state.typeSelected} http={this.props.$http}/>
                <div className="type-selector-group">
                    {this.buildTypesToSelect()}
                </div>
                {this.buildFormType()}
            </div>
        );
    }

    buildTypesToSelect() {
        return this.props.typesToSelect.map((typeSelector) => {
            return (<div className="type-selector" key={typeSelector.id}>
                <input type="radio" className="hidden" name="typeFormSelected" id={'typeFormSelected-' + typeSelector.id} onChange={this.selectFormType.bind(this, typeSelector)} checked={this.state.typeSelected === typeSelector.id}/>
                <label htmlFor={'typeFormSelected-' + typeSelector.id}>
                    <i className={'fa ' + 'fa-' + typeSelector.icon}/>
                    <span>{$translate.instant(typeSelector.name)}</span>
                </label>
            </div>)
        });
    }

    buildFormType() {
        if(this.state.formSubmitted && this.state.formSuccessfulResponse) {
            return <div className='success-response'>
                <i className='fa fa-ok-circled'/>
                <h3>{$translate.instant('contact.msg_success_title')}</h3>
                <p>{$translate.instant('contact.msg_success_desc')}</p>
            </div>
        }
        const result = [];
        // result.push(`<RjsSupportContactForm${this.state.typeSelected.charAt(0).toUpperCase() + this.state.typeSelected.slice(1)} formType={this.state.typeSelected}/>`)
        switch (this.state.typeSelected) {
            case 'delivery':
                result.push(<RjsSupportContactFormDelivery key='form-delivery' formType={this.state.typeSelected}  http={this.props.$http} onFormSubmitted={this.onFormSubmitted.bind(this)}/>)
                break;
            case 'ordering':
                result.push(<RjsSupportContactFormOrdering key='form-orders' formType={this.state.typeSelected} http={this.props.$http} onFormSubmitted={this.onFormSubmitted.bind(this)}/>)
                break;
            case 'payment':
                result.push(<RjsSupportContactFormPayment key='form-payments' formType={this.state.typeSelected} http={this.props.$http} onFormSubmitted={this.onFormSubmitted.bind(this)}/>)
                break;
            case 'returns':
                result.push(<RjsSupportContactFormReturns key='form-returns' formType={this.state.typeSelected} http={this.props.$http} onFormSubmitted={this.onFormSubmitted.bind(this)}/>)
                break;
            case 'producers':
                result.push(<RjsSupportContactFormProducers key='form-producers' formType={this.state.typeSelected} http={this.props.$http} onFormSubmitted={this.onFormSubmitted.bind(this)}/>)
                break;
        }
        return result;
    }

selectFormType(typeSelected) {
    if (typeSelected) {
      trackEvent("contactform",
        {
          gtmObject: {
            type: "suggested",
            searchterm: typeSelected?.id,
            selection: t(typeSelected?.name)
          }
        });
    };
    this.setState({typeSelected: typeSelected.id, formSubmitted: false, formSuccessfulResponse: false});
}

    onFormSubmitted(successfull) {
        this.setState({formSubmitted: true, formSuccessfulResponse: successfull});
        trackEvent('contactform_send', {gtmObject: {category: this.state.typeSelected}});
    }
}
