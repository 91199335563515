'use strict';

angular.module('app').factory('User', ['$resource', function($resource) {
  return $resource('/api/users/:id', null, {
    current: { url: '/api/users/current', method: 'GET', transformResponse: function (data, headers) {
      var user = JSON.parse(data);
      return user;
    } }
  });
}]);
