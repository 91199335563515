import React from 'react';

export class RjsSupportContactFormActiveOrders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeOrders: [],
            currentUser: UserService.currentUser
        }
        this.parsedOrderDeliveryTime = null;
        this.$http = props.http;
        this.firstLoad = true;
        this._isMounted = false;

    }
    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) this.loadOrders();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render(props) {
        return (<div className='support-form-active-orders'>
            {this.buildOrders()}
        </div>);
    }

    loadOrders() {
        if (!this.state.currentUser) {
            this.props.onChildComponentLoaded(true);
            return;
        }

        this.$http.get('/api/frontend/support_contact/active_orders.json').then((response) => {
            console.debug(response)
            this.setState({activeOrders: response.data}, () => {
                this.props.onChildComponentLoaded(true);
            });
        }, (error) => {
            console.debug(error)
        });
    }

    buildOrders() {
        if (this.state.activeOrders.length <= 0) return;
        const activeOrders = this.state.activeOrders.map((order) => {
           return <div className="active-order" key={order.id}>
               <div className="date-calendar-widget">
                   <div className="month">{this.parseDate(order.delivery_time).toLocaleString(this.state.currentUser.locale, { month: 'short' })}</div>
                   <div className="body">
                       <div className="day">{this.parseDate(order.delivery_time).toLocaleString(this.state.currentUser.locale, { weekday: 'short' })}</div>
                       <div className="date">{this.parseDate(order.delivery_time).toLocaleString(this.state.currentUser.locale, { day: 'numeric' })}</div>
                   </div>
               </div>
               <div className="order-info">
                   <div className="order-info-simple">
                       <div className="number"><span><a href={`/orders/${order.number}`} target='_blank'>{order.number}</a></span></div>
                       <div className="status"><span><i className='fa fa-cog'/><span>{order.shipment_state}</span></span></div>
                       <div className="total">CHF {order.total}</div>
                   </div>
                   <div className="delivery-time"><span className='delivery-time-label'>Delivery Time: </span><span className='delivery-time-value'>{order.formated_delivery_time}</span></div>
                   <div className="report-btn">
                       <button onClick={this.reportOrder.bind(this, order)}><i className="fa fa-attention"/><span>Report problem</span></button>
                   </div>
               </div>
           </div>
        });
        return (<div className='active-orders-group'>
            <h3>{$translate.instant('contact.active_orders')}</h3>
            {activeOrders}
        </div>);
    }


    parseDate(dateStr) {
        if (this.parsedOrderDeliveryTime == null) {
            this.parsedOrderDeliveryTime = new Date(dateStr);
        }

        return this.parsedOrderDeliveryTime;
    }

    reportOrder(order) {
        this.props.onSelectOrder(order)
    }

}