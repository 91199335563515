'use strict';

angular.module('app').controller('NavTaxonsCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$timeout', '$http', '$element', '$localStorage', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $timeout, $http, $element, $localStorage, Alerts) {
    $scope.extendedNav = false;
    window.NavTaxonsCtrl = $scope;

    /**
     * Promise placeholder for the timed delay of menu open
     */
    var menuPreopenPromise;

    $scope.onPrimaryCategoryMouseEnter = function(event, taxonId) {
      if (menuPreopenPromise)
        $timeout.cancel(menuPreopenPromise);

      menuPreopenPromise = $timeout(function() {
        $scope.mouseOverTaxonId = taxonId;
        $scope.mouseOverTaxonIconElement = event.currentTarget;
        $rootScope.$broadcast('navigation:categories:mouseenter', taxonId, event.currentTarget);
      }, 380);
    };

    $scope.onPrimaryCategoryMouseLeave = function(event, taxonId) {
      if (menuPreopenPromise)
        $timeout.cancel(menuPreopenPromise);

      $scope.mouseOverTaxonId = null;
      $scope.mouseOverTaxonIconElement = null;
      $rootScope.$broadcast('navigation:categories:mouseleave');
    };

    // Private members

    // Initialisation
    $rootScope.$on('navigation:categories:panel:mouseenter', function(e, taxonId) {
      $scope.extendedNav = true;
    });

    // $rootScope.$on('navigation:categories:mouseleave', function(e) {
    //
    // });

    $scope.$on('$destroy', function() {
      window.NavTaxonsCtrl = null;
    });
  }
]);
