'use strict';

/**
 * Directive for making videos automatically fill parent containers based on default
 * dimensions and aspect ration.
 *
 * Example use case, can be saved a CMS-managed snippet:
 *
 * <code>
 *   <iframe adjustable-video width="{{ video.width }}" height="{{ video.height }}" data-default-width="560" data-default-height="315" src="https://www.youtube.com/embed/8ixMuJ_JUkY" frameborder="0" allowfullscreen></iframe>
 * </code>
 *
 */
angular.module('app')
  .directive('adjustableVideo', function() {
    return {
      link: function(scope, element, attributes) {
      },
      controller: ['$scope', '$element', '$http', '$interval', function ($scope, $element, $http, $interval) {
        $scope.video = {
          width: '100%',
          height: 'auto',
          defaultWidth: $element.attr('data-default-width'),
          defaultHeight: $element.attr('data-default-height'),
          matchHeightOf: $element.attr('match-height-of')
        };

        $scope.$on('$destroy', () => {
          if (layoutUpdateInterval) clearInterval(layoutUpdateInterval);
        })

        var defaultRatio = $scope.video.defaultWidth / $scope.video.defaultHeight;

        // notifyHeightTarget CSS selector experession will be also assigned the calculated height (useful for iframe wrappers)
        var notifyHeightTarget = $element.attr('notify-height');

        let layoutUpdateInterval = setInterval(function() {
          if($element.height > 200) // a hack to avoid running this multiple times after the video frame has been fully loaded
            return;

          if ($scope.video.matchHeightOf) {
            var height = sprintf('%dpx', $(matchHeightOf).height());
            $element.css({ height: height });
          } else {
            var height = sprintf('%dpx', $element.width() / defaultRatio);
            $element.css({ height: height });
          }

          if (notifyHeightTarget)
            $(notifyHeightTarget).css({ height: height });

        }, 200);
      }]
    }
  });
