angular.module('app').directive('ngSrcDelayed', ['$rootScope', '$parse', '$timeout', function($rootScope, $parse, $timeout) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      $timeout(() => {
        var fn = $parse(attrs.ngSrcDelayed);
        attrs.$set('src', fn(scope, {}));
        // element.attr('src', fn(scope, {}))
      });
    }
  };
}]);