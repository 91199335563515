'use strict';

angular.module('app').controller('CmsPageModalCtrl', ['$scope', '$rootScope', '$q', '$http', '$uibModalInstance', '$sce', 'pageId',
  function ($scope, $rootScope, $q, $http, $uibModalInstance, $sce, pageId) {
    $scope.pageId = pageId;

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    //
    // Initialize
    //
  }
]);
