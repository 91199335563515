import * as _ from 'underscore';

/**
 * DEPRECIATED, use UiStateService
 */
angular.module('app').service('UiStateManager', ['$rootScope', '$q', '$route', '$http', '$timeout', '$location', '$compile', 'NativeContainer', function ($rootScope, $q, $route, $http, $timeout, $location, $compile, NativeContainer) {
  var $scope = this || {};

  var rootTaxonomyStem = window.rootTaxonomyStem; // a collection of root catalog permalink for locales
  var isFirstTime = true;

  /**
   * A flag, which when set to true, will skip location
   * change once(!), next time when the browser address bar
   * (i.e. location.href) is changed
   *
   * @type {boolean}
   */
  var skipNextLocationChange = false; // this flag is used to force skip location change, when restoring path after a popup/nested view

  /**
   * Stores the path string of the path
   * that was requested the very last time
   *
   * @type {null}
   */
  var currentLoadingPath = null;

  //
  // Private members
  //

  /**
   * Checks the current location or the provided URL for whether it is an URL which will
   * change the session's locale.
   *
   * This is used to prevent turbolink load in such a case, to make sure the whole page and JSON
   * data is correctly reloaded in the new locale.
   *
   * @private
   * @param url (optional) You can provide an URL with query to check it, instead of the current location
   * @returns {boolean}
   */
  var isLocaleChangeQuery = function(url) {
    if (url != null) {
      return url.indexOf('localechange=') >= 0
    } else {
      return window.location.search.indexOf('localechange=') >= 0;
    }
  };

  var isCategoryPageQuery = function(url) {
    return new RegExp(rootTaxonomyStem.join("|")).test(url);
  };

  //
  // Public members:
  //

  /**
   * Describes the hashtag symbol used for in-page anchor navigation.
   *
   * @public
   * @type {string}
   */
  $scope.hashTag = '#';

  /**
   * Used for debugging
   *
   * @type {boolean}
   */
  $scope.breakpointOnLocationChange = false;

  /**
   * DEPRECEATED: Use $location.reload(..., false) instead!
   *
   * This basically allows setting a location string, without triggering the automatic
   * onLocationChangeStart logic. A typical case: you somehow load the page/popup content
   * inside a separate controller, and just want to reflect it in the URL, without triggering
   * a page reload
   *
   * @public
   * @param path
   * @param search Optional query string. Current query will be deleted, if this is not specified
   */
  $scope.setPathWithPreventChangeEvent = function(path, search) {
    skipNextLocationChange = true;
    if (search == null) search = '';
    $location.changeUrl(path).search(search);
  };

  $scope.setHashtag = function(hashtag) {
    $location.hash(hashtag);
  };

  $scope.back = function() {
    var oldUrl = location.href;

    window.history.back();

    NativeContainer.broadcastWebEvent('backButtonClicked', {source: oldUrl});
  };

  /**
   * DEPRECEATED
   *
   * Directly loads an URL via the turbolink mechanism, setting the URL
   * directly to the provided absolutePath.
   *
   * @public
   * @param absolutePath
   */
  $scope.turboOpenPage = function(absolutePath) {
    $location.url(absolutePath)
  };

  /**
   * Loads a page at the provided absolutePath and renders its contents
   * within the body of the current page.
   *
   * Note: This method doesn't change the URL of the current page, it's just a loaded-method
   *
   * @public
   * @param absolutePath
   */

}]);