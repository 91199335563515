import React from 'react'

export class RjsSupportContactFormProductOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
        this.$http = props.http;
        this.firstLoad = true;

        this.optionList = [
            {key: 'missing', name: 'contact.missing'},
            {key: 'wrong', name: 'contact.wrong'},
            {key: 'weight', name: 'contact.weight'},
            {key: 'bad', name: 'contact.bad'}
        ]
    }

    render(props) {
        return (<div className='support-form-product-options'>
            {this.buildInputOptions()}
        </div>);
    }


    buildInputOptions() {
        if (this.props.product == null || !this.props.product.checked) return;

        return this.optionList.map((option, index) => {
           return <label key={option.key} className='support-form-product-sub-checkbox'>
               <input type="radio" name={`radio-group-${this.props.product.id}`} className='hidden' value={index} onChange={this.selectErrorType.bind(this)}/>
               <i className='fa fa-ok-circled check-icon' />
               <i className='check-icon-placeholder' />
               <p className='text'>{$translate.instant(option.name)}</p>
           </label>
        });
    }

    selectErrorType(e) {
        this.props.onSelectTypeError(this.props.product, this.optionList[e.target.value])
    }



}
