import React from 'react'
import {RjsSupportContactFormHelpInfo} from "./RjsSupportContactFormHelpInfo";
import {RjsSupportContactFormGeneral} from "./RjsSupportContactFormGeneral";
import {RjsSupportContactFormActiveOrders} from "./RjsSupportContactFormActiveOrders";

export class RjsSupportContactFormDelivery extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customData: {},
            allChildComponentsLoaded: false,
            currentUser: UserService.currentUser
        }
        this.$http = props.http;
        this.firstLoad = true;
        this.childComponentsLoaded = [];
        this.waitingComponentsLength = 1;

        this.onChildComponentLoaded = this.onChildComponentLoaded.bind(this);
        this.selectOrder = this.selectOrder.bind(this);

        this.formGeneralRef = React.createRef();

        this.onFormSubmitted = props.onFormSubmitted;
    }

    render(props) {
        return (<div className={`support-form-delivery ${this.state.allChildComponentsLoaded ? '' : 'hidden'}` }>
            <RjsSupportContactFormHelpInfo parentFormType={this.props.formType} http={this.$http} onChildComponentLoaded={this.onChildComponentLoaded} />
            <RjsSupportContactFormGeneral parentFormType={this.props.formType} currentUser={this.state.currentUser} customData={this.state.customData} forGeneralRef={this.formGeneralRef} http={this.$http} onFormSubmitted={this.onFormSubmitted}/>
        </div>);
    }

    selectOrder(order) {
        this.state.customData.selectedOrder = order;
        this.setState({customData: this.state.customData}, () => {
            window.scrollTo(0, this.formGeneralRef.current.offsetTop - 180);
        });
    }

    onChildComponentLoaded(loaded) {
        this.childComponentsLoaded.push(loaded);
        if (loaded && this.childComponentsLoaded.length >= this.waitingComponentsLength) {
            this.setState({allChildComponentsLoaded: _.every(this.childComponentsLoaded, (child) => {
                    return child;
                })
            });
        }
    }
}
