import {useEffect} from 'react';
import {trackPageView, trackEvent, pusherSubscribe, pusherUnsubscribe} from '../services/tracking-helper';
import {useSelector} from '../shared-services/redux-tiny';

const useTracking = (
  options = {},
  onExit = () => {}
) => {
  const currentUser = useSelector(state => state.session.currentUser);
  const ahoy = window.ahoy;

  const csTrackTransaction = (order) => {
    ahoy.track(
      'cs-transaction',
      {
        order_id: order?.id,
        order_number: order?.number,
        total: order?.total,
        completedAt: order?.completed_at,
        completionTracked: order?.completion_tracked
      }
    );
    window._uxa = window._uxa || [];
    console.log('CS transaction push', {order});
    window._uxa.push(['ec:transaction:create', {
      id: `${order?.number}`,
      revenue: `${order?.total}`,
      currency: `${order?.currency}`
    }]);

    window._uxa.push(['ec:transaction:send']);
  };

  useEffect(() => {
    const {pusherEvents} = options;

    if (pusherEvents?.length && currentUser?.id) {
      // pusherSubscribe(pusherEvents, currentUser);
    }

    return () => {
      // pusherUnsubscribe();
      onExit();
    };
  }, [currentUser]);

  return {trackPageView, trackEvent, csTrackTransaction};
};

export default useTracking;
