async function hashSHA256(data) {
  if (!data) {
    console.warn("Provided data is undefined or empty, skipping hash.");
    return "";
  }
  const encoder = new TextEncoder();
  const dataEncoded = encoder.encode(data);
  const hashBuffer = await crypto.subtle.digest("SHA-256", dataEncoded);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
  return hashHex;
}

async function hashUserDetails(userDetails) {
  const hashedDetails = {};

  const addIfNotEmpty = (key, value) => {
    if (value || value === 0) hashedDetails[key] = value;
  };

  addIfNotEmpty("first_name", userDetails.first_name ? await hashSHA256(userDetails.first_name) : "");
  addIfNotEmpty("last_name", userDetails.last_name ? await hashSHA256(userDetails.last_name) : "");
  addIfNotEmpty("street_address", userDetails.street_address ? await hashSHA256(userDetails.street_address) : "");
  addIfNotEmpty("phone", userDetails.phone ? await hashSHA256(userDetails.phone) : "");
  addIfNotEmpty("email", userDetails.email ? await hashSHA256(userDetails.email) : "");
  addIfNotEmpty("password", userDetails.password ? await hashSHA256(userDetails.password) : "");
  if (userDetails.postal_code) {
    const numericPostalCode = parseInt(userDetails.postal_code.slice(0, 5), 10);
    if (!isNaN(numericPostalCode)) {
      addIfNotEmpty("postal_code", numericPostalCode);
    } else {
      console.warn("Invalid postal code:", userDetails.postal_code);
    }
  }
  addIfNotEmpty("city", userDetails.city ? userDetails.city.toLowerCase() : "");
  addIfNotEmpty("region", userDetails.region ? userDetails.region.toLowerCase() : "");
  addIfNotEmpty("country", userDetails.country || "");
  addIfNotEmpty("state", userDetails.state || "");

  return hashedDetails;
}

export {hashSHA256, hashUserDetails};
