import * as _ from 'underscore';
import * as sprintf from 'sprintf';

angular.module('app').controller('ReferralInvitationsEditPageCtrl', ['$scope', '$http', 'Alerts', '$location', '$element', 'clipboard', function($scope, $http, Alerts, $location, $element, clipboard){
  window.ReferralInvitationsEditPageCtrl = $scope;

  var loadReferrals = function() {
    $http.get('/api/frontend/referral_invitations.json').then(function(response) {
      $scope.user = response.data.user;
      $scope.referrals = response.data.referrals;
      $scope.tokenizedUrl = $scope.tokenizedUrlNoLocale = sprintf('%s://%s/invite/%s?nzp', $location.protocol(), location.host, $scope.user.referral_token);
      if (I18n.locale != 'de') $scope.tokenizedUrl += sprintf('&locale=%s', I18n.locale);
      $scope.shortTokenUrl = $scope.tokenizedUrlNoLocale.replace('http://www.', '').replace('https://www.', '').replace('http://', '').replace('?nzp', '');
    })
  };

  $scope.copyLink = function() {
    clipboard.copyText($scope.tokenizedUrl, $element.find('.input.form-control')[0]);
    $scope.onCopySuccess();
  };

  $scope.onCopySuccess = function() {
    Alerts.success($translate.instant("account_settings.referrals.link_copied"));
  };

  $scope.onCopyError = function() {
    Alerts.error($translate.instant("account_settings.referrals.copy_to_clipboard_error"))
  };

  $scope.$on('$destroy', function() {
    window.ReferralInvitationsEditPageCtrl = null;
  });

  loadReferrals();
}]);