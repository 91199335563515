angular.module('app').service('NotificationUtils', ['$rootScope', '$q', '$http', '$cookies', '$uibModal', function ($rootScope, $q, $http, $cookies, $uibModal) {
  var scope = this;

  var dismissedCache = {};

  this.isDismissed = function(notificationId) {
    if (dismissedCache[notificationId] == null) {
      dismissedCache[notificationId] = $cookies.get('_NotificationUtils_dismissed_' + notificationId) == 'true' ? true : false;
      return dismissedCache[notificationId];
    }

    return dismissedCache[notificationId];
  };

  this.dismiss = function(notificationId) {
    $cookies.put('_NotificationUtils_dismissed_' + notificationId, 'true')
    dismissedCache[notificationId] = true;
    $(sprintf('[data-notification-id=%s]', notificationId)).hide();
  };

  window.NotificationUtility = scope;
}]);
