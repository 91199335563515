import * as _ from 'underscore';
import {trackEvent, trackPageView} from '../services/tracking-helper';

angular.module('app').controller(
  'CartCtrl', [
    '$scope', '$sce', '$http', '$q', '$rootScope', 'Alerts', 'blockUI', 'Hubs', 'UserService',
    '$location', 'TaxonHelper', '$translate', '$timeout', 'UnitsInflector', 'CartData',
    'PromotionsService', 'PartnerPixelHelper', 'OrderHelper', 'SmartPassService',
    function(
      $scope, $sce, $http, $q, $rootScope, Alerts, blockUI, Hubs, UserService,
      $location, TaxonHelper, $translate, $timeout, UnitsInflector, CartData,
      PromotionsService, PartnerPixelHelper, OrderHelper, SmartPassService) {
      window.CartCtrl = $scope;
      $scope.PromotionsService = PromotionsService;
      $scope.SmartPassService = SmartPassService;

      //
      // Private members:
      //

      var unOnCartDataSaved;

      function constructor() {
        $scope.loadOrder().then(response => {
          const order = response.data.order;
          if (!window.isMobile) trackPageView('basket', {products: order?.line_items, checkout: order});
        });

        Tracking.sendCheckoutStageStartEvent('cart');

        unOnCartDataSaved = $rootScope.$on('cartdata:saved', function() {
          $scope.loadOrder();
        });

        $scope.$watch('CartData.cart.updated_at', function(updatedAt) {
          if (updatedAt == null)
            return;
          $scope.reloadCart();
          CartData.updateTotal();
        });
      }

      function destructor() {
        if (unOnCartDataSaved) unOnCartDataSaved();
        window.CartCtrl = null;
      }

      function processLineItems() {
        loadProductData();
        $scope.sortItemsByCategory();

        _.each($scope.order.line_items, function(item) {
          if (item.product && item.product.hub_ids && item.product.hub_ids.indexOf($scope.order.hub_id) == -1) {
            item.unavailable_in_order_hub = true
          } else {
            item.unavailable_in_order_hub = false
          }

          // Inject quantity labels into variants
          UnitsInflector.$inflect(item.variant.quantity_in_units, item.product.unit_name).then(label => {
            item.variant.quantity_label = label
          });

          _.each(item.product.variants_and_option_values, variant => {
            UnitsInflector.$inflect(variant.quantity_in_units, item.product.unit_name).then(label => {
              variant.quantity_label = label
            })
          });
        });

      }

      //
      // Public members
      //

      $scope.Hubs = Hubs;

      $scope.translationData = {};

      $scope.productDataCache = [];
      $scope.taxonsCache = {};

      $scope.isSaving = false;
      $scope.ordersFirstLoading = true;
      $scope.lastUserModificationAt = (new Date).getTime();

      $scope.currentStorefrontSupplier = window.currentStorefrontSupplier;

      $scope.loadOrder = function() {
        return $q(function(resolve, reject) {
          $scope.isLoading = true;

          $scope.setSaving();

          loadFreePromotionTag();

          const params = {
            number: window.currentOrderNumber,
            order_token: window.currentOrderToken,
            no_variant_labels: "t"
          };

          $http.get(apiUrl("/orders/current.json"), {params})
            .then(function(response) {
              if (response.data.order && response.data.order.line_items) {
                response.data.order.line_items = _.sortBy(response.data.order.line_items, function(li) { return li.product.name });
              }

              $scope.order = response.data.order;
              updateAdjustments();

              $scope.translationData = angular.extend($scope.translationData, {
                minimum_order_value_formatted: "CHF " + ($scope.order.minimum_order_value || 0).toFixed(2).toString(),
                order_item_total_formatted: "CHF " + ($scope.order.total || 0).toFixed(2).toString()
              });

              processLineItems();

              sendToCriteo($scope.order);

              $rootScope.$broadcast("cart-ctrl:updated", $scope.order);
              $rootScope.$broadcast("cart:changed", $scope.order);

              setTimeout(() => { if (window.PageloadTracking) PageloadTracking.viewloadSummaryEnd() }, 100);

              PartnerPixelHelper.cartView($scope.order);

              resolve(response);
            }).finally(function() {
              $scope.isLoading = false;
              $scope.setNotSaving();
            });
        });
      };

      $scope.setSaving = function() {
        $scope.isSaving = true;
        NProgress.start();
        $rootScope.$broadcast("cart:update:start");
      };

      $scope.setNotSaving = function() {
        $scope.ordersFirstLoading = false;
        $scope.isSaving = false;
        NProgress.done();
        $rootScope.$broadcast("cart:update:finish");
      };

      $scope.submitCart = function() {
        if ($scope.order && $scope.order.item_total < $scope.order.minimum_order_value) {
          Alerts.error($translate.instant('errors.minimum_order_value_error', { amount: $scope.translationData.minimum_order_value_formatted }));
        } else {
          trackEvent('begin_checkout', {products: $scope.order.line_items, checkout: $scope.order});
          if (UserService.isLoggedIn)
            $location.path('/cashier');
          else $location.path('/signup').search({checkout: 't', login: 't', locale: $translate.use()});
        }
      };

      // private
      function setShouldShowAdjustments() {
        $scope.shouldShowAdditionalFees = shouldShowAdditionalFees();

        $scope.shouldShowAdjustments = ($scope.order.non_zero_adjustments && $scope.order.non_zero_adjustments.length > 0) ||
          $scope.order.weight_control_surcharge_total > 0 ||
          $scope.shouldShowAdditionalFees;
      }

      // Logic for "shipping fee"
      function shouldShowAdditionalFees() {
        let should = !$scope.order.has_valid_delivery_slot && $scope.maxShippingCost > 0 &&
          $scope.order.item_total < $scope.order.min_value_for_free_shipping;

        should = should || $scope.order.handling_fee > 0;

        return should
      }

      function setMaxShippingCost() {
        $scope.maxShippingCost = $scope.order.approximate_shipping_cost ?? 0;
      }

      // Automatically sets Xpress delivery mode ON if "switch-express-on" parameter is found.
      // Zipcode needs to match with Xpress zone, and order is either empty or not containing any non-express products.
      function shouldSetXpressFromUrlParameter() {
        if (window.currentStorefront) return;

        let urlParams = new URLSearchParams(location.search);

        if (urlParams.get('xpress') === 't' && urlParams.get('szp') && urlParams.get('szp').length === 4) {
          let deliveryTermsWatcher = $rootScope.$on('hubs:delivery_terms:loaded', () => {
            if (Hubs.deliveryTerms && Hubs.deliveryTerms.zipcode) {
              if (Hubs.deliveryTerms.zipcode.can_do_express_delivery) {
                $scope.setExpressOrLaunchWarningPopup(true)
              }

              deliveryTermsWatcher()
            }
          })
        }
      }

      function updateAdjustments() {
        $scope.order.adjustments.forEach(adjustment => {
          const withModal = [':handling_fee'];
          if (withModal.includes(adjustment.raw_label || adjustment.code))
            adjustment.clickable = true;
        });

        setMaxShippingCost();
        setShouldShowAdjustments();
      }

      function sendToCriteo(order) {
        if (window.criteo_q == null) return;

        var items = _.map(order.line_items, function(line_item) {
          return { id: line_item.product.sku, price: line_item.price, quantity: line_item.variant.variant_based_quantity };
        });

        // console.log("Sending updated basket to criteo: ", items);
        window.criteo_q.push({ event: "viewBasket", item: items });
      }

      function loadFreePromotionTag() {
        $http.get("/orders/free_delivery_promotion_tag?locale=" + I18n.locale, {params: { number: window.currentOrderNumber, order_token: window.currentOrderToken }}).then(
          function(response) {
            $scope.freeDeliveryPromotionTagContent = $sce.trustAsHtml(response.data);
          },
          function(response) {}
        );
      };

      function loadProductData() {
        let locale = $translate.use();
        var productIds = $scope.order.line_items.map(i => i.product_id);

        return $q((resolve, reject) => {
          // Attempt to fetch product data from local cache first
          _.each(_.select($scope.productDataCache, i => i.product_id), productData => {
            var item = _.find($scope.order.line_items, i => i.product_id == productData.id);
            if (item) item.productData = productData;
          });

          // Fill the 'currentVariant' key for items with known product data
          _.each($scope.order.line_items, item => {
            if (item.productData) item.currentVariant = _.find(item.productData.variants, v => v.id == item.variant.id);
          });

          // Only fetch products that weren't available in cache
          productIds = _.chain($scope.order.line_items).select(i => i.productData == null).map(i => i.product_id).value().join(',');

          if (productIds && productIds.length > 0) {
            // Fill in the missing product data
            $http.get(`/api/products.json`, { params: { locale: locale, ids: productIds, template: 'product_in_catalog', 'hub_ids[]': Hubs.allHubIds, include_unavailable: 't', per_page: 120 }}).then(response => {
              let taxonIds = []; // these taxons will be requested from taxonCache to enrich line item data

              // Inject a key called 'productData' into every line item with full details of related products
              _.each(response.data.products || [], productData => {
                var item = _.find($scope.order.line_items, i => i.product_id == productData.id);

                if (item) {
                  if (item.viewMode == null) item.viewMode = 'view';
                  if (productData) {
                    item.productData = productData;
                    $scope.productDataCache.push(productData);
                    _.each(item.productData.taxon_ids, id => taxonIds.push(id))
                  } else {
                    console.error(`Cannot load productData for line item ${item.id}, product_id = ${item.product_id}`)
                  }
                } else {
                  console.error(`Cannot find matching line item for productData, product_id = ${item.product_id}`)
                }
              });
            })
          } else {
            resolve()
          }
        });
      }

      $scope.sortItemsByCategory = function() {
        $scope.sorting = 'category';

        $scope.order.line_items.sort((a, b) => {
          if (a.ax_primary_taxon == null || b.ax_primary_taxon == null)
            return 0;

          let aChar = a.ax_primary_taxon.name || "";
          let bChar = b.ax_primary_taxon.name || "";

          if (aChar < bChar)
            return -1;
          else if (aChar > bChar)
            return 1;
          else
            return 0;
        })
      };

      $scope.reloadCart = function() {
        return $scope.loadOrder();
      };

      $scope.emptyCart = function() {
        blockUI.start();
        $scope.isClearingCart = true;

        return $http.get('/cart/empty').then(function() {
          trackEvent('removefromcart', {gtmObject: {value: $scope.order?.item_total}, products: $scope.order.line_items})
            .finally(() => {
              trackEvent('empty_cart', {gtmObject: {value: $scope.order?.item_total}, products: $scope.order.line_items});
            });
          CartData.load().then(() => {
            $scope.loadOrder().then(() => {
              blockUI.stop();
              $location.path('/');
            });
          })
        }, (e) => {
          Alerts.error(errorMessage(e));
          blockUI.stop();
        }).finally(function() {
          $scope.isClearingCart = false;
        });
      };

      /**
       * Depreciated, use named sets instead
       *
       * @returns {*}
       */
      $scope.getRecommendedProducts = function() {
        return $q((resolve, reject) => {
          $http.get(`/api/frontend/orders/${$scope.order.number}/recommended_products_ids.json?max_products=12`).then(recommendationData => {
            let productIds = recommendationData.data.product_ids;

            $http.get(`/api/products.json`, { params: { locale: $translate.use(), ids: productIds.join(','), template: 'product_in_catalog', 'hub_ids[]': [$scope.order.hub_id], per_page: 12 }}).then(response => {
              $scope.recommendedProducts = response.data.products;
            })
          })
        })
      };

      $scope.onAdjustmentDescriptionClick = function(adjustment) {
        if (adjustment.permabox_adjustment) OrderHelper.showPermaboxDepositExplanationModel();
        if (adjustment.code === ":handling_fee") OrderHelper.showHandlingFeeModal($scope.order);
        if (adjustment.code === ":weight_control") $rootScope.showWeightControlModal($scope.order);
      };

      $scope.onNoReplacementsDesiredChange = function(event) {
        if ($scope.order.no_replacements_desired) {
          $http.put(`/api/frontend/orders/${$scope.order.number}/update_no_replacements_desired.json`, { order_token: $scope.order.token, no_replacements_desired: 't' }).then(response => {
            trackEvent("no_replacements");
          })
        } else {
          $http.put(`/api/frontend/orders/${$scope.order.number}/update_no_replacements_desired.json`, { order_token: $scope.order.token, no_replacements_desired: 'f' }).then(response => {
            trackEvent("replacements");
          })
        }
      };

      $rootScope.$on("track-gtm-cart", () => {
        if (window.isMobile) trackPageView("basket", {products: $scope.order?.line_items, checkout: $scope.order});
      });

      $scope.$on('$destroy', destructor);
      constructor();
}]);
