import {useTopLayerActions} from "../../shared-services/redux-tiny";
import FullLoader from "./FullLoader";

const useFullLoader = () => {
  const {fullLoaderVisible} = useTopLayerActions();

  const launchLoader = (options = {}) => {
    fullLoaderVisible(true);
    if (options?.duration) {
      setTimeout(() => {
        fullLoaderVisible(false);
      }, options?.duration);
    }
  };

  const closeLoader = () => {
    fullLoaderVisible(false);
  };

  return {launchLoader, closeLoader, FullLoader};
};

export default useFullLoader;
