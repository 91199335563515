angular.module('app').controller('NewsletterViewCtrl', ['$scope', 'Alerts', '$http', '$localStorage',
  function ($scope, Alerts, $http, $localStorage) {

    $scope.subscribtionEmail = null;
    $localStorage.alreadySubscribed = $localStorage.alreadySubscribed || window.currentUserId ? true : false;
    $scope.alreadySubscribed = $localStorage.alreadySubscribed;
    
    $scope.subscribeUser = function () {
      return  $http.post('/api/frontend/newsletter/subscribe_user.json', { email: $scope.subscribtionEmail }).then(function (response) {

        if(response.data.status == "error") {
          Alerts.error(`${response.data.error_message}<br /> ${response.data.message}`, {timeout: 0});
        } else {
          Alerts.success(response.data.message);
          $scope.alreadySubscribed = true;
          $localStorage.alreadySubscribed = true;
        }

        }, function (error) {
          Alerts.error(`${response.data.error_message}<br /> ${error}`, {timeout: 0});
      });
    }

    $scope.onKeyReleased = function(event) {
      if (event.keyCode == 13) $scope.subscribeUser();
    };
}]);