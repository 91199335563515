const PusherService = () => {
  const channels = [];
  let pusher;

  const init = (loadTries = 0) => {
    if (pusher) return pusher;
    if (loadTries > 5) return;

    if (window.Pusher && !window.browserIsBot) {
      pusher = new window.Pusher(window.Settings.PusherApiKey, {cluster: 'eu', encrypted: true});
    } else {
      loadTries++;
      setTimeout(() => init(loadTries), 2000);
    }
    return pusher;
  };

  const subscribe = (name) => {
    if (window.browserIsBot) return null;

    const fChannel = findChannel(name);
    if (fChannel) return fChannel;

    const channel = {
      name: name,
      instance: init().subscribe(name),
      binds: []
    };
    channels.push(channel);
    return channel;
  };

  const bind = (channelName, actionName, func) => {
    if (window.browserIsBot) return null;

    const binded = _.find(subscribe(channelName).binds, (c) => c === actionName);
    if (binded) return;

    subscribe(channelName).binds.push(actionName);

    let channel = subscribe(channelName).instance
      .bind(actionName, func);

    return channel;
  };

  const unbind = (channelName, actionName) => {
    const fChannel = findChannel(channelName);
    if (fChannel) {
      fChannel.instance.unbind(actionName);
    }
  };

  const unsubscribe = (channelName) => {
    const fChannel = findChannel(channelName);
    if (fChannel){
      _.forEach(fChannel.binds, (b) => {
        fChannel.instance.unbind(b);
      });
      init().unsubscribe(channelName);
      channels = _.without(channels, (c) => {fChannel.name === c.name})
    }
  };

  const findChannel = (name) => {
    if (window.browserIsBot) return null;

    return _.find(channels, (c) => c.name === name);
  };

  const getUserChannelName = (userData) => {
    if (userData == null) {
      return `user-channel-${window.UserService.currentUser.id}-${btoa(window.UserService.currentUser.email)}`;
    } else {
      return `user-channel-${userData.id}-${btoa(userData.email)}`;
    }
  };

  return {bind, unbind, unsubscribe, getUserChannelName};
};

export default PusherService;
