'use strict';

angular.module('app')
  .directive('animatedPageLoader', ['$rootScope', '$timeout', function($rootScope, $timeout) {
    return {
      link: function($scope, $element, attributes) {
        $rootScope.$on('$locationChangeStart', function(event, next, current) {
          if (!window.changingPathWithoutReload) {
            $scope.isLoadingPage = true;
            NProgress.start();
          }
        });

        $rootScope.$on('$viewContentLoaded', function(event, next, current) {
          $timeout(() => {
            $scope.isLoadingPage = false;
            setTimeout(() => NProgress.done(), 250);
          }, 350);
        });

        $rootScope.$on('$locationChangeError', function(event, next, current) {
          $scope.isLoadingPage = false;
          setTimeout(() => NProgress.done(), 1);
        });

        $rootScope.$on('$routeChangeSuccess', function(event, opts) {
          $timeout(() => {
            $scope.isLoadingPage = false;
            setTimeout(() => NProgress.done(), 100);
          }, 350);
        });

        $scope.$on('$destroy', () => {

        })
      },
      transclude: true,
      replace: true,
      template: `<div ng-show='false' style="position: fixed; top: 0px; height: 56px; padding: 17px; left: 20px; right: 20px; z-index: 5500; border-bottom-right-radius: 20px; border-bottom-left-radius: 20px; box-shadow: 0px 2px 10px rgba(0,0,0,.1); background: white; text-align: center; font-size: 16px;">
    <animated-fa-loader></animated-fa-loader>
</div>`
    }
  }]);
