'use strict';

/* Renders a number in a HTML */
// need load the moment.js to use this filter.
angular.module('app')
  .filter('pennies', function() {
    return function(amount) {
      if(amount == null || amount == '')
        return amount;

      if(typeof(amount) == "number") {
        amount = amount.toFixed(2);
      } else if(typeof(amount) == "string") {
        try {
          amount = parseFloat(amount)
          amount = amount.toFixed(2);
        } catch(e) {
          // if (window.airbrake) window.airbrake.notify(e);
          console.error(e);
          amount = 0.0.toFixed(2);
        }
      }

      var separator = '.';
      if(amount.indexOf(',') > 0) separator = ',';

      var parts = amount.split(separator);

      return parts[0] + "<span class='pennies'>" + parts[1] + "</span>"
    }
  });