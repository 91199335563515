import * as _ from 'underscore';
import * as moment from 'moment';

'use strict';

angular.module('app').controller('CheckoutPaymentPaypalCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', '$element', 'Alerts', 'blockUI', function ($scope, $sce, $http, $q, $rootScope, $timeout, $element, Alerts, blockUI) {
  window.CheckoutPaymentPaypalCtrl = $scope;

  // Public properties

  $scope.checkoutCtrl = $scope.$parent.$parent.$parent.$parent;
  $scope.paymentCtrl = $scope.$parent.$parent;

  $scope.paypalForm = {};

  //
  // Private members
  //
  var paypalPaymentMethod;


  function constructor() {

  }

  function destructor() {
    window.CheckoutPaymentPaypalCtrl = null;
  }

  function getPaypalPaymentMethod() {
    if (paypalPaymentMethod == null) {
      paypalPaymentMethod = _.find($scope.checkoutCtrl.availablePaymentMethods, (m) => m.type == "Spree::Gateway::PayPalExpress");
    }

    return paypalPaymentMethod;
  }

  //
  // Public methods
  //

  $scope.submitPaypalPayment = function() {
    blockUI.start();

    return $q(function (resolve, reject) {
      var params = {
        payment_method_id: getPaypalPaymentMethod().id,
        order_id: $scope.checkoutCtrl.checkout.number,
        cancel_url: window.location.href
      }; // possiblly missing the after_confirm_url parameter

      $http.get('/api/paypal.json', { params: params }).then(function(response) {
        if (response.data.redirect_url) {
          $scope.checkoutCtrl.getCheckout(); // Update client checkout data
          location.href = response.data.redirect_url;
          resolve({state: 'pending', redirect_url: response.data.redirect_url});
          blockUI.stop();
        }
      }, e => {
        if (e.data && e.data.error) {
          Alerts.error(e.data.error);
          reject(e.data.error);
        } else {
          reject(e);
        }

        blockUI.stop();
      });
    });


  };

  // Initialize

  $scope.$on('$destroy', destructor);
  constructor();
}]);
