angular.module('app')
  .directive('ribbonTitle', ['$window', '$timeout', function($window, $timeout) {
    return {
      restrict: 'AC',
      link: function($scope, $element, attrs) {
        $element.prepend('<span class="tip-left"></span>');
        $element.append('<span class="tip-right"></span>');

        var tipLeft = $element.find('.tip-left');
        var tipRight = $element.find('.tip-right');

        var updateLayout = function() {
          $(tipRight).css('top', $(tipLeft).position().top);
        }

        angular.element($window).bind('resize', updateLayout);

        $scope.$on('$destroy', function() {
          angular.element($window).unbind('resize', updateLayout);
        });

        updateLayout();

        $timeout(updateLayout, 10); // Additional layout fix needed for some browsers, depending on rendering queue
      }
    }
  }]);
