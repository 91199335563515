"use strict";

angular.module("app").controller("smartPassLandingPageCtrl", ["$scope", "$http", "CartData",
  function($scope, $http, CartData) {
    $scope.CartData = CartData;
    $scope.smartPassesEndpoint = "/api/frontend/smart_pass_subscriptions/available_smart_passes.json";
    $scope.paymentUrl = "/paid_subscriptions/new?type=farmypass";
    $scope.isLoading = false;

    $scope.loadSmartPasses = function() {
      $http.get($scope.smartPassesEndpoint).then(function(response) {
        $scope.availableSmartPasses = response.data;
      });
    };

    $scope.loadSmartPasses();
  }
]);
