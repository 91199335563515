'use strict';

angular.module('app').controller('RecipeHomeCtrl', ['$scope', '$rootScope', '$q', '$location', '$window', '$interval', '$http', '$element', 'Alerts',
  function ($scope, $rootScope, $q, $location, $window, $interval, $http, $element, Alerts) {
    // console.log("RecipeHomeCtrl controller initialized with scope");

    window.RecipeHomeCtrl = $scope;

    // Public members:
  }
]);
