'use strict';

angular.module('app').controller('OrderSuggestionsExtraProductCtrl', ['$scope', '$window', '$element', '$timeout', '$rootScope', 'Alerts', 'CartData', function ($scope, $window, $element, $timeout, $rootScope, Alerts, CartData) {
  $scope.isShown = true;
  $scope.isSaving = false;

  $scope.CartData = CartData;

  $scope.addToCart = function(e) {
    $scope.isSaving = true;

    if ($scope.product.age_restricted && !window.currentUserAgeVerified) {
      window.FarmyCartAgeVerificationPopup.show(function() {
        window.currentUserAgeVerified = true;
        $scope.addToCart(null);
      });
      return;
    }

    var variant = {
      id: $scope.product.main_variant.id,
      price: $scope.product.main_variant.price,
      quantity_in_units: $scope.product.main_variant.quantity_in_units
    };

    CartData.setCartVariant($scope.product.id, variant);

    window.Tracking.sendOrderSuggestionEvent('extraProducts', 'added');

    $scope.isSaving = true;
    $rootScope.$on("cartdata:saved", function() {
      $scope.isSaving = false;
      updateIsInCartState();
    });
  };

  // Private members:
  function updateIsInCartState() {
    $scope.isInCart = $scope.CartData.containsProduct($scope.product.id);
  }

  // Destruction
  $scope.$on('$destroy', function() {
  });

  // Initialize
  updateIsInCartState();
}]);
