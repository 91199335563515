'use strict';

angular.module('app').controller('RatingsBlockCtrl', ['$scope', '$rootScope', '$element', '$uibModal', '$compile', '$timeout',
  function ($scope, $rootScope, $element, $uibModal, $compile, $timeout) {
    console.log("RatingsBlockCtrl controller initialized with scope");

    $scope.newRating = "5";

    $rootScope.$on('ui:ratings_updated', function(event, parameter) {
      $scope.alreadyRatedByUser = true;
      $($element).html(
        $compile($(parameter.response))($scope)
      );
    });

    $("#review-modal").on('show.bs.modal', function(e) {
      $("#review-modal .rateit").rateit("value", $scope.newRating);
    });

    $timeout(() => {
      $("div.rateit, span.rateit").rateit();
    }, 500);
  }
]);
