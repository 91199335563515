'use strict';

angular.module('app').controller('InstagramFeedCtrl', ['$scope', '$http',
  function ($scope, $http) {
    $scope.instagramFeed = null;

    $scope.getInstagramFeed = function() {
      return $http.get(sprintf('/api/frontend/social/instagram_feed.json')).then(function(response) {
        $scope.instagramFeed = response.data;
      });
    };

    $scope.getInstagramFeed();
  }]);
