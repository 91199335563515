'use strict';

angular.module('app').controller('SmartPassPurchaseCtrl', ['$scope', '$http', '$rootScope', '$uibModal',
  function($scope, $http, $rootScope, $uibModal) {

    $scope.purchasingSmartPass = $scope.purchasingSmartPass || false;
    $scope.spDuration = '6';
    $scope.spType = 'basic';
    $scope.premiumOnly = false;
    $scope.removeSmartPass = false;

    $scope.purchaseSmartPass = function() {
      $scope.purchasingSmartPass = true;
      $rootScope.currentUserPurchasingSmartPass = true;
      $rootScope.currentSmartPassLevel = 'premium';
      $scope.setSlotPricesFree();
      setDatesAndSlots();
    };

    $scope.cancelSmartPass = function() {
      $scope.purchasingSmartPass = false;
      $rootScope.currentUserPurchasingSmartPass = false;
      $scope.removeSmartPass = true;
      $scope.emptyDateBadges();
      $scope.resetSlotPrices();
    };

    $scope.changeType = function(type) {
      if($scope.spType != type){
        $scope.spType = type;
        setDatesAndSlots();
      }
    };

    var setDatesAndSlots = function() {
      if($scope.order) {
        var smartPass = {level: $scope.spType, duration: $scope.spDuration, week_days: $scope.order.available_smart_passes[$scope.spType].week_days};
        $scope.setDatesAndSlots(smartPass);
      }
    };

    var setPremiumOnly = function() {
      $scope.premiumOnly = true;
      $scope.changeType('premium');
    };

    // 'About SmartPass' MODAL
    var aboutModalTemplateSlug = 'about-smart-pass-modal';
    $scope.aboutTemplateUrl = '/api/frontend/cms_pages/' + aboutModalTemplateSlug + '/content';
    $scope.cancelTemplateUrl = '/ng/templates/smart_pass/smart_pass_cancel_modal.html?locale=' + I18n.locale;

    $scope.openAboutModal = function() {
      var aboutModalInstance = $uibModal.open(
        {
          animation: true,
          size: 'lg',
          templateUrl: $scope.aboutTemplateUrl,
          backdrop: true,
          windowClass: 'smart-pass-about-modal',
          controller: 'SmartPassAboutModal',
          scope: $scope
        }
      );
    };

    $scope.openCancelModal = function() {
      var cancelModalInstance = $uibModal.open(
        {
          animation: true,
          size: 'md',
          templateUrl: $scope.cancelTemplateUrl,
          windowClass: 'smart-pass-cancel-modal',
          controller: 'SmartPassCancelModal'
        }
      );
    };

    $scope.$watch('order', function() {
      if ($scope.order) {
        if ($scope.order.purchasing_smart_pass) {
          $scope.spType = $scope.order.purchasing_smart_pass.level;
          $scope.spDuration = $scope.order.purchasing_smart_pass.duration;
          $scope.purchaseSmartPass();
        }
      }
    }, true);

    $('#top-header-nearest-delivery, #header-nearest-delivery').click(function() {
      $scope.openDeliveryCalendar();
    });

    $rootScope.$on("delivery_date:updated", function(event, date) {
      if($scope.order) {
        var premiumDays = $($scope.order.available_smart_passes['premium'].week_days).not($scope.order.available_smart_passes['basic'].week_days).get();
        if (!$scope.freeSmartPass && premiumDays.indexOf(date._d.getDay()) != -1) {
          setPremiumOnly();
        } else {
          $scope.premiumOnly = false;
          if (!$scope.purchasingSmartPass) { $scope.spType = 'basic' }
        }
      }

      if ($scope.purchasingSmartPass) {
        $scope.setSlotPricesFree();
        var smartPass = {level: $scope.spType, duration: $scope.spDuration, week_days: $scope.order.available_smart_passes[$scope.spType].week_days};
        setDatesAndSlots();
      } else {
        $scope.emptyDateBadges();
        $scope.resetSlotPrices();
      }
    })
  }]);
