'use strict';

angular.module('app')
  .directive('animatedFaLoader', function() {
    return {
      link: function($scope, $element, attributes) {
        var step = 0;

        let updateInterval = setInterval(() => {
          let prevStep = step;

          step += 1;

          if (step > 7) {
            step = 1;
          }

          $element.addClass(`fa-progress-${step}`)
          $element.removeClass(`fa-progress-${prevStep}`)
        }, 180);

        $scope.$on('$destroy', () => {
          if (updateInterval) clearInterval(updateInterval);
        })
      },
      transclude: true,
      replace: true,
      template: `<i class="fa fa-progress-0"></i>`
    }
  });
