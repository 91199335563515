'use strict';

angular.module('app').controller('UserAccountActivateCashCertificateCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$uibModal', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, $uibModal, Alerts) {
    console.log("UserAccountActivateCashCertificateCtrl controller initialized with scope");

    $scope.currentCode = null;

    $scope.submitCode = function() {
      $http.post('/api/frontend/user_accounts/activate_code.json', { code: $scope.currentCode }).then(function(response) {
        if (response.data.success) {
          // Show informational modal window with voucher graphics
          var modalInstance = $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: `/ng/templates/user_accounts/cash_certificate_activated.html?locale=${I18n.locale}`,
            controller: 'UserAccountCashCertificateActivatedModelCtrl',
            windowClass: 'cash-certificate-activated-modal',
            size: 'md',
            resolve: {
              amount: function () {
                return response.data.amount;
              }
            }
          });

          $rootScope.$broadcast('user:balance:updated', {});
        } else {
          Alerts.error(response.data.error);
        }
      });
    };
  }
]);
