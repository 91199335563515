import * as sprintf from 'sprintf';

angular.module('app').controller('PromoJumboUnitCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$localStorage', '$attrs', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, $localStorage, $attrs, Alerts) {
    window.PromoJumboUnitCtrl = $scope;

    // Private members:
    var layerBackground = $element.find('.layer-background');
    var layerMessage = $element.find('.layer-message');
    var layerMessageImage = $element.find('.layer-message img');
    var layerTop = $element.find('.layer-top');
    var layerBottom = $element.find('.layer-bottom');

    // Public members:
    $scope.applySettings = function() {
      //layerMessage.css({'background-image': sprintf("url(%s)", $attrs.jumbotronMessage)});

      if ($attrs.jumbotronBackground)
        layerBackground.css({'background-image': sprintf("url(%s)", $attrs.jumbotronBackground)});

      if ($attrs.jumbotronBackgroundXs && window.isMobile)
        layerBackground.css({'background-image': sprintf("url(%s)", $attrs.jumbotronBackgroundXs)});

      if ($attrs.jumbotronMessage)
        layerMessageImage.attr({'src': $attrs.jumbotronMessage});

      if ($attrs.jumbotronTop)
        layerTop.attr({'src': $attrs.jumbotronTop});

      if ($attrs.jumbotronBottom)
        layerBottom.attr({'src': $attrs.jumbotronBottom});
    };

    // Event listeners and initialization:

    $scope.$on('$destroy', function() {
      window.PromoJumboUnitCtrl = null;
    });

    layerMessage.on('click', function() {
      location.href = $attrs.jumbotronLink;
    });

    $scope.applySettings();

    // console.log("PromoJumboUnitCtrl controller initialized");
  }
]);
