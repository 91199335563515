'use strict';

angular.module('app').controller('UserAccountCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$timeout', 'Alerts', 'UserService',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, $timeout, Alerts, UserService) {
    // console.log("UserAccountCtrl controller initialized with scope");

    $scope.userAccountData = null;

    $scope.loadAccountData = function() {
      $http.get('/api/frontend/user_accounts/my.json?locale=' + I18n.locale).then(function(response) {
        $scope.userAccountData = response.data.account;
      })
    };

    if (UserService.isLoggedIn) {
      $timeout(() => {
        $scope.loadAccountData();
      }, 2500)
    }

    $rootScope.$on('user:balance:updated', function(e) {
      $scope.loadAccountData();
    });

    $rootScope.$on('user:authenticated', function(e) {
      $scope.loadAccountData();
    });
  }
]);
