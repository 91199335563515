import React from 'react';
import styled, {keyframes, css} from "styled-components";

// Full View
const fullView = {
  loadingAnimation: keyframes`
    from {
      transform: translateY(-4px) rotate(.3deg);
      opacity: 0.8;
    }
    to {
      transform: translateY(4px) rotate(-.3deg);
      opacity: 1;
    }
  `,
  ItemWrapper: styled.div`
    height: 340px;
    background: #ffffff;
    box-shadow: 1px 1px 5px -1px rgb(0 0 0 / 25%);
    border-radius: ${props => props.theme.name === 'jucker' ? 0 : 6}px;
    
    &.animate {
      animation: ${props => css`350ms ${fullView.loadingAnimation} ease-in-out ${props.animationKey * 200}ms infinite alternate`};
    }
  `,
  ItemPhoto: styled.div`
    height: 220px;
    background-image: url('/stubs/product-catalog.jpg');
    position: relative;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `,
  ItemContent: styled.div`
    padding: 10px;

    .item-title {
      width: 60%;
      height: 20px;
      background: #eee;
    }

    .item-supplier {
      margin-top: 30px;
      width: 40%;
      height: 15px;
      background: #eee;
    }
  `,
  ItemPrice: styled.div`
    padding: 0 10px;
    text-align: right;

    .price {
      height: 20px;
      width: 40%;
      background: #eee;
      display: inline-block;
    }
  `
}

export const PlaceholderItemFull = (props) => {
  const shouldAnimate = props.animationKey || props.animationKey === 0;

  return (
    <fullView.ItemWrapper className={shouldAnimate ? 'animate' : null} animationKey={props.animationKey}>
      <fullView.ItemPhoto/>
      <fullView.ItemContent>
        <div className="item-title"></div>
        <div className="item-supplier"></div>
      </fullView.ItemContent>
      <fullView.ItemPrice>
        <div className="price"></div>
      </fullView.ItemPrice>
    </fullView.ItemWrapper>
  )
}

// Mobile Cards
const mobileCardsView = {
  loadingAnimation: keyframes`
    from {
      transform: translateY(-2px) rotate(.3deg);
      opacity: 0.8;
    }
    to {
      transform: translateY(2px) rotate(-.3deg);
      opacity: 1;
    }
  `,
  ItemWrapper: styled.div`
    background: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 1px 1px 5px -1px rgb(0 0 0 / 25%);
    min-height: 275px;
    border-radius: ${props => props.theme.name === 'jucker' ? 0 : 3}px;
    
    &.animate {
      animation: ${props => css`350ms ${mobileCardsView.loadingAnimation} ease-in-out ${props.animationKey * 200}ms infinite alternate`}
    }
  `,
  ItemPhoto: styled.div`
    background-image: url('/stubs/product-catalog.jpg');
    padding: 35% 0;
    position: relative;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `,
  ItemContent: styled.div`
    flex-grow: 1;
    padding: 0 5px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .quality-logos {
      width: 40%;
      height: 20px;
      background: #eee;
    }

    .item-title {
      margin-top: 10px;
      width: 90%;
      height: 15px;
      background: #eee;
    }
  `,
  ItemPrice: styled.div`
    padding: 0 10px 5px;
    text-align: right;

    .price {
      height: 20px;
      width: 50%;
      background: #eee;
      display: inline-block;
    }
  `
};

export const PlaceholderItemMobileCards = (props) => {
  const shouldAnimate = props.animationKey || props.animationKey === 0;

  return (
    <mobileCardsView.ItemWrapper className={shouldAnimate ? 'animate' : null} animationKey={props.animationKey}>
      <mobileCardsView.ItemPhoto/>
      <mobileCardsView.ItemContent>
        <div className="quality-logos"/>
        <div className="item-title"/>
      </mobileCardsView.ItemContent>
      <mobileCardsView.ItemPrice>
        <div className="price"/>
      </mobileCardsView.ItemPrice>
    </mobileCardsView.ItemWrapper>
  )
}

// List Mini
const listMini = {
  loadingAnimation: keyframes`
    from {
      transform: translateY(-2px) rotate(.3deg);
      opacity: 0.8;
    }
    to {
      transform: translateY(2px) rotate(-.3deg);
      opacity: 1;
    }
  `,
  ItemWrapper: styled.div`
    display: grid;
    grid-template-columns: 1fr 1.8fr;
    position: relative;
    height: 100px;
    background: white;
    border-radius: 7px;
    border: 1px solid #eee;
    
    &.animate {
      animation: ${props => css`350ms ${listMini.loadingAnimation} ease-in-out ${props.animationKey * 200}ms infinite alternate`};
    }
  `,
  ItemPhoto: styled.div`
    position: relative;
    background-image: url('/stubs/product-catalog.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 7px 0 0 7px;
    overflow: hidden;
  `,
  ItemContent: styled.div`
    padding: 10px;
    display: grid;
    grid-auto-rows: auto;
  `,
  ItemTitle: styled.div`
    width: 80%;
    height: 12px;
    background-color: #eee;
  `,
  ItemSupplier: styled.div`
    width: 40%;
    height: 10px;
    background-color: #eee;
  `,
  ItemPrice: styled.div`
    align-self: end;
    justify-self: flex-end;
    width: 30%;
    background-color: #eee;
    height: 10px;
    padding: 5px 10px 10px 10px;
    display: block;
  `
};

export const PlaceholderItemListMini = (props) => {
  const shouldAnimate = props.animationKey || props.animationKey === 0;

  return (
    <listMini.ItemWrapper className={shouldAnimate ? 'animate' : null} animationKey={props.animationKey}>
      <listMini.ItemPhoto/>
      <listMini.ItemContent>
        <listMini.ItemTitle/>
        <listMini.ItemSupplier/>
        <listMini.ItemPrice/>
      </listMini.ItemContent>
    </listMini.ItemWrapper>
  )
}

// Table
const TableLoadingAnimation = keyframes`
  from {
    transform: translateY(-2px) rotate(.3deg);
    opacity: 0.8;
  }
  to {
    transform: translateY(2px) rotate(-.3deg);
    opacity: 1;
  }
`;

const PHTableCol = styled.div`
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
  width: 12.5%;
  padding: 0 3px;
  
  &.animate {
    animation: ${props => css`350ms ${TableLoadingAnimation} ease-in-out ${props.animationKey * -200}ms infinite alternate`}
  }
`

const tableView = {
  ItemWrapper: styled.div`
    width: 100%;
    display: block;
  `,
  ItemContainer: styled.div`
    outline: none;
    cursor: pointer;
    background: none;
    border-radius: 0;
    padding: 3px 0 4px 0;
    margin: 0;
    width: 100%;
  `,
  ColProductThumb: styled(PHTableCol)`
    width: 64px;
    height: 45px;
    background-image: url('/stubs/product-catalog.jpg');
    background-size: cover;
    background-position: center;
  `,
  ColSupplierFlag: styled(PHTableCol)`
    width: 32px;
    padding-left: 11px;
    padding-top: 2px;
    height: 19px;
  `,
  Flag: styled.div`
    width: 19px;
    height: 19px;
    background-color: #eee;
  `,
  OtherCols: styled(PHTableCol)`
    width: 10.5%;
    padding-left: 9px;
  `,
  OtherColsInner: styled.div`
    width: 100%;
    height: 15px;
    background-color: #eee;
  `
};

export const PlaceholderItemTable = (props) => {
  const shouldAnimate = props.animationKey || props.animationKey === 0;

  return (
    <tableView.ItemWrapper>
      <tableView.ItemContainer>
        <tableView.ColProductThumb className={shouldAnimate ? 'animate' : null} animationKey={1}/>
        <tableView.ColSupplierFlag className={shouldAnimate ? 'animate' : null} animationKey={2}>
          <tableView.Flag/>
        </tableView.ColSupplierFlag>
        <tableView.OtherCols style={{width: "14%"}} className={shouldAnimate ? 'animate' : null} animationKey={3}>
          <tableView.OtherColsInner/>
        </tableView.OtherCols>
        <tableView.OtherCols style={{width: "16%"}} className={shouldAnimate ? 'animate' : null} animationKey={4}>
          <tableView.OtherColsInner/>
        </tableView.OtherCols>
        <tableView.OtherCols style={{width: "17%"}} className={shouldAnimate ? 'animate' : null} animationKey={5}>
          <tableView.OtherColsInner/>
        </tableView.OtherCols>
        <tableView.OtherCols style={{width: "14%"}} className={shouldAnimate ? 'animate' : null} animationKey={6}>
          <tableView.OtherColsInner/>
        </tableView.OtherCols>
        <tableView.OtherCols style={{width: "14%"}} className={shouldAnimate ? 'animate' : null} animationKey={7}>
          <tableView.OtherColsInner/>
        </tableView.OtherCols>
        <tableView.OtherCols style={{width: "14%"}} className={shouldAnimate ? 'animate' : null} animationKey={8}>
          <tableView.OtherColsInner/>
        </tableView.OtherCols>
      </tableView.ItemContainer>
    </tableView.ItemWrapper>
  )
}


export const PlaceholderItemMobile = (props) => {
  return (<div/>)
}


export const PlaceholderItemList = (props) => {
  return (<div/>)
}

export const PlaceholderItemSearch = (props) => {
  return (<div/>)
}

export const PlaceholderItemCart = (props) => {
  return (<div/>);
};
