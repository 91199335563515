import * as _ from 'underscore';

'use strict';

angular.module('app').controller('orderIssuesCtrl', ['$scope', '$http', '$timeout', '$location', 'blockUI', 'Alerts', '$element', 'OrderHelper', 'CartData',
  function ($scope, $http, $timeout, $location, blockUI, Alerts, $element, OrderHelper, CartData) {
    window.orderIssuesCtrl = $scope;

    function constructor() {
      $scope.issue = {
        type: null,
        type_alt: null,
        body: null,
        line_item: null,
        num_of_affected_units: '0'
      };

      $scope.previousType = '';
      $scope.generalType = '';
      $scope.previousGeneralType = '';
      $scope.showProducts = false;
      $scope.productBlockHeight = 'auto';
      $scope.quantityBlockHeight = 'auto';
      $scope.quantityBlockHeightCalculated = false;
      $scope.productBlockHeightCalculated = false;
      $scope.canSubmit = false;

      // Issue types dictionary
      $scope.issueTypes = {
        product: {
          name: 'Product', // TODO: translate!
          values: [
            'damaged_product',
            'wrong_product',
            'wrong_quantity',
            'missing_product',
          ]
        },
        packing: {
          name: 'Packaging',
          values: [
            'damaged_parcel',
            'bad_stacking',
          ]
        },
        delivery: {
          name: 'Delivery',
          values: [
            'courier_issues',
            'late_delivery',
            'early_delivery',
            'wrong_location',
            'no_delivery',
          ]
        }
      };

      $scope.generalTypes = _($scope.issueTypes).keys();

      window.scrollTo({top: 0})
    }

    function loadOrder() {
      let endpoint = `/api/orders/${$scope.orderNumber}.json?no_totals=t&line_item_product_ids=t`;

      $http.get(endpoint).then((response) => {
        $scope.order = response.data;
        loadItemProducts();
      })
    }

    function loadItemProducts() {
      let endpoint = `/api/products.json?ids=${$scope.order.line_item_product_ids.join(',')}&template=product_in_catalog&per_page=100&include_unavailable=t&include_unaddable=t`;

      $http.get(endpoint).then((response) => {
        let products = response.data.products;

        _(products).each((product) => {
          let line_item = ($scope.order.line_items).find((item) =>  item.product_id == product.id);

          if (line_item) {
            line_item.product = product;
            line_item.quantities = _(line_item.product.variants).map(v => { return {quantity_in_units: v.quantity_in_units, label: v.label} });
            line_item.received_quantity_index = _(line_item.quantities).findIndex(q => q.quantity_in_units == line_item.quantity_in_units).toString();
          }
        });

        $timeout(getProductBlockHeight, 10);
      })
    }

    function getProductBlockHeight() {
      $scope.productBlockHeightCalculated = false;
      $scope.productBlockHeight = 'auto';

      $timeout(() => {
        $scope.productBlockHeight = `${$('.product-list-wrapper').innerHeight()}px`;
        $scope.productBlockHeightCalculated = true;
      }, 100);
    }

    function collapseProductsAndScroll() {
      let element = document.querySelector('.issues-form-wrapper');
      scrollToElement(element);

      $scope.showProducts = false;
    }

    function scrollToElement(element) {
      let offsetCorrection = window.isMobile ? 65 : 150;
      let offset = element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offsetCorrection;
      window.scrollTo({top: offset, behavior: 'smooth'});
    }

    // Public

    $scope.expandProductsAndScroll = function(scrollToCurrentLineItem) {
      $scope.showProducts = true;
      scrollToCurrentLineItem = scrollToCurrentLineItem && $scope.issue.line_item;
      let timeout = 500;
      let element;

      $timeout(() => {
        if (scrollToCurrentLineItem)
          element = document.getElementById(`issue-product-${$scope.issue.line_item.id}`);
        else
          element = document.querySelector('.product-toggle');

        scrollToElement(element)
      }, timeout)
    };

    $scope.validateForm = function() {
      if (!$scope.generalType)
        $scope.canSubmit = false;
      else if ($scope.generalType == 'product')
        $scope.canSubmit = $scope.issue.type && $scope.issue.line_item && $scope.issue.body && $scope.issue.body.length > 0 ? true : false;
      else
        $scope.canSubmit = $scope.issue.type && $scope.issue.body && $scope.issue.body.length > 0 ? true : false;
    };

    $scope.onGeneralTypeChange = function() {
      $scope.issue.type = null;
    };

    $scope.onItemRemoved = function(event) {
      event.stopPropagation();
      $scope.issue.line_item = null;

      if ($scope.generalType == 'product')
        $scope.expandProductsAndScroll();

      $scope.validateForm()
    };

    $scope.onItemClicked = function(line_item) {
      $scope.issue.line_item = $scope.issue.line_item == line_item ? null : line_item;
      $scope.validateForm();
      if ($scope.issue.line_item) collapseProductsAndScroll();
    };

    $scope.onIssueTypeChanged = function() {
      if ($scope.issue.type && $scope.previousType != $scope.issue.type) {
        if ($scope.generalType == 'product' && !$scope.issue.line_item) {
          $scope.expandProductsAndScroll();
        }
      }

      $scope.previousType = $scope.issue.type;
      $scope.validateForm();
    };

    let orderWatcher = $scope.$watch('orderNumber', () => {
      loadOrder();
      orderWatcher();
    });

    $scope.$on('$destroy', function() {
      window.orderIssuesCtrl = null;
    });

    constructor();
  }
]);
