'use strict';

angular.module('app').controller('UserLoginViewCtrl', ['$scope', '$rootScope', '$timeout', '$window', '$uibModalInstance', '$location', 'Alerts', 'backToUrl', 'forcePageReload', function ($scope, $rootScope, $timeout, $window, $uibModalInstance, $location, Alerts, backToUrl, forcePageReload) {
  window.UserLoginViewCtrl = $scope;

  $scope.backToUrl = backToUrl;
  $scope.forcePageReload = forcePageReload;

  function constructor() {
    // Close popup on a link click
    setTimeout(() => {
      $('a[href]').on('click', $scope.dismiss);
    }, 250);
  }

  function destructor() {
    window.UserLoginViewCtrl = null;

    // Disable click listener
    $('a[href]').off('click', $scope.dismiss);
  }

  $scope.loginUser = {
    email: undefined,
    password: undefined
  };

  $scope.onLoginFormSubmit = function() {
    $scope.authenticationInProgress = true;

    UserService.authenticate($scope.loginUser.email, $scope.loginUser.password, $scope.loginUser.verify_token).then((user) => {
      if (user.pending_verification) {
        $scope.twoFactorVerificationPending = true;
        $scope.authenticationInProgress = false;

        if (user.pending_verification_message) {
          Alerts.info(user.pending_verification_message)
        }
      } else {
        $scope.twoFactorVerificationPending = false;
        $scope.authenticationInProgress = false;
        $uibModalInstance.close(user);

        if ($scope?.backToUrl) $location.path(decodeURIComponent($scope.backToUrl));
        if ($scope.forcePageReload) window.location.reload();
      }
    }, error => {
      $scope.authenticationInProgress = false;
    })
  };

  $scope.facebookLogin = function () {
    $scope.authenticationInProgress = true;
    UserService.authenticateBySocialNetwork('facebook')
      .then(() => {
          $scope.authenticationInProgress = false;
          $uibModalInstance.dismiss();
        }, (user) => {
          if (user.user_already_exists) {
            Alerts.facebook($translate.instant('social.facebook.already_exists', {email: user.email_old, strategy: 'Facebook'}), {timeout: 0});
          }
          else {
            window.localStorage.setItem('socialLoginUser', JSON.stringify(user));
            $uibModalInstance.dismiss();
            $location.path('/signup');
          }
          $scope.authenticationInProgress = false;
        }
      )
  };

  $scope.dismiss = function() {
    if ($uibModalInstance) {
      $uibModalInstance.dismiss();
    }
  };

  $scope.$on('$destroy', destructor);
  constructor();
}

]);
