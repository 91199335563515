import * as _ from "underscore";

/**
 * Abstraction for a list of product-items, capable of working with
 * collections assigned in parent scope, via attributes or via a 'named-set'
 * (named sets can work without a parent controller)
 *
 * @TODO: Support placeholder product display, respecting the layout
 */
angular.module("app").directive("farmyProductList", () => {
  return {
    controller: ["$rootScope", "$scope", "$q", "$window", "$http", "$element", "$timeout", "$location", "$translate", "Alerts", "CartData", "Hubs", function($rootScope, $scope, $q, $window, $http, $element, $timeout, $location, $translate, Alerts, CartData, Hubs) {
      if (!window.farmyProductLists) window.farmyProductLists = [];
      window.farmyProductLists.push($scope);

      let unOnLocaleChange;
      let _destroyed = false;

      function constructor() {
        $scope.browserIsBot = window.browserIsBot || window.prerenderAgent; // helps manage special UI states for crawler user-agents

        $scope.$watch("namedSet", (newValue, oldValue) => {
          if (newValue && newValue !== oldValue) $scope.loadNamedSetWithDebounce();
        });

        $scope.$watch("productIds", (newValue, oldValue) => {
          if (newValue && newValue !== oldValue) $scope.loadNamedSetWithDebounce();
        });

        // Modify layout on special parameter value 'smart'
        // smart layout switches between full and list views,
        // depending on the device
        $scope.$watch("layout", (newValue, oldValue) => {
          if (newValue && newValue === "smart") {
            $scope.layout = window.isMobile ? "list" : "full";
          }
        });

        if (($scope.namedset && $scope.namedset.length > 0) || $scope.productIds) { $scope.loadNamedSetWithDebounce() }

        // Inject list specific data into individual product records,
        // if applicable
        if ($scope.products && $scope.products.length > 0) {
          applyListPropertiesToProducts();
        }

        $scope.$watch("products", (newValue, oldValue) => {
          if (newValue && newValue.length > 0) {
            applyListPropertiesToProducts();
          }
        });
      }

      function destructor() {
        // Remove global reference
        _destroyed = true;

        if (window.farmyProductLists) {
          const i = window.farmyProductLists.indexOf($scope);
          if (i > -1) window.farmyProductLists.splice(i, 1);
        }

        if (unOnLocaleChange) unOnLocaleChange();

        $rootScope.$broadcast("productList:dismissed");
      }

      function applyListPropertiesToProducts() {
        if ($scope.itemReferrer !== null) {
          _.each($scope.products, product => {
            product.listItemReferrer = $scope.itemReferrer;
          });
        }
      }

      $scope.loadNamedSet = function(opts) {
        $scope.isLoading = true;
        $scope.searchEngine = $location.search().search_engine;

        const params = {
          locale: $translate.use() || I18n.locale,
          hub_id: Hubs.currentHub ? Hubs.currentHub.id : Hubs.currentHubId,
          zipcode: Hubs.currentZipcode ? Hubs.currentZipcode : null,
          template: "product_in_catalog"
        };

        if ($scope.taxonIds) params.taxon_ids = $scope.taxonIds;
        if ($scope.namedset) params.named_set = $scope.namedset;
        if ($scope.productIds) params.ids = $scope.productIds;
        if ($scope.includeUnavailable) params.include_unavailable = "t";
        if ($scope.includeUnaddable) params.include_unaddable = "t";
        if ($scope.expressDelivery === true) params.express_delivery = "t";
        if ($scope.nocache) params.no_cache = "t";
        if ($scope.searchEngine) params.search_engine = $scope.searchEngine;
        if ($scope.debugSearch) params.debug_search = "t";

        const limitTo = $scope.limitTo ? parseInt($scope.limitTo) : null;

        return $http.get("/api/products.json", {params}).then((result) => {
          if (_destroyed) return;

          if (!$scope.products) { $scope.products = [] } else { $rootScope.$broadcast("productList:loaded", $scope.products) }

          if (opts && opts.reset) $scope.products.length = 0;

          _.each(result.data.products, p => {
            if (!limitTo || $scope.products.length < limitTo) {
              if ($scope.itemReferrer) p.listItemReferrer = $scope.itemReferrer; // Set custom referrer to save with the line item
              $scope.products.push(p);
            }
          });
        }).finally(() => {
          $scope.isLoading = false;
        });
      };

      $scope.loadNamedSetWithDebounce = function(opts) {
        if ($scope.debounceLoad) {
          return $q((resolve, reject) => {
            setTimeout(() => {
              return $scope.loadNamedSet(opts);
            }, parseInt($scope.debounceLoad));
          });
        } else {
          return $scope.loadNamedSet(opts);
        }
      };

      constructor();
      $scope.$on("$destroy", destructor);
    }],
    scope: {
      products: "=",
      expressDelivery: "=",
      paginator: "<",
      layout: "@",
      columnClasses: "@",
      namedset: "@",
      trackingListName: "@",
      nocache: "@",
      limitTo: "@",
      taxonIds: "@",
      productIds: "@",
      debounceLoad: "@",
      includeUnavailable: "@",
      includeUnaddable: "@",
      itemReferrer: "@",
      parentNodeId: "@",
      isLoading: "<",
      hideMoreButton: "<",
      debugSearch: "@"
    },
    link: function($scope, $element, attributes) {
      if (!attributes.layout || attributes.layout === "" || attributes.layout === "smart") {
        attributes.layout = (window.isMobile ? "list" : "full");
      }
    },
    template: () => {
      return `<ngr-product-list
      products="products" express-delivery="expressDelivery" paginator="paginator" layout="layout" column-classes="{{columnClasses}}" namedset="{{namedset}}"
      tracking-list-name="{{trackingListName}}" nocache="{{nocache}}" limit-to="{{limitTo}}" taxon-ids="{{taxonIds}}" product-ids="{{productIds}}" debounce-load="{{debounceLoad}}"
      include-unavailable="{{includeUnavailable}}" include-unaddable="{{includeUnaddable}}" item-referrer="{{itemReferrer}}"
      parent-node-id="{{parentNodeId}}" is-loading="isLoading" hide-more-button="hideMoreButton" debug-search="{{debugSearch}}"/>`;
    }
  };
});
