'use strict';

angular.module('app').controller('UserEditBillingCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$q', '$translate', '$localStorage', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, $q, $translate, $localStorage, Alerts) {
    window.UserEditBillingCtrl = $scope;

    // Public properties

    //
    // Private members
    //

    function constructor() {
      $scope.getUserProfile();
    }

    function destructor() {
      window.UserEditBillingCtrl = null;
    }

    function updateUser(params) {
      return $q((resolve, reject) => {
        $scope.userLoading = true;
        blockUI.start();

        $http.put(`/api/frontend/users/${$scope.userId}.json`, params).then(function(response) {
          return $scope.getUserProfile();
            Alerts.success("");
        }, e => {
          Alerts.error("");
        }).finally(function() {
          blockUI.stop();

          $scope.userLoading = false;
        })
      })
    };

    /**
     * Load user profile data
     */
    $scope.getUserProfile = function () {
      return $q(function (resolve, reject) {
        $http.get('/api/users/current.json?noshipping=t&noorders=t').then(function (response) {
          $scope.userProfile = response.data;
          $scope.creditCards = $scope.userProfile.credit_cards.map(card => {
            return {...card, month: `0${card.month}`.slice(-2)}
          });
          resolve($scope.userProfile);
        })
      })
    };

    $scope.removeCreditCard = function(card) {
      return $q((resolve, reject) => {
        if (confirm($translate.instant('confirmation_prompt.body'))) {
          $http.post(`/api/frontend/payments/remove_card.json?card_id=${card.id}`).then(response => {
            $scope.getUserProfile().then(r => resolve());
            Alerts.success("<i class='fa fa-ok'></i>");
          })
        } else {
          reject();
        }
      })

    };

    $scope.updateBillingPreferences = function() {
      return updateUser({ group_invoices: $scope.userProfile.group_invoices })
    };

    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
