import * as _ from 'underscore';
import {trackEvent} from './tracking-helper';
import {cartTools} from "../react/cart/useCart";
import {globalModalQueue} from "../shared-services/globalState/topLayerState";
import globalState from "../shared-services/globalState";

const {signalCartSave, emptyCart: newEmptyCart} = cartTools();

angular.module('app').service(
  'CartData',
  ['$rootScope', '$q', '$http', '$interval', '$timeout', '$filter', 'WeeklyCartService', 'UnitsInflector',
    '$translate', '$cookies', '$uibModal', 'Hubs',
    function ($rootScope, $q, $http, $interval, $timeout, $filter, WeeklyCartService,
              UnitsInflector, $translate, $cookies, $uibModal, Hubs) {
    if (window.CartData) return window.CartData;
    const {pushToQueue} = globalModalQueue();

    let emptyCart = {
    id: window.currentOrderId,
    number: window.currentOrderNumber,
    token: window.currentOrderToken,
    line_items: []
  };

  let $scope = this || {};
  let scope = $scope; // semantic alias, for backward compatibility

  let loadedNearestDeliveryTimeAt = null;
  let lowCapacityPopupVisible = false;
  
  let oldCart = null;

  $scope.cart = angular.copy(emptyCart);
  $scope.isLoading = false;
  $scope.isUpdating = false;
  $scope.itemCount = 0;
  $scope.oldLocale = I18n.locale;
  $scope.checkedOtherCarts = false;
  $scope.updatesSynced = true;
  $scope.isExpressDelivery = false;
  $scope.canDoExpressDelivery = false;

  $scope.addToCartQueue = [];

  /**
   * When cart blocked is true, the client
   * will abort any attempt to modify cart contents,
   * reverting it to the last known state.
   *
   * This appeared as part of the 2020 covid crisis solution
   * to lower system load.
   *
   * @type {boolean}
   */
  $scope.cartBlocked = false;

  /**
   * Unless it is true, loading an order in complete state
   * will trigger a cart reset/reload, so that a complete order cannot
   * be modified
   * @type {boolean}
   */
  $scope.allowCompleteCart = false;

  /**
   * Will contain a reference to current ABO weekly, if the session is in ABO mode
   * @type {null}
   */
  $scope.currentWeekly = null;

  $scope.doUpdateCart = true;

  /**
   * Placeholder for translated text tokens that might be
   * used in a view
   * @type {{}}
   */
  $scope.translationData = {
    minimum_order_value_formatted: null
  };

  /**
   * Cache of product variants data, to be reused when working
   * with cart directly via CartData API without passing product
   * variant descriptions.
   *
   * @type {{}}
   */
  $scope.productDataCache = {};

  // Disable updates upon a specific parameter, for testing
  if (location.search.indexOf('disabled_cart_update') > -1)
    $scope.doUpdateCart = false;

  $scope.resetCart = function() {
    return $q((resolve, reject) => {
      $http.get('/api/frontend/orders/current.json').then(response => {
        $scope.cart.id = response.data.order.id;
        $scope.cart.number = response.data.order.number;
        $scope.cart.token = response.data.order.token;

        window.currentOrderId = $scope.cart.id;
        window.currentOrderNumber = $scope.cart.number;
        window.currentOrderToken = $scope.cart.token;

        let oldIsExpressDelivery = $scope.isExpressDelivery;
        $scope.isExpressDelivery = response.data.order.express_delivery ? true : false;

        $scope.load().then(() => {
          resolve()

          if (oldIsExpressDelivery != $scope.isExpressDelivery) {
            $rootScope.$broadcast('hubs:changed', {currentHub: Hubs.currentHub});
          }
        })
      }, e => reject(e));
    })
  };

  $scope.load = function() {
    $scope.isLoading = true;
    populateOldCart();

    let deferred = $q.defer();
    let currentOrderNumber = window.currentOrderNumber;

    if ($scope.currentWeekly == null) {
      if (currentOrderNumber == null) {
        if (window.currentOrderNumber == null) $scope.resetCart();
        deferred.reject();
        return deferred.promise;
      }

      $scope.loadRequestTimeout = $q.defer();
      $http.get('/api/frontend/orders/' + currentOrderNumber + '/cart.json', { params: { order_token: window.currentOrderToken, locale: I18n.locale}, timeout: $scope.loadRequestTimeout.promise }).then(function(response) {
        if (!$scope.isUpdating) {
          $scope.cart = response.data.cart;

          if ($scope.cart == null) $scope.cart = angular.copy(emptyCart);

          // Check if the order is complete and should not be loaded
          if (response.data.cart.state == 'complete') {
            $scope.isUpdating = false;
            return $scope.resetCart();
          }

          window.currentOrderId = $scope.cart.id;
          window.currentOrderNumber = $scope.cart.number;
          window.currentOrderToken = $scope.cart.token;
          $rootScope.currentUserPurchasingSmartPass = $scope.cart.smart_pass_purchase_order;

          $scope.translationData.minimum_order_value_formatted = "CHF " + $filter('pennies')($scope.cart.minimum_order_value);

          $scope.old_total = $scope.cart.total;

          // Imitate a hub-reload when isExpressDelivery changes from false to true
          let oldIsExpressDelivery = $scope.isExpressDelivery;

          $scope.isExpressDelivery = $scope.cart.express_delivery ? true : false;

          if (oldIsExpressDelivery !== $scope.isExpressDelivery) {
            $rootScope.$broadcast('express:changed', { express: $scope.isExpressDelivery });
            $rootScope.$broadcast('hubs:changed', {currentHub: Hubs.currentHub});
          }

          $scope.productIds = _.map($scope.cart.line_items, i => i.product_id);

          $scope.updateAdjustments();

          updateLegacyApi();
          updateSoftValidations();
          populateUpdatedProductIds();

          // Show multiple carts popup, if the last time we asked was more than half an hour ago
          // and we're not on a thank you page
          // Note: there are exceptions for the checkout and thank-you page
          if ($scope.incompleteOrders == null && !$scope.checkedOtherCarts && UserService.getSecondsSincePreferredCartOrderSet() > 2400 && window.CheckoutCtrl == null && location.href.indexOf('thankyou') == -1) {
            $scope.checkedOtherCarts = true;

            if (window.supplierPortalMode != true && window.PaymentsPayPageCtrl == null && location.href.indexOf("/payments") == -1) { // do not check multiple carts in supplier portal mode or on payment pages
              $timeout(() => {
                $scope.backgroundReloadIncompleteAndNotifyOnMultipleOrders();
              }, 3000)
            }
          }

          $rootScope.$broadcast('cartdata:loaded', {cart: $scope.cart, lastLocalUpdateAt: $scope.lastLocalUpdateAt, needsSave: $scope.needsSave()});
        }

        $scope.isLoading = false;
        deferred.resolve();
      }, function(response) {
        $scope.isLoading = false;
        deferred.reject(response);
      }).finally(function() {
        $scope.isLoading = false;
      });
    } else {
      $scope.isLoading = false;
      deferred.resolve();
    }

    return deferred.promise;
  };

  $scope.save = function() {
    if ($scope.reactIsPushing || $scope.reactIsLoading) return;

    let deferred = $q.defer();
    $rootScope.$broadcast("cart:update:start");

    if ($scope.currentWeekly == null) {

      $scope.isUpdating = true;
      populateOldCart();

      try {
        // Abort active load request, if there is any
        if ($scope.loadRequestTimeout) $scope.loadRequestTimeout.resolve();

        let mappedLineItems = _.map($scope.cart.line_items, function(lineItem) {
          return {
            product_id: lineItem.product_id,
            variant_id: lineItem.variant.id,
            price: lineItem.variant.price,
            quantity_in_units: lineItem.variant.quantity_in_units,
            referrer: lineItem.referrer
          }
        });

        let updateParams = {
          order_token: currentOrderToken,
          line_items: mappedLineItems,
          session_hub_id: Hubs.currentHub && Hubs.currentHub.id
        };

        $scope.lastSaveRequestStartedAt = moment();
        $http.patch('/api/frontend/orders/' + ($scope.cart && $scope.cart.number ? $scope.cart.number : 'null') + '/cart.json', updateParams).then(function(response) {
          updateLegacyApi();

          if ($scope.cart && response.data.cart) {
            // Do a full update if the cart is not initialized yet
            if ($scope.cart.id == null) {
              angular.extend($scope.cart, response.data.cart);
              $scope.translationData.minimum_order_value_formatted = "CHF " + $filter('pennies')($scope.cart.minimum_order_value);
            } else {
              $scope.cart.total = response.data.cart.total;
              $scope.cart.item_total = response.data.cart.item_total;
              $scope.cart.adjustments = response.data.cart.adjustments;
              $scope.cart.cart_summary = response.data.cart.cart_summary;
              $scope.cart.line_items = response.data.cart.line_items;

              if (response.data.cart.minimum_order_value) {
                $scope.cart.minimum_order_value = parseFloat(response.data.cart.minimum_order_value);
              }
            }

            window.currentOrderId = $scope.cart.id;
            window.currentOrderNumber = $scope.cart.number;
            window.currentOrderToken = $scope.cart.token;
          }

          $scope.updateAdjustments();
          $scope.updateTotal();

          updateSoftValidations();
          populateUpdatedProductIds();

          $rootScope.$broadcast("cartdata:saved", $scope.cart);
          $rootScope.$broadcast("cart:changed", $scope.cart);

          // Send Piwik event if total changed
          if ($scope.cart.total !== $scope.old_total) {
            $scope.old_total = $scope.cart.total;
          }

          if (response.data.update_cart_errors && response.data.update_cart_errors.length > 0) {
            Alerts.error(response.data.update_cart_errors.join(", "));
          }

          $scope.updatesSynced = true;
          deferred.resolve();
        }, function(response) {
          deferred.reject(response);
        }).finally(function() {
          $scope.isUpdating = false;
          $scope.isLoading = false;
          $rootScope.$broadcast("cart:update:finish", $scope.cart);
        });
      } catch (e) {
        deferred.reject(e);
        $scope.isUpdating = false;
        $scope.isLoading = false;
        $rootScope.$broadcast("cart:update:finish", $scope.cart);
        // console.error(e);
      }
    } else {
      deferred.resolve();
    }

    return deferred.promise;
  };

  /**
   * Sets the variant of this product in the cart.
   * @param productId
   * @param variant Object with id, label, price, quantity_in_units keys
   * @returns {*}
   */
  $scope.setCartVariant = function(productId, variant, referrer, productOptions, product = null, options) {
    $scope.cart.isUpdating = true;
    const deferred = $q.defer();

    if ($scope.cartBlocked) {
      $scope.showLowCapacityPopup();
      deferred.reject("cart_blocked");
      $scope.cart.isUpdating = false;
      return deferred.promise;
    }

    if ($scope.currentWeekly == null) { // normal shopping mode

      populateOldCart();
      let lineItem = this.getLineItemForProduct(productId);

      // GTM needed variables
      const oldVariant = options?.oldVariant || lineItem?.currentVariant || lineItem?.variant || {quantity_index: 0, price: 0, quantity_in_units: 0};
      const newVariant = variant?.variantObject ? {variant, ...variant.variantObject} : variant;
      const trackingQuantity = (newVariant?.quantity_in_units || 0) - (oldVariant?.quantity_in_units || oldVariant?.qiu || 0);
      let gtmValue = Math.abs(parseFloat(((newVariant?.price || 0) - (oldVariant?.price || 0)).toFixed(2)));

      if (lineItem == null && variant != null) { // Add to cart
        lineItem = {
          product_id: productId,
          quantity: 1,
          price: variant.price,
          total: variant.price,
          variant: variant,
          variant_id: variant.id,
          referrer: referrer,
          ax_primary_taxon: productOptions && productOptions.ax_primary_taxon ? productOptions.ax_primary_taxon : null,
          quantity_in_units: variant.qui || variant.quantity_in_units,
          productData: product || productOptions
        };

        $scope.cart.line_items.push(lineItem);
        $scope.productIds = _.map($scope.cart.line_items, i => i.product_id);

        // Update GTM variables for null lineItem
        gtmValue = Math.abs(parseFloat((newVariant?.price - oldVariant?.price).toFixed(2)));

        $rootScope.$broadcast("cart:product:added", lineItem.productData || {id: productId});
        if (!lineItem.productData) {
          $scope.getProductDataById(lineItem.product_id).then(response => {
            trackEvent(
              "addtocart",
              {
                gtmValue: {value: gtmValue},
                products: {...lineItem, productData: response, trackingQuantity: Math.abs(trackingQuantity)},
                callerLocation: options?.callerLocation
              }
            );
          });
        } else {
          trackEvent(
            "addtocart",
            {
              gtmValue: {value: gtmValue},
              products: {...lineItem, trackingQuantity: Math.abs(trackingQuantity)},
              callerLocation: options?.callerLocation
            }
          );
        }
      } else if (variant == null && lineItem != null) { // Remove from cart
        $scope.cart.line_items = _.without($scope.cart.line_items, _.findWhere($scope.cart.line_items, {product_id: lineItem.product_id}));
        $scope.productIds = _.map($scope.cart.line_items, i => i.product_id);
        trackEvent(
          "removefromcart",
          {
            gtmValue: {value: gtmValue},
            products: {...lineItem, trackingQuantity: Math.abs(trackingQuantity)},
            callerLocation: options?.callerLocation
          }
        ).finally(() => {
          trackEvent(
            "product_removed_from_cart",
            {
              gtmValue: {value: gtmValue},
              products: {...lineItem, trackingQuantity: Math.abs(trackingQuantity)}
            }
          )
        });
      } else if (lineItem != null) { // Update line item
        lineItem.variant = variant;
        lineItem.price = variant.price;
        lineItem.total = variant.price;
        lineItem.quantity_in_units = variant.qui || variant.quantity_in_units;
        lineItem.variant_id = variant.id;
        trackEvent(
          trackingQuantity < 0 ? 'removefromcart' : 'addtocart',
          {
            gtmValue: {value: gtmValue},
            products: {...lineItem, trackingQuantity: Math.abs(trackingQuantity)},
            callerLocation: options?.callerLocation
          }
        );
      } else {
        // console.warn("Invalid cart change for", productId, variant);
      }

      $scope.updateTotal();

      $scope.cart.updated_at = moment().format();
      $scope.lastLocalUpdateAt = moment();

      populateUpdatedProductIds();
      $rootScope.$broadcast("cart:changed", {from: "setCartVariant", cart: $scope.cart, lastLocalUpdateAt: $scope.lastLocalUpdateAt, needsSave: $scope.needsSave()});

      if (!Hubs.currentZipcode) {
        pushToQueue("zipcode", {
          onClose: () => {
            const {getGlobalState} = globalState();
            const {currentZipcode} = getGlobalState("session");

            if (!currentZipcode) {
              newEmptyCart();
            } else signalCartSave();
          }
        });

        return deferred.promise;
      }

      signalCartSave();

      $scope.cart.isUpdating = false;
      deferred.resolve($scope.cart);
    } else { // weekly item list mode
      WeeklyCartService.updateItem(productId, variant, { referrer: referrer }).then(() => {
        $scope.updateTotal();

        $scope.cart.updated_at = moment().format();
        $scope.lastLocalUpdateAt = moment();

        $rootScope.$broadcast('cart:changed', {from: 'setCartVariant:weekly', cart: $scope.cart, lastLocalUpdateAt: $scope.lastLocalUpdateAt, needsSave: $scope.needsSave()});

        $scope.cart.isUpdating = false;
        deferred.resolve($scope.cart);
      }).then(() => {
        $scope.itemCount = WeeklyCartService.currentWeekly.items.length;
        $scope.cart.isUpdating = false;
      });
    }

    return deferred.promise;
  };

  $scope.getLineItemForProduct = function(productId) {
    return _.find($scope.cart.line_items, function (li) {
      return li.product_id == productId;
    });
  };

  $scope.containsProduct = function(productId) {
    if (!$scope.cart || $scope.cart.line_items.length == 0) {
      return false;
    }

    return _.any($scope.cart.line_items, function (line_item) {
      return line_item.product_id == productId;
    });
  };

  $scope.updateAdjustments = function() {
    $scope.oldAdjustmentsLength = $scope.oldAdjustmentsLength || 0;
    $scope.cart.non_zero_adjustments = _.reject($scope.cart.adjustments, a => a.amount == 0);

    let hasShippingFee = false;

    $scope.cart.adjustments.forEach(adjustment => {
      const withModal = [':handling_fee'];
      if (withModal.includes(adjustment.raw_label || adjustment.code))
        adjustment.clickable = true;

      adjustment.isShippingFee = adjustment.code === ':shipping'
      if (adjustment.isShippingFee) hasShippingFee = true;
    })

    $scope.hasShippingFee = hasShippingFee;

    if ($scope.oldAdjustmentsLength !== $scope.cart.non_zero_adjustments.length)
      $rootScope.$broadcast('cart:changed', {from: 'updateAdjustments', cart: $scope.cart, lastLocalUpdateAt: $scope.lastLocalUpdateAt, needsSave: $scope.needsSave()});

    $scope.oldAdjustmentsLength = $scope.cart.non_zero_adjustments.length;

    setMaxShippingCost();
    setShouldShowAdjustments();
  };

  $scope.updateTotal = function() {
    if ($scope.currentWeekly == null) {
      $scope.cart.total = 0;
      $scope.cart.item_total = 0;

      _.each($scope.cart.line_items, function(lineItem) {
        $scope.cart.total += parseFloat(lineItem.price);
        $scope.cart.item_total += parseFloat(lineItem.price);
      });

      // Deduct adjustments
      $scope.cart.total = $scope.cart.total + _.reduce($scope.cart.adjustments, (memo, a) => memo + a.amount, 0);
    } else {
      $scope.cart.total = WeeklyCartService.getTotal();
      $scope.cart.item_total = WeeklyCartService.getTotal();
    }
  };

  $scope.synchronized = function () {
    return $scope.updatesSynced;
  };

  $scope.updating = function () {
    return $scope.isUpdating;
  };

  $scope.needsSave = function () {
    if ($scope.reactIsPushing || $scope.reactIsLoading) return false;

    if (!$scope.lastLocalUpdateAt) {
      return false;
    }

    if (!$scope.lastSaveRequestStartedAt || $scope.lastLocalUpdateAt > $scope.lastSaveRequestStartedAt) {
      // Check time to last update, if it was less than a some hundreds of milliseconds ago,
      // cancel the update, because another fast update maybe incoming
      if (moment() - $scope.lastLocalUpdateAt < 150) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  $scope.setCurrentOrder = function(order) {
    window.currentOrderId = order.id;
    window.currentOrderNumber = order.number;
    window.currentOrderToken = order.token;

    $scope.cart = angular.copy(emptyCart);
    $scope.cart.number = order.number;
    $scope.cart.id = order.id;
    $scope.cart.token = order.token;

    // Current order changed in the session as well
    $http.patch(`/api/frontend/orders/${$scope.cart.number}/current.json`, { order_token: $scope.cart.token }, { withCredentials: true }).then(response => {
      let encodedSession = response.data.encoded_session;

      // Update user Session
      if (encodedSession && !UserService.loggingOut) {
        UserService.updateCurrentSessionCookie(encodedSession);
      }
    });

    return $scope.load();
  };

  $scope.startUpdating = function() {
    $scope.doUpdateCart = true;
  };

  $scope.stopUpdating = function() {
    $scope.doUpdateCart = false;
  };

  // Public API for usage by connected native mobile clients

  $scope.getProductDataById = function(productId) {
    return $q(function(resolve, reject) {
      if ($scope.productDataCache[productId]) {
        resolve($scope.productDataCache[productId]);
      } else {
        $http.get(sprintf('/api/frontend/products/%s.json?include_unaddable=t', productId)).then(function(response) {
          $scope.productDataCache[productId] = response.data.product;
          resolve($scope.productDataCache[productId]);
        }, function(error) {
          reject(error);
        })
      }
    });
  };

  $scope.setProductCount = function(productId, variantQuantityIndex, referrer) {
    let lineItem = this.getLineItemForProduct(productId);

    return $q(function(resolve, reject) {
      $scope.getProductDataById(productId).then(function(productData) {
        let variant = _.find(productData.variants, function (v) {
          return v.quantity_index == variantQuantityIndex;
        });

        if (variant) {
          $scope.setCartVariant(productId, {
            id: variant.id,
            price: variant.price,
            quantity_in_units: variant.quantity_in_units,
            quantity_index: variant.quantity_index
          }, referrer);
        } else if (variant == null && variantQuantityIndex == 0) {
          $scope.setCartVariant(productId, null);
        } else {
          // Already at maximum capacity per product
          // TODO: Notify?
          reject('maxcount');
          return;
        }

        resolve(variant);
      });
    });
  };

  $scope.setProductQuantity = function(productId, quantityInUnits, referrer) {
    let lineItem = this.getLineItemForProduct(productId);

    return $q(function(resolve, reject) {
      $scope.getProductDataById(productId).then(function(productData) {
        let variant = _.find(productData.variants, function (v) {
          return v.quantity_in_units == quantityInUnits;
        });

        if (variant) {
          $scope.setCartVariant(productId, {
            id: variant.id,
            price: variant.price,
            quantity_in_units: variant.quantity_in_units,
            quantity_index: variant.quantity_index
          }, referrer);
        } else if (variant == null && quantityInUnits == 0) {
          $scope.setCartVariant(productId, null);
        } else {
          // Already at maximum capacity per product
          // TODO: Notify?
          reject('maxcount');
          return;
        }

        resolve(variant);
      });
    });
  };

  $scope.increaseProductCount = function(productId, referrer) {
    let lineItem = $scope.getLineItemForProduct(productId);
    let nextIndex = lineItem ? lineItem.variant.quantity_index + 1 : 1;

    return $q(function(resolve, reject) {
      $scope.getProductDataById(productId).then(function(productData) {
        let maxVariantIndex = _.max(_.map(productData.variants, function(v) { return v.quantity_index }));

        if (nextIndex <= maxVariantIndex) {
          let variant = _.find(productData.variants, function(v) {
            return v.quantity_index == nextIndex;
          });

          $scope.setCartVariant(productId, {
            id: variant.id,
            price: variant.price,
            quantity_in_units: variant.quantity_in_units,
            quantity_index: variant.quantity_index
          }, referrer);
        } else {
          // Already at maximum capacity per product
          // TODO: Notify?
          reject('maxcount');
          return;
        }

        resolve(variant);
      });
    });
  };

  $scope.decreaseProductCount = function(productId, removeAll) {
    return $q(function(resolve, reject) {
      let lineItem = $scope.getLineItemForProduct(productId);

      if (!lineItem) {
        reject();
        return;
      }

      let nextIndex = lineItem.variant.quantity_index > 1 ? lineItem.variant.quantity_index - 1 : 0;

      $scope.getProductDataById(productId).then(function(productData) {
        let variant = _.find(productData.variants, function(v) {
          return v.quantity_index == nextIndex;
        });

        if (removeAll || nextIndex == 0 || variant == null) {
          $scope.setCartVariant(productId, null);
          // $rootScope.markedForSaving = true;
          resolve();
        } else {
          $scope.setCartVariant(productId, {
            id: variant.id,
            price: variant.price,
            quantity_in_units: variant.quantity_in_units,
            quantity_index: variant.quantity_index
          });

          resolve(variant);
        }
      });
    });
  };

  $scope.removeLineItem = function(lineItem) {
    return $q((resolve, reject) => {
      populateOldCart();

      let i = $scope.cart.line_items.indexOf(lineItem);
      $scope.cart.line_items.splice(i, 1);
      $scope.cart.updated_at = moment().toString();

      $scope.updateTotal();

      const data = {
        number: $scope.cart.number,
        order_token: $scope.cart.token,
        product_id: lineItem.product_id
      };

      $http
          .post(`/api/frontend/orders/${$scope.cart.number}/remove_line_item.json`, data)
          .then(response => {
            angular.extend($scope.cart, response.data.cart);
            $scope.productIds = _.map($scope.cart.line_items, i => i.product_id);
            $scope.updateAdjustments();
            $scope.updateTotal();
            populateUpdatedProductIds();
            $rootScope.$broadcast("cartdata:saved", $scope.cart);
            $rootScope.$broadcast("cart:changed", {from: "removeLineItem", cart: $scope.cart, lastLocalUpdateAt: $scope.lastLocalUpdateAt, needsSave: $scope.needsSave()});
            resolve($scope.cart);
          }, (e) => {
            Alerts.error(errorMessage(e));
            reject(e);
          })
    })
  };

  $scope.loadIncompleteOrders = function() {
    return $q((resolve, reject) => {
      $http.get(`/api/frontend/orders/incomplete.json?exclude_order_id=${$scope.cart.id}&exclude_zero=t&no_addresses=t&no_payments=t&no_shipments=t&no_adjustments=t&no_min_order_value=t&no_delivery_slot=t&no_state_specific=t&line_item_count=t`).then(response => {
        // Check if there's more than one order that is not the current
        let orders = (response.data && response.data.orders) || [];
        let currentOrder = angular.copy($scope.cart);
        currentOrder.isCurrent = true;
        currentOrder.line_item_count = currentOrder.line_items && currentOrder.line_items.length;

        // Check if the order is already on the list (may happen during automatic cart switch)
        let alreadyIn = _.find(orders, o => o.id == currentOrder.id);

        if (alreadyIn) {
          alreadyIn.isCurrent = true;
        } else orders.splice(0, 0, currentOrder); // Add current order to the collection on top

        // A hack to let the user_menu in the header and mobile sidebar now
        // that there are multiple carts for the session
        UserService.hasOtherCartOrders = true;

        $scope.incompleteOrders = orders;

        resolve(orders);
      }, e => reject(e));
    })
  };

  /**
   * Reloads incomplete orders, changes to the optimal cart (if any) or shows a popup
   */
  $scope.backgroundReloadIncompleteAndNotifyOnMultipleOrders = function() {
    $scope.loadIncompleteOrders().then(orders => {
      // IMPORTANT HIDDEN WORKFLOW:
      // Detect if there's just one incomplete order with items,
      // while the current order is empty. Then switch automatically
      // to the other order. If there are more options - show the popup
      if (orders.length == 2) {
        let otherOrder = _.find(orders, o => !o.isCurrent);

        if (otherOrder && otherOrder.item_total != 0 && $scope.cart.item_total == 0 && ($scope.cart.line_items == null || $scope.cart.line_items.length == 0)) {
          // Swap current empty order with the only other non-empty option:
          console.info("Switching current empty cart to order: ", otherOrder.number);
          $scope.setCurrentOrder(otherOrder)
        } else {
          $scope.notifyOnMultipleCartOrders(orders)
        }
      } else if (orders.length > 2) {
        $scope.notifyOnMultipleCartOrders(orders)
      }
    })
  };

  /**
   * Just reloads incomplete orders and shows the popup
   *
   * @returns {*}
   */
  $scope.reloadIncompleteAndNotifyOnMultipleCartOrders = function() {
    return $q((resolve, reject) => {
      $scope.loadIncompleteOrders().then(orders => {
        $scope.notifyOnMultipleCartOrders(orders);
        resolve(orders);
      }, e => reject(e));
    });
  };

  /**
   * Looks for alternative cart-state orders that may be presented to
   * the user to select as the 'current' order. Will display a popup,
   * if such alternative orders with recent update timestamp are located.
   */
  $scope.notifyOnMultipleCartOrders = function(orders) {
    return $q((resolve, reject) => {
      let modalInstance = $uibModal.open({
        animation: true,
        templateUrl: '/ng/templates/orders/select_current_order_modal.html',
        controller: 'SelectCurrentOrderModalView',
        windowClass: 'select-current-order-modal',
        size: 'lg',
        resolve: {
          orderData: { orders: orders }
        }
      });

      modalInstance.result.then(result => {
        console.info('Modal completed with result', result);
      }, (e) => {
        console.info('Modal dismissed at: ' + new Date());
      });

      modalInstance.result.then(result => {
        console.info('Modal completed with result', result);
      }, (e) => {
        console.info('Modal dismissed at: ' + new Date());
      });

      resolve(orders);
    });
  }

  /**
   * Switches CartData to working with weekly contents
   * instead of the current order. Used when modifying a weekly from a
   * weekly edit page.
   *
   * @param weekly
   */
  $scope.setWeeklyMode = function(weekly) {
    WeeklyCartService.currentWeekly = weekly;
    $scope.currentWeekly = weekly;

    $scope.updateTotal();
    $scope.itemCount = WeeklyCartService.currentWeekly.items.length;
  };

  $scope.leaveWeeklyMode = function() {
    WeeklyCartService.currentWeekly = null;
    $scope.currentWeekly = null;
    $scope.itemCount = $scope.cart.line_items ? $scope.cart.line_items.length : 0;
    $scope.updateTotal();
  };

  $scope.emptyCart = function() {
    blockUI.start();
    $scope.isClearingCart = true;
    $scope.isUpdating = true;

    return $http.get('/cart/empty').then(result => {
      $scope.load().then(result => {
        blockUI.stop();
      }, e => blockUI.stop())
    }, e => {
      Alerts.error(errorMessage(e));
      blockUI.stop();
    }).finally(() => {
      $scope.isUpdating = false;
    })
  };

  $scope.loadNearestDeliveryTime = function() {
    loadedNearestDeliveryTimeAt = (new Date()).getTime();

    return $q((resolve, reject) => {
      if (Hubs.currentZipcode == null) {
        reject(false);
        return
      }

      $scope.nearestDeliveryTimeLoading = true

      $http.get(`/api/farmy/delivery_slots/nearest_delivery_date?zipcode=${Hubs.currentZipcode}&locale=${I18n.locale}&express_delivery=${$scope.isExpressDelivery ? 't' : 'f'}`).then(response => {
        window.nearestDeliveryDateByXpressMode = {
          express: response.data.express && response.data.express.date,
          regular: response.data.regular && response.data.regular.date
        };

        $scope.nearestDeliveryDate = response.data.date;
        $scope.nearestDeliverySlot = response.data.slot;
        $scope.nearestDeliveryDateByXpressMode = {
          express: response.data.express && response.data.express.date,
          regular: response.data.regular && response.data.regular.date};
        $scope.nearestDeliverySlotByXpressMode = {
          express: response.data.express && response.data.express.slot,
          regular: response.data.regular && response.data.regular.slot};
        $scope.areaUnavailable = response.data.area_unavailable
        $scope.nearestDeliveryResponseReceived = true;
        $scope.canDoExpressDelivery = response.data.express_delivery ? true : false;
        if ($scope.nearestDeliverySlot == null)
          $scope.cartBlocked = true;
        else
          $scope.cartBlocked = false;

        buildNearestDeliveryTermLink();
        $rootScope.$broadcast('nearestDeliveryDetails:updated',
          {
            nearestDeliveryDate: $scope.nearestDeliveryDate,
            nearestDeliverySlot: $scope.nearestDeliverySlot,
            nearestDeliveryDateByXpressMode: $scope.nearestDeliveryDateByXpressMode,
            nearestDeliverySlotByXpressMode: $scope.nearestDeliverySlotByXpressMode,
            areaUnavailable: $scope.areaUnavailable,
            canDoExpressDelivery: $scope.canDoExpressDelivery
          });
        $scope.nearestDeliveryTimeLoading = false;

        resolve(response.data);
      }).finally(() => $scope.nearestDeliveryTimeLoading = false);
    })
  };

  $scope.showLowCapacityPopup = function() {
    if (lowCapacityPopupVisible) return false;

    lowCapacityPopupVisible = true;

    let modal = $uibModal.open(
      {
        animation: true,
        size: 'md',
        keyboard: false,
        backdrop: 'static',
        templateUrl: '/ng/templates/carts/low_capacity_cart_blocked_modal.html',
        windowClass: 'plain-modal modal-rounded low-capacity-modal',
        controller: 'PlainModalCtrl',
        resolve: { }
      });

    modal.result.then(function(result) {
      // Do nothing on close
      lowCapacityPopupVisible = false;
    }, e => {
      lowCapacityPopupVisible = false;
    });

    return true;
  };

  $scope.launchExpressWarningPopup = function() {
    let warningModal = $uibModal.open(
      {
        animation: true,
        size: 'md',
        keyboard: false,
        backdrop: 'static',
        templateUrl: `/ng/templates/express_delivery/warning_modal`,
        windowClass: 'express-warning-modal modal-rounded',
        controller: 'ExpressDeliveryWarningModalCtrl',
        resolve: {
          currentDeliveryMode: () => $scope.isExpressDelivery ? 'express' : 'regular'
        }
      });

    return new Promise((resolve, reject) => {
      warningModal.result.then(function(result) {
        if (result.response === 'confirm') {
          let requestedExpressMode = result.requestedExpressMode;
          $scope.setExpressDelivery(requestedExpressMode).then(r => resolve(r));
        } else
          resolve(false);
      });
    })
  }

  $scope.expressProductsInCart = function() {
    return $scope.cart.line_items.filter(i => i.express_delivery === true)
  }

  $scope.nonExpressProductsInCart = function() {
    return $scope.cart.line_items.filter(i => i.express_delivery === false)
  }

  $scope.hasNonExpressProducts = function() {
    return $scope.nonExpressProductsInCart().length > 0;
  }

  $scope.hasExpressProducts = function() {
    return $scope.expressProductsInCart().length > 0;
  }

  $scope.setExpressOrLaunchWarningPopup = function(enabled) {
    return new Promise((resolve, reject) => {
      if ($scope.cart.express_delivery) {
        if (!$scope.hasExpressProducts()) {
          $scope.setExpressDelivery(enabled).then(r => resolve(true));
        } else {
          $scope.launchExpressWarningPopup().then(r => resolve(r))
        }
      } else {
        if (!$scope.hasNonExpressProducts()) {
          $scope.setExpressDelivery(enabled).then(r => resolve(true));
        } else {
          $scope.launchExpressWarningPopup().then(r => resolve(r))
        }
      }
    })
  }

  $scope.setExpressDelivery = function(enabled) {
    const params = {
      express_delivery: enabled ? 't' : 'f',
      order_token: $scope.cart.token
    };

    return new Promise((resolve, reject) => {
      $http.patch(`/api/frontend/orders/${$scope.cart.number}/update_express_delivery.json`, params).then(response => {
        $rootScope.$broadcast('express:changed', { express: response.data.express_delivery });
        $scope.load().then(r => resolve(response.data));
      })
    })
  }

  $scope.isUnderMinimumOrderValue = function() {
    return $scope.cart.item_total < $scope.cart.minimum_order_value;
  }

  //
  // Private members
  //

  function setShouldShowAdjustments() {
    $scope.shouldShowAdditionalFees = shouldShowAdditionalFees();

    $scope.shouldShowAdjustments = ($scope.cart.non_zero_adjustments && $scope.cart.non_zero_adjustments.length > 0) ||
      $scope.cart.weight_control_surcharge_total > 0 ||
      $scope.shouldShowAdditionalFees;
  }

  // Logic for "shipping fee"
  function shouldShowAdditionalFees() {
    // Remove this line when "handling fee" feature is open to everybody.
    if (UserService.isLoggedIn && !UserService.currentUser.should_apply_handling_fee)
      return false;

    let should = !$scope.hasShippingFee && !$scope.cart.has_valid_delivery_slot &&
      $scope.maxShippingCost > 0 &&
      $scope.cart.item_total < $scope.cart.min_value_for_free_shipping;

    should = should || $scope.cart.handling_fee > 0;

    return should
  }

      function setMaxShippingCost() {
        $scope.maxShippingCost = $scope.cart.approximate_shipping_cost ?? 0;
      }

  // Automatically sets Xpress delivery mode ON if "switch-express-on" parameter is found.
  // Zipcode needs to match with Xpress zone, and order is either empty or not containing any non-express products.
  function shouldSetXpressFromUrlParameter() {
    if (window.currentStorefront) return;

    let urlParams = new URLSearchParams(location.search);

    if (urlParams.get('xpress') === 't' && urlParams.get('szp') && urlParams.get('szp').length === 4) {
      let deliveryTermsWatcher = $rootScope.$on('hubs:delivery_terms:loaded', () => {
        if (Hubs.deliveryTerms && Hubs.deliveryTerms.zipcode) {
          if (Hubs.deliveryTerms.zipcode.can_do_express_delivery) {
            $scope.setExpressOrLaunchWarningPopup(true)
          }

          deliveryTermsWatcher()
        }
      })
    }
  }

  function ensureNonXpressForStorefronts() {
    if (window.currentStorefront && $scope.isExpressDelivery)
      $scope.setExpressDelivery(false);
  }

  // Copy the contents of current cart for later comparison.
  function populateOldCart() {
    oldCart = {
      items: $scope.cart && $scope.cart.line_items ? angular.copy($scope.cart.line_items) : null,
      productIds: $scope.productIds
    }
  }

  /**
   * Creates an array of "updatedProductIds" and appends it to the cart object.
   * Both new or removed products, along with those with quantity changes.
   * */
  function populateUpdatedProductIds() {
    let commonProductIds = _($scope.productIds).intersection(oldCart.productIds);
    let needAttentionIds = _(_($scope.productIds)
      .union(oldCart.productIds))
      .difference(commonProductIds);

    let updatedItemIds = _(_(_($scope.cart.line_items)
      .filter(item => commonProductIds.indexOf(item.product_id) > -1))
      .select((item) => {
        let oldItem = _(oldCart.items).find(i => i.product_id == item.product_id);
        return oldItem == null || item.quantity_in_units != oldItem.quantity_in_units
      }))
      .map(item => item.product_id);

    $scope.cart.updatedProductIds = _(updatedItemIds).union(needAttentionIds);
  }

  /**
   * Sets some soft validation flag on cart items, like hub-compatibility
   */
  function updateSoftValidations() {
    if ($scope.cart.line_items == null) return;

    _.each($scope.cart.line_items, item => {
      item.isHubIncompatible = item.hub_ids && item.hub_ids.indexOf($scope.cart.hub_id) == -1; // post-lost check of hub compatibility
    });
  }
  if (Rails.env == 'development') $scope.updateSoftValidations = updateSoftValidations; // for debugging

  function updateLegacyApi() {
    FarmyCartApi.cartItems = _.map($scope.cart.line_items, function(li) { return { variant_id: li.variant.id }});
  }

  function buildNearestDeliveryTermLink() {
    let locale = I18n.locale;

    try {
      $scope.nearestDeliveryTermLink = `${ locale && locale != 'de' ? '/' + locale : ''}/${$translate.instant('zipcode_modal.above-submit-notice.cta-link-path')}`;
    } catch(e) { console.error(e) }
  }

  $rootScope.$on('user:logout', (event) => {
    $timeout(() => {
      $scope.itemCount = 0;
      $scope.resetCart().then(function() {
      });
    })
  });

  $rootScope.$on('checkout:complete', (event) => {
    $timeout(() => {
      $scope.cart = angular.copy(emptyCart);
      $scope.itemCount = 0;
      $scope.resetCart();
    })
  });

  $rootScope.$on("cart:updated", (event, cart) => {
    $scope.cart = cart?.id ? cart : {};
    $scope.itemCount = $scope.cart.line_items ? $scope.cart.line_items.length : 0;
    if (cart?.id) {
      $scope.updateAdjustments();
      $scope.updateTotal();

      updateSoftValidations();
    }
  });

  $rootScope.$on("reactCart:push:started", (event) => {
    $scope.reactIsPushing = true;
  });

  $rootScope.$on("reactCart:push:finished", (event) => {
    $scope.reactIsPushing = false;
  });

  $rootScope.$on("reactCart:load:started", (event) => {
    $scope.reactIsLoading = true;
  });

  $rootScope.$on("reactCart:load:finished", (event) => {
    $scope.reactIsLoading = false;
  });

  $rootScope.$on('zipcode:changed', (event, {zipcode}) => {
    if (zipcode !== Hubs.currentZipcode) $scope.loadNearestDeliveryTime();
  });

  $rootScope.$on('$translateChangeSuccess', function (event, response) {
    let locale = $scope.oldLocale || $translate.use() || I18n.locale;
    if(response.language != locale) {
      $scope.oldLocale = response.language;
      $scope.load().then(function() {
        $rootScope.$broadcast('cart:changed', {from: '$translateChangeSuccess', cart: $scope.cart, lastLocalUpdateAt: $scope.lastLocalUpdateAt, needsSave: $scope.needsSave()});
        buildNearestDeliveryTermLink();
      });
    }
  });

  shouldSetXpressFromUrlParameter();
  ensureNonXpressForStorefronts();

  window.CartData = scope;
}]);
