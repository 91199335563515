import * as _ from 'underscore';

/**
 * Manages translation and pluralization of various measurement units (including product quantity).
 */
angular.module('app').service('UnitsInflector', ['$rootScope', '$q', '$http', '$interval', '$timeout', '$translate', ($rootScope, $q, $http, $interval, $timeout, $translate) => {
  var $scope = this || {};

  window.UnitsInflector = $scope;

  $scope.currentInflections = null;

  function loadInflections() {
      $http.get(window.unitsInflectionsPath).then(response => {
        $scope.unitInflections = JSON.parse(response.data.replace(';', ''));
        $scope.updateCurrent();
      })
  }

  $scope.updateCurrent = function() {
    if ($scope.unitInflections)
      $scope.currentInflections = $scope.unitInflections[$translate.use()];
  };


  $scope.inflect = function(amount, unit) {
    if ($scope.currentInflections == null)
      return `${amount} ${unit}`;

    var pair = _.find($scope.currentInflections, (i, key) => i.one == unit || i.other == unit || key == unit);

    // Attempt to fetch the pair forcing the German key
    if (pair == null) {
      let key = _.findKey($scope.unitInflections.de, (i, key) => i.one == unit || i.other == unit || key == unit);
      pair = $scope.currentInflections[key];
    }

    if (pair && amount == 1) {
      return `${amount.toString().replace(/\.0$/, '')} ${pair.one}`
    } else if (pair && amount != 1) {
      return `${amount.toString().replace(/\.0$/, '')} ${pair.other}`
    } else return `${amount.toString().replace(/\.0$/, '')} ${unit}`;
  };

  /**
   * Same as inflect, but uses a promise pattern
   *
   * @param amount
   * @param unit
   * @returns {*}
   */
  $scope.$inflect = function(amount, unit) {
    return $q((resolve, reject) => {
      if ($scope.currentInflections == null) {
        setTimeout(() => {
          return $scope.$inflect(amount, unit);
        }, 50)
      } else {
        resolve($scope.inflect(amount, unit));
      }
    })
  };

  $timeout(() => {
    loadInflections();
  }, 200);

  $rootScope.$on('$translateChangeSuccess', () => {
    $scope.updateCurrent();
  });

  return $scope;
}]);