import * as _ from 'underscore';

/**
 * Helps manage client-side Split tests.
 *
 * @type {Function}
 */

var TestManager = {
  testCache: {},
  shouldTestCache: {},

  // We need to pass 'base name' splitted from 'segments' due to our naming convention
  // ('should_test' uses just 'base' name; 'start' uses the complete experiment name, segments included)
  startTest: function(name, defaultVariant, segment, restart) {
    if (defaultVariant == null)
      throw new Error("The second argument (defaultVariant) must always be explicitly specified. What is the test is invalid on the server?");

    return new Promise((resolve, reject) => {
      let segmentTag = segment ? `&segment=${segment}` : '';
      let cacheKey = `${name}:${segmentTag}`;

      if (restart || TestManager.testCache[cacheKey] == null) {
        $.get(`/api/frontend/split/start.json?id=${name}&default=${defaultVariant}${segmentTag}`, (response, status, xhr) => {
          TestManager.testCache[cacheKey] = response;

          if (response.testing)
            resolve(response);
          else
            reject(response);
        }).fail((e) => reject(e))
      } else {
        let response = TestManager.testCache[cacheKey];

        if (response.testing)
          resolve(response);
        else
          reject(response);
      }
    })
  },

  shouldTest: function(name) {
    return new Promise((resolve, reject) => {
      if (TestManager.shouldTestCache[name]) {
        $.get(`/api/frontend/split/should_test.json?id=${name}`, (response, status, xhr) => {
          TestManager.shouldTestCache[name] = response;

          if (response.should_test)
            resolve(response);
          else
            reject(response);
        }).fail((e) => reject(e))
      } else {
        let response = TestManager.shouldTestCache[name];

        if (response.should_test)
          resolve(response);
        else
          reject(response);
      }
    })
  },

  finishTest: function(name, segment) {
    return new Promise((resolve, reject) => {
      var segmentTag = segment ? `&segment=${segment}` : '';
      $.get(`/api/frontend/split/finish.json?id=${name}${segmentTag}`, (response, status, xhr) => {
        resolve(response);
      }).fail((e) => reject(e))
    })
  },

  /**
   * Do initializations that are required at startup for some specific tests{}
   */
  setupDefaults: function() {
    // TestManager.startTest(`products_show_scrolling_${window.isMobile ? 'mobile' : 'desktop'}`, 'noscroll');

    // Stub test variants to force specific scenarios while
    // https://farmyag.atlassian.net/browse/IM-2690 is in progress
    // and under question in general
    // This prevents actual requests to api/frontend/split/start:
    TestManager.testCache['products_show_scrolling_mobile:'] = { testing: true, variant: 'noscroll' };
    TestManager.testCache['products_show_scrolling_desktop:'] = { testing: true, variant: 'noscroll' };
  }
};

window.TestManager = TestManager;