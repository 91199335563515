import * as _ from 'underscore';

angular.module('app').directive('farmyContentPromo', () => {
  return {
    controller: ['$rootScope', '$scope', '$timeout', '$element', function ($rootScope, $scope, $timeout, $element) {
      window.farmyContentPromo = window.farmyContentPromo || $scope;

      $scope.scrollWatcher = null;
      $scope.isMobile = window.isMobile;

      function constructor() {
        $scope.expanded = false;

        $scope.maxContentNum = $scope.isMobile ? 2 : 3;

        $scope.$watch('promo.current', (newVal, oldVal) => {
          if ($scope.promo.current) {
            $timeout(() => {
              $scope.expanded = true;
              ahoy.track("engagement:content-shown", { content_names: getContentNames(), channel: window.xSessionChannel });
              if ($scope.promo.type == 'in_catalog') $scope.setScrollWatcher();
            }, 500);
          }
        });
      }

      $scope.onContentClicked = function(contentName) {
        ahoy.track("engagement:content-clicked", { content_names: getContentNames(), clicked: contentName, channel: window.xSessionChannel })
      };

      $scope.onCloseBtnClicked = function() {
        $scope.expanded = false;
        $scope.promo.current = false;
        ahoy.track("engagement:content-dismissed", { content_names: getContentNames(), channel: window.xSessionChannel});
        $timeout(() => {
          $scope.promo.terminated = true;
          broadcastDismissed();
        }, 800)
      };

      $scope.setScrollWatcher = function() {
        $scope.scrollWatcher = $scope.$watch(() => $element[0].getBoundingClientRect().y, (newY) => {
          if (newY < 0) {
            broadcastDismissed();
            $scope.scrollWatcher();
          }
        })
      };

      function getContentNames() {
        return _($scope.promo.contents).map((content) => content.name)
      }

      function broadcastDismissed() {
        $rootScope.$broadcast('content_promo:dismissed');
      }

      function destructor() {
        window.farmyContentPromo = null;
      }

      constructor();
      $scope.$on('$destroy', destructor);
    }],
    scope: {
      promo: '='
    },
    link: function($scope, $element, attributes) {
    },
    template: `<ng-include src="'/ng/templates/content_promos/' + promo.type + '_' + promo.version + '.html?locale=${window.I18n.locale}'" ng-if="!terminated" class="promo-wrapper" ng-class="{'active': expanded}"/>`
  }
});
