import * as _ from 'underscore';

angular.module('app').controller('WeeklyIndexCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$location', 'Alerts', 'blockUI', 'Hubs', 'OrderHelper', 'CartData', 'UserService', function ($scope, $sce, $http, $q, $rootScope, $location, Alerts, blockUI, Hubs, OrderHelper, CartData, UserService) {
  window.WeeklyIndexCtrl = $scope;

  //
  // Private members:
  //

  function constructor() {
    $scope.loadWeeklies();
  }

  function destructor() {
    window.WeeklyIndexCtrl = null;
  }


  //
  // Public members
  //

  $scope.weeklies = [];

  $scope.loadWeeklies = function() {
    $scope.isLoading = true;

    $http.get(`/api/frontend/weeklies.json`).then(response => {
      $scope.weeklies.length = 0;
      _.each(response.data.weeklies, weekly => {
        weekly.product_names_list = weekly.product_names.join(', ');
        $scope.weeklies.push(weekly)
      });
    }).finally(() => {
      $scope.isLoading = false;
    })
  };

  $scope.editWeekly = function(weekly) {
    $location.path(`/weeklies/${weekly.id}`);
  };

  $scope.pauseWeekly = function(weekly) {

  };

  $scope.activateWeekly = function(weekly) {

  };

  $scope.destroyWeekly = function(weekly) {

  };

  $scope.$on('$destroy', destructor);
  constructor();
}]);
