/**
 * Produces a background-based (this HTML5-only) image container, which will
 * contain an image inside a fixed area and can display a low-res preview which
 * will be faded out by a high-res version. If a 'stub' is provided, responsive
 * width and height will be based on container width and stub's width/height ratio.
 *
 * Has three parameters:
 * ui-highres-bg: required, path to high-resolution background to load
 * ui-highres-bg-load-now: optional, load immediately, rather then wait for window load
 *
 * Important compatibility notice: This is intended to use mainly on mobile platforms and thus supports only modern HTML5 engines. It doesn't work in <=IE9.
 *
 * @author Denis Kolesnichenko, Farmy AG
 */
angular.module('app')
  .directive('uiHighresBg', ['$window', '$timeout', function($window, $timeout) {
    return {
      restrict: 'A',
      link: function(scope, $element, attrs) {
        var loadHighres = function() {
          var highresImage = new Image();
          $(highresImage).one('load', function() {
            $timeout(function() {
              $element.css({ backgroundImage: `url(${highresImage.src})` });
            }, 50);
          });

          highresImage.src = attrs.uiHighresBg;
        }

        if (attrs.uiHighresBgLoadNow) {
          $timeout(function() { loadHighres() }, 100);
        } else {
          if (document.readyState == 'complete') {
            loadHighres();
          } else {
            $(window).on('load', function() {
              loadHighres();
            });
          }
        }
      }
    };
  }]);