import * as _ from 'underscore';

'use strict';

/**
 * WP Content Block * by Mike Trainer
 *
 * Injects a custom html block from the parallel WP installation.
 * Takes a "post-name" (name of the WP post providing the content to inject).
 * (Optional) "content-type" will choose a specific output template. Available => ['raw', 'carousel']. Default => 'raw'.
 * (Optional) "password" for password-protected content (recommended in the Wordpress side).
 * (Optional) "wp-location" if WP API is not in the default /wp-api folder.
 *
 * $rootScope.wpIssueDetected boolean is available in the frontend to handle WP response errors.
 *
 * Markup example (restricted to 'element' only):
 *  <farmy-wp-content-block wp-post-name="home-blocks" password="my_strongish_password" wp-location="https://www.my_wordpress.com/wp-json/wp/v2/"></farmy-wp-content-block>
 *
 * Wordpress side:
 * Create a new post.
 * Tweak the "slug" of the post as needed => this will be the "post-name".
 * Create translations as needed.
 * Add password protection (recommended).
 *
 * Note: this directive expects WP api to respond from the default /wp-api endpoint.
 * Note: 'raw' content-type will inject the post content as-is. Ng-components (such as product-list) will be correctly digested.
 * Note: non-raw templates might have specific wp requirements:
 *   - 'carousel' expects a set of (optionally anchored) images. This makes it compatible with raw banner blocks without the need to change the source post.
 */

angular.module('app')
  .directive('farmyWpContentBlock', function() {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        wpPostName: '@',
        wpTemplate: '@',
        password: '@',
        wpLocation: '@',
        wpLocalVars: '='
      },
      controller: ['$scope', '$rootScope', '$timeout', '$element', '$http', '$q', '$compile', 'UserService', function ($scope, $rootScope, $timeout, $element, $http, $q, $compile, UserService) {
        if (UserService.isLoggedIn) {
          let logoutListener = $rootScope.$on('user:logout', () => {
            loadGlobalVars();
            logoutListener();
          })
        } else {
          let loginListener = $rootScope.$on('user:authenticated', () => {
            loadGlobalVars();
            loginListener()
          })
        }

        $scope.baseEndpoint = `${$scope.wpLocation ? $scope.wpLocation : '/wp-api'}`;
        $scope.dynamic = $scope.wpLocalVars;

        $scope.urlParamsArray = ["_fields=content_by_locale,custom_content_by_locale", `slug=${$scope.wpPostName}`];
        if ($scope.password) $scope.urlParamsArray.push(`password=${$scope.password}`);

        function constructor() {
          getTheContent();
          loadGlobalVars();
        }

        // Some helper variables to allow basic WP logic blocks:
        function loadGlobalVars() {
          $scope.userIsLoggedIn = UserService.isLoggedIn;
          // Inferring if completed orders, through the existence of favoritesIds.
          $scope.userHasCompleteOrders = UserService.currentUser && UserService.currentUser.complete_orders_count > 0;
          $scope.currentHubCode = window.currentHubCode;
          $scope.currentHubId = window.currentHubId;
        }

        function getTheContent() {
          let currentLocale = I18n.locale || 'de';
          let params = $scope.urlParamsArray.join('&');
          let endpoint = `${$scope.baseEndpoint}/posts?${params}`;

          $http.get(endpoint).then(response => {
            let data = response.data && response.data.length > 0 ? response.data[0] : null;

            if (!data || !data.content_by_locale) {
              console.info(`No WP content found at "${endpoint}"`);
              $scope.useTransclusion = true;
              return;
            }

            let isCurrentLocalePresent = _(data.content_by_locale).keys().indexOf(currentLocale) > -1;
            let content = '';

            if (!isCurrentLocalePresent) {
              currentLocale = 'de'
            }

            $scope.theContent = data.content_by_locale[currentLocale];

            if (!$scope.wpTemplate || $scope.wpTemplate == 'raw') {
              $element.html($scope.theContent);
              $compile($element.contents())($scope);
            } else {
              extractData[$scope.wpTemplate]($scope.theContent).then((result) => {
                $scope.data = result;

                if (!$scope.data || $scope.data.length == 0)
                  $scope.useTransclusion = true
                else
                  $scope.templateUrl = `ng/templates/wp_blocks/${$scope.wpTemplate}.html`;
              });
            }
          }, (e) => {
            $scope.useTransclusion = true;
            console.info(`wp-content-block issue detected: ${e.status} => ${e.statusText}`);
          })
        }

        // All particular functions to extract the needed data.
        // Functions are required to return a promise.
        let extractData = {
          // Carousel expects to receive a set of (optionally anchored) images.
          // It will extract 'src' and 'href' attributes and match them in the same order.
          // Will respect optional block logic.
          // HTML wrappers are irrelevant (images can be wrapped in columns or any other parent block).
          carousel: (theContent) => {
            let result = null;
            // Inject a hidden content block:
            $scope.id = `carousel-${parseInt(Math.random() * 9000)}`;
            let hiddenElement = $(`<div id="${$scope.id}" class="hidden"></div>`);
            hiddenElement.html(theContent);
            $element.append(hiddenElement);

            $compile(hiddenElement.contents())($scope);

            return $q((resolve, reject) => {
              $timeout(() => {
                // Extract elements:
                let innerElements = $(hiddenElement).find('a');
                if (innerElements.length == 0)
                  innerElements = $(hiddenElement).find('img');

                result = _(innerElements.toArray()).map(i => {
                  return {
                    src: i.src || $(i).find('img')[0].src,
                    href: i.href
                  }
                });

                resolve(result)
              })
            })
          }
        }

        function destructor() {
          window.farmyWpContentBlock = null;
        }

        // Initialize
        $scope.$on('$destroy', destructor);
        constructor();
      }],

      link: function($scope, $element, attributes) {

      },
      template: `<ng-include src="templateUrl"></ng-include>
                 <ng-transclude class="wp-content-wrapper" ng-if="useTransclusion"></ng-transclude>`
    }
  });
