import * as _ from 'underscore';
import {t} from '../shared-services/translate';

/**
 * Service for reading and ediging the current user's SmartPasses
 * IMPORTANT: this service does not automatically load subscriptons
 */
angular.module('app').service('SmartPassService', ['$rootScope', '$q', '$http', '$interval', '$timeout', '$translate', 'Alerts', '$cookies', 'CartData', 'Hubs', '$uibModal', 'UserService', ($rootScope, $q, $http, $interval, $timeout, $translate, Alerts, $cookies, CartData, Hubs, $uibModal, UserService) => {
  var $scope = this || {};

  window.SmartPassService = $scope;

  $scope.selectedIndex = 0;

  // Loads current user subscriptions. Accepts params of this form:
  // {all: true, charges: true, upgrade: true}
  // all -> returns both active and inactive subscriptions; else only active
  // charges -> returns past and future charges for the subscriptions
  // available_plans -> return all SmartPass alternatives available (for up/downgrade purposes)
  $scope.loadSubscriptions = function(params = {}) {
    return $http.get("/api/frontend/smart_pass_subscriptions.json", {params})
      .then(response => {
        $scope.subscriptions = response.data.subscriptions;
        $scope.purchasing = response.data.purchasing;
        $scope.activeZipcodes = response.data.active_zipcodes;
        $scope.hasActiveSubscription = response.data.has_active_subscription;
        $scope.subscription = $scope.activeSubscriptions()[$scope.selectedIndex];
        $scope.hasActiveFarmypass = $scope.subscription?.sp_type === "farmypass" || false;
        $scope.smartPassType = response.data.eligible_smartpass_type;
        $scope.shippingSubscription = $scope.activeSubscriptions().filter(s => s.marketing_zone !== "A")[0];
        window.currentUserActiveSmartPass = $scope.hasActiveSubscription = $scope.activeSubscriptions().length > 0;
        $scope.hasShippingSubscription = !!$scope.shippingSubscription;

        $rootScope.$broadcast("smartpass:updated");
        return $q.resolve($scope.subscriptions);
      })
      .catch(error => {
        console.log("Error loading subscriptions", error);
        return $q.reject(error);
      });
  };

  $scope.activeSubscriptions = function() {
    return _($scope.subscriptions).where({state: 'active'})
  };

  $scope.purchasingSubscriptions = function() {
    return _($scope.subscriptions).where({state: 'purchasing'})
  };

  $scope.inactiveSubscriptions = function() {
    var activeIds = _($scope.activeSubscriptions().concat($scope.purchasingSubscriptions())).pluck('id');
    return _($scope.subscriptions).reject((s) => { return activeIds.indexOf(s.id) > -1 })
  };

  // These methods are to format data for
  // the MyFarmyPass and FarmyPassThankYou components
  // since they share the same ones

  /**
   * @param payment receives an object with pmethod (mandatory) and maskedCC (optional)
   * @return string with the format "Credit Card *********1231"
   * **/
  $scope.formatPayment = (payment) => {
    if (!payment) return null;

    const paymentTable = {
      VIS: t('paid_subscription.payment_method_names.credit_card'),
      ECA: t('paid_subscription.payment_method_names.credit_card'),
      PAP: t('paid_subscription.payment_method_names.paypal'),
      PFC: t('paid_subscription.payment_method_names.postfinance_card'),
      MYO: t('paid_subscription.payment_method_names.myone_card')
    };
    return `${paymentTable[payment.pmethod]}${payment.maskedCC ? ` ${$scope.maskCardNumber(payment.maskedCC)}` : ''}`;
  };

  /**
   * @param cardNumber string
   * @return returns a string with the format "*********1231"
   * **/
  $scope.maskCardNumber = (cardNumber) => {
    const numAsterisks = Math.max(0, cardNumber.length - 4);
    return '*'.repeat(numAsterisks) + cardNumber.slice(-4);
  };

  /**
   * @param date string with the format "2023-12-24T23:59:59+01:00"
   * @param type string with the subscription type
   * @return returns a date string with the format DD.MM.YY for farmypass type and DD/MM/YYYY
   * @example formatDate("2023-12-24T23:59:59+01:00", 'farmypass') => returns 24.12.23
   * **/
  $scope.formatDate = (date, type) => {
    const format = type === 'farmypass' ? 'DD.MM.YY' : 'DD/MM/YYYY';
    return moment(date, 'YYYY-MM-DDTHH:mm:ssZ').format(format);
  };

  /**
   * @param plans array of available smart pass plans
   * @return object with the plans formatted for hofpass legacy
   * **/
  $scope.formatPlansToHofpass = (plans) => {
    const basicPlans = plans.filter(plan => plan.level === 'basic');
    const premiumPlans = plans.filter(plan => plan.level === 'premium');
    const formattedPlans = {};
    if (basicPlans.length > 0) {
      const basicPlansObject = {
        human_week_days_period: basicPlans[0].human_week_days_period,
        week_days: basicPlans[0].week_days,
        plans: {}
      };
      basicPlans.forEach(plan => basicPlansObject.plans[plan.duration] = plan);
      formattedPlans.basic = basicPlansObject;
    }
    if (premiumPlans.length > 0) {
      const premiumPlansObject = {
        human_week_days_period: premiumPlans[0].human_week_days_period,
        week_days: premiumPlans[0].week_days,
        plans: {}
      };
      premiumPlans.forEach(plan => premiumPlansObject.plans[plan.duration] = plan);
      formattedPlans.premium = premiumPlansObject;
    }
    return formattedPlans;
  }

  if (!$scope.subscriptions && UserService.isLoggedIn) $scope.loadSubscriptions();

  $rootScope.$on('user:authenticated', () => {
    $scope.loadSubscriptions();
  });

  $rootScope.$on('user:logout', () => {
    $scope.subscription = null;
    $scope.selectedIndex = 0;
  });

  return $scope;
}]);
