import React from "react";
import styled from "styled-components";
import BasicSpinner from "./BasicSpinner";

const SpinnerLoader = () => {
  return <StyledSpinner>
    <BasicSpinner />
  </StyledSpinner>;
};

export default SpinnerLoader;

export const StyledSpinner = styled.div.attrs({
  className: `
  flex
  align-items-center
  justify-content-center
  `
})`
  background-color: white;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
`;
