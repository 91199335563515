import globalState from "../globalState";
import * as _ from "underscore";

const topLayerGlobalState = () => {
  const {getGlobalState, setGlobalState} = globalState();
  const stateName = "topLayer";

  const globalTopLayer = getGlobalState(stateName);
  let topLayerState = getGlobalState(stateName) || {
    modalQueue: [],
    currentModal: null,
    ...globalTopLayer
  };

  const setTopLayerState = (newState) => {
    const candidateNewState = {...topLayerState, ...newState};

    if (!_.isEqual(candidateNewState, topLayerState)) {
      topLayerState = {...candidateNewState};
      setGlobalState(stateName, topLayerState);
    }
  };

  const fullLoaderVisible = (show) => {
    setTopLayerState({loaderOpen: show});
  };

  const setCurrentModal = (currentModal) => {
    const onClose = topLayerState?.currentModal?.onClose;
    setTopLayerState({currentModal});

    if (onClose) onClose();
    if (currentModal?.onOpen) currentModal.onOpen();
  };

  const setModalQueue = (queue) => {
    setTopLayerState({modalQueue: queue});
  };

  return {...topLayerState, setTopLayerState, fullLoaderVisible, setCurrentModal, setModalQueue};
};
export default topLayerGlobalState;

export const globalModalQueue = (explicitModalName = null) => {
  const pushToQueue = (modalName = explicitModalName, options = {}) => {
    const {setModalQueue, setCurrentModal} = topLayerGlobalState();
    let {modalQueue, currentModal} = topLayerGlobalState();
    const minNameLength = 2;

    if (modalName === currentModal) return;
    if (modalQueue?.includes(currentModal)) return;
    if (!modalName || modalName.length < minNameLength) return;

    const newModal = {name: modalName, ...options};

    if (!modalQueue.length && !currentModal) setCurrentModal(newModal);
    else {
      modalQueue = [...modalQueue, newModal];
      setModalQueue(modalQueue);
    }
  };

  const closeModal = (modalName = explicitModalName) => {
    const {setCurrentModal} = topLayerGlobalState();
    const {modalQueue, currentModal} = topLayerGlobalState();

    if (modalName !== currentModal?.name) return;

    if (modalQueue.length) moveQueue();
    else {
      setCurrentModal(null);
    }

    if (currentModal?.onClose) currentModal.onClose();
  };

  const moveQueue = () => {
    const {modalQueue, setModalQueue, setCurrentModal} = topLayerGlobalState();

    if (!modalQueue || modalQueue.length === 0) return;

    const currentModal = modalQueue.shift();

    setCurrentModal(currentModal);
    setModalQueue(modalQueue);
  };

  return {pushToQueue, closeModal, moveQueue};
};
