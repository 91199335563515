'use strict';

angular.module('app').controller('AllProductsAddedModalCtrl', ['$scope', '$uibModalInstance', '$timeout',
  function ($scope, $uibModalInstance, $timeout) {
    $scope.closeAllProductsAddedModal = function() {
      $uibModalInstance.dismiss('cancel');
    };

    var setModalVerticalPosition = function() {
      $('.modal.all-added-modal .modal-dialog').hide();
      var currentWindowHeight = $(window).height();
      var marginTop = Math.floor((currentWindowHeight - $('.modal.all-added-modal .modal-dialog').height()) / 2.5);
      $('.modal.all-added-modal .modal-dialog').css('margin-top', marginTop.toString() + 'px');
      $('.modal.all-added-modal .modal-dialog').fadeIn();
    };

    $('.modal.all-added-modal .modal-dialog').hide();

    $uibModalInstance.rendered.then(function() {
      setModalVerticalPosition();
    });

    $timeout(function() {
      $('.modal.all-added-modal .modal-dialog').fadeOut(400, function() {
        $scope.closeAllProductsAddedModal();
      });
    }, 2000);

  }]);