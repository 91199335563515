'use strict';

angular.module('app').controller('SmartPassCancelSubscriptionModalCtrl', [ '$scope', '$uibModalInstance',
  function ($scope, $uibModalInstance) {
    $scope.confirm = function() {
      $uibModalInstance.close('confirm');
    };

    $scope.close = function() {
      $uibModalInstance.close('cancel');
    };
  }]);
