import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

export class RjsFilterCategoriesSubmenu extends React.Component {
  constructor(props) {
    super(props);

    const scope = this;

    this.state = {
      isLoading: false
    };

    AngularIntegration.$on('catalog:loading', () => { scope.setState({ isLoading: true }) }).then(unsubscribe => scope._unbindOnCatalogLoading = unsubscribe);
    AngularIntegration.$on('catalog:loaded', () => { scope.setState({ isLoading: false }) }).then(unsubscribe => scope._unbindOnCatalogLoaded = unsubscribe);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    if (this._unbindOnCatalogLoading) this._unbindOnCatalogLoading();
    if (this._unbindOnCatalogLoaded) this._unbindOnCatalogLoaded();
    this._isMounted = false;
  }

  onEntryClick(item, event) {
    if (this._isMounted) {
      event.preventDefault();
      event.stopPropagation();
      
      this.props.parentFilter.onCategoryClick(item);
    }

    return false;
  }

  render(props) {
    const wrapperStyles = {
      width: 240,
      position: 'absolute',
      left: '100%',
      zIndex: 1200,
      marginTop: -23,
      marginLeft: 0
    };

    var items = _.filter(this.props.valueList, v => v.parent_id == this.props.taxon.value);

    let itemElements = [];
    var i;

    for (i = 0; i < items.length; i++) {
      if (!items[i].hidden) {
        let labelClass = 'sublink';
        if (items[i].length >= 24) labelClass = 'tight-1';
        else if (items[i].length >= 28) labelClass = 'tight-2';
        else if (items[i].length >= 33) labelClass = 'tight-3';

        itemElements.push(<li onClick={this.onEntryClick.bind(this, items[i])} key={'sublink_' + items[i].value}>
          <a className={labelClass} href={items[i].url ? `/${items[i].url}` : ''}>{items[i].name}</a>
        </li>);
      }
    }

    return <div className="taxon-submenu-wrapper" style={wrapperStyles}>
        <div className="taxon-submenu">
          <ul>
            {itemElements}
          </ul>
        </div>
      </div>
  }
}