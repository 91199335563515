angular.module('app')
  .directive('farmyBottomBlankFiller', ['$window', '$timeout', function($window, $timeout) {
    return {
      link: function($scope, $element, attrs) {
        var footer = $('#page-footer');
        var filler = $element.find('.blank-filler');

        var updateLayout = function() {
          filler.css('height', 0);
          if (footer.offset()) {
            let fillHeight = $window.innerHeight - footer.offset().top - footer.innerHeight();

            if (fillHeight > 0) {
              filler.css('height', fillHeight)
            }
          }
        };

        $(window).resize(updateLayout);

        $scope.$on('$destroy', function() {
          $(window).unbind('resize', updateLayout);
        });

        $(document).ready(() => {
          updateLayout();
          $timeout(updateLayout, 1500); // Additional layout fix needed for some browsers, depending on rendering queue
        })
      },
      template: `<div class='blank-filler'></div>`
    }
  }]);
