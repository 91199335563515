import * as _ from 'underscore';
import * as sprintf from 'sprintf';

angular.module('app').controller('ShoppingListItemCtrl', ['$rootScope', '$scope', '$window', '$element', '$timeout', 'Alerts', 'CartData', 'UiStateManager', 'CatalogServiceHelper', function ($rootScope, $scope, $window, $element, $timeout, Alerts, CartData, UiStateManager, CatalogServiceHelper) {
  $scope.productId = $element.attr('data-id') ? parseInt($element.attr('data-id')) : null;
  if(isNaN($scope.productId)) $scope.productId = null;

  window.ShoppingListItemCtrl = $scope;
  if (window.ShoppingListItemCtrl_items == null) window.ShoppingListItemCtrl_items = {};

  $scope.$element = $element;

  $scope.lineItemInCart = null;
  $scope.variantInCart = null;
  $scope.variantIndex = null;
  $scope.inCart = false;
  $scope.afterCartAddRecommendationsVisible = false;
  $scope.productTitleSize = '26px';

  // debugger;

  $scope.CartData = CartData;

  setGoogleAnalyticsListName();

  // Cart actions
  $scope.increaseCartQuantity = function(e) {
    animateButton(e.target);
    if ($scope.ageRestricted && !window.currentUserAgeVerified) {
      window.FarmyCartAgeVerificationPopup.show(function () {
        window.currentUserAgeVerified = true;
        $scope.increaseCartQuantity(null);
      });
      return;
    }

    $scope.updatingCart = true;

    if ($scope.productId != $scope.item.selectedProduct.id) $scope.updateSelected();

    if ($scope.variantIndex == null) {
      $scope.variantIndex = 0;
      trackAddToCart();
    } else if($scope.variantIndex < $scope.variants.length)
      $scope.variantIndex += 1;

    // Fail-safe
    if($scope.variantIndex >= $scope.variants.length)
      $scope.variantIndex = $scope.variants.length - 1;

    $scope.variantInCart = $scope.variants[$scope.variantIndex];

    if ($scope.variantInCart) $scope.inCart = true;

    afterCartAdd();

    return CartData.setCartVariant($scope.productId, {
      id: $scope.variantInCart.id,
      price: $scope.variantInCart.price,
      quantity_in_units: $scope.variantInCart.qiu
    }).then(function() {
      afterCartUpdate();
    });
  };

  $scope.decreaseCartQuantity = function(e) {
    animateButton(e.target);
    if ($scope.variantIndex == 0) {
      $scope.variantIndex = null;

      // Track event
      Tracking.sendCartRemoveEvent($scope.productId, $element.find('.item-title').text());
    } else if($scope.variantIndex > 0)
      $scope.variantIndex -= 1;

    // Fail-safe
    if($scope.variantIndex < 0) $scope.variantIndex = null;

    $scope.updatingCart = true;
    if ($scope.productId != $scope.item.selectedProduct.id) $scope.updateSelected();

    if($scope.variantIndex != null) {
      $scope.variantInCart = $scope.variants[$scope.variantIndex];
      return CartData.setCartVariant($scope.productId, {
        id: $scope.variantInCart.id,
        price: $scope.variantInCart.price,
        quantity_in_units: $scope.variantInCart.qiu
      }).then(function() {
        afterCartUpdate();
      });
    } else {
      $scope.variantInCart = null;
      $scope.inCart = false;
      return CartData.setCartVariant($scope.productId, null).then(function() {
        afterCartUpdate();
      });
    }
  };

  $scope.updateSelected = function(index) {
    index = index || $scope.item.selectedIndex || 0;
    $scope.item.selectedIndex = index;
    if ($scope.item.suggestions && $scope.item.suggestions.products != []) {
      let product = $scope.item.suggestions.products[index];

      $scope.productId = $scope.item.suggestions.products[index].id;
      $scope.disabledSale = $scope.item.suggestions.products[index].disabled_sale;
      $scope.available = $scope.item.suggestions.products[index].available_to_add_to_cart;
      $scope.ageRestricted = $scope.item.suggestions.products[index].age_restricted;
      $scope.portalProduct = $scope.item.suggestions.products[index].portal_product;
      $scope.recipeProduct = $scope.item.suggestions.products[index].recipe_product;
      $scope.no_link = $scope.item.suggestions.products[index].no_link;
      $scope.productUrl = $scope.item.suggestions.products[index].product_url;
      $scope.item.selectedProduct = $scope.item.suggestions.products[index];

      if (window.ShoppingListItemCtrl_items)
        window.ShoppingListItemCtrl_items[$scope.productId] = $scope;

      $scope.variants = [];

      _.each($scope.item.suggestions.products[index].variants, function(variant) {
        if (variant.is_master || variant.hidden)
          return;

        $scope.variants.push({id: variant.id, label: variant.quantity_label || variant.label, qiu: variant.quantity_in_units, price: variant.price });
      });

      $scope.variants = _.sortBy($scope.variants, function(variant) { return variant.qiu });

      updateCartState();
    }
  };

  $scope.onImageLoaded = function(e) {
    $timeout(() => {
      updateLayout();
      $scope.imageLoadComplete = true;
    }, 5);
  };

  // $scope.onSelectorClick = function(index) {
  //   $scope.item.selectedIndex = index;
  //   $scope.updateSelected()
  // };

  // Private members:

  function animateButton(target) {
    var targetBtnElement = $(target);

    targetBtnElement.css({'width': '20px', 'margin-left': '2px', 'margin-right': '3px'});

    $timeout(function() {
      targetBtnElement.css({'width': '27', 'margin-left': '-1px', 'margin-right': '-1px'});
      $timeout(function() {
        targetBtnElement.css({'width': '', 'margin-left': '', 'margin-right': ''});
      }, 100);
    }, 100)
  }

  function afterCartUpdate() {
    updateCartState();
    $scope.arrangeInCartItems();
    $scope.updatingCart = false;
  }

  function trackAddToCart() {
    var options = {
      googleAnalyticsProduct: googleAnalyticsProduct()
    };

    Tracking.addToCart($scope.productId, $element.find('.item-title').text(), options);

    ahoy.track("order-item-added",{
      order_id: $scope.CartData.cart.id,
      order_number: $scope.CartData.cart.number,
      product_id: $scope.product.id,
      variant_id: $scope.product.variants[$scope.variantIndex].id,
      hub_id: window.currentHubId,
      list_mode: CatalogServiceHelper.lastUsedListMode,
      sort_mode: CatalogServiceHelper.lastUsedSortMode,
      channel: window.xSessionChannel
    });
  }

  function setupDesktopUiListeners() {
    // Really clunky click callback, part of it comes from legacy code that was supposed to
    // 'angularize' product cards on top static HTML code.
    setTimeout(() => {
      $element.find('.product-link, .product-url').bind('click', function(e) {
        var toElementTagName, toElement;

        // This is a Firefox workaround
        if(e.toElement == null && e.target != null) {
          toElementTagName = e.target.tagName.toLowerCase();
          toElement = e.target;
        }
        else {
          toElementTagName = e.toElement.tagName.toLowerCase();
          toElement = e.toElement;
        }

        if (window.isTouchDevice) {
          // Photo click
          var clickType = 'info';

          if (($(toElement).hasClass('view-product') || $(toElement).parents('.view-product').length > 0))
            clickType = 'info';
          else if ($(toElement).hasClass('col-photo') || $(toElement).parents('.col-photo, .control-panel').length > 0)
            clickType = 'photo';

          // console.log("click target", toElement, clickType);

          if (clickType == 'photo') {
            e.stopPropagation();
            e.preventDefault();

            return false;
          } else if(clickType == 'info' && !$scope.hover) {
            $scope.hover = true;
            $scope.openProductPopup();

            e.preventDefault();
            e.stopPropagation();
            return false;
          } else if(clickType == 'info' && $scope.hover) {
            $scope.openProductPopup();

            e.preventDefault();
            e.stopPropagation();
            return false;
          }
        } else { // non-mobile behaviour
          if(toElementTagName == 'button' || $(toElement).hasClass('buttons') || $(toElement).hasClass('control-panel-body') || $(toElement).hasClass('normal') || $(toElement).hasClass('mini-style') || $(toElement).hasClass('favorites-toggle')) {
            e.preventDefault();
            e.stopPropagation();
            return false;
          } else if($(toElement).hasClass('item-title')) {
            if ($scope.layout == 'table') {
              $scope.openProductPopup();
            } else {
              ProductNavigation.closePopup();
              $scope.openProductPopup();
            }

            e.preventDefault();
            e.stopPropagation();
            return false;
          } else if ($(toElement).parents('.control-panel').length > 0) {
            $scope.openProductPopup();
            e.preventDefault();
            e.stopPropagation();
            return false;
          }
        }

        return true;
      });
    }, 10);
  }

  $scope.onMouseEnter = function(event) {
    // console.log('hover');
    $scope.hover = true;
  };

  $scope.onMouseLeave = function(event) {
    $scope.hover = false;
  };

  function updateLayout() {
    var imgElement = $element.find('.photo img');
    $scope.imageHeight = imgElement.css('height');

    if (!window.isMobile) {
      setupDesktopUiListeners();
    }
  }

  function trackClick(callback) {
    try {
      var options = {
        analyticsListName: $scope.analyticsListName,
        googleAnalyticsProduct: googleAnalyticsProduct()
      };

      Tracking.productClick($scope.productId, options, function() {
        callback();
      });
    } catch(e) {
      console.warn("trackClick failed")
      callback();
    }
  }

  function googleAnalyticsProduct() {
    return $element.data('google-analytics-product') || ($scope.item.suggestions.products[$scope.item.selectedIndex] && $scope.item.suggestions.products[$scope.item.selectedIndex].google_analytics_product);
  }

  function getGoogleAnalyticsListName() {
    var listElement = getGoogleAnalyticsListElement($element);
    return listElement.data("analytics-list-name");
  }

  function getGoogleAnalyticsListElement(el) {
    return el.parents('[data-analytics-list-name]');
  }

  function setGoogleAnalyticsListName() {
    var listName = getGoogleAnalyticsListName();
    if (listName && listName.length > 0) {
       $scope.analyticsListName = listName;
    }
  }

  /**
   * Reflects changes of the cart state in the current line item control
   */
  function updateCartState() {
    $scope.lineItemInCart = _.find(CartData.cart.line_items, function(line_item) {
      return line_item.product_id == $scope.productId;
    });

    if($scope.lineItemInCart) {
      $scope.variantInCart = $scope.lineItemInCart.variant;

      // Set the index of the variant
      $scope.variantIndex = null;

      _.each($scope.variants, function(variant, num) {
        if(variant.id == $scope.variantInCart.id)
          $scope.variantIndex = num;
      });

      if ($scope.variantIndex && $scope.variantIndex >= 0) {
        UnitsInflector.$inflect($scope.variants[$scope.variantIndex].qiu, $scope.item.suggestions.products[index].unit_name).then(label => {
          $scope.variantInCartLabel = label;

          let lcUnit = $scope.item.suggestions.products[index].unit_name.toLowerCase();
          if (lcUnit == 'g.' || lcUnit == 'g' || lcUnit == 'kg' || lcUnit == 'kg.')
            $scope.variantInCartLabelShort = label;
          else $scope.variantInCartLabelShort = $scope.variants[$scope.variantIndex].qiu.toString().replace(/\.0$/, '');
        })
      }
    } else {
      $scope.variantIndex = $scope.variantInCart = null;
      $scope.inCart = false;
    }

    $scope.inCart = $scope.variantInCart != null;
  }


  function afterCartAdd() {
    // showAfterCartAddRecommendations();
  }

  // Destruction
  $scope.$on('$destroy', function() {
    window.ShoppingListItemCtrl = null;
    if (window.ShoppingListItemCtrl_items) window.ShoppingListItemCtrl_items[$scope.productId] == null;
  });

  // Construction
  $scope.updateSelected(0);

  $scope.$watch('item.selectedIndex', function(index) {
    $scope.updateSelected(index)
  });

  $scope.$watch('item.selectedProduct', function(product) {
    if ($scope.item.suggestions) {
      $scope.item.selectedIndex = $scope.item.suggestions.products.indexOf(product);
      $scope.updateSelected($scope.item.selectedIndex);
    }
  });

  var itemParsingWatcher = $scope.$watch('item.parsed', function(parsed) {
    if (parsed){
      $scope.updateSelected($scope.item.selectedIndex);
    }
  });

  $scope.$watch('CartData.cart.updated_at', function(updatedAt) {
    if(updatedAt == null)
      return;
    updateCartState();
  });
}]);
