'use strict';

// Replaces commas for periods at element's input to ensure correct float evaluation
angular.module('app')
  .directive('safeDecimalInput', function() {
    return {
      restrict: "A",
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel){
        element.on("change", function(){
          formatSeparators()
        });

        // TODO Mike: add logic for both separators present (1,233.66 / 1.233,66)
        var formatSeparators = function () {
          ngModel.$setViewValue(ngModel.$viewValue.replace(',', '.'));
        };
      }
    }
  });
