/**
 * NG-VIEWPORT-ENTER (blame Miguel Entrena)
 *
 * Allows specification of custom behaviour when the element
 * is fully (or close to) visible in the viewport.
 *
 * 'enter-one-time' parameter available to prevent eternal execution.
 *
 * Originally created to trigger movement of the
 * delivery slot selector in the new checkout
 * to make user aware of it's scrollability
 *
 *  example:
 *    .data-selector(ng-viewport-enter="doMyStuff(params)" enter-one-time)
 */

'use strict';

angular.module('app')
  .directive('ngViewportEnter', ['$parse', '$timeout', '$rootScope',  function($parse, $timeout, $rootScope) {
    return {
      restrict: 'A',
      link: function(scope, element, attr) {
        if (attr.enterOneTime || attr.enterOneTime == '') scope.oneTime = true;

        scope.attrHandler = $parse(attr.ngViewportEnter);
      },
      controller: ['$scope', '$element', function($scope, $element) {
        $timeout(function() {
          var positionWatcher = $scope.$watch(function() {
            var inViewPort = (window.pageYOffset > $($element).offset().top - 200) && (window.pageYOffset < $($element).offset().top + $($element).innerHeight());
            if (inViewPort) {
              $scope.attrHandler($scope);
              if ($scope.oneTime) positionWatcher();
            }
          })
        }, 2000);
      }]
    }
  }]);
