// This controller is a quick and dirty fix for the first iteration of the BioSuisse Storefront.
// It helps the display of BioSuisse details in the catalog page.
// Since the plan is to use an all-new home for it, this controller won't be relevant for long.

angular.module('app').controller('SupplierDetailsCtrl', ['$scope', '$http', '$q', '$rootScope', '$sce', '$timeout', '$element', 'Alerts', 'blockUI', '$location', 'CatalogServiceHelper', 'TaxonHelper', '$uibModal', '$localStorage', 'UiStateService',
  function ($scope, $http, $q, $rootScope, $sce, $timeout, $element, Alerts, blockUI, $location, CatalogServiceHelper, TaxonHelper, $uibModal, $localStorage, UiStateService) {
  window.SupplierDetailsCtrl = $scope

  function constructor() {
    $scope.$watch('supplierId', () => {
      $scope.loadSupplier()
    })
  }

  function destructor() {
    window.SupplierDetailsCtrl = null;
  }

  $scope.loadSupplier = function() {
    return $http.get(`/api/frontend/suppliers/${$scope.supplierId}/multimedia_contents.json`).then((result) => {
      $scope.supplierMultimedia = result.data.multimedia;
      $scope.supplier = result.data.supplier;

      if ($scope.supplierMultimedia.images && $scope.supplierMultimedia.images.length > 0) {
        $scope.setCurrentImage(0);
      }
    }, e => {
      Alerts.error(errorMessage(e));
    })
  };

  $scope.setCurrentImage = function(num) {
    $scope.currentImageNum = num;

    if ($scope.currentImageNum >= 0) {
      $('.photo-gallery img.main-image').attr('src', $scope.supplierMultimedia.images[num].large);
      $scope.currentImage = $scope.supplierMultimedia.images[num];
    }
  };

  $scope.openGalleryModal = function() {
    if(browserMobileDevice || $('.photo-gallery .main-image').parents('.container-fluidish').width() == $('.photo-gallery .main-image').width())
      return;

    var galleryModal = $uibModal.open({
      animation: true,
      templateUrl: '/ng/templates/suppliers/supplier_gallery_modal.html',
      controller: 'supplierGalleryModalCtrl',
      size: 'lg',
      windowClass: 'supplier-gallery-modal',
      resolve: {
        currentIndex: $scope.currentImageNum,
        images: $scope.supplierMultimedia
      }
    });

    galleryModal.result.then(() => {

    })
  };

  // Initialize

  $scope.$on('$destroy', destructor);
  constructor()
}]);