import * as _ from 'underscore';
import * as sprintf from 'sprintf';

angular.module('app').controller('ProductScrollableListPageCtrl', ['$scope', '$rootScope', '$q', '$location', '$window', '$interval', '$http', '$element', '$sce', '$localStorage', 'Alerts', 'Hubs', 'UiStateService', '$timeout', '$uibModal',
  function ($scope, $rootScope, $q, $location, $window, $interval, $http, $element, $sce, $localStorage, Alerts, Hubs, UiStateService, $timeout, $uibModal) {
    window.ProductScrollableListPageCtrl = $scope;

    $scope.initialOptions = {};

    /**
     * A dictionary of flags that indicate that 'next product' data
     * has already been loaded for a specific product id,
     * @type {{}}
     */
    $scope.loadedForProduct = {};

    $scope.productsList = [];

    $scope.weightNoticeOpen = true;

    $scope.UiStateService = UiStateService;

    function constructor() {
      $scope.initialOptions = JSON.parse($element.attr('data-initial-options'));

      $scope.navigateBackData = UiStateService.backFromProductPageData ? angular.copy(UiStateService.backFromProductPageData) : null;

      if ($scope.navigateBackData) {
        if ($scope.navigateBackData.taxonData)
          $scope.navigateBackName = $scope.navigateBackData.taxonData.name;
      }

      $scope.currentProductId = $scope.initialOptions.productId;

      $scope.enableNavigationScrolling = false;

      $scope.scrollTarget = angular.element(window);

      if ($scope.initialOptions.currentTaxonId) window.currentTaxonId = $scope.initialOptions.currentTaxonId;
      if ($scope.initialOptions.currentParentTaxonId) window.currentParentTaxonId = $scope.initialOptions.currentParentTaxonId;

      $scope.currentProduct = {
        id: $scope.currentProductId,
        template: `/ng/templates/products/product_page.html?ng_view_load=t&product_id=${$scope.currentProductId}&taxon_id=${$scope.initialOptions.currentTaxonId}&parent_taxon_id=${$scope.initialOptions.currentParentTaxonId}&supplier_id=`
      };

      $scope.firstProductId = $scope.currentProduct.id; // will be checked by the product page to decide whether to do tracking automatically

      $scope.productsList.push($scope.currentProduct);

      if (window.lastUsedSortMode) sortMode = window.lastUsedSortMode;

      setTimeout(() => {
        // Manual setup of the affixed element
        $($element.find('.navigate-back')).affix({ offset: { top: $element.find('.navigate-back').data('offset') }});
      }, 10);

      if (!window.browserIsBot) {
        TestManager.startTest(`products_show_scrolling_${window.isMobile ? 'mobile' : 'desktop'}`, 'noscroll').then(result => {
          if (result.variant == 'scroll') {
            $scope.enableNavigationScrolling = true;

            $scope.loadNextProduct().then((nextProduct) => {
              if (nextProduct && nextProduct.id) {
                $scope.productsList.push({
                  id: nextProduct.id,
                  template: `/ng/templates/products/product_page.html?ng_view_load=t&product_id=${nextProduct.id}&taxon_id=${$scope.initialOptions.currentTaxonId}&parent_taxon_id=${$scope.initialOptions.currentParentTaxonId}&supplier_id=`
                });
              }
            });

            $('html, body').animate({scrollTop: (window.isMobile ? 50 : 150)}, 100); // Force scroll-top

            checkScrollingInterval = setInterval(() => {
              checkScrolling();
            }, 333);
          }
        }, rejection => {
          // No endless scrolling this time
        })
      }
    }

    function destructor() {
      window.ProductScrollableListPageCtrl = null;

      if (checkScrollingInterval) clearInterval(checkScrollingInterval);
    }

    // Private members

    var bodyElement, nearBottomElement;
    var sortMode = 'hub_editorial_best';
    var checkScrollingInterval;

    function shouldLoadNextProduct() {
      // TODO: Check if near bottom
      // TODO: Check if already loading a product
    }


    /**
     * Updates pagination-specific metadata
     */
    function setMetaData() {
      var nextUrl, previousUrl;

      var index = _.findIndex($scope.productsList, p => p == $scope.currentProduct);

      if (index < $scope.productsList.length - 1) {
        let nextPageCtrl = $(`.product-page-ctrl[data-product-id=${$scope.productsList[index + 1].id}]`).scope('ProductPageCtrl');
        nextUrl = nextPageCtrl && nextPageCtrl.product && nextPageCtrl.product.seo_url;
      }

      if (index > 0 && $scope.productsList.length > 1) {
        let previousPageCtrl = $(`.product-page-ctrl[data-product-id=${$scope.productsList[index - 1].id}]`).scope('ProductPageCtrl');
        previousUrl = previousPageCtrl && previousPageCtrl.product && previousPageCtrl.product.seo_url;
      }

      // <link rel="next" href="cats_part_2>
      if (nextUrl) {
        if ($("link[rel='next']").length == 0) {
          $('head').append(`<link rel="next" href="${nextUrl}" />`)
        } else {
          $("link[rel='next']").attr('href', nextUrl)
        }
      } else $("link[rel='next']").attr('href', '')

      if (previousUrl) {
        if ($("link[rel='prev']").length == 0) {
          $('head').append(`<link rel="prev" href="${previousUrl}" />`)
        } else {
          $("link[rel='prev']").attr('href', previousUrl)
        }
      } else $("link[rel='prev']").attr('href', '')
    };

    function checkScrolling() {
      bodyElement = bodyElement || $('body');
      nearBottomElement = nearBottomElement || $('.near-bottom');

      var scrollTop = ($scope.scrollTarget && $scope.scrollTarget.scrollTop) ? $scope.scrollTarget.scrollTop() : bodyElement.scrollTop();

      if (window.isMobile)
        scrollTop = Math.max(scrollTop, bodyElement.scrollTop());

      let nearBottomOffset = nearBottomElement.offset();
      let nearBottom = false;

      // Detect scroll to screen bottom situation
      if (nearBottomOffset) nearBottom = scrollTop > nearBottomOffset.top - 800;

      let sections = $('.product-page-end[data-product-id]');
      // Detect section that is currently in the view
      let currentSection = _.chain(sections).map(section => {
        let $section = $(section);
        let offset = $section.offset();

        return {top: offset.top, relativeTop: offset.top - scrollTop, $section: $section}
      }).filter(item => item.relativeTop > (window.isMobile ? 250 : 200)).sortBy(item => item.relativeTop).value()[0];

      if (currentSection) {
        let activeProductId = parseInt(currentSection.$section.attr('data-product-id'));
        if ($scope.currentProduct && $scope.currentProduct.id != activeProductId) {
          $scope.$apply(() => {
            $scope.setCurrentViewProduct({id: activeProductId});
          })
        }
      }
    }

    // Public members

    $scope.loadNextProduct = function () {
      $scope.isLoadingNextProduct = true;

      return $q((resolve, reject) => {
        if ($scope.loadedForProduct[$scope.currentProduct.id]) {
          $scope.loadedForProduct[$scope.currentProduct.id].wasLoaded = true;
          resolve($scope.loadedForProduct[$scope.currentProduct.id]);
          return;
        }

        let params = {
          sort_mode: sortMode,
          hub_id: Hubs.currentHub.id,
          taxon_id: window.currentTaxonId || window.currentParentTaxonId,
          parent_taxon_id: window.currentParentTaxonId,
          supplier_id: window.currentSupplierId
        };

        if ($scope.navigateBackData) {
          if ($scope.navigateBackData.filters)
            params = angular.extend(params, {s: $scope.navigateBackData.filters});

          if ($scope.navigateBackData.taxonData) {
            params.taxon_id = $scope.navigateBackData.taxonData.id;
            params.parent_taxon_id = $scope.navigateBackData.taxonData.parent_id;
          }
        }

        $http.get(`/api/frontend/products/${$scope.currentProduct.id}/next_product_for_scrolling.json`, {params: params}).then(response => {
          $scope.nextProduct = response.data.next_product;

          if ($scope.nextProduct) {
            $scope.loadedForProduct[$scope.currentProduct.id] = $scope.nextProduct;
            resolve($scope.nextProduct)
          } else {
            reject({endOfList: true})
          }
        })
      }).finally(() => {
        $scope.isLoadingNextProduct = false;
      })
    };

    $scope.setCurrentViewProduct = function (product) {
      $scope.currentProduct = _.find($scope.productsList, p => p.id == product.id);

      // Force-set product meta
      let currentPageCtrl = $(`.product-page-ctrl[data-product-id=${product.id}]`).scope('ProductPageCtrl');

      if (currentPageCtrl.product) {
        currentPageCtrl.setNavigationPath();
        currentPageCtrl.setMetaData();
        currentPageCtrl.trackProductView();

        setMetaData();
      }

      let index = _.findIndex($scope.productsList, p => p.id == $scope.currentProduct.id);

      if (index < $scope.productsList.length - 1) $scope.nextProduct = $scope.productsList[index + 1];
      else $scope.nextProduct = null;

      if ($scope.enableNavigationScrolling) {
        $scope.loadNextProduct().then(nextProduct => {
          if (nextProduct && nextProduct.wasLoaded != true && _.find($scope.productsList, p => p.id == nextProduct.id) == null) {
            $scope.productsList.push({
              id: nextProduct.id,
              template: `/ng/templates/products/product_page.html?ng_view_load=t&product_id=${nextProduct.id}&taxon_id=${$scope.initialOptions.currentTaxonId}&parent_taxon_id=${$scope.initialOptions.currentParentTaxonId}&supplier_id=`
            });

            $scope.nextProduct = $scope.productsList[$scope.productsList.length - 1];
          }
        });
      }
    };

    $scope.onChildProductViewProductLoaded = function(productPageCtrl){
      setMetaData();

      // Inject extended product data into the product list of just product ids
      if (productPageCtrl && productPageCtrl.product) {
        let index = _.findIndex($scope.productsList, p => p.id == productPageCtrl.product.id);

        if (index && index > -1 && $scope.productsList[index] && $scope.productsList[index].name == null) {
          $scope.productsList[index].name = productPageCtrl.product.name;
          $scope.productsList[index].image_xs = productPageCtrl.product.image_xs;

          if ($scope.productsList[index] && $scope.nextProduct && $scope.nextProduct.id == $scope.productsList[index].id)
            $scope.nextProduct = $scope.productsList[index];
        }
      }
    };

    $scope.navigateBack = function () {
      if (UiStateService.backFromProductPageData && UiStateService.backFromProductPageData.location) {
        $location.path(UiStateService.backFromProductPageData.location.path).search(UiStateService.backFromProductPageData.location.search || {});
      }
    };

    $scope.scrollToNext = function() {
      $scope.scrollTo($(`.product-page-ctrl[data-product-id=${$scope.nextProduct.id}]`));
    };

    $scope.setWeightControlHeight = function () {
      // $scope.weightNoticeHeight = $('.weight-control-explanation-wrapper').outerHeight();
      $scope.weightNoticeOpen = false;
    };

    $scope.$on('$destroy', destructor);
    constructor();
  }]);
