import {t} from "../../shared-services/translate";
import {hashUserDetails} from "../../services/hashing-module";
import {trackEvent} from "../../services/tracking-helper";

angular.module("app").controller("UserRegistrationFormCtrl", ["$scope", "$http", "$q", "Alerts", "$location", "$element", "$timeout", "AddressHelper", "$translate", "blockUI", function($scope, $http, $q, Alerts, $location, $element, $timeout, AddressHelper, $translate, blockUI) {
  window.UserRegistrationFormCtrl = $scope;

  window.preventPopups = true;

  function constructor() {
    $scope.user = {storefront_id: window.currentStorefront};
    $scope.address = {country_id: AddressHelper.DEFAULT_COUNTRY_ID};
    $scope.billAddress = {country_id: AddressHelper.DEFAULT_COUNTRY_ID};

    /**
     * The controller supports 'short' (default) and 'full' forms.
     *
     * The full form also contains an address that will be validated
     * and sent together with registration data.
     *
     * @type {string}
     */
    $scope.formMode = $element.attr("form-mode") || "short";
    $scope.addressRequired = $element.attr("address-required") === "true";
    $scope.showBirthDate = $element.attr("show-birth-date") !== "false";
    $scope.addressFormVisible = $scope.addressRequired;

    $scope.monthNames = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"].map(month => {
      return t(`calendar.month.${month}`);
    }).join(",");

    $scope.phoneValidated = false;

    // isB2b is actually defined in the RegistrationViewCtrl scope
    // one level above
    // /**
    //  * If changed to true, will cause the form to appear differently.
    //  * A separate billing address form will also appear to allow bill address
    //  * details
    //  *
    //  * @type {boolean}
    //  */
    // $scope.isB2b = false;

    $scope.isFormValid = false;

    // Hack to assign child scope
    if (window.RegistrationViewCtrl) window.RegistrationViewCtrl.UserRegistrationFormCtrl = $scope;

    // Setup custom zipcode validator
    $timeout(() => {
      $scope.addressForm = $element.find("[name=addressForm]").scope("ngFormController") && $element.find("[name=addressForm]").scope("ngFormController").addressForm;

      // Based on https://docs.angularjs.org/api/ng/type/ngModel.NgModelController#$asyncValidators
      const modelZipcode = $element.find("input[name=zipcode]").controller("ngModel");
      if (modelZipcode) modelZipcode.$asyncValidators.unknown = AddressHelper.validators.knownZipcode;

      const modelEmail = $element.find("input[name=email]").controller("ngModel");
      if (modelEmail) modelEmail.$asyncValidators.taken = UserService.validators.emailTaken;

      const modelCity = $element.find("input[name=city]").controller("ngModel");
      if (modelCity) modelCity.$asyncValidators.invalid = AddressHelper.validators.validCity;
    }, 1);

    if ($scope.formMode === "full") {
      $scope.$watch("address.birth_date", (newValue) => { $scope.addressForm && $scope.onAddressBirthDateChange() });
    }

    $scope.$watch("registrationForm.$valid", (newValue) => {
      $scope.userProfileComplete = newValue;
    });

    $timeout(() => {
      // Only fetch if no typing has started
      if ($scope.user.email == null || $scope.user.email === "") {
        $scope.fetchPendingPrefills();
      }
    }, 200);

    $scope.$watchCollection("user", newValue => { $scope.validateForm() });
    $scope.$watchCollection("address", newValue => { $scope.validateForm() });

    // Check social login
    const socialLoginUser = JSON.parse(window.localStorage.getItem("socialLoginUser"));

    $scope.socialLoginUser = socialLoginUser;

    if (socialLoginUser) {
      $scope.user.first_name = socialLoginUser.first_name;
      $scope.user.last_name = socialLoginUser.last_name;
      $scope.user.email = socialLoginUser.email;
      $scope.user.phone_no = socialLoginUser.phone_no;
      $scope.user.facebook_id = socialLoginUser.facebook_id;

      const parsedBirthDate = moment(socialLoginUser.birth_date, "YYYY-DD-MM");
      if (parsedBirthDate.isValid()) $scope.address.birth_date = parsedBirthDate.toDate();
      $scope.address.gender = socialLoginUser.gender.toString();
    }
  }

  function destructor() {
    window.UserRegistrationFormCtrl = null;
    window.localStorage.removeItem("socialLoginUser");
  }

  function doRegisterB2c() {
    $scope.userSignup().then(() => {
      const checkoutPath = (window.isMobile || !NgFrontendAppCtrl.enableCartSidebar) ? "/cashier" : "/cart";

      if ($scope.proceedToCheckout) {
        Alerts.success($translate.instant("messages.logged_in_succesfully"));
        $location.path(`${NgFrontendAppCtrl.locationLocalePrefix}${checkoutPath}`).search({});
      } else {
        $location.path("/");
      }
    }, (e) => {
      // Do nothing
    });
  }

  function doRegisterB2b() {
    return $q((resolve, reject) => {
      $scope.validateB2bProfile().then(result => {
        if (result) {
          $scope.userSignup().then(() => {
            const checkoutPath = (window.isMobile || !NgFrontendAppCtrl.enableCartSidebar) ? "/cashier" : "/cart";

            if ($scope.proceedToCheckout) {
              Alerts.success($translate.instant("messages.logged_in_succesfully"));
              $location.path(`${NgFrontendAppCtrl.locationLocalePrefix}${checkoutPath}`).search({});
            } else {
              $location.path("/");
            }

            resolve(true);
          });
        } else {
          Alerts.error("Problems with your corporate billing address...");
          reject("Problems with your corporate billing address...");
        }
      });
    });
  }

  $scope.userSignup = function() {
    $scope.isRegistering = true;
    blockUI.start();

    let formData = angular.extend({}, $scope.user);
    formData = angular.extend(formData, $scope.address);

    if ($scope.isB2b) { formData.bill_address = $scope.billAddress }

    return $q((resolve, reject) => {
      // eslint-disable-next-line no-undef
      return UserService.signupUser(formData).then(async function(data) {
        const userDetails = {
          first_name: formData.first_name || "",
          last_name: formData.last_name || "",
          street_address: formData.address1 || "",
          postal_code: formData.zipcode || "",
          city: formData.city || "",
          phone: formData.phone_no || "",
          email: formData.email || "",
          country: "CH"
        };

        try {
          await trackEvent("register", {userDetails});
        } catch (error) {
          console.error("Error tracking registration event", error);
        }

        $scope.isRegistering = false;
        blockUI.stop();
        $scope.isRegistered = true;
        resolve(data);
      }, function(error) {
        blockUI.stop();
        $scope.isRegistering = false;
        $scope.isRegistered = false;
        Alerts.error($translate.instant("cannot_perform_operation"));
        reject(error);
      }).finally(() => { blockUI.stop() });
    });
  };

  $scope.userLogin = function() {
    return $q((resolve, reject) => {
      UserService.authenticateByToken($scope.user.token).then(userData => {
        resolve(userData);
      }, e => reject(e));
    });
  };

  $scope.onSubmitClicked = function() {
    $scope.addressForm && $scope.addressForm.$setSubmitted();
    $scope.registrationForm.$setSubmitted();

    if ($scope.isFormValid) {
      if (!$scope.isB2b) { return doRegisterB2c() } else { return doRegisterB2b() }
    }
  };

  $scope.onFacebookButtonClicked = function() {
    facebookLogin();
  };

  $scope.onAddressBirthDateChange = function() {
    UserService.validators.birthDate($scope.address.birth_date).then(r => {
      $scope.addressForm.birth_date = $scope.addressForm.birth_date || {};
      $scope.addressForm.birth_date.$error = $scope.addressForm.birth_date.$error || {};
      $scope.addressForm.birth_date.$error.invalid = undefined;
    }, e => {
      $scope.addressForm.birth_date = $scope.addressForm.birth_date || {};
      $scope.addressForm.birth_date.$error = $scope.addressForm.birth_date.$error || {};
      $scope.addressForm.birth_date.$error.invalid = true;
    });
  };

  $scope.onPhoneBlur = function(event) {
    if ($scope.user && $scope.user.phone_no && $scope.user.phone_no.length > 5 && !$scope.phoneValidated) { $scope.validatePhone() }
  };

  $scope.onPhoneChange = function(event) {
    $scope.phoneValidationFailed = false;
    $scope.phoneValidated = false;
  };

  $scope.validateForm = function() {
    // Depending on the form mode, we're checking if there are any
    // keys in forms' $error object either for just the registration form OR for
    // both forms (+addressForm)
    $timeout(() => {
      if ($scope.formMode === "full") {
        if (($scope.addressForm || !$scope.addressRequired) && $scope.registrationForm) {
          $scope.isFormValid = Object.keys($scope.addressForm.$error).length == 0 && Object.keys($scope.registrationForm.$error).length == 0 &&
            !($scope.addressForm.birth_date && $scope.addressForm.birth_date.$error && $scope.addressForm.birth_date.$error.invalid);
        }
      } else {
        if ($scope.registrationForm) {
          $scope.isFormValid = $scope.registrationForm.$dirty && Object.keys($scope.registrationForm.$error).length == 0;
          if (!$scope.phoneValidated) $scope.isFormValid = false;
        }
      }
    }, 0);
  };

  $scope.validatePhone = function() {
    $scope.phoneValidationFailed = false;
    $scope.phoneValidated = false;

    return $q((resolve, reject) => {
      $http.post("/api/frontend/user_registrations/validate_phone_number.json", {phone: $scope.user.phone_no}).then(response => {
        if (response.data.can_signup) {
          $scope.phoneValidationFailed = false;
          $scope.phoneValidated = true;
          $scope.user.phone_no = response.data.phone.sanitized;
          resolve(response.data);
        } else {
          $scope.phoneValidationFailed = true;
          if (response.data.error) $scope.phoneValidationError = $translate.instant(`messages.${response.data.error}`);
          reject(response.data);
        }
      });
    });
  };

  /**
   * Fetches pending ship address and user details to prefill the form,
   * if this data is available.
   *
   * @returns {Promise}
   */
  $scope.fetchPendingPrefills = function() {
    return $q((resolve, reject) => {
      $http.get("/api/frontend/users/pending_signup_data.json").then(response => {
        if (response.data) {
          if (response.data.user) {
            angular.extend($scope.user, response.data.user);
            $scope.prefilledUserFromOrder = true;
          }

          if (response.data.ship_address) {
            // Transform birth date string to date, if present
            if (response.data.ship_address.birth_date) response.data.ship_address.birth_date = moment(response.data.ship_address.birth_date).toDate();

            // Conver gender token to string
            if (response.data.ship_address.gender) response.data.ship_address.gender = response.data.ship_address.gender.toString();

            $scope.prefilledAddressFromOrder = true;
            angular.extend($scope.address, response.data.ship_address);

            if (response.data.ship_address.firstname) $scope.user.first_name = response.data.ship_address.firstname;
            if (response.data.ship_address.lastname) $scope.user.last_name = response.data.ship_address.lastname;
            if (response.data.ship_address.phone) $scope.user.phone_no = response.data.ship_address.phone;
          }
        }

        resolve(response.data);
      });
    });
  };

  /**
   * Validates b2b profile data with Intrum to confirm
   * user input authenticity and credentials
   */
  $scope.validateB2bProfile = function() {
    $scope.b2bValidationError = false; // just for semantics in the view
    $scope.b2bProfileValidated = false;

    return $q((resolve, reject) => {
      const address = angular.merge({}, $scope.billAddress);

      const params = {
        user: {email: $scope.user.email},
        bill_address: address
      };

      $http.post("/api/frontend/users/check_b2b_profile.json", params).then(response => {
        const profileValid = response.data.allowed;

        $scope.b2bProfileValidated = profileValid;
        $scope.b2bValidationError = !profileValid;

        if (profileValid) resolve(profileValid);
        else reject(profileValid);
      }, e => {
        console.error(errorMessage(e), e);
        reject(errorMessage(e));
      });
    });
  };

  $scope.testFillForm = function() {
    let randomToken = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) { randomToken += possible.charAt(Math.floor(Math.random() * possible.length)) }

    const randomEmail = "testUser_" + randomToken + "@mobiquest.ru";

    $scope.user.email = randomEmail;
    $scope.user.password = "12345678";
    $scope.user.password_confirmation = "12345678";
    $scope.user.phone_no = "+43 23 123-22-94";
    $scope.user.first_name = "Max";
    $scope.user.last_name = "Mustermann";

    $scope.address.title = "Herr";
    $scope.address.gender = "2";
    $scope.address.address1 = "Hauptstrasse";
    $scope.address.house_number = "24B";
    $scope.address.zipcode = "8001";
    $scope.address.city = "Zürich";
    $scope.address.birth_date = moment().add(-28, "years").toDate();

    // $timeout(() => {
    //   $scope.validateForm()
    // }, 10)
  };

  $scope.$on("$destroy", destructor);
  constructor();
}]);
