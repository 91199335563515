import * as _ from 'underscore';
import * as moment from 'moment';

'use strict';

angular.module('app').controller('DeliverySlotOverviewCtrl', ['$scope', '$rootScope', '$sce', '$http', '$q', '$timeout', '$element', 'blockUI', '$swipe', function ($scope, $rootScope, $sce, $http, $q, $timeout, $element, blockUI, $swipe) {
  window.DeliverySlotOverviewCtrl = $scope;

  /**
   * An object storing previously loaded slots batches.
   * Useful only if there's a period selector, to prevent new api calls upon repeated click.
   * @type {null}
   */
  $scope.deliverySlotsCache = {};

  /**
   * A structure, containing checkoutCtrl.availableDeliverySlots grouped by dates
   * @type {null}
   */
  $scope.slotsByDate = null;

  /**
   * Array of dates with available slots for the ng-repeater of the slot selector
   * @type {null}
   */
  $scope.slotDates = null;

  $scope.slotDays = null;

  $scope.selectedSlot = null;

  $scope.selectedPeriod = 'default';

  //
  // Private members
  //

  // Some UI layout helpers
  var SLOT_ELEMENT_WIDTH = () => 160;
  var SLOT_ELEMENT_MARGINS = () => 16;

  var zipcodeListener, hubsListener, expressListener;

  function constructor() {
    $scope.zipcode = window.currentZipcode;
    if ($scope.zipcode) {
      $scope.getDeliverySlots();
    }

    zipcodeListener = $rootScope.$on('hubs:zipcode_modal:closed', () => {
      if (window.currentZipcode && window.currentZipcode != $scope.zipcode) {
        $scope.deliverySlotsCache = {};
        $scope.zipcode = window.currentZipcode;
        $scope.getDeliverySlots();
      }
    });

    hubsListener = $rootScope.$on('hubs:changed', () => {
      if (window.currentZipcode) {
        $scope.deliverySlotsCache = {};
        $scope.zipcode = window.currentZipcode;
        $scope.getDeliverySlots();
      }
    });

    expressListener = $rootScope.$on('express:changed', (event) => {
      if (window.currentZipcode) {
        $scope.deliverySlotsCache = {};
        $scope.zipcode = window.currentZipcode;
        $scope.getDeliverySlots();
      }
    });

    // Watch changes to source available slots, and transform the array
    // into a number of data-structures needed for the delivery slot selector to work
    let slotsWatcher = $scope.$watch('deliverySlots', function(newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        transformSlots();
      }

      slotsWatcher()
    });


    let datesWatcher = $scope.$watch('slotDates', function(newValue, oldValue) {
      if (newValue && newValue.length > 0) $timeout(setDatesHeight, 1000);

      datesWatcher()
    });
  }

  function destructor() {
    window.CheckoutDeliveryTimeCtrl = null;
    zipcodeListener && zipcodeListener();
    hubsListener && hubsListener();
    expressListener && expressListener();
  }

  $scope.getDeliverySlots = function () {
    $scope.loadingDeliverySlots = true;

    return $q(function (resolve, reject) {
      if ($scope.deliverySlotsCache[$scope.selectedPeriod]) {
        $scope.deliverySlots = $scope.deliverySlotsCache[$scope.selectedPeriod];
        transformSlots();
        resolve($scope.deliverySlots)
      } else {
        $http.get(`/api/farmy/delivery_slots/overview_index.json?zipcode=${$scope.zipcode}&express_delivery=${CartData.isExpressDelivery ? 't' : 'f'}&locale=${I18n.locale}&period=${$scope.selectedPeriod}`).then(function (response) {
          if (!response.data.not_available_delivery_slot) {
            $scope.deliverySlots = response.data.delivery_slots;
            $scope.availablePeriods = response.data.available_periods;
            $scope.deliverySlotsCache[$scope.selectedPeriod] = $scope.deliverySlots;
            transformSlots()
            resolve($scope.deliverySlots);
          }
        }, (e) => reject(e)).finally(() => $scope.loadingDeliverySlots = false);
      }
    });
  };

  function setDatesHeight() {
    if ($scope.checkoutCtrl.stage == 'delivery_time') {
      var slots = $('.delivery-day');
      var maxHeight = _(_(slots).map(function(e) { return $(e).height() })).max();

      slots.height(maxHeight);
    }
  }

  $scope.onPeriodSelected = function(period) {
    if ($scope.selectedPeriod == period) return;

    $scope.selectedPeriod = period;
    $scope.getDeliverySlots()
  }

  /**
   * Reads the available delivery slots array and converts it
   * into a structure grouped by slot dates
   */
  function transformSlots() {
    $scope.slotsByDate = _.groupBy($scope.deliverySlots, (slot) => moment(slot.delivery_time).locale(I18n.locale).format("Do ddd"));
    $scope.slotDays = _.map($scope.slotsByDate, (slots, key) => key);
    $scope.slotDates = _.map($scope.slotsByDate, (slots, key) => moment(slots[0].delivery_time).startOf('day').toDate() );

    $element.find('.inner-scroll').css({width: `${$scope.slotDates.length * (SLOT_ELEMENT_WIDTH() + SLOT_ELEMENT_MARGINS())}px` });
  }

  $scope.removeShadowOverlay = function() {
    var element = $('.shadow-overlay');

    element.addClass('invisible');
    $timeout(function() {
      element.remove();
    }, 500)
  };

  // Initialize

  $scope.$on('$destroy', destructor);
  constructor()
}]);
