import * as _ from 'underscore';
import {trackEvent} from '../services/tracking-helper';

angular.module('app').controller('LineItemCtrl', ['$scope', '$rootScope', '$element', '$filter', 'Alerts', 'OrderUpdater', 'CartData', function ($scope, $rootScope, $element, $filter, Alerts, OrderUpdater, CartData) {
  window.lineItemCtrl = $scope;
  $scope.product = $scope.line_item.product;
  $scope.lineItemQuantityUpdating = false;
  $scope.variantSelectorOptions = null;
  $scope.currentDayOfWeek = $rootScope.currentDayOfWeek;

  $scope.updateVariant = function (variant) {
    // Force-hide the dropdown on update, both lines are required:
    // http://stackoverflow.com/questions/10941540/how-to-hide-twitter-bootstrap-dropdown

    $element.find('.dropdown.open .dropdown-toggle').dropdown('hide');
    $element.find('.product-qty.btn-group').removeClass('open');

    if ($scope.line_item.variant.id != variant.id) {
      var old_variant_id = $scope.line_item.variant.id;
      $scope.line_item.variant = variant;

      setUpdatedTotals(variant);

      setSaving();
      $scope.$parent.setSaving();

      $rootScope.$broadcast("line_item:change:start");

      CartData.setCartVariant($scope.product.id, $scope.line_item.variant).then(
        function() {
          $scope.loadOrder().then(function() {
            setNotSaving();
          });

          $rootScope.$broadcast("line_item:change:end");
        },
        function(response) {
          setNotSaving();
          $scope.$parent.setNotSaving();

          if(response == "expired") {
            // Just do nothing if request expired
          } else if (response.data.full_messages && response.data.full_messages.length > 0) {
            Alerts.error(response.data.full_messages);
          } else {
            Alerts.error($translate.instant('errors.unknown_error'));
          }

          $rootScope.$broadcast("line_item:change:error");
        }
      ).finally(function(data) {
      });
    }
  };

  $scope.updateQuantityByCheckbox = function () {
    $scope.line_item.updated_at = moment().format();

    if ($scope.line_item.quantity == 0) {
      $scope.isHidden = true;
    }

    // setUpdatedTotals(variant);
    //
    setSaving();
    $scope.$parent.setSaving();

    $scope.line_item.quantity = $scope.line_item.quantity > 0 ? 0 : 1;

    $rootScope.$broadcast("line_item:updated");

    OrderUpdater.updateQuantity($scope.line_item.id, $scope.line_item.quantity).then(
      function() {
        $scope.loadOrder().then(function() {
          setNotSaving();
        });
      },
      function(response) {
        setNotSaving();
        $scope.$parent.setNotSaving();

        if(response == "expired") {
          // Just do nothing if request expired
        } else if (response.data.full_messages && response.data.full_messages.length > 0) {
          Alerts.error(response.data.full_messages);
        } else {
          Alerts.error($translate.instant('errors.unknown_error'));
        }
      }
    ).finally(function(data) {
      if ($scope.line_item.quantity > 0) {
        $scope.isHidden = false;
      }
    });
  };

  $scope.deleteLineItem = function() {
    $scope.isDeleted = true;
    trackEvent(
      "removefromcart",
      {
        gtmObject: {value: $scope.line_item?.price},
        products: {...$scope.line_item, productQuantity: $scope.line_item?.variant?.quantity_index}
      }
    ).finally(() => {
      trackEvent(
        "product_removed_from_cart",
        {
          gtmObject: {value: $scope.line_item?.price},
          products: {...$scope.line_item, productQuantity: $scope.line_item?.variant?.quantity_index}
        }
      );
    });
    $scope.line_item.quantity = 0;

    $scope.$parent.setSaving();

    $rootScope.$broadcast("line_item:delete:clicked");

    OrderUpdater.deleteLineItem($scope.line_item.id).then(
      function() {
        $scope.$parent.setNotSaving();
        $scope.loadOrder();
      },
      function() {
        $scope.isDeleted = false;
        $scope.$parent.setNotSaving();
        Alerts.error($translate.instant('errors.unknown_error'));
      }
    );
  };

  // private

  function setSaving() {
    $scope.lineItemQuantityUpdating = true;
  }

  function setNotSaving() {
    $scope.lineItemQuantityUpdating = false;
  }

  function setUpdatedTotals(variant) {
    $scope.line_item.price = variant.price;
  }

  function updateLayout() {
    $element.find('.btn-handle').css({ height: $element.find('img.img-thumb').height() });
  }

  $element.find('img.img-thumb').on('load', function() { updateLayout() });

  // Setting product during initialization isnt' enough
  $scope.$watch('line_item', function(value) {
    $scope.product = value.product;

    if($scope.product != null) {
      $scope.variantSelectorOptions = _.map($scope.product.variants_and_option_values, function(v) {
        var v = { id: v.id, variantObject: v, name: v.options_text, price: v.price, label: v.quantity_label + ', CHF ' + $filter('number')(v.price, '2') };

        if ($scope.line_item && $scope.line_item.variant && v.id == $scope.line_item.variant.id)
          $scope.variantInSelector = v;

        return v;
      });
    }


  })

  // This is currently used by controls in mobile layout only
  $scope.$watch('variantInSelector', function(newVariant, oldVariant) {
    if (newVariant != null && newVariant != oldVariant)
      $scope.updateVariant(newVariant);
  });
}]);
