import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app')
  .directive('farmyCatalogFilterTree', function() {
    return {
      controller: ['$rootScope', '$scope', '$http', '$q', '$timeout', 'Alerts', 'blockUI', '$attrs', '$element', 'TaxonHelper', 'CatalogServiceHelper', function ($rootScope, $scope, $http, $q, $timeout, Alerts, blockUI, $attrs, $element, TaxonHelper, CatalogServiceHelper) {
        var globalName = null;

        function constructor() {
          $scope.$watch('filter', (newValue) => {
            if (newValue) {
              globalName = 'catalogFilter_' + newValue.search_param;
              window[globalName] = $scope;
            }
          });

          $scope.$watch('selections', (newValue, oldValue) => {
            // console.log(newValue, oldValue, newValue == oldValue);
            if (newValue != null && newValue != oldValue) {
              // TODO: Refactor this nasty way of calling back
              if (window.CatalogViewCtrl) window.CatalogViewCtrl.update({ resetPage: true });
              if (window.CatalogSearchViewCtrl) window.CatalogSearchViewCtrl.update({ resetPage: true });
              if (window.SupplierPageViewCtrl) window.SupplierPageViewCtrl.update({ resetPage: true });

              if ($scope.change) {
                $scope.change($scope);
              }
            }
          }, true);

          $scope.$watch('CatalogServiceHelper.currentTaxon', (newValue, oldValue) => {
            if (newValue != null) {
              var taxon = CatalogServiceHelper.currentTaxon;
              $scope.currentParent = angular.extend($scope.currentParent || {}, { id: taxon.parent_id, name: taxon.parent_name, permalink: taxon.parent_permalink, parent_id: taxon.parent_parent_id, all_parents: taxon.parents });
            } else if (newValue == null) {
              $scope.currentParent = null;
            }
          });

          $scope.$watch('minimized', (newValue) => {
            if (newValue == 'false') $scope.minimized = false;
            else if (newValue == 'true') $scope.minimized = true;
          });

          $scope.$watch('minimizing', (newValue) => {
            if (newValue == 'false') $scope.minimizing = false;
            else if (newValue == 'true') $scope.minimizing = true;
          });

          $timeout(() => { $scope.isReady = true }, 10);
        }

        function destructor() {
          if (globalName) {
            window[globalName] = null;
          }
        }

        // Public members

        $scope.CatalogServiceHelper = CatalogServiceHelper;

        /**
         * Selects the parent node (category), if present and unchecks all
         * other nodes.
         */
        $scope.selectParent = function() {
          if ($scope.currentParent) {
            for(var k in $scope.selections) {
              $scope.selections[k] = false;
            }

            $scope.selections[$scope.currentParent.id] = true;
          } else {
            throw new Error("No parent to select");
          }
        };

        $scope.onNodeChange = function(node) {
          // Reset the parent flag shortly after, otherwise it'll
          // get stuck as always selected, even though there's no checkbox for it,
          // unless +multiselect+ is enabled.
          if (!$scope.multiselect) {
            for(var k in $scope.selections) {
              if (k != node.value) {
                $scope.selections[k] = false;
              }
            }
          }
        };

        $scope.toggleMinimize = function() {
          $scope.minimized = !$scope.minimized;
        };

        // Initialize

        /**
         * Default value for selections, if not set
         * @type {{}}
         */
        $scope.selections = $scope.selections || {};

        $scope.minimized = $scope.minimized || window.isMobile;
        $scope.minimizing = $scope.minimizing || window.isMobile;

        $scope.isReady = false;

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      scope: {
        filter: '=',
        multiselect: '@',
        selections: '=',
        change: '&',
        minimized: '=',
        minimizing: '=',
      },
      link: function($scope, $element, attributes) {

      },
      templateUrl: function() { return `/ng/templates/products/filter_tree.html?locale=${window.I18n.locale}` }
    }
  });


