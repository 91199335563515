var dependencies = [
  'ngAnimate',
  'ngAria',
  'ngCookies',
  // 'ngResource',
  'ngRoute',
  'ngSanitize',
  'ngTouch',
  'ngStorage',
  'angularMoment',
  'pascalprecht.translate',
  'ui.bootstrap',
  //'ui.bootstrap.carousel',
  // 'angular-parallax',
  'ngComboDatePicker',
  'blockUI',
  'angular-clipboard',
  'angularLoad',
  // 'credit-cards',
  'ng-token-auth'
]

// if (window.Rails.env == 'development') {
//   dependencies.push('angularStats')
// }

window.appModule = angular.module('app', dependencies);

// Allow lazy-loading of controllers and directices in the 'app' module
// See https://dpopescu.me/2016/02/29/dynamic-loading-of-angularjs-components/
// and https://www.bennadel.com/blog/2553-loading-angularjs-components-after-your-application-has-been-bootstrapped.htm
angular.__module = angular.module;
angular.module = function (module, requires, configFn) {
  // console.log('angular.module', module, requires, configFn);
  return window.appModule;
};
