import * as _ from 'underscore';

angular.module('app').directive('orderItem', () => {
  return {
    controller: ['$rootScope', '$scope', '$location', 'OrderHelper', 'CartData', '$http', 'NativeContainer', function ($rootScope, $scope, $location, OrderHelper, CartData, $http, NativeContainer) {
      $scope.browser = NativeContainer.browser;

      function constructor() {
        $scope.showProductList = window.isMobile ? false : true;
        $scope.isLoading = false;
        $scope.productCount = $scope.order.line_item_product_ids.length;
        $scope.lineItemProductIdsString = $scope.order.line_item_product_ids.length > 0 ? _($scope.order.line_item_product_ids).uniq().join(',') : null;
      }

      $scope.onShowProductsClicked = function() {
        $scope.isLoading = true;
        $scope.showProductList = true;
      };

      $scope.onHideProductsClicked = function() {
        $scope.showProductList = false;
      };

      $scope.repeatOrder = function() {
        let params = {
          id: $scope.order.id,
          token: $scope.order.token,
        };

        blockUI.start();

        OrderHelper.repeatOrder({ id: $scope.order.id, token: $scope.order.token }).then(() => {
          blockUI.stop();
          $location.path("/cart");
        }, e => {
          Alerts.error(errorMessage(e));
          blockUI.stop();
        });
      };

      $scope.setOrderAsCurrent = function(orderId) {
        CartData.doUpdateCart = false; // Switch off automatic cart syncing, to avoid conflicts between carts

        blockUI.start($translate.instant('messages.please_wait_while_loading'));

        return $http.post($scope.locationLocalePrefix + sprintf('orders/%d/set_to_current.json', $scope.orderId)).then(function(response) {
          location.href = $scope.locationLocalePrefix = '/cart';
        }, function (error) {
          Alerts.error(errorMessage(error));
          CartData.doUpdateCart = true;
        }).finally(() => {
          blockUI.stop()
        });
      };

      function destructor() {
      }

      constructor();

      $rootScope.$on('productList:loaded', () => $scope.isLoading = false);

      $scope.$on('$destroy', destructor)
    }],
    scope: {
      order: '=',
      layout: '@'
    },
    link: function($scope, $element, attributes) {

    },
    template: `<ng-include ng-show='layout' src="'/ng/templates/orders/order_item_' + layout + '.html?locale=${window.I18n.locale}'" />`
  }
});
