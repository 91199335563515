import * as _ from 'underscore';

angular.module('app').controller('PaymentsOrderStatusPageCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$routeParams', 'Alerts', 'blockUI', 'Hubs', 'UserService', '$location', '$translate', '$timeout', 'CartData', 'DatatransHelper',
                                                        function ($scope,   $sce,   $http,   $q,   $rootScope,   $routeParams,   Alerts,   blockUI,   Hubs,   UserService,   $location,   $translate,   $timeout,   CartData,   DatatransHelper) {
  window.PaymentsOrderStatusPageCtrl = $scope;

  //
  // Private members:
  //

  function constructor() {
    $scope.currency = window.defaultCurrency;
    $scope.orderToken = $location.search().order_token;
    $scope.payNowMode = $location.search().pay_now != null;
    $scope.customAmount = $location.search().amount ? parseFloat($location.search().amount) : null;

    $scope.loadPayment();
  }

  function destructor() {
    window.PaymentsOrderStatusPageCtrl = null;
  }

  function onDatatransPopupClosed() {
    if (window.Rails.env === "development") console.log("onDatatransPopupClosed");
  }

  $scope.loadPayment = function() {
    return $q((resolve, reject) => {
      var paymentId = null;

      if (window._PaymentsPayPageCtrl_paymentId && $routeParams.id == null) {
        paymentId = window._PaymentsPayPageCtrl_paymentId;
      } else if ($routeParams.payment != null) {
        paymentId = $routeParams.payment;
      } else {
        Alerts.error("Fehler bei der Bezahlung");
        location.assign('/cashier');
        return reject("No payment id provided");
      }

      var params = {};

      if ($scope.orderToken) params.order_token = $scope.orderToken;
      if ($scope.customAmount) params.amount = $scope.customAmount;

      return $http.get(`/api/frontend/payments/${paymentId}.json`, { params: params }).then(response => {
        $scope.payment = response.data.payment;

        // Override amount if needed
        if ($scope.customAmount) $scope.payment.amount = $scope.customAmount;

        // Special case: it there's no payment, there must've
        // been some unknown payment workflow error, so we try to
        // redirect to the checkout again
        if ($scope.payment == null) {
          Alerts.error("Fehler bei der Bezahlung");
          location.assign('/cashier');
          return;
        }

        if (params.order_token) $scope.payment.order_token = params.order_token;

        if ($scope.payment.currency != null) {
          $scope.currency = $scope.payment.currency;
        }

        resolve($scope.payment);
      }, e => {
        Alerts.error(errorMessage(e))
      })
    });
  };

  $scope.onTryAgainClick = function() {
    // console.log("DatatransHelper.initiatePayment({ amount: 1.0, identifier: '123' });");
    var payment = {
      amount: parseFloat($scope.payment.amount),
      currency: $scope.payment.currency,
      identifier: $scope.payment.order.id,
      order_id: $scope.payment.order.id,
      order_token: $scope.orderToken,
    };

    DatatransHelper.initiatePayment(payment, {
      mode: 'lightbox',
      paymentmethod: 'VIS,ECA,AMX,PAP,PFC,PEF',
      success_url: `${window.settingsSiteUrl}/orders/${$scope.payment.order.number}/thankyou?order_token=${$scope.orderToken}`,
      error_url: `${window.settingsSiteUrl}/payments/${$scope.payment.order.number}/order_status?order_token=${$scope.orderToken}`,
      cancel_url: `${window.settingsSiteUrl}/payments/${$scope.payment.order.number}/order_status?order_token=${$scope.orderToken}`,
      onClosed: onDatatransPopupClosed
    });
  };

  $scope.onCheckoutClick = function() {
    // console.log("DatatransHelper.initiatePayment({ amount: 1.0, identifier: '123' });");
    // DatatransHelper.initiatePayment({ amount: 1.0, identifier: '123' }, { mode: 'lightbox', paymentmethod: 'VIS,ECA' });
    location.assign('/cashier');
  };

  $scope.$on('$destroy', destructor);
  constructor();
}]);
