import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app')
  .directive('farmyCatalogFilterCheckbox', function() {
    return {
      controllerAs: 'checkboxFilterCtrl',
      controller: ['$rootScope', '$scope', '$http', '$q', '$timeout', 'Alerts', 'blockUI', '$attrs', '$element', 'TaxonHelper', 'CatalogServiceHelper', 'Hubs', function ($rootScope, $scope, $http, $q, $timeout, Alerts, blockUI, $attrs, $element, TaxonHelper, CatalogServiceHelper, Hubs) {
        let DEFAULT_MAX_OPTIONS = 384;
        let ctrl = this;
        var globalName = null;

        function constructor() {
          if (ctrl.filter) {
            globalName = 'catalogFilter_' + ctrl.filter.search_param;
            window[globalName] = ctrl;
          }

          $scope.$watch("checkboxFilterCtrl.filter", (newValue) => {
            if (newValue) {
              globalName = 'catalogFilter_' + newValue.search_param;
              window[globalName] = ctrl;

              if (window.CatalogFilterCtrl && window.CatalogFilterCtrl.urlFilterParameters && window.CatalogFilterCtrl.urlFilterParameters[newValue.search_param]) {
                angular.extend(ctrl.selections || {}, window.CatalogFilterCtrl.urlFilterParameters[newValue.search_param]);
                ctrl.nothingSelected = _.isEmpty(ctrl.selections);
              }
            }
          });

          $scope.$watch("checkboxFilterCtrl.selections", (newValue, oldValue) => {
            // console.log(newValue, oldValue, newValue == oldValue);
            if (newValue != null && newValue != oldValue) {
              // TODO: Refactor this nasty way of calling back
              if (window.CatalogViewCtrl) window.CatalogViewCtrl.update({ resetPage: true });
              if (window.CatalogSearchViewCtrl) window.CatalogSearchViewCtrl.update({ resetPage: true });
              if (window.SupplierPageViewCtrl) window.SupplierPageViewCtrl.update({ resetPage: true });

              // Clean up selections from false keys
              for(var k in newValue) {
                if (newValue[k] === false) {
                  delete newValue[k];
                }
              }

              ctrl.nothingSelected = _.isEmpty(newValue);

              CatalogServiceHelper.onFilterSelectionChange();

              if (ctrl.change) {
                ctrl.change(ctrl);
              }
            }

          }, true);

          // Initialize collapse stated, if specified in the options
          $scope.$watch("checkboxFilterCtrl.collapseTo", (newValue) => {
            if (newValue != null) {
              ctrl.collapseTo = parseInt(newValue);
              ctrl.limitExpression = parseInt(newValue);
              ctrl.setCollapsed(true);
            } else {
              ctrl.setCollapsed(false);
            }
          });

          $timeout(() => { ctrl.isReady = true }, 10);
        }

        function destructor() {
          if (globalName) {
            window[globalName] = null;
          }
        }

        // Public members

        ctrl.setCollapsed = function(collapsed) {
          ctrl.collapsed = collapsed;

          if (ctrl.collapsed) {
            ctrl.limitExpression = parseInt(ctrl.limitExpression);
            ctrl.orderingExpression = '-result_count';
          } else {
            ctrl.limitExpression = DEFAULT_MAX_OPTIONS;
            ctrl.orderingExpression = 'name';
          }

          if (ctrl.filter) {
            ctrl.totalEligibleOptions = _.filter(ctrl.filter.values, v => v.result_count && v.result_count > 0).length;
          }
        };

        ctrl.clearKeywordsFilter = function() {
          ctrl.optionKeywordsFilter = '';
          ctrl.onKeywordsFilterChange();
        };

        ctrl.onKeywordsFilterChange = function() {
          // TODO
          if (ctrl.optionKeywordsFilter == null || ctrl.optionKeywordsFilter == "" || ctrl.optionKeywordsFilter.trim().length == 0) {
            _.each(ctrl.filter.values, v => { v._hidden = false })
          } else {
            _.each(ctrl.filter.values, v => { v._hidden = (v.name.toLowerCase().indexOf(ctrl.optionKeywordsFilter.toLowerCase()) == -1) })
          }
        };

        ctrl.toggleMinimize = function() {
          ctrl.minimized = !ctrl.minimized;
        };

        // Initialize

        /**
         * Default value for selections, if not set
         * @type {{}}
         */
        ctrl.selections = $scope.selections || {};
        ctrl.nothingSelected = _.isEmpty(ctrl.selections);
        ctrl.minimized = $scope.minimized || window.isMobile;
        ctrl.minimizing = $scope.minimizing || window.isMobile;

        ctrl.limitExpression = DEFAULT_MAX_OPTIONS; // show 384 max options
        ctrl.orderingExpression = ['name'];
        ctrl.optionKeywordsFilter = "";
        ctrl.collapsed = false;
        ctrl.isReady = false;

        ctrl.CatalogServiceHelper = CatalogServiceHelper;

        $scope.$watch('ctrl.minimized', (newValue) => {
          if (newValue == 'false') ctrl.minimized = false;
          else if (newValue == 'true') ctrl.minimized = true;
        });

        $scope.$watch('ctrl.minimizing', (newValue) => {
          if (newValue == 'false') ctrl.minimizing = false;
          else if (newValue == 'true') ctrl.minimizing = true;
        });

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      bindToController: true,
      scope: {
        filter: '=',
        selections: '=',
        searchEnabled: '@', // will display a search box to filter options through text
        collapseTo: '@', // allows to collaps the filter down to N most-frequent options and hide the rest under 'show all' and optional search bar
        minimized: '=',
        minimizing: '='
      },
      link: function($scope, $element, attributes) {

      },
      templateUrl: function() { return `/ng/templates/products/filter_checkbox.html?locale=${window.I18n.locale}` }
    }
  });


