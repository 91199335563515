import * as _ from 'underscore';
import * as sprintf from 'sprintf';

/**
 * ProductNavigation helper service wraps helpers and utility methods, for
 * navigating across the product catalog in a global way. The most common use-case
 * is the invoking of a 'product overview' popup (which is like the product page, but in a popup)
 */
angular.module('app').service('ProductNavigation', ['$rootScope', '$q', '$http', '$route', '$interval', '$timeout', '$location', '$uibModal', 'UiStateManager', 'UiStateService', 'UserService', function ($rootScope, $q, $http, $route, $interval, $timeout, $location, $uibModal, UiStateManager, UiStateService, UserService) {
  var scope = this;

  scope.productPopupInstance = null;

  scope.isPopupOpen = false;

  window.ProductNavigation = scope;

  var lastProductOpened = null; // helps prevents dupe clicks
  var previousTitle, previousCanonical, previousAlternate, previousAmp;

  let popupLoadingState = {};

  var restoreMetaData = function () {
    window.document.title = previousTitle;
    $("link[rel='canonical']").attr('href', previousCanonical);
    $("link[rel='alternate']").attr('href', previousAlternate); // unfortunately, it's best to clear alternative links,
  };

  /**
   * Opens a popup with the product described by the productIdentifier
   * Provide "preProduct" (must comply with the product_in_catalog json structure) via options to show preview product info while loading the full data.
   * @param productIdentifier number, string or a Product-like object (with id and/or name keys)
   */
  scope.openProductPopup = function (productIdentifier, options) {
    if (!options) {
      options = {}
    }
    ;

    var locale_query = '';
    if (I18n.locale != 'de') {
      locale_query = '?locale=' + I18n.locale
    }

    let storefront_tag = window.currentStorefront ? `_${window.currentStorefront}` : '';

    if (lastProductOpened == productIdentifier)
      return;

    // help prevent incidential double-clicks
    if (scope.productPopupInstance && lastProductOpened != productIdentifier) {
      scope.productPopupInstance.dismiss();
      scope.productPopupInstance = null;
    }

    lastProductOpened = productIdentifier;

    // Reset loading state
    popupLoadingState.dismissed = false;

    var lastRoute = {
      route: $route.current,
      path: $location.path(),
      search: $location.search(),
      hash: $location.hash()
    };

    try {
      previousTitle = document.title;
      previousCanonical = $("link[rel='canonical']").attr('href');
      ;
      previousAlternate = $("link[rel='alternate']").attr('href');
    } catch (e) {
      console.error(e)
    }

    // Helps track the loading state of the popup, to
    // prevent route setting if the popup is dismissed before complete load

    try {
      if (window.isMobile)
        UiStateService.setBodyScroll(false);
    } catch (e) {
      console.error(e)
    }

    scope.productPopupInstance = $uibModal.open({
      animation: window.isMobile ? false : $rootScope.animationsEnabled,
      templateUrl: `/ng/templates/products/show_in_popup${storefront_tag}.html` + locale_query,
      controller: 'ProductModalViewCtrl',
      windowClass: 'modal-rounded modal-product-view',
      resolve: {
        productIdentifier: function () {
          if (productIdentifier.id != null)
            return productIdentifier.id;
          else
            return productIdentifier;
        },

        productName: function () {
          if (productIdentifier.name != null)
            return productIdentifier.name;
          else
            return null;
        },

        nextProductIds: function () {
          return [];
        },

        preProduct: function () {
          if (options == null) return null;
          return options.preProduct;
        },

        parentAnalyticsListName: function () {
          if (!_.isEmpty(options.parentAnalyticsListName)) {
            return "Popup (" + options.parentAnalyticsListName + ")";
          } else {
            return null;
          }
        },

        lastRoute: lastRoute,
        loadingState: popupLoadingState,
      }
    });

    scope.productPopupInstance.result.then(function (opts) {
      lastProductOpened = null;
      popupLoadingState.dismissed = true;

      if (window.isMobile)
        UiStateService.setBodyScroll(true);

      if (opts && opts.nextLocation) {
        if (opts.loadPath) $location.path(opts.nextLocation);
        else {
          if (lastRoute) {
            Tracking.sendPageviewExt(lastRoute.path);
            Tracking.trackPageview(lastRoute.path, UserService);
          }

          restoreMetaData();

          $location.changeUrl(opts.nextLocation);
        }
      } else {
        if (lastRoute) {
          Tracking.sendPageviewExt(lastRoute.path);
          Tracking.trackPageview(lastRoute.path, UserService);
        }

        restoreMetaData();
      }
    }, function () {
      // Dismissed
      lastProductOpened = null;
      popupLoadingState.dismissed = true;

      if (window.isMobile)
        UiStateService.setBodyScroll(true);

      if (lastRoute) {
        Tracking.sendPageviewExt(lastRoute.path);
        Tracking.trackPageview(lastRoute.path, UserService);
        restoreMetaData();
        $location.changeUrl(lastRoute.path);
      }
    }).finally(function () {
    });

    if (scope.productPopupInstance) {
      scope.isPopupOpen = true;
      return scope.productPopupInstance.opened;
    } else {
      return null;
    }
  };

  scope.closePopup = function () {
    if (scope.isPopupOpen) scope.productPopupInstance.close();
    scope.isPopupOpen = false;
  };

  /**
   Opens the product detail page and closes the mobile side cart if on mobile. The params should be the seo_url from the product and the toggleCartSidebar function (if needed)
  **/
  scope.openProductDetailPage = function(productLink, callback) {
    if (productLink) $location.path(productLink);
    if (callback) callback();
  };
}]);
