import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app')
  .directive('farmyPartnerReturnToBtn', function() {
    return {
      controller: ['$rootScope', '$scope', '$timeout', '$element', '$sessionStorage', '$location', function ($rootScope, $scope, $timeout, $element, $sessionStorage, $location) {
        window.farmyPartnerReturnToBtn = $scope;

        function constructor() {
          // Set initial params from url:
          let partnerUrl = $location.search().partner_url;
          let partnerToken = $location.search().partner_token;
          let existingUrl = sessionStorage.getItem('partnerReturnToUrl');

          if (partnerUrl) {
            $scope.partnerReturnToUrl = partnerUrl;
            // partnerToken/returnToken specs still unclear.
            // if (partnerToken)
            //   $scope.partnerReturnToUrl += `?token=${partnerToken}`

            sessionStorage.setItem('partnerReturnToUrl', $scope.partnerReturnToUrl)
            setCheckoutListener()
          } else if (existingUrl && existingUrl.length > 0) {
            $scope.partnerReturnToUrl = existingUrl
          }
        }

        function destructor() {
          window.farmyPartnerReturnToBtn = null;
        }

        // Public members
        $scope.onReturnBtnClicked = function() {
          sessionStorage.removeItem('partnerReturnToUrl')
          location.replace($scope.partnerReturnToUrl);
          $scope.partnerReturnToUrl = null;
        }

        // Listeners
        function setCheckoutListener() {
          let checkoutCompleteListener = $rootScope.$on('checkout:complete', (event) => {
            let separator = $scope.partnerReturnToUrl.includes('?') ? '&' : '?'
            $scope.partnerReturnToUrl += `${separator}order_complete=t`;
            checkoutCompleteListener();

            $timeout($scope.onReturnBtnClicked, 8000)
          })
        }

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      link: function($scope, $element, attributes) {

      },
      templateUrl: function() { return `/ng/templates/partners/partner_return_to_btn.html?locale=${window.I18n.locale}` }
    }
  });


