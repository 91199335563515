'use strict';

angular.module('app').directive('parallaxBg', ['$window', '$interval', '$timeout',
  function ($window, $interval, $timeout) {
    return {
      restrict: 'A',
      link: function(scope, $element, $attrs) {
        var parallaxSpeed = $attrs.parallaxSpeed ? parseFloat($attrs.parallaxSpeed) : null;

        function updatePosition() {
          var scrollTop = $(window).scrollTop();
          var totalHeight = window.innerHeight || (document.body && document.body.offsetHeight);

          var offset = (scrollTop * (parallaxSpeed || 1.313) / totalHeight) * 100;

          // horizontal positioning
          // $element.css('background-size', sprintf("105%% 105%%"));
          $element.css('background-position', sprintf("48.5%% %fpx", offset))
        }

        function initialize() {
          angular.element($window).bind("scroll", updatePosition);
          angular.element($window).bind("touchmove", updatePosition);
          updatePosition();
        }

        initialize();
      }
    }
  }
]);
