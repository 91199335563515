import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app')
  .directive('farmyFormFieldFeedback', function() {
    return {
      controller: ['$scope', '$sce', '$q', '$rootScope', '$timeout', 'Alerts', function ($scope, $sce, $q, $rootScope, $timeout, Alerts) {
        function constructor() {

        }

        function destructor() {
        }

        // Public members

        // Initialize

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      scope: {
        model: '=',
        field: '=',
      },
      link: function($scope, $element, attributes) {

      },
      template: function() {
        return `<div class="feedback" ng-bind-html='model.errors[field]' ng-show='model.errors[field]'></div>`;
      }
    }
  });


