const cartParser = (mode) => {
  const parsers = {
    full: (cart, options = {isLocalUpdate: true}) => {
      const {addTopTaxon, sortItemsById, sortItemsByCategory, updateSummary} = cartParserTools();
      if (!cart?.id) return;

      const shouldModify = options.isLocalUpdate;
      let updatedAttrs = {};

      let lineItems = addTopTaxon(cart.line_items) || [];
      lineItems = sortItemsById(lineItems);
      lineItems = sortItemsByCategory(lineItems);

      updatedAttrs = {line_items: lineItems};

      if (shouldModify) {
        const productIds = lineItems?.map(item => item.product_id);
        const cartSummary = updateSummary(cart);
        const updatedAt = Date();

        updatedAttrs = {
          ...updatedAttrs,
          cart_summary: cartSummary,
          weight_control_surcharge_total: cartSummary.weight_control_surcharge_total,
          item_total: cartSummary.item_total,
          productIds,
          updatedAt
        };
      }

      return {
        ...cart,
        ...updatedAttrs
      };
    },
    cartUpdateItems: (lineItems) => {
      return lineItems.map(lineItem => ({
        product_id: lineItem.product_id,
        variant_id: lineItem.variant.id,
        price: lineItem.variant.price,
        quantity_in_units: lineItem.variant.quantity_in_units,
        referrer: lineItem.referrer
      }));
    }
  };

  return ((mode) => parsers[mode || "full"])(mode);
};

export default cartParser;

export const cartParserTools = () => {
  const categoriesObject = (lineItems) => lineItems
    .map(product => product.productData?.ax_primary_taxon?.name)
    .filter(item => item)
    .sort()
    .reduce((object, key) => {
      object[key] = [];
      return object;
    }, {});

  const groupByCategory = (lineItems) => lineItems
    .sort((a, b) => a.name - b.name)
    .reduce((result, lineItem) => {
      const {productData} = lineItem;
      productData.total = lineItem.total;

      if (lineItem?.ax_primary_taxon?.name in result) {
        result[lineItem?.ax_primary_taxon?.name].push(productData);
      } else {
        result[lineItem?.ax_primary_taxon?.name] = [productData];
      }
      return result;
    }, categoriesObject(lineItems));

  const hashForGroupedItems = (groupedByCategory) => JSON
    .stringify(Object.keys(groupedByCategory)
      .map(cat => [cat, groupedByCategory[cat].map(i => i?.variant_id || i?.total)])
    );

  const sortItemsByName = (lineItems) => {
    if (!lineItems?.length) return lineItems;

    const sortedItems = lineItems.sort((a, b) => {
      const aChar = a?.productData?.name || "";
      const bChar = b?.productData?.name || "";

      if (aChar < bChar) { return -1 } else if (aChar > bChar) { return 1 } else { return 0 }
    });

    return sortedItems;
  };

  const sortItemsById = (lineItems) => {
    if (!lineItems?.length) return lineItems;

    const sortedItems = lineItems.sort((a, b) => {
      const aChar = a?.productData?.id || 0;
      const bChar = b?.productData?.id || 0;

      if (aChar < bChar) { return -1 } else if (aChar > bChar) { return 1 } else { return 0 }
    });

    return sortedItems;
  };

  const sortItemsByCategory = (lineItems) => {
    if (!lineItems?.length) return lineItems;

    const sortedItems = lineItems.sort((a, b) => {
      const aChar = a?.ax_primary_taxon?.name || "";
      const bChar = b?.ax_primary_taxon?.name || "";

      if (aChar < bChar) { return -1 } else if (aChar > bChar) { return 1 } else { return 0 }
    });

    return sortedItems;
  };

  const addTopTaxon = (lineItems) => {
    if (!lineItems?.length) return;

    return lineItems.map(item => {
      if (item.ax_primary_taxon?.id) return item;

      const primaryTaxon = item.productData?.categories?.find(cat => cat.depth === 1);
      return {...item, ax_primary_taxon: primaryTaxon};
    });
  };

  const adjustmentsTotal = (cart) => {
    const adjustments = cart?.cart_summary?.adjustments || [];
    return adjustments.map(ad => ad?.amount || 0).reduce((sum, current) => sum + current, 0);
  };

  const updateSummary = (cart) => {
    const result = {};

    result.item_total = cart
      .line_items
      .map(item => item.total)
      .reduce((sum, current) => sum + current, 0);

    // update item_total adjustment.
    const itemTotalAdj = cart?.cart_summary?.adjustments.find(adj => adj?.code === ":item_total");

    if (itemTotalAdj) itemTotalAdj.amount = result.item_total;
    else cart.cart_summary.push({label: "Warenwert", amount: result.item_total, additional_info: null, code: ":item_total"});

    const wcTotal = cart
      .line_items
      .map(item => item?.weight_control_surcharge || 0)
      .reduce((sum, current) => sum + current, 0);

    result.weight_control_surcharge_total = wcTotal;
    result.item_total_incl_wc_surcharge = adjustmentsTotal(cart);

    return {...cart.cart_summary, ...result};
  };

  return {
    sortItemsByCategory,
    sortItemsById,
    sortItemsByName,
    addTopTaxon,
    adjustmentsTotal,
    updateSummary,
    groupByCategory,
    hashForGroupedItems
  };
};

export const cartComparator = () => {
  const {sortItemsById} = cartParserTools();

  const didCartChange = (oldCart, newCart) =>
    didBasicCartChange(oldCart, newCart) || didLineItemsChange(oldCart, newCart);

  const hashFromLineItems = (lineItems) =>
    sortItemsById(lineItems).map(item => item.variant_id).join("");

  const hashFromBasicCart = (cart) => {
    cart = cart || {};
    const duplicate = {...cart};
    delete duplicate.line_items;
    delete duplicate.adjustments;
    delete duplicate.cart_summary;

    return JSON.stringify(duplicate);
  };
  const didBasicCartChange =
    (oldCart, newCart) => hashFromBasicCart(oldCart) !== hashFromBasicCart(newCart);

  const didLineItemsChange =
    (oldCart, newCart) => hashFromLineItems(oldCart?.line_items || []) !== hashFromLineItems(newCart?.line_items || []);

  return {didCartChange};
};
