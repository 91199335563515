'use strict';

angular.module('app').controller('WeeklyAddressCtrl', ['$scope', '$http', '$element', '$timeout', 'Hubs', function ($scope, $http, $element, $timeout, Hubs) {
  window.WeeklyAddressCtrl = $scope;

  $scope.placeholderValue = '';
  $scope.showDeliveryTimeDropdown = false;

  // Public methods

  $scope.loadWeekly = function() {
    $http.get(sprintf('/api/frontend/weeklies/%s.json', $scope.weeklyId)).then(function(response) {
      $scope.weekly = response.data;

      if($scope.weekly.ship_address_id) {
        $http.get(sprintf('/api/frontend/addresses/%s.json', $scope.weekly.ship_address_id)).then(function(response) {
          $scope.shipAddress = response.data;
          if ($scope.shipAddress.zipcode) $scope.onZipcodeFieldChange();
        })
      }
    })
  };

  $scope.loadTimePreferenceOptions = function(weekday, zipcode) {
    var queryArray = [];
    if(weekday) queryArray.push(sprintf('weekday=%s', weekday));
    if(zipcode) queryArray.push(sprintf('zipcode=%s', zipcode));
    var query = queryArray.join('&');

    $http.get(sprintf('/api/frontend/weeklies/%s/time_preference_options/?%s', $scope.weeklyId, query)).then(function(response) {
      $scope.timePreferenceOptions = response.data;
      $scope.checkTimePreferenceValid();
      $scope.showDeliveryTimeDropdown = $scope.timePreferenceOptions && $scope.timePreferenceOptions.length > 1;
      if (!$scope.showDeliveryTimeDropdown) $scope.weekly.delivery_time_preference = '';
    })
  };

  $scope.checkTimePreferenceValid = function() {
    $scope.timePreferenceValid = $scope.weekly.delivery_time_preference == '' ? true : [].concat.apply([], $scope.timePreferenceOptions).indexOf($scope.weekly.delivery_time_preference) > -1;
    $scope.setPlaceholderValue();

    // Remove the AngularJS auto-created option, if any.
    $timeout(function() {
      $element.find('#weekly_delivery_time_preference option[value*="?"]').remove()
    }, 10);
  };

  $scope.setPlaceholderValue = function() {
    $scope.placeholderValue = ($scope.weekly.delivery_time_preference && !$scope.timePreferenceValid) ? $scope.weekly.delivery_time_preference : ''
  };

  $scope.onZipcodeFieldChange = function() {
    $scope.showDeliveryTimeDropdown = false;
    if ($scope.shipAddress.zipcode.length == 4) {
      Hubs.fetchHubChangeStatus($scope.shipAddress.zipcode).then(function(response){
        var newSelectedHubId = response.hubFromZipcode.id;

        $scope.loadTimePreferenceOptions(null, $scope.shipAddress.zipcode);

        if(newSelectedHubId != $scope.selectedHubId) {
          $scope.selectedHubId = newSelectedHubId;
        }
      });
    }
  };

  var weeklyIdWatcher = $scope.$watch('weeklyId', function(weeklyId) {
    if(weeklyId) {
      $scope.loadWeekly();
      weeklyIdWatcher();
    }
  })
}]);
