import {getCallerLocation} from "./tracking-helper";
// Contentsquare methods
const ahoy = window.ahoy;
const trackedPageEvents = ["addtocart", "removefromcart", "product_removed_from_cart", "empty_cart", "begin_checkout"];

const mainTag = (type = null, options) => {
  if (type === 'order') type = 'thankyou-page';
  if (options?.checkout && type === 'thankyou-page') {
    ahoy.track(
      'cs-maintag',
      {
        step: '1: start maintag',
        order_id: options.checkout?.id,
        type,
        order_number: options.checkout?.number
      }
    );
  }
  if (!window.browserIsBot && !window.prerenderAgent) {
    if (options?.checkout && type === 'thankyou-page') {
      ahoy.track(
        'cs-maintag',
        {
          step: '2: !browserIsBot and !prerenderAgent',
          order_id: options.checkout?.id,
          type,
          order_number: options.checkout?.number
        }
      );
    }
    window._uxa = window._uxa || [];
    try {
      if (typeof dataLayer !== 'undefined') {
        for (let i = 0; i < dataLayer.length; i++) {
          customVariables(type, options);
        }
      }
    } catch (e) {
    }
    if (typeof CS_CONF === 'undefined') {
      if (options?.checkout && type === 'thankyou-page') {
        ahoy.track(
          'cs-maintag',
          {
            step: '3: CS_CONF undefined',
            order_id: options.checkout?.id,
            pathTracked: window.location.pathname + window.location.hash.replace('#', '?__'),
            type,
            order_number: options.checkout?.number
          }
        );
      }
      window._uxa.push(['setPath', window.location.pathname + window.location.hash.replace('#', '?__')]);
      const mt = document.createElement('script');
      mt.type = 'text/javascript';
      mt.async = true;
      mt.src = '//t.contentsquare.net/uxa/3f6d525fcfedc.js';
      document.getElementsByTagName('head')[0].appendChild(mt);
    } else {
      if (options?.checkout && type === 'thankyou-page') {
        ahoy.track(
          'cs-maintag',
          {
            step: '4: CS_CONF defined',
            order_id: options.checkout?.id,
            pathTracked: window.location.pathname + window.location.hash.replace('#', '?__'),
            type,
            order_number: options.checkout?.number
          }
        );
      }
      window._uxa.push(['trackPageview', window.location.pathname + window.location.hash.replace('#', '?__')]);
    }
  }
};

// ContentSquare custom page event
const trackPageEvent = (eventName, options) => {
  if (!trackedPageEvents.includes(eventName)) return;
  if (["addtocart", "removefromcart"].includes(eventName)) {
    eventName = `${eventName}_${options?.callerLocation || getCallerLocation()}`;
  }

  window._uxa = window._uxa || [];
  window._uxa.push(['trackPageEvent', eventName]);
};
// ContentSquare custom variables
const customVariables = (type, options) => {
  if (type) window._uxa.push(['setCustomVariable', 1, 'pagetype', type]);
  if (options?.category) window._uxa.push(['setCustomVariable', 2, 'category', options.category, 'nextPageOnly']);
};

export {mainTag, trackPageEvent, customVariables};
