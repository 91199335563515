'use strict';

angular.module('app').controller('FirstTwoOrdersViewCtrl', ['$scope', '$rootScope', '$window','$uibModal', '$templateCache','$http', '$timeout', '$localStorage', 'Hubs',
  function($scope, $rootScope, $window, $uibModal, $templateCache, $http, $timeout, $localStorage, Hubs) {
    window.FirstTwoOrdersViewCtrl = $scope;
    if ($localStorage.firstTwoOrdersModalNewShown) {
      return true
    } else {
      $scope.nextDeliveryDates = {};

      $http.get(sprintf('/api/farmy/delivery_slots/next_delivery_dates_per_zone.json?limit=1&locale=%s', I18n.locale)).then(function(response) {
        $scope.nextDeliveryDates = response.data;
      });

      $scope.firstTwoOrdersTemplateUrl = '/ng/templates/campaigns/first_two_orders_with_delivery_modal.html?locale=' +I18n.locale;

      $scope.openAboutModal = function() {
        var firstTwoModalInstance = $uibModal.open(
          {
            animation: true,
            size: 'lg',
            template: $scope.firstTwoOrdersTemplate,
            backdrop: true,
            windowClass: 'first-two-orders-modal',
            controller: 'TwoFirstOrdersModalCtrl',
            scope: $scope
          }
        );
      };

      $http.get($scope.firstTwoOrdersTemplateUrl).then(function(response) {
        $scope.firstTwoOrdersTemplate = response.data;

        $timeout(function() {
          if(!$localStorage.firstTwoOrdersModalNewShown) {
            $scope.openAboutModal();
            $localStorage.firstTwoOrdersModalNewShown = true;
          }
        }, 1000)
      });
    }
  }
]);
