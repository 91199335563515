'use strict';

angular.module('app')
  .directive('lazyloadFadeInSection', ['$compile', '$animate', '$window', '$timeout', function($compile, $animate, $window, $timeout) {
    return {
      multiElement: true,
      transclude: 'element',
      priority: 600,
      terminal: true,
      restrict: 'A',
      $$tlb: true,
      link: function($scope, $element, $attr, ctrl, $transclude) {
        var block, childScope, previousElements;
        var id = $attr.id;

        var shown = false;

        var setVisible = function(value) {
          if (value) {
            // console.log("Showing", id);

            if (!childScope) {
              $transclude(function(clone, newScope) {
                childScope = newScope;
                clone[clone.length++] = document.createComment(' end ngIf: ' + $attr.lazyloadFadeInSection + ' ');
                // Note: We only need the first/last node of the cloned nodes.
                // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                // by a directive with templateUrl when its template arrives.
                block = {
                  clone: clone
                };
                $animate.enter(clone, $element.parent(), $element);
              });
            }
          } else {
            if (previousElements) {
              previousElements.remove();
              previousElements = null;
            }
            if (childScope) {
              childScope.$destroy();
              childScope = null;
            }
            if (block) {
              previousElements = getBlockNodes(block.clone);
              $animate.leave(previousElements).done(function(response) {
                if (response !== false) previousElements = null;
              });
              block = null;
            }
          }
        };

        var onScroll = function(e) {
          // Unsubscribe from the scroll event, once shown
          var top = 0;

          // $element itself points to a comment element (because of ngTransclude)
          try {
            top = $element.next().offset().top;
          } catch(e) { }

          if (top >= $(window).scrollTop() && top < $(window).height() + $(window).scrollTop()) {
            $timeout(function() {
              setVisible(true);
            }, 100);
            shown = true;
            $window.removeEventListener('scroll', onScroll);
          }

        }

        $window.addEventListener('scroll', onScroll);

        // Load anyway after window loads
        $window.addEventListener('load', function() {
          $timeout(function() {
            setVisible(true);
          }, 100);
          shown = true;
          $window.removeEventListener('scroll', onScroll);
        });

        onScroll();
      }
    };
  }]);