const AngularIntegration = {
  $location: null,

  $broadcast: function(eventName, parameter) {
    return new Promise((resolve, reject) => {
      if (window.$ngRootScope == undefined) {
        // Rebroadcast until angular is initialized
        setTimeout(function() {
          $ngRootScope.$broadcast(eventName, parameter);
          resolve();
        }, 100);
      } else {
        $ngRootScope.$broadcast(eventName, parameter);

        try {
          setTimeout(function() {
            $ngRootScope.$apply();
          }, 0);
        } catch(e) {
          // Do nothing
        }

        resolve();
      }
    });

  },

  $on: function(eventName, callback) {
    return new Promise((resolve, reject) => {
      if (window.$ngRootScope == undefined) {
        // Rebroadcast until angular is initialized
        setTimeout(function() {
          let unsubscribe = $ngRootScope.$on(eventName, callback);
          resolve(unsubscribe)
        }, 100);
      } else {
        let unsubscribe = $ngRootScope.$on(eventName, callback);
        setTimeout(function() {
          resolve(unsubscribe)
        }, 0)
      }
    })
  },

  $: function(readyCallback) {
    var scope = this;

    if (window.$ngRootScope == undefined) {
      // Rebroadcast until angular is initialized
      setTimeout(function() {
        scope.$(readyCallback);
      }, 30);
    } else {
      readyCallback();
    }
  },

  $store: function(key, value) {
    window.localStorage[key] = value;
  },

  $fetch: function(key) {
    return window.localStorage[key];
  },
};

window.$ti = (function(key, opts) {
  if (opts) {
    return window.$translate.instant(key, opts)
  } else return window.$translate.instant(key, opts);
});

window.AngularIntegration = AngularIntegration;
export default AngularIntegration;
