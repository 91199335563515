angular.module('app').directive('bouncyButton', ['$timeout', function ($timeout) {
  return {
    restrict: 'A',
    scope: {
    },
    link: function (scope, element, attrs) {
      element.bind('click', function() {
        onButtonClick()
      });

      var onButtonClick = function() {
        var targetBtnElement = element.find('img');

        targetBtnElement.css({'width': '20px', 'margin-left': '2px', 'margin-right': '3px'});

        $timeout(function() {
          targetBtnElement.css({'width': '27', 'margin-left': '-1px', 'margin-right': '-1px'});
          $timeout(function() {
            targetBtnElement.css({'width': '', 'margin-left': '', 'margin-right': ''});
          }, 100);
        }, 100)
      }


    }
  };
}]);
