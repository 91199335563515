angular.module('app').controller('UserNotificationsPopupCtrl', ['$q', '$scope', '$timeout', '$uibModalInstance',
  function ($q, $scope, $timeout, $uibModalInstance) {
    window.UserNotificationsPopupCtrl = $scope;

    $scope.notificationPanelListener = {
      onNotificationOpened: null,
      onNotificationClosed: null
    };

    $scope.dismiss = function() {
      $uibModalInstance.dismiss();
    };

    setTimeout(() => {
      window.notificationsPopupOpening = false;
    }, 100);

    $scope.$on('$destroy', () => {
      window.UserNotificationsPopupCtrl = null;
    })
  }
]);
