import * as _ from 'underscore';
import * as sprintf from 'sprintf';

angular.module('app').controller('NavMobileSidebarTaxonsCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$timeout', '$http', '$element', '$localStorage', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $timeout, $http, $element, $localStorage, Alerts) {
    window.NavMobileSidebarTaxonsCtrl = $scope;

    $scope.toggleStates = {};
    $scope.activeStates = {};
    $scope.subcategories = {};
    $scope.$location = $location;

    $scope.lastOpenedTaxon = null;
    $scope.lastOpenedTaxonId = null;

    $scope.onCategoryClick = function(taxonPermalink, taxonId, level, $event) {
      if ($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }

      if (window.CatalogViewCtrl) { // for category browse pages
        // Reset and reassign active taxon state
        $scope.activeStates = {};
        $scope.activeStates[taxonId] = true;

        if (window.CatalogViewCtrl.currentTaxonId != taxonId) {
          window.CatalogViewCtrl.loadView(taxonPermalink, $event)

          // Expand first level taxons once upon click
          if (level == 0 && !$scope.toggleStates[taxonId])
            $scope.toggleStates[taxonId] = true;

          try {
            $timeout(() => { FarmyMobileUi.toggleSidebar() }, 1);
          } catch(e) { console.warn(e) }
        } else { // third level
          FarmyMobileUi.toggleSidebar();
        }
      } else { // for non category browse page
        var url = taxonPermalink.lastIndexOf("/", 0) == 0 ? taxonPermalink : ('/' + taxonPermalink);
        $location.path(url);
      }

      $scope.lastOpenedTaxon = taxonPermalink;
      $scope.lastOpenedTaxonId = taxonId;
    };

    $scope.toggleTaxon = function(taxonId, $event) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }

      if ($scope.toggleStates[taxonId] == null) {
        $scope.toggleStates[taxonId] = true;

        // Check if it was a direct click
        if ($event) Tracking.sendCatalogNavigationEvent('menuItemUnfolded', taxonId);
      } else {
        $scope.toggleStates[taxonId] = null;

        // Check if it was a direct click
        if ($event) Tracking.sendCatalogNavigationEvent('menuItemFolded', taxonId);
      }
    };

    // $scope.toggleLoadableTaxon = function(taxonId, $event) {
    //   if ($event) {
    //     $event.preventDefault();
    //     $event.stopPropagation();
    //   }
    //
    //   if ($scope.toggleStates[taxonId] == null) {
    //     if ($scope.subcategories[taxonId]) {
    //       $scope.toggleStates[taxonId] = true;
    //     } else {
    //       $scope.loadSubcategory(taxonId).then(function() {
    //         $scope.toggleStates[taxonId] = true;
    //       });
    //     }
    //   } else {
    //     $scope.toggleStates[taxonId] = null;
    //   }
    // };

    // $scope.loadSubcategory = function(parentTaxonId) {
    //   return $http.get(sprintf('/api/frontend/taxons/flatten.json?q[parent_id_eq]=%d', parentTaxonId)).then(function(response) {
    //     $scope.subcategories[parentTaxonId] = response.data.taxons;
    //
    //     // Fix height of the reloaded subcategory
    //     $timeout(function() {
    //       var el = $($(sprintf('.sidebar-mobile a[data-taxon-id=%d]', parentTaxonId)).parent().children('ul')[0]);
    //
    //       // Restores native element height
    //       if (el) {
    //         updateSectionHeight(el);
    //       }
    //     }, 200);
    //   });
    // }

    /**
     * Sets exact pixel height for section elements that are rendered hidden
     * first time and thus have zero height
     * @param section
     */
    var updateSectionHeight = function(section) {
      if (!section)
        return;

      section.css('height', 'auto');
      section.css('display', 'block');
      section.css('height', section.height());
      section.css('display', null);
    };

    $scope.$on('$destroy', function() {
      window.NavMobileSidebarTaxonsCtrl = null;
    });

    // Preset 'active' state for current taxon, if any
    var currentPath = location.pathname;

    if ($location.url().length > 3) {
      currentPath = $location.url()
    }

    // Hack to make sliding work (need to set explicit height for all slidable elements)
    // _.each($element.find('ul.subcategory'), function(element) {
    //   element = $(element);
    //   updateSectionHeight(element);
    // });

    $timeout(function() {
      if ($(sprintf('.sidebar-mobile a[href="%s"]', currentPath)).length == 1) {
        var taxonId = parseInt($(sprintf('.sidebar-mobile a[href="%s"]', currentPath)).data('taxon-id'));
        $scope.activeStates[taxonId] = true;
      }
    }, 10);
  }
]);
