'use strict';

angular.module('app').controller('UserAccountCashCertificateActivatedModelCtrl', ['$scope', '$rootScope', '$uibModalInstance', 'amount',
  function ($scope, $rootScope, $uibModalInstance, amount) {
    console.log("UserAccountCashCertificateActivatedModelCtrl controller initialized with scope");

    $scope.amount = amount;

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    }
  }
]);
