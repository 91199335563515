import * as _ from 'underscore';

angular.module('app').controller('NavTaxonsDetailsCtrl', ['$scope', '$rootScope', '$location', '$window', '$timeout', '$interval', '$http', '$element', '$localStorage', 'Alerts',
  function ($scope, $rootScope, $location, $window, $timeout, $interval, $http, $element, $localStorage, Alerts) {
    $scope.visible = false;
    window.NavTaxonsDetailsCtrl = $scope;

    // Private members:
    var hideTimer = null;

    function updateLayout() {
      if ($('header .categories-nav').length == 0)
        return;

      $element.css({
        width: $('header .categories-nav').width()
      });

      $element.css({
        left: $('body').width() / 2 - $element.width() / 2,
        top: $('.anchor-for-nav-taxon-details').offset().top + 15
      });

      $timeout(function() {
        if ($scope.navbarLinkElement) {
          $element.find('.arrow-up').offset({
            opacity: 1,
            left: $scope.navbarLinkElement.offset().left + $scope.navbarLinkElement.width() / 2 - 5 /* round(9 / 2) = 5 */,
            top: $element.offset().top - 9 //$('.anchor-for-nav-taxon-details').offset().top - window.scrollY - 5
          });
        } else {
          // $element.find('.arrow-up').css('opacity', 0);
        }
      }, 1);

      $timeout(function() {
        fitLayout();
      }, 5);
    };

    function fitLayout() {
      if (document.body.scrollTop == null)
        return;

      // Calculate if we're out of bounds vertically, hide featured categories, if so...
      var padding = 0;

      if ($element.find('.featured-categories:visible').length == 0)
        padding = 81;

      var bottomBorderY = $('#categories-extended').height() + padding + $('#categories-extended').offset().top - document.body.scrollTop;
      var bottomScreenY = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

      if (bottomBorderY > bottomScreenY) {
        $element.find('.featured-categories').hide();
      } else {
        $element.find('.featured-categories').show();
      }
    }

    $scope.openCategory = function(taxonId, event) {
      if (window.NavTaxonsCtrl)
        window.NavTaxonsCtrl.mouseOverTaxonId = null;

      $location.url(taxonId);

      $scope.visible = false;
      $scope.taxonId = null;

      // if (event)
      //   event.preventDefault();

      $timeout(function() {
        $rootScope.$broadcast('navigation:categories:mouseleave');
      }, 80);
    };

    $scope.onMouseEnter = function() {
      if (hideTimer) $timeout.cancel(hideTimer);
      $rootScope.$on('navigation:categories:mouseenter');
    };

    $scope.onMouseLeave = function() {
      $rootScope.$broadcast('navigation:categories:mouseleave');
    };

    // Initialization
    $rootScope.$on('navigation:categories:mouseenter', function(e, taxonId, linkElement) {
      if (hideTimer) $timeout.cancel(hideTimer);
      $scope.visible = true;
      $scope.taxonId = taxonId;
      $scope.navbarLinkElement = $(`li.category-item[data-taxon-id=${taxonId}]`); // Used to position the 'arrow' tip of the menu
      // console.log('$scope.navbarLinkElement', $scope.navbarLinkElement);
      updateLayout();
    });

    $rootScope.$on('navigation:categories:mouseleave', function(e) {
      hideTimer = $timeout(function() {
        $scope.visible = false;
        $scope.taxonId = null;
      }, 200);
    });

    var onWindowScroll = function() {
      updateLayout();

      // Compensate for affix collapse animation
      $timeout(function() {
        if ($scope.visible)
          updateLayout();
      }, 500);
    }

    $scope.$on('$destroy', function() {
      window.NavTaxonsDetailsCtrl = null;
      $($window).off('scroll', onWindowScroll);
    });

    $scope.$on('turbolink:change', function() {
      $($window).off('scroll', onWindowScroll);
    });

    $($window).on('scroll', onWindowScroll);
  }
]);
