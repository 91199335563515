import * as _ from 'underscore';
import * as moment from 'moment';

'use strict';

angular.module('app').controller('CheckoutPaymentPostFinanceEfinanceDtCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', '$element', 'Alerts', 'blockUI', 'DatatransHelper',
                                                             function ($scope,   $sce,   $http,   $q,   $rootScope,   $timeout,   $element,   Alerts,   blockUI,   DatatransHelper) {
  window.CheckoutPaymentPostFinanceEfinanceDtCtrl = $scope;

  // Public properties

  $scope.checkoutCtrl = $scope.$parent.$parent.$parent.$parent;
  $scope.paymentCtrl = $scope.$parent.$parent;
  $scope.deferredSettlement = true;

  //
  // Private members
  //

  function constructor() {
    // $scope.initiatePayment();
  }

  function destructor() {
    window.CheckoutPaymentPostFinanceEfinanceDtCtrl = null;
  }

  function onDatatransPopupClosed() {
    // TODO: Collapse the payment method form to force the user to expand the tab again?
  }

  //
  // Public methods
  //

  $scope.initiatePayment = function() {
    $scope.payment = {
      amount: $scope.checkoutCtrl.getTotalPaymentPending(),
      currency: $scope.checkoutCtrl.checkout.currency,
      identifier: $scope.checkoutCtrl.checkout.id,
      order_id: $scope.checkoutCtrl.checkout.id,
      order_token: $scope.checkoutCtrl.checkout.token
    };

    if ($scope.card != null && $scope.card.pseudo_pan != null) {
      $scope.payment.aliasCard = { pan: $scope.card.pseudo_pan, year: $scope.card.year, month: $scope.card.month, type: $scope.card.cc_type }
    }

    $scope.checkoutCtrl.trackAddPaymentInfo($scope.paymentCtrl.selectedPaymentMethod.name);

    DatatransHelper.initiatePayment($scope.payment, {
      mode: 'lightbox',
      paymentmethod: 'PEF', // See https://docs.datatrans.ch/docs/other-payment-methods
      rememberMe: true,
      deferredSettlement: $scope.deferredSettlement,
      uppStartTarget: '_top',
      success_url: `${window.settingsSiteUrl}/orders/${$scope.checkoutCtrl.checkout.number}/thankyou?order_token=${$scope.checkoutCtrl.checkout.token}`,
      error_url: `${window.settingsSiteUrl}/payments/${$scope.checkoutCtrl.checkout.number}/order_status?order_token=${$scope.checkoutCtrl.checkout.token}`,
      cancel_url: `${window.settingsSiteUrl}/payments/${$scope.checkoutCtrl.checkout.number}/order_status?order_token=${$scope.checkoutCtrl.checkout.token}`,
      onClosed: onDatatransPopupClosed
    });
  };

  // Initialize

  $scope.$on('$destroy', destructor);
  constructor();
}]);
