import i18next from "i18next";
import axios from "axios";
import {useEffect, useState} from 'react';

const globalSettings = {
  server: {apiEndpoint: 'api'}
};

const loadTranslations = (locale, isInit = false) => {
  return new Promise((resolve, reject) => {
    const loadRemoteTranslations = () => {
      const {apiEndpoint} = globalSettings?.server;
      return axios
        .get(`${apiEndpoint}/frontend/locale/translations.json?lang=${locale}`);
    };

    loadRemoteTranslations().then(response => resolve(response.data));
  });
};

const initialize = async (locale = 'de', onUpdate = () => {
}, translations) => {
  onUpdate = onUpdate || (() => {
  });
  translations = translations || await loadTranslations(locale, true);

  const options = {
    lng: locale,
    debug: false,
    resources: {
      de: locale === 'de' ? {translation: await translations} : {},
      'fr-CH': locale === 'fr-CH' ? {translation: await translations} : {},
      en: locale === 'en' ? {translation: await translations} : {},
    }
  };

  i18next.init(options).then(r => onUpdate(locale));
};

const setCookie = (locale) => {
  document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`;
};

const apiUpdateUser = (locale) => {
  const {apiEndpoint} = globalSettings?.server;
  const params = {
    locale,
    noaccount: 't',
    nocards: 't',
    noshipping: 't',
    noorders: 't'
  };

  axios.get(`${apiEndpoint}/users/current.json`, params);
};

const updateLocale = (locale, onUpdate = () => {
}, translations) => {
  onUpdate = onUpdate || (() => {
  });
  if (!i18next?.hasResourceBundle(locale, 'translation')) {
    if (translations) {
      i18next.addResourceBundle(locale, 'translation', translations, true);
      i18next.changeLanguage(locale).then(r => onUpdate(locale));
    } else {
      loadTranslations(locale, false).then(response => {
        i18next.addResourceBundle(locale, 'translation', response, true);
        i18next.changeLanguage(locale).then(r => onUpdate(locale));
      });
    }
  } else {
    i18next.changeLanguage(locale).then(r => onUpdate(locale));
  }
};

const setLocale = (locale, onUpdate = () => {
}, translations) => {
  if (i18next?.language) {
    updateLocale(locale, onUpdate, translations);
  } else {
    initialize(locale, onUpdate, translations);
  }

  setCookie(locale);
  apiUpdateUser(locale);
};

export const translate = (key, defaultKey = null) => i18next.t(key, defaultKey);
export const t = translate;

export const useLocale = () => {
  const [locale, setLocale] = useState(i18next?.language || I18n?.locale || 'de');

  useEffect(() => {
    if (i18next.language && i18next.language !== 'locale') {
      setLocale(i18next.language);
    }
  }, [i18next.language]);

  return locale;
}

/** useEnsureTranslate
 * It checks for the existence of loaded translations, else retries.
 * Since it changes state, will force re-render to ensure re-render.
 * @param key
 * @param numOfRetries
 * @param interval
 */
export const useEnsureTranslate = (key = "ensure_translate", numOfRetries = 0, interval = 0) => {
  const defaultInterval = 500;
  const defaultNumOfRetries = 5;
  interval = interval || defaultInterval;
  numOfRetries = numOfRetries || defaultNumOfRetries;

  const [success, setSuccess] = useState(false);
  const [round, setRound] = useState(0);

  const addRound = () => {
    setRound(round + 1);
  };

  const pushRound = (isRetry = false) => {
    const translation = translate(key);
    if (translation && isRetry) setSuccess(true);
    else addRound();
  };

  useEffect(() => {
    pushRound();
  }, []);

  useEffect(() => {
    let timeoutLoop;

    if (!success && round > 0 && round < numOfRetries) {
      timeoutLoop = setTimeout(() => {
        pushRound(true);
      }, interval);
    }

    return () => {
      if (timeoutLoop) clearTimeout(timeoutLoop);
    };
  }, [round]);
};

export const getChildrenKeys = (key, partialKey = false) => {
  const parentLength = key.split('.').length;
  const translation = i18next.getDataByLanguage(i18next.language)?.translation;
  const keys = Object.keys(translation).filter(k => k.includes(key));

  return Array
    .from(new Set(keys.map(i => i
      .split('.')
      .splice(0, parentLength + 1)
      .join('.')
    )));
};

export const translateCollection = (key, defaultKey = null) => {
  if (!key) return;
  const dataCollection = translate(key, defaultKey);
  if (typeof dataCollection !== 'string') return;
  return dataCollection.split('\n');
};

export const useTranslatedUrls = (localeUrls) => {
  useEffect(() => {
    if (localeUrls) {
      storeTemplateHeadersCache(localeUrls);
    }
  }, [localeUrls]);

  const storeTemplateHeadersCache = (localeUrls) => {
    const locale = i18next.language || "de";
    const templateCacheKeys = [
      `${localeUrls[locale]}`,
      `${localeUrls[locale]}?locale=${locale}&ng_view_load=t`,
      `${localeUrls[locale]}?&locale=${locale}&ng_view_load=t`
    ];
    if (window._templateHeadersCache) {
      templateCacheKeys.forEach(templateCacheKey => {
        if (window._templateHeadersCache[templateCacheKey] == null || window._templateHeadersCache[templateCacheKey][`alt_url_${locale}`] == null) {
          window._templateHeadersCache[templateCacheKey] = window._templateHeadersCache[templateCacheKey] || {};
          window.I18n?.availableLocales.forEach(localeKey => {
            window._templateHeadersCache[templateCacheKey][`alt_url_${localeKey}`] = localeUrls[localeKey];
          });
        }
      });
    }
  };
};

export default setLocale;
