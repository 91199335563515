'use strict';

angular.module('app').controller('CheckoutCreateUserBalanceDiscountCtrl', ['$scope', '$rootScope', '$q', '$location', '$window', '$interval', '$http', '$element', 'Alerts',
  function ($scope, $rootScope, $q, $location, $window, $interval, $http, $element, Alerts) {
    console.log("CheckoutCreateUserBalanceDiscountCtrl controller initialized with scope");

    // Public members:
    $scope.isUpdating = true;
    $scope.addedAmount = null;
    $scope.userAccountData = null;

    $scope.order = {
      id: window.currentOrderId,
      number: window.currentOrderNumber,
      token: window.currentOrderToken
    }

    /**
     * Loads user balance details data to assist in form filling
     */
    $scope.loadAccountData = function() {
      $http.get('/api/frontend/user_accounts/my.json').then(function(response) {
        $scope.userAccountData = response.data.account;
      })
    };

    /**
     * Submits a user account balance discount request, if successful, order
     * adjustment will be created and the page will be reloaded
     */
    $scope.createAdjustment = function() {
      $scope.addedAmount = cleanAmount($scope.addedAmount);

      if ($scope.isUpdating)
        return false;

      $scope.isUpdating = true;

      return $q(function(resolve, reject) {
        if(!isAmountValid()) {
          Alerts.error($translate.instant('errors.discount_amount_incorrect', { max: $scope.userAccountData.cash.toFixed(2) }));
          reject();
          return;
        } else if($scope.addedAmount <= 1) {
          Alerts.error($translate.instant('errors.discount_amount_incorrect', { max: $scope.userAccountData.cash.toFixed(2) }));
          reject();
          return;
        } else if($scope.addedAmount > $scope.userAccountData.cash) {
          Alerts.error($translate.instant('errors.discount_amount_incorrect', { max: $scope.userAccountData.cash.toFixed(2) }));
          reject();
          return;
        }

        $http.post('/api/frontend/orders/' + $scope.order.number + '/add_discount_adjustment_from_account_balance.json', {
          order_token: $scope.order.token,
          amount: $scope.addedAmount,
        }).then(function(response) {
          console.log("OK", response.data);
          Alerts.success($translate.instant('messages.account_balance_discount_created'));

          window.location.reload();

          resolve(response.data);
        }, function(error) {
          console.log(error);
          Alerts.error(errorMessage(error));
          $timeout(function() { $scope.isUpdating = false; }, 3000);
        });
      }).finally(function() {
        $scope.addedAmount = null;
        $timeout(function() { $scope.isUpdating = false; }, 3000);
      });
    };

    // Private members:

    var isAmountValid = function() {
      return !isNaN($scope.addedAmount);
    };

    var cleanAmount = function(amount) {
      try {
        return parseFloat(amount.toString().replace(/\,/i, '.').replace(/[(CHF)(Fr)(\-)]/ig, '').replace(' ', ''));
      } catch(e) {
        return amount;
      }
    };

    // Initialization

    $scope.$watch('userAccountData', function(accountData) {
      if(accountData && accountData.cash) {
        $scope.isUpdating = false;
      }
    });

    //$scope.$watch('addedAmount', function(addedAmount) {
    //  if($scope.userAccountData && $scope.userAccountData.cash) {
    //
    //  }
    //});

    $scope.loadAccountData();
  }
]);
