'use strict';

angular.module('app').controller('ReactivationLandingCtrl', ['$scope', '$rootScope', '$q', '$http', '$location', '$timeout', 'SuppliersHelper', 'blockUI', 'OrderHelper', function ($scope, $rootScope, $q, $http, $location, $timeout, SuppliersHelper, blockUI, OrderHelper) {
  window.ReactivationLandingCtrl = $scope;
  window.isReactivationPage = true;

  console.log('ReactivationLandingCtrl loaded');

  let waitingInterval, waitingTimeout;
  $scope.showInitialTagline = true;
  $scope.currentLocale = I18n.locale;

  function constructor() {
    $scope.reactivationId = $location.search().id;
    $scope.userId = window.currentUserId;
    waitingLoop();

    getLocalSuppliers();

    if (!$scope.reactivationId) {
      // $location.changeUrl('/');
      // destructor()
    }

    if ($scope.reactivationId) {
      getReactivationMessage().then((response) => {
        $scope.reactivation = response;
        $scope.reactivation.other_product_ids = $scope.reactivation.other_product_ids.join(',');
        $scope.gift_product = response.gift_products ? response.gift_products[0]: null;
        $scope.clearWaitingLoop();
      }, (error) => {
        console.log(error);
        redirectToHome()
      })
    }
  }

  function getReactivationMessage() {
    return $q((resolve, reject) => {
      $http.get(`api/frontend/reactivation_messages/${$scope.reactivationId}.json?locale=${I18n.locale}`).then((response) => {
        let reactivation = response.data;

        if (reactivation && reactivation.id)
          resolve(reactivation);
        else
          reject(reactivation)

      }, (error) => {
        reject(error)
      })
    });
  }

  function getLocalSuppliers() {
    let distance = 0.25;
    let currentZipcode = window.currentZipcode;

    SuppliersHelper.getSuppliersAtDistance(currentZipcode, distance).then(list => {
      if (list.suppliers.length < 2) {
        return SuppliersHelper.getSuppliersAtDistance(currentZipcode, distance + 0.3).then(list => {
          $scope.featuredSuppliers = list.suppliers.slice(0, 4);
        });
      } else {
        $scope.featuredSuppliers = list.suppliers.slice(0, 4);
      }
    });
  }

  function redirectToHome() {
    $location.path('/');
    $location.search({locale: I18n.locale});
    destructor();
  }

  function waitingLoop() {
    let element = $('.initial-tagline');
    let text = element.html().trim();
    let spans = _(text.split('')).map((t) => {return `<div class="tagline-char${t === ' ' ? ' space' : ''}">${t}</div>`}).join('');

    element.html(spans);
    let els = element.find('.tagline-char');
    let index = 0;

    waitingInterval = setInterval(() => {
      if(!els[index]) index = 0;
      if($(els[index]).html() === ' ') index++;

      $(els[index]).addClass('bouncy');
      let insideIndex = index;

      setTimeout(() => {
        $(els[insideIndex]).removeClass('bouncy');
        index++
      }, 100)
    }, 50)
  }

  function destructor() {
    window.ReactivationLandingCtrl = null;
    window.isReactivationPage = null;
  }

  // public

  $scope.repeatLastOrder = function() {
    blockUI.start();

    OrderHelper.repeatOrder({ id: 'last', token: '', excludedProductIds: $scope.gift_product ? [$scope.gift_product.id] : null }).then(() => {
      blockUI.stop();
      $location.path("/cart");
    }, e => {
      Alerts.error(errorMessage(e));
      blockUI.stop();
    });
  };

  $scope.clearWaitingLoop = function() {
    clearInterval(waitingInterval);
    $timeout(() => {
      $scope.showInitialTagline = false;
      $('.initial-tagline').html('');

      $timeout(() => {$(window).trigger('resize')}, 500)
    }, 500)
  };

  $scope.setMainImgHeight = function() {
    $timeout(() => {
      $('.main-product-wrapper .main-product-image').css('height', `${$('.main-product-wrapper').innerHeight()}px`)
    }, 1000)
  };

  // Initialize

  $scope.$on('$destroy', destructor);
  constructor();
}]);
