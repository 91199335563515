import * as _ from 'underscore';

'use strict';

angular.module('app').controller('UserRatingsModalController', ['$scope', '$rootScope', '$timeout', '$http', '$localStorage', 'Alerts', '$uibModalInstance',
  function ($scope, $rootScope, $timeout, $http, $localStorage, Alerts, $uibModalInstance) {
    window.UserRatingsModalController = $scope;

    // Public members

    $scope.onSubmitClicked = function () {
      $uibModalInstance.close({dismiss: false})
    };

    $scope.onDismissClicked = function () {
      $uibModalInstance.close({dismiss: true})
    };

    // Check if there are un-scored ratings
    $scope.checkIfSubmitAllowed = function () {
      $scope.submitAllowed = !_(_($rootScope.userRatings).map((rating) => {return rating.score})).some((score) => {return !score})
    };

    // Private members

    function constructor() {
      _($rootScope.userRatings).each((rating) => {
        rating.scoreRange = _(Array(rating.score_max)).map((n, i) => {return i + 1});
      });

      $scope.ratingsByDate = _(_(_($rootScope.userRatings).sortBy('service_date').reverse())
          .groupBy((rating) => { return rating.service_date })).pairs()
    }

    function destructor() {
      window.UserRatingsNotificationController = null;
    }

    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
