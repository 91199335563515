import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app').controller('CheckoutDeliveryModeCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', 'CartData', 'Alerts', 'blockUI', function ($scope, $sce, $http, $q, $rootScope, $timeout, CartData, Alerts, blockUI) {
  window.CheckoutDeliveryModeCtrl = $scope;

  $scope.checkoutCtrl = $scope.$parent.$parent;

  $scope.deliverModeStepHidden = true;

  var autoSkippedOnce = false;

  function constructor() {
    $scope.$watch('checkoutCtrl.checkout.delivery_mode', (newValue, oldValue) => {
      // Skip the delivery mode dialog, since pickup isn't available to regular customers
      if ($scope.checkoutCtrl && $scope.checkoutCtrl.checkout && $scope.checkoutCtrl.checkout.id != null && newValue == null && !autoSkippedOnce) {
        autoSkippedOnce = true;
        console.log("Skipping delivery mode");
        $timeout(() => {
          $scope.chooseMode('deliver');
        }, 10);
      }
    })
  }

  $scope.chooseMode = function(mode) {
    return $q(function(resolve, reject) {
      if ($scope.checkoutCtrl.checkout.delivery_mode == mode) {
        resolve({});
        return;
      }

      blockUI.start();

      var nextState = 'address';

      $scope.checkoutCtrl.checkout.delivery_mode = mode;

      // Reset pickup point information when 'delivery' is picked
      $scope.pickupPoint = null;
      // If delivery mode changes, delivery slot selection must be reset
      $scope.deliverySlot = null;

      $scope.checkoutCtrl.updateCheckout({delivery_mode: mode, delivery_slot_id: null, pickup_point_id: null, state: nextState}, true).then(function() {
        blockUI.stop();
        $timeout(() => window.scrollToElement('#deliveryModeSummary'), 500);
        $scope.updateStage();
      });
    })
  }

  function destructor() {
    window.CheckoutDeliveryModeCtrl = null;
  }

  // Public members

  // Initialize

  $scope.$on('$destroy', destructor);
  constructor();
}]);
