appModule.directive('userDietaryPreferencesSelector', ['$q', '$translate', '$http', 'UserService', function($q, $translate, $http, UserService) {
  return {
    restrict: 'E',
    scope: {
      saveOnChange: '@', // will update user record (if logged in) on selection change
      mode: '@', // expects either 'short' or 'extended' for a longer list of options
    },
    controller: ['$scope', '$rootScope', '$window', '$location', '$timeout', '$element', 'UserService', 'OrderHelper', 'UiStateService', function($scope, $rootScope, $window, $location, $timeout, $element, UserService, OrderHelper, UiStateService) {
      window.userDietaryPreferencesSelector = $scope;

      function constructor() {

      }

      function destructor() {
        window.userDietaryPreferencesSelector = null;
      }

      //
      // Public members
      //

      $scope.selection = {};

      $scope.onPreferenceIconClick = function() {

      };

      $scope.onTemplateLoaded = function() {
        // Enable bootstrap fix

      };

      $scope.getSelection = function() {
        return $scope.selection;
      };

      $scope.$on('$destroy', destructor);
      constructor();
    }],
    link: function (scope, element, attrs) {

    },
    template: `<farmy-ng-include src="'/ng/templates/nav/user_accounts/dietary_preferences_selector.html'" onload="onTemplateLoaded()"></farmy-ng-include>`
  };
}]);
