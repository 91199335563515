import * as _ from 'underscore';
import * as sprintf from 'sprintf';

angular.module('app').service('OrderUpdater', ['$q', '$http', 'CartData', function ($q, $http, CartData) {
  var lastUserModificationAt;

  this.updateVariant = function(old_variant_id, new_variant_id) {
    var deferred = $q.defer();

    lastUserModificationAt = (new Date).getTime();
    var requestStartedAt = (new Date).getTime();

    var data = {
      number: window.currentOrderNumber, order_token: window.currentOrderToken,
      old_variant_id: old_variant_id, new_variant_id: new_variant_id
    };

    $http.patch('/api/frontend/orders/update_variant.json?locale=' + I18n.locale, data)
    .then(
      function(response) {
        if (requestStartedAt >= lastUserModificationAt) {
          CartData.load();
          deferred.resolve();
        }
        else deferred.reject("expired");
      },
      function(response) { deferred.reject(response); }
    );

    return deferred.promise;
  };

  this.updateQuantity = function(lineItemId, quantity) {
    var deferred = $q.defer();

    lastUserModificationAt = (new Date).getTime();
    var requestStartedAt = (new Date).getTime();

    var data = {
      number: window.currentOrderNumber,
      order_token: window.currentOrderToken,
      id: lineItemId,
      quantity: quantity
    };

    $http.patch('/api/frontend/orders/update_line_item_quantity.json', data)
    .then(
      function(response) {
        if (requestStartedAt >= lastUserModificationAt) {
          CartData.load();
          deferred.resolve();
        } else {
          deferred.reject("expired");
        }
      },
      function(response) { deferred.reject(); }
    );

    return deferred.promise;
  }

  this.deleteLineItem = function(lineItemId) {
    var deferred = $q.defer();

    lastUserModificationAt = (new Date).getTime();
    var requestStartedAt = (new Date).getTime();

    var data = {
      number: window.currentOrderNumber,
      order_token: window.currentOrderToken,
      line_item_id: lineItemId
    };

    $http.post(`/api/frontend/orders/${window.currentOrderNumber}/remove_line_item.json`, data)
    .then(
      function(response) {
        if (requestStartedAt >= lastUserModificationAt) {
          CartData.load();
          deferred.resolve();
        }
      },
      function(response) { deferred.reject(); }
    );

    return deferred.promise;
  };

  // private
}]);
