'use strict';

angular.module('app').controller('FacebookFeedCtrl', ['$scope', '$http',
  function ($scope, $http) {
    $scope.facebookFeed = null;

    $scope.getFacebookFeed = function() {
      return $http.get(sprintf('/api/frontend/social/facebook_feed.json')).then(function(response) {
        $scope.facebookFeed = response.data;
      });
    };

    $scope.getFacebookFeed();
  }]);
