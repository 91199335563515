/**
 * Defines multiple input-value filtering and formatting directives
 */

angular.module('app').directive('formatPlainNumber', ['$filter', function ($filter) {
  return {
    require: '?ngModel',
    link: function (scope, elem, attrs, ctrl) {
      if (!ctrl) return;


      ctrl.$formatters.unshift(function (a) {
        return $filter('number')(ctrl.$modelValue)
      });


      ctrl.$parsers.unshift(function (viewValue) {
        var plainNumber = viewValue.replace(/[^\d|\-+|\.+]/g, '');
        elem.val($filter('number')(plainNumber));
        return plainNumber;
      });
    }
  };
}]);

angular.module('app').directive('formatMoneyNumber', ['$filter', function ($filter) {
  return {
    require: '?ngModel',
    link: function (scope, elem, attrs, ctrl) {
      if (!ctrl) return;


      ctrl.$formatters.unshift(function (a) {
        return $filter('number')(ctrl.$modelValue, 2)
      });


      ctrl.$parsers.unshift(function (viewValue) {
        var plainNumber = viewValue ? parseFloat(viewValue) : null;
        elem.val($filter('number')(plainNumber, 2));
        return plainNumber;
      });
    }
  };
}]);

angular.module('app').directive('formatMoneyNumberOnBlur', ['$filter', function ($filter) {
  return {
    require: '?ngModel',
    link: function (scope, elem, attrs, ctrl) {
      if (!ctrl) return;

      if (ctrl.$modelValue)
        elem.val($filter('number')(ctrl.$modelValue ? parseFloat(ctrl.$modelValue) : 0, 2));

      elem.on('blur', (e) => {
        elem.val($filter('number')(ctrl.$modelValue ? parseFloat(ctrl.$modelValue) : 0, 2));
      });
    }
  };
}])