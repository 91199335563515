'use strict';

angular.module('app')
  .directive('dismissablePanel', function() {
    return {
      link: function(scope, element, attributes) {
        scope.hidePanel = localStorage[attributes.dismissablePanel];

        if (scope.hidePanel == 'true') {
          element.hide();
        }

        element.find('.ok-button').click(function() {
          dismissPanel();
        });

        var dismissPanel = function() {
          localStorage[attributes.dismissablePanel] = true;
          scope.hidePanel = true;
          element.slideUp("slow");
        };
      }
    }
  });

window.DimissablePanelUtil = {
  hideById: function(id) {
    localStorage[id] = true;
  }
}