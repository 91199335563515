/**
 * farmyNgView is a custom ngView implementation that allows
 * changing location URL without changing the view.
 *
 * TODO: Override router's methods to prevent unnecessary fetching of the content from the server
 * TODO: Support dynamic pre-evaluation of angular-related javascript
 *
 * @type {module}
 */

appModule.directive('farmyNgView', farmyNgViewFactory);
appModule.directive('farmyNgView', farmyNgFillViewFactory);

farmyNgViewFactory.$inject = ['$route', '$anchorScroll', '$animate', '$rootScope', '$translate', '$location', '$window', 'UserService'];
function farmyNgViewFactory($route, $anchorScroll, $animate, $rootScope, $translate, $location, $window, UserService) {
  return {
    restrict: 'ECA',
    terminal: true,
    priority: 400,
    transclude: 'element',
    link: function(scope, $element, attr, ctrl, $transclude) {
      var currentScope,
        currentElement,
        previousLeaveAnimation,
        autoScrollExp = attr.autoscroll,
        onloadExp = attr.onload || '';

      var firstTranslationCallback = true;
      var firstLoad = true;

      scope.$on('$routeChangeSuccess', () => {
        if (window.changingPathWithoutReload != true) {
          update()
        } else {
          window.changingPathWithoutReload = null;
        }
        window.GoogleDataLayerAdapter.angularJSRouteChangeSuccess();
      });

      $rootScope.$on('$translateChangeSuccess', function () {
        if (firstTranslationCallback) {
          firstTranslationCallback = false;
          return;
        }

        // Reload location with the new locale
        if (!window.$locationChanging) {
          var originalPath = $location.path();
          const originalPathNonDefaultLocale = _.find(I18n.availableLocales, l => originalPath.indexOf(`/${l}/`) === 0);

          // Build a generic path with switched locale
          if (originalPathNonDefaultLocale != null && originalPathNonDefaultLocale != $translate.use()) {
            if (Rails.env != 'production') console.log(`Switching resource from locale ${originalPathNonDefaultLocale || 'de'} to new locale ${$translate.use()}`);
            originalPath = originalPath.replace(new RegExp(`^/${originalPathNonDefaultLocale}`, 'i'), '');
          }

          // Check if we have access to extended template headers
          // and attempt find a localized URL there
          // console.log("Looking for template header cache by key:", $route.current.loadedTemplateUrl || $location.path());
          const templateHeaders = window._templateHeadersCache && $route.current ? window._templateHeadersCache[$route.current.loadedTemplateUrl || $location.path()] : null;

          if (templateHeaders && templateHeaders[`alt_url_${$translate.use()}`]) {
            originalPath = templateHeaders[`alt_url_${$translate.use()}`];
            var oldParams = angular.copy($location.search());

            if (oldParams['locale'])
              delete oldParams['locale'];

            // We can use a known alt hreflang url to switch the resource locale:
            $location.path(originalPath).search(oldParams).replace();
          } else {
            $location.path(originalPath).search(angular.extend($location.search(), { locale: $translate.use() })).replace();
          }
        }
      });

      function cleanupLastView() {
        if (previousLeaveAnimation) {
          $animate.cancel(previousLeaveAnimation);
          previousLeaveAnimation = null;
        }

        if (currentScope) {
          currentScope.$destroy();
          currentScope = null;
        }
        if (currentElement) {
          previousLeaveAnimation = $animate.leave(currentElement);
          previousLeaveAnimation.then(function() {
            previousLeaveAnimation = null;
          });
          currentElement = null;
        }
      }

      function update(track=true) {
        var locals = $route.current && $route.current.locals,
          template = locals && locals.$template;

        if (window.PageloadTracking) PageloadTracking.viewloadSummaryStart();

        if (angular.isDefined(template) && locals._no_reload != true && $route.current._no_reload != true) {
          var newScope = scope.$new();
          var current = $route.current;

          let templateHeaders = window._templateHeadersCache ? window._templateHeadersCache[current.loadedTemplateUrl] : null;

          // Note: This will also link all children of ng-view that were contained in the original
          // html. If that content contains controllers, ... they could pollute/change the scope.
          // However, using ng-view on an element with additional content does not make sense...
          // Note: We can't remove them in the cloneAttchFn of $transclude as that
          // function is called before linking the content, which would apply child
          // directives to non existing elements.
          var clone = $transclude(newScope, function(clone) {
            $animate.enter(clone, null, currentElement || $element).then(function onNgViewEnter() {
              if (angular.isDefined(autoScrollExp)
                && (!autoScrollExp || scope.$eval(autoScrollExp))) {
                $anchorScroll();
              }
            });
            cleanupLastView();
          });

          currentElement = clone;
          currentScope = current.scope = newScope;
          currentScope.$emit('$viewContentLoaded');
          currentScope.$eval(onloadExp);

          // TODO: Setup document title via $rootScope
          if (!firstLoad && $route.current && $route.current.loadedTemplateUrl) {
            let trackingObj = {
              location: $route.current.loadedTemplateUrl.replace("ng_view_load=t", ""),
              title: templateHeaders && templateHeaders.title ? templateHeaders.title : undefined
            };

            if (templateHeaders && templateHeaders.title) {
              $rootScope.setDocumentTitle(templateHeaders.title);

              // TODO: Scroll only if the title string changes..?
              $window.scrollTo(0, 0);
            }

            if (track) {
              window.Tracking.sendPageviewExt(trackingObj.location, trackingObj)
            }
          }

          if(templateHeaders && templateHeaders.robots_meta) {
            $rootScope.setRobotsMeta(templateHeaders.robots_meta);
          }

          if (track) Tracking.trackPageview(null, UserService);

          firstLoad = false;
        } else {
          if (($route.current == null || $route.current._no_reload != true) && (locals == null || locals._no_reload != true))
            cleanupLastView();
        }
      }

      // Alias method to update the custom ngView from within the app controller
      scope.updateFarmyNgView = update;

      update();
    }
  };
}

// This directive is called during the $transclude call of the first `ngView` directive.
// It will replace and compile the content of the element with the loaded template.
// We need this directive so that the element content is already filled when
// the link function of another directive on the same element as ngView
// is called.
farmyNgFillViewFactory.$inject = ['$compile', '$controller', '$route', '$timeout'];
function farmyNgFillViewFactory($compile, $controller, $route, $timeout) {
  return {
    restrict: 'ECA',
    priority: -400,
    link: function(scope, $element) {
      var current = $route.current;
      var locals = current.locals;

      // Detect cases when the full template is returned and fail with an error if this happens:
      if (locals.$template.match(/\<html.*data\-ng\-app\=(\"|\')app(\"|\')/)) {
        console.error("Got invalid template with the layout", { route: $route }, "template:", locals.$template);
        window.AngularIntegration.$broadcast("ngview:template:fulllayouterror", { route: $route }); // broadcast the error for the UI to react

        window.lastBrokenTemplate = locals.$template;

        // Attempt to extract the content part from the response:
        try {
          var xmlParser = new DOMParser();
          var xml = xmlParser.parseFromString(locals.$template, "text/html");
          window.lastBrokenTemplateXml = xml;

          var content = "<div style='padding: 20px; text-align: center'>Connection error. Please reload the page.</div>"
          if (xml.getElementById("page-content-main")) {
            let responseBodyPart = xml.getElementById("page-content-main").innerHTML.trim();

            if (responseBodyPart != "") content = responseBodyPart;
          }

          // Substitute template with the content without the layout
          locals.$template = content;
        } catch(e) {
          throw new Error("Page load error: full template is not a valid page, please contact support.");
        }
      }

      if (window.changingPathWithoutReload != true && locals._no_reload != true) {
        // Find and eval preeval blocks before injecting the HTML
        // Preeval blocks may contain custom controller and directive definition,
        // that will be registered on the main appModule
        var preevalBlocks = locals.$template.match(/<script[^>]*preeval>([\s\S]+?)<\/script>/ig);

        if (preevalBlocks) {
          for(var k in preevalBlocks) {
            locals.$template = locals.$template.replace(preevalBlocks[k], ''); // clear preeval scripts from the source
            preevalBlocks[k] = preevalBlocks[k].replace(/<(\/?)script[^>]*>/g, '');
            window.eval(preevalBlocks[k]); // eval them in the window namespace
          }
        }

        $element.html(locals.$template);

        var link = $compile($element.contents());

        if (current.controller) {
          locals.$scope = scope;
          var controller = $controller(current.controller, locals);
          if (current.controllerAs) {
            scope[current.controllerAs] = controller;
          }
          $element.data('$ngControllerController', controller);
          $element.children().data('$ngControllerController', controller);
        }

        link(scope);
      }
    }
  };
}
