const ThemeNatuerli = {
  name: 'natuerli',
  colors: {
    primaryColor: '#176da0',
    primaryBg: '#F4991A',
    textColor: '#333333'
  }
};

export default ThemeNatuerli
