'use strict';

angular.module('app').controller('ReferralBlockCtrl', ['$scope', '$http', 'Alerts', '$location', function($scope, $http, Alerts, $location){
  $scope.emailAddresses = [];
  $scope.emailFields = [0];

  $scope.getCollection = function(number) {
    return new Array(number);
  };

  $scope.addEmailField = function() {
    if($scope.emailFields.length < 6) {
      $scope.emailFields = $scope.emailFields.concat(0);
    }
  };

  $scope.onCopySuccess = function() {
    Alerts.success($translate.instant("account_settings.referrals.link_copied"))
  };

  $scope.onCopyError = function() {
    Alerts.error($translate.instant("account_settings.referrals.copy_to_clipboard_error"))
  };

  $scope.$watch('referralToken', function(token) {
    if (token && token.length > 0) {
      $scope.tokenizedUrl = $location.protocol() + '://' + location.host + '/invite/' + token;
    }
  });

  $scope.$on('$destroy', function() {
    window.ReferralBlockCtrl = null;
  });
}]);