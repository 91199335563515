angular.module("app")
  .config(["$routeProvider", "$locationProvider", "$translateProvider", function($routeProvider, $locationProvider, $translateProvider) {
    $locationProvider.html5Mode(true);

    const Hubs = window.Hubs || {};
    const isCorporateBuyer = !!((window.currentUserRoles && window.currentUserRoles.indexOf("corporate_buyer") > -1));

    $routeProvider
      .when("/", {
        templateUrl: (params) => {
          const debugSearch = params.debug_search === "t" ? "&debug_search=t" : "";

          if (window.currentStorefront) {
            return `/home/home_${window.currentStorefront}.html?ng_view_load=t&locale=${I18n.locale}${params.home_version ? "&home_version=" + params.home_version : ""}${debugSearch}`;
          } else if (window.spreeUserLoggedIn && params.show_jumbo !== "t") {
            return `/start.html?ng_view_load=t&locale=${I18n.locale}${params.home_version ? "&home_version=" + params.home_version : ""}${debugSearch}`;
          } else {
            return `/start.html?show_jumbo=t&ng_view_load=t&locale=${I18n.locale}${params.home_version ? "&home_version=" + params.home_version : ""}${debugSearch}`;
          }
        }
      })
      .when("/explore", {
        templateUrl: (params) => {
          return `/explore.html?ng_view_load=t&locale=${I18n.locale}`;
        }
      })
      .when("/experimental/react_product_list", {
        templateUrl: (params) => {
          return "/ng/templates/experimental/react_product_list.html";
        }
      })
      .when("/my", {
        templateUrl: (params) => {
          return `/explore.html?ng_view_load=t&locale=${I18n.locale}`;
        }
      })
      .when("/farmers_home", {
        templateUrl: (params) => {
          return "/ng/templates/supplier_portal/index.html";
        }
      })
      .when("/farmers_home/calendar", {
        templateUrl: (params) => {
          return "/ng/templates/supplier_portal/calendar.html";
        }
      })
      .when("/farmers_home/product/:product_id", {
        templateUrl: (params) => {
          return "/ng/templates/supplier_portal/product.html";
        }
      })
      .when("/farmers_home/products", {
        templateUrl: (params) => {
          return "/ng/templates/supplier_portal/products.html";
        }
      })
      .when("/farmers_home/documents", {
        templateUrl: (params) => {
          return "/ng/templates/supplier_portal/documents.html";
        }
      })
      .when("/cashier", {
        templateUrl: (params) => {
          return `/cashier.html?ng_view_load=t&locale=${I18n.locale}`;
        },
        reloadOnSearch: false
      })
      .otherwise({
        templateUrl: (e, b) => {
          let urlPath = window.location.pathname + window.location.search;

          if (urlPath.indexOf("?") < 1) {
            urlPath += "?";
          }

          const locale = $translateProvider.use() || I18n.locale;

          if (urlPath.indexOf("locale=") === -1) {
            urlPath = `${urlPath}&locale=${locale}`;
          }

          // Hack for absolute links in development
          if (window.Rails.env === "development") urlPath = urlPath.replace("/www.farmy.ch", "");

          return `${urlPath}&ng_view_load=t`;
        }
      });
  }]);

// See https://stackoverflow.com/questions/14974271/can-you-change-a-path-without-reloading-the-controller-in-angularjs
appModule.run(["$route", "$rootScope", "$location", "$timeout", "$translate", function($route, $rootScope, $location, $timeout, $translate) {
  const originalLocationPath = $location.path;

  $location.path = function(path, options) {
    return originalLocationPath.apply($location, [path]);
  };

  $location.changeUrl = function(path, options) {
    window.changingPathWithoutReload = true;

    if (options && options.prependLocale) {
      const locale = $translate.use() || I18n.locale;

      if (locale !== "de" && (path.indexOf(locale) === -1 || path.indexOf(locale) >= 3)) {
        if (path[0] === "/") path = `/${locale}${path}`;
        else path = `/${locale}/${path}`;
      }
    }

    setTimeout(() => {
      window.changingPathWithoutReload = null;
    }, 100);

    if (options && options.replace) return originalLocationPath.apply($location, [path]).replace();
    return originalLocationPath.apply($location, [path]);
  };

  $location.changeSearch = function(search, paramValue) {
    switch (arguments.length) {
    case 0:
      return this.$$search;
    case 1:
      window.changingPathWithoutReload = true;

      if (angular.isString(search) || angular.isNumber(search)) {
        search = search.toString();
        this.$$search = angular._privateExposed.parseKeyValue(search);
      } else if (angular.isObject(search)) {
        search = angular.copy(search, {});
        // remove object undefined or null properties
        angular.forEach(search, function(value, key) {
          if (value == null) delete search[key];
        });

        this.$$search = search;
      } else {
        throw angular._privateExposed.$locationMinErr("isrcharg",
          "The first argument of the `$location#search()` call must be a string or an object.");
      }
      break;
    default:
      if (angular.isUndefined(paramValue) || paramValue === null) {
        delete this.$$search[search];
      } else {
        this.$$search[search] = paramValue;
      }
    }

    this.$$compose();

    if (arguments.length !== 0) {
      setTimeout(() => {
        window.changingPathWithoutReload = null;
      }, 200);
    }

    return this;
  };
}]);

window.apiUrl = function(url) {
  if (I18n.locale === "de") {
    return "/api/frontend" + url;
  } else {
    return "/" + I18n.locale + "/api/frontend" + url;
  }
};
