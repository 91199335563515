angular.module('app').controller('VerifyPhonePopupCtrl', ['$q', '$scope', '$timeout', '$uibModalInstance', '$http',
  function ($q, $scope, $timeout, $uibModalInstance, $http) {
    window.VerifyPhonePopupCtrl = $scope;

    $scope.verificationCode = null;
    $scope.resendingCode = false;

    $scope.dismiss = function() {
      $uibModalInstance.dismiss();
    };

    $scope.resendCode = function() {
      $scope.resendingCode = true;
      return $http.post(`/api/frontend/users/send_phone_token.json`).then(response => {

      }).finally(() => {
        $scope.resendingCode = false;
      });
    };

    $scope.verify = function() {
      $scope.errorText = null;

      return $q((resolve, reject) => {
        $http.post(`/api/frontend/users/verify_phone_token.json`, { token: $scope.verificationCode }).then(response => {
          if (response.data.success) {
            resolve(response.data);
            $uibModalInstance.close({ success: true });
          }
        }, (error) => {
          $scope.errorText = errorMessage(error);
          reject(error)
        });
      })
    }

    $scope.$on('$destroy', () => {
      window.VerifyPhonePopupCtrl = null;
    })
  }
]);
