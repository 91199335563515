'use strict';

angular.module('app').controller('ReferralInvitationsNewPageCtrl', ['$scope', '$http', 'Alerts', '$location', function($scope, $http, Alerts, $location){
  window.ReferralInvitationsNewPageCtrl = $scope;

  var ReferralsEndpoint = '/api/frontend/referral_invitations';

  $scope.$on('$destroy', function() {
    window.ReferralInvitationsNewPageCtrl = null;
  });
}]);