import React from 'react';
import RjsProductItem from "./products/RjsProductItem";
import {translate} from 'ng-frontend/shared-services/translate';
import * as styledView from './RjsProductTable.styled'

export const RjsProductTable = (props) => {
  const {products, paginator, isBot} = props

  return (
    <div className="product-list-wrapper">
      <styledView.TableHeader className="table-header" ng-if="layout == 'table'">
        <styledView.ColProductThumbDefault className="col-product-thumb-default header-col">
          <styledView.ColProductThumb className="col col-product-thumb"/>
        </styledView.ColProductThumbDefault>
        <styledView.ColSupplierFlag className="col col-supplier-flag"/>

        <styledView.ColSupplier className="col col-supplier">
          {translate('catalog.columns.producer')}
        </styledView.ColSupplier>

        <styledView.ColName className="col col-name">
          {translate('catalog.columns.name')}
        </styledView.ColName>

        <styledView.ColPrice className="col col-price">
          {translate('catalog.columns.price')}
        </styledView.ColPrice>

        <styledView.ColUnit className="col col-unit">
          {translate('catalog.columns.per')}
        </styledView.ColUnit>

        <styledView.ColFlags className="col col-flags">
          {translate('catalog.columns.certificate')}
        </styledView.ColFlags>

        <styledView.TableCol className="col col-cart-controls">
          {translate('catalog.columns.cart')}
        </styledView.TableCol>
      </styledView.TableHeader>

      <div className="clearfix"></div>
    </div>
  )
}

export default RjsProductTable
