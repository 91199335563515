'use strict';

angular.module('app').controller('TaxonFavoritesCtrl', ['$scope', '$rootScope', '$q', '$compile', '$location', '$window', '$interval', '$http', '$element', 'Alerts',
  function ($scope, $rootScope, $q, $compile, $location, $window, $interval, $http, $element, Alerts) {
    // console.log("TaxonFavoritesCtrl controller initialized with scope");

    window.TaxonFavoritesCtrl = $scope;

    // Public members:

    function updateFavorites() {
      return $http.get(sprintf('/api/frontend/taxons/%d/favorites', $element.attr('data-taxon-id'))).then(function(response) {
        $element.html($compile(response.data.html)($scope));
      });
    };

    if (currentUserId)
      updateFavorites();
  }
]);
