'use strict';

angular.module('app').controller('ExpressDeliveryWarningModalCtrl', [ '$scope', '$uibModalInstance', 'currentDeliveryMode',
  function ($scope, $uibModalInstance, currentDeliveryMode) {
    $scope.currentDeliveryMode = currentDeliveryMode;
    let requestedExpressMode = currentDeliveryMode !== 'express';

    $scope.confirm = function() {
      $uibModalInstance.close({response: 'confirm', requestedExpressMode: requestedExpressMode});
    };

    $scope.dismiss = function() {
      $uibModalInstance.close({response: 'reject', requestedExpressMode: requestedExpressMode});
    };
  }]);
