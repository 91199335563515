'use strict';

angular.module('app').controller('CmsPageFrontendCtrl', ['$scope', '$rootScope', '$q', '$http', '$sce',
  function ($scope, $rootScope, $q, $http, $sce) {
    window.CmsPageFrontendCtrl = $scope;

    function constructor() {

    }

    function destructor() {
      window.CmsPageFrontendCtrl = null;
    }

    //
    // Public members
    //

    /**
     * Page database ID
     */
    $scope.pageId = window.cmsPageId;

    /**
     * Placeholder for page JSON configuration.
     */
    $scope.pageConfiguration = window.cmsPageConfiguration || {};

    try {
      /**
       * Returns the 'current' page configuration based on hub name
       */
      $scope.currentHubConfiguration = $scope.pageConfiguration[window.currentHubCode.toLowerCase()] || {};
    } catch(e) { console.warn(e) }

    $scope.sessionZipcode = window.currentZipcode;

    try {
      $scope.sessionZoneType = window.currentZoneType;
      if($scope.sessionZoneType.indexOf('plus') > -1) {
        $scope.sessionZoneType = 'plus';
      } else if($scope.sessionZoneType.indexOf('zurich') > -1) {
        $scope.sessionZoneType = 'not_plus';
      } else if($scope.sessionZoneType.toString().indexOf('Lausanne')) {
        $scope.sessionZoneType = 'lausanne';
      }
    } catch(e) {console.warn(e)}

    /**
     * Returns a confuiguratuin block for specific hub.
     *
     * @param hubName
     * @returns {*}
     */
    $scope.configurationForHub = function(hubName) {
      if ($scope.pageConfiguration == null)
        return {};

      return $scope.pageConfiguration[hubName.toLowerCase()] || {}
    };

    //
    // Initialize
    //

    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
