import {cartTools} from "./useCart";

const productParser = (mode) => {
  const {getCurrentCart, lineItemFromProduct} = cartTools();

  const parsers = {
    catalogProductSingle: (product) => {
      const result = {...product};
      const cart = getCurrentCart();

      const lineItem = lineItemFromProduct(product, cart);

      if (lineItem) result.lineItem = lineItem;
      else delete result.lineItem;

      return result;
    },
    catalogProducts: (products) => {
      const result = products
        .map(product => parsers.catalogProductSingle(product));

      return result;
    }
  };

  return ((mode) => parsers[mode || "catalogProducts"])(mode);
};

export default productParser;
