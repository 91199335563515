'use strict';

angular.module('app').controller('CheckoutAddressCtrl', ['$scope', '$rootScope', '$q', '$http', '$timeout', '$uibModal', 'Hubs', function ($scope, $rootScope, $q, $http, $timeout, $uibModal, Hubs) {
  window.CheckoutAddressCtrl = $scope; // Please don't fucking touch this!

  $scope.order = {};
  $scope.isUseShipAddress = true;
  $scope.isPickup = false;
  $scope.pickupPoints = [];
  $scope.shipAddresses = [];
  $scope.orderSavedBillAddress = null;
  $scope.formErrors = {};
  $scope.showShipAddressDropdown = false;
  $scope.showBillAddressDropdown = false;
  $scope.mailboxNoticeText = $translate.instant('errors.address_mailbox_notice');
  $scope.mailboxNoticePlacement = window.isMobile ? 'bottom-center' : 'top-center';
  $scope.isZipcodeInCurrentHub = true;
  $scope.canSafelyChangeHub = true; // true by default

  // blockUI.start();

  // Public methods

  $scope.isSameAddress = function(address1, address2) {
    if (address1 == null && address2 == null) {
      return true
    } else {
      if (address1 == null || address2 == null) { return false }
    }

    return address1.firstname == address2.firstname &&
        address1.lastname == address2.lastname &&
        address1.company == address2.company &&
        address1.address1 == address2.address1 &&
        address1.house_number == address2.house_number &&
        address1.zipcode == address2.zipcode &&
        address1.city == address2.city &&
        address1.phone == address2.phone;
  };

  $scope.shipAddressSelectChanged = function() {
    $scope.currentShipAddress = _.find($scope.preferenceAddresses, function(a) { return parseInt(a.id) == parseInt($scope.currentShipAddressId) });
    autoFillShipAddress($scope.currentShipAddress);
  };

  $scope.billAddressSelectChanged = function() {
    $scope.currentBillAddress = _.find($scope.preferenceAddresses, function(a) { return parseInt(a.id) == parseInt($scope.currentBillAddressId) });
    autoFillBillAddress($scope.currentBillAddress);
  };

  $scope.billingAddressCheckboxChanged = function() {
    if ($scope.isUseShipAddress) {
      var someKnownBirthDate = $scope.order.bill_address ? $scope.order.bill_address.birth_date : null;

      $scope.order.bill_address = angular.copy($scope.order.ship_address);

      if ($scope.order.bill_address && $scope.order.bill_address.birth_date == null && someKnownBirthDate)
        $scope.order.bill_address.birth_date = someKnownBirthDate;
    }
    $scope.checkIfZipcodeAllowed();
  };

  $scope.newBillAddress = function() {
    $scope.currentBillAddress = {};
  };

  $scope.newShipAddress = function() {
    $scope.currentShipAddress = {};
  };

  $scope.openMailboxNotice = function(elementName) {
    $scope.mailboxNoticeOpen = true;
    $scope.elementName = elementName;
    $timeout(function() {
      $scope.mailboxNoticeOpen = false;
    }, 5000)
  };

  $scope.checkIfZipcodeAllowed = function() {
    if ($scope.currentHub && $scope.order.bill_address) {
      var comparedZipcode = $scope.fetchShippingZipcode();

      if (!comparedZipcode) return;
      if ($scope.lastShippingZipcode == comparedZipcode) return;

      comparedZipcode = $scope.lastShippingZipcode = comparedZipcode.toString().trim();
      $scope.isZipcodeInCurrentHub = $scope.currentHub.zipcodes.indexOf(parseInt(comparedZipcode).toString()) > -1;

      // Check if the hub can be changed safely
      if (comparedZipcode.length > 3) {
        Hubs.fetchHubChangeStatus(comparedZipcode).then(function(response) {
          $scope.hubFromZipcode = response.hubFromZipcode;
          $scope.canSafelyChangeHub = response.canSafelyChangeHub;
          $scope.unavailableProducts = response.unavailableProducts;

          $scope.showZipcodeNotice = !$scope.canSafelyChangeHub
        });
      } else {
        $scope.showZipcodeNotice = false
      }
    } else $scope.isZipcodeInCurrentHub = false;
  };

  $scope.onUnsafeSubmit = function() {
    $scope.openUnsafeHubChangeModal()
  };

  $scope.openUnsafeHubChangeModal = function() {
    var unsafeHubChangeModal = $uibModal.open(
      {
        animation: true,
        size: 'md',
        templateUrl: sprintf('/ng/templates/hubs/unsafe_hub_change_modal.html?locale=%s', I18n.locale),
        windowClass: 'unsafe-hub-change-modal modal-rounded',
        controller: 'UnsafeHubChangeModalCtrl',
        scope: $scope
      });
  };

  $scope.fetchShippingZipcode = function() {
    if($scope.isUseShipAddress && $scope.order.bill_address) {
      return $scope.order.bill_address.zipcode
    } else if ($scope.order.ship_address) {
      return $scope.order.ship_address.zipcode
    }
  };

  $scope.onPickupSelected = function() {
    $scope.isPickup = true;
    if ($scope.currentHub) {
      $http.get(sprintf('/api/frontend/orders/can_safely_change_hub.json?hub_id=%s', $scope.currentHub.id)).then(function(response) {
        $scope.canSafelyChangeHub = response.data.is_safe;
        $scope.unavailableProducts = response.data.unavailable_products;
      })
    }
  };

  $scope.onShippingSelected = function() {
    $scope.isPickup = false;
    $scope.checkIfZipcodeAllowed();
  };

  // 'Mailbox' keywords watchers:
  // Possible input points for our keywords are
  // 'special instructions' text area and 'address1' fields

  var instructionsWatcher = $scope.$watch('specialInstructions', function(string) {
    onWatcherFired(string, 'special');
  });

  var shipAddressWatcher = $scope.$watch('order.ship_address.address1', function(string) {
    onWatcherFired(string, 'ship');
  });

  var billAddressWatcher = $scope.$watch('order.bill_address.address1', function(string) {
    onWatcherFired(string, 'bill');
  });

  var shipHouseNumberWatcher = $scope.$watch('order.ship_address.house_number', function(string) {
    onWatcherFired(string, 'ship.house_number');
  });

  var billHouseNumberWatcher = $scope.$watch('order.bill_address.house_number', function(string) {
    onWatcherFired(string, 'bill.house_number');
  });

  $rootScope.$on('hubs:zipcode_modal:submited', function(){
    $scope.fetchCurrentHub();
  });

  // Private methods

  function onWatcherFired(string, elementName) {
    if (isMailboxPresent(string)) {
      $scope.openMailboxNotice(elementName);
      // Unsubscribe all watchers once a notice has been displayed
      unsuscribeMailboxWatchers();
    }
  };

  function isMailboxPresent(string) {
    if (string) {
      var substrings = ['postfach', 'case postale', 'office box', 'mailbox'];
      return _(substrings).find(function(substring) {
        return string.toLowerCase().indexOf(substring) > -1;
      });

    } else return false
  };

  function unsuscribeMailboxWatchers() {
    instructionsWatcher();
    shipAddressWatcher();
    billAddressWatcher();
    shipHouseNumberWatcher();
    billHouseNumberWatcher();
  };

  function normalizeAddresses() {
    var address_attributes = ['firstname', 'lastname', 'title', 'company', 'zipcode', 'city', 'phone',
      'separate_street_name', 'separate_house_number', 'register_number'];

    if ($scope.order.bill_address) {
      $scope.order.bill_address = angular.extend(_.pick($scope.order.bill_address, address_attributes), {
        address1: $scope.order.bill_address.separate_street_name,
        house_number: $scope.order.bill_address.separate_house_number,
        birth_date: $scope.order.bill_address.birth_date ? new Date($scope.order.bill_address.birth_date) : null
      });
    }
    if ($scope.order.ship_address) {
      $scope.order.ship_address = angular.extend(_.pick($scope.order.ship_address, address_attributes), {
        address1: $scope.order.ship_address.separate_street_name,
        house_number: $scope.order.ship_address.separate_house_number,
        birth_date: $scope.order.ship_address.birth_date ? new Date($scope.order.ship_address.birth_date) : null
      });
    }

    $scope.isUseShipAddress = $scope.isSameAddress($scope.order.bill_address, $scope.order.ship_address);
  };

  function autoFillShipAddress(sourceAddress) {
    $scope.order.ship_address = sourceAddress;

    if ($scope.isUseShipAddress) {
      $scope.order.bill_address = $scope.order.ship_address;
    };

    //console.debug("autoFillShipAddress normalizeAddresses");
    normalizeAddresses();
  };

  function autoFillBillAddress(sourceAddress) {
    $scope.order.bill_address = sourceAddress;

    //console.debug("autoFillShipAddress normalizeAddresses");
    normalizeAddresses();
  };

  function buildAddressByUser(user) {
    return {
      firstname: user.first_name,
      lastname: user.last_name,
      phone: user.phone_no
    };
  };

  /**
   * Reads pickup point information from the page into a local array
   */
  function loadPickupPoints() {
    try {
      $scope.pickupPoints = _($('input.pickup-point-item')).map(function(el) {
        return {
          id: $(el).val(),
          name: $(el).next().text()
        }
      });
    } catch(e) { console.error(e) }

    if ($('input.pickup-point-item:checked').length > 0) {
      window.LegacyCheckoutAddress.selectPickupPoint($('input.pickup-point-item:checked').val());
    }
  };

  function loadOrder() {
    return $q(function(resolve, reject) {
      $http.get(apiUrl("/orders/current/address.json")).then(function(response) {
        $scope.order = response.data.order;
        $scope.user = response.data.user;
        resolve();
      });
    });
  };

  function constructor() {
    $scope.isLoading = true;
    $scope.isInitialising = true;

    var similarPreferredAddress;

    loadPickupPoints();

    // Load order and make initial assignments (shouldn't it be all part of loadOrder?)
    loadOrder().then(function() {
      // $scope.shipAddresses = $scope.user.ship_addresses;
      // $scope.billAddresses = $scope.user.bill_addresses;

      $scope.preferenceAddresses = $scope.user.preference_addresses;
      $scope.shipAddresses = _.filter($scope.user.preference_addresses, function(a) { return a.preferred_for == null || a.preferred_for == 'ship' });
      $scope.billAddresses = _.filter($scope.user.preference_addresses, function(a) { return a.preferred_for == null || a.preferred_for == 'bill' });

      $scope.smartPassAddress = $scope.shipAddresses.filter(function (address) { return address.is_smart_pass })[0];

      if (!$scope.order.ship_address) {
        $scope.order.ship_address = $scope.smartPassAddress || _.last($scope.preferenceAddresses);
      }

      if (!$scope.order.bill_address) {
        // console.debug(" no order's bill address");
        $scope.billAddressWasAssignedBefore = false;
        $scope.order.bill_address = _.last($scope.billAddresses)
      } else {
        $scope.billAddressWasAssignedBefore = true;
      }

      // Match selected addresses in the dropdowns, based on address similarity
      if ($scope.order.ship_address) {
        var similarPreferredShipAddress = _.find($scope.preferenceAddresses, function(address) {
          return $scope.isSameAddress($scope.order.ship_address, address);
        });

        // Use existing preference address
        if (similarPreferredShipAddress) {
          $timeout(function() {
            $scope.currentShipAddress = similarPreferredShipAddress;
            if ($scope.currentShipAddress.id) $scope.currentShipAddressId = $scope.currentShipAddress.id.toString();
          }, 100);
        }
      }

      if ($scope.order.bill_address) {
        var similarPreferredBillAddress = _.find($scope.preferenceAddresses, function(address) {
          return $scope.isSameAddress($scope.order.ship_address, address);
        });

        $timeout(function() {
          $scope.currentBillAddress = similarPreferredBillAddress;
          if ($scope.currentBillAddress && $scope.currentBillAddress.id) $scope.currentBillAddressId = $scope.currentBillAddress.id.toString();
        }, 100);
      } else {
        $scope.order.bill_address = buildAddressByUser($scope.user);
      }

      normalizeAddresses();

      if ($scope.shipAddresses.length != 0) {
        $scope.showShipAddressDropdown = true;
      }

      if ($scope.billAddresses.length != 0) {
        $scope.showBillAddressDropdown = true;
      }

      // Listen or pickup tab, to show a conditional popup if there's no billing address assigned
      $('#deliveryMethodTabs a[data-toggle="tab"]').on('show.bs.tab', function (e) {
        if ($(e.target).data('target').indexOf("delivery_method_pickup_tab") > -1
          && !$scope.billAddressWasAssignedBefore) {
          $('#pickup-form-address-popup').modal('show'); // Show the bill address popup for pickup orders
        }
      });

      // blockUI.stop();
    }).finally(function() {
      $scope.isLoading = false;
      $scope.isInitialising = false;
      Hubs.fetchCurrentHub().then(function(currentHub) {
        $scope.currentHub = currentHub;
        if ($scope.currentHub) $scope.checkIfZipcodeAllowed();
      });
    });
  }

  function destructor() {
    window.CheckoutAddressCtrl = null;
  }

  $scope.$on('$destroy', destructor);
  constructor();
}]);
