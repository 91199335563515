'use strict';

/**
 * Directive for making videos automatically fill parent containers based on default
 * dimensions and aspect ration.
 *
 * Example use case, can be saved a CMS-managed snippet:
 *
 * <code>
 *   <iframe adjustable-video width="{{ video.width }}" height="{{ video.height }}" data-default-width="560" data-default-height="315" src="https://www.youtube.com/embed/8ixMuJ_JUkY" frameborder="0" allowfullscreen></iframe>
 * </code>
 *
 */
angular.module('app')
  .directive('animatedGifPreview', function() {
    return {
      link: function($scope, $element, attributes) {
        var gif = $element.find('.animated-gif').attr('data-animation-url');
        var preview = $element.find('.animated-gif').attr('src');

        var gifElement = $element.find('.animated-gif');

        // Preload animation
        var animationCache = new Image();
        animationCache.src = gif;

        $element.on('mouseenter', function() {
          gifElement.attr('src', gif);
        })

        $element.on('mouseleave', function() {
          gifElement.attr('src', preview);
        })
      }
    }
  });
