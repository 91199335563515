import * as _ from 'underscore';

angular.module('app').controller('PermaboxDepositExplanationModalCtrl', ['$scope', '$uibModalInstance', 'order', function($scope, $uibModalInstance, order) {
  window.PermaboxDepositExplanationModalCtrl = $scope;

  function constructor() {

  }

  function destructor() {
    window.PermaboxDepositExplanationModalCtrl = null;
  }

  $scope.dismiss = function() {
    $uibModalInstance.dismiss()
  };

  $scope.$on('$destroy', destructor);
  constructor();
}]);