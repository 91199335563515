'use strict';

angular.module('app').controller('WeeklySmartPassCtrl', ['$scope', '$http', '$rootScope', '$uibModal', 'Alerts',
  function($scope, $http, $rootScope, $uibModal, Alerts) {
    window.WeeklySmartPassCtrl = $scope;

    $scope.purchasingSmartPass = $scope.purchasingSmartPass || false;
    $scope.premiumOnly = false;
    $scope.weeklyId = null;
    $scope.smartPassInCart = false;
    $scope.editingSmartPass = false;
    var weekliesEndPoint = '/api/frontend/weeklies/';
    var smartPassesEndPoint = '/api/frontend/smart_pass_subscriptions/available_smart_passes.json';

    $scope.purchaseSmartPass = function() {
      $scope.purchasingSmartPass = true;
      $scope.editingSmartPass = true;
      $scope.premiumOnly = false;
      $rootScope.currentUserPurchasingSmartPass = true;
      $rootScope.currentSmartPassLevel = 'premium';

      if (!$scope.weekly.purchasing_smart_pass) $scope.weekly.purchasing_smart_pass = {
        level: 'premium',
        duration: '6',
        zone: $scope.weekly.zone,
        address_id: $scope.weekly.address_id
      };

      $scope.premiumOnly = $scope.available_smart_passes[$scope.weekly.zone].basic.week_days.indexOf($scope.weekly.day_of_week) == -1;
      if ($scope.premiumOnly) setPremiumOnly()
    };

    $scope.changeType = function(type) {
      if($scope.weekly.purchasing_smart_pass.level != type){
        $scope.weekly.purchasing_smart_pass.level = type;
      }
    };

    var setPremiumOnly = function() {
      $scope.premiumOnly = true;
      $scope.changeType('premium');
    };

    // 'About SmartPass' MODAL
    var aboutModalTemplateSlug = 'about-smart-pass-modal';
    $scope.aboutTemplateUrl = '/api/frontend/cms_pages/' + aboutModalTemplateSlug + '/content';
    $scope.cancelTemplateUrl = '/ng/templates/smart_pass/smart_pass_cancel_modal.html?locale=' + I18n.locale;

    $scope.openAboutModal = function() {
      var aboutModalInstance = $uibModal.open(
        {
          animation: true,
          size: 'lg',
          templateUrl: $scope.aboutTemplateUrl,
          backdrop: true,
          windowClass: 'smart-pass-about-modal',
          controller: 'SmartPassAboutModal',
          scope: $scope
        }
      );
    };

    $scope.openCancelModal = function() {
      var cancelModalInstance = $uibModal.open(
        {
          animation: true,
          size: 'md',
          templateUrl: $scope.cancelTemplateUrl,
          windowClass: 'smart-pass-cancel-modal',
          controller: 'SmartPassCancelModal'
        }
      );
    };

    $scope.addToWeekly = function() {
      updateWeekly();
      $scope.editingSmartPass = false;
    };

    var updateWeekly = function() {
      $http.put(weekliesEndPoint + $scope.weekly.id + '.json', {weekly: $scope.weekly}).then(function(response) {
        $scope.weekly = response.data;
        $scope.smartPassInCart = !!$scope.weekly.purchasing_smart_pass;
        Alerts.success($translate.instant('smart_pass.alerts.settings_updated'));
      })
    };

    $scope.cancelSmartPass = function() {
      $scope.purchasingSmartPass = false;
      $scope.editingSmartPass = false;
      $rootScope.currentUserPurchasingSmartPass = false;
      $scope.weekly.purchasing_smart_pass = null;
      $scope.smartPassInCart = false;
      updateWeekly();
    };

    $scope.toggleEdit = function() {
      $scope.editingSmartPass = !$scope.editingSmartPass;
    };

    var loadResources = function() {
      $http.get(smartPassesEndPoint).then(function(response) {
        $scope.available_smart_passes = response.data;

        $http.get(weekliesEndPoint + $scope.weeklyId + '.json').then(function(response) {
          $scope.weekly = response.data;
          if ($scope.weekly.purchasing_smart_pass) {
            $scope.purchasingSmartPass = true;
            $scope.smartPassInCart = true;
          }
        })
      });
    };

    $scope.$watch('weeklyId', function() {
      loadResources();
      $scope.$watch('currentUserPurchasingSmartPass', function(purchasing) {
        if(purchasing) {
          $scope.purchaseSmartPass();
        }
      })
    });

    $rootScope.$watch('currentUserPurchasingSmartPass', function() {
      $rootScope.currentUserPurchasingSmartPass = $scope.purchasingSmartPass
    });
  }]);
