import * as _ from 'underscore';

'use strict';

angular.module('app').controller('SubscribePageSmartPassEditCtrl', ['$scope', '$http', '$rootScope', '$uibModal', 'PromotionsService', '$location', 'SmartPassService', '$q', 'UserService',
  function($scope, $http, $rootScope, $uibModal, PromotionsService, $location, SmartPassService, $q, UserService) {
    window.SubscribePageSmartPassEditCtrl = $scope;
    $scope.subscribePageCtrl = window.PaymentsSubscribePageCtrl;
    $scope.smartPassService = SmartPassService;
    $scope.payNow = $scope.subscribePageCtrl.payNow = false;
    $scope.status = null;
    $scope.canUpgrade = false;
    $scope.subscribePageCtrl.actionText = 'submit';
    $scope.userId = UserService.currentUser.id;

    function constructor() {
      if(UserService.isLoggedIn) {
        setSmartPassWatcher()
      } else {
        let token = $location.search().tptoken;
        UserService.authenticateByToken(token).then(response => {
          SmartPassService.loadSubscriptions()
          setSmartPassWatcher()
        }, e => $location.path('/'))
      }
    }

    function destructor() {
      window.SubscribePageSmartPassEditCtrl = null;
    }

    function loadSmartPassSubscription(forceReload) {
      forceReload = forceReload || false;

      return $q((resolve, reject) => {
        $scope.smartPassSubscription = _($scope.smartPassService.subscriptions).find(s => s.id === $scope.smartPassSubscriptionId);

        if (!forceReload && $scope.smartPassSubscription && $scope.smartPassSubscription.available_smart_passes) {
          resolve($scope.smartPassSubscription);
        } else {
          $scope.smartPassService
            .loadSubscriptions({all: true, available_plans: true})
            .then(result => {
              $scope.smartPassSubscription = _($scope.smartPassService.subscriptions).find(s => s.id === $scope.smartPassSubscriptionId);

              if ($scope.smartPassSubscription) resolve($scope.smartPassSubscription);
              else reject('Smartpass not found');
            });
        }
      });
    }

    function setSmartPassWatcher() {
      let smartPassWatcher = $scope.$watch('smartPassService.subscriptions', (subscriptions) => {
        smartPassWatcher();

        $scope.smartPassSubscriptionId = $scope.idFromParams || $scope.subscribePageCtrl.subscription.smart_pass_subscription_id

        loadSmartPassSubscription().then(() => {
          setStatus();

          if ($scope.status == 'no_paid_subscription')
            attachPaidSubscription()
        });
      }, true);
    }

    // Sets a general state.
    function setStatus() {
      if ($scope.smartPassSubscription.paid_subscription) {
        if ($scope.smartPassSubscription.paid_subscription.active) {
          $scope.status = 'active';

          if ($scope.smartPassSubscription.level == 'premium' && $scope.smartPassSubscription.duration.toString() == '12')
            $scope.canUpgrade = false;
          else
            $scope.canUpgrade = true;
        } else $scope.status = 'inactive'
      } else if ($scope.smartPassSubscription.state == 'active' && !$scope.smartPassSubscription.needs_renew) {
        $scope.status = 'no_paid_subscription'
      } else {
        $scope.subscribePageCtrl.templateUrl = '/ng/templates/paid_subscriptions/smart_pass_new.html'
      }
    }

    function reset() {
      $scope.onCancelUpgradeClicked();
      $scope.smartPassSubscriptionId = $scope.subscribePageCtrl.subscription.smart_pass_subscription_id;

      loadSmartPassSubscription().then(result => {
        setStatus();
      });

      $scope.setSubmitButton(true);
    }

    function attachPaidSubscription() {
      if (!$scope.smartPassSubscription) return;
      let total, price, payNow;

      if ($scope.smartPassSubscription.state == 'active' && !$scope.smartPassSubscription.needs_renew) {
        total = 0;
        price = parseFloat($scope.smartPassSubscription.price);
        payNow = false;
        $scope.subscribePageCtrl.actionText = 'revive';
      } else {
        total = parseFloat($scope.smartPassSubscription.price);
        price = total;
        payNow = true;
      }

      const smartPassSubscription = {
        id: $scope.smartPassSubscription.id,
        level: $scope.smartPassSubscription.level,
        duration: $scope.smartPassSubscription.duration,
        user_id: $scope.userId,
        order_id: $scope.orderId,
        price: price
      };

      $scope.subscribePageCtrl.subscription = $scope.subscribePageCtrl.subscription || {};

      $scope.subscribePageCtrl.subscription.totalPrice = total;
      $scope.subscribePageCtrl.subscription.amount = total;
      $scope.subscribePageCtrl.subscription.default_amount = price;

      $scope.subscribePageCtrl.subscription.nextPaymentDate = $scope.nextPaymentDate($scope.smartPassSubscription);
      $scope.subscribePageCtrl.subscription.nextPaymentAmount = price;

      $scope.subscribePageCtrl.subscription.source_parameters = {
        action: 'revive',
        smart_pass_subscription: smartPassSubscription,
        pay_now: payNow
      };

      $scope.setSubmitButton();
    }

    // #### Event handlers ####
    $scope.onUpgradeClicked = function() {
      $scope.isEditing = true;
      $scope.subscribePageCtrl.payNow = true;
    };

    $scope.onCancelUpgradeClicked = function() {
      $scope.isEditing = false;
      $scope.subscribePageCtrl.payNow = false;
      $scope.setSubmitButton(reset);
    };

    $scope.onSelectedAddressChanged = function(selectedAddress) {
      selectedAddress = selectedAddress || null;

      if (!selectedAddress || $scope.spId) return;

      $scope.selectedAddress = selectedAddress;
      $scope.zipcode = $scope.selectedAddress.zipcode;

      if ($scope.zipcode && $scope.zipcode.length == 4 && $scope.zipcode.match(/^\d+$/)) {
        PromotionsService.getPromoFromZipcode($scope.zipcode).then((response) => {
          if (response.marketing_zone) {
            $scope.marketingZone = response.marketing_zone;
            $scope.selectedZipcode = $scope.zipcode;

            // if($scope.zones[$scope.marketingZone])
            //   $scope.onSpSelectionChanged();
          }
          $scope.setSubmitButton()
        })
      } else {
        $scope.setSubmitButton()
      }
    };

    $scope.onSpSelectionChanged = function(selectedSmartPass) {
      $scope.selectedSubscription = selectedSmartPass;

      if (!$scope.selectedSubscription) {
        $scope.selectedSubscription = null;
        $scope.setSubmitButton(true);
        return;
      }

      // In "upgrade" scenarios, the total to pay might differ from the original price.
      let total = parseFloat($scope.selectedSubscription.total);
      let price = parseFloat($scope.selectedSubscription.price);

      $scope.subscribePageCtrl.subscription.totalPrice = total;
      $scope.subscribePageCtrl.subscription.nextPaymentDate = $scope.nextPaymentDate($scope.selectedSubscription);
      $scope.subscribePageCtrl.subscription.nextPaymentAmount = price;

      $scope.subscribePageCtrl.subscription = $scope.subscribePageCtrl.subscription || {};
      $scope.subscribePageCtrl.subscription.default_amount = price;
      $scope.subscribePageCtrl.subscription.amount = total;

      const smartPassSubscription = {
        id: $scope.smartPassSubscription.id,
        level: $scope.selectedSubscription.level,
        duration: $scope.selectedSubscription.duration,
        user_id: $scope.userId,
        order_id: $scope.orderId,
        price: total
      };

      $scope.subscribePageCtrl.subscription.source_parameters = {
        action: 'upgrade',
        smart_pass_subscription: smartPassSubscription,
        pay_now: true
      };

      $scope.setSubmitButton();
    };


    // #### Getters ####
    $scope.nextPaymentDate = function(smartPassSubscription) {
      return moment().add(parseInt(smartPassSubscription.duration), 'months').toDate()
    };

    // #### Setters ####
    $scope.setSubmitButton = function(reset) {
      reset = reset || null;

      if (reset || ($scope.status != 'no_paid_subscription' && !$scope.isEditing)) {
        $scope.subscribePageCtrl.showSubmit = false;
        $scope.subscribePageCtrl.enableSubmit = false;
        return;
      }

      if ($scope.status == 'no_paid_subscription' || $scope.selectedSubscription.total > 0){
        $scope.subscribePageCtrl.showSubmit = true;
        $scope.subscribePageCtrl.enableSubmit = true;
      }
    };

    $scope.changeType = function(type) {
      if($scope.spType != type){
        $scope.spType = type;
        $scope.onSpSelectionChanged();
      }
    };

    $scope.toggleRenewable = function() {
      $scope.subscribePageCtrl.toggleRenewable().then(response => {
        // loadSmartPassSubscription(true);
        $scope.smartPassSubscription = response.smart_pass_subscription;
      })
    };

    function onSuccessfulPayment() {
      if ($scope.status != 'no_paid_subscription')
        reset()
    }
    $scope.subscribePageCtrl.onSuccessfulPayment = onSuccessfulPayment;

    // Init
    constructor();
    $scope.$on('destroy', destructor)
  }]);
