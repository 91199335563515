// Borrowed from http://embed.plnkr.co/8LDJXj/

angular.module('app').filter('replaceText', [function () {

  return function (input, from, to) {

    if(input === undefined) {
      return;
    }

    var regex = new RegExp(from, 'g');
    return input.replace(regex, to).trim();
  };


}]);