import * as _ from 'underscore';

/**
 * @desc One-line address form. Parses plain text into a Farmy-compatible address.
 * Includes Google Autocomplete (along with session token to contain cost).
 * Google Autocomplete is limited to Switzerland.
 *
 * If Google couldn't find a match, the custom parser will still do the job.
 *
 * @param boolean showSelector => bool expression: additionally loads the preference-address-selector.
 * @param initialAddress => object.
 * @param onChange => function: if defined, returns a payload to the parent => { address: addressObject, isValid: boolean }
 * @param validates => string: comma-separated list of keys whose absence will yield payload.isValid 'false' and trigger a warning.
 * @param initialStrAddress => string: to write in the input when init.
 *
 * @example <farmy-one-line-address-form
 *             show-selector="true"
 *             initial-address="initialAddress"
 *             on-change="onCurrentAddressChanged"
 *             validates="zipcode,city,house_number">
 *          </farmy-one-line-address-form>
 *
 */
angular.module('app').directive('farmyOneLineAddressForm', () => {
  return {
    controller: ['$rootScope', '$scope', '$http', '$q', '$timeout', '$element', 'UserService', function ($rootScope, $scope, $http, $q, $timeout, $element, UserService) {
      window.farmyOneLineAddressForm = $scope;
      $scope.rawInput = '';
      $scope.showPredictions = false;

      function constructor() {
        setupGoogleLibrary();
        if (!window.cities) getCities();
        if (!window.zipcodes) getZipcodes();

        if($scope.showSelector)
          $scope.loadPreferredAddresses();

        if (!UserService.currentUserId) {
          UserService.showLoginScreen();
        }

        if ($scope.initialStrAddress) {
          $scope.rawInput = angular.copy($scope.initialStrAddress);
        }

        getGoogleAutocompleteService();
      }

      function destructor() {
        window.farmyOneLineAddressForm = null;
      }

      // Preferred addresses to inject in the address selector directive.
      $scope.loadPreferredAddresses = function() {
        $http.get('/api/users/current.json').then((response) => {
          if (response.data.preference_addresses && response.data.preference_addresses.length > 0) {
            $scope.preferredAddresses = _.chain(response.data.preference_addresses).sortBy(address => {
              if (address.updated_at)
                return moment(address.updated_at).unix();
              else if (address.created_at)
                return moment(address.created_at).unix();
              return 0
            }).reverse().value();

            if($scope.preferredAddresses[0])
              $scope.selectedAddress = angular.extend({}, $scope.preferredAddresses[0]);

            if ($scope.selectedAddress && $scope.selectedAddress.id) {
              $scope.address = angular.extend({}, $scope.preferredAddresses[0]);

              // Valid by default when from the selector.
              $scope.isAddressValid = true;

              // Reset the ID, no longer needed for the _edited_ address:
              $scope.selectedAddress.id = null;
            }

            $scope.onChangeCallback();
          } else
            $scope.showSelector = false;
        })
      };

      $scope.onRawFormBlur = function() {
        $timeout(() => {
          $scope.isEditing = false;
          $scope.showPredictions = false;
        }, 200);
      };

      $scope.buildQueryFromAddres = (address) => {
        return `${address.address1} ${address.house_number}, ${address.zipcode} ${address.city}`
      }

      $scope.onUserInput = function(event) {
        $scope.validationErrorItems = null;

        if (event.keyCode == 13) {
          if ($scope.showPredictions)
            $scope.selectCurrentPrediction();

          blurRawForm();
          $scope.onRawFormBlur();
        } else if (event.keyCode == 38 && $scope.showPredictions) { // up
          event.preventDefault();
          $scope.highlightPreviousPrediction();
        } else if (event.keyCode == 40 && $scope.showPredictions) { // down
          event.preventDefault();
          $scope.highlightNextPrediction();
        } else if ($scope.rawInput.length > 5 && $scope.previousInput != $scope.rawInput) {
          $scope.previousInput = $scope.rawInput;

          $timeout.cancel($scope.parseTimeout);
          $scope.parseTimeout = $timeout(() => {
            $scope.parseInput();
          }, 500)
        } else if ($scope.rawInput.length < 6 && $scope.previousInput != $scope.rawInput) {
          $scope.previousInput = $scope.rawInput;
          resetAddress();
        }
      };

      $scope.onChangeCallback = function() {
        if ($scope.hasCallbackFunction) {
          let payload = {
            address: $scope.selectedAddress,
            isValid: $scope.isAddressValid
          }

          $scope.onChange()(payload)
        }
      };

      $scope.highlightNextPrediction = function() {
        if ($scope.predictionIndex < $scope.predictions.length - 1)
          $scope.predictionIndex++;
      };

      $scope.highlightPreviousPrediction = function() {
        if ($scope.predictionIndex > 0)
          $scope.predictionIndex--;
      };

      $scope.onPredictionHovered = function(index) {
        $scope.predictionIndex = index;
      };

      $scope.onPredictionSelected = function(index) {
        $scope.currentPrediction = $scope.predictions[index];

        $scope.rawInput = $scope.currentPrediction.fullAddress;

        $scope.parseAddressFromCurrentPrediction()
      };

      $scope.selectCurrentPrediction = function() {
        $scope.onPredictionSelected($scope.predictionIndex)
      };

      $scope.parseAddressFromCurrentPrediction = function() {
        if(!$scope.currentPrediction) return;

        let request = {
          placeId: $scope.currentPrediction.place_id
        };

        $scope.placesService.getDetails(request, (place, status) => {
          if (status == google.maps.places.PlacesServiceStatus.OK) {
            place.extractAttr = function(type) {
              let addressComponents = _(place.address_components).find((c) => c.types.includes(type))
              return addressComponents ? addressComponents.long_name : null;
            };

            $scope.rawInput = $scope.currentPrediction.fullAddress;

            fillCommonAddressParameters()

            $scope.selectedAddress.address1 = place.extractAttr('route');
            $scope.selectedAddress.house_number = place.extractAttr('street_number');
            $scope.selectedAddress.zipcode = place.extractAttr('postal_code');
            $scope.selectedAddress.city = place.extractAttr('locality');
            $scope.isAddressValid = false;

            getValidationErrors();

            $scope.onChangeCallback();
          }
        })
      };

      // Custom parsing => if google service is unavailable or yields no results.
      $scope.parseRawInput = function() {
        if (!$scope.rawInput || $scope.rawInput.length < 4)
          return;

        fillCommonAddressParameters();

        $scope.selectedAddress.zipcode = extractZipcode();
        $scope.selectedAddress.city = capitalize(extractCity());

        let restOfAddress = cleanRawInput().replace(extractZipcode(), '').replace(extractCity().toLowerCase(), '').replace(/ +/, ' ');
        $scope.selectedAddress.address1 = capitalize(extractAddress1(restOfAddress));
        $scope.selectedAddress.house_number = extractHouseNumber(restOfAddress);
        $scope.isAddressValid = false;

        getValidationErrors();

        $scope.onChangeCallback();
      };

      $scope.parseInput = function() {
        cancelValidationError();
        getGoogleAutocompleteService();

        if ($scope.rawInput.length > 0) {
          if ($scope.autocompleteService && $scope.placesSessionToken)
            $scope.addressAutoComplete();
          else
            $scope.parseRawInput();
        } else {
          $scope.selectedAddress = {};
        }

        $scope.onChangeCallback();
        $scope.parsedOutput = $scope.rawInput.length > 0 ? $scope.rawInput : "&nbsp;";
      };

      $scope.addressAutoComplete = function() {
        if (!$scope.autocompleteService || !$scope.placesSessionToken)
          return;

        if (!$scope.rawInput || $scope.rawInput.length == 0)
          return;

        getGooglePredictions().then(() => {
          if ($scope.predictions.length > 0) {
            $scope.showPredictions = true;
          } else {
            $scope.parseRawInput();
            $scope.showPredictions = false;
          }
        });
      };

      $scope.onSelectedAddressChanged = function(address) {
        $scope.selectedAddress = address;
        $scope.isAddressValid = true;

        if ($scope.selectedAddress.isNew)
          focusInput();

        if ($scope.rawInput.length > 4)
          $scope.parseInput();

        $scope.onChangeCallback()
      }

      // Private members
      function focusInput() {
        $timeout(() => {
          let inputElement = $('.one-line-address-form-wrapper form input');
          inputElement.focus()
        }, 500)
      }

      function fillCommonAddressParameters() {
        $scope.selectedAddress.user_id = UserService.currentUserId;
        $scope.selectedAddress.firstname = UserService.currentUser.first_name;
        $scope.selectedAddress.lastname = UserService.currentUser.last_name;
        $scope.selectedAddress.phone = UserService.currentUser.phone_no;

        $scope.selectedAddress.preference = $scope.preference;
      }

      function getGooglePredictions() {
        return $q((resolve, reject) => {
          $scope.predictions = [];
          $scope.predictionIndex = 0;

          $scope.autocompleteService.getPlacePredictions({
            input: $scope.rawInput,
            sessionToken: $scope.placesSessionToken,
            componentRestrictions: {country: ['ch', 'li']}
          }, (predictions, status) => {
            $scope.predictions = status == google.maps.places.PlacesServiceStatus.OK ? predictions : [];
            if ($scope.predictions.length > 0) {
              _($scope.predictions).each((prediction) => {
                let addressComponents = _(prediction.terms).map(p => p.value);
                addressComponents.pop();
                prediction.fullAddress = addressComponents.join(', ')
              })
            }

            resolve($scope.predictions);
          });
        });
      }

      function resetPredictions() {
        $scope.predictions = [];
        $scope.showPredictions = false;
      }

      // Attempts to load google autocomplete service, if not present.
      function getGoogleAutocompleteService() {
        if (!$scope.autocompleteService) {
          if (window.google && window.google.maps) {
            $scope.autocompleteService = new google.maps.places.AutocompleteService();
            $scope.placesService = new google.maps.places.PlacesService(document.createElement('div'));
          }
        }

        if (!$scope.placesSessionToken)
          $scope.placesSessionToken = $scope.autocompleteService ? new google.maps.places.AutocompleteSessionToken() : null;
      }

      function blurRawForm() {
        $('#raw-input').blur();
        $scope.isEditing = false;
      }

      function getValidationErrors() {
        cancelValidationError();
        if (!$scope.validateKeys || $scope.validateKeys.length == 0) {
          $scope.isAddressValid = true;
          return
        }

        let missingKeys = _($scope.validateKeys).select(key => !$scope.selectedAddress[key] || $scope.selectedAddress[key] == '')

        if (missingKeys.length > 0) {
          $scope.validationErrorItems = missingKeys;
          $scope.showValidationMessage = true;
          $scope.isAddressValid = false;
        } else
          $scope.isAddressValid = true;
      }

      function cancelValidationError() {
        if ($scope.errorTimeOut) $timeout.cancel($scope.errorTimeOut);
        $scope.validationErrorItems = null;
        $scope.showValidationMessage = false;
      }

      function capitalize(string) {
        if (typeof string !== 'string') return '';
        return _(string.split(' ')).map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
      }

      function extractAddress1(restOfAddress) {
        let extractWordsWithNoNumbers = new RegExp(/\b[^\d\W]+\b/g);
        let address1Parts = restOfAddress.match(extractWordsWithNoNumbers);
        return address1Parts.join(' ');
      }

      function extractHouseNumber(restOfAddress) {
        let extractWordsWithNoNumbers = new RegExp(/\b[^\d\W]+\b/g);
        let address1Parts = restOfAddress.match(extractWordsWithNoNumbers);

        let numberParts = _(restOfAddress.replace(new RegExp(`${address1Parts.join('|')}|`, 'g'), '').split(' ')).compact()
        return numberParts.join(', ')
      }

      function extractZipcode() {
        let foundZipcodes = cleanRawInput().match(new RegExp(`^${window.zipcodes.join('|')}$`));

        if (foundZipcodes)
          return foundZipcodes[0];
        else return '';
      }

      function extractCity() {
        let foundCities = cleanRawInput().match(new RegExp(`^${window.cities.join('|')}$`));

        if (foundCities)
          return capitalize(_(foundCities).max(c => c.length));
        else return '';
      }

      function cleanRawInput() {
        return $scope.rawInput.toLowerCase().replace(/-|_|,/, ' ');
      }

      function resetAddress() {
        $scope.selectedAddress = {};
        cancelValidationError();
        $scope.onChangeCallback();
      }

      // get zipcodes by api call
      function getZipcodes() {
        $http.get('/api/frontend/addresses/zipcodes.json').then(function(response) {
          window.zipcodes = response.data;
        });
      }
      // get cities by api call
      function getCities() {
        $http.get('/api/frontend/addresses/cities.json').then(function(response) {
          window.cities = response.data;
        });
      }
      // setup google library
      function setupGoogleLibrary() {
        if (!window.google || !window.google.maps) {
          window.loadGoogleMaps('AIzaSyAONcNogX2lpAxPWWH2cT5qOYw7CkvvQ_0', "&components=country:CH|country:LI");
        }
      }

      constructor();
      $scope.$on('$destroy', destructor);
    }],
    scope: {
      preference: '=',
      showSelector: '=',
      onChange: '&',
      resetInput: '&',
      validates: '@',
      initialAddress: '=',
      initialStrAddress: '@'
    },
    link: function($scope, $element, attributes) {
      // Since "ngChange" is inside the declared scope, it will always return a function.
      // This way we confirm that a callback function actually lives inside.
      if (attributes.onChange)
        $scope.hasCallbackFunction = true;

      if (attributes.validates)
        $scope.validateKeys = attributes.validates.replace(' ', '').split(',')

      $scope.parsedForm = document.getElementById('parsed-input-inner');
      $scope.rawForm = document.getElementById('raw-input');
    },
    templateUrl: '/ng/templates/addresses/one_line_form.html'
  }
});
