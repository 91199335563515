import React from 'react';
import {Link as ReactLInk, useHistory, useLocation} from 'react-router-dom';
import {Image} from 'react-bootstrap';

// useHistory =>
// useLocation =>

export const useRouter = (pageNamespace = '', dynamicQueryName = '') => {
  // const location = useLocation();
  // const history = useHistory();

  const pathname = location.pathname.replace(pageNamespace, '');
  // const push = history.push;

  const asPath = `${location.pathname}${location.search}`;

  const query = () => {
    let result = {};

    if (dynamicQueryName.length)
      result[dynamicQueryName] = pathname;

    location
      .search
      .replace('?', '')
      .split('&')
      .forEach( i => {
        const [key, val] = i.split('=');
        if (key?.length) result[key] = encodeURIComponent(val);
      });

    return result
  };

  return {
    pathname,
    // push,
    asPath,
    query: query()
  }
};

// Allows the use of 'href' in the Link component, to follow Next.js standard.
export const Link = (props) => {
  const linkProps = props.abs ? props : {...props, to: props.href};

  return props.abs ?
    <a {...linkProps}>{props.children}</a> :
    <ReactLInk {...linkProps}>{props.children}</ReactLInk>;
};

// Farmy image:
// Returns an Image component with the absolute path (/resources/<storefront>/images/...)
const storefront = window.currentStorefront || 'farmy';
const useAssetsPath = (path) => {
  return `/resources/${storefront || 'farmy'}/images/${path}`;
};

export const FarmyImage = (props) => {
  const realPath = useAssetsPath(props.src);

  const src = props.abs ? props.src : realPath;
  const newProps = {...props, src};

  if (props.freesize?.toString() !== 'true') {
    const parentRatio =
    newProps.className = `${newProps.className ? newProps.className + ' ' : ''}full-max-size`;
  }

  // if (!props.width || !props.height) {
  //   newProps.width = props.width || props.height || 250;
  //   newProps.height = props.height || props.width || 250;
  // }

  return <Image {...newProps}/>;
};
