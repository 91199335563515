import * as _ from 'underscore';

/**
 * The filter sidebar controller for the catalog section takes care of the part of the filters logic.
 * Filters are legacy components with some pieces of jQuery code still active. This controller tries
 * to bind together the old parts with some new Angular logic. Because much filter data, including templates,
 * is server-generated, we cannot use normal angular data bindings for taxons and filter values here and there's
 * lots of dirty UI-related code.
 */
angular.module('app').controller('CatalogFilterCtrl', ['$scope', '$rootScope', '$element', '$timeout', '$compile', '$window', '$http', 'Alerts', 'ProductNavigation', 'CatalogServiceHelper', '$location', '$uibModal',
  function ($scope, $rootScope, $element, $timeout, $compile, $window, $http, Alerts, ProductNavigation, CatalogServiceHelper, $location, $uibModal) {
    // console.log("CatalogFilterCtrl controller initialized");
    window.CatalogFilterCtrl = $scope;

    $scope.$element = $element;

    $scope.filterSelections = { };

    //
    // Private
    //
    $scope.urlFilterParameters = {};

    function constructor() {
      // Initialize constraints:
      if ($element.data('supplier-id')) {
        // TODO: Hide the supplier filter and constraint filters to a certain supplier id
        $scope.constraints = $scope.constraints || {};
        $scope.constraints.supplierId = $element.data('supplier-id')
      }

      if ($element.data('taxon-id')) {
        // TODO: Hide the taxon filter and constraint filters to a certain taxon id
        $scope.constraints = $scope.constraints || {};
        $scope.constraints.taxonId = $element.data('taxon-id')
      }

      $scope.$watch('filters', (newValue) => {
        _.each(newValue, (f) => {
          if ($scope.filterSelections[f.search_param] == null) {
            $scope.filterSelections[f.search_param] = {};
          }
        })
      });

      // Attempt read URL parameters, if present
      if (!_.isEmpty($location.search())) {
        var queryFilterParams = CatalogServiceHelper.deserializeUrlParamsToFilterSelection($location.search());
        // console.log("Decoded filters from URL (urlFilterParameters): ", queryFilterParams)
        $scope.urlFilterParameters = queryFilterParams;
        angular.extend($scope.filterSelections, queryFilterParams);
        $rootScope.$broadcast('catalog:filters:queryloaded', { selections: $scope.filterSelections })
      }

      $timeout(() => { CatalogServiceHelper.onFilterSelectionChange() });
    }

    function destructor() {
      window.CatalogFilterCtrl = null;
    }

    //
    // Public
    //

    $scope.getSelection = function() {
      var params = {};

      for(var filter in $scope.filterSelections) {
        var selected = _.chain($scope.filterSelections[filter]).map((checked, key) => checked ? key : null).compact().value();

        if (selected.length > 0) {
          params[filter] = selected;
        }
      }

      // Master filters are applied separately on top of the actual selection
      let masterFilters = CatalogServiceHelper.getMasterFilterSelection();

      for(var filter in masterFilters) {
        if (params[filter] != null)
          params[filter] = params[filter].concat(masterFilters[filter]);
        else
          params[filter] = angular.copy(masterFilters[filter]);
      }

      return params;
    };

    $scope.filtersEmpty = function() {
      return $scope.filters == null;
    };

    /**
     * Filters must be updated through this method, rather than writing directly to the scope.
     * A direct write will cause a massive DOM-event listener overwrite (thousands at once), which
     * is rather slow in most browsers, even Chrome. Instead, filters and values are immutably
     * updated, preserving references.
     *
     * @param newFilters
     */
    $scope.updateFilters = function(newFilters) {
      if ($scope.filters == null) {
        $scope.filters = newFilters;
      } else {
        _.each(newFilters, (updatedFilter) => {
          var existingFilter = _.find($scope.filters, f => f.search_param == updatedFilter.search_param);

          if (existingFilter) {
            // Update each individual value in the filter data
            // This complex updated is needed to avoid recreating watches in the angular ng-repeat collection
            for(var i in existingFilter.values) {
              var updatedValue = _.find(updatedFilter.values, v => v.orig_value == existingFilter.values[i].orig_value);
              if (updatedValue) existingFilter.values[i] = angular.extend(existingFilter.values[i], updatedValue);
            }
          } else {
            $scope.filters.push(updatedFilter);
          }
        })
      }

      // TODO: Update existing filters value facets
      // TODO: Remove filters that are no longer present
    };

    /**
     * Returns the scope of a filter controller by its search param name
     *
     * @param searchParam
     * @returns {*}
     */
    $scope.getFilterScope = function(searchParam) {
      return window['catalogFilter_' + searchParam];
    };

    // Initialization

    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
