import {trackPageView} from '../services/tracking-helper';

angular.module('app').controller('SupportContactFormPageCtrl', ['$scope', function ($scope) {
    window.SupportContactFormPageCtrl = $scope;

    function constructor() {
        trackPageView('contact');
    }

    function destructor() {
        window.SupportContactFormPageCtrl = null;
    }

    $scope.$on('$destroy', destructor);

    constructor();
}]);