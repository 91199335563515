import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app')
  .directive('farmyCheckoutDebugTool', function() {
    return {
      controller: ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', 'Alerts', 'blockUI', function ($scope, $sce, $http, $q, $rootScope, $timeout, Alerts, blockUI) {
        window.CheckoutDebugTool = $scope;

        $scope.checkoutCtrl = window.CheckoutCtrl;

        function constructor() {
          // Special case for the static 'thankyou' page, where's there's no CheckoutCtrl
          $timeout(function() {
            if ($scope.checkoutCtrl == null && $scope.checkout == null) {
              // Load order directly
              $http.get(`/api/orders/${window.checkoutOrderNumber}.json`).then((r) => {
                if ($scope.checkoutCtrl == null)
                  $scope.checkoutCtrl = {};

                $scope.checkout = r.data
                $scope.checkoutCtrl.checkout = $scope.checkout;

              })

              // Load user profile directly
              $http.get('/api/users/current.json').then(function(response) {
                $scope.user = response.data;
              })
            }
          }, 1000);
        }

        function destructor() {
          window.CheckoutDebugTool = null;
        }

        // Public members

        // Initialize

        $scope.$on('$destroy', destructor)
        constructor()
      }],
      scope: {
        checkout: '=',
        user: '=',
      },
      link: function($scope, $element, attributes) {

      },
      template: `
<div class="well">
    <h5>Checkout debug info panel</h5>
    <table class="table table-hover table-bordered" style="font-size: 11px;">
        <tr>
            <th>Number</th>
            <td>{{ checkout.number }}</td>
            <th>State</th>
            <td>{{ checkout.state }}</td>
        </tr>
        
        <tr>
            <th>checkoutCtrl.stage</th>
            <td>{{ checkoutCtrl.stage }}</td>
            <th>checkout.stage</th>
            <td>{{ checkout.checkout_stage }}</td>
        </tr>
        
        <tr>
            <th>Checkout.ship_address</th>
            <td ng-if="checkout.ship_address">[{{ checkout.ship_address.id }}] {{ checkout.ship_address.zipcode }}, {{ checkout.ship_address.city }}, {{ checkout.ship_address.address1 }}, {{ checkout.ship_address.house_number }}</td>
            <td ng-if="!checkout.ship_address">–</td>
            <th>Checkout.shipping_method</th>
            <td>[{{ checkout.shipping_method_id }}] {{ checkoutCtrl.shippingMethod.name }}</td>
        </tr>

        <tr>
            <th>Checkout.delivery_mode</th>
            <td>{{ checkout.delivery_mode }}</td>
            <th>–</th>
            <td>–</td>
        </tr>
        
        <tr>
            <th>Checkout.pickupPoint</th>
            <td>{{ checkoutCtrl.pickupPoint.name }}</td>
            <th>Checkout.pickup_point_id</th>
            <td>{{ checkout.pickup_point_id }}</td>
        </tr>
                
        <tr>
            <th>Order.delivery_slot</th>
            <td>{{ checkout.delivery_slot.id }}</td>
            <th>Current Delivery slot</th>
            <td>{{ checkoutCtrl.deliverySlot.label }}</td>
        </tr>
        
        <tr>
            <th>Item total</th>
            <td>{{ checkout.item_total }}</td>
            <th>Total</th>
            <td>{{ checkout.total }}</td>
        </tr>
        
        <tr>
            <th>Ship total</th>
            <td>{{ checkout.ship_total }}</td>
            <th>Discount Total</th>
            <td>{{ checkout.discount_total }}</td>
        </tr>
        
        <tr>
            <th>Prepaid:</th>
            <td>{{ checkout.payment_total }}</td>
            <th></th>
            <td></td>
        </tr>
                
        <tr>
            <th colspan="4">User Account</th>
        </tr>
        
        <tr>
            <th>ID</th>
            <td>{{ user.id }}</td>
            <th>Email</th>
            <td>{{ user.email }}</td>
        </tr>
        
        <tr>
            <th>Complete Orders</th>
            <td>{{ user.complete_order_count }}</td>
            <th>Saved cards:</th>
            <td>{{ user.saved_cards.length }}</td>
        </tr>
        
        <tr>
            <th>Guthaben CHF:</th>
            <td>{{ user.account.balance | number:2 }}</td>
            <th>Points Eggs:</th>
            <td>{{ user.account.points | number }}</td>
        </tr>
    </table>    
</div>`
    }
  });


