angular.module('app').directive('mobileFooter', ['$window', '$document', '$timeout', '$interval', function ($window, $document, $timeout, $interval) {
  return {
    restrict: 'C',
    scope: {
      footerElementId: '@',
      additionalPadding: '@',
      debounceWait: '@'
    },
    link: function ($scope, $element, $attrs) {
      var updateInterval;

      function onWindowResize() {
        var height = $('.mobile-footer').height();
        var offset = $('.mobile-footer').offset();

        if (offset.top + height + 5< window.innerHeight) {
          $scope.$apply(() => { $element.css({opacity: 0}) });
        } else {
          $scope.$apply(() => {  $element.css({opacity: 1}) });
        }
      }

      $scope.$on('$destroy', function() {
        if (updateInterval)
          $interval.cancel(updateInterval);
      });

      $window.$on('resize', onWindowResize);

      onWindowResize();

      // Just defer once to fix random layout issues
      setTimeout(onWindowResize, 2500);
    }
  };
}]);

