import * as _ from 'underscore';

/**
 * Service for reading and ediging the current user's SmartPasses, free delivery and product promotions
 * IMPORTANT: this service does not automatically load subscriptons
 */
angular.module('app').service('PromotionsService', ['$rootScope', '$window', '$q', '$http', '$localStorage', '$timeout', 'FarmyNotifications', ($rootScope, $window, $q, $http, $localStorage, $timeout, FarmyNotifications) => {
  var $scope = this || {};
  window.PromotionsService = $scope;

  $scope.currentPromotion = $scope.currentPromotion || null;
  $localStorage.freeProductNotificationShown = $localStorage.freeProductNotificationShown || false;

  // Watch zipcode changes to update available promo
  $rootScope.$on('react:zipcode:changed', (response, {zipcode}) => {
    if (zipcode !== window.currentZipcode) {
      loadAvailablePromotion($scope.currentZipcode);

      $localStorage.freeProductNotificationShown = false;
      $scope.loadFreeProductPromos(true);
    }
  });

  // Watch the global currentOrderId: second order shouldn't display 'No minimum order value'
  $rootScope.$watch(()=> { return $window.currentOrderId }, ()=> {
    loadAvailablePromotion($scope.currentZipcode);
  });

  $rootScope.$on('user:authenticated', () => { $scope.loadFreeProductPromos(true) });
  $rootScope.$on('user:registered', () => { $scope.loadFreeProductPromos(true) });

  $rootScope.$on('$locationChangeSuccess', () => {
    if (!$localStorage.freeProductNotificationShown)
      // Timeout-ed to allow ReactivationLanding to be effectively destroyed
      $timeout(() => {
        $scope.loadFreeProductPromos(true)
      }, 1000);
  });

  // Sets current promotion based on zipcode
  var loadAvailablePromotion = function(zipcode) {
    zipcode = zipcode || window.currentZipcode;
    if (!zipcode) return;

    return $q((resolve, reject) => {
      $scope.getPromoFromZipcode(zipcode).then(response => {
        $scope.currentPromotion = response;
        $scope.hasPromo = response.has_promo;
        $rootScope.$broadcast('promo:updated', {promotion: $scope.currentPromotion});
      });
    });
  };

  // Returns available promotion for given zipcode
  // '' if no zipcode is available
  $scope.getPromoFromZipcode = function(zipcode) {
    return $q((resolve, reject) => {
      $http.get(`/hubs/promo_type_from_zipcode.json`, {params: {zipcode: zipcode}}).then(response => {
        resolve(response.data)
      })
    });
  };

  $scope.loadFreeProductPromos = function(createNotification) {
    createNotification = createNotification || false;

    if ($scope.hasLoadedFreeProductPromos || $scope.isLoadingFreeProducts || ($scope.freeProductPromos && $scope.hasFreeProductPromos))
      return;

    let orderId = window.currentOrderId || window.currentOrderNumber;
    let userId = window.currentUserId;

    if (!orderId || !userId)
      return;

    return $q((resolve, reject) => {
      if (window.isReactivationPage) return {};

      $scope.isLoadingFreeProducts = true;

      $http.get(`/api/frontend/orders/current_free_products.json?id=${orderId}&locale=${I18n.locale}`).then((response) => {
        $scope.hasFreeProductPromos = response.data.has_free_product_promos;
        $scope.freeProductPromos = response.data.free_product_promos;
        $scope.isLoadingFreeProducts = false;

        $scope.hasLoadedFreeProductPromos = true;

        if (createNotification && $scope.hasFreeProductPromos) $scope.createFreeProductNotifications()
      }).finally(() => { $scope.isLoadingFreeProducts = false })
    })
  };

  $scope.createFreeProductNotifications = function() {
    if ($localStorage.freeProductNotificationShown) return;

    _($scope.freeProductPromos).each((product) => {
      let notification = {
        style: 'free_product_promotion',
        contents: product,
      };

      FarmyNotifications.enqueue(notification).then(() => {
        console.log({notification: notification});
        $localStorage.freeProductNotificationShown = true
      })
    });
  };

  if (!$scope.currentPromotion)
    loadAvailablePromotion();

  return $scope;
}]);