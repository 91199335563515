import * as _ from 'underscore';

/**
 * @desc Wraps a subscription component (email only).
 *
 * This directive is just a wrapper. It makes the component reusable across multiple layouts.
 * It could have been a controller, but components are more in line with modern practices.
 *
 * Exposes:
 * @var email (string => to be used in ng-model) // Note: since "transclude" creates its own scope, "email" needs to be called as "$parent.email".
 * @function onSubmit() (to be used in ng-click) // No need to call $parent here (since the function is not defined in the current scope).
 *
 * @example <farmy-newsletter-form>
 *            <input type="text" ng-model="$parent.email" placeholder="Enter you email...">
 *            <button class="btn btn-primary" ng-click="onSubmit()">Submit</button>
 *          </farmy-newsletter-form>
 *
 */
angular.module('app').directive('farmyNewsletterForm', () => {
  return {
    transclude: true,
    controller: ['$rootScope', '$scope', '$http', '$q', '$element', 'Alerts', '$translate', function ($rootScope, $scope, $http, $q, $element, Alerts, $translate) {
      window.farmyNewsletterForm = $scope;
      // Before using it set the correct endpoint
      //$scope.apiEndpoint = '/api/frontend/subscribe_email.json';
      $scope.email = '';

      function constructor() {}

      function destructor() {
        window.farmyNewsletterForm = null;
      }

      $scope.onSubmit = function() {
        if ($scope.email && $scope.email.length > 4)
          subscribeEmail().then((response) => {
            if(response.data.success) {
              $scope.subscribed = true;
              Alerts.success($translate.instant('newsletter_subscribe.responses.success'))
            } else if (response.data.error && response.data.error == 'already_subscribed') {
              $scope.subscribed = true;
              Alerts.info($translate.instant('newsletter_subscribe.responses.already_subscribed'));
            }
            else
              Alerts.error($translate.instant('cannot_perform_operation'));
          })
      }

      function subscribeEmail() {
        return $q((resolve, reject) => {
          let params = { email: $scope.email };

          params.locale = I18n.locale || 'de';

          if (window.currentZipcode)
            params.zipcode = window.currentZipcode;

          $http.post($scope.apiEndpoint, params).then((response) => {
            resolve(response);
          }, (e) => reject(e))
        })
      }

      constructor();
      $scope.$on('$destroy', destructor);
    }],
    link: function($scope, $element, attributes) {
    },
    template: `<div class="farmy-newsletter-form" ng-transclude></div>`
  }
});
