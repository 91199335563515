// POJO global wrapper for analytics tracking (mainly for sending events to GA and internal Ahoy)
//
// See: https://developers.google.com/analytics/devguides/collection/analyticsjs/events
window.Tracking = {
  sendEvent: function(category, action, label, value) {
    return; // to test if even tracking affects collected transactions, we temporarily disable all GA events
    
    if (window.trackingOptOut)
      return;

    if (Rails.env == 'development')
      console.log("Tracking", "sendEvent", category, action, label, value);

    var params = {
      eventCategory: category,
      eventAction: action
    };

    if (label) params.eventLabel = label;
    if (value) params.eventValue = value;

    params.transport = 'beacon';

    if (window.ga) {
      ga('send', 'event', params);
    } else {
      setTimeout(function() {
        Tracking.sendEvent(category, action, label, value);
      }, 1000);
    }
  },

  // TODO Use safely.js
  addToCart: function(productId, productName, options, callback) {
    if (!options) options = {};

    // our custom add to cart event. Obsolete and can be removed in the future
    this.sendEvent('cart', 'added', productName, productId);

    // Google Analytics
    if (window.ga && options.googleAnalyticsProduct) {
      var googleAnalyticsProduct = options.googleAnalyticsProduct;
      ga('ec:addProduct', {
        'id': googleAnalyticsProduct.id,
        'name': googleAnalyticsProduct.name,
        'category': googleAnalyticsProduct.category,
        'brand': googleAnalyticsProduct.brand,
        'variant': googleAnalyticsProduct.variant, // TODO Use real variant
        'price': googleAnalyticsProduct.price,
        'quantity': '1'
      });
      ga('ec:setAction', 'add');
      ga('send', 'event', 'Ecommerce', 'Add to Cart', googleAnalyticsProduct.id, {
        hitCallback: function() {
          if (callback) { callback() };
        }
      });
    } else {
      if (callback) { callback() };
    }
  },

  // This is a standalone event because Piwik only requires CartData.total, which is not immediately available on 'AddToCart'
  piwikCartUpdate: function(total) {
    safely(function(){
      if (window._paq && total) {
        _paq.push(['trackEcommerceCartUpdate',
          total
        ])
      }
    })
  },

  sendCartAddRecipeEvent: function(productId, productName) {
    this.sendEvent('cart-recipe', 'added', productName, productId)
  },

  sendCartRemoveEvent: function(productId, productName) {
    this.sendEvent('cart', 'removed', productName, productId)
  },

  productClick: function(productId, options, callback) {
    safely(function() {
      // Google Analytics
      if (window.ga && options.googleAnalyticsProduct) {
        var googleAnalyticsProduct = options.googleAnalyticsProduct;
        ga('ec:addProduct', {
          'id': googleAnalyticsProduct.id,
          'name': googleAnalyticsProduct.name,
          'category': googleAnalyticsProduct.category,
          'brand': googleAnalyticsProduct.brand,
          'variant': '',
          'position': ''
        });
        ga('ec:setAction', 'click', { list: options.analyticsListName} );

        ga('send', 'event', 'Ecommerce', 'Click', googleAnalyticsProduct.id, {
          hitCallback: function() {
            if (callback) { callback() };
          }
        });
      } else {
        if (callback) { callback() };
      }
    });
  },

  productViewed: function(productId, productName, options) {
    if (!options) options = {};

    // our custom add to cart event. Obsolete and can be removed in the future
    this.sendEvent('product', 'viewed', productName, productId);

    // Google Analytics
    if (window.ga && options.googleAnalyticsProduct) {
      if (!CartData.containsProduct(productId)) {
        var googleAnalyticsProduct = options.googleAnalyticsProduct;
        ga('ec:addProduct', {
          'id': googleAnalyticsProduct.id,
          'name': googleAnalyticsProduct.name,
          'category': googleAnalyticsProduct.category,
          'brand': googleAnalyticsProduct.brand,
          'variant': googleAnalyticsProduct.variant
        });
        ga('ec:setAction', 'detail');
        ga('send', 'event', 'Ecommerce', options.actionLabel, googleAnalyticsProduct.id);
      }
    }
    // Piwik Analytics
    if (window._paq && options.googleAnalyticsProduct) {
      var product = options.googleAnalyticsProduct;
      _paq.push(['setEcommerceView',
        product.id,
        product.name,
        product.category,
        product.price
      ])
    }
  },

  productImpressions: function(options) {
    safely(function() {
      // Google Analytics
      if (window.ga && _.any(options.googleAnalyticsImpressions)) {
        var impressionsByList =_.groupBy(options.googleAnalyticsImpressions, function(impression) {
          return impression.list;
        });

        _.each(impressionsByList, function(listImpressions, listName) {
          _.each(listImpressions, function(googleAnalyticsImpression) {
            if (!false) {
              ga('ec:addImpression', googleAnalyticsImpression);
            }
          });

          ga('send', 'event', 'Ecommerce', 'Product Impressions', listName, { 'nonInteraction': 1 });
        });
      }
    });
  },

  productListModeChange: function(mode) {
    this.sendEvent('catalog', 'viewModeChange', mode);
  },

  productSubcategoryNavigation: function(categoryId) {
    this.sendEvent('catalog', 'subcategoryViewed', categoryId);
  },

  sendCatalogNavigationEvent: function(eventCategory, categoryId) {
    this.sendEvent('catalog', eventCategory, categoryId);
  },

  sendRecipeViewedEvent: function(productId, productName) {
    this.sendEvent('recipe', 'viewed', productName, productId)
  },

  sendProductFavoredEvent: function(productId, productName) {
    this.sendEvent('product', 'favored', productName, productId)
  },

  sendProductUnfavoredEvent: function(productId, productName) {
    this.sendEvent('product', 'unfavored', productName, productId)
  },

  sendCheckoutStageStartEvent: function(stage) {
    this.sendEvent('checkout', 'stageStart', stage);
  },

  sendCheckoutSkipRecommend: function(label, callback) {
    this.sendEvent('checkout', 'skipRecommend', label);
    if (callback) { callback() };
  },

  sendCheckoutCompleteEvent: function(itemTotal) {
    this.sendEvent('checkout', 'complete', itemTotal.toString() + " CHF", itemTotal);
  },

  sendCatalogFilterUsedEvent: function(filterType, filterValue) {
    if (filterType)
      filterType = filterType.replace('_', '-');

    // console.log("Sending filter used event:", filterType, filterValue);
    this.sendEvent('catalog-filter-used', filterType, filterValue);
  },

  sendOrderSuggestionEvent: function(eventAction, eventLabel) {
    // console.log("Sending sendOrderSuggestionEvent event:", eventAction, eventLabel);
    this.sendEvent('order-suggestion', eventAction, eventLabel);
  },

  sendShoppingListEvent: function(eventAction, eventLabel) {
    console.log("Sending sendShoppingListEvent event:", eventAction, eventLabel);
    this.sendEvent('shopping-list', eventAction, eventLabel);
  },

  /**
   *
   * @param action String: query|headerCollapsed|headerExpanded
   * @param query
   */
  sendSearchEvent: function(action, query) {
    this.sendEvent('search', action, query);
  },

  sendSearchAddToCartEvent: function(productId) {
    this.sendEvent('search', 'addToCart', productId);
  },

  sendError: function(type, resource) {
    this.sendEvent('errors', type, resource);
  },

  lastPageviewPath: null,

  getLastPageviewPath: function() {
    if (this.lastPageviewPath) {
      return this.lastPageviewPath;
    } else {
      return window.location.pathname;
    }
  },

  lastTrackedPageview: null,

  trackPageview: function(url, UserService) {
    if (url == null) {
      url = window.location.pathname + window.location.search;
    }

    if (this.lastTrackedPageview != url) {
      ahoy.track("page-view", { url: url, user_id: UserService && UserService.currentUser ? UserService.currentUser.id : null, referrer: this.lastTrackedPageview || document.referrer, channel: window.xSessionChannel });
      this.lastTrackedPageview = url;
    }
  },

  trackTiming: function(timingType, timeInMillis) {
    let type = timingType;
    ahoy.track(`page-timing-${type}`, { timing: timeInMillis })
  },

  sendPageviewExt: function(path, pageFields) {
    if (path[0] != '/') path = '/' + path;

    if (Rails.env == 'development') console.info("Would tracked pageview on production:", path);

    this.lastPageviewPath = path;
  }
}
