import * as _ from 'underscore';

/**
 * Helper for pagination within a farmyProductList element, implements
 * optional infinite scrolling.
 *
 * @type {Function}
 */
var ProductPaginator = function(catalogScope, pagination) {
  var scope = {
    scollHandler: null,
    scrollTarget: null,
    pagination: pagination,
    displayedPages: [],
    onFirstPage: null,
    onLastPage: null,

    update: function() {
      // Take

      bodyElement = bodyElement || $('body');
      nearBottomElement = nearBottomElement || document.querySelector('.near-bottom');

      if (!nearBottomElement) return;

      const rect = nearBottomElement.getBoundingClientRect();

      if (rect.bottom) {
        const inView = rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        let nextPage = inView && pagination.page == pagination.current_page;


        let currentTimestamp = (new Date()).getTime();
        let cooldownComplete = cooldownStartedAt == null || (currentTimestamp - cooldownStartedAt) >= cooldownDelay;

        if (!cooldownComplete) {
          // Wait and try again
          setTimeout(() => {
            scope.update()
          }, 330);
        } else {
          if (!window.CatalogServiceHelper.isLoading && !loadingPaginatedContents && nextPage && pagination.page < (pagination.total_pages || 12)) {
            loadingPaginatedContents = true;
            pagination.page += 1;

            this.updateDisplayPagination();
            if (typeof catalogScope.update === 'function') {
              catalogScope.update().then(() => {
                if (Rails.env === 'development') console.info("Pagination update", `Current page: ${pagination.page}`, `total: ${pagination.total_pages}`);

                setTimeout(() => {
                  catalogScope.$apply(() => {
                    loadingPaginatedContents = false;
                    cooldownStartedAt = (new Date()).getTime();
                  })
                }, 100);
              });
            }
          }
        }
      } else {
        // What to do if it's null? Self-destroy?
      }
    },

    onScroll: function(e) {
      // TODO
      scope.update();
    },

    updatePaginationFromResponse: function(data) {
      angular.extend(pagination || {}, {
        total_count: parseInt(data.total_count || "0"),
        total_pages: parseInt(data.total_pages || "1"),
        page: parseInt(data.current_page || data.page),
        current_page: parseInt(data.current_page || data.page), // indicates the page that was actually loaded, is compared
        per_page: parseInt(data.per_page)
      });

      this.updateDisplayPagination();
    },

    updateDisplayPagination: function() {
      this.displayedPages.length = 0;

      var currentPageParams = angular.copy(NgFrontendAppCtrl.$location.search());
      if (currentPageParams['page']) delete currentPageParams['page'];
      if (currentPageParams['nzp']) delete currentPageParams['nzp']; // special case for prerender
      if (currentPageParams['prerender']) delete currentPageParams['prerender']; // special case for prerender

      // Update base URL
      this.baseUrl = NgFrontendAppCtrl.$location.path() + '?' + $.param(currentPageParams);

      if (this.baseUrl[this.baseUrl.length - 1] != '&' && this.baseUrl[this.baseUrl.length - 1] != '?') // Prepare for page= parameter append
        this.baseUrl += '&';

      let howManyPagesToDisplay = 6;

      if (pagination.total_pages != null && pagination.total_pages > 0) {
        if (pagination.page == 1 || pagination.page == 0) {
          this.onFirstPage = true;
        }

        if (pagination.page == pagination.total_pages) {
          this.onLastPage = true;
        }

        // Pages right
        for(var i = 1; i <= pagination.total_pages; i++) {
          if (Math.abs(pagination.page - i) < howManyPagesToDisplay)
            this.displayedPages.push(i);
        }
      }

    },

    destroy: function() {
      // Unbind scroll events
      if (scope.scrollHandler && scope.scrollTarget)
        scope.scrollTarget.off('scroll', '*', scope.scrollHandler)
    }
  };

  const cooldownDelay = 2000; // minimum wait time between loading the next page automatically on scroll
  var cooldownStartedAt = 0;
  var loadingPaginatedContents = false;
  var nearBottomElement, bodyElement;

  // Private members
  function constructor() {
    // TODO: Decide if to respond to scroll with pagination
    // Crawlers don't get to use infinite scrolling and get the link-based pagination instead
    if (window.browserIsBot) {
      // Do nothing, static pagination will be displayed
    } else {
      setupScrollListener();
    }
  }

  function setupScrollListener() {
    scope.scrollTarget = angular.element(window);
    scope.scrollHandler = scope.scrollTarget.on('scroll', scope.onScroll);
  }
  // TODO: Bind to scroll event

  constructor();

  return scope;
};

window.ProductPaginator = ProductPaginator;
