import React from "react";
import styled from "styled-components";
import SpinnerLoader from "./SpinnerLoader";
import {useSelector} from "../../shared-services/redux-tiny";

const FullLoader = ({renderLoader = false}) => {
  const loaderOpen = useSelector(state => state.topLayer.loaderOpen);

  if (loaderOpen) {
    return <StyledSpinnerLoaderWrapper>
      <SpinnerLoader/>
    </StyledSpinnerLoaderWrapper>;
  }

  return <></>;
};

export default FullLoader;

export const StyledSpinnerLoaderWrapper = styled.div.attrs({
  className: `
  flex
  align-items-center
  justify-content-center
  `
})`
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.6);
  z-index: 90000;
`;
