import styled from "styled-components";
import currentTheme from "../react-themes/theme";

const theme = currentTheme();

// Table
export const ProductTableWrapper = styled.div`
  width: 100%;
  transition: background-color 200ms ease-out;

  &:hover {
    background: #FEFEFE;

    .col-cart-controls {
      .btn-decrease-amount {
        background: #EFEFEF;
      }
    }
  }
`

export const TableHeader = styled.div`
  border-collapse: collapse;
  font-size: 12px;
  border-bottom: 1px solid #cacaca;
  padding-bottom: 5px;
`

// Table Item
export const TableItem = styled.div`
  width: 100%;
  display: block;
`

export const TableItemContainer = styled.div`
  outline: none;
  background: none;
  border-radius: 0;
  padding: 3px 0 4px 0;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid #cacaca;

  &.active {
    &.col-name, &.col-name h4 {
      font-weight: bolder;
    }
  }
  
  .item-supplier {
    padding: 0;
  }
`

export const TableCol = styled.div`
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
  width: 12.5%;
  padding: 0 3px;
`

export const ColThumbWrapper = styled(TableCol)`
  width: 64px;
  height: 45px;
  padding: 0;
`;

export const ColProductThumbDefault = styled(TableCol)`
  width: 64px;
  height: 45px;
  background-image: url('/stubs/product-catalog.jpg');
  background-size: cover;
  background-position: center;
  padding: 0;
  
  &.header-col {
    background: none !important;
  }
`

export const ColProductThumb = styled.div.attrs(props => ({
  style: {
    backgroundImage: `url(${props.backgroundImage})`
  }
}))`
  width: 64px;
  height: 45px;
  background-size: cover;
  background-position: center;
`

export const ColSupplierFlag = styled(TableCol)`
  width: 32px;
  padding-left: 11px;
  padding-top: 2px;

  img {
    height: 19px;
  }
`
export const ColSupplier = styled(TableCol)`
  width: 12.5%;
  padding-left: 9px;
  
  a {
    font-size: 85%;
    color: #8f877f;
  }
`

export const ColName = styled(TableCol)`
  width: 27%;
  padding-left: 13px;

  h3.item-title, h4.item-title {
    margin: 0;
    padding: 0;
    height: auto;
    line-height: normal;
    font-size: 12px;
  }
  .product-link {
    color: ${theme.colors.textColor};
  }
`

export const ColPrice = styled(TableCol)`
  width: 12.5%;
`

export const ColUnit = styled(TableCol)`
  width: 12.5%;
  
  span {
    font-size: 11px;
    color: #787878;
  }
`

export const ColFlags = styled(TableCol)`
  width: 12.5%;

  .item-flag img, img.item-flag {
    max-height: 24px;
    max-width: 45px;
    margin-right: 3px;
  }

  /* Special rules for irregular shape logos */
  img.kag, img.msc {
    height: 15px;
  }
  
  .certificates-wrapper {
    display: flex;
  }
`

export const CartControls = styled.div`
  width: 100%;
  
  .flex-table {
    width: 100%;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    justify-items: normal;
    align-items: center;
  }
`

export const ControlsQuantity = styled.div`
  text-align: center;
  padding: 0 2px;
  flex-grow: 1;
  font-size: 11px;
`

export const ControlsBtn = styled.button`
  transition: background-color .2s ease-in-out;
  padding: 0;
  margin: 0;
  height: 23px;
  width: 23px;
  font-size: 16px;
  line-height: 0;
`

export const ControlsBtnAddToCart = styled(ControlsBtn)`
  width: 100%;
`

export const PriceStrikeOut = styled.div`
  margin-left: 22px;
  font-size: 88%;
  text-decoration: line-through;
  margin-top: -6px;
`
