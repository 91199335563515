/**
 * A generic service that can be shared across directives and controllers
 * to access common UI state registers (like popup opened, sidebar enabled etc.)
 * that must be known by various components at different depths.
 */
angular.module('app').service('UiStateService', ['$rootScope', '$q', function ($rootScope, $q) {
  var $scope = this || {};

  window.UiStateService = $scope;

  $scope.inCheckout = undefined;
  $scope.isMobile = window.isMobile;

  /**
   * If the cart sidebar is currently expanded (in desktop mode)
   * @type {undefined}
   */
  $scope.cartSidebarVisible = undefined;

  /**
   * Structure that hints where to 'return' from a product page view
   * @type {undefined}
   */
  $scope.backFromProductPageData = undefined;

  $scope.filterSidebarVisible = undefined;
  
  $scope.setBodyScroll = function(enabled) {
    if (!enabled)
      $("body").css({overflow: "hidden"});
    else
      $("body").css({overflow: "auto"});
  };

  $scope.showFilterSidebar = function(show) {
    UiStateService.filterSidebarVisible = show;
  } ;

  $scope.enableNavigateBackButton = function(location, label) {

  };
}]);
