farmyNgIncludeDirective.$inject = ['$rootScope', '$templateRequest', '$anchorScroll', '$animate', '$translate'];
function farmyNgIncludeDirective($rootScope, $templateRequest, $anchorScroll, $animate, $translate) {
  return {
    restrict: 'ECA',
    priority: 400,
    terminal: true,
    transclude: 'element',
    controller: angular.noop,
    compile: function (element, attr) {
      var srcExp = attr.farmyNgInclude || attr.src,
        onloadExp = attr.onload || '',
        autoScrollExp = attr.autoscroll;

      var currentSrc = null;
      var currentLocale = $translate.use() || I18n.locale;

      return function (scope, $element, $attr, ctrl, $transclude) {
        var changeCounter = 0,
          currentScope,
          previousElement,
          currentElement;

        var cleanupLastIncludeContent = function () {
          if (previousElement) {
            previousElement.remove();
            previousElement = null;
          }
          if (currentScope) {
            currentScope.$destroy();
            currentScope = null;
          }
          if (currentElement) {
            $animate.leave(currentElement).done(function (response) {
              if (response !== false) previousElement = null;
            });
            previousElement = currentElement;
            currentElement = null;
          }
        };

        var updateContent = function () {
          let src = currentSrc;

          // Add locale parameter to the URL
          if (src && src.indexOf('locale=') == -1) {
            if (src.indexOf('?') == -1) {
              src += '?';
            }

            src += `&locale=${currentLocale}`;
          }

          var afterAnimation = function (response) {
            if (response !== false && angular.isDefined(autoScrollExp) &&
              (!autoScrollExp || scope.$eval(autoScrollExp))) {
              $anchorScroll();
            }
          };
          var thisChangeId = ++changeCounter;

          if (src) {
            //set the 2nd param to true to ignore the template request error so that the inner
            //contents and scope can be cleaned up.
            $templateRequest(src, true).then(function (response) {
              if (scope.$$destroyed) return;

              if (thisChangeId !== changeCounter) return;
              var newScope = scope.$new();
              ctrl.template = response;

              // Note: This will also link all children of ng-include that were contained in the original
              // html. If that content contains controllers, ... they could pollute/change the scope.
              // However, using ng-include on an element with additional content does not make sense...
              // Note: We can't remove them in the cloneAttchFn of $transclude as that
              // function is called before linking the content, which would apply child
              // directives to non existing elements.
              var clone = $transclude(newScope, function (clone) {
                cleanupLastIncludeContent();
                $animate.enter(clone, null, $element).done(afterAnimation);
              });

              currentScope = newScope;
              currentElement = clone;

              currentScope.$emit('$includeContentLoaded', src);
              scope.$eval(onloadExp);
            }, function () {
              if (scope.$$destroyed) return;

              if (thisChangeId === changeCounter) {
                cleanupLastIncludeContent();
                scope.$emit('$includeContentError', src);
              }
            });
            scope.$emit('$includeContentRequested', src);
          } else {
            cleanupLastIncludeContent();
            ctrl.template = null;
          }
        }
        scope.$watch(srcExp, function ngIncludeWatchAction(src) {
          currentSrc = src;
          updateContent();
        });

        $rootScope.$on('$translateChangeSuccess', function () {
          if (currentLocale != $translate.use()) {
            currentLocale = $translate.use();
            updateContent();
          }
        });
      };
    }
  };
};

farmyNgIncludeFillContentDirective.$inject = ['$compile'];
function farmyNgIncludeFillContentDirective($compile) {
  return {
    restrict: 'ECA',
    priority: -400,
    require: 'farmyNgInclude',
    link: function (scope, $element, $attr, ctrl) {
      if (!window.isIE) {
        if (toString.call($element[0]).match(/SVG/)) {
          // WebKit: https://bugs.webkit.org/show_bug.cgi?id=135698 --- SVG elements do not
          // support innerHTML, so detect this here and try to generate the contents
          // specially.
          $element.empty();
          $compile(jqLiteBuildFragment(ctrl.template, window.document).childNodes)(scope,
            function namespaceAdaptedClone(clone) {
              $element.append(clone);
            }, {futureParentElement: $element});
          return;
        }
      }

      $element.html(ctrl.template);
      $compile($element.contents())(scope);
    }
  };
};

appModule.directive('farmyNgInclude', farmyNgIncludeDirective);
appModule.directive('farmyNgInclude', farmyNgIncludeFillContentDirective);
