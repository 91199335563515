angular.module('app').filter('abs', function () {
  return function(val) {
    return Math.abs(val);
  }
});

angular.module('app').filter('htmlText', function () {
  return function(val) {
    if (val == null)
      return null;

    var html = '<p>' + val.replace("\n", "</p>\n<p>") + '</p>';

    return html;
  }
});

