import * as _ from 'underscore';

angular.module('app').controller('OrderSuggestionsSearchFormCtrl', ['$scope', '$http', '$element', '$sce', '$compile', function ($scope, $http, $element, $sce, $compile) {
  var minChars = 3;
  // var perPage = NgFrontendAppCtrl.ngfrontend.isMobile ? 15 : 5;
  var perPage = 50;

  var loadSuggestionsTimeout = null;

  // formats date as 2015-10-17
  var httpCacheKey = new Date().toISOString().slice(0, 10);

  var bottomPadding = 25;
  var maxContainerWidth = parseInt($(".search-form-suggestions-panel").css('height'));
  var fixContainerLayoutTimeout = null;

  $scope.isOpen = false;
  $scope.keywords = $element.find("input[type='search']").data("init-value");

  if ($scope.keywords.replaceAll) {
    $scope.keywords = $scope.keywords.replaceAll(/\[|\]|\"|\{|\}|\)|\(|\;/g, "")
  }

  // DEBUG
  // var debugImgSrc = "https://d1q864mr06oufu.cloudfront.net/farmy-s3/public/spree/products/1996/thumb/Erdbeeren_klein.jpg";
  // $scope.supplier_suggestions = [
  //   { name: "Farmy", url: "/", mini_image_url: debugImgSrc }, { name: "John Baker", url: "/", mini_image_url: debugImgSrc }
  // ];
  //
  // $scope.taxon_suggestions = [
  //   { name: "Ruebli", url: "/", mini_image_url: debugImgSrc } , { name: "Kartofel", url: "/", mini_image_url: debugImgSrc }
  // ];
  //
  // $scope.certificate_suggestions = [
  //   { name: "Demeter", products_url: "/", mini_image_url: debugImgSrc } , { name: "CH-BIO", products_url: "/", mini_image_url: debugImgSrc }
  // ]
  //
  // $scope.product_suggestions = [
  //   { name: "Büffel Joghurt Brombeer, 125g", url: "/", price: 1.95, mini_image_url: debugImgSrc } , { name: "Rotkraut gekocht, 250g", url: "/", price: 59.00, mini_image_url: debugImgSrc }
  // ];
  //
  // /DEBUG

  $scope.suggest = function() {
    // Scroll to top on mobile
    if (NgFrontendAppCtrl.ngfrontend.isMobile) {
      $('html, body').animate({
        scrollTop: $('#search-form-we form > input').offset().top - 68
      }, 500);

      // TODO: Assign suggestion results height based on viewport height
    }

    clearSuggestions();
    cancelFixContainerLayout();

    if ($scope.keywords && $scope.keywords.length >= minChars) {
      loadSuggestions($scope.keywords);
    } else {
      $scope.close();
    }
  };

  $scope.open = function() {
    $scope.isOpen = true;
  };

  $scope.close = function() {
    $scope.isOpen = false;
  }

  $scope.anySuggestion = function() {
    return _.any($scope.product_suggestions);
  };

  // private

  function clearSuggestions() {
    $scope.product_suggestions = null;
  }

  function cancelLoadings() {
    // TODO
  }

  function loadSuggestionsPerform(keywords) {
    searchProducts(keywords);
  }

  function loadSuggestions(keywords) {
    if (loadSuggestionsTimeout) {
      clearTimeout(loadSuggestionsTimeout);
    }

    loadSuggestionsTimeout = setTimeout(function() {
      loadSuggestionsPerform(keywords);
    }, 500);
  }

  function searchProducts(keywords) {
    var url = apiUrl("/products/autosuggest.json?cachekey=" + httpCacheKey + "&per_page=" + perPage + "&keywords=" + keywords);

    $http.get(url, { cache: true }).then(function(response) {
      if (response.data.products) {
        $scope.product_suggestions = response.data.products;
        onSuggestionsAdd();
      }

      window.Tracking.sendOrderSuggestionEvent('search', 'used');
    });
  }

  function cancelFixContainerLayout() {
    if (fixContainerLayoutTimeout) {
      clearTimeout(fixContainerLayoutTimeout);
    }
  }

  function onSuggestionsAdd() {
    $scope.isOpen = $scope.anySuggestion();

    cancelFixContainerLayout();

    fixContainerLayoutTimeout = setTimeout(function() {
      fixContainerLayout();
    }, 100);
  }

  function fixContainerLayout() {
    var actualHeight = _.max(
      _.map($(".search-form-suggestions-panel .height-root"), function(el) {
        return parseInt($(el).css('height')) }
      )
    );

    if (actualHeight > bottomPadding) {
      var containerHeight = _.min([actualHeight + bottomPadding, maxContainerWidth]);

      // For mobile screens, use the whole remaining screen height
      if(NgFrontendAppCtrl.ngfrontend.isMobile) {
        containerHeight = $(window).height() - 135;
      } else {
        // Fix width to make the ugly scroll bar show up:
        var scrollWrapper = $($element.find('.hide-scrollbar'));
        scrollWrapper.width(scrollWrapper.parent().width() - parseInt(scrollWrapper.parent().css('paddingLeft')) - 2);
      }

      $(".search-form-suggestions-panel").css('height', containerHeight);
      $(".search-form-suggestions-panel").css('margin-bottom', -containerHeight);
    }
  }

  function listenToCloseResultsOnOutsideClick() {
    $(".order-suggestions-view-ctrl").on("click", function(event) {
      if (event.target.className.indexOf("order-suggestions-view-ctrl") != -1) {
        $scope.close();
      }
    });
  }

  fixContainerLayout();

  // initialize
  listenToCloseResultsOnOutsideClick();
}]);
