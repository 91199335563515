'use strict';

angular.module('app').controller('UserEditPageCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$localStorage', '$translate', 'Alerts', 'UserService',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, $localStorage, $translate, Alerts, UserService) {
    var endpoint = '/api/frontend/users/';
    $scope.userLoading = false;

    $scope.unsubscribtionMode = $location.search().unsubscribe == "t";
    $scope.unsubscribeResult = null;

    $scope.loadUser = function() {
      $scope.userLoading = true;
      return new Promise((resolve, reject) => {
        $http.get(endpoint + ($scope.userId || window.currentUserId) + '.json?include_smart_pass=true&locale=' + I18n.locale).then(function(response) {
          $scope.user = response.data;
          resolve(true)
        }).finally(function() {
          $scope.userLoading = false;
          resolve(false)
        })
      })
    };

    var updateUser = function() {
      $scope.userLoading = true;
      $http.put(endpoint + $scope.userId + '.json', $scope.user).then(function(response) {
        // Do nothing for now: if the update is successful, nutrient buttons will update withou issue.
      }, e => {
        Alerts.error(`<i class='fa fa-times'>${response?.data?.message || "Error"}</i>`);
      }).finally(function() {
        $scope.loadUser()
        $scope.userLoading = false;
      })
    };

    $scope.onNutritionalIconClick = function(property) {
      if (!$scope.userLoading) {
        $scope.user.nutritional_data[property] = !$scope.user.nutritional_data[property];
        updateUser();
      }
    };

    $scope.$watch('selectedIndex', function(){
      $scope.navBarWidth = 100 / $scope.optionsOrderedList.length;
      $scope.navBarPosition = $scope.navBarWidth * $scope.optionsOrderedList.indexOf($scope.selectedIndex);
    });

    $scope.$watch('userId', function(val) {
      $scope.loadUser().then((userLoaded) => {
        if (userLoaded && $scope.unsubscribtionMode && !$scope.user.only_basic_communication) {
          unsubscribeUser();
        }
        if (!userLoaded) UserService.showLoginScreen({backToUrl: window.location.pathname + window.location.hash, forcePageReload: true});
      });
    });

    $scope.facebookLogin = function () {
      $scope.authenticationInProgress = true;
      UserService.authenticateBySocialNetwork('facebook')
        .then((user) => {
            $scope.authenticationInProgress = false;
            $scope.user.facebook_id = user.facebook_id;
          }, (user) => {
            if (user.user_already_exists) {
              Alerts.facebook($translate.instant('social.facebook.already_exists', {email: user.email_old, strategy: 'Facebook'}), {timeout: 0});
            }
          }
        )
    };

    $scope.facebookUnlink = function () {
      $scope.authenticationInProgress = true;
      UserService.unlinkSocialNetwork('facebook').then(response => {
        if (response.data.success) $scope.user.facebook_id = null;
        $scope.authenticationInProgress = false;
      })
    }

    function unsubscribeUser(){
      $http.put(endpoint + $scope.userId + '.json', {only_basic_communication: true}).then(function(response) {
        $scope.unsubscribtionMode = false;
        $scope.unsubscribeResult = response.data.message;
        $scope.user.only_basic_communication = true;
      }).finally(function() {
        $scope.userLoading = false;
      })
    }
  }
]);

