import * as _ from 'underscore';

angular.module('app').controller('WeeklyEditCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$element', '$translate', 'Alerts', 'blockUI', 'Hubs', 'OrderHelper', 'CartData', 'UserService', 'AddressHelper', function ($scope, $sce, $http, $q, $rootScope, $element, $translate, Alerts, blockUI, Hubs, OrderHelper, CartData, UserService, AddressHelper) {
  window.WeeklyEditCtrl = $scope;

  //
  // Private members:
  //
  var weeklyId;

  function constructor() {
    weeklyId = $element.data('weekly-id'); // TODO

    $scope.loadOptions().then(() => {
      $scope.loadWeekly().then(() => {
        CartData.setWeeklyMode($scope.weekly);
      })
    })
  }

  function destructor() {
    window.WeeklyEditCtrl = null;

    CartData.leaveWeeklyMode();
  }


  //
  // Public members
  //

  $scope.weekly = {};

  $scope.loadWeeklyOptions = function() {

  };

  $scope.loadOptions = function() {
    return $q((resolve, reject) => {
      $http.get(`/api/frontend/weeklies/weekly_options.json?weekly_id=${weeklyId}`).then(response => {
        $scope.dayOfWeekOptions = response.data.day_of_week_options;
        $scope.periodicityOptions = response.data.periodicity;
        resolve(response.data);
      })
    })
  };

  $scope.loadWeekly = function(id) {
    $scope.isLoading = true;

    if (id == null) {
      id = weeklyId;
    }

    blockUI.start();

    return $http.get(`/api/frontend/weeklies/${id}.json?include_items=t&product_summary=t&include_ship_address=t`).then(response => {
      angular.extend($scope.weekly, response.data);
      weeklyId = $scope.weekly.id;
      $scope.selectedPeriodicity = _.find($scope.periodicityOptions, o => o.id == $scope.weekly.periodicity);
      $scope.selectedDayOfWeek = _.find($scope.dayOfWeekOptions, o => o.id == $scope.weekly.day_of_week_id);
    }).finally(() => {
      $scope.isLoading = false;
      blockUI.stop();
    })
  };

  $scope.updateWeekly = function() {
    var params = {};

    $scope.weekly.day_of_week = $scope.selectedDayOfWeek.id;
    $scope.weekly.periodicity = $scope.selectedPeriodicity.id;

    params.weekly = angular.copy($scope.weekly);

    $scope.isLoading = true;

    blockUI.start();

    return $q((resolve, reject) => {
      $http.patch(`/api/frontend/weeklies/${weeklyId}.json?include_items=t&product_summary=t&include_ship_address=t`, params).then(response => {
        angular.extend($scope.weekly, response.data);
        resolve(response.data);
      }, e => {
        Alerts.error(errorMessage(e));
        reject(e)
      }).finally(() => {
        blockUI.stop();
        $scope.isLoading = false;
      });
    });
  };

  $scope.updateItem = function(item) {
    var params = {
      item: angular.copy(item)
    };

    $scope.isLoading = true;

    blockUI.start();

    return $q((resolve, reject) => {
      $http.patch(`/api/frontend/weeklies/${weeklyId}/update_item.json?item_id=${item.id}&product_data=t`, params).then(response => {
        let existingItem = _.find($scope.weekly.items, i => i.id == response.data.id)

        if (existingItem) {
          angular.extend(existingItem, response.data);
        } else {
          $scope.weekly.items.push(response.data)
        }

        resolve(response.data);
      }, e => {
        Alerts.error(errorMessage(e));
        reject(e)
      }).finally(() => {
        blockUI.stop();
        $scope.isLoading = false;
      });
    });
  };

  $scope.pauseWeekly = function() {
    return $http.post(`/api/frontend/weeklies/${$scope.weekly.id}/pause.json`).then(response => {
      Alerts.success($translate.instant('messages.changes_saved'));
      angular.extend($scope.weekly, response.data);
    });
  };

  $scope.activateWeekly = function() {
    return $http.post(`/api/frontend/weeklies/${$scope.weekly.id}/activate.json`).then(response => {
      Alerts.success($translate.instant('messages.changes_saved'));
      angular.extend($scope.weekly, response.data);
    });
  };
  
  $scope.updateShippingAddress = function() {
    return $q((resolve, reject) => {
      let params = {
        shipping_type: 'delivery',
        weekly: {
          ship_address_attributes: AddressHelper.sanitizeAttributes($scope.weekly.ship_address)
        }
      };

      blockUI.start();

      $http.post(`/api/frontend/weeklies/${$scope.weekly.id}/update_shipping.json`, params).then((response) => {
        Alerts.success($translate.instant('messages.changes_saved'));
        angular.extend($scope.weekly, response.data);
      }).finally(() => {
        blockUI.stop();
      });
    })
  };

  $scope.$on('$destroy', destructor);
  constructor();
}]);
