import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app')
  .directive('farmyCheckoutOrderTotals', function() {
    return {
      controller: ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', 'CartData', 'Alerts', 'blockUI', function ($scope, $sce, $http, $q, $rootScope, $timeout, CartData, Alerts, blockUI) {
        window.farmyCheckoutOrderTotals = $scope;

        $scope.checkoutCtrl = window.CheckoutCtrl;

        function constructor() {

        }

        function destructor() {
          window.farmyCheckoutOrderTotals = null
        }

        // Public members

        $scope.removeDiscountAdjustment = function(adjustment) {
          blockUI.start()

          return $q((resolve, reject) => {
            adjustment.isUpdating = true

            $http.post('/api/frontend/orders/' + $scope.checkoutCtrl.checkout.id.toString() + '/remove_adjustment.json', { adjustment_id: adjustment.id }).then((response) => {
              $scope.checkoutCtrl.getUserProfile();
              $scope.checkoutCtrl.getPaymentMethods();

              $scope.checkoutCtrl.getCheckout().then((r) => {
                adjustment.isUpdating = false
                blockUI.stop()
                resolve(r)
              })
            }, (e) => {
              blockUI.stop()
              reject(e)
            }).finally(function() {
              if (adjustment)
                adjustment.isUpdating = false
            });
          });
        }

        $scope.onAdjustmentDescriptionClick = function(adjustment) {
          if (adjustment.permabox_adjustment) OrderHelper.showPermaboxDepositExplanationModel();
          if (adjustment.code === ':handling_fee') OrderHelper.showHandlingFeeModal($scope.checkout);
        };

        // Initialize

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      scope: {
        checkout: '='//,
        // checkoutCtrl: '='
      },
      link: function($scope, $element, attributes) {

      },
      templateUrl: () => { return `/ng/templates/checkout/order_total.html?locale=${window.I18n.locale}` }
    }
  });


