"use strict";
import setLocale from "../shared-services/translate";
import globalState from "../shared-services/globalState";

angular.module("app").controller("NgFrontendAppCtrl", ["$scope", "$rootScope", "$location", "$window", "$timeout", "$interval", "$http", "$element", "$localStorage", "$translate", "$q", "Alerts", "blockUI", "amMoment", "CartData", "CmsUtils", "NotificationUtils", "ProductNavigation", "NativeContainer", "UiStateManager", "Hubs", "UserService", "FarmyNotifications", "UserNotifications", "UserRatings", "PromotionsService", "TaxonHelper", "CatalogServiceHelper", "PusherService", "Engagement", "$uibModal",
  function($scope, $rootScope, $location, $window, $timeout, $interval, $http, $element, $localStorage, $translate, $q, Alerts, blockUI, amMoment, CartData, CmsUtils, NotificationUtils, ProductNavigation, NativeContainer, UiStateManager, Hubs, UserService, FarmyNotifications, UserNotifications, UserRatings, PromotionsService, TaxonHelper, CatalogServiceHelper, PusherService, Engagement, $uibModal) {
    window.$ngRootScope = $rootScope;
    window.NgFrontendAppCtrl = $scope;
    window.blockUI = blockUI;
    window.UiStateManager = $rootScope.UiStateManager = $scope.UiStateManager = UiStateManager;
    window.$translate = $translate;

    const {Tracking, AngularIntegration, $} = window;

    $rootScope.ngfrontend = $scope.ngfrontend = {
      version: 0.1,
      isMobile: window.isMobile,
      isPrerenderAgent: window.prerenderAgent,
      locale: I18n.locale
    };

    // Initialize global state.
    globalState(true);

    $scope.locationLocalePrefix = "";

    if ($scope.ngfrontend.locale !== "de") { $scope.locationLocalePrefix = "/" + $scope.ngfrontend.locale + "/" }

    $rootScope.locationLocalePrefix = $scope.locationLocalePrefix;
    $rootScope.currentDate = new Date();
    $rootScope.currentDayOfWeek = $rootScope.currentDate.getDay();

    $scope.CartData = window.CartData = CartData;
    $scope.CmsUtils = CmsUtils;
    $scope.UserService = UserService;
    $scope.Hubs = Hubs;
    $scope.NotificationUtils = NotificationUtils;
    $scope.ProductNavigation = ProductNavigation;

    $rootScope.supplierPortalMode = window.supplierPortalMode;

    $rootScope.NativeContainer = $scope.NativeContainer = NativeContainer;

    /**
     * Branding parameter allows switching the scope and branding of
     * the platform frontend to a specific predefined vendor
     * @type {null}
     */
    $rootScope.catalogBranding = null;

    $scope.browserMobileDevice = window.browserMobileDevice;
    $scope.browserMobileDeviceName = window.browserMobileDeviceName;

    AngularIntegration.$location = $scope.$location = $location;

    $scope.ProductNavigation = ProductNavigation;

    $scope.animationsEnabled = true;
    $scope.currentUserId = window.currentUserId;
    $scope.currentPath = null;

    $scope.currentStorefront = window.currentStorefront;
    $scope.currentStorefrontSupplier = window.currentStorefrontSupplier;

    /**
     * Controls if the cart sidebar will be shown
     * @type {null}
     */
    $scope.enableCartSidebar = null;

    $scope._documentTitle = document.title;

    //
    // Private members:
    //

    /**
     * This fixes a brisc scroll transition for page-content
     * when the header turns into a fixed ('affix') version.
     */
    function setupBodyAffix() {
      // Affix behaviour for body
      angular.element($window).bind("scroll", function(e, p) {
        const scrollTop = $(window).scrollTop();

        // Apply global affixed class to body, so that other components can re-adjust
        if (scrollTop > window.affixHeaderOffset) {
          if (!$("body").hasClass("affixed")) { $("body").addClass("affixed") };
        } else if ($("body").removeClass("affixed"));
      });
    }

    // Work-around to make in-page hash-links work
    // the old way after angular integration
    $("a[href]").click(function() {
      const hash = $(this).attr("href");
      if ($(this).data("toggle")) return true; // Special case for bootstrap tabs which use location.hash as well
      if (hash.length <= 1 || hash[0] !== "#") return true;

      $("html, body").animate({
        scrollTop: $(hash).offset().top
      }, 500);
      return false;
    });

    $scope.scrollTo = function(selector) {
      $("html, body").animate({scrollTop: $(selector).offset().top - 150}, 500);
    };

    $scope.requestNotificationPermission = function() {
      try {
        if (Notification) {
          if (Notification.permission !== "granted") { Notification.requestPermission() }
        }
      } catch (e) { console.error(e) }
    };

    $scope.isHeaderAffixed = function() {
      return $("body").hasClass("affixed");
    };

    /**
     * Open a new link via javascript optionally sending GA
     * event tracking info (provide a tracking structure, with category, action and label keys)
     *
     * @deprecated Due to name collision with NativeContainer method. Use $rootScope.openUrlTracked instead.
     * @param url
     * @param {object} tracking Allowed keys: category, action, label (for GA events)
     */
    $scope.openUrl = function(url, tracking) {
      if (tracking) {
        Tracking.sendEvent(tracking.category, tracking.action, tracking.label);

        $timeout(function() {
          $window.location = url;
        }, 100);
      } else {
        $window.location = url;
      }
    };

    $rootScope.openUrlTracked = $scope.openUrlTracked = function(url, tracking) {
      if (tracking) {
        Tracking.sendEvent(tracking.category, tracking.action, tracking.label);

        $timeout(function() {
          $window.location = url;
        }, 100);
      } else {
        $window.location = url;
      }
    };

    $scope.showNewsletterPopup = function() {
      $("#newsletter-signup-popup").modal("show");
      return false;
    };

    // amMoment.changeLocale('de'); TODO: @denis why was this here?
    amMoment.changeLocale(I18n.locale.replace("-CH", ""));

    $scope.$on("$locationChangeStart", function(event, next, current) {
      $scope.currentPath = $location.path();
    });

    window.blockUI = blockUI; // This is needed for debugging only

    if (!window.prerenderAgent) {
      CartData.loadNearestDeliveryTime();
    }

    $rootScope.openZipcodeModal = $scope.openZipcodeModal = function(opensFor) {
      Hubs.openZipcodeModal();
    };

    $rootScope.$on("$translateChangeSuccess", function() {
      const locale = $translate.use();
      window.I18n.locale = $scope.ngfrontend.locale = locale;
      amMoment.changeLocale(locale.replace("-CH", ""));
      $scope.locationLocalePrefix = "";

      if ($scope.ngfrontend.locale !== "de") { $scope.locationLocalePrefix = "/" + $scope.ngfrontend.locale + "/" }

      $rootScope.locationLocalePrefix = $scope.locationLocalePrefix;

      const translation = $translate.getTranslationTable(locale);
      setLocale(locale, null, translation);
    });

    $rootScope.setDocumentTitle = function(title) {
      $("head title").html(title);
      document.title = $scope._documentTitle = title;
    };

    $rootScope.setRobotsMeta = function(value) {
      let robotsMeta = document.querySelector("[name=\"ROBOTS\"]");
      if (robotsMeta) robotsMeta.setAttribute("content", value);
      else {
        robotsMeta = document.createElement("meta");
        robotsMeta.setAttribute("name", "ROBOTS");
        robotsMeta.setAttribute("content", value);
        document.head.appendChild(robotsMeta);
      }
    };

    $scope.$on("$destroy", function() {
      // General cleanup related to SPA mode
      if (window.productsInfiniteScroll) {
        window.productsInfiniteScroll.destroy();
        window.productsInfiniteScroll = null;
      }
    });

    NativeContainer.notifyAppInitialized();

    if (!window.isMobile || window.browserTabletDevice) {
      setupBodyAffix();
    }

    /**
     * Listener for prerender
     */
    $rootScope.$on("$viewContentLoaded", function() {
      checkPrerenderReady().then(() => {
        // console.log("READY FOR PRERENDER SNAPSHOT");
        setTimeout(() => { // Extra safety timeout for post-load DOM initializations
          window.prerenderReady = true;
        }, 1500);
      });
    });

    if (window.TestManager) window.TestManager.setupDefaults();

    function checkPrerenderReady() {
      return $q((resolve, reject) => {
        if ($http.pendingRequests.length > 0) {
          setTimeout(() => checkPrerenderReady().then(r => resolve(true)), 200);
        } else {
          setTimeout(() => {
            if ($http.pendingRequests.length > 0) {
              setTimeout(() => checkPrerenderReady().then(r => resolve(true)), 200);
            } else {
              resolve(true);
            }
          }, 100);
        }
      });
    }
  }
]);
