import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app').controller('CheckoutRegistrationViewCtrl', ['$scope', '$http', '$q', '$interval', '$timeout', '$element', 'Alerts', 'blockUI', 'AddressHelper', function ($scope, $http, $q, $interval, $timeout, $element, Alerts, blockUI, AddressHelper) {
  window.CheckoutRegistrationViewCtrl = $scope;

  // Private members:

  var validationInterval;

  function constructor() {
    // Ugly non-ng validation
    validationInterval = $interval(() => {
      $scope.validateRegistrationForm();
    }, 150);
  }

  function destructor() {
    window.CheckoutRegistrationViewCtrl = null;

    // Cancel validation interval
    if (validationInterval) $interval.cancel(validationInterval);
  }

  // Public members

  /**
   * type {FormController}
   */
  $scope.registrationForm = {
    form: $element.find('form#new_spree_user'),
    birth_date: $element.find('form#new_spree_user #spree_user_birth_date')
  };

  $scope.isUpdating = false;
  $scope.isValid = true;

  $scope.submitForm = function() {
    $scope.isUpdating = true;
    blockUI.start();
  };

  $scope.validateRegistrationForm = function() {
    $scope.isValid = true;
    $scope.isBirthDateValid = true;

    // Check birth date for sanity
    if ($scope.registrationForm.birth_date && $scope.registrationForm.birth_date.length > 0) {
      var val = CheckoutRegistrationViewCtrl.registrationForm.birth_date.val();

      // Only validate the value if it's set, since in fact it is optional!
      if (val && val != "") {
        try {
          var date = new Date(CheckoutRegistrationViewCtrl.registrationForm.birth_date.val());

          // Disallow 'funny' dates
          if (date.getYear() < 10 || date.getYear() > 107) {
            $scope.isValid = false;
            $scope.isBirthDateValid = false;
          }
        } catch(e) {
          console.error(e);
          $scope.isValid = false;
          $scope.isBirthDateValid = false;
        }
      }
    }
  }

  // Initialize

  $scope.$on('$destroy', destructor);
  constructor();
}]);