import React from 'react';
import ReactDOM from 'react-dom';
import * as _ from 'underscore';
import { RjsSupportContactForm } from '../react-components/support-contact-form/RjsSupportContactForm';

'use strict';

angular.module('app')
    .directive('ngrSupportContactForm', ['$http', '$location', 'UserService', function($http, $location, UserService) {
        return {
            scope: {
            },
            link: ($scope, $element, attrs) => {
                let component, recommendations;

                window.ngrSupportContactForm = $scope;

                function destructor() {

                }

                $scope.$on('$destroy', destructor);

                let factory = React.createFactory(RjsSupportContactForm),
                    props = {
                        $http: $http,
                        routeParams: $location.search(),
                        typesToSelect: [
                            {name: 'contact.delivery', icon: 'truck rotated', id: 'delivery'},
                            {name: 'contact.ordering', icon: 'bag', id: 'ordering'},
                            {name: 'contact.payment', icon: 'cc-visa', id: 'payment'},
                            {name: 'contact.returns', icon: 'truck', id: 'returns'},
                            {name: 'contact.producers', icon: 'user', id: 'producers'}
                        ],
                        currentUser: _.isEmpty(UserService.currentUser) ? null : UserService.currentUser
                };

                // console.log(ngrSupportContactForm, attrs);

                component = ReactDOM.render(
                    factory(props, $scope),
                    $element[0]
                );

            },
        }
    }]);


