angular.module('app').directive('imageonload', ['$rootScope', '$parse', function($rootScope, $parse) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      element.bind('load', function() {
        var fn = $parse(attrs['imageonload']);

        var callback = function(event) {
          fn(scope, {$event: event});
        };

        if (!$rootScope.$$phase) {
          scope.$apply(callback);
        } else if (forceAsync) {
          scope.$evalAsync(callback);
        } else {
          try {
            callback();
          } catch (error) {
            $exceptionHandler(error);
          }
        }
      });

      if (attrs['imageonerror']) {
        element.bind('error', function() {
          var fn = $parse(attrs['imageonerror']);

          var callback = function(event) {
            fn(scope, {$event: event});
          };

          if (!$rootScope.$$phase) {
            scope.$apply(callback);
          } else if (forceAsync) {
            scope.$evalAsync(callback);
          } else {
            try {
              callback();
            } catch (error) {
              $exceptionHandler(error);
            }
          }
        });
      }

      if (attrs['fallbackonerror']) {
        element.bind('error', function() {
          element.attr('src', attrs['fallbackonerror']);
        });
      }
    }
  };
}]);