import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

/**
 * Listens to shipping zipcode changes and runs cart contents validations related
 * to the shipping destination (Hubs compatibility, etc.)
 *
 * A visual element of the shipping stage is linked to scope variables
 * modified by this method.
 */
angular.module('app')
  .directive('farmyBelowBrowseAddon', function() {
    return {
      controller: ['$rootScope', '$scope', '$http', '$q', '$timeout', 'Alerts', 'blockUI', '$attrs', '$element', 'TaxonHelper', 'Hubs', function ($rootScope, $scope, $http, $q, $timeout, Alerts, blockUI, $attrs, $element, TaxonHelper, Hubs) {
        window.farmyBelowBrowseAddon = $scope;

        var unbindCatalogViewChaged;

        function constructor() {
          $scope.eligibleForFirstTwoOrdersPromo = window.eligibleForFirstTwoOrdersPromo;

          $scope.currentZipcode = window.currentZipcode;
          $scope.minimumOrderValue = 50;

          if ($scope.currentZipcode) {
            Hubs.getZipcodeInfo($scope.currentZipcode).then(info => {
              $scope.zipcodeInfo = info;

              $timeout(() => { $scope.generateOffers(); }, 1500);
            });
          }

          unbindCatalogViewChaged = $rootScope.$on('catalog:view:changed', (data, e) => {
            console.log(data, e);

            if ($scope.currentZipcode) {
              $timeout(() => { $scope.generateOffers(); }, 1500);
            }
          });
        }

        function destructor() {
          window.farmyBelowBrowseAddon = null;

          if (unbindCatalogViewChaged) unbindCatalogViewChaged();
        }

        // Public members

        $scope.generateOffers = function() {
          var params = {
            hub_id: Hubs.currentHubId
          };

          // When on category page
          if (window.CatalogViewCtrl && window.CatalogViewCtrl.currentTaxonId) {
            // Get this taxon info and decide what to do
            TaxonHelper.getTaxonInfo(window.CatalogViewCtrl.currentTaxonId).then((taxonInfo) => {
              if (taxonInfo.depth > 1) {
                // We'll fetch products from the parent
                params.taxon_id = taxonInfo.parent_id;
                $scope.recommendedTaxonName = taxonInfo.parent_name;
                params.sort_mode = 'fos';
                $scope.getRecommendedProducts(params);
              } else {
                // We'll fetch products from this taxon, since there's no parent
                params.taxon_id = taxonInfo.id;
                $scope.recommendedTaxonName = taxonInfo.name;
                params.sort_mode = 'price_asc';
                $scope.getRecommendedProducts(params);
              }
            })
          } else if (window.productId && window.currentTaxonId) { // Product page
            TaxonHelper.getTaxonInfo(window.currentTaxonId).then((taxonInfo) => {
              // We'll fetch products from this taxon, since there's no parent
              params.taxon_id = taxonInfo.id;
              $scope.recommendedTaxonName = taxonInfo.name;
              params.sort_mode = 'fos';
              $scope.getRecommendedProducts(params);
            })
          } else {
            params.sort_mode = 'fos'
          }
        }

        $scope.getRecommendedProducts = function(params) {
          if (params == null)
            params = { };

          if (params.q == null) params.q = {}

          return $q((resolve, reject) => {
            if (params.per_page == null)
              params.per_page = '14';

            if (params.sort_mode == null)
              params.sort_mode = 'price_asc';

            params.express_delivery = CartData.isExpressDelivery ? 't' : 'f';

            $http.get(`/api/products.json?template=product_in_catalog`, { params: params }).then((response) => {
              $scope.recommendedProducts = response.data.products;
              $scope.recommendedProducts = _.chain($scope.recommendedProducts).shuffle().first(5).value();
              resolve($scope.recommendedProducts);
            })
          });
        }

        // Initialize

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      scope: {

      },
      link: function($scope, $element, attributes) {

      },
      templateUrl: function() { return `/ng/templates/taxons/below_browse_addon.html?locale=${window.I18n.locale}` }
    }
  });


