'use strict';

angular.module('app').controller('EditAddressModal', [ '$scope', '$uibModalInstance', '$http',
  function ($scope, $uibModalInstance, $http) {
    window.EditAddressModal = $scope;
    $scope.addressEndpoint = '/api/frontend/addresses/';

    $scope.loadAddress = function() {
      $http.get($scope.addressEndpoint + $scope.$resolve.addressId + '.json?locale=' + I18n.locale).then(function(response) {
        $scope.address = response.data;
        $scope.editedAddress = angular.copy($scope.address);
      })
    };

    $scope.updateAddress = function() {
      if (JSON.stringify($scope.editedAddress) === JSON.stringify($scope.address)) {
        $scope.onAddressUpdated({successful: true});
      } else {
        $http.put($scope.addressEndpoint + $scope.address.id + '.json?locale=' + I18n.locale, $scope.editedAddress).then(function(response) {
          $scope.address = response.data;
          $scope.onAddressUpdated({successful: true, updated: true});
        }, function(error) {
          $scope.onAddressUpdated({successful: false, error: error});
        })
      }
    };

    $scope.onAddressUpdated = function(responseMessage) {
      $uibModalInstance.close(responseMessage);
    };

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.loadAddress();
  }
]);
