import React from 'react'
import {trackEvent} from '../../services/tracking-helper';
import {RjsSupportContactFormHelpInfo} from "./RjsSupportContactFormHelpInfo";
import {RjsSupportContactFormGeneral} from "./RjsSupportContactFormGeneral";

export class RjsSupportContactFormSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchResults: null,
            searchIsLoading: false
        }

        this.$http = props.http;
        this.firstLoad = true;

        this.searchTypingTimeout = null;
        this.searchInputRef = React.createRef();
        this.wrapperRef = React.createRef();
        this.clearInputAndCloseQuestions = this.clearInputAndCloseQuestions.bind(this);
    }

    render(props) {
        return (<div className='support-form-search' ref={this.wrapperRef}>
            <label className='support-search-input'>
                <i className="fa fa-search"/>
                <input type="text" placeholder={$translate.instant('contact.search_faqs')} onKeyUpCapture={this.questionSearch.bind(this)} ref={this.searchInputRef}/>
                <i className='fa fa-cancel-circled' onClick={this.clearInputAndCloseQuestions}/>
            </label>
            {this.buildSearchResults()}
            {this.buildLoading()}
        </div>);
    }

    questionSearch(e) {
        const searchText = e.target.value,
            self = this;


        if(self.searchTypingTimeout) {
            self.setState({searchResults: null});
            clearTimeout(self.searchTypingTimeout);
        }

        if(searchText.length > 3) {
            self.setState({searchIsLoading: true});
            self.searchTypingTimeout = setTimeout(() => {
                self.$http.get(`/api/zendesk/search_articles.json?query_str=${searchText}&locale=${window.I18n.locale}`).then((response) => {
                    self.setState({searchResults: response.data.articles, searchIsLoading: false}, () => {
                        document.addEventListener('click', self.clearInputAndCloseQuestions)
                    });
                }, (error) => {
                    console.debug(error);
                    self.setState({searchIsLoading: false});
                });
            }, 1000)
        }
    }

    buildSearchResults() {
        if(!this.state.searchResults) return;
        if(this.state.searchResults.length <= 0 ) return <div className='search-results'><div className="question text-center">{$translate.instant('contact.question_not_found')}</div></div>

        const questions = this.state.searchResults.map((questionObj) => {
            return (<div className='question' key={questionObj.id}>
                <h3 onClick={this.showQuestion.bind(this, questionObj.id, questionObj.title)}><span>{questionObj.title}</span><i className={'fa fa-' + (this.state.activeQuestion === questionObj.id ? 'minus' : 'plus')}/></h3>
                <div className={this.state.activeQuestion === questionObj.id ? '' : 'hidden' }>
                    <p dangerouslySetInnerHTML={{__html: questionObj.details}} />
                </div>

            </div>)
        });
        return <div className='search-results'>
            {questions}
        </div>
    }

    buildLoading() {
        if(!this.state.searchIsLoading) return;
        return <div className='search-results search-results-loading'>
            <i className="fa fa-spin6 fa-spin"/>
        </div>
    }

    clearInputAndCloseQuestions(event) {
        if (this.wrapperRef && (!this.wrapperRef.current.contains(event.target) || event.target.classList.contains('fa-cancel-circled'))) {
            this.setState({searchResults: null, searchIsLoading: false, activeQuestion: null}, () => {
                document.removeEventListener('click', this.clearInputAndCloseQuestions)
            });
            this.searchInputRef.current.value = '';
        }
    }

    showQuestion(id, title, e) {
        if (this.state.activeQuestion === id) {
            id = null;
        }
        this.setState({activeQuestion: id})
        if (id && title) {
            trackEvent('contactform',
              {
                  gtmObject: {
                        type: 'direct',
                        searchterm: this.searchInputRef.current.value,
                        selection: title
                      }
              });
        };
    }




}