angular.module("uib/template/modal/window.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("uib/template/modal/window.html",
    "<div modal-render=\"{{$isRendered}}\" tabindex=\"-1\" role=\"dialog\" class=\"modal\"\n" +
    "    uib-modal-animation-class=\"fade\"\n" +
    "    modal-in-class=\"in\"\n" +
    "    ng-style=\"{'z-index': 5050 + index*10, display: 'block'}\">\n" +
    "    <div class=\"modal-dialog {{size ? 'modal-' + size : ''}}\"><div class=\"modal-content\" uib-modal-transclude></div></div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("app").run(["$templateCache", function($templateCache) {
    $templateCache.put("template/carousel/carousel.html",
        "<div ng-mouseenter=\"pause()\" ng-mouseleave=\"play()\" class=\"carousel\" ng-swipe-right=\"prev()\" ng-swipe-left=\"next()\">\n" +
        "  <div class=\"carousel-inner\" ng-transclude></div>\n" +
        "  <a role=\"button\" href class=\"left carousel-control\" ng-click=\"prev()\" ng-show=\"enableArrows && slides.length > 1\">\n" +
        "    <span aria-hidden=\"true\" class=\"glyphicon glyphicon-chevron-left\"></span>\n" +
        "    <span class=\"sr-only\">previous</span>\n" +
        "  </a>\n" +
        "  <a role=\"button\" href class=\"right carousel-control\" ng-click=\"next()\" ng-show=\"enableArrows && slides.length > 1\">\n" +
        "    <span aria-hidden=\"true\" class=\"glyphicon glyphicon-chevron-right\"></span>\n" +
        "    <span class=\"sr-only\">next</span>\n" +
        "  </a>\n" +
        "  <ol class=\"carousel-indicators\" ng-show=\"slides.length > 1\">\n" +
        "    <li ng-repeat=\"slide in slides | orderBy:indexOfSlide track by $index\" ng-class=\"{ active: isActive(slide) }\" ng-click=\"select(slide)\">\n" +
        "      <span class=\"sr-only\">slide {{ $index + 1 }} of {{ slides.length }}<span ng-if=\"isActive(slide)\">, currently active</span></span>\n" +
        "    </li>\n" +
        "  </ol>\n" +
        "</div>");
}]);

angular.module("app").run(["$templateCache", function($templateCache) {
    $templateCache.put("template/carousel/slide.html",
        "<div ng-class=\"{\n" +
        "    'active': active\n" +
        "  }\" class=\"item text-center\" ng-transclude></div>\n" +
        "");
}]);