'use strict';

angular.module('app')
  .directive('favoritesToggle', ['$rootScope', '$http', '$q', '$timeout', function($rootScope, $http, $q, $timeout) {
    return {
      link: function(scope, element, attributes) {
        var $scope = {};

        $scope.isUpdating = false;
        $scope.productId = attributes.productId;

        $scope.setFavorite = function(favorite) {
          if (favorite) {
            if (window.currentUserFavoritesIds.indexOf(parseInt($scope.productId)) < 0)
              window.currentUserFavoritesIds.push(parseInt($scope.productId));

            element.addClass('favorite');
          } else {
            window.currentUserFavoritesIds = _.reject(currentUserFavoritesIds, function(id) { return id.toString() == $scope.productId.toString() });
            element.removeClass('favorite');
          }
        };

        element.on('click', function() {
          if (!$scope.isUpdating) {
            $scope.isUpdating = true;

            return $http.post(sprintf('/api/frontend/favorites/%d/toggle.json', attributes.productId)).then(function(response) {
              if (response.data.in_favorites)
                $scope.setFavorite(true);
              else $scope.setFavorite(false);

              $rootScope.$broadcast('favorites:updated', $scope, attributes.productId);
            }).finally(function() {
              $scope.isUpdating = false;
            });
          }
        });

        var updateStateRetries = 0;

        function updateState(waitForValue) {
          // This is a hack for interpolation-based attrbitutes which aren't readily available at link time
          if (waitForValue && !attributes.productId) {
            if (updateStateRetries < 6) {
              $timeout(function() {
                updateStateRetries += 1;
                updateState(true);
              }, 200);
              return;
            }
          }

          // This alias is just for debugging, so that favorites:updated event callback
          // has a cleaner access to the productId of the directive's individual scope
          $scope.productId = attributes.productId;

          if (currentUserId && window.currentUserFavoritesIds.indexOf(parseInt($scope.productId)) >= 0)
            $scope.setFavorite(true);
          else if (currentUserId && element.hasClass('favorite'))
            $scope.setFavorite(false);

          if (!currentUserId)
            $(element).hide();
        };

        $rootScope.$on('favorites:updated', function(e, scope, productId) {
          updateState();
        });

        updateState(true);
      },
    }
  }]);