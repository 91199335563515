'use strict';

angular.module('app').controller('SearchProductItemCtrl', ['$scope', '$window', '$element', '$timeout', '$rootScope', 'Alerts', 'CartData', function ($scope, $window, $element, $timeout, $rootScope, Alerts, CartData) {
  $scope.isShown = true;
  $scope.isSaving = false;

  $scope.CartData = CartData;

  $scope.addToCart = function(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    $scope.isSaving = true;

    if ($scope.product.age_restricted && !window.currentUserAgeVerified) {
      window.FarmyCartAgeVerificationPopup.show(function() {
        window.currentUserAgeVerified = true;
        $scope.addToCart(null);
      });
      return;
    }

    var variant = {
      id: $scope.product.variants[0].id,
      price: $scope.product.variants[0].price,
      quantity_in_units: $scope.product.variants[0].quantity_in_units
    };

    CartData.setCartVariant($scope.product.id, variant);
    Tracking.sendSearchAddToCartEvent($scope.product.id);

    $scope.isSaving = true;

    $rootScope.$on("cartdata:saved", function() {
      $scope.isSaving = false;
      updateIsInCartState();
    });
  };

  // Private members:
  function updateIsInCartState() {
    $scope.isInCart = $scope.CartData.containsProduct($scope.product.id);
  }

  // Destruction
  $scope.$on('$destroy', function() {
  });

  // Initialize
  updateIsInCartState();
}]);
