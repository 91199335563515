import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import currentTheme from "../react-themes/theme";
import {translate} from "../shared-services/translate";
import {RjsFilterCheckboxes} from "./RjsFilterCheckboxes";
import {FarmyImage} from "./tools/nextjs-compatibility-layer";

const CheckboxFilterWrapper = styled.div`
  a.show-all {
    font-size: 12px;
    position: relative;
    left: -4px;
  }
  .farmy-family-title {
    img {
      width: auto;
      height: 18px;
    }
  }
`;

const FilterValuesList = styled.ul`
  & > li.checkbox-filter-value.checkbox.discount-offer.awesome-checkbox {
    background: ${currentTheme().colors.primaryBg};
    border: 1px solid ${currentTheme().colors.primaryBg};
    border-radius: 3px;
    padding: 10px 10px 10px 15px;
    margin-left: -16px !important;
    
    
    label {
      color: #ffffff;
    }    
  }
`;

export class RjsFilterFarmyFamily extends RjsFilterCheckboxes {
  constructor(props) {
    super(props);
    this.state.family = true;

    const scope = this;
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }


  render(props) {
    var wrapperClass = "filter-wrapper";

    var toggleIconClass = "fa fa-caret-right";
    if (!this.state.minimized) toggleIconClass += " open";

    var totalEligibleOptions = null;
    var valueElements = [];

    const onShowAll = this.onShowAll.bind(this);

    // TODO: Apply sorting orderBy:checkboxFilterCtrl.orderingExpression | limitTo:checkboxFilterCtrl.limitExpression:0
    var values = this.props.filter.values;

    totalEligibleOptions = values.length;

    if (this.props.taxonDepth != null) {
      values = _.filter(this.props.filter.values, v => v.depth == this.props.taxonDepth);
    } else {
      values = this.props.filter.values;
    }

    if (this.props.sorting == "name_asc") {
      values = _.sortBy(values, v => v.name.toLowerCase());
    } else if (this.props.sorting == "results_desc") {
      if (this.state.showAll && this.props.showFirstCount) { // always sort by alpha in 'show all' state for minimized-by-default
        values = _.sortBy(values, v => v.name.toLowerCase());
      } else {
        values = _.sortBy(values, v => -v.result_count);

        // if (this.props.showFirstCount) {
        //   totalEligibleOptions = _.filter(this.props.filter.values, v => v.result_count > 0).length;
        //   values = values.slice(0, parseInt(this.props.showFirstCount))
        // }
      }
    }

    // Remove this Angebote element if it's present:
    const discountIndex = _.findIndex(values, v => v.name == 'Angebote' || v.name == 'Offers' || v.name == 'Offres');
    let discountValue;

    if (discountIndex > -1) {
      discountValue = values.splice(discountIndex, 1);
      discountValue[0].isDiscountOffer = true;
    } else {
      // Nothing
    }


    // Filter out empty filter options with no results
    let realValues = values.filter(val => (val.result_count != 0 && !val._hidden) || this.state.selections[val.value]);

    // If the filter is in reduced mode, show only top {reducedItemMaxCount} values with the most results
    if (!this.state.showAll && realValues.length > this.state.reducedItemMaxCount) {
      realValues = realValues.sort((a, b) => b.result_count - a.result_count).slice(0, this.state.reducedItemMaxCount);
    }

    if (discountValue && discountValue.length > 0 && discountValue[0].result_count > 0) {
      realValues.unshift(discountValue[0]);
    }

    for (var i = 0; i < realValues.length; i++) {
      let val = realValues[i];

      let labelClass = this.state.browser.isChrome ? "" : "browser-fix";
      if (val.length >= 24) labelClass = 'tight-1';
      else if (val.length >= 28) labelClass = 'tight-2';
      else if (val.length >= 33) labelClass = 'tight-3';

      let labelId = `k_${this.props.filter.search_param}_v_${val.value}`

      valueElements.push(
        <li className={`checkbox-filter-value checkbox awesome-checkbox id_${val.value} ${val.isDiscountOffer ? 'discount-offer' : ''}`} key={labelId}>
          <input disabled={this.state.isLoading} type="checkbox" value={val.value} id={labelId}
                 onChange={this.onSelectionChange.bind(this, val)}
                 checked={this.state.selections[val.value] || false}
          />
          <label htmlFor={labelId} className={labelClass}>
            {val.name}
            <span className="result-count">[{val.result_count}]</span>
          </label>
        </li>)

    }

    let hasValues = _.filter(realValues, v => v.result_count > 0).length > 0;

    return <CheckboxFilterWrapper
      ref={this.filterRef}
      className={`filter-component ${this.state.minimized ? 'minimized' : ''} ${hasValues ? '' : 'invisible'}`}
      >{hasValues &&
    <div className={wrapperClass}>
      <div className={`title-wrapper ${this.state.minimizing ? 'clickable' : ''}`}
           onClick={this.state.minimizing ? this.toggleMinimize.bind(this) : undefined}>
        <div className='title-inner'>
          <h5 className="filter-title">
            <span className='farmy-family-title'>{this.props.filter.display_name} <FarmyImage src={'farmy_family/ff-chick-green.png'}/></span>
          </h5>
        </div>
        <div className="toggle-icon">
          {this.state.minimizing ? <i className={toggleIconClass} ng-show=""></i> : null}
        </div>
      </div>

      <FilterValuesList className={"filter filter-type-checkbox filter-type-" + this.props.filter.search_param}>
        {valueElements}
      </FilterValuesList>
      <div className="clearfix"></div>
      {!this.state.showAll && <a href='#' className={'show-all'} onClick={onShowAll}>{translate('search_form.show_more_results')}</a>}
    </div>
    }<div className={"clearfix"}></div></CheckboxFilterWrapper>;
  }

  // componentDidCatch(error, info) {
  //   console.log(error, info);
  //   super.componentDidCatch(error, info);
  // }
}