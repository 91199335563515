import * as sprintf from 'sprintf';

angular.module('app').controller('NavDeliveryCalendarModalCtrl', ['$scope', '$http', '$uibModalInstance', 'Hubs',
  function($scope, $http, $uibModalInstance, Hubs) {
    $scope.isLoading = true;

    var datetimepickerZurich, datetimepickerOthers;

    // Attach to window variables to expose current hub flags
    $scope.currentHubCode = window.currentHubCode;
    $scope.currentZipcode = window.currentZipcode;

    $scope.dates = {
      zurich_dates: null,
      others_dates: null
    };

    $scope.calendarOptions = {
      zurich: {
        inline: true,
        enabledDates: $scope.dates.zurich_dates,
        locale: I18n.locale,
        format: 'MM/dd/YYYY'
      },
      others: {
        inline: true,
        enabledDates: $scope.dates.others_dates,
        locale: I18n.locale,
        format: 'MM/dd/YYYY'
      }
    };

    $scope.closeDeliveryCalendar = function() {
      $uibModalInstance.dismiss('cancel');
    };

    // This is now the default method: zipcode is now always present on the session or user.
    $scope.loadDeliveryDates = function() {
      Hubs.deliveryDatesForZipcode().then((response) => {
        $scope.dates = response;

        $('#delivery-calendar').html('');

        datetimepickerZurich = $('#delivery-calendar').datetimepicker({
          inline: true,
          enabledDates: $scope.dates,
          locale: I18n.locale,
          format: 'MM/dd/YYYY' }).data('DateTimePicker');

        $scope.isLoading = false;
      })
    };

    $scope.loadDeliveryDates();
  }
]);