import * as _ from 'underscore';

angular.module('app').service('SuppliersHelper', ['$q', '$http', '$interval', '$timeout', '$location', 'blockUI', 'Hubs', ($q, $http, $interval, $timeout, $location, blockUI, Hubs) => {
  var $scope = this || {};
  window.SuppliersHelper = $scope;

  $scope.getSuppliersAtDistance = function(originZipcode, distance, isExpressDelivery) {
    return $q((resolve, reject) => {
      $http.get(`/api/frontend/suppliers/near_zipcode.json?zipcode=${originZipcode}&distance=${distance}&express_delivery=${isExpressDelivery ? 't' : 'f'}`).then(response => {
        if (response.data.suppliers) {
          resolve(response.data)
        } else {
          reject({ error: 'Invalid response' })
        }
      }, error => {
        reject(error);
      })
    })
  };

  return $scope;
}]);