import * as _ from 'underscore';

angular.module('app').controller('PaymentsPayPageCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$routeParams', 'Alerts', 'blockUI', 'Hubs', 'UserService', '$location', '$translate', '$timeout', 'CartData', 'DatatransHelper',
                                                function ($scope,   $sce,   $http,   $q,   $rootScope,   $routeParams,   Alerts,   blockUI,   Hubs,   UserService,   $location,   $translate,   $timeout,   CartData,   DatatransHelper) {
  window.PaymentsPayPageCtrl = $scope;
  window.$routeParams = $routeParams;

  //
  // Private members:
  //

  function constructor() {
    $scope.currency = window.defaultCurrency;
    $scope.orderToken = $location.search().order_token;
    $scope.loadPayment();
  }

  function destructor() {
    window.PaymentsPayPageCtrl = null;
  }

  $scope.loadPayment = function() {
    return $q((resolve, reject) => {
      var paymentId = null;

      if (window._PaymentsPayPageCtrl_paymentId && $routeParams.id == null) {
        paymentId = window._PaymentsPayPageCtrl_paymentId;
      } else if ($routeParams.id != null) {
        paymentId = $routeParams.id;
      } else {
        return reject("No payment id provided");
      }

      var params = {};

      if ($scope.orderToken) params.order_token = $scope.orderToken;

      return $http.get(`/api/frontend/payments/${paymentId}.json`, { params: params }).then(response => {
        $scope.payment = response.data.payment;

        if (params.order_token) $scope.payment.order_token = params.order_token;

        if ($scope.payment.currency != null) {
          $scope.currency = $scope.payment.currency;
        }

        resolve($scope.payment);
      }, e => {
        Alerts.error(errorMessage(e))
      })
    });
  };

  $scope.onPayClick = function() {
    console.log("DatatransHelper.initiatePayment({ amount: 1.0, identifier: '123' });");
    DatatransHelper.initiatePayment($scope.payment, {
      mode: 'lightbox',
      paymentmethod: 'VIS,ECA,AMX',
      success_url: `${window.settingsSiteUrl}/payments/${$scope.payment.identifier}/success?order_token=${$scope.orderToken}`,
      error_url: `${window.settingsSiteUrl}/payments/${$scope.payment.identifier}/order_status?order_token=${$scope.orderToken}`,
      cancel_url: `${window.settingsSiteUrl}/payments/${$scope.payment.identifier}/order_status?order_token=${$scope.orderToken}`,
      onClosed: onDatatransPopupClosed
    });
  };

  function onDatatransPopupClosed() {
    if (Rails.env === "development") console.log("onDatatransPopupClosed");
  }

  $scope.$on('$destroy', destructor);
  constructor();
}]);
