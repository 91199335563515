"use strict";

angular.module("app")
  .directive("farmyCheckoutCourierTip", function() {
    return {
      controller: ["$scope", "$http", "$q", "$timeout", "$rootScope", "CartData", "$element", "blockUI", function($scope, $http, $q, $timeout, $rootScope, CartData, $element, blockUI) {
        window.farmyCheckoutCourierTip = $scope;
        $scope.checkoutCtrl = window.CheckoutCtrl;

        $scope.presetOptions = [
          0, 1, 2, 4
        ];

        $scope.isCustom = false;
        $scope.isUpdating = false;

        const checkoutLoadListener = $rootScope.$on("checkout:loaded", (event, checkout) => {
          if ($scope.isUpdating) return;

          $scope.checkout = checkout;
          getCurrentTip();

          checkoutLoadListener();
        });

        function destructor() {
          window.farmyCheckoutCourierTip = null;
        }

        // Public members
        $scope.onOptionClicked = function(option) {
          $scope.isCustom = false;
          option = $scope.currentTip === option ? 0 : option;
          setCurrentTip(option);
        };

        $scope.onCustomClicked = function(event) {
          if ($scope.editMode) return;
          $scope.editMode = true;

          if ($scope.isCustom) { getInputElement().value = $scope.currentTip }

          focusInput();

          $scope.onCustomValueKeyPress({target: getInputElement()});
        };

        $scope.onCustomValueKeyPress = function(event) {
          const value = parseInt(event.target.value);
          if (event.keyCode === 13) {
            setCustomValue(event.target.value);
            return;
          }

          if (!value || $scope.presetOptions.includes(parseInt(event.target.value))) { $scope.isCustom = false } else if (parseInt(event.target.value)) { $scope.isCustom = true }
        };

        $scope.onCustomValueInputBlur = function(event) {
          setCustomValue(event.target.value);
        };

        // Private members
        function setCurrentTip(option) {
          if ($scope.isUpdating || !$scope.checkout) return;
          $scope.isUpdating = true;
          blockUI.start();
          $scope.currentTip = option;

          const params = {
            courier_tip: option
          };

          $http.put(`/api/checkouts/${$scope.checkout.number}.json`, params).then(r => {
            $scope.checkoutCtrl.getCheckout();
            CartData.load();
          }).finally(r => {
            $scope.isUpdating = false;
            blockUI.stop();
          });
        }

        function setCustomValue(value) {
          $scope.editMode = false;
          blurInput();

          value = parseFloat(value);

          if (!value) { setCurrentTip(0) } else if (value > 30) { setCurrentTip(30) } else { setCurrentTip(value) }
        }

        function getCurrentTip() {
          const adjustments = $scope.checkout && $scope.checkout.adjustments;
          if (!adjustments || !adjustments.length) return 0;

          let tipAdjustment = adjustments.find(a => a.code === ":courier_tip");
          tipAdjustment = tipAdjustment || adjustments.find(a => a.raw_label === ":courier_tip");

          $scope.currentTip = tipAdjustment ? tipAdjustment.amount : 0;
          setIsCustom();
        }

        function setIsCustom(value = $scope.currentTip) {
          $scope.isCustom = value && !$scope.presetOptions.includes(value);
        }

        function focusInput() {
          const element = getInputElement();
          element && $timeout(() => element.focus(), 100);
        }

        function getInputElement() {
          return $element.context.querySelector(".custom-value-input input");
        }

        function blurInput() {
          const element = getInputElement();
          element && $timeout(() => element.blur(), 100);
        }

        // Initialize
        $scope.$on("$destroy", destructor);
      }],
      templateUrl: () => { return `/ng/templates/checkout/courier_tip.html?locale=${window.I18n.locale}` }
    };
  });
