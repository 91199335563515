'use strict';

angular.module('app').controller('DonationsRecipientsCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$localStorage', '$sce', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, $localStorage, $sce, Alerts) {
    $scope.donationRecipientsEndpoint = '/api/frontend/donations_recipients';
    $scope.showResetHint = false;

    $scope.loadDonationsRecipients = function() {
      $http.get($scope.donationRecipientsEndpoint + '.json?locale=' + I18n.locale).then(function(response) {
        $scope.recipients = response.data;
        _($scope.recipients).each(function(recipient) {
          if (recipient.social_project.goal && recipient.social_project.goal > 0) {
            if (recipient.social_project.total_raised != recipient.social_project.goal) {
              recipient.social_project.total_raised %= recipient.social_project.goal;
            }
            $scope.setPercentages(recipient);
          }
          recipient.social_project.description = $sce.trustAsHtml(recipient.social_project.description)
        });
      })
    };

    $scope.setPercentages = function(recipient) {
      var conversionFactor = 0;
      recipient.barPercentage = '0%';

      if (recipient.social_project.total_raised > recipient.social_project.goal) {
        recipient.barPercentage = recipient.goalPercentage = '100%';
      } else {
        if (recipient.social_project.total_raised > 0 && recipient.social_project.total_raised < recipient.social_project.goal) {
          conversionFactor = (recipient.social_project.goal - recipient.social_project.total_raised) / 400;
        }
        recipient.goalPercentage = (recipient.social_project.total_raised / recipient.social_project.goal * 100).toFixed(1).toString() + '%';
        recipient.barPercentage = ((recipient.social_project.total_raised / recipient.social_project.goal * 100) + conversionFactor).toString() + '%';
      }
    };

    $scope.resetProjectDonations = function(project) {
      $http.post(`${$scope.donationRecipientsEndpoint}/${project.id}/reset_project_donations_totals.json`)
        .then(() => {
          $scope.loadDonationsRecipients();
          Alerts.success(`${project.title} donations reset successfully!`);
        })
        .catch(error => Alerts.error(error.toString()));
    };

    $scope.$watch('recipientId', function() {
      $scope.loadDonationsRecipients();
    });

    $rootScope.$on('user:balance:updated', function(e) {
      $scope.loadDonationsRecipients();
    });
  }
]);
