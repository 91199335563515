angular.module('app')
  .controller('PaymentsSubscribePageCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$routeParams', 'Alerts', 'blockUI', 'Hubs', 'UserService', 'SmartPassService', '$location', '$translate', '$timeout', 'CartData', 'DatatransHelper', '$uibModal', 'SubscriptionPaymentService',
    function($scope, $sce, $http, $q, $rootScope, $routeParams, Alerts, blockUI, Hubs, UserService, SmartPassService, $location, $translate, $timeout, CartData, DatatransHelper, $uibModal, SubscriptionPaymentService) {
      window.PaymentsSubscribePageCtrl = $scope;

      /**
       * If a charge should be made at the same
       * time with authorization registration
       * @type {boolean}
       */
      $scope.payNow = false;
      $scope.currency = window.defaultCurrency;
      $scope.subsTypes = ['smart_pass', 'weekly', 'generic'];
      $scope.templatesEndpoint = '/ng/templates/paid_subscriptions';
      $scope.apiEndpoint = '/api/frontend/paid_subscriptions';
      $scope.templatePath = 'generic.html';
      $scope.currentOrderToken = window.currentOrderToken;
      $scope.smartPassService = SmartPassService;

      //
      // Private members:
      //

      /** Constructor and related getters **/

      function constructor() {
        const idWatcher = $scope.$watch('id', (id) => {
          if (id) {
            loadSubscription().then(initializeSubscription);
          } else {
            initializeSubscription();
          }

          idWatcher();
        });
      }

      function loadSubscription() {
        if (!$scope.id) return;

        return $q(function(resolve, reject) {
          $http.get(`/api/frontend/paid_subscriptions/${$scope.id}.json`).then((response) => {
            $scope.subscription = response.data;
            resolve($scope.subscription.type);
          });
        });
      }

      // Sets an initial subscription object and scope vars based on server-injected params.
      function initializeSubscription() {
        const type = subscriptionType();

        if (type === 'abo') {
          $scope.subscriptionType = 'Spree::PaidSubscription::Weekly';
          $scope.targetType = 'Spree::Weekly';
          $scope.typeAlias = 'weekly';
        } else if (['hofpass', 'farmypass'].includes(type)) {
          $scope.subscriptionType = 'Spree::PaidSubscription::SmartPass';
          $scope.targetType = 'Spree::SmartPassSubscription';
          $scope.typeAlias = type === 'farmypass' ? type : 'smart_pass';
        } else {
          $scope.subscriptionType = 'Spree::PaidSubscription::Base';
          $scope.typeAlias = 'generic';
        }

        if ($location.search().paynow != null) {
          $scope.payNow = true;
        }

        $scope.sourceId = getSourceId();

        $scope.templateUrl = getTemplateUrl();

        if (!$scope.id) {
          $scope.subscription = {
            type: $scope.subscriptionType,
            periodicity: $location.search().periodicity
          };

          if ($scope.subscriptionType === 'Spree::PaidSubscription::Weekly') {
            $scope.subscription.weekly_id = $scope.sourceId;
          } else if ($scope.subscriptionType === 'Spree::PaidSubscription::SmartPass') {
            $scope.subscription.smart_pass_subscription_id = $scope.sourceId;
          }
        }

        updatePeriodicityLabel();

        if ($location.search().amount) {
          try {
            $scope.subscription.amount = parseFloat($location.search().amount);
          } catch (e) {
            console.error("Error parsing the 'amount' parameters:", e);
          }
        }
      }

      function getSourceId() {
        if ($scope.subscription) {
          return $scope.subscription.smart_pass_subscription_id || $scope.subscription.weekly_id;
        } else {
          return $location.search().source_id;
        }
      }

      // Returns the source-specific inner template url.
      function getTemplateUrl() {
        if ($scope.typeAlias !== 'generic') {
          const actionName = $scope.sourceId ? 'edit' : $scope.actionName;
          $scope.templatePath = `${$scope.typeAlias}_${actionName}.html`;

          if ($scope.sourceId) $scope.templatePath += `?id=${$scope.sourceId}`;
        }

        return `${$scope.templatesEndpoint}/${$scope.templatePath}`;
      }

      function updatePeriodicityLabel() {
        // TODO: Convert periodicity token into something human readable
      }

      /** Actions **/

      // Creates a new PaidSubscription for the user.
      // Can take an "autoActivate" boolean parameter => if true, "no Datatrans needed, gateway_params are present in the payload"
      // This step happens before Datatrans involvement,
      // which can easily lead to unfinished subscriptions.
      function createSubscription() {
        return $q((resolve, reject) => {
          const params = {
            subscription: {
              type: $scope.subscriptionType || $scope.subscription.type,
              default_amount: $scope.subscription.amount,
              periodicity: $scope.subscription.periodicity,
              source_parameters: $scope.subscription.source_parameters
            }
          };

          $http.post(`${$scope.apiEndpoint}.json`, params).then(response => {
            angular.extend($scope.subscription, response.data);
            resolve({subscription: $scope.subscription, response: response.data});
          }, error => {
            $scope.initiatingPayment = false;
            Alerts.error(errorMessage(error));
            reject(error);
          });
        });
      }

      function deleteSubscription() {
        if (!$scope.subscription) return;

        if ($scope.subscription?.state && $scope.subscription?.state !== "active") {
          $http.delete(`${$scope.apiEndpoint}/${$scope.subscription.id}.json`).then(response => {
            $scope.subscription = null;
            console.log("Subscription deleted successfully.");
          }, error => {
            console.error("Error deleting subscription:", error);
          });
        }
      }

      $scope.$on("subscriptionDeleted", () => {
        $scope.subscription = null;
        initializeSubscription();
      });

      function destructor() {
        deleteSubscription();
        window.PaymentsSubscribePageCtrl = null;
      }

      function launchPayment() {
        SubscriptionPaymentService.launch($scope.subscription).then(result => {
          $scope.subscription = result;

          if ($scope.onSuccessfulPayment && typeof($scope.onSuccessfulPayment) === 'function') {
            $scope.onSuccessfulPayment();
          }
        });
      }

      function isFarmypassUrl() {
        return $location.path().includes('farmypass');
      }

      function subscriptionType() {
        const smartPassType = isFarmypassUrl() ? "farmypass" : $scope.smartPassService.smartPassType;

        if ($scope.id && $scope.subscription) {
          return $scope.subscription.type.replace('smart_pass', smartPassType);
        }

        return smartPassType;
      }

      //
      // Public members:
      //

      /** Main Event Handler **/
      // If PaidSubscription is present => show confirmation popup.
      // Else => create new PaidSubscription and initiate payment.
      $scope.onPayClick = function() {
        if ($scope.subscription.id) {
          launchPayment();
        } else {
          createSubscription().then(result => {
            launchPayment();
          });
        }
      };

      // Auto-renew toggle.
      $scope.toggleRenewable = function() {
        return $q((resolve, reject) => {
          if (!$scope.subscription || !$scope.subscription.id) {
            reject('No subscription found.');
            return;
          }

          $http.patch(`${$scope.apiEndpoint}/${$scope.subscription.id}/toggle_auto_renew.json`).then(response => {
            $scope.subscription = response.data;
            resolve($scope.subscription);
          });
        });
      };

      // Launch
      $scope.$on('$destroy', destructor);
      constructor();
    }
  ]);
