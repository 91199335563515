/**
 * Necessary providers for ngr-component.
 */

import React from "react";
import {BrowserRouter} from "react-router-dom";
import {ReduxTinyProvider} from "../../shared-services/redux-tiny";

export const NgrContextProvider = ({children}) => {
  return <ReduxTinyProvider>{children}</ReduxTinyProvider>;
};

export const NgrRouterProvider = ({children}) => {
  return <BrowserRouter>{children}</BrowserRouter>;
};
