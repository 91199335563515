angular.module('app')
  .directive('cacheView', ['$templateCache', function($templateCache)
  {
    return {
      restrict: 'A',
      compile:  function (element)
      {
        $templateCache.put('static_view.html', element.html());
      }
    };
  }]);