'use strict';

angular.module('app').controller('ParallaxJumboCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$timeout', '$http', '$element', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $timeout, $http, $element, Alerts) {
    window.ParallaxJumboCtrl = $scope;

    // Public members


    // Private members

    function constructor() {

    }

    function destructor() {
      window.ParallaxJumboCtrl = null;
    }

    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
