import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app')
  .directive('farmyCatalogBreadcrumbs', function() {
    return {
      controller: ['$rootScope', '$scope', '$http', '$q', '$timeout', '$attrs', '$element', 'TaxonHelper', 'CatalogServiceHelper', function ($rootScope, $scope, $http, $q, $timeout, $attrs, $element, TaxonHelper, CatalogServiceHelper) {
        window.farmyCatalogBreadcrumbs = $scope;

        function constructor() {
          $scope.$watch('CatalogServiceHelper.currentTaxon', (newValue, oldValue) => {

          });
        }

        function destructor() {
          window.farmyCatalogBreadcrumbs = null;
        }

        // Public members

        $scope.CatalogServiceHelper = CatalogServiceHelper;

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      scope: {

      },
      link: function($scope, $element, attributes) {

      },
      template: `<div class="breadcrumbs" id="breadcrumbs-container">
  <ol class="breadcrumb" itemscope="" itemtype="http://schema.org/BreadcrumbList">
    <li class="" itemprop="itemListElement" itemscop="" itemtype="http://schema.org/ListItem" ng-repeat="parentTaxon in CatalogServiceHelper.currentTaxon.parents | orderBy:'-' track by $index" ng-if="parentTaxon.depth > 0">
      <a itemprop="item" ng-href="{{parentTaxon.seo_url}}" tabindex="0">
          <span class="name">{{ parentTaxon.name }}</span>
          <meta content="{{ $index }}" itemprop="position">
      </a>
    </li>
    <li class="" itemprop="itemListElement" itemscop="" itemtype="http://schema.org/ListItem">
      <a itemprop="item" ng-href="{{parentTaxon.seo_url}}" tabindex="0">
          <span class="name">{{ CatalogServiceHelper.currentTaxon.name }}</span>
          <meta content="1" itemprop="position">
      </a>
    </li>
  </ol>
</div>`
    }
  });


