import * as _ from 'underscore';
import * as sprintf from 'sprintf';

angular.module('app').service('UserRatings', ['$rootScope', '$q', '$http', '$interval', '$uibModal', '$localStorage', '$timeout', '$translate', '$location', 'Alerts', function ($rootScope, $q, $http, $interval, $uibModal, $localStorage, $timeout, $translate, $location, Alerts) {
  var scope = this;

  window.UserRatings = this;

  /**
   * Placeholder for hub and zipcode delivery terms
   * @type {{}}
   */

  scope.getUserRatings = function(options) {
    let params = options ? `?${$.param(options)}` : '';

    return $q((resolve, reject) => {
      $http.get(`/api/frontend/users/${window.currentUserId}/user_ratings.json${params}`).then((response) => {
        $rootScope.userRatings = response.data.user_ratings;
        return resolve(scope.userRatings)
      }, (e) => { reject(e) })
    });
  };

  // Watches other opened modals before openning
  function softOpenUserRatingsModal() {
    if(window.isReactivationPage) return;

    if ($('.modal:visible').length > 0) {
      let modalWatcher = $rootScope.$watch(() => {$('.modal:visible').length}, (newVal) => {
        if (newVal == 0) {
          openUserRatingsModal();
          modalWatcher()
        }
      })
    } else {
      openUserRatingsModal()
    }
  }

  function openUserRatingsModal() {
    let userRatingsModal = $uibModal.open(
        {
          animation: true,
          size: 'md',
          keyboard: true,
          backdrop: true,
          templateUrl: `/ng/templates/user_ratings/user_ratings_modal.html?locale=${I18n.locale}`,
          windowClass: 'user-ratings-modal modal-rounded',
          controller: 'UserRatingsModalController'
        });

    userRatingsModal.result.then((response) => {
      if (response){
        submitRatings(response.dismiss);
      }
    }, () => {
      submitRatings(true)
    });
  }

  function submitRatings(dismiss) {
    dismiss = dismiss || false;

    let ratings = _($rootScope.userRatings).map((rating) => {return _(_(rating).pick('id', 'score', 'comments')).extend({dismiss: dismiss})});

    $http.post(`/api/frontend/users/${window.currentUserId}/user_ratings/submit.json`, {user_ratings: ratings}).then((response) => {
      if (!dismiss)
        Alerts.success($translate.instant('farmy.user_ratings.thanks'))
    })
  }

  $rootScope.$watch('userRatings', () => {
    if ($rootScope.userRatings && $rootScope.userRatings.length > 0) {
      softOpenUserRatingsModal();
    }
  });

  $rootScope.$on('$locationChangeSuccess', () => {
    if (window.currentUserId) {
      let options = _(_($location.$$search).pick('order_rating_ids')).extend({'pending': true});
      scope.getUserRatings(options)
    }
  });

  $rootScope.$on('$destroy', () => {
    if (unOnHubsChanged) unOnHubsChanged();
    if (unOnUserAuthenticated) unOnUserAuthenticated();
  })
}]);
