appModule.directive('farmyWebsiteFooter', ['$translate', function($translate) {
  return {
    restrict: 'E',
    controller: ['$scope', 'UserService', function ($scope, UserService) {
      $scope.UserService = UserService;
    }],
    link: function (scope, element, attrs) {
      scope.locale = $translate.use();

      scope.Hubs = {
        currentHubId: 'foo'
      };

      scope.footerTemplate = window.currentStorefront ? `/ng/templates/nav/footer_${window.currentStorefront}.html` : '/ng/templates/nav/footer.html';
    },
    template: function() {
      return `<farmy-ng-include src="footerTemplate"></farmy-ng-include>`
    }
  };
}]);