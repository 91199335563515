import * as _ from "underscore";
import {trackEvent} from "../services/tracking-helper";
import cartParser from "../react/cart/cartParser";
import {cartTools} from "../react/cart/useCart";

// eslint-disable-next-line no-unused-expressions
"use strict";

/**
 * Listens to shipping zipcode changes and runs cart contents validations related
 * to the shipping destination (Hubs compatibility, etc.)
 *
 * A visual element of the shipping stage is linked to scope variables
 * modified by this method.
 */
angular.module("app")
  .directive("farmyCartSidebar", function() {
    return {
      controller: ["$rootScope", "$scope", "$http", "$q", "$window", "$timeout", "$interval", "$location", "Alerts", "blockUI", "$attrs", "$element", "CartData", "Hubs", "AddressHelper", "OrderHelper", "UserService", "UiStateService", "ProductNavigation", "$translate", "UnitsInflector", "SmartPassService",
        function($rootScope, $scope, $http, $q, $window, $timeout, $interval, $location, Alerts, blockUI, $attrs, $element, CartData, Hubs, AddressHelper, OrderHelper, UserService, UiStateService, ProductNavigation, $translate, UnitsInflector, SmartPassService) {
          window.farmyCartSidebar = $scope;
          $scope.ProductNavigation = ProductNavigation;
          $scope.SmartPassService = SmartPassService;

          //
          // Public members:
          //

          $scope.mode = window.isMobile ? "mobile" : "desktop";
          $scope.CartData = CartData;
          $scope.Hubs = Hubs;
          $scope.UserService = UserService;
          $scope.productDataCache = [];
          $scope.oldLocale = $translate.use() || I18n.locale || "de";

          const $ = window.$;

          let contentContainer;
          let contentParent;
          let layoutUpdateInterval;
          let lateProductDataCheckInterval;
          let currentLocation;
          let unOnLocationChangeSuccess, unOnLayoutChangeRequest;

          const {lineItemFromProduct, variantById} = cartTools();

          const timeouts = {
            short: 500,
            long: 1500
          };

          const parseCart = cartParser();

          function constructor() {
            updateLayout();

            angular.element($window).bind("resize", () => {
              updateLayout();
            });
            angular.element($window).bind("scroll", () => {
              updateLayout();
              $timeout(() => {
                updateLayout();
              });
            });

            $scope.toggleCartSidebar = function() {
              $scope.cartSidebarVisible = window.farmyCartSidebar.cartSidebarVisible = !window.farmyCartSidebar.cartSidebarVisible;
              if (window.farmyMobileSidebar) window.farmyMobileSidebar.hideSidebar();
            };

            $rootScope.$on("$translateChangeSuccess", function(event, response) {
              const locale = $scope.oldLocale || $translate.use() || I18n.locale;
              if (response.language !== locale) {
                $scope.oldLocale = response.language;
              }
            });

            $scope.updateCart = function(response, shouldParse = false) {
              let cart = response?.cart?.cart || response?.cart || response;

              if (cart?.id) {
                if (shouldParse) cart = parseCart(cart);
                $scope.order = cart;
                $scope.lineItems = cart?.line_items;
                updateLayout();
              }
            };

            $rootScope.$on("cart:updated", (event, response) => $scope.updateCart(response));
            $rootScope.$on("cart:changed", (event, response) => $scope.updateCart(response, true));

            unOnLayoutChangeRequest = $rootScope.$on("layout:changerequest", () => {
              updateLayout();
            });

            // Initialize line item reference right away, if it's available
            if (CartData.cart.line_items) {
              $scope.lineItems = CartData.cart.line_items;
              $timeout(updateLayout, 1);
            }

            unOnLocationChangeSuccess = $scope.$on("$locationChangeSuccess", function() {
              updateLayout();
            });
          }

          function destructor() {
            window.farmyCartSidebar = null;
            if (layoutUpdateInterval) clearInterval(layoutUpdateInterval);
            if (lateProductDataCheckInterval) $interval.cancel(lateProductDataCheckInterval);
            if (unOnLocationChangeSuccess) unOnLocationChangeSuccess();
            if (unOnLayoutChangeRequest) unOnLayoutChangeRequest();
          }

          function getFooterOffset() {
            return Math.max(0, $(window).height() - $("farmy-website-footer")[0].getBoundingClientRect().top);
          }

          /**
           * Calculates if the sidebar should be dislayed based on user agent
           * and window size
           */
          function updateCartSidebarState() {
            currentLocation = $location.path();

            $scope.inCheckout = !!window.CheckoutCtrl;
            $scope.noCheckoutButton = $scope.inCheckout || window.location.href.indexOf("/cashier") > -1 || window.location.href.indexOf("/thankyou") > -1;
            $scope.showRegistrationInfo = currentLocation.indexOf("/signup") > -1;

            const oldVisibleState = UiStateService.cartSidebarVisible;

            const sidebarExcludedPages = /\/(cart|wocheneinkauf|order_status|farmy_family\/edit|farmypass\/new)/;

            // On mobile the sidebar is always available, but folded
            // (see mobile component template for details)
            if (!Hubs.currentZipcode || window.supplierPortalMode || location.pathname.includes("/ct/")) {
              UiStateService.cartSidebarVisible = window.NgFrontendAppCtrl.enableCartSidebar = false;
            } else if ($scope.mode === "mobile") {
              UiStateService.cartSidebarVisible = window.NgFrontendAppCtrl.enableCartSidebar = true;
            } else if (UiStateService.noCartSidebarRequested) {
              UiStateService.cartSidebarVisible = window.NgFrontendAppCtrl.enableCartSidebar = false;
            } else { // on desktop, the sidebar will be enabled starting from a certain screen width
              if (sidebarExcludedPages.test(currentLocation)) {
                UiStateService.cartSidebarVisible = window.NgFrontendAppCtrl.enableCartSidebar = false;
              } else if (window.innerWidth > 1405 && CartData.cart && CartData.cart.line_items && CartData.cart.line_items.length > 0) {
                UiStateService.cartSidebarVisible = window.NgFrontendAppCtrl.enableCartSidebar = true;
              } else {
                UiStateService.cartSidebarVisible = window.NgFrontendAppCtrl.enableCartSidebar = false;
              }
            }

            // Emit layout:update event in case the state changed
            if (oldVisibleState !== UiStateService.cartSidebarVisible) {
              $timeout(() => {
                $rootScope.$broadcast("layout:update");
              }, 250);
            }
          }

          /**
           * Notifies the checkout controller of changes (if a checkout is in progress)
           */
          function notifyCheckout() {
            if (!window.CheckoutCtrl) {
              return;
            }

            window.CheckoutCtrl.getCheckout().then(() => {
              window.CheckoutCtrl.getOrder();
            });
          }

          function updateLayout() {
            updateCartSidebarState();

            contentContainer = $("#page-content-main.page-content");
            contentParent = $($(contentContainer).parent());

            if ($scope.mode === "desktop") {
              let width;

              if (window.NgFrontendAppCtrl.enableCartSidebar) {
                width = 230;
                let top;
                const footerOffset = getFooterOffset();

                if (window.NgFrontendAppCtrl.isHeaderAffixed()) {
                  top = _.chain($("header .affix")).map(e => $(e).outerHeight()).inject((memo, num) => memo + num, 0).value();
                  const offset = 0;// parseInt($('.container-fluid.affix').data('offset'));
                  $($element).css({right: 0, width, top: top + offset, bottom: footerOffset});
                } else {
                  top = $($("header")[0]).outerHeight() + 14; // $('header.header-main').height();
                  $($element).css({right: 0, width, top, bottom: footerOffset});
                }

                contentContainer.css({width: contentParent.width() - width});
              } else {
                contentContainer.css({width: contentParent.width()});
              }
            } else {
              // TODO: Mobile mode support?
            }
          }

          /**
           * Indicates that the session is currently in checkout state
           * @type {boolean}
           */
          $scope.inCheckout = !!window.CheckoutCtrl;

          $scope.noCheckoutButton = $scope.inCheckout || window.location.href.indexOf("/cashier") > -1 || window.location.href.indexOf("/thankyou") > -1;

          /**
           * Flag used to toggle visibility of the foldable cart sidebar (on mobile)
           *
           * @type {boolean}
           */
          $scope.cartSidebarVisible = false;

          $scope.isMobile = window.isMobile;

          /**
           * If the user can edit line items in the sidebar
           *
           * @type {boolean}
           */
          $scope.canChangeItems = false;

          $scope.goToCheckout = function() {
            const checkoutPath = window.isMobile ? "/cashier" : "/cart";

            if (CartData.cartBlocked) {
              CartData.showLowCapacityPopup();
              return;
            }

            if (CartData.cart.item_total < CartData.cart.minimum_order_value) {
              Alerts.error($translate.instant("errors.minimum_order_value_error", {amount: CartData.translationData.minimum_order_value_formatted}));
              return;
            }
            // TODO: Tracking checkout start, ahoy, hotjar, GA etc.
            const dryIce = CartData.cart?.adjustments.find(adjustment => adjustment.code === ":dry_ice");
            const freeShipping = CartData.cart?.adjustments.find(adjustment => adjustment.code === ":smart_pass");
            const gtmObject = {
              value: CartData.cart?.total,
              coupon: CartData.cart?.coupon_code || "",
              itemtotal: CartData.cart?.item_total,
              shipping: freeShipping ? 0 : CartData.cart?.ship_total,
              tax: parseFloat((CartData.cart.total + CartData.cart.weight_control_surcharge_total - CartData.cart.item_total).toFixed(2)),
              dryice: dryIce?.amount || 0,
              handcut: CartData.cart?.weight_control_surcharge_total,
              smallorder: CartData.cart?.handling_fee
            };
            if (window.isMobile) trackEvent("begin_checkout", {gtmObject, products: CartData.cart.line_items});
            if (UserService.isLoggedIn) {
              if (window.isMobile) $scope.cartSidebarVisible = false;
              $location.path(checkoutPath).hash("").search("");
            } else {
              if (window.isMobile) $scope.cartSidebarVisible = false;
              $location.path("/signup").hash("").search({checkout: "t", login: "t", locale: $translate.use()});
            }
          };

          $scope.onItemClick = function(lineItem) {

          };

          /**
           * Update the line item variant on user input, save the cart.
           *
           * @param lineItem
           */
          $scope.onVariantChange = function(lineItem) {
            // Check that the lineItem doesn't already exist, to prevent this bug: https://farmyag.atlassian.net/browse/IM-11022
            const oldVariant = variantById(lineItem?.productData, lineItem.variant_id);
            const currentLineItem = lineItemFromProduct(lineItem?.productData);
            const isSameVariant = currentLineItem?.variant_id === lineItem.variant.id;
            if (isSameVariant) return;

            lineItem.viewMode = "view";
            CartData.setCartVariant(lineItem.product_id, lineItem?.variant || lineItem?.currentVariant, "cart-sidebar", null, "cart", {oldVariant, callerLocation: "cart-sidebar"}).then(() => {
              CartData.save().then(() => {
                notifyCheckout();
              });
            });
          };

          $scope.removeItem = function(lineItem) {
            CartData.removeLineItem(lineItem).then(() => {
              trackEvent(
                "removefromcart",
                {
                  gtmObject: {value: lineItem?.price},
                  products: {...lineItem, productQuantity: lineItem?.variant?.quantity_index}
                }).finally(() => {
                trackEvent(
                  "product_removed_from_cart",
                  {
                    gtmObject: {value: lineItem?.price},
                    products: {...lineItem, productQuantity: lineItem?.variant?.quantity_index}
                  });
              });
              notifyCheckout();
            });
          };

          $scope.showCartSidebar = function(show) {
            UiStateService.cartSidebarVisible = window.NgFrontendAppCtrl.enableCartSidebar = show;
          };

          $scope.showHandlingFeeModal = OrderHelper.showHandlingFeeModal;

          $scope.onAdjustmentDescriptionClick = function(adjustment) {
            if (adjustment.permabox_adjustment) OrderHelper.showPermaboxDepositExplanationModel();
            if (adjustment.code === ":handling_fee") OrderHelper.showHandlingFeeModal($scope.order);
            if (adjustment.code === ":weight_control") $rootScope.showWeightControlModal($scope.order);
          };

          $scope.onNoFeesPromoClicked = function() {
            OrderHelper.showNoFeesPromoModal();
          };

          // Initialize

          $scope.$on("$destroy", destructor);
          constructor();
        }],
      scope: {},
      link: function($scope, $element, attributes) {
      },
      template: function() {
        if (window.isMobile) {
          return "<farmy-ng-include src=\"'/ng/templates/carts/cart_sidebar_mobile.html'\"></farmy-ng-include>";
        } else {
          return "<farmy-ng-include src=\"'/ng/templates/carts/cart_sidebar.html'\"></farmy-ng-include>";
        }
      }
    };
  });
