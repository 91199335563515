angular.module('app').service('CmsUtils', ['$rootScope', '$q', '$http', '$uibModal', function ($rootScope, $q, $http, $uibModal) {
  var scope = this;

  var modalInstances = {};

  this.showPopup = function(pageId) {
    var modalInstance = $uibModal.open({
      animation: $rootScope.animationsEnabled,
      templateUrl: '/api/frontend/cms_pages/' + pageId + '/content.html' + '?locale=' + I18n.locale,
      controller: 'CmsPageModalCtrl',
      windowClass: 'modal-rounded modal-cms-page',
      resolve: {
        pageId: function() {
          return pageId;
        }
      }
    });

    modalInstances[pageId] = modalInstance;

    modalInstance.result.then(function(result) {
      if (modalInstances[pageId])
        delete(modalInstances[pageId]);
    }, function () { });
  };

  window.CmsUtils = scope;

  $("body").trigger("angular:cms_utils_loaded");
}]);
