'use strict';

angular.module('app').controller('RewardsPagePanelCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$localStorage', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, $localStorage, Alerts) {
    console.log("RewardsPagePanelCtrl controller initialized with scope");

    $scope.eggs = [
      { number: 1, showNumber: false, face: 'gray', claimed: false },
      { number: 2, showNumber: true, face: 'gray', claimed: false },
      { number: 3, showNumber: true, face: 'yellow', claimed: false },
      { number: 4, showNumber: false, face: 'gray', claimed: false },
      { number: 5, showNumber: true, face: 'yellow', claimed: false },
      { number: 6, showNumber: false, face: 'gray', claimed: false },
      { number: 7, showNumber: true, face: 'gray', claimed: false },
      { number: 8, showNumber: false, face: 'yellow', claimed: false },
      { number: 9, showNumber: false, face: 'gray', claimed: false },
      { number: 10, showNumber: true, face: 'yellow', claimed: false },
    ];

    $scope.updateEggs = function() {
      _.each($scope.eggs, function(egg, num) {
        if(egg.number <= $scope.rewards.points)
          egg.claimed = true;
        else egg.claimed = false;
      });
    };

    $scope.$watch('rewards', function(newValue, oldValue) {
      if(newValue != null)
        $scope.updateEggs();
    });
  }
]);
