
class ProductLoader {
    constructor() {

    }

    load(params) {
        return new Promise((resolve, reject) => {
            this.getProvider().get(`/api/products.json`, { params: params }).then(response => {
                resolve(response.data);
            }).catch(error => {
                console.error("Error loading products:", error);
                reject(error);
            });
        })
    }

    getProvider() {
        return angular.element(document.body).injector().get('$http')
    }
}

export default ProductLoader
