import * as _ from 'underscore';

angular.module('app').controller('SelectCurrentOrderModalView', ['$scope', '$rootScope', '$location', '$interval', '$timeout', '$translate', '$http', '$q', '$localStorage', 'Alerts', 'UserService', '$uibModalInstance', 'CartData', 'blockUI', 'orderData',
  function ($scope, $rootScope, $location, $interval, $timeout, $translate, $http, $q, $localStorage, Alerts, UserService, $uibModalInstance, CartData, blockUI, orderData) {
    function constructor() {
      window.SelectCurrentOrderModalView = $scope;

      $scope.orders = orderData.orders;

      for(var i in $scope.orders) {
        if ($scope.orders[i].channel == 'spree')
          $scope.orders[i].channelName = 'Web'
        else if ($scope.orders[i].channel == 'app') {
          $scope.orders[i].channelName = `App / ${$scope.orders[i].subchannel}`;
        }
      }
    }

    function destructor() {
      window.SelectCurrentOrderModalView = null;
    }

    $scope.setAsCurrentOrder = function(order, dontCloseModal) {
      blockUI.start();

      if (window.ahoy) ahoy.track("select-cart-modal-submit", { option_count: $scope.orders && $scope.orders.length, new_order_id: order && order.id, channel: window.xSessionChannel });

      return CartData.setCurrentOrder(order).then(() => {
        UserService.setPreferredCartOrderSetAt(new Date());
        blockUI.stop();

        if (dontCloseModal != true) {
          $uibModalInstance.dismiss(order);
        }
      });
    };

    $scope.destroyOrder = function(order) {
      if (confirm($translate.instant("confirmation_prompt.body"))) {
        blockUI.start();

        return $q((resolve, reject) => {
          // If the order is current and we have another order to switch to,
          // this current order can be safely removed.
          if (order.isCurrent) {
            // Find another order to use as current:
            let otherOrder = _.find($scope.orders, o => o.id != order.id);

            if (otherOrder) {
              return $scope.setAsCurrentOrder(otherOrder, true).then((result) => {
                if (window.ahoy) ahoy.track("select-cart-modal-delete-order", { option_count: $scope.orders && $scope.orders.length, destroy_order_id: order && order.id, channel: window.xSessionChannel });

                return $http.post(`/api/frontend/orders/${order.number}/destroy_order.json`, { order_token: order.token }).then(response => {
                  $scope.orders = _.reject($scope.orders, o => o.id == order.id);

                  blockUI.stop();

                  resolve(response.data);
                })
              });
            } else {
              blockUI.stop();
              reject("Cannot delete the only order");
            }
          } else {
            // Just destroy the order and remove it from the list
            if (window.ahoy) ahoy.track("select-cart-modal-delete-order", { option_count: $scope.orders && $scope.orders.length, destroy_order_id: order && order.id, channel: window.xSessionChannel });

            return $http.post(`/api/frontend/orders/${order.number}/destroy_order.json`, { order_token: order.token }).then(response => {
              $scope.orders = _.reject($scope.orders, o => o.id == order.id);

              blockUI.stop();

              resolve(response.data);
            })
          }
        })
      } else {
        return $q((resolve, reject) => {
          reject("Action aborted by user");
        })
      }
    };

    /**
     * Tells the server that that specific order can be disposed of
     *
     * @param order
     */
    $scope.disposeOrder = function(order) {

    };

    $scope.dismiss = function() {
      if (window.ahoy) ahoy.track("select-cart-modal-cancelled", { option_count: $scope.orders && $scope.orders.length, channel: window.xSessionChannel });
      UserService.setPreferredCartOrderSetAt(new Date());
      $uibModalInstance.dismiss(null);
    };

    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
