import * as _ from 'underscore';
import * as moment from 'moment';

'use strict';

angular.module('app').controller('CheckoutPaymentByjunoCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', '$element', 'Alerts', 'blockUI', function ($scope, $sce, $http, $q, $rootScope, $timeout, $element, Alerts, blockUI) {
  window.CheckoutPaymentByjunoCtrl = $scope;

  // Public properties

  $scope.checkoutCtrl = $scope.$parent.$parent.$parent.$parent;
  $scope.paymentCtrl = $scope.$parent.$parent;

  $scope.byjunoForm = {};

  //
  // Private members
  //
  var invoicePaymentMethod;

  function constructor() {

  }

  function destructor() {
    window.CheckoutPaymentByjunoCtrl = null;
  }

  function getInvoicePaymentMethod() {
    if (invoicePaymentMethod == null) {
      invoicePaymentMethod = _.find($scope.checkoutCtrl.availablePaymentMethods, (m) => m.type == "Spree::Gateway::ByjunoGateway");
    }

    return invoicePaymentMethod;
  }

  //
  // Public methods
  //

  $scope.submitByjunoPayment = function() {
    blockUI.start();

    return $q(function (resolve, reject) {
      var billAddress = $scope.checkoutCtrl.billAddress;

      // Submit final order request.
      // Note: It is assumed that the bill address is already saved at this point
      var params = {
        payments_attributes: [
          { payment_method_id: getInvoicePaymentMethod().id } // the ID is from the available methods list
        ],
        payment_source: { }
      };

      params.payment_source[getInvoicePaymentMethod().id.toString()] = {
        country_id: billAddress.country_id,
        city: billAddress.city,
        firstname: billAddress.firstname,
        lastname: billAddress.lastname,
        address1: billAddress.address1,
        house_number: billAddress.house_number,
        title: billAddress.title,
        company: billAddress.company,
        zipcode: billAddress.zipcode,
        phone: billAddress.phone,
        birth_date: billAddress.birth_date,
        register_number: billAddress.register_number,
      };
      $scope.checkoutCtrl.trackAddPaymentInfo($scope.paymentCtrl.selectedPaymentMethod.name);
      $scope.checkoutCtrl.updateCheckout(params).then((r) => {
        resolve(r);
        blockUI.stop();
        console.log("Checkout was completed")
      });
    });
  };

  // Initialize

  $scope.$on('$destroy', destructor);
  constructor();
}]);
