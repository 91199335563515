import * as _ from 'underscore';
import {trackPageView, trackEvent} from '../services/tracking-helper';

'use strict';

angular.module('app').controller('CheckoutPaymentStageCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', '$element', 'Alerts', 'blockUI', 'CartData', 'UserNotifications', '$translate',
  function($scope, $sce, $http, $q, $rootScope, $timeout, $element, Alerts, blockUI, CartData, UserNotifications, $translate) {
    window.CheckoutPaymentStageCtrl = $scope;

    // Public properties

    $scope.checkoutCtrl = $scope.$parent.$parent;

    $scope.coupon = {code: null};
    $scope.couponForm = {};
    $scope.couponMessages = null;

    //
    // Private members
    //

    function constructor() {
      $scope.$watch('checkoutCtrl.stage', function(newValue) {
        if ($scope.checkoutCtrl.checkout && !$scope.checkoutCtrl.isCheckoutStageBefore('payment')) {
          $rootScope.$broadcast('paymentStage:loaded');

          if ($scope.checkoutCtrl.availablePaymentMethods == null) {
            $scope.checkoutCtrl.getPaymentMethods();
          }
          trackPageView('checkout', {products: $scope.checkoutCtrl.checkout?.line_items, checkout: $scope.checkoutCtrl.checkout});
        }
      });
    }

    function destructor() {
      window.CheckoutPaymentStageCtrl = null;
    }

    //
    // Public methods
    //

    $scope.selectPaymentMethod = function(paymentMethod) {
      if ($scope.selectedPaymentMethod === paymentMethod) {
        $scope.selectedPaymentMethod = null;
      } else {
        if (paymentMethod.type === 'Spree::Gateway::InvoiceGateway') {
          const overdueDate = $scope.order.invoice_overdue_date;

          if ($scope.order.invoice_max_limit_reached) {
            Alerts.error($translate.instant('checkout.invoice_limit'));
            return;
          }

          if ($scope.order.invoice_overdue_date) {
            Alerts.error($translate.instant('checkout.invoice_overdue', {date: overdueDate}));
            return;
          }
        }

        $scope.selectedPaymentMethod = paymentMethod;
        if (window.hj && paymentMethod) {
          try {
            window.hj('vpv', `/checkout/${$scope.checkoutCtrl.stage}/${paymentMethod.type.split('::')[2]}`);
          } catch (e) {
            // if (window.airbrake) window.airbrake.notify(e);
            console.error(e);
          }
        }
      }
    };

    /**
     * Gets an existing eligible adjustment for a Guthaben discount, if it exists
     */
    $scope.getAccountBalanceDiscountAdjustments = function() {
      _.select($scope.checkoutCtrl.adjustments, a => a.source_type === 'Spree::UserAccountTransaction' && a.eligible);
    };

    /**
     * Gets a sum of all existing eligible adjustment for a Guthaben discount, if it exists
     */
    $scope.getAccountBalanceDiscountAdjustmentSum = function() {
      _.chain($scope.checkoutCtrl.adjustments).select(a => a.source_type === 'Spree::UserAccountTransaction' && a.eligible).map(a => a.amount).inject((memo, num) => memo + num, 0);
    };

    $scope.submitPayment = function() {
      // TODO
    };

    $scope.submitCouponCode = function() {
      blockUI.start();
      $scope.couponForm.code.$error.invalid = $scope.couponForm.code.error = null;
      $scope.couponForm.$submitted = false;
      $scope.couponMessages = null;

      return $q((resolve, reject) => {
        $scope.checkoutCtrl
          .updateCheckout({coupon_code: $scope.coupon.code}, true)
          .then(c => {
            $scope.coupon.applied = true;
            trackEvent("coupon_code_applied",
              {
                coupon: c.coupon_code,
                coupon_message: c?.coupon_messages[0],
                products: c.line_items,
                checkout: c
              });
            if (c?.coupon_messages)
              $scope.couponMessages = c.coupon_messages.join(' ');
            resolve(c);
            blockUI.stop();
            CartData.load();
            if (window.CheckoutCtrl) {
              window.CheckoutCtrl.updateCheckout(null, true);
            }
            $scope.checkoutCtrl.getPaymentMethods(); // Guthaben might now have some new funds
          }, function(e) {
            $scope.coupon.applied = false;
            $scope.couponForm.code.$error.invalid = true;
            $scope.couponForm.$submitted = true;
            $scope.couponForm.code.error = e.data.errors.coupon_code[0];
            $scope.couponMessages = $scope.couponForm.code.error;
            trackEvent("coupon_code_rejected",
              {
                coupon: $scope.coupon.code,
                coupon_message: $scope.couponMessages,
                products: $scope.checkoutCtrl.checkout?.line_items,
                checkout: $scope.checkoutCtrl.checkout
              });
            blockUI.stop();
          });
      });
    };

    $scope.submitOrderWithZeroTotal = function() {
      $scope.formError = null;

      blockUI.start();

      const params = {
        id: $scope.checkoutCtrl.checkout.id,
        number: $scope.checkoutCtrl.checkout.number
      };

      return $q(function(resolve, reject) {
        $scope.checkoutCtrl.updateCheckout(params).then((r) => {
          resolve(r);
          blockUI.stop();

          if (r.state === 'complete') console.log('Checkout was completed');
        }, e => {
          Alerts.error(errorMessage(e));
          blockUI.stop();
        });
      });
    };

    // Initialize

    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
