'use strict';

angular.module('app').controller('RewardsToolpanelCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$localStorage', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, $localStorage, Alerts) {

    $scope.$on('rewards:claimed', function(e) {
      $scope.loadPoints().then(function() {
        $element.find('.points').removeClass('animated refreshed');

        setTimeout(function() {
          $element.find('.points').addClass('animated refreshed');
        }, 10);
      });
    });

    $scope.loadPoints = function() {
      return $http.get('/api/frontend/rewards/points.json').then(function(response) {
        var numOfEggs = response.data.rewards.points;
        $scope.eggs = 0;
        if (numOfEggs > 0) {
          $scope.loading = true;
          $interval(function(){
            $scope.eggs++;
            if ($scope.eggs == numOfEggs) $scope.loading = false;
          }, 1, numOfEggs)
        }
      })
    };

    if(window.currentUserId && !window.location.pathname.match(/^\/checkout/)) { // Skip checkout pages to avoid distractions
      $scope.loadPoints()
    } else {
      $scope.eggs = null;
    }
  }
]);
