import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

/**
 * Watches notifications from the Notifications service
 * and displays an ordered queue of messages
 *
 * Markup example (restricted to 'element' only):
 *  <farmy-notifications-dock></farmy-notifications-dock>
 *
 */
angular.module('app')
  .directive('farmyNotificationsDock', function() {
    return {
      restrict: 'E',
      scope: {},
      controller: ['$scope', '$rootScope', '$timeout', 'FarmyNotifications', 'blockUI', function ($scope, $rootScope, $timeout, FarmyNotifications, blockUI) {
        window.farmyNotificationsDock = $scope;

        $scope.displayDock = false;
        $scope.showBullet = false;
        $scope.bulletStyle = 'info';
        $scope.notifications = FarmyNotifications.notifications;

        function constructor() {
          // Watches the queue for new additions.
          $scope.$watch('notifications.length', function(newVal, oldVal) {
            if (newVal > 0) {
              if (blockUI.isBlocking()) {
                var blockUIWatcher = $scope.$watch(function() { return blockUI.isBlocking() }, function(watching) {
                  if (!watching) {
                    moveQueue();
                    blockUIWatcher();
                  }
                })
              } else {
                moveQueue()
              }
            } else {
              modalModeOff()
            }
          }, true);
        }

        function destructor() {
          window.farmyNotificationsDock = null;
        }

        function trigger(notification) {
          notification.current = true;
        }

        function moveQueue() {
          if (!_($scope.notifications).some(function(i) { return i.current || i.visible })) {
            if ($scope.notifications[0].modal) {
              modalModeOn()
            } else {
              modalModeOff()
            }
            trigger($scope.notifications[0])
          }
        }

        function modalModeOn() {
          $('farmy-notifications-dock').addClass('modal-mode-active').addClass('modal-mode');
        }

        function modalModeOff() {
          $('farmy-notifications-dock').removeClass('modal-mode');
          $timeout(function(){
            $('farmy-notifications-dock').removeClass('modal-mode-active')
          }, 200);
        }

        // Initialize

        $scope.$on('$destroy', destructor);
        constructor();
      }],

      template: `<div farmy-ng-include="'/ng/templates/farmy_notifications/notifications_dock.html?locale=${window.I18n.locale}'" style="display: flex; justify-content: center"></div>`
    }
  });


