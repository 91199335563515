'use strict';

angular.module('app').controller('ProductRecipeViewCtrl', ['$scope', '$rootScope', '$window', '$http', '$element', 'Alerts', 'CartData', '$uibModal',
  function ($scope, $rootScope, $window, $http, $element, Alerts, CartData, $uibModal) {
    // console.log("ProductRecipeViewCtrl controller initialized with scope");

    $scope.recipe = null;
    $scope.unavailableProducts = [];
    $scope.ageRestrictedRecipe = false;

    $scope.addIngredientsToCart = function(e) {

      if ($scope.ageRestrictedRecipe && !window.currentUserAgeVerified) {
        window.FarmyCartAgeVerificationPopup.show(function () {
          window.currentUserAgeVerified = true;
          $scope.increaseCartQuantity(null);
        });
        return;
      }

      _($scope.recipe.recipe_products).each(function(product, index) {
        if (product.available) {
          if (product.variantIndex == null)
            product.variantIndex = product.quantity_index;

          // Fail-safe
          if (product.variantIndex >= product.variants.length)
            product.variantIndex = product.variants.length - 1;

          var variantInCart = product.variants[product.variantIndex];

          if (variantInCart) product.inCart = true;

          return CartData.setCartVariant(product.id, {
            id: variantInCart.id,
            price: variantInCart.price,
            quantity_in_units: variantInCart.qiu
          }).then(function() {
            updateRecipeProduct(product, index);
            Tracking.sendCartAddRecipeEvent($scope.recipe.product_id, $scope.recipe.title);
          });
        }
      });

      openAllProductsAddedPopup();
    };

    $scope.loadRecipe = function() {
      return $http.get(sprintf('/api/frontend/product_recipes/%s.json?locale=' + I18n.locale, $scope.recipeProductId.toString())).then(function(response) {
        $scope.recipe = response.data.recipe;
        $scope.setCartVariantIndexes();

        Tracking.sendRecipeViewedEvent($scope.recipe.product_id, $scope.recipe.title);
      });
    };

    $scope.setCartVariantIndexes = function() {
      $scope.ageRestrictedRecipe = false;
      _($scope.recipe.recipe_products).each(function(product) {
        if (CartData.containsProduct(product.id)) {
          var lineItem = _(CartData.cart.line_items).find(function(li) {
            return li.product_id == product.id;
          });

          product.variantIndex = product.variants.indexOf(
            _(product.variants).find(function(v) { return v.id == lineItem.variant.id; })
          ) + 1;

          if (product.available && product.age_restricted) $scope.ageRestrictedRecipe = true;
        }
        if (product.age_restricted) {
          $scope.ageRestricted = true;
        }
        if (!product.available){
          $scope.unavailableProducts.push(product.name);
        }
      })
    };

    var openAllProductsAddedPopup = function() {
      var modalTemplate = '/ng/templates/recipes/all_products_added_modal.html?locale=' + I18n.locale;
      var AllProductsAddedModalInstance = $uibModal.open({
        animation: false,
        size: 'sm',
        templateUrl: modalTemplate,
        backdrop: true,
        windowClass: 'all-added-modal',
        controller: 'AllProductsAddedModalCtrl',
        scope: $scope
      });

      // unavailableModalInstance.result.finally(function(result){
      //   $scope.isModalOpen = false;
      //   $scope.showBackButton = true;
      //   $scope.animationFinished = true;
      // })
    };

    $scope.$watch('recipeProductId', function(productId, oldValue) {
      $scope.loadRecipe();
    });


    // Private

    var updateRecipeProduct = function(product, index) {
      $scope.recipe.recipe_products[index].inCart = product.inCart;
      $scope.recipe.recipe_products[index].variantIndex = product.variantIndex + 1;
    };
  }
]);
