import * as _ from 'underscore';

/**
 * @desc Reusable component for hofPass selection.
 * Will react to zipcode changes updating the HP zone.
 * If spId present, will allow only upgrade options.
 * Returns a SmartPassSubscription object inside.
 *
 * @example <smart-pass-selector
 *            zipcode="zipcode"
 *            sp-type="spType"
 *            sp-duration="spDuration"
 *            on-change="myOwnCallbackFunction"> => will return with a SmartPassSubscription as parameter.
 *          </smart-pass-selector>
 */
angular.module('app').directive('smartPassSelector', () => {
  return {
    controller: ['$rootScope', '$scope', '$http', '$timeout', '$element', 'SmartPassService', function($rootScope, $scope, $http, $timeout, $element, SmartPassService) {
      window.smartPassSelector = $scope;
      $scope.smartPassService = SmartPassService;

      function constructor() {
        loadAvailableSmartPasses();

        $rootScope.$on('zipcode:changed', () => loadAvailableSmartPasses());

        $scope.spIdWatcher = $scope.$watch('spId', (newVal, oldVal) => {
          if (newVal != oldVal) {
            [$scope.spDuration, $scope.spType] = [null, null];
            loadAvailableSmartPasses();
          }
        })
      }

      function destructor() {
        window.smartPassSelector = null;
      }

      $scope.onSpSelectionChanged = function(type, duration) {
        if (!type || !duration) {
          [$scope.spType, $scope.spDuration, $scope.selectedSubscription] = [null, null, null];
          return;
        }

        $scope.spType = type;
        $scope.spDuration = duration;

        $scope.selectedSubscription = $scope.availableSet[$scope.spType].plans[$scope.spDuration];

        if ($scope.selectedSubscription.disabled)
          $scope.selectedSubscription = null;
        else {
          $scope.selectedSubscription.level = $scope.spType;
          $scope.selectedSubscription.duration = $scope.spDuration;
        }

        onSelectionChanged();
      };

      $scope.onFarmypassSelectionChanged = function(plan) {
        if (!plan || !plan.level || !plan.duration) {
          [$scope.spType, $scope.spDuration, $scope.selectedSubscription] = [null, null, null];
          return;
        }

        $scope.spType = plan.level;
        $scope.spDuration = plan.duration;

        $scope.selectedSubscription = plan;

        if ($scope.selectedSubscription.disabled)
          $scope.selectedSubscription = null;

        onSelectionChanged();
      };

      function onSelectionChanged() {
        if ($scope.hasCallbackFunction)
          $scope.onChange()($scope.selectedSubscription);
      }

      function loadAvailableSmartPasses() {
        // If we are editing an existing SmartPassSubscription
        // we return specific availability for it.

        if ($scope.spId) {
          $scope.currentSmartPass = _($scope.smartPassService.subscriptions).find(s => s.id == $scope.spId);

          if ($scope.currentSmartPass && $scope.currentSmartPass.available_smart_passes) {
            $scope.availableSmartPasses = $scope.currentSmartPass.available_smart_passes;
            $scope.availableSet = $scope.availableSmartPasses;
          } else {
            $scope.smartPassService.loadSubscriptions({all: true, available_plans: true}).then((response) => {
              $scope.currentSmartPass = _($scope.smartPassService.subscriptions).find((s) => s.id == $scope.spId);
              // $scope.currentSmartPass.duration = $scope.currentSmartPass.duration.toString();

              if ($scope.currentSmartPass) {
                $scope.availableSmartPasses = $scope.currentSmartPass.available_smart_passes;
                $scope.availableSet = $scope.availableSmartPasses;
                completeSetData();
              }
            })
          }
        // else we return all available plans.
        } else {
          $http
            .get(`/api/frontend/smart_pass_subscriptions/available_smart_passes.json?locale=${I18n.locale}`)
            .then((response) => {
              $scope.availableSmartPasses = $scope.availableSet =
                $scope.smartPassService.smartPassType === 'farmypass'
                  ? response.data
                  : $scope.smartPassService.formatPlansToHofpass(response.data);
            });
        }
      }

      function completeSetData() {
        _(_($scope.availableSet).keys()).each((level) => {
          _(_($scope.availableSet[level].plans).keys()).each((duration) => {
            $scope.availableSet[level].plans[duration].isCurrent = level == $scope.currentSmartPass.level && duration == $scope.currentSmartPass.duration;
            $scope.availableSet[level].plans[duration].disabled = level == 'basic' && duration == 1 ? true : $scope.availableSet[level].plans[duration].total <= 0;
          })
        })
      }

      constructor();
      $scope.$on('$destroy', destructor);
    }],
    scope: {
      spId: '=',
      zipcode: '=',
      spType: '=',
      spDuration: '=',
      onChange: '&',
      canUpgrade: '<',
      revival: '&'
    },
    link: function($scope, $element, attributes) {
      // Since "ngChange" is inside the declared scope, it will always return a function.
      // This way we confirm that a callback function actually lives inside.
      if (attributes.onChange)
        $scope.hasCallbackFunction = true;
    },
    templateUrl: ($element, $scope) => {
      const templateType = SmartPassService.smartPassType === 'farmypass' ? 'farmypass' : 'smart_pass';
      return `/ng/templates/smart_pass/${templateType}_selector.html?locale=${I18n.locale}`;
    }
  }
});
