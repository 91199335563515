/**
 * NgrComponent => shared Angular to React component provider.
 * @param react-component => name of React page, expected in the following folder:
 *        /app/javascript/ng-frontend/[...]
 * @external => wraps in ReduxTinyProvider to grant access to global state (see component docs).
 */

import React from "react";
import ReactDOM from "react-dom";
import {NgrContextProvider, NgrRouterProvider} from "./context-providers";

angular.module("app")
  .directive("ngrComponent", [function() {
    "use strict";
    return {
      scope: {
        reactComponent: "@",
        useContext: "=",
        props: "="
      },
      link: function($scope, $element, attrs) {
        function destructor() {
          ReactDOM.unmountComponentAtNode($element[0]);
        }

        $scope.$on("$destroy", destructor);

        return new Promise((resolve, reject) => {
          $scope.$watch("props", () => {
            import(`../../${$scope?.reactComponent}`).then(ReactComponent => {
              const routerFactory = React.createFactory(NgrRouterProvider);
              const componentFactory = React.createFactory(ReactComponent?.default);
              const mainFactory = React.createFactory(NgrContextProvider);

              const component = routerFactory({
                children: $scope.useContext
                  ? mainFactory({children: componentFactory($scope.props)})
                  : componentFactory($scope.props)
              });

              resolve(ReactDOM.render(component, $element[0]));
            }, error => {
              console.error(error);
              reject(error);
            });
          });
        });
      }
    };
  }]);
