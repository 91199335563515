import * as _ from 'underscore';

/**
 * Responsable for sending messages to the Notifications Dock directive
 * It also responds to the former Alerts service API
 *
 * @author: Miguel Entrena
 *
 * @Example:
 *  var notification = {
 *    message: 'The world was annihilated',
 *    style: 'success'
 *  };
 *
 *  Alerts.enqueue(notification)
 *  or
 *  Alerts.success('The world was annihilated')
 *
 * @AvailableParameters:
 *  message: string (accepts HTML),
 *  showCloseBtn: bool; enables the close button; default: true,
 *  persistent: bool; notification persists upon page reload until dismissed,
 *  timeout: (ms); 0 to disable timeout; default: 5000,
 *  repeatable: bool; allows enqueue of already present notification; default: false,
 *  style: string; style of notification (info, error, success...); also for templates ('eggs_earned'...),
 *  highPrio: bool; pushes notification to the top of the queue; default: false
 *
 * @Promise: notifications return a promise with the notification object.
 *  var notification;
 *  Alerts.success('Operation successful').then(response => { notification = response });
 *  notification.dismiss()
 */

angular.module('app').service('FarmyNotifications', ['$rootScope', '$localStorage', '$timeout', '$q', function($rootScope, $localStorage, $timeout, $q) {
  var $scope = this || {};
  window.Alerts = this; // window.Alerts is now directed to this service

  $localStorage.farmyNotifications = $localStorage.farmyNotifications || [];
  $scope.notifications = angular.copy($localStorage.farmyNotifications);
  $scope.index = $scope.notifications.length > 0 ? _(_($scope.notifications).pluck('index')).max() : 345;

  var styles = ['error', 'info', 'success', 'warn'];

  var template = {
    message: null, // Body of the notification, accepts HTML
    showCloseBtn: true,
    persistent: false, // Wether it will persist upon page reload.
    timeout: 5000, // 0 for 'no auto hiding'.
    delay: null, // TODO: ms till it's displayed.
    repeatable: false, // Avoid duplication of enqueued notifications
    style: 'info',
    highPrio: false,
    current: false, // private attr
    visible: false // private attr
  };

  // private members

  function extendTemplate(notification) {
    if (notification.icon) {
      if (notification.icon.indexOf('fa-') > -1) {
        notification.icon = `<i class="fa %{notification.icon}">`
      } else {
        notification.icon = `<img class="notification-icon" src="%{notification.icon}" />`
      }
    }

    notification.index = $scope.index;
    $scope.index ++;

    return angular.extend({}, template, notification)
  }

  // Returns a farmy-notification from the common former Alerts (window and service)
  function enqueueFromPreviousVersion(message, options, type) {
    var notification = {message: message, style: type};

    if (angular.isObject(message)) {
      notification = angular.extend(message, {style: type});
    } else if (options && angular.isObject(options)) {
      notification = angular.extend(options, notification)
    }

    return $scope.enqueue(notification);
  }

  // public members

  $scope.enqueue = function (notification) {
    // Skip adding repeated notifications unless forced by the 'repeatable' flag.
    var shouldPushNotification = notification.repeatable || !_($scope.notifications).any(function (i){
      return i.message == notification.message && i.style == notification.style && i.contents == notification.contents
    });

    return $q(function(resolve, reject) {
      if (shouldPushNotification) {
        notification = extendTemplate(notification);

        if (notification.highPrio) {
          $scope.notifications.unshift(notification)
        } else {
          $scope.notifications.push(notification)
        }

        // Use $localStorage if the 'persistent' flag is set to true.
        if (notification.persistent)
          $localStorage.farmyNotifications.push(notification);

        // return the scope of the new notification to access public methods
        var notificationWatcher = $rootScope.$watch(function() {
          return $(`#notification-${notification.index} .notification`).length > 0
        }, function(newVal) {
          if (newVal){
            resolve($(`#notification-${notification.index} .notification`).scope());
            notificationWatcher();
          }
        })
      }
    });
  };

  $scope.remove = function (notification) {
    $scope.notifications.splice($scope.notifications.indexOf(notification), 1);
    if (notification.persistent) $localStorage.farmyNotifications.splice($localStorage.farmyNotifications.indexOf(notification), 1)
  };

  $scope.destroyAll = function() {
    $scope.notifications = [];
    $localStorage.farmyNotifications = []
  };

  $scope.hideAll = function() {
    _($scope.notifications).each(function(n) {
      n.current = n.visible = false;
    })
  };

  $scope.trigger = function() {
    if ($scope.notifications.length > 0)
      $scope.notifications[0].current = true;
  };

  // Alerts service posing interface

  $scope.error = function(message, options) {
    return enqueueFromPreviousVersion(message, options, 'error')
  };

  $scope.info = function(message, options) {
    return enqueueFromPreviousVersion(message, options, 'info')
  };

  $scope.success = function(message, options) {
    return enqueueFromPreviousVersion(message, options, 'success')
  };

  $scope.warn = function(message, options) {
    return enqueueFromPreviousVersion(message, options, 'warn')
  };

  // This alert is commonly used as 'turboloading' animation.
  // Needs to be 'highPrio' and hide any ongoing notification.
  $scope.default = function(message, options) {
    options = options || {};
    options.highPrio = true;
    $scope.hideAll();
    return enqueueFromPreviousVersion(message, options, 'default');
  };

  $scope.facebook = function(message, options) {
    return enqueueFromPreviousVersion(message, options, 'facebook')
  };

  return $scope;
}]);
