// Enable dynamic post-bootstrap loading of controllers
// TODO: Risks of this override are unknown!
//
// See https://dpopescu.me/2016/02/29/dynamic-loading-of-angularjs-components/
// and https://www.bennadel.com/blog/2553-loading-angularjs-components-after-your-application-has-been-bootstrapped.htm
appModule.config(['$compileProvider', '$controllerProvider', function ($compileProvider, $controllerProvider) {
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(whatsapp|mailto|http|https):/);

  // Allow lazy-loading of controllers and directices in the 'app' module
  appModule._controller = appModule.controller;
  appModule.controller = function(name, constructorFn) {
    $controllerProvider.register(name, constructorFn);
    return(this);
  }

  appModule._directive = appModule.directive;
  appModule.directive = function(name, constructorFn) {
    $compileProvider.directive(name, constructorFn);
    return(this);
  }
}]);