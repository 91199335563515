'use strict';

angular.module('app').controller('RewardsPageCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$localStorage', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, $localStorage, Alerts) {
    $scope.rewardsEndpoint = '/api/frontend/rewards';
    $scope.pointsCount = 0;
    $scope.pointsForPrize = 500;
    $scope.barPercentage = '0%';
    $scope.canExchangePoints = false;

    $scope.loadPoints = function() {
      $http.get($scope.rewardsEndpoint + '/points.json').then(function(response) {
        $scope.rewards = response.data.rewards;

        if(!$scope.rewards.points) {
          $scope.rewards.points = 0;
        }

        $scope.pointsCount = $scope.rewards.points;
        $scope.canExchangePoints = $scope.pointsCount >= 500;

        $scope.numOfEggs = 0;
        if ($scope.pointsCount > 0) {
          $interval(function(){
            $scope.numOfEggs++;
          }, 3, $scope.pointsCount - 1);
        }

        $scope.setEggsHumanCount();
        // $scope.setBarPercentage();
      })
    };

    $scope.convertPointsToCash = function() {
      $http.post($scope.rewardsEndpoint + '/convert_points_to_cash.json', {num_of_points: $scope.pointsForPrize}).then(function(response) {
        $scope.loadPoints();
        $rootScope.$broadcast('user:balance:updated', {});
        Alerts.success($translate.instant('rewards.operation_successful'));
      }, function(error) {
        Alerts.error(error.data.exception)
      })
    };

    $scope.convertPointsToDonation = function(social_project_id) {
      $http.post($scope.rewardsEndpoint + '/convert_points_to_donation.json', {num_of_points: $scope.pointsForPrize, social_project_id: social_project_id}).then(function(response) {
        $scope.loadPoints();
        $rootScope.$broadcast('user:balance:updated', {});
        Alerts.success($translate.instant('rewards.thanks_for_donating'));
      }, function(error) {
        Alerts.error(error.data.exception)
      })
    };

    $scope.setEggsHumanCount = function() {
      $scope.eggsHumanCount = $scope.pointsCount + ' ' + ($scope.pointsCount == 1 ? $translate.instant('rewards.bonus_egg') : $translate.instant('rewards.bonus_eggs'));
    };

    $scope.getNumber = function(num) {
      return new Array(num);
    };

    $scope.setEggsHumanCount();
    $scope.loadPoints();
  }
]);
