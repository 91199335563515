angular.module('app').controller('supplierGalleryModalCtrl', ['$scope', '$uibModalInstance', 'currentIndex', 'images', function ($scope, $uibModalInstance, currentIndex, images) {
  $scope.currentIndex = angular.copy(currentIndex);
  $scope.images = images.images;

  $scope.setImage = function(num) {
    $scope.currentIndex = num;

    if ($scope.currentIndex >= 0) {
      $('.supplier-gallery-modal .photo-gallery img.main-image').attr('src', $scope.images[num].thumb);
      $scope.currentImage = $scope.images[num];
    }
  };

  $scope.moveForward = function() {
    var num = $scope.currentIndex == $scope.images.length - 1 ? 0 : $scope.currentIndex + 1;
    $scope.setImage(num)
  };

  $scope.closeModal = function() {
    $uibModalInstance.dismiss()
  };

  $scope.setImage(0)
}]);
