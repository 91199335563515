appModule.directive('farmyWebsiteHeader', ['$q', '$translate', '$http', function($q, $translate, $http) {
  return {
    restrict: 'EAC',
    scope: {

    },
    controller: ['$scope', '$rootScope', '$window', '$location', '$timeout', '$element', 'UserService', 'OrderHelper', 'UiStateService', 'SmartPassService', 'PromotionsService', 'Hubs', 'CartData', '$uibModal', 'UserNotifications',
         function($scope,   $rootScope,   $window,   $location,   $timeout,   $element,   UserService,   OrderHelper,   UiStateService,   SmartPassService,   PromotionsService,   Hubs,   CartData,   $uibModal,   UserNotifications) {
      window.farmyWebsiteHeader = $scope;
      var unOnHubsChanged;

      function constructor() {
        $rootScope.$watch('SmartPassService.purchasing', function() {
          $timeout(function(){
            if (SmartPassService.purchasing) {
              $scope.hideSmartPassBadgeIfScroll();
            }
          }, 20);
        });

        unOnHubsChanged = $rootScope.$on('hubs:changed', onHubsChanged);

        $rootScope.$watch(()=> { return SmartPassService.subscription ? SmartPassService.subscription.level : null }, function() {
          $rootScope.switchSmartPassBadge();
        });

        $window.onscroll = function() {
          if (SmartPassService.hasActiveSubscription || SmartPassService.purchasing) {
            $scope.hideSmartPassBadgeIfScroll();
          }
        };

        $rootScope.$on('express:change_started', () => {
          $scope.expressDeliveryOngoingChange = true
        })

        $rootScope.$on('express:change_finished', () => {
          $scope.expressDeliveryOngoingChange = false
        })

        $rootScope.$on('express:changed', (e, args) => {
          $scope.expressDeliveryChecked = args.express;
        })
      }

      function destructor() {
        window.farmyWebsiteHeader = null;
        if (unOnHubsChanged) unOnHubsChanged();
      }

      function onHubsChanged(e) {
        $scope.currentHubCode = Hubs.currentHub.code;
      }

      $scope.freeDeliveryDateRangeNow = window.freeDeliveryDateRangeNow;
      $rootScope.currentSmartPassLevel = window.currentSmartPassLevel;
      $scope.scrollPosition = 0;
      $scope.currentZipcodeInHubZone = window.currentZipcodeInHubZone;
      $scope.currentHubCode = window.currentHubCode;
      $scope.currentZoneRegional = window.currentZoneRegional;
      $scope.ngfrontend = window.NgFrontendAppCtrl.ngfrontend;
      $scope.supplierPortalMode = window.supplierPortalMode;
      $scope.currentStorefrontSupplier = window.currentStorefrontSupplier;

      $scope.badgeTop = {
        'basic': '-65px',
        'premium': '-65px'
      };

      $scope.OrderHelper = OrderHelper;
      $scope.UiStateService = UiStateService;
      $scope.UserService = UserService;
      $scope.SmartPassService = SmartPassService;
      $scope.PromotionsService = PromotionsService;
      $scope.UserNotifications = UserNotifications;

      $scope.canSeeBadges = false;

      $scope.CartData = CartData;

      $scope.smartPassSubscription = SmartPassService.subscription;

      /**
       * If the notifications panel is visible
       * @type {boolean}
       */
      $scope.notificationPanelVisible = false;

      $scope.hideSmartPassBadgeIfScroll = function() {
        if ($(window).scrollTop() > 5) {
          hideSmartPassBadges();
        } else { showCurrentSmartPassBadge() }
      };

      $rootScope.switchSmartPassBadge = function() {
        hideSmartPassBadges();
        showCurrentSmartPassBadge();
      };

      $scope.isCartButtonMuted = function() {
        return location.href.indexOf('/cashier') > -1 || location.href.indexOf('/checkout/') > -1 || location.href.indexOf('/cart') > -1;
      };

      $scope.expandSearch = function(event) {
        setTimeout(function() {
          AngularIntegration.$broadcast('search:shortcut:function');
          event.target.value = '';
        }, 1); // to exit the $digest cycle
      };

      $scope.changeLocale = function(locale) {
        return UserService.changeLocale(locale)
      };

     $scope.showBadges = function() {
       if ($scope.UserService?.currentUser && Object.keys($scope.UserService.currentUser).length > 0) {
         const farmyFamily = $scope.UserService.currentUser.ff_subscriber && $scope.UserService.canSeeFarmyFamily;
         const smartPass = $scope.SmartPassService.hasActiveSubscription;
         const investor = $scope.UserService.currentRoles.includes('crowd_investor');
         $scope.canSeeBadges = smartPass || investor || farmyFamily;
       } else {
         $scope.canSeeBadges = false;
       }
     };

     $rootScope.$on('user:updated', () => {
       $scope.showBadges();
     });
     $rootScope.$on('smartpass:updated', () => {
       $scope.showBadges();
     });
      /**
       * Callback for the internal ng-include
       */
      $scope.onTemplateLoaded = function() {
        // Enable bootstrap fix
        $('header [data-spy=affix]').affix({offset: {top: parseInt($element.find('[data-offset]').data('offset'))} })
      };

      $scope.showMultipleCartOrdersModal = function() {
        if (window.CartData) {
          blockUI.start();
          window.CartData.reloadIncompleteAndNotifyOnMultipleCartOrders().finally(() => { blockUI.stop() });
        }
      };

      /**
       * NB: This method has a clone in the website-header component
       */
      $scope.onLowCapacityWarningClick = function() {
        $location.path(CartData.nearestDeliveryTermLink);
      };

      $scope.toggleNotifications = function() {
        if (window.notificationsPopupOpening == true)
          return;

        if ($scope.notificationsPopupInstance != null && $scope.notificationsPopupInstance.close) {
          $scope.notificationsPopupInstance.close();
        }

        $scope.notificationsPopupInstance = $uibModal.open({
          templateUrl: `/ng/templates/user_accounts/notifications_popup.html?locale=${I18n.locale}`,
          controller: 'UserNotificationsPopupCtrl',
          size: 'md',
          resolve: {

          }
        });

        window.notificationsPopupOpening = true;

        $scope.notificationsPopupInstance.result.then(() => {
          $scope.notificationsPopupInstance = null;
        }, () => { $scope.notificationsPopupInstance = null });
      };

      $scope.notificationPanelListener = {
        onNotificationPanelHidden: function() {
          $scope.notificationPanelVisible = false;
        }
      };

      var hideSmartPassBadges = function() {
        $scope.badgeTop = {
          'basic': '-65px',
          'premium': '-65px'
        };
      };

      var showCurrentSmartPassBadge = function() {
        $scope.badgeTop[$rootScope.currentSmartPassLevel || 'premium'] = '-16px';
      };

      $scope.$on('$destroy', destructor);
      constructor();
    }],
    link: function (scope, element, attrs) {

    },
    template: () => {
      if (window.currentStorefront) {
        return `<farmy-ng-include src="'/ng/templates/nav/header_${window.currentStorefront}.html'" onload="onTemplateLoaded()"></farmy-ng-include>`
      } else return `<farmy-ng-include src="'/ng/templates/nav/header.html'" onload="onTemplateLoaded()"></farmy-ng-include>`
    }
  };
}]);
