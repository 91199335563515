angular.module('app').directive('fillContainerWidth', ['$window', '$document', '$timeout', function ($window, $document, $timeout) {
  return {
    restrict: 'A',
    scope: {
      footerElementId: '@',
      additionalPadding: '@',
      debounceWait: '@'
    },
    link: function (scope, element, attrs) {
      if (attrs.onlyMobile && !window.isMobile) {
        return;
      }

      var widthSourceSelector = attrs.fillContainerWidth;
      var fillContainerPadding = parseInt(attrs.fillContainerPadding);

      if (scope.debounceWait === 0) {
        angular.element($window).on('resize', onWindowResize);
      } else {
        // allow debounce wait time to be passed in.
        // if not passed in, default to a reasonable 250ms
        angular.element($window).on('resize', debounce(onWindowResize, scope.debounceWait || 250));
      }

      onWindowResize();

      // returns a fn that will trigger 'time' amount after it stops getting called.
      function debounce(fn, time) {
        var timeout;
        // every time this returned fn is called, it clears and re-sets the timeout
        return function() {
          var context = this;
          // set args so we can access it inside of inner function
          var args = arguments;
          var later = function() {
            timeout = null;
            fn.apply(context, args);
          };
          $timeout.cancel(timeout);
          timeout = $timeout(later, time);
        };
      }

      function onWindowResize() {
        var elementWidth = $(widthSourceSelector).outerWidth();
        if (scope.additionalPadding) elementWidth -= parseInt(scope.additionalPadding) * 2;
        element.css('width', elementWidth + 'px');
      }
    }
  };
}]);
