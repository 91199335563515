angular.module('app').service('NativeContainer', ['$rootScope', '$q', '$http', '$cookies', '$interval', '$timeout', function ($rootScope, $q, $http, $cookies, $interval, $timeout) {
  var scope = this;

  window.NativeContainer = scope;

  scope.mode = 'web';

  if(navigator.userAgent.indexOf('AndroidWebView') > 0 && window.androidContainer)
    scope.mode = 'android';
  else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.openUrl)
    scope.mode = 'ios';

  scope.isApp = (scope.mode != 'web');

  // Setup client version for the mobile app, if this info is available
  if (window.localStorage && window.localStorage.apiClientVersion) {
    scope.apiClientVersion = parseFloat(window.localStorage.apiClientVersion);
  } else {
    scope.apiClientVersion = 1.0; // default 'current' version
  }

  // Browser detection
  let userAgent = (navigator && (navigator.userAgent || navigator.appVersion)) || '';
  scope.browser = {
    isFirefox: userAgent.indexOf('Firefox') > -1,
    isChrome: userAgent.indexOf('Chrome') > -1,
    isMobile: userAgent.indexOf('Mobile') > 1,
  };

  scope.browser.isSafari = !scope.browser.isChrome && userAgent.indexOf('Safari') > -1;

  var sessionData = {
    userId: window.currentUserId
  };

  // Public members
  scope.notifyAppInitialized = function() {
    if (scope.mode == 'ios') {
      window.webkit.messageHandlers.notifyAppInitialized.postMessage(JSON.stringify(sessionData));
    } else if (scope.mode == 'android') {
      window.androidContainer.notifyAppInitialized(JSON.stringify(sessionData));
    }
  };

  scope.notifyUserSession = function(userData) {
    if (scope.mode == 'ios' && window.webkit.messageHandlers.notifyUserSession) {
      window.webkit.messageHandlers.notifyUserSession.postMessage(JSON.stringify(userData));
    } else if (scope.mode == 'android' && window.androidContainer.notifyUserSession) {
      window.androidContainer.notifyUserSession(JSON.stringify(userData));
    }
  };

  scope.openUrl = function(url) {
    if (scope.mode == 'ios') {
      window.webkit.messageHandlers.openUrl.postMessage(url);
    } else if (scope.mode == 'android') {
      window.androidContainer.openUrl(url);
    } else {
      console.log("Would open ", url, "in external browser");
    }
  };

  scope.toggleSidebar = function() {
    if (scope.mode == 'ios' && window.webkit.messageHandlers.toggleSidebar) {
      window.webkit.messageHandlers.toggleSidebar.postMessage('');
    } else if (scope.mode == 'android') {
      throw "Not supported"
    } else {
      console.log("Would toggle native mobile sidebar");
    }
  };

  scope.closeSidebar = function() {
    if (scope.mode == 'ios' && window.webkit.messageHandlers.closeSidebar) {
      window.webkit.messageHandlers.closeSidebar.postMessage('');
    } else if (scope.mode == 'android') {
      throw "Not supported"
    } else {
      console.log("Would close native mobile sidebar");
    }
  };

  scope.openSidebar = function() {
    if (scope.mode == 'ios' && window.webkit.messageHandlers.openSidebar) {
      window.webkit.messageHandlers.openSidebar.postMessage('');
    } else if (scope.mode == 'android') {
      throw "Not supported"
    } else {
      console.log("Would close native mobile sidebar");
    }
  };

  /**
   * Broadcasts custom events to the native container
   *
   * @param event
   * @param payload
   */
  scope.broadcastWebEvent = function(event, payload) {
    if (scope.mode == 'ios' && window.webkit.messageHandlers.onWebEvent) {
      var data = JSON.stringify({ event: event, payload: payload });
      window.webkit.messageHandlers.onWebEvent.postMessage(data);
    } else if (scope.mode == 'android' && window.androidContainer.onWebEvent) {
      var data = JSON.stringify({ event: event, payload: payload });
      window.androidContainer.onWebEvent(JSON.stringify(data));
    } else if (Rails.env == 'development') {
      var data = JSON.stringify({ event: event, payload: payload });
      console.log("Sent web event to the native container", data);
    }
  };

  scope.log = function(message) {
    if (scope.mode == 'ios') {
      window.webkit.messageHandlers.logInfo.postMessage(message);
    } else {
      console.log(message);
    }
  };

  scope.getPushRedirectRequested = function(callback) {
    // Define callback function
    window.getPushRedirectRequestedResult = function(result) {
      callback(result);
    };

    if (scope.mode == 'ios') {
      window.webkit.messageHandlers.getPushRedirectRequested.postMessage('');
    } else if (scope.mode == 'android') {
      // TODO:
      setTimeout(function() {
        callback(null);
      }, 1);
    } else {
      setTimeout(function() {
        callback(null);
      }, 1);
    }
  }

  // Scans through external links in the view
  // and attaches an open-with-native-browser
  // click-listener onto them
  scope.observeExternalLinks = function() {
    $('a[href^="http"]').each(function(num, el) {
      el = $(el);

      // Skip links
      if (el.attr('href').indexOf(settingsSiteUrl) == 0) {
        return;
      } else {
        el.on('click', function(e) {
          e.preventDefault();
          scope.openUrl(el.attr('href'));
        });
      }
    });
  };

  /**
   * Just resends device token information. Needs to be called again after user
   * signs in to link the device id to a user account
   */
  scope.refreshDeviceToken = function() {
    if (scope.pushServiceDeviceToken) {
      $http.post('/api/frontend/users/add_device.json', { device: { token: scope.pushServiceDeviceToken, device_type: scope.deviceType } }).then(function(response) {
        console.log("Push service device refreshed:", { token: scope.pushServiceDeviceToken, device_type: scope.deviceType }, response.data);
      }, function(error) {
        console.warn("Error saving device push service token:", error)
      });
    } else {
      console.warn("No token available, nothing to refresh")
    }
  };

  /**
   * Helper for navigating to turbolinked and angular views,
   * especially category pages.
   *
   * Common usage examples:
   *
   * NativeContainer.navigateTo(123, 'taxon');
   * NativeContainer.navigateTo(433, 'product');
   * NativeContainer.navigateTo('hofladen/gemuese'); => taxon permalink
   * NativeContainer.navigateTo('bananen-hevlar'); => product permalink
   *
   * @param resource
   * @param resourceType
   */
  scope.navigateTo = function(resource, resourceType, locale) {
    if (locale == 'de' || locale == null) locale = '';
    else locale = '/' + locale;

    if (resourceType == 'taxon' && window.CatalogViewCtrl) {
      window.CatalogViewCtrl.loadView(locale + '/' + UrlUtils.stripLeadingSlash(resource), null);
    } else {
      location.href = locale + '/' + UrlUtils.stripLeadingSlash(resource);
    }
  };

  scope.authenticateByToken = function(token) {
    $http.post(sprintf('/users/authenticate_by_token.json'), { }, { headers: {
      'Authorization': 'Bearer ' + token
    } }).then(function(response) {
      console.log("Auth success");

      scope.notifyUserSession(response.data.user);

      $timeout(function() {
        location.reload();
      }, 250);
    })
  };

  //
  // Cart control methods
  //
  // Currently used in the latest version of the iOS (classic) app.
  //

  scope.getCart = function() {
    return JSON.stringify(CartData.cart);
  };

  /**
   * Sends a cart action callback to parent NativeContainer. Fired when
   * a cart API methods was called from within the native container, upon
   * its completion.
   *
   * Sends a JSON structure with last action type, related product id and latest cart data, including line items
   */
  scope.cartActionCallback = function(action, productId) {
    var message = JSON.stringify({action: 'increment', product_id: productId, cart: CartData.cart})

    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.onCartCallback)
      window.webkit.messageHandlers.onCartCallback.postMessage(message);
  };

  /**
   * Sets exact multiple of product in the cart, based on variant quantity index (variant's numerical position in the list of product's variants)
   *
   * @param productId   ID of the product
   * @param variantQuantityIndex  Index/position of the variant in product.variants
   * @param referrer
   */
  scope.cartSetProductCount = function(productId, variantQuantityIndex, referrer) {
    return CartData.setProductCount(productId, variantQuantityIndex, referrer).then(function(result) {
      scope.cartActionCallback('setCount', productId);
    });
  };

  /**
   * Sets product quantity in cart base on exact quantity in units. Specified quantity in units
   * must match one of existing variants
   * @param productId
   * @param quantityInUnits
   * @param referrer
   */
  scope.cartSetProductQuantity = function(productId, quantityInUnits, referrer) {
    return CartData.setProductQuantity(productId, quantityInUnits, referrer).then(function(result) {
      scope.cartActionCallback('setQuantity', productId);
    });
  };

  scope.cartIncreaseProductCount = function(productId, referrer) {
    return CartData.increaseProductCount(productId, referrer).then(function(result) {
      scope.cartActionCallback('increaseCount', productId);
    });
  };

  scope.cartDecreaseProductCount = function(productId, removeAll) {
    return CartData.decreaseProductCount(productId, removeAll).then(function(result) {
      scope.cartActionCallback('decreaseCount', productId);
    });
  };

  // Private members

  var onUserDeviceUpdateToken = function (e, data) {
    console.log('device data updated: ', data);

    scope.pushServiceDeviceToken = data.token;
    scope.deviceType = data.device;

    scope.refreshDeviceToken();
  };

  /**
   * Triggered when a login or registration operation completes
   *
   * @param e
   * @param data
   */
  var onSessionLogin = function(e, data) {
    // TODO: Send session/user id to the native container

    scope.refreshDeviceToken();
  };

  $rootScope.$on('user:device:updateToken', onUserDeviceUpdateToken);
  $rootScope.$on('user:device:refreshToken', scope.refreshDeviceToken);
  $rootScope.$on('session:onLogin', onSessionLogin);

  if (scope.isApp) {
    // Make links to external websites work by natively opening
    // the default device browser app
    scope.observeExternalLinks();

    // Redirect to a push message target on load, if it's set by
    // the native container. Periodical check is implemented to
    // provide basic Swiss-army-knife support for this
    scope.getPushRedirectRequested(function(url) {
      if (url && url != location.href && url.length > 6) {
        setTimeout(function() {
          window.location.href = url;
        }, 10);
      }
    });
  }
}]);
