'use strict';

/**
 * Lightweight lazy-load directive for drag-n-drop file upload (by Miguel Entrena)
 * Based on https://github.com/enyo/dropzone
 *
 * Example
 *
 * <farmy-file-upload id="my-id" upload-path="path_to_action" upload-on-success="$parent.loadResource()"></farmy-file-upload>
 *
 * NOTES:
 * Element ID is mandatory.
 *
 */

angular.module('app')
  .directive('farmyFileUpload', function() {
    return {
      scope: {
        id: '@',
        uploadPath: '@',
        uploadOnSuccess: '@'
      },
      link: function(scope, element, attributes) {
      },
      controller: ['$scope', '$element', 'Alerts', '$translate', 'blockUI', function ($scope, $element, Alerts, $translate, blockUI) {
        // 'dropzone.js' places the Dropzone class in the window object.
        import(/* webpackChunkName: "Dropzone" */ 'ng-frontend/lazy-modules/dropzone.min.js').then((Dropzone) => {
          $scope.hasFile = false;

          Dropzone.autoDiscover = false;

          Dropzone.options[$.camelCase($scope.id)] = {
            createImageThumbnails: false,
            autoProcessQueue: false,
            acceptedFiles: 'image/jpeg',
            parallelUploads: 1,
            maxFilesize: .5 // MB
          };

          $element.dropzone({url: $scope.uploadPath});
          // This is needed since the above line doesn't seem to return the actual instance.
          let uploader = Dropzone.forElement($element[0]);
          $scope.uploader = uploader;

          $scope.uploadFile = function() {
            uploader.processQueue();
          };


          // EVENTS
          uploader.on('dragenter', ()=> {
            $element.find('.upload-drop-zone').addClass('nv-file-over');
          });

          uploader.on('dragover', ()=> {
            $element.find('.upload-drop-zone').addClass('nv-file-over');
          });

          uploader.on('dragleave', ()=> {
            $element.find('.upload-drop-zone').removeClass('nv-file-over');
          });

          uploader.on('drop', ()=> {
            $element.find('.upload-drop-zone').removeClass('nv-file-over');
          });

          uploader.on('drop', ()=> {
            uploader.removeAllFiles();
          });

          uploader.on('addedfile', (file)=> {
            $scope.file = file;

            let fileWatcher = $scope.$watch('file.accepted', ()=> {
              if (file.accepted != undefined) {
                if (file.accepted == true)
                  $scope.hasFile = true;
                else if (file.accepted == false) {
                  uploader.removeFile(file);
                  $scope.hasFile = false;
                  fileWatcher();
                  Alerts.error(`Format or size not compatible.<br /><small>Max size: <b>500kB</b> (yours is ${file.size / 1000}kB)<br />Type: <b>jpg/jpeg</b> (yours is ${file.type})<small>`)
                }
              }
            });

            $scope.hasFile = uploader.getAcceptedFiles().length > 0;
          });

          uploader.on('removedfile', ()=> {});

          uploader.on('sending', ()=> {
            blockUI.start();
          });

          uploader.on('success', (file, response)=>{
            $scope.response = response;
            $scope.hasFile = false;

            blockUI.stop();
            Alerts.success($translate.instant('rewards.operation_successful'));

            uploader.removeAllFiles();

            if ($scope.uploadOnSuccess)
              $scope.$eval($scope.uploadOnSuccess);
          });
        })
      }],
      templateUrl: '/ng/templates/farmy_file_upload/file_upload.html'
    }
  });
