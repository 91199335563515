'use strict';

angular.module('app').controller('HomeStartViewCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$timeout', '$http', '$element', '$localStorage', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $timeout, $http, $element, $localStorage, Alerts) {
    window.HomeStartViewCtrl = $scope;

    // Public members

    // Private members

    function constructor() {
    }

    function destructor() {
      window.HomeStartViewCtrl = null;
    }

    $scope.$watch(function() {return _(_($('.bubble-wrapper')).map(function(element) {return $(element).outerHeight()})).max()}, function(height) {
      if (!$scope.commentBubbleHeight || height > $scope.commentBubbleHeight)
        $scope.commentBubbleHeight = height;
    });

    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
