import ProductLoader from "./product-loader";

export const ProductFetcher = {
    loader: new ProductLoader()
}

export const apiLoadProducts = (params) => {
    return ProductFetcher.loader.load(params)
}

export default ProductFetcher
