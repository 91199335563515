import {getFilteredCertificates, setVariantDisplayPrices} from '../../shared-services/product-data-parser';
import * as _ from 'underscore';
import * as sprintf from 'sprintf';
import {trackPageView} from '../../services/tracking-helper';
import frontendSettings from "../../frontendSettings";

angular.module('app').controller('ProductPageCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$sce', '$localStorage', 'Alerts', '$timeout', 'TaxonHelper', '$uibModal', 'PartnerPixelHelper', 'UserService', 'Hubs',
  function($scope, $rootScope, $location, $window, $interval, $http, $element, $sce, $localStorage, Alerts, $timeout, TaxonHelper, $uibModal, PartnerPixelHelper, UserService, Hubs) {
    window.ProductPageCtrl = $scope;

    function constructor() {
      $scope.initialOptions = JSON.parse($element.attr('data-initial-options'));
      $scope.triggerUnavailablePopup = false;
      $scope.certificatesBlock1 = [];
      $scope.certificatesBlock2 = [];

      if ($scope.initialOptions.productId) {
        window.productId = $scope.productId = $scope.initialOptions.productId;
      }

      if ($scope.initialOptions.currentTaxonId) window.currentTaxonId = $scope.initialOptions.currentTaxonId;
      if ($scope.initialOptions.currentParentTaxonId) window.currentParentTaxonId = $scope.initialOptions.currentParentTaxonId;

      $scope.$watch('productId', function (newValue) {
        if (newValue != null) {
          $scope.loadProduct();
        }
      });

      // Init legacy UI if needed
      $timeout(function () {
        $scope.productItemCtrl = $element.find('.product-item-ctrl').scope();
      }, 100);
    }

    function destructor() {
      $rootScope.$broadcast('productPage:dismissed');
      window.ProductPageCtrl = null;

      $scope._destroyed = true;
    }

    /**
     * Animates the cross-fade of elements
     */

    var isFriday = function () {
      return (new Date).getDay() == 5;
    };

    var setMetaData = function () {
      window.document.title = $scope.product.meta_title ? $scope.product.meta_title : $scope.product.name;
      var canonical = sprintf('%s%s', settingsSiteUrl, $scope.product.seo_url);
      var description = $scope.product.meta_description ? $scope.product.meta_description : $scope.product.description;

      $("link[rel='canonical']").attr('href', canonical);
      $("meta[name='description']").attr('content', description);

      if ($scope.product.price === 0) {
        let robotTagValue = 'NOINDEX, FOLLOW';
        $rootScope.setRobotsMeta(robotTagValue);
      }

      if ($scope.product.alternate_seo_urls) {
        _.each(I18n.availableLocales, locale => {
          $(`link[hreflang='${locale == 'de' ? 'x-default' : locale}']`).attr('href', $scope.product.alternate_seo_urls[locale]);
        });
      }
    };

    $scope.setMetaData = setMetaData;

    var openUnavailableInHubPopup = function () {
      $scope.triggerUnavailablePopup = true;
    };

    var openUnavailablePopupIfZipcodeDismissed = function () {
      if (!window.currentZipcode) return;

      if (!window.preventPopups) {
        setTimeout(function() {
          $scope.zipcodeInterval = setInterval(showPopupIfZipcodeDismissed, 100);
        }, 200);
      }
    };

    var showPopupIfZipcodeDismissed = function () {
      if (!window.zipcodeModalPresent) {
        openUnavailableInHubPopup();
        clearInterval($scope.zipcodeInterval);
      }
    };

    var trackProductView = function () {
      ahoy.track("viewed-product", {
        product_id: $scope.product.id,
        id: $scope.product.id,
        permalink: $scope.product.permalink,
        channel: window.xSessionChannel
      });

      trackPageView('product', {products: $scope.product});
    };

    $scope.trackProductView = trackProductView;

    $scope.displaySaturdayDeliveryNote = isFriday();

    $scope.productItemCtrl = null;

    $scope.ProductScrollableListPageCtrl = window.ProductScrollableListPageCtrl;

    $scope.loadProduct = function () {
      $scope.isLoading = true;

      return $http.get(sprintf('/api/products/%s.json', $scope.productId), {
        params: {
          locale: I18n.locale,
          alternate_seo_urls: 't'
        }
      }).then(function (response) {
        $scope.product = response.data;
        $scope.productName = $scope.product.name;
        $scope.descriptionHeight = 0;

        $scope.setNavigationPath();

        $scope.masterVariant = _.find($scope.product.variants, function (variant) {
          return variant.is_master == true;
        });

        // Inflate some additional data, based on the product source JSON
        $scope.product.availableInCurrentHub = !Hubs.currentZipcode || $scope?.product?.hub_ids?.includes(Hubs.currentHubId);
        $scope.product.image_xl = $scope.product.image.lg;
        $scope.product.image_sm = $scope.product.image.md;
        $scope.product.image_xs = $scope.product.image.xs;
        $scope.product.image_product = $scope.product.image.md;

        $scope.parsedDescription = $scope.product.description.replace(/\n/g, '<br />');
        $scope.parsedDescription = $sce.trustAsHtml($scope.parsedDescription);

        $scope.product.variants = setVariantDisplayPrices($scope.product, UserService.currentUser);

        $scope.displaySaturdayDeliveryNote = isFriday() && !$scope.product.delivers_saturday;

        // True if a 'producer' property exists:
        $scope.product.producer_property = _($scope.product.product_properties).find(function (p) {
          return p.property_name == 'producer'
        });
        if ($scope.product.producer_property) {
          $scope.product.producer_property.value = $sce.trustAsHtml($scope.product.producer_property.value);
        }

        $scope.certificatesBlock1 = getFilteredCertificates($scope.product?.certificates, frontendSettings.certificatesBlock1, true).slice(0, 2);
        $scope.certificatesBlock2 = getFilteredCertificates($scope.product?.certificates, frontendSettings.certificatesBlock1, false);

        if ($scope.product.google_analytics_product) {
          $scope.product.google_analytics_product.price = $scope.product.price
        }

        if (window.ProductScrollableListPageCtrl == null || window.ProductScrollableListPageCtrl.firstProductId == $scope.product.id) { // Do not automatically send meta data when part of a list
          setMetaData();
          trackProductView();
        }

        if (!$scope.product.availableInCurrentHub) openUnavailablePopupIfZipcodeDismissed();

        $rootScope.$broadcast('productPage:productLoaded', $scope.product);

        setTimeout(() => {
          if (window.PageloadTracking) PageloadTracking.viewloadSummaryEnd();
        }, 10);

        $timeout(() => {
          $scope.loadProductTaxonsInfo()
        }, 10);

        if (window.ProductScrollableListPageCtrl) {
          $timeout(() => {
            window.ProductScrollableListPageCtrl.onChildProductViewProductLoaded($scope)
          }, 10);
        }

        PartnerPixelHelper.productView($scope.product);

      }, function (error) {
        if (window.Rollbar) Rollbar.error("Product wasn't available for product page: #" + $scope.productId, error);
        Alerts.warn("Momentan nicht verfügbar");
      }).finally(function () {
        $scope.isLoading = false;
      })
    };

    $scope.loadProductTaxonsInfo = function () {
      TaxonHelper.getTaxonInfos($scope.product.taxon_ids, { seo_url: 't' }).then(taxonInfos => {
        $scope.productTaxons = _.filter(taxonInfos, t => t.taxonomy_id == TaxonHelper.CATEGORIES_ROOT_TAXONOMY_ID); // filter for a specific taxonomy
      })
    };

    $scope.setNavigationPath = function() {
      if (!$scope._destroyed) {
        if (!window.$locationChanging) {
          $location.changeUrl($scope.product.seo_url, {replace: true});
        } else console.warn("ABORTED changeUrl because location change is in progress!");
      }
    };

    $scope.navigateBack = function () {
      $window.history.back();
    };

    // Unit can come from weight_units or packaging.
    // In absence of both, will return 'item'.
    $scope.getProductUnit = function() {
      if(!$scope.product) return $translate.instant('item');

      if ($scope.product.weight_units && $scope.product.weight_units.length > 0) {
        return $scope.product.weight_units
      } else if ($scope.product.packaging && $scope.product.packaging.length > 0) {
        return $scope.product.packaging
      } else {
        return $translate.instant('item')
      }
    };

    $rootScope.$on('zipcode:changed', function (event, data) {
      if ($scope?.product) {
        $scope.product.availableInCurrentHub = $scope.product?.hub_ids?.includes(window.currentHubId);
        if (!$scope.product.availableInCurrentHub) openUnavailablePopupIfZipcodeDismissed();
      }
    });

    $scope.$on('$destroy', destructor);
    constructor();
  }]);
