import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app')
  .directive('farmyCheckoutCreditCardSelectorDt', function() {
    return {
      controller: ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', 'Alerts', 'blockUI', 'AddressHelper', function ($scope, $sce, $http, $q, $rootScope, $timeout, Alerts, blockUI, AddressHelper) {
        window.CheckoutCreditCardSelectorDt = $scope;

        /**
         * The model selected by the saved cards selector
         *
         * @type {{id: null, user_id: null, last_4: null, pseudo_card_pan: null}}
         */
        $scope.card = {
          id: null,
          user_id: null,
          last_4: null,
          pseudo_pan: null,
          cc_type: null
        };

        $scope.cardId = null;

        function constructor() {
          var savedCardsWatcher = $scope.$watch('checkoutCtrl.userProfile', (() => {
            if ($scope.checkoutCtrl.userProfile.credit_cards) {
              if ($scope.checkoutCtrl.userProfile.credit_cards.length > 0) {
                $scope.cardId = $scope.checkoutCtrl.userProfile.credit_cards[0].id.toString();
                $scope.onCardChanged();
              }
              savedCardsWatcher()
            }
          })
          );
        }

        function destructor() {
          window.CheckoutCreditCardSelectorDt = null;
        }

        // Public members

        $scope.onCardChanged = function() {
          if (!$scope.cardId || $scope.cardId.length == 0) {
            $scope.card = {
              id: null,
              user_id: null,
              last_4: null,
              pseudo_pan: null,
              cc_type: null
            };
          } else $scope.card = angular.extend($scope.card || {}, _.find($scope.checkoutCtrl.userProfile.credit_cards, (a) => a.id.toString() == $scope.cardId.toString()));
        };

        // Initialize

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      templateUrl: function() { return `/ng/templates/checkout/payment_credit_card_selector_dt.html?locale=${window.I18n.locale}` }
    }
  });


