// https://docs.connects.ch/en/profiling-inkl-conversion-tracking/?%25PartnerID%25=e70h7JiRaBIJW0vXYtIw
angular.module('app').service('PartnerPixelHelper', [function () {
  let $scope = this || {};

  window.PartnerPixelHelper = $scope;

  let pixelScriptInjected = false;

  const merchantId = 'e70h7JiRaBIJW0vXYtIw';

  $scope.checkoutConversionTracking = function (checkout) {
    const customScript = `window._lea.push({
      id: '${merchantId}',
      module: 'OrderTracking',
      event: 'Sale',
      site: 'checkout',
      oid: '${checkout.number}',
      ovalue: ${checkout.total},
      ocurrency: '${window.defaultCurrency}',
      ocategory: '${(UserService.currentUser && UserService.currentUser.complete_order_count && UserService.currentUser.complete_order_count >= 1) ? "existing customers" : "new customers"}',
      ochannel: '${checkout.channel_human_name}',
    });`;
    createScript(customScript);
  };

  $scope.pageView = function () {
    const customScript = `window._lea.push({
      id: '${merchantId}',
      module: 'Profiling',
      event: 'PageView',
      pageName: '${window.location.pathname}',
      refererUrl: '${document.referrer}'
    });`;
    createScript(customScript);
  };

  $scope.categoryView = function (category) {
    const customScript = `window._lea.push({
      id: '${merchantId}',  
      module: 'Profiling',
      event: 'CategoryView',
      categoryId: '${category.id}',
      categoryName: '${category.name}',  
      categoryClickUrl: '${category.canonical_url}',
      refererUrl: '${document.referrer}'
    });`;
    createScript(customScript);
  };

  $scope.productView = function (product) {
    const customScript = `window._lea.push({
      id: '${merchantId}',
      module: 'Profiling',
      event: 'ProductView',
      currency: '${window.defaultCurrency}',
      productName: '${product.name}',
      productId: '${product.id}',
      productPrice: ${product.price},
      productCategory: [${product.taxon_ids}],
      productBrand: '${product.supplier.legal_name}',
      productRating: '${product.rating}',
      productInStock: '${product.available_to_add_to_cart}',
      productOnSale: '${product.available_to_add_to_cart}',
      productImgUrl: '${product.image_product}',
      productClickUrl: '${window.location.origin}/${product.permalink}',
      customerGender: '${UserService.currentUser.id ? UserService.currentUser.gender : ""}',
      customerZip: '${UserService.currentUser.zipcode}',
      userStatus: '${UserService.isLoggedIn}',
      refererUrl: '${document.referrer}'
    });`;
    createScript(customScript);
  };

  $scope.cartView = function (order) {
    const customScript = `window._lea.push({
      id: '${merchantId}',
      module: 'Profiling',
      event: 'CartView',
      totalPrice: ${order.total},
      totalQuantity: ${order.line_items.length},
      currency: '${window.defaultCurrency}',
      products: ${stringifyLineItems(order.line_items)},
      refererUrl: '${document.referrer}'
    });`;
    createScript(customScript);
  };

  // Private
  function createScript(customLogic) {
    try {
      if (window.Rails.env == 'production') {
        let script = document.createElement('script');
        script.async = true;
        script.innerText = `/* <![CDATA[ */ 
        window._lea = window._lea || [];
        ${customLogic.replace(/<br>/g, '').trim()}
        ${!pixelScriptInjected ? '(function(d){var s=d.createElement("script");s.async=true;s.src=(d.location.protocol=="https:"?"https:":"http:")+"//tc.connects.ch/lila.js";var a=d.getElementsByTagName("script")[0];a.parentNode.insertBefore(s,a)})(document);\n if (window.Rails.env != \'production\') console.log(\'[PartnerPixelHelper] Executed!\');' : ''}        
        /* ]]> */`;
        script.innerText = script.innerText.replace(/ +/g, ' ');
        let first_script = document.getElementsByTagName("script")[0];
        first_script.parentNode.insertBefore(script, first_script);

        if (!pixelScriptInjected)
          pixelScriptInjected = true;
      }
    } catch(e) {
      console.error("PartnerPixelHelper.createScript", e);
    }
  }

  function stringifyLineItems(items) {
    let str = '[';
    for (let i = 0; i < items.length; i++) {
      str += `{
        productId: ${items[i].product_id}, 
        productName: ${items[i].name}, 
        productPrice: ${items[i].price}, 
        productQuantity: ${items[i].quantity}
      },`
    }
    return `${str}]`;
  };

  return $scope;
}]);
