import * as _ from 'underscore';

'use strict';

angular.module('app').controller('AboutUsCtrl', ['$scope', '$rootScope', '$q', '$http',
  function ($scope, $rootScope, $q, $http) {
    console.log("AboutUsCtrl controller initialized with scope");
    window.AboutUsCtrl = $scope;

    $scope.contentIsLoaded = false;

    function constructor() {
      loadContents()
    }

    function loadContents() {
      $scope.contentIsLoaded = false;

      $http.get(`/api/frontend/about/contents.json?locale=${I18n.locale}`).then((response) => {
        $scope.contents = response.data;

        $scope.contents.certificate_codes = injectStyles($scope.contents.certificate_codes, window.isMobile ? 1 : 3);
        $scope.contents.team_profiles = injectStyles(_($scope.contents.team_profiles).shuffle(), window.isMobile ? 2 : 6, true);

        $scope.contentIsLoaded = true
      })
    }

    function injectStyles(collection, numOfColumns, group) {
      group = group || false;
      let groupedCollection = [];

      if (numOfColumns == 1) {
        return _(collection).map((item) => {
          return angular.extend(item, {cssClass: 'col-xs-12'})
        })
      } else {
        let cssColumns = 12 / numOfColumns;
        let numOfRows = Math.ceil(collection.length / numOfColumns);

        let certGroups = _(_.range(numOfRows)).map((index) => {return collection.slice(index * numOfColumns, index * numOfColumns + numOfColumns) });

        certGroups = _(certGroups).map((group) => {
          return _(group).map((cert) => {
            let cssClass = `col-xs-${cssColumns}`;
            let columnsFilled = group.length * cssColumns;
            let cssOffset = group.length < numOfColumns ? " img-block-wrapper-offset" : '';
            cssClass = cssClass + cssOffset;
            let offset = cssOffset.length > 0  && cert == group[0] ? `${((0.5 - columnsFilled / 24) * 100 - 0.1).toFixed(1)}%` : null;

            return angular.extend(cert, {cssClass: cssClass, offset: offset})
          })
        })

        return group ? certGroups : _(certGroups).flatten()
      }
    }

    function destructor() {
      window.AboutUsCtrl = null
    }

    // Public members:

    constructor();
  }
]);
