import * as _ from 'underscore';

angular.module('app').controller('OrderSuggestionsCartCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', 'Alerts', function ($scope, $sce, $http, $q, $rootScope, Alerts) {
  $scope.isSaving = false;
  $scope.ordersFirstLoading = true;
  $scope.lastUserModificationAt = (new Date).getTime();

  $scope.originalLineItemPositions = {};

  $scope.loadOrder = function() {
    return $q(function(resolve, reject) {
      $scope.setSaving();

      var endpoint = "/api/frontend/orders/current.json?locale=" + I18n.locale;

      $http.get(endpoint, {params: { number: window.currentOrderNumber, order_token: window.currentOrderToken }}).then(function(response) {
        if(response.data.order && response.data.order.line_items) {
          response.data.order.line_items = sortLineItems(response.data.order.line_items);
        }

        $scope.order = response.data.order;
        // REMOVE?
        setOriginalLineItemPositions();

        sendToCriteo($scope.order);

        $rootScope.$broadcast("cart-ctrl:updated", $scope.order);

        resolve(response);
      })
      .finally(function() {
        $scope.setNotSaving();
      });
    })
  };

  $scope.setSaving = function() {
    $scope.isSaving = true;
    NProgress.start();
  }

  $scope.setNotSaving = function() {
    $scope.ordersFirstLoading = false;
    $scope.isSaving = false;
    NProgress.done();
  }

  // private

  function sendToCriteo(order) {
    if (window.criteo_q == null) return;

    var items = _.map(order.line_items, function(line_item) {
      return { id: line_item.product.sku, price: line_item.price, quantity: line_item.variant.variant_based_quantity };
    });

    window.criteo_q.push({ event: "viewBasket", item: items });
  }

  function setOriginalLineItemPositions() {
    $scope.originalLineItemPositions = {};

    var index = 0;
    _.each($scope.order.line_items, function(line_item) {
      if (line_item.quantity > 0) {
        $scope.originalLineItemPositions[line_item.product.id.toString()] = index;
        index = index + 1;
      }
    });
  }

  // preserve original position for existing, sort others
  function sortLineItems(line_items) {
    var res = _.each(line_items, function(line_item) {
      var productNamePartSize = 100;

      var originalPosition = $scope.originalLineItemPositions[line_item.product.id.toString()];

      if (originalPosition != null) {
        var sortArray = [0, originalPosition, Array(productNamePartSize + 1).join("a")];
      } else {
        // (100 - quantity) —— means that we sort less quantity first assuming it is sorted as a string
        // var sortArray = [(100 - line_item.quantity), line_item.product.name.slice(0, productNamePartSize)];
        var sortArray = [1, (100 - line_item.quantity), line_item.updated_at];
      }

      line_item.sortValue = sprintf("%d-%05d-%s", sortArray[0], sortArray[1], sortArray[2]);
    });

    res = _.sortBy(line_items, function(line_item) { return line_item.sortValue; });

    return res;
  }

  function resortLineItems() {
    $scope.order.line_items = sortLineItems($scope.order.line_items);
  }

  // initialize
  $scope.loadOrder();

  $rootScope.$on('cartdata:saved', function() {
    $scope.loadOrder();
  });

  $rootScope.$on("line_item:updated", function() {
    resortLineItems();
  });

  $rootScope.$on("line_item:change:start", function() {
    window.Tracking.sendOrderSuggestionEvent('cart', 'changed');
  });

  $rootScope.$on("line_item:delete:clicked", function() {
    window.Tracking.sendOrderSuggestionEvent('cart', 'product-removed');
  });
}]);
