import * as _ from 'underscore';

/**
 * Helps inflate resource.errors dictionary with errors based
 * on a typical API post/put request response with ActiveRecord
 * error collection in the response.errors key.
 *
 * @type {Function}
 */
var ActiveRecordErrorJar = (function(errors) {
  var scope = {
    resourceErrors: null,
    errors: null,

    getErrors: function() {
      return new Promise(function(resolve, reject) {
        resolve(scope.errors);
      })
    },

    inflateResource: function(resource, resource_key) {
      if (resource.errors == null)
        resource.errors = [];

      return this.getErrors().then(function(errors) {
        _.each(errors, function(val, key) {
          if (typeof(key) == 'number') {
            resource.errors = resource.errors || {};
            resource.errors['base'] = val;
          } else {
            let fieldKeys = key.split('.');

            // Skip errors for resources that do not match the resource_key
            if (resource_key != null && resource_key != fieldKeys[0])
              return;

            let resourceFieldKey = fieldKeys[fieldKeys.length - 1];
            resource.errors[resourceFieldKey] = val.join(", ");
          }
        })
      })
    }
  };

  scope.resourceErrors = errors;
  scope.errors = errors;

  return scope;
});

window.ActiveRecordErrorJar = ActiveRecordErrorJar;