import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app').controller('CheckoutPickupFormCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', '$element', 'AddressHelper', 'Alerts', 'blockUI', 'UserLocation', 'PromisedElement', function ($scope, $sce, $http, $q, $rootScope, $timeout, $element, AddressHelper, Alerts, blockUI, UserLocation, PromisedElement) {
  window.CheckoutPickupFormCtrl = $scope;

  $scope.checkoutCtrl = window.CheckoutCtrl;
  $scope.selectedPickupPoint = null;

  /**
   * @type {google.maps.Map}
   */
  $scope.pickupPointMap = null;

  /**
   * @type {app.farmyCheckoutAddressForm}
   */
  $scope.billAddressForm = null;

  $scope.selectedBillAddressId = null;

  $scope.markers = null;

  // Private members

  function constructor() {
    // Map update in the summary section
    $scope.checkoutCtrl.getPickupPoints().then(function() {
      if ($scope.checkoutCtrl.pickupPoint) {
        $scope.selectedPickupPoint = $scope.checkoutCtrl.pickupPoint;
      }

      $scope.updatePickupPointMap();
      $scope.sortByDistance();
    });

    $timeout(() => {
      if (!$scope.checkoutCtrl.shippingMethods || $scope.checkoutCtrl.shippingMethods.length == 0) $scope.checkoutCtrl.getShippingMethods()
    }, 1);

    $scope.$watch('checkoutCtrl.billAddress', (newValue, oldValue) => {
      if ($scope.checkoutCtrl.billAddress != null && $scope.selectedBillAddress == null) {
        $scope.selectedBillAddress = angular.extend({}, $scope.checkoutCtrl.billAddress);
        $timeout($scope.checkFormsValid, 100);
      }
    });

    // If preference address is readily available, load it immediately
    // TODO: Change to promise
    if ($scope.checkoutCtrl.preferenceBillAddresses != null) {
      if ($scope.checkoutCtrl.preferenceBillAddresses.length > 0) {
        $timeout(() => {
          if (AddressHelper.isBlank($scope.selectedBillAddress)) {
            $timeout(() => {prefillKnownAddress()}, 1)
          }
        })
      } else {
        $timeout(() => {
          if (window.Checkout_billAddressForm)
            window.Checkout_billAddressForm.showFullForm = true;
        }, 10)
      }
    } else { // Start watching the parent controller for preference addresses
      var unwatchCheckoutCtrlpreferenceBillAddresses = $scope.$watch('checkoutCtrl.preferenceBillAddresses', function(newValue, oldValue) {
        if (newValue != oldValue && $scope.checkoutCtrl.preferenceBillAddresses != null) {
          if ($scope.checkoutCtrl.preferenceBillAddresses.length > 0) {
            if (AddressHelper.isBlank($scope.selectedBillAddress)) {
              $timeout(() => {prefillKnownAddress()}, 1);
              unwatchCheckoutCtrlpreferenceBillAddresses()
            }
          } else {
            $timeout(() => {
              if (window.Checkout_billAddressForm)
                window.Checkout_billAddressForm.showFullForm = true;
            }, 10)
          }
        }
      })
    }
  }

  function destructor() {
    window.CheckoutPickupFormCtrl = null;
  }

  function setupMapMarkers() {
    $scope.markers = [];
    _.each($scope.checkoutCtrl.availablePickupPoints, (p) => {
      var marker = new google.maps.Marker({
        map: $scope.pickupPointMap,
        position: {lat: parseFloat(p.lat), lng: parseFloat(p.lng)}
      });

      marker.addListener('click', function(e) {
        $scope.selectPickupPoint(p);
      });

      $scope.markers.push(marker);
    });
  }

  /**
   * Attempts to select the most recent known preference address
   */
  function prefillKnownAddress() {
    // Bill address
    $scope.checkoutCtrl.preferenceBillAddresses = _($scope.checkoutCtrl.preferenceBillAddresses).sortBy((address) => {
      if (address.created_at)
        return moment(address.created_at).unix();
      return 0
    }).reverse();

    if ($scope.checkoutCtrl.preferenceBillAddresses[0]) {
      $scope.selectedBillAddress = angular.extend({}, $scope.checkoutCtrl.preferenceBillAddresses[0]);

      // Force-select that address in the preference address selector dropdown:
      if ($scope.billAddressSelector && $scope.selectedBillAddress && $scope.selectedBillAddress.id) {
        $scope.billAddressSelector.addressId = $scope.selectedBillAddress.id.toString();
        $scope.billAddressSelector.address = angular.extend({}, $scope.checkoutCtrl.preferenceBillAddresses[0]);

        $timeout(() => {
          $scope.checkFormsValid();
        }, 100);

        // Reset the ID, no longer needed for the _edited_ address:
        $scope.selectedBillAddress.id = null;
      }
    }
  }

  // Public members

  $scope.enterBillAddress = function() {
    $scope.billAddressInputRequired = true;
  };

  $scope.submitBillAddress = function() {
    return $q(function(resolve, reject) {

      $scope.billAddressSubmitted = true;
      blockUI.start();

      if ($scope.checkoutCtrl.shipAddress) $scope.checkoutCtrl.shipAddress.errors = null;
      if ($scope.checkoutCtrl.billAddress) $scope.checkoutCtrl.billAddress.errors = null;
      if ($scope.selectedBillAddress) $scope.selectedBillAddress.errors = null;

      var checkoutParams = {
        bill_address: {
          firstname: $scope.selectedBillAddress.firstname,
          lastname: $scope.selectedBillAddress.lastname,
          title: $scope.selectedBillAddress.title,
          address1: $scope.selectedBillAddress.address1,
          address2: $scope.selectedBillAddress.address2,
          house_number: $scope.selectedBillAddress.house_number,
          company: $scope.selectedBillAddress.company,
          country_id: $scope.selectedBillAddress.country_id || AddressHelper.DEFAULT_COUNTRY_ID,
          zipcode: $scope.selectedBillAddress.zipcode,
          city: $scope.selectedBillAddress.city,
          phone: $scope.selectedBillAddress.phone,
          birth_date: AddressHelper.sanitizeBirthDate($scope.selectedBillAddress.birth_date),
        }
      };

      // Optionally inject the selected pickup point, if any
      if ($scope.selectedPickupPoint)
        checkoutParams.pickup_point_id = $scope.selectedPickupPoint.id;

      $scope.updateCheckout(checkoutParams, true).then(function(checkout) {
        blockUI.stop();

        $scope.checkoutCtrl.billAddress = checkout.bill_address;

        // Dirty trick: clear dirty attributes form flags (the bill address gets modified a bit later)
        $timeout(() => {$scope.billAddressForm.addressForm.$setPristine(); $scope.billAddressForm.showFullForm = false}, 150);
        $timeout(() => window.scrollToElement('#pickupFormPickupPointSelection'), 500);

        resolve(checkout);
      }, function(e) {
        var errors = new ActiveRecordErrorJar(e.data.errors);

        if ($scope.checkoutCtrl.billAddress)
          errors.inflateResource($scope.checkoutCtrl.billAddress, "bill_address");

        blockUI.stop();
        reject(e);
      }).finally(() => blockUI.stop());
    })
  };

  $scope.submitPickupForm = function() {
    return $q(function(resolve, reject) {
      blockUI.start();

      if ($scope.checkoutCtrl.shipAddress) $scope.checkoutCtrl.shipAddress.errors = null;
      if ($scope.checkoutCtrl.billAddress) $scope.checkoutCtrl.billAddress.errors = null;
      if ($scope.selectedBillAddress) $scope.selectedBillAddress.errors = null;

      // Also apply the pickup shipping method
      var pickupShipping = _.find($scope.checkoutCtrl.shippingMethods, (m) => m['pickup?']);

      var checkoutParams = {
        pickup_point_id: $scope.selectedPickupPoint.id,
        shipping_method_id: pickupShipping.id
      };

      // Inject bill address too, if it's still not present on the order
      // (happens with existing customers with existing addresses - there's no bill address step).
      if ($scope.checkoutCtrl.billAddress == null) {
        checkoutParams.bill_address = {
          firstname: $scope.selectedBillAddress.firstname,
          lastname: $scope.selectedBillAddress.lastname,
          title: $scope.selectedBillAddress.title,
          address1: $scope.selectedBillAddress.address1,
          address2: $scope.selectedBillAddress.address2,
          house_number: $scope.selectedBillAddress.house_number,
          company: $scope.selectedBillAddress.company,
          country_id: $scope.selectedBillAddress.country_id || AddressHelper.DEFAULT_COUNTRY_ID,
          zipcode: $scope.selectedBillAddress.zipcode,
          city: $scope.selectedBillAddress.city,
          phone: $scope.selectedBillAddress.phone,
          birth_date: AddressHelper.sanitizeBirthDate($scope.selectedBillAddress.birth_date),
        }
      }

      $scope.updateCheckout(checkoutParams).then(function(checkout) {
        blockUI.stop();
        if (checkout.bill_address) $scope.checkoutCtrl.billAddress = checkout.bill_address;
        $scope.checkoutCtrl.pickupPoint = $scope.selectedPickupPoint;
        $scope.checkoutCtrl.updateStage().then(r => $scope.updateShippingDestinationMap()); // Force-update checkout stage on success
        $timeout(() => window.scrollToElement('#pickupPointSummary'), 500);
        resolve(checkout);
      }, function(e) {
        console.error(e);
        Alerts.error(e.data.friendly_errors[0]);

        blockUI.stop();
        reject(e);
      }).finally(() => blockUI.stop());
    })
  };

  $scope.selectPickupPoint = function(pickupPoint) {
    $scope.selectedPickupPoint = pickupPoint;

    if ($scope.pickupPointMap && pickupPoint.lat) {
      $scope.pickupPointMap.panTo({lat: parseFloat(pickupPoint.lat), lng: parseFloat(pickupPoint.lng)});
    }
  };

  /**
   * Sorts pickup points by distance to the customer
   */
  $scope.sortByDistance = function() {
    UserLocation.getUserLocation().then(function(coords) {
      _.each($scope.checkoutCtrl.availablePickupPoints, (p) => {
        var dist = UserLocation.unsafeDistanceTo(parseFloat(p.lat), parseFloat(p.lng));

        if (dist) {
          dist = Math.round(dist, 2);
        }

        p.distanceTo = dist;
      })
    })
  };

  /**
   * Update the shipping destination google map for either delivery or pickup location
   */
  $scope.updatePickupPointMap = function() {
    $scope.checkoutCtrl.getMapsApi().then(function() {
      if ($scope.pickupPointMap == null) {
        var defaultCenter = new window.google.maps.LatLng(47.3295618, 8.4789311);

        var mapOptions = {
          zoom: 15,
          center: defaultCenter,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          draggable: !("ontouchend" in document),
          mapTypeId: window.google.maps.MapTypeId.ROADMAP
        };

        PromisedElement.promise($element, '.pickup-point-map').then(function (el) {
          var el = el.get(0);

          $scope.pickupPointMap = new window.google.maps.Map(el, mapOptions);
          $(el).addClass('active');

          setupMapMarkers();

          UserLocation.getUserLocation().then(function(coords) {
            $scope.customerMarker = new google.maps.Marker({
              map: $scope.pickupPointMap,
              position: {lat: coords.latitude, lng: coords.longitude}
            });

            $scope.pickupPointMap.panTo({lat: coords.latitude, lng: coords.longitude});
          })
        });
      }
    });
  };

  $scope.checkFormsValid = function() {
    // Validate only bill address
    $scope.areFormsValid = $scope.billAddressForm && $scope.billAddressForm.addressForm.$valid;
  };

  // Initialize

  $scope.$on('$destroy', destructor);
  constructor();
}]);
