import React from 'react'
import {RjsSupportContactFormHelpInfo} from "./RjsSupportContactFormHelpInfo";
import {RjsSupportContactFormGeneral} from "./RjsSupportContactFormGeneral";
import {RjsSupportContactFormOrderSelector} from "./RjsSupportContactFormOrderSelector";

export class RjsSupportContactFormOrdering extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customData: {},
            allChildComponentsLoaded: false,
            currentUser: UserService.currentUser
        }
        this.$http = props.http;
        this.firstLoad = true;

        this.childComponentsLoaded = [];
        this.waitingComponentsLength = 2;

        this.onChildComponentLoaded = this.onChildComponentLoaded.bind(this);
        this.onCustomDataChanges = this.onCustomDataChanges.bind(this);

        this.onFormSubmitted = props.onFormSubmitted;
    }

    render(props) {
        return (<div className={`support-form-ordering ${this.state.allChildComponentsLoaded ? '' : 'hidden'}`}>
            <RjsSupportContactFormOrderSelector parentFormType={this.props.formType} http={this.$http} currentUser={this.state.currentUser} customData={this.state.customData} onChildComponentLoaded={this.onChildComponentLoaded} onCustomDataChanges={this.onCustomDataChanges}/>
            <RjsSupportContactFormHelpInfo parentFormType={this.props.formType} http={this.$http} onChildComponentLoaded={this.onChildComponentLoaded} />
            <RjsSupportContactFormGeneral parentFormType={this.props.formType} currentUser={this.state.currentUser} customData={this.state.customData} http={this.$http} onFormSubmitted={this.onFormSubmitted}/>
        </div>);
    }

    componentDidMount() {

    }

    onCustomDataChanges(customData) {
        this.setState({customData: customData});
    }

    onChildComponentLoaded(loaded) {
        this.childComponentsLoaded.push(loaded);
        if (loaded && this.childComponentsLoaded.length >= this.waitingComponentsLength) {
            this.setState({allChildComponentsLoaded: _.every(this.childComponentsLoaded, (child) => {
                    return child;
                })
            });
        }
    }

}
