'use strict';

angular.module('app').controller('UserPasswordCtrl', ['$scope', function ($scope) {
  window.UserPasswordCtrl = $scope;

  $scope.submitPasswordReset = function () {
    if($scope.resetPasswordForm.$valid)
      UserService.resetPassword($scope.email).then((response) => {
        if(_.isEmpty(response.data.errors)) Alerts.success($translate.instant('devise.user_passwords.spree_user.send_instructions'));
        else Alerts.error($translate.instant('invalid.email'));
      })

  };
}]);