'use strict';

angular.module('app').controller('ProductListingTestCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$sce', '$localStorage', 'Alerts', '$timeout',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, $sce, $localStorage, Alerts, $timeout) {
    window.ProductListingTestCtrl = $scope;

    $scope.loadProducts = function() {
      var url = sprintf('/api/frontend/products/autosuggest.json?keywords=%s&per_page=12&mode=in_catalog', 'bananen');

      return $http.get(url).then(function(response) {
        $scope.products = response.data.products;
      });
    };

    $scope.$on('$destroy', function() {
      window.ProductListingTestCtrl = null;
    });

    $scope.loadProducts();
}]);
