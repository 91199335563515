import * as _ from 'underscore';

angular.module('app').controller('SmartPassEditPageCtrl', ['$scope', '$rootScope', '$http', '$uibModal', 'Alerts', '$translate', 'SmartPassService', function($scope, $rootScope, $http, $uibModal, Alerts, $translate, SmartPassService) {
  window.SmartPassEditPageCtrl = $scope;
  $scope.upgradedSPSubscription = null;
  $scope.smartPassEndpoint = '/api/frontend/smart_pass_subscriptions/';
  $scope.upgradingSmartPass = false;
  $scope.isUpgrading = false;
  $scope.selectedSPSubscriptionAddressId = null;
  $scope.renewableCheckboxState = false;
  $scope.smartPassService = SmartPassService;

  $scope.toggleUpgrade = function() {
    $scope.upgradingSmartPass = !$scope.upgradingSmartPass;
    if ($scope.upgradingSmartPass) {
      $scope.upgradedSPSubscription = angular.copy($scope.selectedSPSubscription);
    } else {
      $scope.upgradedSPSubscription = null;
    }
  };

  $scope.cancelSPSubscription = function() {
    $http.patch($scope.smartPassEndpoint + $scope.selectedSPSubscription.id + '/cancel_subscription.json').then(function() {
      $scope.loadUser();
    });
  };

  $scope.changeType = function(type) {
    $scope.upgradedSPSubscription.level = type;
  };

  $scope.upgradeSubscription = function() {
    // Logic behind subscription creation and payment
    $scope.isUpgrading = true;
    $http.put($scope.smartPassEndpoint + $scope.upgradedSPSubscription.id + '/upgrade.json', $scope.upgradedSPSubscription).then(function(response) {
      Alerts.success($translate.instant('smart_pass.alerts.hofpass_upgraded'));
      $scope.loadUser();
      $scope.toggleUpgrade();
    }, function(error) {
      Alerts.error($translate.instant('smart_pass.alerts.couldnt_upgrade'));
    }).finally(function() {
      $scope.isUpgrading = false;
      $scope.upgradingSmartPass = false;
    });
  };

  // 'Cancel Subscription' MODAL
  $scope.cancelTemplateUrl = '/ng/templates/smart_pass/smart_pass_cancel_subscription_modal.html?locale=' + I18n.locale;

  $scope.toggleRenewable = function() {
    const params = {is_renewable: !$scope.selectedSPSubscription.is_renewable};
    $http.put($scope.smartPassEndpoint + $scope.selectedSPSubscription.id + '.json?locale=' + I18n.locale, params).then(function(response) {
      Alerts.success($translate.instant('smart_pass.alerts.settings_updated'));
      $scope.loadUser();
    }, function(error) {
      Alerts.error($translate.instant('smart_pass.alerts.cannot_perform_operation'));
    });
  };

  $scope.openCancelSubscriptionModal = function() {
    const cancelSubscriptionInstance = $uibModal.open(
      {
        animation: true,
        size: 'md',
        templateUrl: $scope.cancelTemplateUrl,
        windowClass: 'smart-pass-cancel-subscription-modal',
        controller: 'SmartPassCancelSubscriptionModalCtrl'
      }
    );

    cancelSubscriptionInstance.result.then(function(result) {
      if (result === 'confirm') {
        // $scope.cancelSPSubscription();
        if ($scope.selectedSPSubscription.is_renewable) {
          $scope.selectedSPSubscription.is_renewable = false;
          $http.put($scope.smartPassEndpoint + $scope.selectedSPSubscription.id + '.json?locale=' + I18n.locale, $scope.selectedSPSubscription).then(function(response) {
            Alerts.success($translate.instant('smart_pass.alerts.hofpass_cancelled'));
            $scope.loadUser();
          }, function(error) {
            Alerts.error($translate.instant('smart_pass.alerts.cannot_perform_operation'));
          });
        } else { Alerts.success($translate.instant('smart_pass.alerts.hofpass_cancelled')) }
      }
    });
  };

  // 'Edit address' MODAL
  $scope.editAddressTemplateUrl = '/ng/templates/addresses/edit_address_modal.html?locale=' + I18n.locale;
  $scope.openEditAddressModal = function() {
    const editAddressModal = $uibModal.open(
      {
        animation: true,
        size: 'md',
        templateUrl: $scope.editAddressTemplateUrl,
        windowClass: 'edit-address-modal',
        controller: 'EditAddressModal',
        resolve: {
          addressId: $scope.selectedSPSubscription.address.id
        }
      }
    );

    editAddressModal.result.then(function(result) {
      if (result.successful) {
        Alerts.success($translate.instant('smart_pass.alerts.address_updated'));
        if (result.updated) $scope.loadUser();
      } else {
        Alerts.error(result.error.data.message);
      }
    });
  };

  $scope.$watch('user', function() {
    if (!$scope.user) return;

    $scope.smartPassSubscriber = $scope.user.ever_had_valid_smartpass_subscription;

    if (!$scope.user.smart_pass_subscriptions || $scope.user.smart_pass_subscriptions.length === 0) return;

    $scope.smartPassSubscriptions = $scope.user.smart_pass_subscriptions;

    _($scope.smartPassSubscriptions).each(function(smartPassSubscription) {
      normalizeSubscription(smartPassSubscription);
    });

    if (!$scope.selectedSPSubscriptionAddressId) {
      $scope.selectedSPSubscription = $scope.smartPassSubscriptions[0];
      $scope.selectedSPSubscriptionAddressId = $scope.selectedSPSubscription.address.id;
    } else {
      $scope.selectedSPSubscription = $scope.smartPassSubscriptions.find(function(subscription) {
        return subscription.address.id === $scope.selectedSPSubscriptionAddressId;
      });
    }

    $rootScope.currentSmartPassLevel = $scope.selectedSPSubscription.level;
  });

  function normalizeSubscription(smartPassSubscription) {
    smartPassSubscription.finished_at = $scope.smartPassService.formatDate(smartPassSubscription.finished_at, smartPassSubscription?.sp_type);
    smartPassSubscription.started_at = $scope.smartPassService.formatDate(smartPassSubscription.started_at, smartPassSubscription?.sp_type);
    smartPassSubscription.label = smartPassSubscription.address.full_address + ' - ' + smartPassSubscription.state_for_user;
  }

  $scope.$watch('selectedSPSubscription', function() {
    if ($scope.isUpgrading) $scope.toggleUpgrade();

    if ($scope.selectedSPSubscription) {
      updateCurrentSmartPassLevel();
      updateSelectedSubscriptionAddress();

      $scope.renewableCheckboxState = $scope.selectedSPSubscription.is_renewable;
    }
  });

  function updateCurrentSmartPassLevel() {
    if ($rootScope.currentSmartPassLevel !== $scope.selectedSPSubscription.level) {
      $rootScope.currentSmartPassLevel = $scope.selectedSPSubscription.level;

      if ($rootScope.switchSmartPassBadge) {
        $rootScope.switchSmartPassBadge();
      }
    }
  }

  function updateSelectedSubscriptionAddress() {
    if ($scope.selectedSPSubscription.address.id !== $scope.selectedSPSubscriptionAddressId) {
      $scope.selectedSPSubscriptionAddressId = $scope.selectedSPSubscription.address.id;
    }
  }

  $scope.$on('$destroy', function() {
    window.SmartPassEditPageCtrl = null;
  });
}]);
