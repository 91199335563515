import * as _ from 'underscore';
import * as moment from 'moment';

angular.module('app').controller('SuppliersCatalogViewCtrl', ['$scope', '$http', '$q', '$rootScope', '$sce', '$timeout', '$element', 'Alerts', 'blockUI', '$location', 'CatalogServiceHelper', 'TaxonHelper', '$uibModal', function ($scope, $http, $q, $rootScope, $sce, $timeout, $element, Alerts, blockUI, $location, CatalogServiceHelper, TaxonHelper, $uibModal) {
  window.SuppliersCatalogViewCtrl = $scope;

  // Constructor/Destructor

  function constructor() {
    if ($scope.filter.alpha == null && $scope.filter.region == null)
      $scope.filter.alpha = '[0-9],a,ä,b,c';

    loadSuppliers();
  }

  function destructor() {
    _destroyed = true;
    window.SuppliersCatalogViewCtrl = null;
  }

  // Public properties

  $scope.suppliers = null;
  $scope.sortMode = 'name_asc';

  $scope.filter = {
    alpha: $location.search().alpha,
    region: null
  };

  $scope.pagination = {
    current_page: 1,
    per_page: 32
  };

  $scope.setAlphaFilter = function(value) {
    $scope.filter.region = null;
    $scope.filter.alpha = value;

    loadSuppliers();
  };

  //
  // Private members
  //

  let _destroyed = false;

  function loadSuppliers() {
    return $q((resolve) => {
      var params = {
        template: 'in_catalog',
        locale: I18n.locale,
        name_starts: $scope.filter.alpha,
        sort_mode: 'alpha',
        // page: $scope.pagination.current_page,
        // per_page: $scope.pagination.per_page
      };

      $http.get(`/api/frontend/suppliers.json`, { params: params }).then(response => {
        $scope.suppliers = response.data.suppliers;
        angular.extend($scope.pagination, response.data);
        delete $scope.pagination.suppliers;
      });
    });
  }

  // Initialize

  $scope.$on('$destroy', destructor);
  constructor()
}]);
