import * as _ from 'underscore';

angular.module('app').controller('WeightControlModalCtrl', ['$scope', '$uibModalInstance', 'resource', function($scope, $uibModalInstance, resource) {
    console.log('WeightControlModalCtrl loaded');
    window.WeightControlModalCtrl = $scope;

    $scope.resource = resource;
    $scope.weightControlledLineItems = resource.line_items ? _(resource.line_items).select((line_item) => line_item.weight_control_billing) : null;

    $scope.closeModal = function() {
      $uibModalInstance.dismiss()
    };
}]);