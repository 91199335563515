'use strict';

angular.module('app').controller('ProductUnavailableInHubModalCtrl', ['$scope', '$uibModalInstance', 'categoryUrl',
  function ($scope, $uibModalInstance, categoryUrl) {
    $scope.categoryUrl = categoryUrl;

    $scope.onChangeZipcodeClicked = function() {
      $uibModalInstance.close('openZipcodeModal');
      $scope.openZipcodeModal();
    };

    $scope.onCloseClicked = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.$on('$destroy', function() {
      $scope = null
    });
  }
]);
