import * as _ from "underscore";
import {getGlobalState} from "../shared-services/globalState";

angular.module("app").service("OrderHelper", ["$rootScope", "$q", "$http", "$interval", "$location", "$timeout", "blockUI", "Hubs", "$uibModal", "PromotionsService", ($rootScope, $q, $http, $interval, $location, $timeout, blockUI, Hubs, $uibModal, PromotionsService) => {
  const $scope = this || {};
  window.OrderHelper = $scope;
  const {loadCart} = getGlobalState("cart");

  /**
   * Indicates whether current order is in suggestions mode.
   * This is actually a TODO, currently always returns false
   *
   * @type {boolean}
   */
  $scope.isSuggestionOrder = false;

  /**
   * Indicates whether current order is in ABO (weekly) mode.
   * This is actually a TODO, currently always returns false
   *
   * @type {boolean}
   */
  $scope.isWeeklyOrder = false;

  /**
   * Gets a list of incompatible order items. You can optionally pass a specific hub_id
   * or zipcode to check against, via 'params'.
   *
   * @param order An object that has a nested line_items collection, with each line item having a child called 'product' with the hub_ids field.
   * @param params { hub_id: (optional), zipcode: (optional) }
   */
  $scope.getIncompatibleLineItems = function(order, params) {
    if (params == null) { params = {} }

    // Some basic validation of the expected order structure
    if (order.line_items == null) { throw new Error("No 'line_items' node present in the order") }
    if (order.line_items.length > 0 && (order.line_items[0].hub_ids == null && (order.line_items[0].product == null || order.line_items[0].product.hub_ids == null))) { throw new Error("Line item does not contain either 'hub_ids' or 'product' node and/or 'product.hub_ids' node") }

    // A separate resolver for hub id. If there's no explicit ID
    // we have to calculate it with possible async hold'ups.
    const resolveHubId = () => {
      return $q((resolve, reject) => {
        if (params.hub_id) { // try hub_id in params
          return resolve(parseInt(params.hub_id));
        } else if (params.zipcode) { // maybe a zipcode is provided?
          Hubs.getHubByZipcode(params.zipcode).then(hub => {
            resolve(parseInt(hub.id));
          }, (e) => {
            reject("not_found");
          });
        } else if (order.hub_id) { // if there's nothing, take order.hub_id
          resolve(parseInt(order.hub_id));
        } else { // reject if there's no hub id data available at all
          reject("no_hub_id");
        }
      });
    };

    // This is the main promise of the method:
    return $q((resolve, reject) => {
      resolveHubId().then(hub_id => {
        const problemItems = _.select(order.line_items, (item) => {
          if (item.hub_ids) return item.hub_ids.indexOf(hub_id) == -1; // Supports alternative structures of order line items,
          else return item.product.hub_ids.indexOf(hub_id) == -1; // with and without a nested 'product' node.
        });

        // Resolve with an array of incompatible line items
        resolve(problemItems);
      }, (e) => { reject(e) });
    });
  };

  /**
   * Removes items that do not belong to the selected hub from the order.
   *
   * @param order An order-like structure with at least 'number' and 'line_items{product_id, hub_ids}' nodes
   * @param hub_id
   */
  $scope.removeConflictingItems = function(order, valid_hub_id) {
    return $q((resolve, reject) => {
      // Calculate productIds to be removed
      const problemItems = _.select(order.line_items, (item) => {
        if (item.hub_ids) return item.hub_ids.indexOf(valid_hub_id) == -1; // Supports alternative structures of order line items,
        else return item.product.hub_ids.indexOf(valid_hub_id) == -1; // with and without a nested 'product' node.
      });

      const productIds = _.map(problemItems, (i) => i.product_id);

      $http.patch(`/api/frontend/orders/${order.number}/remove_products.json`, {product_ids: productIds}).then((response) => {
        if (response.data.success) {
          resolve(response.data);
        } else reject(response.data);
      }, (e) => reject(e));
    });
  };

  /**
   * Repeats the sourceOrder, copying its contents into
   * the current order. Requires CartData to have a known
   * cart, with an ID and number.
   *
   * @param sourceOrder { id, token }
   * @returns {*}
   */
  $scope.repeatOrder = function(sourceOrder) {
    return $q((resolve, reject) => {
      if (window.CartData.cart == null || window.CartData.cart.number == null) {
        reject("no_current_order");
        return;
      }

      const params = {
        repeat_order_id: sourceOrder.id,
        order_token: sourceOrder.token,
        excluded_product_ids: sourceOrder.excludedProductIds,
        no_response: "t",
        no_delivery_slot: "t",
        no_shipments: "t",
        no_adjustments: "t",
        no_payments: "t",
        no_line_items: "t",
        no_state_specific: "t"
      };
      
      $http.post(`/api/orders/${window.CartData.cart.number}/repeat.json`, params)
        .then(() => {
          loadCart();
          $location.path("/cart");
          resolve();
        }, e => reject(e));
    });
  };

  $scope.showPermaboxDepositExplanationModel = function() {
    const modal = $uibModal.open({
      animation: true,
      templateUrl: `/ng/templates/orders/permabox_deposit_explanation.html?locale=${I18n.locale}`,
      controller: "PermaboxDepositExplanationModalCtrl",
      size: "lg",
      windowClass: "permabox-deposit-modal",
      resolve: {
        order: window.CartData.cart
      }
    });

    modal.result.then(() => {
      // Do something on submit? Not this time...
    });
  };

  $scope.showNoFeesPromoModal = function() {
    const marketingZone = PromotionsService.currentPromotion.marketing_zone;
    const zoneTag = marketingZone === "C" ? "B" : marketingZone;

    const noFeesPromoModal = $uibModal.open({
      animation: true,
      templateUrl: `/ng/templates/orders/no_fees_promo_modal.html?marketing_zone=${zoneTag}&locale=${I18n.locale}`,
      controller: "NoFeesPromoModalCtrl",
      size: "lg",
      windowClass: "no-fees-promo-modal"
    });

    noFeesPromoModal.result.then(() => {});
  };

  $scope.showHandlingFeeModal = function(order) {
    const handlingFeeModal = $uibModal.open({
      animation: true,
      templateUrl: `/ng/templates/orders/handling_fee_modal.html?locale=${I18n.locale}`,
      controller: "HandlingFeeModalCtrl",
      size: "lg",
      windowClass: "handling-fee-modal",
      resolve: {
        order
      }
    });

    handlingFeeModal.result.then(() => {});
  };

  $rootScope.showWeightControlModal = function(resource) {
    const resourceType = resource.number ? "order" : "product";
    
    const weightControlModal = $uibModal.open({
      animation: true,
      templateUrl: `/ng/templates/${resourceType}s/weight_control_modal.html?locale=${I18n.locale}`,
      controller: "WeightControlModalCtrl",
      size: "lg",
      windowClass: "weight-control-modal",
      resolve: {
        resource
      }
    });

    weightControlModal.result.then(() => {

    });
  };

  return $scope;
}]);
