'use strict';

angular.module('app').controller('HomeVideoSectionViewCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$timeout', '$http', '$element', '$localStorage', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $timeout, $http, $element, $localStorage, Alerts) {
    window.HomeVideoSectionViewCtrl = $scope;

    // Public members

    $scope.vimeoPlayer = null;

    // Private members

    function constructor() {
      var initWatcher = $scope.$watch('videoId', function() {
        if ($scope.videoId) {
          initVideo();
          initWatcher()
        }
      });
    }

    function destructor() {
      window.HomeVideoSectionViewCtrl = null;
    }

    function initVideo() {
      $timeout(function() {
        var options = {
          id: $scope.videoId || 198065652,
          width: $element.find('article.video').width(),
          height: $element.find('article.video').height(),
          title: false,
          playsinline: true,
          autoplay: false,
          background: false,
          loop: false
        };

        $scope.vimeoPlayer = new Vimeo.Player($element.find('#welcome-video-frame')[0], options);
        $scope.vimeoPlayer.setVolume(0);

        $scope.vimeoPlayer.on('play', function() {
          console.log('played the video!');
        });
      }, 1);
    }

    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
