'use strict';

angular.module('app').controller('ProfileEditCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$localStorage', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, $localStorage, Alerts) {
    console.log("ProfileEditCtrl controller initialized with scope");

    window.ProfileEditCtrl = $scope;

    $scope.loadUserProfile = function() {
      return $http.get(sprintf('/api/frontend/profiles/%d.json', $scope.currentUserId)).then(function(response) {
        $scope.profile = response.data.profile;
      });
    };

    $scope.updateProfile = function() {
      blockUI.start($translate.instant('messages.please_wait_while_loading'));
      $scope.isUpdating = true;

      return $http.patch(sprintf('/api/frontend/profiles/%d.json', $scope.currentUserId), {
        // This profile object's params are filtered at the profiles_controller
        // TODO(Mike): Remove the 'posts' key (not needed for the update action and could be substantially heavy)
        profile: $scope.profile
      }).then(function(response) {
        $scope.profile = response.data.profile;

        Alerts.success($translate.instant('messages.changes_saved'));
      }).finally(function() {
        $scope.isUpdating = false;
        blockUI.stop();
      });
    }

    $scope.$watch('userIdentifier', function(newValue, oldValue) {
      if(newValue) {
        $scope.userIdentifier = newValue;
        $scope.loadUserProfile();
      }
    });
  }
]);
