'use strict';

angular.module('app').controller('UserLoginPageCtrl', ['$scope', '$rootScope', '$timeout', '$window', '$location', 'Alerts', function ($scope, $rootScope, $timeout, $window, $location, Alerts) {
  window.UserLoginPageCtrl = $scope;

  $scope.twoFactorVerificationPending = false;

  function constructor() {
  };

  function destructor() {
    window.UserLoginPageCtrl = null;
  };

  $scope.loginUser = {
    email: undefined,
    password: undefined
  };

  $scope.onLoginFormSubmit = function () {
    $scope.authenticationInProgress = true;

    UserService.authenticate($scope.loginUser.email, $scope.loginUser.password, $scope.loginUser.verify_token).then((user) => {
      if (user.pending_verification) {
        $scope.twoFactorVerificationPending = true;
        $scope.authenticationInProgress = false;

        if (user.pending_verification_message) {
          Alerts.info(user.pending_verification_message)
        }
      } else {
        $scope.twoFactorVerificationPending = false;
        $scope.authenticationInProgress = false;

        if (UserService.loginOptions?.backToUrl.length) {
          const urlToGo = decodeURIComponent(UserService.loginOptions.backToUrl);
          UserService.loginOptions.forcePageReload ? window.location.href = urlToGo : $location.url(urlToGo);
        } else {
          UserService.loginOptions.forcePageReload ? window.location.href = '/' : $location.path('/');
        };
        UserService.resetLoginOptions();
      }
    }, error => {
      $scope.authenticationInProgress = false;
    })
  };


  $scope.facebookLogin = function () {
    $scope.authenticationInProgress = true;
    UserService.authenticateBySocialNetwork('facebook')
      .then(() => {
          $scope.authenticationInProgress = false;
          $location.path('/');
        }, (user) => {
          if (user.user_already_exists) {
            Alerts.facebook($translate.instant('social.facebook.already_exists', {email: user.email_old, strategy: 'Facebook'}), {timeout: 0});
          }
          else{
            window.localStorage.setItem('socialLoginUser', JSON.stringify(user));
            $location.path('/signup');
          }
          $scope.authenticationInProgress = false;
        }
      )
  };

  $scope.dismiss = function () {
    // Popup controller interface support, not used when displayed as a standalone page
    // Helps close the popup when a link is clicked
  };

  $scope.$on('$destroy', destructor);
  constructor();
}

]);
