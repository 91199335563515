'use strict';

angular.module('app').controller('PaidSubscriptionConfirmationModal', [ '$scope', '$uibModalInstance', '$http', 'paymentMethod',
  function ($scope, $uibModalInstance, $http, paymentMethod) {
    window.PaidSubscriptionConfirmationModal = $scope;
    $scope.paymentMethod = paymentMethod;

    $scope.onConfirmClicked = function() {
      $uibModalInstance.close({confirm: true, change_payment: false});
    };

    $scope.onChangeClicked = function() {
      $uibModalInstance.close({confirm: false, change_payment: true});
    }

    $scope.onCancelClicked = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
]);
