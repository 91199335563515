window.NumberUtils = {
  pad: function(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }
};

window.UrlUtils = {
  /**
   * Strips the leading slash from str, if it is present
   *
   * @param str
   * @returns {*}
   */
  stripLeadingSlash: function(str) {
    if (str[0] == '/')
      return str.substr(1, str.length - 1);

    return str;
  },

  /**
   * Strips the locale token and the leading slash
   *
   * @param str
   * @returns {*}
   */
  stripLocaleSlash: function(str) {
    str = str.replace(/^(\/?de\/)|(\/?fr-CH\/)|^(\/en\/)|(^en\/)/, '');

    return UrlUtils.stripLeadingSlash(str);
  },

  getPathNodes: function(url) {
    return _.reject(url.split('/'), function(node) { return node == '' });
  },

  getPathNodesWithoutLocale: function(url) {
    return _.reject(UrlUtils.stripLocaleSlash(url).split('/'), function(node) { return node == '' });
  },

  addLocationPrefix: function(str) {
    if (NgFrontendAppCtrl.locationLocalePrefix && NgFrontendAppCtrl.locationLocalePrefix.length > 0) {
      if (str[0] == '/') {
        return '/' + NgFrontendAppCtrl.ngfrontend.locale + str;
      } else {
        return NgFrontendAppCtrl.ngfrontend.locale  + '/' + str;
      }
    } else {
      return str;
    }
  },

  reloadWithLocale: function(locale) {
    var href = location.href;

    if (href.indexOf('?') == -1) href += '?';
    href += `locale=${I18n.locale}`;

    location.href = href;
  }
};

// Precautions for name collisions
if (window.ArrayUtils == undefined) window.ArrayUtils = {};
window.ArrayUtils.isEmpty = function(array_or_null) {
  if (array_or_null == null || array_or_null.length == 0) return true;
  return false;
};

let underscored = require('underscore.string/underscored');

window.StringUtils = {
  underscored: underscored
};

window.errorMessage = function(error) {
  if(error.data && error.data.error)
    return error.data.error;
  if(error.statusText)
    return error.statusText;
  else if(error.exception)
    return error.exception;
  else if(error.friendly_errors && error.friendly_errors.length > 0)
    return error.friendly_errors[0];
  else
    return error;
};

window.scrollToElement = function(el) {
  if (typeof(el) == 'string') el = $(el);
  if (el.offset())
    $('html, body').animate({ scrollTop: el.offset().top - 150 }, 500);
};