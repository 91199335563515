import * as _ from "underscore";
import {trackPageView, trackEvent} from "./tracking-helper";

angular.module("app").service("CatalogServiceHelper", ["$rootScope", "$q", "$http", "$location", "$interval", "$timeout", "$translate", "Hubs", ($rootScope, $q, $http, $location, $interval, $timeout, $translate, Hubs) => {
  const $scope = this || {};

  window.CatalogServiceHelper = $scope;

  /**
   * Will be set on category pages
   * @type {object}
   */
  $scope.currentTaxon = null;

  /**
   * Will be set on supplier pages
   * @type {object}
   */
  $scope.currentSupplier = null;

  /**
   * Indicates that the catalog view is in search mode currently
   *
   * @type {boolean}
   */
  $scope.isSearchView = null;

  $scope.isSupplierView = null;

  $scope.filterSelections = null;

  /**
   * Will be seto to true or false by a watcher of filter selections.
   *
   * @type {boolean}
   */
  $scope.anyFiltersSelected = null;

  $scope.lastUsedListMode = null;
  $scope.lastUsedSortMode = null;

  $scope.serializeFilterSelectionToUrlParams = function(selection, opts) {
    const params = {};

    for (const key in selection) {
      if (key[0] === "$") continue; // skip special vars
      if (key.indexOf("master_") > -1) continue; // skip master filters

      if (opts == null || opts[key] !== false) { params[`s[${transformFilterToUrlParameter(key)}][]`] = selection[key] }
    }

    return params;
  };

  $scope.deserializeUrlParamsToFilterSelection = function(locationSearch) {
    const selection = {};

    for (const key in locationSearch) {
      if (key[0] !== "s" || key[1] !== "[") continue; // skip special vars

      let props = {};

      if (typeof(locationSearch[key]) === "object" || Array.isArray(locationSearch[key])) {
        _.each(locationSearch[key], v => {
          props[v] = true;
        });
      } else {
        props = {};
        props[locationSearch[key]] = true;
      }

      if (key.indexOf("master_") > -1) { // special case for boolean master-filters
        selection[key.replace("s[", "").replace("]", "")] = "t";
      } else {
        selection[key.replace("s[", "").replace("][]", "")] = props;
      }
    }

    return selection;
  };

  /**
   * Must be called manually from within filters,
   * when a filter selection changes. Allows other components
   * to watch filter selections.
   */
  $scope.onFilterSelectionChange = function() {
    if (window.CatalogFilterCtrl) {
      $scope.filterSelections = window.CatalogFilterCtrl.getSelection();
      $scope.anyFiltersSelected = !_.isEmpty($scope.filterSelections);

      const serializedFilters = $scope.serializeFilterSelectionToUrlParams($scope.filterSelections);
      window.serializedFilters = serializedFilters;
    }
  };

  $scope.getMasterFilterSelection = function() {
    if (window.farmyCatalogMasterFilters) {
      return window.farmyCatalogMasterFilters.getFilterSettings();
    } else return {};
  };

  /**
   * Generic getter for products with some flexible parameters
   *
   * @param params
   * @param paginator
   * @returns {*}
   */
  $scope.loadProducts = function(params, paginator) {
    $scope.searchEngine = $location.search().search_engine;
    const debugSearch = location.search.includes("debug_search=t");

    return $q((resolve, reject) => {
      params = params || {};
      params.template = params.template || "product_in_catalog";
      params.filters = params.filters || "t";
      params.sort_mode = params.sort_mode || "hub_best";
      params.locale = $translate.use();
      params.express_delivery = params.express_delivery === true ? "t" : "f";
      if ($scope.searchEngine) params.search_engine = $scope.searchEngine;
      if (debugSearch) params.debug_search = "t";

      if (paginator) {
        params.page = paginator.pagination.page;
        params.per_page = paginator.pagination.per_page;
      }

      if (window.CategoryDebugTool) {
        params.include_unaddable = window.CategoryDebugTool.params.include_unaddable;
      }

      if (params.s == null) { params["s[hub_ids]"] = Hubs.currentHubId } else if (params.s.hub_ids == null) { params.s.hub_ids = Hubs.currentHubId }

      $scope.isLoading = $scope.isLoading = true;

      $http.get(`/api/products.json?${$.param(params)}`).then((result) => {
        if (paginator) paginator.updatePaginationFromResponse(result.data);

        // Do not update filters if there are already loaded and it's not the first page
        const needsFilterUpdate = ((window.CatalogFilterCtrl && window.CatalogFilterCtrl.filtersEmpty()) || (!window.CatalogFilterCtrl && $scope.filter == null) || $scope.pagination.current_page <= 1);

        // Inject and update filters into the neighboring filters controller, if present
        if (result.data.filters && needsFilterUpdate) {
          if (Rails.env == "development") { console.log("Filters will be updated") }

          if (window.CatalogFilterCtrl) {
            $timeout(() => {
              window.CatalogFilterCtrl.updateFilters(result.data.filters);
              CatalogServiceHelper.onFilterSelectionChange();
              $timeout(() => {
                $scope.isLoading = false;
                CatalogServiceHelper.setLoading(false);
              }, 10);
            });
          } else {
            $scope.filters = result.data.filters;
            $timeout(() => {
              CatalogServiceHelper.setLoading(false);
              $scope.isLoading = false;
            }, 10);
          }
        } else {
          // Allow a small delay before announcing load complete
          $timeout(() => {
            CatalogServiceHelper.setLoading(false);
            $scope.isLoading = false;
          }, 10);
        }

        resolve(result.data.products);
      });
    });
  };

  CatalogServiceHelper.setLoading = function(loading) {
    const oldVal = $scope.isLoading;
    $scope.isLoading = loading;

    if (oldVal != loading) {
      if (loading) { $rootScope.$broadcast("catalog:loading") } else { $rootScope.$broadcast("catalog:loaded") }
    }
  };

  $scope.setCurrentTaxon = function(taxon) {
    $scope.currentTaxon = taxon;
    // Only track GTM in Market pages.
    const shouldTrack = taxon && $scope.isCatalogPage();
    if (shouldTrack) {
      trackPageView("category",
        {
          category: taxon?.name,
          item_list_id: `category_${taxon?.id}`,
          item_list_name: taxon?.name?.toLowerCase(),
          products: window.CatalogViewCtrl?.products
        }
      );
    }
    $rootScope.$broadcast("catalog:currentTaxon:changed", {taxon});
  };

  $scope.scrollToCatalogHead = function(opts) {
    if ($("h1.current-taxon").length == 0) return;

    let targetOffset = $("h1.current-taxon").offset().top - 145;
    if (window.isMobile) targetOffset = $("h1.current-taxon").offset().top - 50;

    if (opts && opts.ifBelow) {
      // Check if the scroll position is not below
      if (($("html, body").scrollTop() || 0) <= targetOffset) return;
    }

    $("html, body").animate({scrollTop: targetOffset}, 200);
  };

  $scope.isCatalogPage = function() {
    const localeUrls = window.rootTaxonomyStem || [];
    return localeUrls.some(url => location.pathname.includes(url));
  };

  function transformFilterToUrlParameter(filterName) {
    return filterName;
  }

  return $scope;
}]);
