'use strict';

angular.module('app').controller('CheckoutRecommendCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', 'CartData', 'Alerts', 'blockUI', function ($scope, $sce, $http, $q, $rootScope, $timeout, CartData, Alerts, blockUI) {
  window.CheckoutRecommendCtrl = $scope;

  // Initialize
  $('.skip-recommend-step-button').on('click', function() {
    Tracking.sendCheckoutSkipRecommend("Button", function() {
      $('#submitButton').trigger('click');
    });
  });

  $rootScope.$on('cartdata:saved', function() {
    var orderTotalSelector = $('.checkout-step .step-header .price-info .price');
    if (orderTotalSelector.length > 0) {
      orderTotalSelector.html(sprintf('CHF %f', CartData.cart.total));
    }
  });

  $scope.$on('$destroy', function() {
    window.CheckoutRecommendCtrl = null;
  });

  // Auto-scroll down a little bit
  $(document).ready(function() {
    if ($('.checkout-step-header-recommend').length > 0)
      $('body').animate({scrollTop: $('.checkout-step-header-recommend').offset().top - 100}, 400);
  });
}]);
