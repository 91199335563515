angular.module('app').controller('HandlingFeeModalCtrl', ['$scope', '$uibModalInstance', 'order', function($scope, $uibModalInstance, order) {
    console.log('HandlingFeeModal loaded');
    window.handlingFeeModal = $scope;

    $scope.order = order;

    $scope.closeModal = function() {
      $uibModalInstance.dismiss()
    };
}]);
