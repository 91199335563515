import * as sprintf from 'sprintf';

angular.module('app').controller('ZipcodeModalCtrl', ['$scope', '$rootScope', '$uibModalInstance', '$http', '$localStorage', 'Hubs', 'angularLoad', 'isPickup', '$timeout', '$translate', 'Alerts', 'UserService', 'CartData', 'firstZipcodeSubmission',
  function ($scope, $rootScope, $uibModalInstance, $http, $localStorage, Hubs, angularLoad, isPickup, $timeout, $translate, Alerts, UserService, CartData, firstZipcodeSubmission) {
    window.ZipcodeModalCtrl = $scope;

    $scope.zipcode = window.currentZipcode ? window.currentZipcode : null;
    $scope.sessionHasZipcode = !!$scope.zipcode;
    $scope.hubName = window.currentHubName ? window.currentHubName : null;
    $scope.inputPristine = true;
    $scope.showPlaceholder = false;
    $scope.hidePromotions = true; // Avoids promotions block visible prior to AB test response
    $scope.selectedHubId = null;
    $scope.currentStorefront = window.currentStorefront;

    $scope.opensFor = $localStorage.zipcodePopupOpensFor;

    // Stages translate into specific layout contents.
    // "initial" is the same in any case,
    // once the zipcode is entered, we can choose between 'express' or 'regular'.
    $scope.stage = 'initial';
    $scope.stages = ['initial', 'express', 'regular'];
    $scope.shouldShowExpressWarning = false;

    $scope.$translate = $translate;
    $scope.UserService = UserService;

    $scope.aboutUsLink = {
      'de': '/uber-uns?nzp',
      'en': '/en/about-us?nzp',
      'fr-CH': '/fr-CH/a-propos-de-nous?nzp',
    };

    var guessRetries = 0;
    var startingLocale = I18n.locale;

    function constructor() {
      $scope.setLocale((window.I18n && window.I18n.locale) ? window.I18n.locale : 'de');

      // Do not auto-guess the locale for registered users
      if (window.currentUserId || location.search.indexOf('locale=') > -1 || location.pathname.match(/\/en\/|\/fr-CH\//)) {
        $scope.preferredLocaleManuallySet = true;
      }

      // Force try detect zip with delay (because angularLoad.then may not fire in some cases).
      // Zipcode detection is AB tested.

      if ($scope.zipcode == null || $scope.zipcode == "") {
        // if (r.testing && r.variant == 'enabled') {
        //   $scope.showPlaceholder = false;
        //   triggerZipcodeDetection()
        // } else // if test variant != 'enabled'
        $scope.showPlaceholder = true
      } else { // if $scope.zipcode present
        $scope.isZipcodeValid = true;
        $scope.checkIfZipcodeValid();
      }

      // Set focus on the input field with delay
      $timeout(function(){
        var inputVisibilityWatcher = $scope.$watch(function() { return $($('input.input-zipcode')[0]).is(':visible') }, function(isVisible) {
          if (isVisible) {
            $timeout(function(){$('input.input-zipcode').focus();}, 500);
            inputVisibilityWatcher();
          }
        });
      }, 500);

      // Watchers
      $scope.$watch('zipcode', (newZipcode, oldZipcode) => {
        if (!$scope.zipcode.length) $scope.incompatibleHubForStorefront = false;
        $scope.scanZipcode(newZipcode, oldZipcode);
      });
    }

    $scope.scanZipcode = function (newZipcode = $scope.zipcode, oldZipcode) {
      if ($scope.preventChecks) {
        $scope.preventChecks = false;
        return;
      }

      if (newZipcode === oldZipcode) return;

      if (validInput(newZipcode) && newZipcode !== $scope.lastZipcodeEntered) {
        $scope.promoType = null;
        $scope.isZipcodeValid = false;
        $scope.checkIfZipcodeValid();
      } else if (validInput() && $scope.zipcode === $scope.lastZipcodeEntered) {
        $scope.isZipcodeValid = true;

        $scope.stage = $scope.previousStage;
        if (!$scope.stage) {
          $scope.stage = $scope.canDoExpressDelivery ? 'express' : 'regular';
        }

        $scope.promoType = $scope.lastPromoType || $scope.getPromoType();
      } else {
        $scope.isZipcodeValid = false;
      }
    }

    function destructor() {
      Hubs.setZipcodePopupToShow();
      $rootScope.$broadcast('hubs:zipcode_modal:closed');
      $rootScope.isLoadingPopup = false;

      window.ZipcodeModalCtrl = null;
    }

    const validInput = (zipcode = $scope.zipcode) => {
      const zipcodeLength = 4;

      // input length equals to zipcodelength
      let validInput = zipcode?.length === zipcodeLength;
      // input is an integer (equals to its string back and forth transformation).
      if (validInput) validInput = parseInt(zipcode).toString() === zipcode.toString();

      return validInput;
    };

    const guessZip = function() {
      $scope.loadingLocation = true;

      if (navigator.geolocation && navigator.geolocation.getCurrentPosition) {
        var onPositionUpdated = function(position) {
          console.log("Position arrived", position);

          if(position.coords == null) {
            console.warn("No valid position data available in ", position);
            $scope.locationGuessed = false;
            $scope.loadingLocation = false;
            return;
          }

          // Attempt to find a zipcode for the detected location
          $http.post('/hubs/guess_zipcode_by_location.json', {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }).then(function(response) {
            if ($scope.zipcode == null || $scope.zipcode == "") {
              $scope.zipcode = response.data.zipcode;
              $scope.guessedZipcode = response.data.zipcode;
              $scope.locationGuessed = true;
              $scope.checkIfZipcodeValid();

              Tracking.sendEvent("start", "zipcodeGuessed", $scope.zipcode);
            }
          });

          $scope.loadingLocation = false;
        };

        // Request location update
        navigator.geolocation.getCurrentPosition(onPositionUpdated,
          function(error) {
            console.warn("Location API error", error);
            $scope.loadingLocation = false;

            if (guessRetries < 1) {
              guessZip();
              guessRetries += 1;
            }
          },
          { enableHighAccuracy: true, timeout: 12000 });
      } else {
        // browser doesn't supports geolocation
      }
    };

    $scope.onKeyPressed = function(event) {
      // ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Esc', 'Enter'];
      // [37, 39, 8, 46, 13];
      // let reg = /^\d+$/;
      // eslint-disable-next-line no-magic-numbers
      const assistCodes = [37, 39, 8, 46];

      if (assistCodes.includes(event.keyCode)) $scope.preventChecks = true;

      if (event.keyCode !== 13) {
        $scope.hubChanged = false;
      }
    };

    $scope.onKeyReleased = function(event) {
      $scope.inputPristine = false;
      if (!$scope.zipcode.length) {
        $scope.incompatibleHubForStorefront = false;
        $scope.inputPristine = true;
      }

      if (validInput()) {
        $scope.scanZipcode();
      }

      if (event.keyCode === 13) $scope.onSubmitClicked();
      if (event.keyCode === 27 && window.currentZipcode) $scope.onRemainClicked();
    };

    $scope.onSubmitClicked = function() {
      if (!$scope.isZipcodeValid) return;

      document.removeEventListener("keypress", $scope.onKeyReleased, true);

      Tracking.sendEvent("start", "zipcodeEntered", $scope.zipcode);
      ahoy.track("zipcode-popup-zipcode-entered", { code: $scope.zipcode, channel: window.xSessionChannel });

      Hubs.setCurrentHubFromZipcode($scope.zipcode, firstZipcodeSubmission).then((hub) => {
        Hubs.setCurrentZipcode($scope.zipcode); // TODO: Denis: I think this call is not needed
        $uibModalInstance.close({successful: true, hub});
      }, function(error) {
        $uibModalInstance.close({successful: false, error});
      }).finally(() => {
        // Reload page is needed in edge to fix unknown issues after hub is changed
        setTimeout(() => {
          if (window.isEdge) location.reload();
        }, 500);
      });
    };

    $scope.showExpressWarning = function() {
      $scope.shouldShowExpressWarning = true;
    }

    $scope.onExpressModeConfirmed = function() {
      $uibModalInstance.close({successful: true});
      CartData.setExpressDelivery(true).then(r => $scope.onSubmitClicked())
    }

    $scope.onExpressModeClicked = function () {
      if (!$scope.canDoExpressDelivery || CartData.isExpressDelivery) {
        $scope.onSubmitClicked()
      }

      if ($scope.canDoExpressDelivery && !CartData.isExpressDelivery)
        if (CartData.hasNonExpressProducts())
          $scope.showExpressWarning();
        else
          CartData.setExpressDelivery(true).then(r => $scope.onSubmitClicked())
      else
        $scope.onSubmitClicked();
    };

    $scope.onRegularModeConfirmed = () => {
      $uibModalInstance.close({successful: true});
      CartData.setExpressDelivery(false).then(r => $scope.onSubmitClicked())
    }

    $scope.onRegularModeClicked = function() {
      if (CartData.isExpressDelivery)
        CartData.setExpressDelivery(false).then(r => $scope.onSubmitClicked());
      else
        $scope.onSubmitClicked();
    }

    $scope.onCorrectClicked = function() {
      $scope.previousStage = $scope.stage;
      $scope.stage = 'initial';
    }

    $scope.onRemainClicked = function () {
      if ($localStorage.currentUserHubId) {
        document.removeEventListener('keypress', $scope.onKeyReleased, true)
        $uibModalInstance.close()
      } else {
        $scope.onSubmitClicked();
      }
    };

    $scope.onDismissClicked = function() {
      window.Hubs.hubSetByUser = true;
      window.Hubs.setCurrentZipcode($scope.zipcode).then(() => {
        window.Hubs.setCurrentHub(1)
      })
      $uibModalInstance.close();
    };

    $scope.onAboutUsClick = function() {
      $scope.loadingAboutUs = true;

      ahoy.track("zipcode-popup-clicked-about", { code: $scope.zipcode, channel: window.xSessionChannel });
      Tracking.sendEvent('zipcode-popup', 'clicked-about', $scope.zipcode);
      $timeout(() => {
        window.location.href = $scope.aboutUsLink[$translate.use()];
      }, $scope.loadingAboutUs ? 4000 : 250); // a hack to sort-of allow dupelicarte clicks
    };

    $scope.checkIfZipcodeValid = function() {
      if ($scope.preventChecks) {
        $scope.preventChecks = false;
        return;
      }

      if ($scope.inputPristine && !$scope.zipcode) return;

      $scope.hidePromotions = false;
      $scope.incompatibleHubForStorefront = false;
      $scope.isChecking = true;

      return Hubs.fetchHubChangeStatus($scope.zipcode).then((response) => {
        $scope.isZipcodeValid = response.hubFromZipcode.success;

        if ($scope.isZipcodeValid && window.currentStorefront) {
          if (!isHubValidForStorefront(response.hubFromZipcode.id)) {
            $scope.isZipcodeValid = false;
            $scope.incompatibleHubForStorefront = true;
          }
        }

        $scope.isChecking = false;

        if ($scope.isZipcodeValid) {
          Hubs.getDeliveryTerms($scope.zipcode).then(r => {
            $scope.deliveryTerms = Hubs.deliveryTerms;
            $scope.canDoExpressDelivery = $scope.deliveryTerms && $scope.deliveryTerms.zipcode.can_do_express_delivery

            // Only switch modes if the user has entered a zipcode.
            if ($scope.canDoExpressDelivery)
              $scope.stage = 'express';
            else
              $scope.stage = 'regular';

            if (window.currentStorefront) {
              document.addEventListener('keypress', $scope.onKeyReleased, true);
            }
          });
          $scope.hubChanged = (!window.currentZipcode || response.hubFromZipcode.name == $scope.hubName) ? false : true;
          $scope.lastZipcodeEntered = $scope.zipcode;

          if (!$scope.preferredLocaleManuallySet && response.defaultLocale) {
            $scope.setLocale(response.defaultLocale);
          }

          $scope.getPromoType().then(function() {
            Tracking.sendEvent("start", "zipcodePromoShown", $scope.promoType);
          });
        } else {
          Tracking.sendEvent('zipcode-popup', 'validation-failed', $scope.zipcode);
          ahoy.track("zipcode-popup-validation-failed", { code: $scope.zipcode, channel: window.xSessionChannel, storefront: window.currentStorefront });
          $scope.promoType = null;
          setWarningHeight();
          $scope.incompatibleHubForStorefront = true;
        }
      }, (e) => {
        Tracking.sendEvent('zipcode-popup', 'validation-failed', $scope.zipcode);
        ahoy.track("zipcode-popup-validation-failed", { code: $scope.zipcode, channel: window.xSessionChannel, storefront: window.currentStorefront });
        $scope.isChecking = false;
      });
    };

    $scope.getPromoType = function() {
      // Get request with zipcode and get promo_type string.
      return $http.get(sprintf('/hubs/promo_type_from_zipcode.json?zipcode=%s', $scope.zipcode)).then(function(response) {
        $scope.promoType = (response.data.promo_type && response.data.promo_type.length > 0) ? response.data.promo_type : null;
        $scope.noMinOrderValue = response.data.no_min_order_value

        if ($scope.promoType) {
          setPromoHeight($scope.promoType);
          $scope.lastPromoType = $scope.promoType;
        };
      })
    };

    $scope.setPreferredLocale = function(locale) {
      $scope.setLocale(locale);
      $scope.preferredLocaleManuallySet = true;
    };

    $scope.setLocale = function(locale) {
      $scope.preferredLocale = locale;

      if ($translate.use() != locale)
        $translate.use(locale);

      $timeout(function() {
        setPromoHeight($scope.promoType)
      }, 300);
    };

    $scope.dismiss = function() {
      $uibModalInstance.close();
    };

    $scope.close = function() {
      $uibModalInstance.close(null);
    };

    // Private functions

    var isHubValidForStorefront = function(hubId) {
      if (!window.currentStorefront || ['bio_suisse'].indexOf(window.currentStorefront) > -1) return true;
      return window.currentStorefrontSupplier.hub_id == hubId
    };

    var timedLocationReload = function(delay) {
      delay = delay || 2000;
      $timeout(function() {
        location.reload()
      }, delay)
    };

    var timedLocationAssign = function(url, delay) {
      delay = delay || 2000;
      $timeout(function() {
        location.assign(url)
      }, delay)
    };

    var setPromoHeight = function (promo_type) {
      var element = $(`.promotion-block.${promo_type}`);

      $timeout(() => {
        element.css('height', element.find('.inner-wrapper').outerHeight() + 10)
      }, 10)
    };

    var setWarningHeight = function() {
      var element = $('.please-enter-notice');
      element.parent('small').css('height', element.innerHeight() + 20)
    };

    // Initialize

    $rootScope.$on('$translateChangeSuccess', () => {
      Hubs.getDeliveryTerms($scope.zipcode).then(r => {
        $scope.deliveryTerms = Hubs.deliveryTerms
      })
    })

    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
