import * as sprintf from 'sprintf';

angular.module('app').controller('NavDeliveryZoneCtrl', ['$scope', '$rootScope', '$uibModal', '$http', '$location', '$timeout', 'Hubs', 'CartData',
  function ($scope, $rootScope, $uibModal, $http, $location, $timeout, Hubs, CartData) {
    var lastPopoverInstance = null;

    $scope.Hubs = Hubs;
    $scope.CartData = CartData;
    $scope.dates = null;

    window.NavDeliveryZoneCtrl = $scope;

    $scope.openZipcodeModal = $rootScope.openZipcodeModal;

    $scope.openDeliveryCalendar = $rootScope.openDeliveryCalendar = function() {
      if (!$rootScope.deliveryCalendarIsOpen) {
        $rootScope.deliveryCalendarIsOpen = true;
        var datesModalInstance = $uibModal.open(
          {
            animation: true,
            size: 'sm',
            templateUrl: sprintf('/ng/templates/nav/delivery_calendar_modal.html?locale=%s', I18n.locale),
            windowClass: 'modal delivery-calendar',
            controller: 'NavDeliveryCalendarModalCtrl'
          }
        );

        datesModalInstance.result.then((result) => {
          $rootScope.deliveryCalendarIsOpen = false;
        }, (reason)=> {
          $rootScope.deliveryCalendarIsOpen = false;
        })
      }
    };

    $scope.onNextDeliveryDateClick = function() {
      if (CartData.isExpressDelivery)
        $location.path($translate.instant('mobile_header.next_delivery_block.link_url.xpress'));
      else
        $scope.openDeliveryCalendar();
    }

    window.openZipcodeModal = $scope.openZipcodeModal;
    window.openDeliveryCalendar = $scope.openDeliveryCalendar;
    window.onNextDeliveryDateClick = $scope.onNextDeliveryDateClick;

    $scope.$on('$destroy', function() {
      if (lastPopoverInstance) {
        lastPopoverInstance.off('hidden.bs.popover');
        lastPopoverInstance.off('shown.bs.popover');
      }
    })
  }
]);
