'use strict';

angular.module('app').controller('SearchFormProductItemCtrl', ['$scope', '$window', '$element', function ($scope, $window, $element) {
  $scope.analyticsListName = getGoogleAnalyticsListName();

  $scope.showProduct = function(event) {
    event.stopPropagation();
    event.preventDefault();

    openProductPopup();
  };

  // private

  function trackClick(callback) {
    try {
      var options = {
        analyticsListName: $scope.analyticsListName,
        googleAnalyticsProduct: googleAnalyticsProduct()
      };

      Tracking.productClick($scope.product.id, options, function() {
        callback();
      });
    } catch(e) {
      console.warn("trackClick failed")
      callback();
    }
  }

  function googleAnalyticsProduct() {
    return $element.data('google-analytics-product');
  }

  function getGoogleAnalyticsListName() {
    var listElement = getGoogleAnalyticsListElement($element);
    return listElement.data("analytics-list-name");
  }

  function getGoogleAnalyticsListElement(el) {
    return el.parents('[data-analytics-list-name]');
  }

  function openProductPopup() {
    trackClick(function() {
      $window.ProductNavigation.openProductPopup($scope.product.id);
    })
  }

  // Destruction
  $scope.$on('$destroy', function() {
  });
}]);
