import * as _ from 'underscore';

'use strict';

angular.module('app').service('UserNotifications', ['$rootScope', '$http', '$window', '$q', 'FarmyNotifications', 'PusherService', '$sce', '$interpolate', '$interval', '$timeout', '$location',
                                            function($rootScope,   $http,   $window,   $q,   FarmyNotifications,   PusherService,   $sce,   $interpolate,   $interval,   $timeout,   $location) {
  console.log('UserNotifications loaded with scope');
  var $scope = this || {};

  ///// User Notifications /////

  window.UserNotifications = $scope;

  $scope.notificationsEndpoint = '/api/frontend/notifications';

  $scope.sessionUpdateChannel = null;
  $scope.undeliveredNotifications = 0;
  $scope.recentNotifications = 0;

  $scope.pusherEvents = {};
  $scope.pusherEventNames = ['session-update', 'user-notification', 'engagement']; // 'user-notification' is not needed, since we send notifications through the 'notifications update' channel

  $scope.loadNotifications = function() {
    if (window.isReactivationPage) return [];

    var params = {
      locale: I18n.locale,
      include_message: 't',
      last: 16
    };

    var deliveredOnce = false;

    return $q((resolve, reject) => {
      $http.get($scope.notificationsEndpoint + '.json', { params: params }).then(function(response) {
        $scope.notifications = response.data;

        $scope.undeliveredNotifications = 0;
        $scope.recentNotifications = 0;

        if ($scope.notifications.length > 0) {
          _($scope.notifications).each(notification => {
            var notificationScope = {
              notification: notification
            };

            if(notification.message) {
              notification.message_compiled = $interpolate(notification.message)(notificationScope);
              notification.message = $sce.trustAsHtml(notification.message);
            }

            if(notification.title) {
              notification.title_compiled = $interpolate(notification.title)(notificationScope);
              notification.title = $sce.trustAsHtml(notification.title);
            }

            if (notification.created_seconds_ago) {
              if (notification.created_seconds_ago < 120 && notification.seen == null) {
                $scope.recentNotifications += 1;
              }
            }

            generateListStyles(notification);

            try {
              if (notification.delivered == false) {
                $scope.undeliveredNotifications += 1;

                // Show the latest notification, but don't automatically show the rest of them, if more than one
                if (!deliveredOnce) {
                  var message = notification.contents ? notification.contents.message : null
                  if (message == null && notification.title) message = notification.title;
                  if (message == null && notification.contents && notification.contents.title) message = notification.contents.title;
                  if (message == null && notification.contents && notification.contents.title) message = notification.contents.title;
                  const delay = notification.contents ? notification.contents?.delay : null;

                  let farmyNotification = {
                    style: notification.template_style,
                    contents: notification.contents,
                    message: message,
                    showCloseBtn: true,
                    timeout: 10000,
                    id: notification.id,
                    delay
                  };

                  FarmyNotifications.enqueue(farmyNotification);
                  deliveredOnce = true;
                }
              }
            } catch(e) {
              console.error(e);
            }
          })
        }

        $rootScope.$broadcast('user-notifications:loaded');
        
        window.angularNotifications = response.data;

        resolve($scope.notifications);
      })
    });
  };

  $scope.setDelivered = function(notification) {
    notification.delivered_at = new Date();

    if ($scope.undeliveredNotifications > 0) {
      $scope.undeliveredNotifications -= 1;
    }

    $http.post($scope.notificationsEndpoint + '/' + notification.id + '/set_delivered.json').then(() => { },
      function(error) {
        console.log(error);
      })
  };


  /////// Real-time Messaging //////

  $scope.bindPusherVisitorChannels = function() {
    let visitorId = ahoy.getVisitorId();

    if (visitorId) {
      let channelName = `user-channel-${visitorId}`;

      bindToEvents(channelName);
    } else {
      throw new Error('No visit data available')
      // Find a way to wait for visitor info
    }
  };

  $scope.bindPusherUserChannels = function() {
    if (window.UserService.currentUser && window.UserService.currentUser.id) {
      // console.info(`Will bind pusher channels for user ${window.UserService.currentUser.id} [events=${events.join(', ')}]`);

      let channelName = `user-channel-${getPrivateChannelName()}`;

      bindToEvents(channelName);

      $scope.notificationsUpdateChannel = PusherService.bind(channelName, 'notifications-update', function (data) {
        if (window.Rails.env != 'production') console.log(`Pusher[${channelName}]:`, data)
        $scope.onNotificationsUpdateMessage(data);
      });
    }
  };

  $scope.unbindPusherUserChannels = function(event, options) {
    let previousUser = options.previousUser;

    if (previousUser && previousUser.id) {
      // console.info(`Will unbind pusher channels for user ${previousUser.id}`);
      let channelName = `user-channel-${getPrivateChannelName(previousUser)}`;

      unbindToEvents(channelName);
      $scope.sessionUpdateChannel = null;

      PusherService.unbind(`user-channel-${getPrivateChannelName(previousUser)}`, 'notifications-update');
    }
  };

  $scope.unbindPusherVisitorChannels = function(event, options) {
    let visitorId = ahoy.getVisitorId();
    let channelName = `user-channel-${visitorId}`;

    unbindToEvents(channelName);
  };

  $scope.onUserChannelMessage = function(message) {
    // Do some message handling, depending on the data
    if (message.eventName == 'logout' && !window.UserService.loggingOut) {
      $rootScope.$broadcast('user:logout:outside', { });
    } else if (message.eventName.indexOf('engagement:') > -1) {
      $rootScope.$broadcast(message.eventName, message.payload)
    } else if (message.eventName == 'custom_notification') {
      addNotificationFromMessage(message)
    }
  };

  function addNotificationFromMessage(payload) {
    var message = payload.contents ? payload.contents.message : null
    if (message == null && payload.title) message = payload.title;
    if (message == null && payload.contents && payload.contents.title) message = payload.contents.title;

    let farmyNotification = {
      style: payload.template_style,
      contents: payload.contents,
      message: message,
      showCloseBtn: payload.showCloseBtn == true ? true : false,
      timeout: typeof(payload.timeout) == 'number' ? payload.timeout : 10000,
      delay: typeof(payload.delay) == 'number' ? payload.delay : null
    };

    FarmyNotifications.enqueue(farmyNotification)
  }

  $scope.onNotificationsUpdateMessage = function(message) {
    $scope.loadNotifications();
  };

  function onUserLogin() {
    $scope.unbindPusherVisitorChannels();
    $scope.bindPusherUserChannels();
  }

  function onUserLogout(event, options) {
    $scope.unbindPusherUserChannels(event, options);
    $scope.bindPusherVisitorChannels();
  }

  function bindToEvents(channelName) {
    _($scope.pusherEventNames).each((eventName) => {
      $scope.pusherEvents[eventName] = PusherService.bind(channelName, eventName, function(data) {
        if (window.Rails.env != 'production') console.log(`Pusher[${channelName}]:`, data);

        $scope.onUserChannelMessage(data);
      });
    });
  }

  function unbindToEvents(channelName) {
    $scope.pusherEvents = {};

    _($scope.pusherEventNames).each((eventName) => {
      PusherService.unbind(channelName, eventName);
    });
  }

  function touchRecentNotifications(noApplyScope) {
    let oldVal = $scope.recentNotifications;

    if ($scope.notifications && $scope.notifications.length > 0) {

      $scope.recentNotifications = 0;

      _($scope.notifications).each(notification => {
        if (notification.created_seconds_ago != null && notification.created_seconds_ago < 120 && notification.seen == null) {
          $scope.recentNotifications += 1;
          notification.created_seconds_ago += 4;
        } else if (notification.created_seconds_ago != null) {
          notification.created_seconds_ago += 4;
        }
      });
    }

    if (oldVal != $scope.recentNotifications && noApplyScope != true && window.NgFrontendAppCtrl) {
      window.NgFrontendAppCtrl.$apply()
    }
  }

  function getPrivateChannelName(userData) {
    if (userData == null) {
      if (window.UserService.currentUser == null || window.UserService.currentUser.id == null)
        throw new Error("No user data available");

      return window.UserService.currentUser.id + '-' + btoa(window.UserService.currentUser.email);
    } else {

      return userData.id + '-' + btoa(userData.email);
    }
  }

  /**
   * Calculates CSS classes for the event element in the
   * notifications list popup
   * @param notification
   */
  function generateListStyles(notification) {
    if (notification.event_type == "order_completed") {
      notification.event_list_class = "event-success";
      notification.icon_list_class = "fa fa-ok";
      notification.template_style = "order_completed";
    } else if (notification.event_type == "order_shipped") {
      notification.event_list_class = "event-success";
      notification.icon_list_class = "fa fa-truck";
      notification.template_style = "success";
    } else if (notification.event_type == "added_line_item_promotion" || notification.event_type == "added_line_item_promotion") {
      notification.event_list_class = "event-gift";
      notification.icon_list_class = "fa fa-tags";
      notification.template_style = notification.event_type;
    } else if (notification.event_type == "eggs_earned") {
      notification.event_list_class = "event-bonus";
      notification.icon_list_class = "fa fa-star";
      notification.template_style = notification.event_type;
    } else if (notification.event_type == "en_route") {
      notification.event_list_class = "event-tracking";
      notification.icon_list_class = "fa fa-location";
      notification.template_style = notification.event_type;
    } else if (notification.event_type == "success") {
      notification.event_list_class = "event-success";
      notification.icon_list_class = "fa fa-ok";
      notification.template_style = notification.event_type;
    } else if (notification.event_type == "increase") {
      notification.event_list_class = "event-success";
      notification.icon_list_class = "fa fa-plus";
      notification.template_style = "success";
    } else if (notification.event_type == "decrease") {
      notification.event_list_class = "event-danger";
      notification.icon_list_class = "fa fa-minus";
      notification.template_style = "info";
    } else if (notification.event_type == "error") {
      notification.event_list_class = "event-danger";
      notification.icon_list_class = "fa fa-times";
      notification.template_style = "error";
    } else if (notification.event_type == "danger") {
      notification.event_list_class = "event-danger";
      notification.icon_list_class = "fa fa-attention";
      notification.template_style = "error";
    } else if (notification.event_type == "free_products_pending") {
      notification.event_list_class = "event-gift";
      notification.icon_list_class = "fa fa-heart";
      notification.template_style = "info";
    } else if (notification.event_type == 'courier') {
      notification.template_style = 'info'
    } else {
      notification.event_list_class = "event-info";
      notification.icon_list_class = "fa fa-info-circled";
      notification.template_style = notification.event_type;
    }
  }

  function setNativeAppListener() {
    window.addEventListener('beforeinstallprompt', onNativeAppInstallPrompt);
  }

  function onNativeAppInstallPrompt(event) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    event.preventDefault();
    // Stash the event so it can be triggered later.
    // NOTE: we're using a custom notification for this, but still keep reference to the event just in case.
    $scope.nativeAppDeferredPrompt = event;

    // Send notification
    let params = { timeout: 0, style: 'install_android_app', delay: 1000 }
    FarmyNotifications.enqueue(params);

    // Remove listener
    window.removeEventListener('beforeinstallprompt', onNativeAppInstallPrompt)
  }

  //*
  function displayNotificationsFromUrl() {

  }

  // Initialize
  // $rootScope.$on('cart:changed', $scope.loadNotifications);

  // Also watch the global currentOrderId: this way 'thankyou' page will display 'egg notifications'
  // $rootScope.$watch(()=> { return $window.currentOrderId }, ()=> { $scope.loadNotifications() });

  $rootScope.$on('user:loaded', onUserLogin);
  $rootScope.$on('user:authenticated', onUserLogin);
  $rootScope.$on('user:logout', onUserLogout);

  let iTouchRecentNotifications = setInterval(touchRecentNotifications, 4000);

  if (!window.browserIsBot) {
    $interval(() => {
      if (window.CartData && window.CartData.cartBlocked) return; // do not update when cart is blocked
      $scope.loadNotifications()
    }, 60000); // update once in a minute
  }

  $rootScope.$on('$destroy', () => {
    if (iTouchRecentNotifications) clearInterval(iTouchRecentNotifications);
  });

  if (!window.browserIsBot) {
    setNativeAppListener();

    $timeout(() => {
      if (!window.UserService.isLoggedIn) $scope.bindPusherVisitorChannels();
      displayNotificationsFromUrl()
      $scope.loadNotifications();
    }, 1);
  }
}]);
