'use strict';

angular.module('app').controller('OrderSuggestionsLoginCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$timeout', '$compile', '$sce', '$http', '$element', '$localStorage', '$uibModal', 'Alerts', 'blockUI',
  function ($scope, $rootScope, $location, $window, $interval, $timeout, $compile, $sce, $http, $element, $localStorage, $uibModal, Alerts, blockUI) {

    $scope.orderSuggestion = null;
    $scope.order = null; // This is a reference to $scope.orderSuggestion.order (simply a shortcut)
    $scope.deliverySlots = null; // This is a reference to $scope.orderSuggestion.delivery_slots (simply a shortcut)
    $scope.isCreating = false;
    $scope.isLoading = false;

    window.OrderSuggestionsLoginCtrl = $scope;

    $scope.scrollTo = function(sectionSelector) {
      $('html, body').animate({ scrollTop: $(sectionSelector).offset().top - 100 }, 500)

      window.Tracking.sendOrderSuggestionEvent('scrollTo', sectionSelector.replace(/\#|\./ig, ''));
    };

    $scope.updateLayout = function() {
      $element.find('.jumbotron').css({"min-height": $element.find('.jumbotron > .container').height() + 95 + 80 });
    };

    // Private members:

    function initializeVideoPlayerAPI() {
      safely(function() {
        $scope.videoPlayer = new Vimeo.Player($(".video-wrapper .video")[0]);

        $scope.videoPlayer.on('play', function() {
          window.Tracking.sendOrderSuggestionEvent('introVideo', 'played');
        });

        $scope.videoPlayer.on('pause', function() {
          window.Tracking.sendOrderSuggestionEvent('introVideo', 'paused');
        });

        $scope.videoPlayer.on('error', function() {
          window.Tracking.sendOrderSuggestionEvent('introVideo', 'error');
        });

        $scope.videoPlayer.on('ended', function() {
          window.Tracking.sendOrderSuggestionEvent('introVideo', 'ended');
        });
      });
    }

    function playVideo() {
      safely(function() {
        if ($scope.videoPlayer) { $scope.videoPlayer.play(); }
      });
    }

    function pauseVideo() {
      safely(function() {
        if ($scope.videoPlayer) { $scope.videoPlayer.pause(); }
      });
    }

    function autoPlayVideo() {
      safely(function() {
        if (window.isMobile) { return; }

        $timeout(function() {
          playVideo();
        }, 330)
      });
    }

    function initialize() {
      initializeVideoPlayerAPI();
      autoPlayVideo();
    }

    // Watchers:

    $scope.$on('$destroy', function() {
      window.OrderSuggestionsLoginCtrl = null;
    });

    initialize();

    $timeout(function() {
      $scope.updateLayout();
    }, 10);
  }
]);
