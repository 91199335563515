'use strict';

angular.module('app').controller('ChristmasCountdownCtrl', ['$scope', '$window','$uibModal', '$http', 'CartData', '$timeout',
  function($scope, $window, $uibModal, $http, CartData, $timeout) {
    $window.ChristmasCountdownCtrl = $scope;
    $scope.CartData = CartData;
    $scope.christmasDatesCollection = angular.fromJson($window.christmasDatesCollection);
    $scope.animationFinished = $scope.isClaimed = $scope.isModalOpen = $scope.pageLoaded = $scope.showBackButton = false;

    if ($window.currentGiftProduct != '') {
      $scope.currentGiftProduct = angular.fromJson($window.currentGiftProduct);
    }

    $scope.loadCurrentGiftProduct = function() {
      if (!$scope.currentGiftProduct) {
        $scope.frontDayIndex = 0;
      } else {
        $scope.currentDateProduct = _.find($scope.christmasDatesCollection, function(date) {
          return date.is_today;
        });
        $scope.frontDayIndex = $scope.currentDateProduct.index;

      }
      arrangeDomDates();
    };

    $scope.dateClasses = function(date) {
      var classes = date.state_class;
      if(date.is_today){
        classes += ' active';
      } else if(date.is_past) {
        classes += ' past';
      } else {
        classes += ' future';
      }
      return classes;
    };

    $scope.onSwipeLeft = function() {
      $scope.moveToRight();
    };

    $scope.onSwipeRight = function() {
      $scope.moveToLeft();
    };

    $scope.moveToRight = function() {
      if ($scope.frontDayIndex < $scope.christmasDatesCollection.length - 1) {
        $scope.frontDayIndex += 1;
        arrangeDomDates();
      }
    };

    $scope.moveToLeft = function() {
      if ($scope.frontDayIndex > 0) {
        $scope.frontDayIndex -= 1;
        arrangeDomDates();
      }
    };

    $scope.resetCalendar = function() {
      $scope.frontDayIndex = $scope.currentDateProduct.index;
      arrangeDomDates();
    };

    $scope.triggerGiftAction = function() {
      var timeOut = $scope.animationFinished ? 0 : 1500;

      if(!$scope.isModalOpen){
        $scope.isClaimed = $scope.CartData.containsProduct($scope.currentDateProduct.product_id);
        $scope.isModalOpen = true;
        $timeout(openGiftPopup, timeOut);
      }
    };

    $scope.claimGift = function() {
      if(!$scope.isClaimed) {
        CartData.setCartVariant($scope.currentDateProduct.product_id, $scope.currentDateProduct.variant);
        $scope.isClaimed = true;
      }
    };

    $scope.addHofpassToCart = function() {
      var smart_pass = { level: 'basic', duration: 1, zone_code: 'regional', free: true };

      $.post("/checkout/add_smart_pass", { smart_pass: smart_pass }, function (response) {
        $scope.isClaimed = true;
      })
    };

    var arrangeDomDates = function() {
      _.map($scope.christmasDatesCollection, function(date) {
        if(date.index == $scope.frontDayIndex) {
          date.state_class = 'front';
        } else if(date.index > 0 && date.index == $scope.frontDayIndex + 1) {
          date.state_class = 'back-right';
        } else if(date.index < $scope.christmasDatesCollection.length - 1 && date.index == $scope.frontDayIndex - 1) {
          date.state_class = 'back-left';
        } else if(date.index > $scope.frontDayIndex) {
          date.state_class = 'date-hidden right';
        } else {
          date.state_class = 'date-hidden left';
        }

        if(date.is_today) {date.state_class += ' today'}
      })
    };

    var openGiftPopup = function() {
      var giftModalTemplate = '/ng/templates/campaigns/christmas_gift_modal.html?locale=' + I18n.locale;
      var giftModalInstance = $uibModal.open({
        animation: true,
        size: 'lg',
        templateUrl: giftModalTemplate,
        backdrop: true,
        windowClass: 'christmas-gift-modal',
        controller: 'ChristmasGiftModalCtrl',
        scope: $scope
      });

      giftModalInstance.result.finally(function(result){
        $scope.isModalOpen = false;
        $scope.showBackButton = true;
        $scope.animationFinished = true;
      })
    };

    $scope.loadCurrentGiftProduct();

    angular.element(document).ready(function () {
      $timeout(function() {$scope.pageLoaded = true;}, 1500);
    });
}]);