/**
 * Search helper works as service, binding various search-related UI components of the website,
 * and facilitates inter-controller communication about search events
 */
angular.module('app').service('SearchHelper', ['$rootScope', '$q', function ($rootScope, $q) {
  var scope = this;

  /**
   * Must be managed by SearchFormCtrl
   *
   * @type {boolean}
   */
  scope.isOpen = false;
  scope.inputFocused = false;
}]);