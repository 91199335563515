angular.module('app').directive('farmyExpressDeliverySwitchButtons', () => {
  return {
    controller: ['$scope', '$rootScope', 'CartData', 'Hubs', function ($scope, $rootScope, CartData, Hubs) {
      $scope.options = {
        expressDeliveryChecked: CartData.isExpressDelivery
      };

      $scope.checkVisibility = function() {
        $scope.isCheckoutPage = location.pathname === '/cashier';
        $scope.shouldHide = !Hubs.canDoExpressDelivery || $scope.isCheckoutPage;
      }

      $scope.checkVisibility('initial');

      $rootScope.$on('hubs:changed', (e) => $scope.checkVisibility());
      $rootScope.$on('hubs:delivery_terms:loaded', (e) => $scope.checkVisibility());

      $rootScope.$on('express:change_started', (e) => $scope.switchingExpressMode = true);
      $rootScope.$on('express:change_finished', (e) => $scope.switchingExpressMode = false);
      $rootScope.$on('express:changed', (e) => {
        $scope.options.expressDeliveryChecked = CartData.isExpressDelivery;
        $scope.checkVisibility();
      })

      $scope.xpressCounter = 0;
      let initialExpressDeliveryWatcher = $scope.$watch(() => {
        return CartData.isExpressDelivery
      }, (isExpress) => {
        if ([true, false].includes(isExpress)) {
          $scope.options = {
            expressDeliveryChecked: isExpress
          }

          $scope.xpressCounter += 1;

          if ($scope.xpressCounter > 1)
            initialExpressDeliveryWatcher();
        }
      })

      $scope.onExpressDeliveryCheckboxChange = function () {
        $scope.switchingExpressMode = true;
        $scope.options.expressDeliveryChecked = !$scope.options.expressDeliveryChecked;

        $rootScope.$broadcast('express:change_started')

        CartData.setExpressOrLaunchWarningPopup($scope.options.expressDeliveryChecked).then(confirmed => {
          $rootScope.$broadcast('express:changed', {express: CartData.isExpressDelivery});
        }).finally(() => {
          $scope.switchingExpressMode = false;
          $rootScope.$broadcast('express:change_finished')
        });
      };
    }],
    templateUrl: `/ng/templates/express_delivery/switch_buttons.html`
  }
});
