angular.module('app').controller('DeletedAccountModalCtrl', ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
  console.log('DeletedAccountModalCtrl loaded');
  window.DeletedAccountModalCtrl = $scope;

  function constructor() {

  }

  function destructor() {
    window.DeletedAccountModalCtrl = null;
  }

  $scope.closeModal = function() {
    $uibModalInstance.dismiss()
  };

  $scope.$on('$destroy', destructor);
  constructor();
}]);