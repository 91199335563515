import {useEffect, useState} from 'react';
import {useSelector, useSessionActions} from '../shared-services/redux-tiny';
import axios from 'axios';

const {$on} = window.AngularIntegration;

const useTopTaxonFilters = () => {
  const catalogMasterFilters = window.farmyCatalogMasterFilters;
  const topTaxonFilters = useSelector(state => state.session.topTaxonFilters);
  const globalMasterFilters = useSelector(state => state.session.masterFilters);
  const [localMasterFilters, setLocalMasterFilters] = useState(null);
  const {setTopTaxonFilters, setMasterFilters: setGlobalMasterFilters} = useSessionActions();

  const initialize = () => {
    if (!topTaxonFilters) {
      initializeMasterFilters();
    }
  };

  const filterObjectToCollection = (filterObject = {}) => {
    return Object.keys(filterObject)
      .filter(key => filterObject[key])
      .map(key => `master_${key.replace('master_', '')}`);
  };

  const initializeMasterFilters = () => {
    const {masterFilters} = catalogMasterFilters;
    const newFilters = globalMasterFilters?.length
      ? globalMasterFilters
      : filterObjectToCollection(masterFilters);

    setGlobalMasterFilters(newFilters);
  };

  const loadTopTaxonFilters = () => {
    const filterSettings = localMasterFilters;

    const params = {
      locale: window.I18n.locale
    };

    if (filterSettings?.length) {
      params.master_filters = filterSettings.join(',');
    }

    axios.get('/api/products/top_category_filter_tree.json', {params}).then(response => {
      setTopTaxonFilters(response.data);
    });
  };

  useEffect(() => {
    if (catalogMasterFilters) initialize();
    $on('catalog:filters:master:changed', (event, response) => {
      let {masterFilters} = response;

      masterFilters = filterObjectToCollection(masterFilters);

      setGlobalMasterFilters(masterFilters);
    });
  }, [catalogMasterFilters]);

  useEffect(() => {
    if (!localMasterFilters || (globalMasterFilters || []).join('/') !== (localMasterFilters || []).join('/')) {
      setLocalMasterFilters(globalMasterFilters);
    }
  }, [globalMasterFilters]);

  useEffect(() => {
    if (localMasterFilters) loadTopTaxonFilters();
  }, [localMasterFilters]);

  return topTaxonFilters;
};

export default useTopTaxonFilters;
