import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

/**
 * Listens to shipping zipcode changes and runs cart contents validations related
 * to the shipping destination (Hubs compatibility, etc.)
 *
 * A visual element of the shipping stage is linked to scope variables
 * modified by this method.
 */
angular.module('app')
  .directive('filterSidebarMobile', function() {
    return {
      controller: ['$rootScope', '$scope', '$http', '$q', '$window', '$timeout', '$interval', '$location', 'Alerts', 'blockUI', '$attrs', '$element', '$translate', 'UiStateService', function ($rootScope, $scope, $http, $q, $window, $timeout, $interval, $location, Alerts, blockUI, $attrs, $element, $translate, UiStateService) {
        window.filterSidebarMobile = $scope;

        var layoutUpdateInterval;

        function constructor() {
          updateLayout();

          $scope.UiStateService = UiStateService;

          angular.element($window).bind('resize', () => { updateLayout() });
          angular.element($window).bind('scroll', () => {
            updateLayout();
            $timeout(() => { updateLayout() });
          });

          $rootScope.$on('filters:postchange', () => {
            UiStateService.showFilterSidebar(false);
          });
        }

        function destructor() {
          window.filterSidebarMobile = null;
          if (layoutUpdateInterval) clearInterval(layoutUpdateInterval);
        }

        function updateLayout() {

        }

        //
        // Public members:
        //

        $scope.onSwipeRight = function() {
          UiStateService.showFilterSidebar(false);
        };

        // Initialize

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      scope: {

      },
      link: function($scope, $element, attributes) {

      },
      template: function() {
        return `<farmy-ng-include src="'/ng/templates/products/filter_sidebar_mobile.html?locale=${$translate.use()}'"></farmy-ng-include>`
      }
    }
  });
