'use strict';

angular.module('app').controller('SmartPassCancelModal', [ '$scope', '$uibModalInstance',
  function ($scope, $uibModalInstance) {
    $scope.confirm = function() {
      $uibModalInstance.dismiss('confirm');
    };

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }]);
