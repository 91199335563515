import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app')
  .directive('farmyCategoryDebugTool', function() {
    return {
      controller: ['$scope', '$http', '$q', '$rootScope', '$timeout', 'Alerts', 'blockUI', 'CatalogServiceHelper', function ($scope, $http, $q, $rootScope, $timeout, Alerts, blockUI, CatalogServiceHelper) {
        window.CategoryDebugTool = $scope;

        function constructor() {
          $scope.params = {};
        }

        function destructor() {
          window.CategoryDebugTool = null;
        }

        // Public members

        $scope.onMouseEnter = function() {
          $scope.hover = true;
        };

        $scope.onMouseLeave = function() {
          $scope.hover = false;
        };

        $scope.includeInactive = function() {
          if (CatalogServiceHelper.currentTaxon == null) {
            Alerts.error("Please wait for the category to load")
            return;
          }

          if ($scope.params.include_unaddable == null)
            $scope.params.include_unaddable = 't';
          else
            $scope.params.include_unaddable = null;

          window.CatalogViewCtrl.pagination.page = window.CatalogViewCtrl.pagination.current_page = 1;
          window.CatalogViewCtrl.update();
        };

        $scope.clearCategoryCache = function() {
          return $q((resolve, reject) => {
            if (CatalogServiceHelper.currentTaxon == null) {
              Alerts.error("Please wait for the category to load")
              return reject();
            }

            blockUI.start();

            Promise.all([
              $http.post('/api/backend/products/clear_cache.json', { taxon_id: CatalogServiceHelper.currentTaxon.id }),
            ]).then(values => {
              const [product_response, prerender_response] = values;

              if (product_response.data.deleted_count && product_response.data.deleted_count > 0) {
                Alerts.success(`Removed ${product_response.data.deleted_count} cache entries`)
              } else {
                Alerts.warn(`No cache entries found for ${CatalogServiceHelper.currentTaxon.name}`);
              }

              if (prerender_response.data.success) Alerts.success('Prerender key removed');
              else Alerts.warn('Error removing key');

              blockUI.stop();
            })
          })
        };

        // Initialize

        $scope.$on('$destroy', destructor)
        constructor()
      }],
      transclude: true,
      scope: {

      },
      link: function($scope, $element, attributes) {

      },
      template: `
<style>
  .category-debug-tool-wrapper {
    transition: all .3s ease-out;
    position: fixed; 
    left: 40%; 
    bottom: -25px; 
    height: 35px; 
    margin-bottom: 0; 
    border-bottom-left-radius: 0; 
    border-bottom-right-radius: 0; 
    z-index: 4000; 
    font-size: 11px;
    padding-top: 8px;      
  }
  
  .category-debug-tool-wrapper.hovered {
    bottom: 0
  }
</style>
<div class="well category-debug-tool-wrapper" ng-mouseenter='onMouseEnter()' ng-mouseleave='onMouseLeave()' ng-class="{hovered: hover}">
    <button class="btn btn-xs" ng-class="{'btn-primary': params.include_unaddable, 'btn-default': !params.include_unaddable}" ng-click="includeInactive()" href="#">Include inactive products</button>&nbsp;
    <button class="btn btn-default btn-xs" ng-click="clearCategoryCache()" href="#">Clear cache</button>
</div>`
    }
  });


