import * as _ from 'underscore';

/**
 * Helper service that assists CartData with weekly modification
 */
angular.module('app').service('WeeklyCartService', ['$rootScope', '$q', '$http', '$interval', '$timeout', '$translate', 'Alerts', 'Hubs', '$uibModal', ($rootScope, $q, $http, $interval, $timeout, $translate, Alerts, Hubs, $uibModal) => {
  var $scope = this || {};

  window.WeeklyCartService = $scope;

  $scope.currentWeekly = null;

  //
  // Public memebers
  //

  $scope.loadWeekly = function(id) {
    $scope.isLoading = true;

    return $http.get(`/api/frontend/weeklies/${id}.json?include_items=t&product_summary=t`).then(response => {
      if ($scope.currentWeekly == null)
        $scope.currentWeekly = {};
      angular.extend($scope.currentWeekly, response.data);
    }).finally(() => {
      $scope.isLoading = false;
    })
  };

  $scope.getTotal = function() {
    return _.reduce($scope.currentWeekly.items, (memo, i) => i.price + memo, 0);
  };

  /**
   * Updates a weekly item, identified by productId to a new variant.
   *
   * @param productId
   * @param variant
   * @returns {*}
   */
  $scope.updateItem = function(productId, variant, options) {
    var params = { };

    if (variant) {
      params.item = { variant_id: variant['id'] ? variant.id : variant };

      $scope.isLoading = true;

      blockUI.start();

      return $q((resolve, reject) => {
        $http.patch(`/api/frontend/weeklies/${$scope.currentWeekly.id}/update_item.json?product_id=${productId}&product_data=t&include_variant=t`, params).then(response => {
          let existingItem = _.find($scope.currentWeekly.items, i => i.id == response.data.id)

          if (existingItem) {
            angular.extend(existingItem, response.data);
          } else {
            $scope.currentWeekly.items.push(response.data)
          }

          resolve(response.data);
        }, e => {
          Alerts.error(errorMessage(e));
          reject(e)
        }).finally(() => {
          blockUI.stop();
          $scope.isLoading = false;
        });
      });
    } else {
      $scope.isLoading = true;

      blockUI.start();

      return $q((resolve, reject) => {
        $http.post(`/api/frontend/weeklies/${$scope.currentWeekly.id}/destroy_item.json?product_id=${productId}`, params).then(response => {
          let existingItemIndex = _.findIndex($scope.currentWeekly.items, i => i.id == response.data.id);

          if (existingItemIndex >= 0) {
            $scope.currentWeekly.items.splice(existingItemIndex, 1);
          }

          resolve(response.data);
        }, e => {
          Alerts.error(errorMessage(e));
          reject(e)
        }).finally(() => {
          blockUI.stop();
          $scope.isLoading = false;
        });
      });
    }

  };

  //
  // Private members
  //

  $rootScope.$on('user:logout', (event) => {
    $scope.currentWeekly = null;
  });

  return $scope;
}]);