'use strict';

/**
 * This is a generic controller for simple modal windows
 */
angular.module('app').controller('PlainModalCtrl', [ '$scope', '$uibModalInstance', '$http', '$localStorage', 'Hubs',
                                            function ($scope,   $uibModalInstance,   $http,   $localStorage,   Hubs) {
    $scope.dismiss = function() {
      $uibModalInstance.dismiss();
    };

    $scope.submitModal = function(result) {
      $uibModalInstance.close(result);
    };

    $scope.onSubmitClicked = function(event, result) {
      $scope.submitModal(result);
    }
  }
]);
