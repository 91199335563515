import React from 'react';
import ReactDOM from 'react-dom';
import {RjsFilterPanel} from './../react-components/RjsFilterPanel';
import {BrowserRouter} from 'react-router-dom';
import {ReduxTinyProvider} from '../shared-services/redux-tiny';

const NgrContextProvider = ({children}) => {
  return <ReduxTinyProvider>{children}</ReduxTinyProvider>;
};

const NgrRouterProvider = ({children}) => {
  return <BrowserRouter>{children}</BrowserRouter>;
};

angular.module('app')
  .directive('ngrFilterPanel', ['$rootScope', '$http', '$timeout', function ($rootScope, $http, $timeout) {
    'use strict';
    return {
      scope: {},
      link: function ($scope, $element, attrs) {
        var recommendations;

        window.ngrFilterPanel = $scope;

        function destructor() {

        }

        $scope.$on('$destroy', destructor);

        const routerFactory = React.createFactory(NgrRouterProvider);
        const componentFactory = React.createFactory(RjsFilterPanel);
        const mainFactory = React.createFactory(NgrContextProvider);

        const props = { $http: $http, $timeout: $timeout, filters: [] };

        // console.log(ngrFilterPanel, attrs);

        if (attrs.categoryCheckboxes)
          props.categoryCheckboxes = true;

        if (attrs.sidebarMode)
          props.sidebarMode = true;

        if (attrs.taxonDepth)
          props.taxonDepth = parseInt(attrs.taxonDepth);

        const component = routerFactory({
          children: mainFactory({children: componentFactory(props)})
        });

        ReactDOM.render(component, $element[0]);

        // component.setEntries([]);
        // component.setState({ someData: {} });

        // setInterval(function() {
        //   _.each(recommendations, function(r) {
        //     r.technical_analysis.rsi.d1[0] = Math.random() * 100;
        //     r.technical_analysis.rsi.m1[0] = Math.random() * 100;
        //   });
        //
        //   if (component)
        //     component.setRecommendations(recommendations);
        // }, 100);
      }
    };
  }]);
