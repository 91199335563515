export const useNgRef = (parent) => {
    const ref = {
        $$ngref: true,
        $$received: false,
        parent: parent,
        actions: {},
        isValid: () => ref.$$received,
        change: (closure) => {
            try {
                closure(ref.actions)
            } catch(e) {
                console.warn(e, "ngref hook error")
            }
        }
    };
    return ref;
}

export const invalidateNgRef = (ngref) => {
    ngref.$$received = false;
    ngref.actions = {};
    return ngref
}

export const acceptNgRef = (ngref, actions) => {
    ngref.$$received = true;
    Object.assign(ngref.actions, actions)
    return ngref
}

export default useNgRef
