// Borrowed from http://embed.plnkr.co/8LDJXj/

angular.module('app').filter('capitalizeText', [function () {

  return function (input, from, to) {

    if(input === undefined) {
      return;
    }

    if (input.length == 0) return input;
    input[0] = input[0].toUpperCase();
    return input;
  };


}]);