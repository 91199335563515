import * as _ from 'underscore';

/**
 * Service for reading, changing and authneticating the 'current user'
 */
angular.module('app').service('BrandingService', ['$q', '$http', '$interval', '$timeout', '$translate', 'Alerts', ($q, $http, $interval, $timeout, $translate, Alerts) => {
  var $scope = this || {};

  window.BrandingService = $scope;

  $scope.isBrandingEnabled = window.windowBrandingOption || false;

  if ($scope.isBrandingEnabled) {
    $scope.branding = window.windowBrandingOption;
  }

  return $scope;
}]);