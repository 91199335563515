'use strict';

angular.module('app').controller('MobileInfoFooterCtrl', ['$scope', 'CartData',
function($scope, CartData) {

  $scope.CartData = CartData;

  var recalculateFooterInfoHeight = function() {
    var bodyElement = $('body');
    var footerInfoElement = $('.footer-order-info');
    var footerLinksElement = $('.footer-links');

    footerInfoElement.css('height', '').css('position', 'relative').css('margin-top', '');
    footerLinksElement.css('padding-bottom', '');

    var infoHeight = footerInfoElement.height();
    var emptyPixels = $(window).height() - bodyElement.position().top - bodyElement.outerHeight(true);

    if (emptyPixels > 0) {
      infoHeight = infoHeight + emptyPixels;
      footerInfoElement.css('height', infoHeight + 'px').css('position', 'relative').css('margin-top', '2px');
      footerLinksElement.css('padding-bottom', '');
    } else {
      footerInfoElement.css('height', '').css('position', 'fixed');
      footerLinksElement.css('padding-bottom', (infoHeight + 5) + 'px');
    }
  };

  $scope.$watch(function(){
    return $('.page-content').height()
  }, function(){
    recalculateFooterInfoHeight();
  });

  $scope.$on('$destroy', function() {
    window.ShoppingListCtrl = null;
  });
}]);
