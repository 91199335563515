import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

angular.module('app')
  .directive('farmyFilterPills', function() {
    return {
      controller: ['$rootScope', '$scope', '$http', '$q', '$timeout', '$attrs', '$element', 'TaxonHelper', 'CatalogServiceHelper', function ($rootScope, $scope, $http, $q, $timeout, $attrs, $element, TaxonHelper, CatalogServiceHelper) {
        window.farmyFilterPills = $scope;

        $scope.selectedFilterValues = [];

        function constructor() {
          $scope.$watchCollection('CatalogServiceHelper.filterSelections', handleFilterSelection);
        }

        function destructor() {
          window.farmyFilterPills = null;
        }

        function handleFilterSelection(newSelections, oldSelections) {
          $scope.selectedFilterValues.length = 0;

          for(var key in newSelections) {
            // Skip the taxon ids filter in category browsing mode
            if (key == "taxon_ids" && window.CatalogViewCtrl)
              continue;

            _.each(newSelections[key], filterValue => {
              let filter = _.find(CatalogFilterCtrl.filters, f => f.search_param == key);

              if (filter) {
                let filterOption = _.find(filter.values, v => v.value == filterValue);

                if (filterOption && _.find($scope.selectedFilterValues, v => v.name == filterOption.name) == null) {
                  $scope.selectedFilterValues.push({
                    filter: key,
                    value: filterValue,
                    name: filterOption.name
                  })
                }

              }
            });
          }
        };

        // Public members

        $scope.CatalogServiceHelper = CatalogServiceHelper;

        $scope.cancelFilterSelection = function(filterValue) {
          if (CatalogFilterCtrl.filterSelections) {
            if (CatalogFilterCtrl.filterSelections[filterValue.filter] && CatalogFilterCtrl.filterSelections[filterValue.filter][filterValue.value]) {
              delete CatalogFilterCtrl.filterSelections[filterValue.filter][filterValue.value];
              // CatalogFilterCtrl.filterSelections[filterValue.filter][filterValue.value] = null;
              handleFilterSelection(CatalogFilterCtrl.filterSelections);
              $rootScope.$broadcast('catalog:filters:changed', { selections: CatalogFilterCtrl.filterSelections, sender: $scope });
            }
          }
        };

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      scope: {

      },
      link: function($scope, $element, attributes) {

      },
      template: `<div class="cancel-filters">
        <a class="cancel-filter filter_id_{{filterValue.value}}" ng-repeat="filterValue in selectedFilterValues" ng-click="cancelFilterSelection(filterValue)" ng-show="filterValue">
            {{ filterValue.name }} <i class="fa fa-times2"></i>
        </a>
</div>`
    }
  });
