import * as _ from 'underscore';

var GoogleDataLayerAdapter = window.GoogleDataLayerAdapter = {
  lastData: null,

  /**
   * Expects products to be an
   * @param products
   */
  cartPage: function(products) {
    products = this.transformProducts(products);

    if (window.dataLayer == null) window.dataLayer = [];

    try {
      window.dataLayer.push({
        'page': {
          'step': 1,
          'type': 'basket',	// This value must not change. Required
          'environment': Rails.env,	// This value must not change. Required
        },
        'products': products,
        'total_value': _.reduce(products, function(memo, p) { return memo + parseFloat(p.price) }, 0),
        'ecommerce': {
          'detail': {
            'product_ids': _.map(products, function(p) { return p.id }),
            'products': products,
          }
        }
      });

      this.lastData = window.dataLayer[window.dataLayer.length - 1];
    } catch(e) { console.warn(e) }
  },

  checkoutAddressPage: function(products) {
    products = this.transformProducts(products);

    if (window.dataLayer == null) window.dataLayer = [];

    window.dataLayer.push({
      'page': {
        'type': 'checkout',	// This value must not change. Required
        'environment': Rails.env,	// This value must not change. Required
        'step': 2
      },
      'ecommerce': {
        'checkout': {
          'actionField': {
            'step': 1
          },
          'products': products,
          'detail': {
            'product_ids': _.map(products, function(p) { return p.id }),
          }
        }
      }
    });

    this.lastData = window.dataLayer[window.dataLayer.length - 1];
  },

  productPage: function(product, listName) {
    product = this.transformProducts([product])[0];

    if (window.dataLayer == null) window.dataLayer = [];

    try {
      window.dataLayer.push({
        'ecommerce': {
          'currencyCode': window.defaultCurrency,
          'detail': {
            'actionField': {
              'list': listName || 'other'
            },
            'product_ids': _.map([product], function(p) { return p.id }),
            'products': [product]
          }
        },
        'total_value': parseFloat(product.price),
        'page': {
          'type': 'product',
          'environment': Rails.env
        }
      });
    } catch(e) { console.warn(e) }

    this.lastData = window.dataLayer[window.dataLayer.length - 1];
  },

  productList: function(products, listName) {
    products = this.transformProducts(products)

    if (window.dataLayer == null) window.dataLayer = [];

    window.dataLayer.push({
      'ecommerce': {
        'currencyCode': window.defaultCurrency,
        'detail': {
          'actionField': {
            'list': listName || 'other'
          },
          'impressions': products,
          // 'product_ids': _.map(products, function(p) { return p.id }),
          'products': products
        }
      },
      'page': {
        'type': 'category',
        'environment': Rails.env
      }
    });

    this.lastData = window.dataLayer[window.dataLayer.length - 1];
  },

  additionToCart: function(operation, productData) {
    const event = operation === "add" ? "addToCart" : operation === "remove" ? "removeFromCart" : null;
    if (window.dataLayer == null) window.dataLayer = [];
    const categoriesLength = productData.categories.length;
    if (event) {
      window.dataLayer.push({
        'event': event,
        'ecommerce': {
          'currencyCode': window.defaultCurrency,
          [operation]: {
            'products': [{
              'name': productData.name,
              'id': productData.id,
              'price': productData.price,
              'brand': productData.brand,
              'item_category': categoriesLength > 0 ? productData.categories[0].name : "",
              'item_category2': categoriesLength > 1 ? productData.categories[1].name : "",
              'item_category3': categoriesLength > 2 ? productData.categories[2].name : "",
              'variant': productData.variant,
              'quantity': productData.quantity
            }]
          }
        }
      });
    }
  },

  addPaymentInfo: function(order) {
    const line_items = this.formatLineItems(order.line_items)
    if (window.dataLayer == null) window.dataLayer = [];
    window.dataLayer.push({
      'event': 'add_payment_info',
      'ecommerce': {
        'currency': order.currency,
        'value': order.value,
        'coupon': order.coupon_code || "",
        'payment_type': order.payment_name,
        'items': line_items
      }
    });
    this.lastData = window.dataLayer[window.dataLayer.length - 1];
  },

  addShippingInfo: function(order) {
    const line_items = this.formatLineItems(order.line_items)

    if (window.dataLayer == null) window.dataLayer = [];

    window.dataLayer.push({
      'event': 'add_shipping_info',
      'ecommerce': {
        'currency': order.currency,
        'value': order.value,
        'coupon': order.coupon_code || "",
        'shipping_tier': order.shipping_tier,
        'items': line_items
      }
    });
    this.lastData = window.dataLayer[window.dataLayer.length - 1];
  },

  checkoutComplete: function(order, products) {
    products = this.transformProducts(products);

    if (window.dataLayer == null) window.dataLayer = [];

    const orderHasBeenPushed = window.dataLayer.find(e =>
      e.ecommerce && e.ecommerce.purchase && e.ecommerce.purchase.actionField &&
      e.ecommerce.purchase.actionField.id === order.number
    );
    if (orderHasBeenPushed) return;

    const orderTotal = order.itemTotal + order.shippingFee + order.dryIceFee + order.handlingFee + order.weightControlFee;

    window.dataLayer.push({
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': order.number,	// Required
            'affiliation': 'Online Store',
            'value': orderTotal,	// Required,
            'item_total': order.itemTotal,
            'delivery_charge': order.shippingFee,
            'dry_ice_charge': order.dryIceFee,
            'cut_by_hand_surcharge': order.weightControlFee,
            'small_order_surcharge': order.handlingFee,
            'tax_cost': order.tax,
            'adjustment_type': 'ENHANCEMENT',
            'user_identifiers': {
              'hashed_email': order.email,
              'hashed_phone_number': order.phone,
              'address_info': {
                'first_name': order.firstName,
                'last_name': order.lastName,
                'street': order.street,
                'city': order.city,
                'zipcode': order.zipcode,
                'state': order.state,
                'country': order.country,
              }
            }
          },
        }
      },
      'products': products,
    });

    this.lastData = window.dataLayer[window.dataLayer.length - 1];
  },

  triggerAfterLoadEvent: function() {
    if (window.dataLayer == null) window.dataLayer = [];
    window.dataLayer.push({ event: 'gtm.dom', 'gtm.uniqueEventId': this.uniqueEventId() });
    window.dataLayer.push({ event: 'turbolinkAfterLoad', 'gtm.uniqueEventId': this.uniqueEventId() });
  },

  angularJSRouteChangeSuccess: function() {
    if (window.dataLayer == null) window.dataLayer = [];
    window.dataLayer.push({ event: 'angularJSRouteChangeSuccess', 'gtm.uniqueEventId': this.uniqueEventId() });
    this.resetGoogleTagVars();
  },

  /**
   * @private
   *
   * @param products
   * @returns {*}
   */
  transformProducts: function(products) {
    // Convert a non-empty array-of-arrays into an array of hashes
    // this is needed to support optional optimized parameter format
    if (products.length > 0 && products[0].name == undefined) {
      products = _.map(products, function(p) {
        return {
          id: p[0],
          name: p[1],
          brand: p[2],
          category: p[3],
          price: p[4],
          quantity: p[5]
        };
      })

      // [{
      //   'name': 'Hy Pro Protein',
      //   'brand': 'All Stars',
      //   'price': '9.99',
      //   'quantity': 1
      // }]
    }

    return products;
  },

  formatLineItems: function(lineItems) {
    let lineItemsFormatted = [];
    if (lineItems.length > 0) {
      lineItemsFormatted = lineItems.map(item => {
        const categories = item.category_name.split("/");
        return {
          item_id: `${item.product_id}`,
          item_name: item.product_name,
          price: item.price,
          item_brand: item.supplier_name,
          item_category: categories[0] || "",
          item_category2: categories[1] || "",
          item_category3: categories[2] || "",
          item_category4: categories[3] || "",
          item_category5: categories[4] || "",
          variant: item.variant.name,
          quantity: item.quantity
        };
      })
    }
    return lineItemsFormatted;
  },

  uniqueEventId: function() {
    if (this.eventCounter == null)
      this.eventCounter = 10;

    this.eventCounter += 1;

    return this.eventCounter;
  },

  resetGoogleTagVars: function () {
    delete window.checkoutComplete;
    delete window.checkoutLocale;
    delete window.checkoutLocaleBase;
    delete window.checkoutOrderNumber;
    delete window.checkoutChannel;
    delete window.checkoutUserId;
    delete window.checkoutSessionId;
    delete window.checkoutTimestamp;
    delete window.checkoutSalutation;
    delete window.checkoutFirstName;
    delete window.checkoutLastName;
    delete window.checkoutEmail;
    delete window.checkoutOrderCurrency;
    delete window.checkoutStreet;
    delete window.checkoutZipcode;
    delete window.checkoutCity;
    delete window.checkoutCountry;
    delete window.checkoutState;
    delete window.checkoutFirstNameHashed;
    delete window.checkoutLastNameHashed;
    delete window.checkoutEmailHashed;

    delete window.checkoutTotal;
    delete window.checkoutTotalPennies;

    delete window.checkoutTaxTotal;
    delete window.checkoutHandlingFee;
    delete window.checkoutDryIceFee;
    delete window.checkoutWeightControlFee;

    delete window.checkoutItemTotal;
    delete window.checkoutItemTotalPennies;
    delete window.checkoutItemTotalNoVat;

    delete window.checkoutDiscountTotal;
    delete window.checkoutDiscountTotalPennies;

    delete window.checkoutShippingTotal;
    delete window.checkoutShippingTotalPennies;

    delete window.checkoutPromotionCode;
    delete window.checkoutPromotionPersonalCode;

    delete window.sovendusShopId;
    delete window.sovendusBannerId;
    delete window.sovendusCheckSum ;
  }
};
