import * as _ from 'underscore';

angular.module('app').controller('PaidSubscriptionsIndexPage', ['$scope', '$rootScope', '$http', '$uibModal', 'Alerts', function($scope, $rootScope, $http, $uibModal, Alerts){
  window.paidSubscriptionsIndexPage = $scope;

  function constructor() {
    loadSubscriptions()
  }

  function destructor() {
    window.paidSubscriptionsIndexPage = null;
  }

  function loadSubscriptions() {
    $http.get('api/frontend/paid_subscriptions/my.json').then((response) => {
      $scope.hasActiveSubscription = response.data.has_active_subscription || false;
      $scope.subscriptions = response.data.subscriptions;

      $scope.smartPassSubscriptions = _($scope.subscriptions).select((s) => s.type == 'smart_pass');
      $scope.weeklySubscriptions = _($scope.subscriptions).select((s) => s.type == 'weekly');
      $scope.otherSubscriptions = _($scope.subscriptions).select((s) => !['smart_pass', 'weekly'].includes(s.type))
    })
  }

  $scope.$on('destroy', destructor);
  constructor();
}]);