// angular.module('app').factory('$exceptionHandler', ['$log', function ($log) {
//   let loggedErrors = [];
//
//   return function (exception, cause) {
//     if (window.Rollbar) {
//       // Prevent dupe error flooding to save money on Rollbar quota
//       if (loggedErrors.indexOf(exception.message + location.pathname) > -1) {
//         console.error("[Error repeated]", "Skipped deep logging due to error repetition, original error: ", exception);
//         return;
//       }
//
//       // Skip bot errors
//       if (window.browserIsBot || window.prerenderAgent) {
//         return;
//       }
//
//       let UserService = window.UserService;
//
//       let customProperties = {
//         userId: (UserService && UserService.currentUser && UserService.currentUser.id) || window.currentUserId,
//         isMobile: window.isMobile,
//         userLocale: I18n.locale,
//         isPrerenderAgent: window.prerenderAgent,
//         currentZipcode: (window.Hubs && window.Hubs.currentZipcode) || window.currentZipcode,
//         location_path: location.pathname,
//         browserIsBot: window.browserIsBot
//       };
//
//       if (window.CatalogViewCtrl) {
//         try {
//           customProperties.currentTaxon = window.CatalogViewCtrl.currentTaxon && window.CatalogViewCtrl.currentTaxon.name;
//           customProperties.currentPage = window.CatalogViewCtrl.pagination && window.CatalogViewCtrl.pagination.current_page;
//           customProperties.productIds = window.CatalogViewCtrl.products && _.map(window.CatalogViewCtrl.products, p => p.id).join(',');
//         } catch(e) { console.warn(e) }
//       }
//
//       loggedErrors.push(exception.message + location.pathname);
//
//       window.Rollbar.error(exception, customProperties, function(err, data) {
//         if (err) {
//           console.error("Error while reporting error to Rollbar: ", err, "Original error: ", exception);
//           return;
//         }
//         // console.log("Error successfully reported to Rollbar. UUID:", data.result.uuid);
//       });
//
//       console.error(exception);
//     } else {
//       loggedErrors.push(exception.message + location.pathname);
//       throw exception;
//     }
//   };
// }]);