import * as _ from 'underscore';

angular.module('app').controller('DashboardQuickActionsCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', 'Alerts', 'blockUI', 'Hubs', 'OrderHelper', '$location', function ($scope, $sce, $http, $q, $rootScope, Alerts, blockUI, Hubs, OrderHelper, $location) {
  //
  // Private members:
  //

  function constructor() {

  }

  function destructor() {

  }


  //
  // Public members
  //

  $scope.repeatLastOrder = function() {
    if (window.CartData && window.CartData.cartBlocked) {
      window.CartData.showLowCapacityPopup();
      return;
    }

    blockUI.start();

    OrderHelper.repeatOrder({ id: 'last', token: '' }).then(() => {
      blockUI.stop();
      $location.path("/cart");
    }, e => {
      Alerts.error(errorMessage(e));
      blockUI.stop();
    });
  };

  $scope.$on('$destroy', destructor);
  constructor();
}]);
