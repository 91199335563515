import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

/**
 * Watches promos from the Engagement service
 * and displays an ordered queue of messages
 *
 * Markup example (restricted to 'element' only):
 *  <farmy-content-promo-dock></farmy-content-promo-dock>
 *
 */
angular.module('app')
  .directive('farmyContentPromoDock', function() {
    return {
      restrict: 'E',
      scope: {},
      controller: ['$scope', '$rootScope', '$timeout', 'Engagement', 'blockUI', function ($scope, $rootScope, $timeout, Engagement, blockUI) {
        window.farmyContentPromoSideDock = $scope;

        $scope.Engagement = Engagement;

        function constructor() {
          // Watches the queue for new additions.
          $scope.$watch('Engagement.promos.popup.length', function(newVal, oldVal) {
            if (newVal > 0) {
              if (blockUI.isBlocking()) {
                var blockUIWatcher = $scope.$watch(function() { return blockUI.isBlocking() }, function(watching) {
                  if (!watching) {
                    moveQueue();
                    blockUIWatcher();
                  }
                })
              } else {
                moveQueue()
              }
            }
          }, true);
        }

        function destructor() {
          window.farmyContentPromoSideDock = null;
        }

        function trigger(promo) {
          promo.current = true;
        }

        function moveQueue() {
          if ($scope.Engagement.promos.popup.length > 0 && !_($scope.Engagement.promos.popup).some(function(i) { return i.current || i.visible })) {
            trigger($scope.Engagement.promos.popup[0])
          }
        }

        // Initialize

        $rootScope.$on('content_promo:dismissed', () => {
          $scope.Engagement.promos.popup = _($scope.Engagement.promos.popup).reject((promo) => promo.terminated);
          moveQueue();
        });

        $scope.$on('$destroy', destructor);
        constructor();
      }],

      template: `<div farmy-ng-include="'/ng/templates/content_promos/content_promo_dock.html?locale=${window.I18n.locale}'"></div>`
    }
  });


