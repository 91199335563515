import * as _ from 'underscore';

angular.module('app').directive('farmySupplierItem', () => {
  return {
    controller: ['$rootScope', '$scope', '$window', '$element', '$timeout', '$interval', '$location', 'blockUI', 'Alerts', 'CartData', '$translate', 'UnitsInflector', 'TaxonHelper', function ($rootScope, $scope, $window, $element, $timeout, $interval, $location, blockUI, Alerts, CartData, $translate, UnitsInflector, TaxonHelper) {

      function constructor() {
        if (window.Rails.env == 'development') {
          if (window.farmySupplierItem == null) window.farmySupplierItem = [];
          window.farmySupplierItem.push($scope);
        }

        $scope.$watch('supplier', (newValue, oldValue) => {
          if (newValue != oldValue)
            updateSupplierData();
        });

        if ($scope.supplier)
          updateSupplierData();
      }

      function destructor() {
        if (window.Rails.env == 'development') {
          if (window.farmySupplierItem == null) window.farmySupplierItem = [];
            window.farmySupplierItem.splice(_.indexOf(window.farmySupplierItem, $scope), 1);
        }
      }

      function updateSupplierData() {
        let metadata = $scope.supplier.ax_products_metadata;

        if (metadata && metadata.ranked_categories) {
          TaxonHelper.getLocalizedTaxonNameById(metadata.ranked_categories[0]).then(name => $scope.extras.specialization = name ? name : null);

          if (metadata.ranked_categories.length > 1) {
            TaxonHelper.getLocalizedTaxonNameById(metadata.ranked_categories[1]).then(name => {
              if ($scope.extras.specialization) {
                if (name) $scope.extras.specialization += `, ${name}`
              } else if (name)
                $scope.extras.specialization = name;
            });
          }
        }
      }

      $scope.layout = $element.attr('layout') || 'full';

      $scope.extras = { };
      $scope.TaxonHelper = TaxonHelper;

      constructor();
      $scope.$on('$destroy', destructor);
    }],
    scope: {
      supplier: '=',
      layout: '@' || 'full'
    },
    link: function($scope, $element, attributes) {

    },
    template: `<farmy-ng-include ng-show='layout' src="'/ng/templates/suppliers/supplier_item_' + layout + '.html?locale=${window.I18n.locale}'" />`
  }
});
