"use strict";

angular.module("app").controller("UnsafeHubChangeModalCtrl", ["$scope", "$uibModalInstance", "$http", "$localStorage", "Hubs", "angularLoad",
  function($scope, $uibModalInstance, $http, $localStorage, Hubs, angularLoad) {
    $scope.closeUnsafeHubChangeModal = function() {
      $uibModalInstance.dismiss("cancel");
      if ($scope.isPickup) Hubs.openZipcodeModal(true);
    };

    $scope.onSubmitClicked = function() {
      if (!$scope.isPickup) {
        const oldZipcode = window.currentZipcode;
        const newZipcode = $scope.fetchShippingZipcode();

        $http.post("/hubs/apply.json", {zip: newZipcode}).then(function(response) {
          window.currentHubId = window.currentUserHubId = $localStorage.currentUserHubId = response.data.hub.id;
          window.currentZipcode = window.currentUserZipcode = $localStorage.currentUserZipcode = response.data.zipcode.name;

          $uibModalInstance.close({successful: true});
          if (oldZipcode !== window.currentZipcode) location.href = "/cart?validate_hubs=true";
        }, function(error) {
          $uibModalInstance.close({successful: false, error});
        });
      } else location.href = "/cart?validate_hubs=true";
    };
  }
]);
