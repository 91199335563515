import React from "react";
import styled from "styled-components";
import currentTheme from "../../react-themes/theme";

const theme = currentTheme();

const BasicSpinner = ({small, color}) => {
  return <StyledBasicSpinner>
    {
      [0, 1, 2, 3, 4, 5, 6, 7].map(line => {
        return <div
          key={line}
          className="line"
          style={{
            backgroundColor: color || theme.colors.primaryColor,
            width: small ? "7px" : "15px",
            height: small ? "3px" : "6px",
            opacity: (1 - ((1 / 8) * line)),
            transform: `rotate(${line * 360 / 8}deg) translate(${small ? "7px" : "14px"})`,
            animationDelay: `${0.2 * line}s`
          }}></div>;
      })
    }
  </StyledBasicSpinner>;
};

export default BasicSpinner;

export const StyledBasicSpinner = styled.div.attrs({
  className: `
  flex
  align-items-center
  justify-content-center
  `
})`
  .line {
    position: absolute;
    border-radius: 5px;
    animation: loader 1500ms linear infinite;
  }

  @keyframes loader{
    0%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }
`;
