import * as _ from 'underscore';
import * as sprintf from 'sprintf';

'use strict';

/**
 * Listens to shipping zipcode changes and runs cart contents validations related
 * to the shipping destination (Hubs compatibility, etc.)
 *
 * A visual element of the shipping stage is linked to scope variables
 * modified by this method.
 */
angular.module('app')
  .directive('farmyPersonalCategoryProductsAddon', function() {
    return {
      controller: ['$rootScope', '$scope', '$http', '$q', '$timeout', '$translate', 'Alerts', 'blockUI', '$attrs', '$element', 'TaxonHelper', 'Hubs', function ($rootScope, $scope, $http, $q, $timeout, $translate, Alerts, blockUI, $attrs, $element, TaxonHelper, Hubs) {
        window.farmyPersonalCategoryProductsAddon = $scope;

        var unbindCatalogViewChaged;
        var unbindHubsChaged;

        function constructor() {
          $scope.hidden = window.localStorage.farmyPersonalCategoryProductsAddon_hidden ? (window.localStorage.farmyPersonalCategoryProductsAddon_hidden == 'true') : false;

          if ($scope.hidden) $element.addClass('minimized-hidden');

          // Always shows, without AB tests
          loadInitialData();

          // Refresh products on hub change
          unbindHubsChaged = $rootScope.$on('hubs:changed', function(event, options) {
            $scope.getRecommendedProducts();
          });

          // Example of using AB Test API for showing/hiding the module:
          //
          // if (window.currentUserAdmin) {
          //   loadInitialData();
          // } else if(window.currentUserId) {
          //   TestManager.shouldTest('taxon_show_product_bookmarks_to_existing_customers').then((d) => {
          //     if (d.should_test) {
          //       TestManager.startTest('taxon_show_product_bookmarks_to_existing_customers', 'shown').then((r) => {
          //         if (r.testing && r.variant == 'shown')
          //           loadInitialData();
          //         else console.log('farmyPersonalCategoryProductsAddon left disabled for split variant');
          //       }, (e) => {})
          //     }
          //   }, (e) => {});
          // }
        }

        function destructor() {
          window.farmyPersonalCategoryProductsAddon = null;

          if (unbindCatalogViewChaged) unbindCatalogViewChaged();
          if (unbindHubsChaged) unbindHubsChaged();
        }

        function loadInitialData() {
          unbindCatalogViewChaged = $rootScope.$on('catalog:view:changed', (data, e) => {
            console.log(data, e);

            $timeout(() => {
              $scope.minimized = true;
              $scope.firstToShow = 'last_order';
              $scope.generateOffers();
            }, 1500);
          });

          $scope.generateOffers();
        }
        // Public members

        /**
         * Determines which type of offers to show,
         * if there are no category items in the very last order,
         * items from older orders will be offered
         *
         * @type {string}
         */
        $scope.firstToShow = 'last_order';

        $scope.minimized = true;
        $scope.minimizedVersionOffersLimit = 2;
        $scope.nothingToShow = true;

        $scope.generateOffers = function() {
          $scope.getRecommendedProducts();
        }

        $scope.getRecommendedProducts = function(params) {
          if (params == null)
            params = { };

          params.hub_id = Hubs.currentHubId;
          params.locale = $translate.use();

          return $q((resolve, reject) => {
            if (params.per_page == null)
              params.per_page = '12';

            if (params.taxon_id == null)
              params.taxon_id = (window.CatalogViewCtrl && window.CatalogViewCtrl.currentTaxonId) || window.currentTaxonId;

            $http.get(`/api/frontend/products/category_view_personal_feeds.json?template=product_in_catalog&locale=${$translate.use()}&express_delivery=${CartData.isExpressDelivery ? 't' : 'f'}`, { params: params }).then((response) => {
              $scope.categoryPersonalProducts = response.data.results.category_personal_products;
              $scope.lastOrderCategoryProducts = response.data.results.last_order_category_products;

              $scope.firstToShow = 'last_order';
              $scope.offers = $scope.lastOrderCategoryProducts;

              if (ArrayUtils.isEmpty($scope.lastOrderCategoryProducts)) {
                $scope.firstToShow = 'previous_orders';
                $scope.offers = $scope.categoryPersonalProducts;
              }

              $scope.nothingToShow = ArrayUtils.isEmpty($scope.categoryPersonalProducts) && ArrayUtils.isEmpty($scope.lastOrderCategoryProducts);

              resolve($scope.offers);
            })
          });
        };

        $scope.expand = function() {
          ahoy.track("personal-category-products-expand", { taxon_id: (window.CatalogViewCtrl && window.CatalogViewCtrl.currentTaxonId) || window.currentTaxonId, channel: window.xSessionChannel });

          $scope.minimized = false;
        };

        $scope.minimize = function() {
          ahoy.track("personal-category-products-minimize", { taxon_id: (window.CatalogViewCtrl && window.CatalogViewCtrl.currentTaxonId) || window.currentTaxonId, channel: window.xSessionChannel });

          $scope.minimized = true;
        };

        $scope.expandMore = function() {
          ahoy.track("personal-category-products-show-more", { taxon_id: (window.CatalogViewCtrl && window.CatalogViewCtrl.currentTaxonId) || window.currentTaxonId, channel: window.xSessionChannel });

          $scope.firstToShow = 'previous_orders';
          $scope.offers = $scope.categoryPersonalProducts;
        };

        $scope.hideThis = function() {
          ahoy.track("personal-category-products-hide", { taxon_id: (window.CatalogViewCtrl && window.CatalogViewCtrl.currentTaxonId) || window.currentTaxonId, channel: window.xSessionChannel });

          $element.addClass('minimized-hidden');
          $scope.hidden = true;
          window.localStorage.farmyPersonalCategoryProductsAddon_hidden = 'true';
        };

        $scope.showMinimized = function() {
          ahoy.track("personal-category-products-restore", { taxon_id: (window.CatalogViewCtrl && window.CatalogViewCtrl.currentTaxonId) || window.currentTaxonId, channel: window.xSessionChannel });

          $element.removeClass('minimized-hidden');

          $scope.hidden = false;
          window.localStorage.farmyPersonalCategoryProductsAddon_hidden = 'false';
        }
        // Initialize

        $scope.$on('$destroy', destructor);
        constructor();
      }],
      scope: {

      },
      link: function($scope, $element, attributes) {

      },
      templateUrl: function() { return `/ng/templates/products/personal_category_products_addon.html?locale=${window.I18n.locale}` }
    }
  });


